import { Page } from "../../Components/Page/Page";
import {
    Box,
    Card,
    Typography,
    styled,
    BoxProps,
    Button,
    IconButton,
    Stack,
    Avatar,
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import { DEFAULT_PAGE, DEFAULT_AUTH_IMAGE, LOGO } from "../../Utils/constants";
import { ReactNode, useMemo } from "react";
import { LanguageSelect } from "../../Components/LanguageSelect/LanguageSelect";
import { Logo } from "../../Components/Logo/Logo";
import { ArrowBackRounded } from "@mui/icons-material";
import Tabs from "./Tabs";

const Styled = styled(Box)({
    height: "100vh",
    background:
        "linear-gradient(0deg, #33117C 0%, rgba(209, 17, 17, 0) 100%), url(/graphics/login-bg.jpeg)",
    backgroundSize: "cover",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
});

const StyledFooter = styled((props: BoxProps) => <Box component={"footer"} {...props} />)({
    padding: 24,
    width: "100%",
    display: "flex",
    justifyContent: "center",
});

const states = [
    { key: "login", label: "Log ind" },
    { key: "create", label: "Opret konto" },
];

export const AuthLayout = ({
    children,
    state,
    title,
    description,
    onBack,
    hideTabs = false,
    hideFooter = false,
}: {
    children?: ReactNode;
    state?: "login" | "create";
    hideFooter?: boolean;
    hideTabs?: boolean;
    onBack?: () => void;
    title?: string;
    description?: string;
    imgSrc?: string;
}) => {
    const { isAuthenticated, hasValidHashToken } = useAuth();

    const navigate = useNavigate();

    if (isAuthenticated && !hasValidHashToken()) {
        return <Navigate to={DEFAULT_PAGE} replace />;
    }

    const handleChangeState = (key: string) => {
        navigate(`/${key}`);
    };

    return (
        <Styled p={{ xs: 4, lg: 10 }}>
            <Stack justifyContent={"space-between"} mr={10}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Logo square={true} height={{ xs: 56 }} />
                    <Logo ml={2} white={true} height={{ xs: 36 }} />
                </Stack>
                <Box color={"white"} maxWidth={560} alignSelf={"flex-end"}>
                    <Typography
                        variant={"h1"}
                        color={"inherit"}
                        fontSize={{ xs: "2.4em", lg: "3.25em" }}
                    >
                        Alt du skal bruge som alternativ terapeut, og mere til.
                    </Typography>
                    <Box mt={4} display={"flex"}>
                        <Avatar
                            alt={"Asbjørn Axelsen"}
                            src={"/asbjorn-profile.png"}
                            sx={{
                                width: 64,
                                height: 64,
                                border: "2px solid #722FDE",
                            }}
                        />
                        <Box ml={4}>
                            <Typography fontSize={"1.1em"}>
                                “Spiritworld vil facilitere mere balance i verden - en sjæl ad
                                gangen. Derfor har vi brug for din hjælp, vi kan ikke gøre det
                                alene. Tilslut dig bevægelsen, så vi sammen kan skabe en bedre
                                verden.”
                            </Typography>
                            <Typography
                                mt={1}
                                whiteSpace={"pre-wrap"}
                                fontSize={"1.1em"}
                                color={"#D664BE"}
                                fontWeight={600}
                            >
                                Asbjørn Axelsen
                                <br />
                                <Typography fontWeight={500} component={"span"}>
                                    Medstifter af Spiritworld
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Stack>

            <Box
                flexGrow={{ xs: 1, md: 0 }}
                maxWidth={480}
                width={"100%"}
                maxHeight={{
                    lg: "95vh",
                    xs: "98vh",
                }}
                px={{ xs: 4, md: 7 }}
                py={{ xs: 4, md: 6 }}
                display={"flex"}
                flexDirection={"column"}
                borderRadius={{ xs: 0, md: 2 }}
                sx={{
                    background: "#F2F2F7",
                    overflowY: "auto",
                    boxShadow: "0 0 16px 0 rgba(0,0,0,0.18)",
                }}
            >
                {!onBack && (
                    <Stack alignItems={"center"}>
                        <Logo
                            mt={{ xs: 1, md: 0 }}
                            square={true}
                            display={{ xs: "none", lg: "block" }}
                            height={{ xs: 40, lg: 92 }}
                        />
                        <Logo mt={{ xs: 0, lg: 3 }} height={{ xs: 40, lg: 40 }} />
                    </Stack>
                )}

                {onBack && (
                    <Box>
                        <IconButton onClick={onBack}>
                            <ArrowBackRounded />
                        </IconButton>
                    </Box>
                )}

                {!hideTabs && (
                    <Tabs
                        mt={{ xs: 3, lg: 6 }}
                        mb={2}
                        items={states}
                        selectedKey={state}
                        handleClick={handleChangeState}
                    />
                )}

                {title && (
                    <Typography mb={description ? 1 : 2} mt={onBack ? 3 : 5} variant={"h3"}>
                        {title}
                    </Typography>
                )}

                {description && <Typography mb={2}>{description}</Typography>}
                {children}
                {!hideFooter && (
                    <StyledFooter mt={"auto"}>
                        <LanguageSelect />
                    </StyledFooter>
                )}
            </Box>
        </Styled>
    );
};
