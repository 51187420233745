import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { theme } from "./Utils/theme";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorProvider } from "./Pages/SyiPage/useErrors";
import { MobileBlocker } from "./Components/MobileBlocker/MobileBlocker";
import ErrorBoundary from "./Components/ErrorBoundary";

const container = document.getElementById("root") as HTMLElement;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // Default stale time of 10 minutes
            retry: false, // We rarely have timeout/sloppy connections
            refetchOnWindowFocus: false,
        },
    },
});

const getEnv = () => {
    const hostname = window.location.hostname;

    if (hostname.includes("localhost")) {
        return "local";
    }

    if (hostname.includes("dev")) {
        return "development";
    }

    if (hostname.includes("staging")) {
        return "staging";
    }

    if (hostname === "app.holdbar.com") {
        return "production";
    }

    return "local";
};

const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <ErrorProvider>
                    <MobileBlocker />
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </ErrorProvider>
            </ThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
