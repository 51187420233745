import { Box, BoxProps, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { StatusChip } from "../StatusChip";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { BookingListEmptyScreen, EmptyScreen } from "../EmptyScreen/EmptyScreen";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { ListHeader } from "../ListHeader/ListHeader";
import { AccessTimeRounded, EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import { BookingsIcon } from "../../Icons";
import { IconLabel } from "../IconLabel/IconLabel";
import { Customer, EnrichedCustomer, useCustomers } from "../../Hooks/useCustomers";
import { formatMoney } from "../VariantSelect/VariantSelect";
import { useNavigate } from "react-router-dom";

const columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
        value: () => <BookingsIcon />,
    },
    {
        key: "name",
        label: "Navn",
        props: {
            xs: 3.5,
            lg: 2.8,
        },
        value: (customer: Customer) => (
            <>
                <Typography
                    fontSize={{ xs: "0.88em", lg: "1em" }}
                    lineHeight={"1.1"}
                    fontWeight={"500"}
                >
                    {customer.name}
                </Typography>
                <IconLabel
                    variant={"body2"}
                    fontSize={"0.75em"}
                    Icon={AccessTimeRounded}
                    label={formatDistanceToNow(new Date(customer.created)) + " siden"}
                />
            </>
        ),
    },
    {
        key: "contact",
        label: "Kontakt",
        props: {
            xs: 4.5,
            lg: 3.5,
            pr: 1,
        },
        value: (customer: Customer) => (
            <>
                <IconLabel Icon={EmailOutlined} label={customer.email} />
                <IconLabel Icon={PhoneOutlined} label={customer.phone} />
            </>
        ),
    },
    {
        key: "status",
        label: "Status",
        props: {
            xs: 1.5,
        },
        value: (el: Customer) => (
            <span>
                <StatusChip status={"active"} />
            </span>
        ),
    },
    {
        key: "totalSpent",
        label: "Beløb",
        props: {
            xs: 2,
        },
        value: (customer: EnrichedCustomer, t: TranslateFunction) => (
            <Typography fontSize={"0.88rem"}>
                {formatMoney(t)({ value: customer.totalSpent, nativeCurrency: "dkk" })}
            </Typography>
        ),
    },
];

export type CustomersListProps = BoxProps & {};

export const CustomersList = ({ ...props }: CustomersListProps) => {
    const navigate = useNavigate();

    const {
        customers: { data: _customers, isLoading },
    } = useCustomers();

    const [searchValue, setSearchValue] = useState<null | string>(null);

    const [filter, setFilter] = useState<"all" | "future" | "past" | string>("all");

    const customers = useMemo(() => {
        if (searchValue) {
            return _customers?.filter(({ name, email, phone }) => {
                return [name, email, phone].some((el) =>
                    el?.toLowerCase().includes(searchValue.toLowerCase())
                );
            });
        }
        return _customers;
    }, [_customers, searchValue, filter]);

    const { t } = useTranslate("utils.tables.header");

    const handleClick = (customerId: string) => () => {
        navigate(`/customers/${customerId}`);
    };

    return (
        <Box {...props}>
            <ListHeader
                title={null}
                filterOptions={[
                    { key: "all", label: "Alle" },
                    { key: "future", label: "Kommende" },
                    { key: "past", label: "Afsluttet" },
                ]}
                searchPlaceholder={"Søg i klienter"}
                onSearch={setSearchValue}
                onChangeFilter={setFilter}
                defaultSelectedFilter={"all"}
                mb={2}
            />
            <Grid container p={"12px 20px"} mb={1}>
                {columns.map((el) => {
                    return (
                        <Grid
                            fontSize={"0.75em"}
                            fontWeight={600}
                            textTransform={"uppercase"}
                            key={el.key}
                            item
                            {...el.props}
                            component={Typography}
                        >
                            {el.key !== "none" && t(el.key)}
                        </Grid>
                    );
                })}
            </Grid>

            <Stack spacing={0}>
                {customers?.length === 0 && (
                    <BookingListEmptyScreen
                        mt={6}
                        title={t("emptyState.title", "customers")}
                        description={t("emptyState.description", "customers")}
                    />
                )}

                {customers?.map((customer) => (
                    <CustomerListItem key={customer.id} customer={customer} onClick={handleClick} />
                )) ?? <ListSkeleton />}
            </Stack>
        </Box>
    );
};

const CustomerListItem = ({
    onClick,
    customer,
}: {
    customer: Customer;
    onClick: (id: string) => () => void;
}) => {
    const { t } = useTranslate("utils");
    return (
        <Box p={"4px 8px"}>
            <Grid
                container
                component={Card}
                p={1.5}
                alignItems={"center"}
                sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "rgb(252,252,252)" },
                }}
                onClick={onClick(customer.id)}
            >
                {columns.map((el) => {
                    return (
                        <Grid
                            whiteSpace={"pre-wrap"}
                            key={`${customer.id}-${el.key}`}
                            item
                            {...el.props}
                            {...el.valueProps}
                        >
                            {el.value?.(customer as EnrichedCustomer, t)}
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};
