import { Box, ButtonBaseProps, Skeleton, Stack, Typography } from "@mui/material";
import { CustomCard, ICustomCardProps, LeftLabel } from "../CustomCard/CustomCard";
import { CalendarIcon } from "../../Icons";
import React from "react";
import { Localized } from "../../Hooks/useService";
import { languagesOptions } from "../../Utils/config";
import { useTranslate } from "../../Hooks/useTranslate";
import { renderDateTime } from "@hiddengemgroup/utils-date";

interface IEventCard {
    languages: Array<keyof Localized>;
    startDateTime: string | Date;
    endDateTime?: string | Date;
    availableSlots?: number;
    bookingsCount?: number;
    assignees?: {
        id: string;
        name: string;
        pictures?: {
            profile: {
                url: string;
            };
        };
    }[];
}

export const EventCard = ({
    title,
    onClickStatus,
    status,
    languages: langs,
    startDateTime,
    endDateTime,
    availableSlots,
    bookingsCount,
    assignees,
    children,
    onClick,
    fullWidth = false,
    ...props
}: ButtonBaseProps & Omit<ICustomCardProps, "image" | "content"> & IEventCard) => {
    const { t } = useTranslate("utils.generic");

    return (
        <CustomCard
            image={
                <Box
                    sx={{ background: "#F2F2F7" }}
                    fontSize={"80px"}
                    borderRadius={"12px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={168}
                    height={168}
                >
                    <CalendarIcon fontSize={"inherit"} htmlColor={"#636366"} />
                </Box>
            }
            status={status}
            title={title!}
            onClickStatus={onClickStatus}
            onClick={onClick}
            fullWidth={fullWidth}
            content={
                (
                    <Stack mt={2} spacing={1}>
                        <Box display={"flex"} alignItems={"center"}>
                            <LeftLabel>{t("language")}</LeftLabel>
                            <Typography fontSize={"20px"} lineHeight={"1em"}>
                                {langs?.map((el) => languagesOptions[el].emoji).join(" ")}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <LeftLabel>{t("time")}</LeftLabel>
                            <Typography fontWeight={500} fontSize={"0.8em"} color={"#636366"}>
                                {(startDateTime &&
                                    renderDateTime(startDateTime, endDateTime, {
                                        standard: "ddmyyyyhh",
                                        sameDay: "dmhh",
                                    })) ?? <Skeleton width={240} height={22} />}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <LeftLabel>{t("participants")}</LeftLabel>
                            {(availableSlots && (
                                <Typography fontWeight={500} fontSize={"0.8em"} color={"#636366"}>
                                    {bookingsCount} / {availableSlots}
                                </Typography>
                            )) ?? <Skeleton width={80} height={22} />}
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <LeftLabel>{t("guide")}</LeftLabel>
                            <Typography fontWeight={500} fontSize={"0.8em"} color={"#636366"}>
                                {assignees?.map((el) => el.name).join(", ") ?? ""}
                            </Typography>
                        </Box>
                    </Stack>
                ) as any
            }
            {...props}
        >
            {children && (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                >
                    {children}
                </Box>
            )}
        </CustomCard>
    );
};
