import { Box, Button, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { Tip } from "../../Components/Tip/Tip";
import { SyiSection } from "../SyiPage/SyiSection";
import { ArrowBackRounded, ArrowForwardRounded, ErrorRounded } from "@mui/icons-material";
import React, { useMemo } from "react";
import { ProfileUpload } from "../../Components/ProfileUpload/ProfileUpload";
import type { TInput } from "./OnBoardingPage";
import { useOutletContext } from "react-router-dom";
import { useController, useFormState, useWatch } from "react-hook-form";
import { LanguageChips } from "../../Components/LanguageChips/LanguageChips";
import { useTranslate } from "../../Hooks/useTranslate";
import { useProfile } from "../../Hooks/useProfile";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";
import { Footer } from "./ContentBlock";

const meInputs: TInput[] = [
    {
        key: "name",
        name: "Fulde navn",
        helperText: "Så dit team kan søge dig frem i Holdbar",
        props: {
            fullWidth: true,
        },
    },
    {
        key: "email",
        name: "Email",
        helperText: "Så dit team kan skrive til dig",
        props: {
            disabled: true,
        },
    },
    {
        key: "phone",
        name: "Telefon",
        helperText: "Så dit team kan ringe til dig",
    },
];

export const ErrorBlock = ({ label }: { label: string }) => {
    return (
        <Box p={1}>
            <ErrorRounded />
            <Typography variant={"body2"} color={"error"}>
                {label}
            </Typography>
        </Box>
    );
};

export const OnBoardingProfileSection = () => {
    const { t } = useTranslate("onboarding.profile");

    const {
        handleDeleteImage,
        handleUploadPrepared,
        handleSubmitMe,
        registerMe,
        wasInvited,
        handleNext,
        getMeValues,
        onSubmitMe,
        handleSubmit,
        handleBack,
    } = useOutletContext<any>();

    const { errors } = useFormState();

    const {
        userinfo: { data: userinfo },
    } = useProfile();

    const pictures = useWatch({ name: "pictures" });

    const picture = useMemo(() => {
        const { pictures } = getMeValues();
        const { localUrl, url, ...props } = pictures?.profile ?? {};
        return { ...props, url: localUrl ?? url };
    }, [pictures]);

    const { field: languages } = useController({ name: "languages", rules: { required: true } });

    return (
        <>
            <Typography mb={2} variant={"h3"}>
                {t("boxTitle")}
            </Typography>
            <Tip filled label={t("profilePictureHelpText")} />
            <Typography variant={"h5"} mt={3}>
                {t("profilePictureTitle")}
            </Typography>
            <ProfileUpload
                mt={2}
                fileType={"profile"}
                onUploadPrepared={handleUploadPrepared("me")}
                label={t("profilePictureButtonHelpText")}
                media={picture}
                subjectId={userinfo?.sub}
                subject={"me"}
                containerProps={{
                    sx: {
                        borderColor: "transparent",
                    },
                }}
            />

            <Divider sx={{ mt: 3, mb: 3 }} />

            <Typography variant={"h5"} mb={3}>
                {t("infoTitle")}
            </Typography>

            <Grid container spacing={2} columns={2} pl={0}>
                {meInputs.map((el, i) => (
                    <Grid item xs={el.props?.fullWidth ? 1 : 1}>
                        <TextField
                            autoFocus={i === 0}
                            label={t(`${el.key}Placeholder`)}
                            fullWidth={true}
                            error={Boolean(errors[el.key])}
                            defaultValue={""}
                            helperText={
                                Boolean(errors[el.key])
                                    ? t("required", "utils.errors")
                                    : t(`${el.key}HelpText`)
                            }
                            {...(el?.props ?? {})}
                            {...registerMe(el.key, { required: true })}
                        />
                    </Grid>
                ))}
                <TextField
                    sx={{ visibility: "hidden" }}
                    type={"hidden"}
                    {...registerMe("id", { value: userinfo?.sub })}
                />
            </Grid>

            <Divider sx={{ mt: 3, mb: 3 }} />

            <SyiSection
                pb={12}
                title={t("languagesTitle")}
                error={Boolean(errors["languages"]) ? t("required", "utils.errors") : ""}
            >
                <LanguageChips
                    onChange={languages.onChange}
                    multiple={true}
                    languages={languages.value}
                />
            </SyiSection>
            <Footer
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={1}
                mt={4}
            >
                <Button
                    onClick={handleBack}
                    startIcon={<ArrowBackRounded />}
                    variant={"outlined"}
                    size={"large"}
                    color={"secondary"}
                >
                    {t("back", "buttons")}
                </Button>
                <ProgressButton
                    size={"large"}
                    variant={"contained"}
                    onClick={onSubmitMe(wasInvited ? handleSubmit : handleNext)}
                    startIcon={<ArrowForwardRounded />}
                    label={t("next", "buttons")}
                />
            </Footer>
        </>
    );
};
