import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Box, BoxProps } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import weakMemoize from "@emotion/weak-memoize";

const memoizedCreateCacheWithContainer = weakMemoize((container) =>
    createCache({
        container: container as any,
        key: "with-emotion",
    })
);

export const IFrame = ({ children, title, ...props }: BoxProps<"iframe">) => {
    const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
    const doc = contentRef?.contentWindow?.document;
    const mountNode = doc?.body;
    const insertionTarget = doc?.head;
    useEffect(() => {
        if (insertionTarget) {
            const link = document.createElement("link");
            link.href = "/RichText.css";
            link.rel = "stylesheet";
            link.type = "text/css";
            insertionTarget.append(link);
        }
    }, [insertionTarget]);

    return (
        <Box
            component={"iframe"}
            title={title}
            {...props}
            overflow={"scroll"}
            scrolling={"yes"}
            frameBorder={0}
            ref={setContentRef}
        >
            {mountNode &&
                insertionTarget &&
                createPortal(
                    <CacheProvider value={memoizedCreateCacheWithContainer(insertionTarget)}>
                        {children}
                    </CacheProvider>,
                    mountNode
                )}
        </Box>
    );
};
