import Fetch, { IShallowSuccessResponse } from './index'

export type TTerms = { [type: string]: { versions: { [lang: string]: { content: string } } } }

export const getTerms = async () => {
    if (typeof process.env.REACT_APP_API_TERMS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TERMS_URL is not defined')
    }

    return Fetch.get<TTerms>(`${process.env.REACT_APP_API_TERMS_URL}company`)
}

export const updateTerms = async (type: string, payload: { [k: string]: unknown }) => {
    if (typeof process.env.REACT_APP_API_TERMS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TERMS_URL is not defined')
    }

    if (!payload) {
        throw new Error('Missing payload')
    }

    return Fetch.put(`${process.env.REACT_APP_API_TERMS_URL}company/${type}`, payload)
}

export const createFromTemplate = async <T>(
    type: string,
    language: string,
    variables: { [k: string]: string },
) => {
    if (typeof process.env.REACT_APP_API_TERMS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TERMS_URL is not defined')
    }

    if (!type) {
        throw new Error('Missing type')
    }

    return Fetch.post<T>(`${process.env.REACT_APP_API_TERMS_URL}template/${type}`, {
        language,
        variables,
    })
}
