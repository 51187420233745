import { useProfile } from "./useProfile";
import { useMemo } from "react";
import { renderLocation } from "../Utils/helpers";

export const useAddress = (
    payload:
        | {
              value?: string;
              custom?: string;
              multiple?: { value: string }[];
              selectedOptionKey: "companyAddress" | "custom" | "multiple" | string;
          }
        | undefined
) => {
    const {
        company: { data: company },
    } = useProfile();
    const address = useMemo(() => {
        if (payload === undefined) {
            return undefined;
        }
        if (payload?.selectedOptionKey === "custom" && (payload?.value || payload?.custom)) {
            return payload.value ?? payload.custom;
        }
        if (payload?.selectedOptionKey === "multiple" && payload?.multiple) {
            return payload.multiple.map((el) => el.value);
        }
        return renderLocation(company?.location);
    }, [company, payload]);
    return {
        address,
    };
};
