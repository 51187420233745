import { Box, Dialog } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CloseButton } from "../Components/CloseButton/CloseButton";
import { useTranslate } from "../Hooks/useTranslate";

interface VideoDialogProps {
    onClose?: () => void;
    src: string;
}

export const VideoDialog = NiceModal.create<VideoDialogProps>(({ onClose, src }) => {
    const { t } = useTranslate("utils.generic");

    const modal = useModal();

    const handleClose = () => {
        modal.hide().then((el) => modal.remove());
        onClose?.();
    };

    const handleOk = () => {
        modal.hide().then((el) => modal.remove());
    };

    return (
        <Dialog open={modal.visible} fullScreen={true} onClose={handleClose}>
            <CloseButton
                onClick={handleClose}
                sx={{ position: "absolute", top: 24, right: 24, zIndex: 10 }}
            />
            <Box component={"video"} crossOrigin={""} width={"100%"} height={"100%"} controls>
                <source src={src} type={"video/mp4"} />
            </Box>
        </Dialog>
    );
});
