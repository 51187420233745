import { useTranslate } from "./useTranslate";
import { UseFormReturn, useWatch } from "react-hook-form";

export const useServiceType = (control?: UseFormReturn["control"]) => {
    const { t } = useTranslate("utils.serviceTypes.definiteSingular");
    const type = useWatch({ name: "type", control });
    if (type) {
        return {
            translated: t(type),
            type,
        };
    }
    return {
        translated: t("theService", "utils.generic"),
        type,
    };
};
