import react, { useState } from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ListItemText } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { AvatarButton, getInitials } from "../AvatarButton/AvatarButton";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../Hooks/useProfile";
import { Avatar, capitalize, Stack, StackProps, styled, useMediaQuery } from "@mui/material";
import { useQueryClient } from "react-query";
import { useAuth } from "../../Hooks/useAuth";
import { getFirstName } from "../../Utils/helpers";

const StyledMUIButton = styled(Menu)(() => ({
    ["&.MuiButton-root"]: {
        boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.5)",
        borderColor: "red",
        "&:hover": {
            backgroundColor: "rgba(255,255,255,0.1)",
        },
    },
}));

export const StyledMenu = ({ children, ...props }: MenuProps) => {
    return (
        <StyledMUIButton
            sx={{
                boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.5)",
                borderColor: "red",
            }}
            PaperProps={{
                sx: {
                    overflow: "hidden",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                    },
                },
            }}
            {...props}
        >
            {children}
        </StyledMUIButton>
    );
};

type TAccountItem = {
    key: string;
    label: string;
    src: string;
};

const accounts: TAccountItem[] = [
    {
        key: "stauning",
        label: "Stauning Whisky",
        src: "/stauning-logo.png",
    },
    {
        key: "bridgewalking",
        label: "Bridge Walking Lillebælt",
        src: "/bridgewalking-logo.png",
    },
];

export const AccountSelect = ({ items, ...props }: StackProps & { items?: TAccountItem[] }) => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const { clearAuth } = useAuth();
    const { company, me, myRole } = useProfile();

    const queryClient = useQueryClient();

    const [selected, setSelected] = useState(0);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: react.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectAccount = (index: number) => {
        setSelected(index);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = async () => {
        await queryClient.removeQueries();
        clearAuth();
        navigate("/login", { replace: true });
    };

    return (
        <Stack alignItems={"center"} {...props}>
            <Avatar
                sx={{
                    mb: 2,
                    width: { xs: "56px", lg: "120px" },
                    height: { xs: "56px", lg: "120px" },
                    fontSize: "1.2em",
                    fontWeight: "500",
                }}
                src={me.data?.pictures?.profile?.url}
            >
                {me.isLoading ? null : getInitials(me.data?.name)}
            </Avatar>
            <AvatarButton
                onClick={handleClick}
                disableRipple={true}
                showAvatar={false}
                subtitle={capitalize(myRole ?? "")}
                label={isMobile ? getFirstName(me.data?.name ?? "") : me.data?.name}
                isLoading={me.isLoading}
            />
            <StyledMenu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleLogOut} sx={{ color: "error.main" }}>
                    <ListItemIcon>
                        <Logout fontSize="small" color={"error"} />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </StyledMenu>
        </Stack>
    );
};
