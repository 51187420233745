import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useMemo } from "react";
import { useService } from "./useService";
import { useBookings } from "./useBookings";
import { type Activity, useActivities } from "./useActivities";
import { toast } from "react-toastify";

export type Customer = {
    id: string;
    status: "active";
    created: string;
    updated: string;
    companyId: string;
    externalCompanyId: string;
    externalId: string;
    name: string;
    phone: string;
    email: string;
    location: {
        address: string;
        zipCode: string;
        city: string;
        country: string;
    };
    activities?: Activity[];
    age: string;
    picture: { key: string; url: string } | null;
    source?: string; // manual | lead/12345
};

export type EnrichedCustomer = {
    totalSpent: number;
} & Customer;

const calculateSpent = (activities: Activity[] | undefined) => {
    return activities?.reduce((total, activity) => {
        if (activity?.metadata?.price?.amount) {
            return total + activity.metadata.price.amount;
        }
        return total;
    }, 0);
};

export const useCustomers = (serviceId?: string | undefined, customerId?: string) => {
    const queryClient = useQueryClient();

    const { services } = useService();

    const { getServiceIdFromBooking, getServiceName } = useBookings();

    const {
        activities: { data: activities },
    } = useActivities("customer", customerId);

    const CustomersQueryKey = ["customers"];
    const SingleCustomerQueryKey = ["customer", customerId];

    const customers = useQuery<Customer[]>(
        CustomersQueryKey,
        async () => {
            await queryClient.cancelQueries(CustomersQueryKey);
            const data = await api.getCustomers();
            return data
                .map((el) => {
                    queryClient.setQueryData(["customer", el.id], el);
                    return {
                        ...el,
                        totalSpent: calculateSpent(activities),
                    };
                })
                .sort((a, b) => a.name?.localeCompare(b.name));
        },
        {
            enabled: true,
        }
    );

    const customer = useQuery<Customer>(
        SingleCustomerQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleCustomerQueryKey);
            return api.getCustomer(customerId as string);
        },
        {
            enabled: Boolean(customerId),
        }
    );

    const enrichedCustomer = useMemo(() => {
        if (customer.data) {
            return {
                ...customer.data,
                activities: activities?.map((el) => {
                    return {
                        ...el,
                        ...(el.event?.startsWith("booking") && {
                            metadata: {
                                ...el.metadata,
                                name: getServiceName(getServiceIdFromBooking(el.subjectId)),
                            },
                        }),
                    };
                }),
                totalSpent: calculateSpent(activities),
            };
        }
    }, [customer, services.data, activities]);

    const updateCustomer = useMutation(
        ({ id, showAsyncToast = true, ...payload }: { id: string; showAsyncToast?: boolean }) => {
            return api.updateCustomer(id, { ...payload });
        },
        {
            onMutate: async ({ id, showAsyncToast, ...payload }) => {
                const singleQueryKey = ["customer", id];

                await queryClient.cancelQueries(CustomersQueryKey);

                const previous = queryClient.getQueryData<Customer>(singleQueryKey);

                queryClient.setQueryData<Customer[]>(CustomersQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<Customer>(singleQueryKey, (prev) => {
                    return { ...prev!, ...payload };
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<Customer>(
                        ["customer", variables.id],
                        context.previous
                    );
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.allSettled([queryClient.invalidateQueries(CustomersQueryKey)]);
                const handler = async () => {
                    await Promise.all([
                        queryClient.invalidateQueries(["offers"]),
                        queryClient.invalidateQueries(["bookings"]),
                        queryClient.invalidateQueries(["activities"]),
                    ]);
                    await Promise.all([
                        queryClient.invalidateQueries([
                            "activities",
                            { subject: "customer", subjectId: data?.id ?? variables?.id },
                        ]),
                        queryClient.invalidateQueries([
                            "records",
                            "customer",
                            data?.id ?? variables?.id,
                        ]),
                        queryClient.invalidateQueries(["offer"]),
                        queryClient.invalidateQueries([
                            "offers",
                            "customer",
                            data?.id ?? variables?.id,
                        ]),
                        queryClient.invalidateQueries([
                            "bookings",
                            "customer",
                            data?.id ?? variables?.id,
                        ]),
                        queryClient.invalidateQueries(["customer", data?.id]),
                    ]);
                };
                if (variables.showAsyncToast) {
                    const toastId = toast.loading(
                        "Opdaterer kundeinformation i aftaler og tilbud..."
                    );
                    new Promise((resolve) => {
                        setTimeout(resolve, 15000);
                    }).then(async () => {
                        await handler();
                        toast.update(toastId, {
                            type: "success",
                            render: "Kundeinformation opdateret",
                            autoClose: 3500,
                            isLoading: false,
                        });
                    });
                } else {
                    await handler();
                }
            },
        }
    );

    const deleteCustomer = useMutation((id: string) => api.deleteCustomer(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(CustomersQueryKey);

            queryClient.setQueryData<Customer[]>(["customers"], (prev) => {
                return prev?.filter((el) => el.id !== id) ?? [];
            });

            queryClient.setQueryData<Customer | undefined>(["customer", id], undefined);

            return { serviceId };
        },
        onSettled: async (data, err, id, context) => {
            await queryClient.invalidateQueries(CustomersQueryKey);
        },
    });

    return {
        customer,
        customers,
        enrichedCustomer,
        deleteCustomer,
        updateCustomer,
    };
};
