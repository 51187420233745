import { Box, BoxProps, styled } from "@mui/material";
import React, { ComponentPropsWithoutRef } from "react";

const StyledPage = styled(Box)(({ theme }) => ({
    margin: "40px 0 40px 0",
    maxWidth: theme.breakpoints.up("xl"),
    width: "100%",
    position: "relative",
    ["@media screen and (max-width: 1200px)"]: {
        margin: "24px 0",
    },
})) as typeof Box;

export const Page = ({ children, ...props }: BoxProps) => {
    return <StyledPage {...props}>{children}</StyledPage>;
};
