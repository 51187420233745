import {
    Box,
    BoxProps,
    ButtonProps,
    CircularProgress,
    IconButton,
    StackProps,
    styled,
    Tooltip,
} from "@mui/material";
import type { TImage } from "../../Utils/types";
import { useTranslate } from "../../Hooks/useTranslate";
import React, { useRef, useState } from "react";
import * as api from "../../Api";
import { UploadContainer } from "../ProfileUpload/ProfileUpload";
import { DeleteOutlineRounded, FullscreenRounded, VideocamRounded } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { useUploader } from "../../Hooks/useUploader";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import { VideoDialog } from "../../Modals/VideoDialog";

const Styled = styled(Box)({
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(100,100,100,.05)",
    ["&:hover"]: {
        backgroundColor: "rgba(100,100,100,.15)",
    },
    minWidth: 80,
    minHeight: 80,
    width: 80,
    height: 80,
    padding: 0,
    borderRadius: 100,
});

const processVideo = async (file: File, onProgress: ({ ratio }: { ratio: number }) => void) => {
    const { createFFmpeg, fetchFile } = await import("@ffmpeg/ffmpeg");
    const ffmpeg = createFFmpeg({
        progress: onProgress,
    });
    await ffmpeg.load();
    ffmpeg.FS("writeFile", "upload.mp4", await fetchFile(file));
    await ffmpeg.run("-i", "upload.mp4", "-b:v", "4M", "-threads", "4", "output.mp4");
    await ffmpeg.run("-i", "upload.mp4", "-vframes", "1", "poster.png");
    const video = ffmpeg.FS("readFile", "output.mp4");
    const poster = ffmpeg.FS("readFile", "poster.png");
    const cleanUp = () => {
        ffmpeg.FS("unlink", "poster.png");
        ffmpeg.FS("unlink", "output.mp4");
        ffmpeg.FS("unlink", "upload.mp4");
        ffmpeg.exit();
    };
    return {
        video,
        poster,
        cleanUp,
    };
};

export const VideoUpload = ({
    video,
    subjectId,
    containerProps,
    children,
    subject,
    onDelete,
    onUploadPrepared,
    label,
    ...props
}: StackProps & {
    containerProps?: BoxProps;
    video?: TImage & { poster: TImage };
    subject: "company" | "me";
    subjectId?: string;
    onUploadPrepared: (...params: any[]) => void;
    onDelete: (key: string) => Promise<void>;
    label: string;
}) => {
    const { t } = useTranslate("buttons");

    const toastId = useRef<ReturnType<typeof toast> | null>();

    const [processing, setProcessing] = useState(false);

    const poster = useRef<{
        key: string;
        url: string;
        uploadFunc: () => Promise<void>;
    }>();
    const ref = useRef<HTMLInputElement | null>(null);

    const handleUpload =
        (type: "poster" | "video") =>
        (
            el: { key: string; url: string },
            localUrl: string,
            fileType: string,
            uploadFunc: () => Promise<void>,
            ...params: any[]
        ) => {
            if (type === "poster") {
                poster.current = { ...el, uploadFunc };
                return;
            }
            const { uploadFunc: upload, ...p } = poster.current ?? {};
            onUploadPrepared(
                { ...el, poster: p },
                localUrl,
                fileType,
                () => Promise.all([upload?.(), uploadFunc()]),
                ...params
            );
            if (video?.key) {
                api.deleteUpload(video.key);
            }
            if (video?.poster?.key) {
                api.deleteUpload(video.poster.key);
            }
        };

    const { loading, uploadProgress, tempUrls, onDrop } = useUploader({
        fileType: "video",
        modelType: subject,
        id: subjectId,
        onPrepared: handleUpload("video"),
        multiple: false,
    });

    const { onDrop: onDropPoster } = useUploader({
        fileType: "video",
        modelType: subject,
        id: subjectId,
        onPrepared: handleUpload("poster"),
        multiple: false,
    });

    const handleProgress = (progress: { ratio: number }) => {
        if (toastId.current === null) {
            return;
        }
        const value = Math.round(progress.ratio * 100);
        const label = `Behandler video: ${value}%`;
        if (toastId.current) {
            if (value === 100) {
                toast.update(toastId.current as string, { render: "Uploader..." });
                return;
            }
            toast.update(toastId.current, { render: label });
            return;
        }
        toastId.current = toast(label, {
            autoClose: false,
            isLoading: true,
        });
    };

    const handleDrop = async (acceptedFiles: File[]) => {
        const [aFile] = acceptedFiles;
        if (aFile instanceof File) {
            try {
                setProcessing(true);
                if (aFile.type === "video/mp4") {
                    toastId.current = toast("Uploader...", { isLoading: true });
                    await onDrop([aFile]);
                    toast.update(toastId.current as string, {
                        render: "Din video er uploaded",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                    });
                    toastId.current = undefined;
                    return;
                }
                handleProgress({ ratio: 0 });
                const { video, poster, cleanUp } = await processVideo(aFile, handleProgress);
                toast.update(toastId.current as string, { render: "Uploader..." });
                await onDropPoster([
                    new File([poster.buffer], "poster.png", {
                        type: "image/png",
                    }),
                ]);
                await onDrop([
                    new File([video.buffer], "video.mp4", {
                        type: "video/mp4",
                    }),
                ]);
                toast.update(toastId.current as string, {
                    render: "Din video er uploaded",
                    type: "success",
                    isLoading: false,
                    autoClose: 2500,
                });
                cleanUp();
            } catch (err) {
                console.log("Error processing video", err);
                if (toastId.current) {
                    toast.update(toastId.current, {
                        render: "Hov, der skete en fejl. Prøv igen eller vælg en anden fil.",
                        type: "error",
                    });
                }
            } finally {
                setProcessing(false);
                toastId.current = undefined;
            }
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: {
            "video/mp4": [".mp4"],
            "video/quicktime": [".mov"],
        },
        maxFiles: 1,
    });

    const handleClickUpload = () => {
        ref.current?.click();
    };

    const handleDelete: ButtonProps["onClick"] = (e) => {
        e.stopPropagation();
        if (video?.key) {
            onDelete(video.key);
        }
    };

    const handleClickFullScreen =
        (url: string): ButtonProps["onClick"] =>
        (e) => {
            e.stopPropagation();
            NiceModal.show(VideoDialog, { src: url });
        };

    const videoSrc = tempUrls?.[0] ?? video?.url;

    return (
        <UploadContainer
            label={label}
            buttonLabel={t(`uploadVideo`)}
            onClickUpload={handleClickUpload}
            {...props}
        >
            <Styled
                {...getRootProps()}
                {...containerProps}
                {...(video?.url ? undefined : { border: "3px solid #322FDE" })}
            >
                <input {...getInputProps()} ref={ref} />
                {
                    <>
                        {processing ? (
                            <CircularProgress />
                        ) : videoSrc ? (
                            <Tooltip
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, -14],
                                                },
                                            },
                                        ],
                                    },
                                }}
                                title={
                                    <>
                                        <IconButton
                                            color={"info"}
                                            onClick={handleClickFullScreen(videoSrc)}
                                        >
                                            <FullscreenRounded />
                                        </IconButton>
                                        {video?.key && (
                                            <IconButton color={"info"} onClick={handleDelete}>
                                                <DeleteOutlineRounded />
                                            </IconButton>
                                        )}
                                    </>
                                }
                            >
                                <video
                                    style={{ objectFit: "cover" }}
                                    width={"100%"}
                                    height={"100%"}
                                    crossOrigin={""}
                                    muted
                                >
                                    <source src={videoSrc} type={"video/mp4"} />
                                </video>
                            </Tooltip>
                        ) : (
                            <VideocamRounded fontSize={"large"} htmlColor={"#8E8E93"} />
                        )}
                    </>
                }
            </Styled>
        </UploadContainer>
    );
};
