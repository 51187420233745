import { Box, Stack, TextFieldProps } from "@mui/material";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { Logo } from "../../Components/Logo/Logo";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { TextBlock } from "./TextBlock";
import { ContentBlock } from "./ContentBlock";
import { useForm, FormProvider, UseControllerProps } from "react-hook-form";
import { useProfile } from "../../Hooks/useProfile";
import { createId } from "../SyiPage/SyiPage";
import type { TImage } from "../../Utils/types";
import { useTranslation } from "react-i18next";
import { LanguageSelect } from "../../Components/LanguageSelect/LanguageSelect";
import isEmpty from "lodash.isempty";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";

export type TInput = {
    key: string;
    name: string;
    props?: TextFieldProps;
    helperText?: string;
    rules?: UseControllerProps["rules"];
};

const _steps = [
    {
        key: "start",
        title: "Velkommen til Spiritworld",
        description:
            "Vi har lavet en lille opsætningsguide så du let og hurtigt kan komme i gang med Holdbar.",
    },
    {
        key: "profile",
        title: "Min profil",
        description:
            "På Holdbar får du og dit team alle en personlig profil, med navn, profilbillede, hvilke sprog du/I taler og evt. en lille beskrivelse af hvem du/I er.",
    },
    {
        key: "company",
        title: "Min virksomhed",
        description:
            "Her opretter du din virksomhed, med din virksomheds logo samt virksomhedsoplysninger så Holdbar kan udbetale penge til dig og din virksomhed.",
    },
    /*
    {
        key: "locale",
    },
    {
        key: "team",
        title: "Mit team",
        description:
            "Her inviterer du dit team til Holdbar. De vil modtage en invitation på deres mail hvorfra de vil blive guidet ind på Holdbar.\n\nSkriv email på den person du vil invitere og definer hvilken rolle personen skal have.\n\nDu kan invitere lige så mange du ønsker. Du kan også tilføje flere brugere senere under indstillinger.",
    },*/
    {
        key: "success",
        title: "Færdig",
        description:
            "Det var det! Din profil, din virksomhed og dit team er nu klar til at bruge Holdbar.",
    },
];

export const mapUsersToRoles = (users: any[]) => {
    return users?.reduce((acc, { role, ...props }) => {
        const type = `${role}s`;
        return {
            ...acc,
            [type]: [...(acc?.[type] ?? []), { ...props }],
        };
    }, {});
};

const getDomain = (str: string) => {
    try {
        const url = new URL(normalizeWebsite(str));
        return url.host.replaceAll("www.", "").split(".")[0];
    } catch (err) {
        return null;
    }
};

const normalizeWebsite = (website: string) => {
    return website.startsWith("http") ? website : `https://${website}`;
};

export const OnBoardingPage = () => {
    const { t } = useTranslation();

    const uploadFuncRef = useRef<{ exec: () => Promise<void>; cleanUp: Function; id: string }[]>(
        []
    );
    const contentRef = useRef<null | HTMLElement>();
    const [wasInvited, setWasInvited] = useState<boolean | null>(null);

    const {
        params: { step },
    } = useMatch("welcome/:step") ?? { params: {} };
    const navigate = useNavigate();

    const [hasLoadedInitially, setHasLoadedInitially] = useState(false);

    const { updateCompany, firstLogin, me, company, updateMe, userinfo } = useProfile();

    const {
        register: registerMe,
        reset: resetMe,
        getValues: getMeValues,
        setValue: setMeValue,
        formState: formStateMe,
        handleSubmit: onSubmitMe,
        control: meControl,
        ...methods
    } = useForm({ reValidateMode: "onSubmit" });

    const {
        register: registerCompany,
        getValues: getCompanyValues,
        setValue: setCompanyValue,
        formState: formStateCompany,
        handleSubmit: onSubmitCompany,
        control: companyControl,
    } = useForm<Record<string, any>>({
        defaultValues: {
            defaultCurrency: "dkk",
            languages: ["da"],
        },
    });

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (company.data && !company.isLoading && isEmpty(formStateCompany.dirtyFields)) {
            for (const [key, value] of Object.entries(company?.data ?? {})) {
                setCompanyValue(key, value, { shouldTouch: true });
            }
            setHasLoadedInitially(true);
        }
    }, [company]);

    useEffect(() => {
        if (me.data && !me.isLoading && isEmpty(formStateMe.dirtyFields)) {
            for (const [key, value] of Object.entries(me?.data ?? {})) {
                setMeValue(key, value, { shouldTouch: true });
            }
            setHasLoadedInitially(true);
        }
    }, [me]);

    useEffect(() => {
        if (wasInvited === null && userinfo.data) {
            setWasInvited(Boolean(userinfo.data?.org));
        }
    }, [userinfo.data]);

    const steps = useMemo(() => {
        return _steps.filter((el) =>
            wasInvited ? !["company", "team", "locale"].includes(el.key) : true
        );
    }, [wasInvited]);

    const progress = useMemo(() => {
        return Math.round(((currentStep + 1) / steps.length) * 100);
    }, [steps, currentStep]);

    useEffect(() => {
        userinfo.refetch();
    }, []);

    useEffect(() => {
        if (step) {
            const foundIndex = steps.findIndex((el) => el.key === step);
            setCurrentStep(foundIndex);
        }
    }, [step, steps]);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current?.scrollTo(0, 0);
        }
    }, [currentStep]);

    useEffect(() => {
        if (userinfo.data?.email) {
            setMeValue("email", userinfo.data?.email, {
                shouldValidate: true,
                shouldDirty: false,
            });
            setMeValue("id", userinfo.data?.sub, {
                shouldValidate: true,
                shouldDirty: false,
            });
        }
    }, [userinfo.data]);

    const handleNext = async () => {
        if (currentStep + 1 < steps.length) {
            setCurrentStep((p) => p + 1);
            return navigate(`${steps[currentStep + 1].key}`, { replace: true });
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep((p) => p - 1);
            navigate(`${steps[currentStep - 1].key}`, { replace: true });
        }
    };

    const handleSubmitCompany = async (me: { name: string; email: string; id: string }) => {
        const companyFormData = getCompanyValues();
        delete companyFormData["admins"];
        delete companyFormData["guides"];

        const { ...companyData } = companyFormData;

        if (userinfo.data) {
            const payload = {
                ...companyData,
                domains: null,
                admins: [
                    {
                        ...me,
                        email: me.email.toLowerCase(),
                    },
                ],
            };
            await updateCompany.mutateAsync(payload);
        }
    };

    const handleSubmit = async (users: any[]) => {
        await Promise.allSettled([
            ...uploadFuncRef.current.map(async (el) => {
                try {
                    return await el.exec();
                } catch (err) {
                    return console.log("Error uploading image", err);
                }
            }),
        ]);
        const mePayload = getMeValues();
        const proms: Promise<unknown>[] = [updateMe.mutateAsync(mePayload)];
        if (!wasInvited) {
            proms.push(
                handleSubmitCompany({
                    email: mePayload.email,
                    name: mePayload.name,
                    id: createId(),
                })
            );
        }
        return Promise.all(proms).then(() => handleNext());
    };

    const handleUploadPrepared =
        (modelType: "company" | "me") =>
        (
            { key, url }: { [k: string]: string },
            localUrl: string,
            fileType: "logo" | "profile",
            uploadFunc: () => Promise<void>,
            cleanUp: () => void
        ) => {
            const setter = modelType === "company" ? setCompanyValue : setMeValue;
            const id = createId();
            setter(
                "pictures",
                { [fileType]: { url, localUrl, key, id } },
                {
                    shouldDirty: true,
                }
            );
            uploadFuncRef.current.push({
                exec: () => {
                    setter("pictures", { [fileType]: { url, key } });
                    return uploadFunc();
                },
                id,
                cleanUp: () => {
                    setter("pictures", { [fileType]: null });
                    cleanUp();
                },
            });
        };

    const handleDeleteImage =
        (prop: "logo" | "profile", modelType: "company" | "me") =>
        async ({ id }: TImage) => {
            uploadFuncRef.current = uploadFuncRef.current.filter((el: any) => {
                if (el.id === id) {
                    el.cleanUp();
                    return true;
                }
            });
        };

    return (
        <Box
            sx={{
                background: 'url("/graphics/login-bg.jpeg") no-repeat',
                backgroundSize: "cover",
            }}
        >
            <Box
                sx={{
                    height: "100vh",
                    width: "100vw",
                    background:
                        "linear-gradient(315deg, rgba(91,18,149,1) 0%, rgba(27,39,125, 0.8) 100%)",
                }}
                p={{ xs: 2, md: 10 }}
                display={"flex"}
            >
                <Logo white height={48} position={"absolute"} left={80} top={80} />

                <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={16}
                >
                    <TextBlock
                        title={t(`onboarding.${steps[currentStep]?.key}.leftTitle`)}
                        description={t(`onboarding.${steps[currentStep]?.key}.leftDescription`)}
                    >
                        {currentStep === 0 && (
                            <LanguageSelect
                                sx={{
                                    mt: 6,
                                    backgroundColor: "rgba(34,25,99, 1)",
                                    borderColor: "transparent",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "rgba(34,25,99, 0.9)",
                                    },
                                }}
                            />
                        )}
                    </TextBlock>
                    <ContentBlock ref={contentRef} progress={progress}>
                        {hasLoadedInitially ? (
                            <FormProvider
                                {...{
                                    ...methods,
                                    control: meControl,
                                    handleSubmit: onSubmitMe,
                                    getValues: getMeValues,
                                    setValue: setMeValue,
                                    reset: resetMe,
                                    register: registerMe,
                                    formState: formStateMe,
                                }}
                            >
                                <Outlet
                                    context={{
                                        currentStep,
                                        companyControl,
                                        formStateMe,
                                        formStateCompany,
                                        registerMe,
                                        registerCompany,
                                        getMeValues,
                                        getCompanyValues,
                                        onSubmitMe,
                                        onSubmitCompany,
                                        wasInvited,
                                        handleSubmit,
                                        handleBack,
                                        handleNext,
                                        handleDeleteImage,
                                        handleUploadPrepared,
                                    }}
                                />
                            </FormProvider>
                        ) : (
                            <SimpleSkeleton />
                        )}
                    </ContentBlock>
                </Stack>
            </Box>
        </Box>
    );
};
