import { WeekdayStr } from 'rrule'

export const weekdays: WeekdayStr[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

export const durationOptions = [
    10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 120, 150, 180,
]

export const paddingOptions = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115,
    120,
]

export const languagesOptions: { [key: string]: any } = {
    da: {
        label: 'Dansk',
        emoji: '🇩🇰',
        icon: '/flags/da.png',
        locale: 'da',
    },
    en: {
        label: 'Engelsk',
        emoji: '🇬🇧',
        icon: '/flags/en.png',
        locale: 'enGB',
    } /*,
    no: {
        label: 'Norsk',
        emoji: '🇳🇴',
        icon: '/flags/no.png',
        locale: 'nn',
    },
    sv: {
        label: 'Engelsk',
        emoji: '🇸🇪',
        icon: '/flags/sv.png',
        locale: 'sv',
    },
    de: {
        label: 'Tysk',
        emoji: '🇩🇪',
        icon: '/flags/de.png',
        locale: 'de',
    }*/,
}

export const currencyOptions: { [key: string]: any } = {
    dkk: {
        emoji: '🇩🇰',
        icon: '/flags/da.png',
        label: 'DKK',
    },
    gbp: {
        emoji: '🇬🇧',
        icon: '/flags/en.png',
        label: 'GBP (£)',
    },
    usd: {
        emoji: '🇺🇸',
        icon: '/flags/us.png',
        label: 'USD ($)',
    } /*,
    nok: {
        emoji: '🇳🇴',
        icon: '/flags/no.png',
        label: 'NOK',
    },
    sek: {
        emoji: '🇸🇪',
        icon: '/flags/sv.png',
        label: 'SEK',
    }*/,
    eur: {
        emoji: '🇪🇺',
        icon: '/flags/eu.png',
        label: 'EUR (€)',
    },
}
