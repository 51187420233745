import {
    Avatar,
    Box,
    BoxProps,
    Button,
    Dialog,
    Divider,
    IconButton,
    Link,
    Rating,
    Stack,
    StackProps,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Controller, useController, useForm } from "react-hook-form";
import { ProgressButton } from "../Components/ProgressButton/ProgressButton";
import { Header } from "./Header";
import { StarOutlineRounded, StarRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Picture } from "../Components/Picture/Picture";
import { useReviews } from "../Hooks/useReviews";
import { createId } from "../Utils/helpers";
import { toast } from "react-toastify";

export type ReviewDialogProps = {
    subject: "service";
    subjectId: string;
    picture?: string;
    name?: string;
};

type FormData = {
    content: string;
    customer: {
        name: string;
    };
    rating: number;
};

const defaultValues = {
    customer: {
        name: "",
    },
    content: "",
    rating: -1,
};
export const ReviewDialog = NiceModal.create<ReviewDialogProps>(
    ({ subject, subjectId, name, picture }) => {
        const modal = useModal();

        const [editingId, setEditingId] = useState<string | null>();

        const {
            control,
            getValues,
            reset,
            handleSubmit,
            formState: { errors },
        } = useForm<FormData>({
            defaultValues,
        });

        const { field: ratingField } = useController({
            name: "rating",
            control,
            rules: { required: true, validate: (value) => value >= 0 },
        });

        const { reviews, review, deleteReview, updateReview } = useReviews(
            subject,
            subjectId,
            editingId ?? undefined
        );

        useEffect(() => {
            if (editingId && review.data) {
                reset(review.data);
            } else if (editingId === null) {
                reset(defaultValues);
            }
        }, [editingId, review.data, reset]);

        const onSubmit = async () => {
            if (ratingField.value === -1) {
                toast.error("Vælg antal stjerner");
                return Promise.reject();
            }
            await updateReview.mutateAsync({
                id: editingId ?? createId(),
                publishedDate: new Date().toISOString(),
                ...getValues(),
                subjects: [{ type: subject, id: subjectId }],
            });
            setEditingId(null);
            reset(defaultValues);
        };

        const handleDelete = async () => {
            if (editingId) {
                setEditingId(null);
                await deleteReview.mutateAsync(editingId);
            }
        };
        const handleClose = () => modal.remove();

        const handleToggleEdit = (id: string) => {
            setEditingId((p) => (p === id ? null : id));
        };

        return (
            <Dialog open={modal.visible} fullWidth maxWidth={"sm"}>
                <Header
                    pl={{ xs: 2, lg: 3 }}
                    title={editingId ? "Rediger omtale" : "Tilføj omtale"}
                    onClose={handleClose}
                />
                <Box p={3} sx={{ overflowY: "auto" }}>
                    <Typography>
                        Her kan du tilføje omtaler (også kaldt: udtalelser eller testimonials) fra
                        tidligere kunder. Omtalen vises på din ydelses side.
                    </Typography>
                    <Stack mt={3} justifyContent={"center"} alignItems={"center"}>
                        {picture && (
                            <Picture
                                mb={2}
                                borderRadius={1}
                                aspectRatio={1}
                                width={64}
                                height={64}
                                source={picture}
                            />
                        )}
                        <Typography mb={2} variant={"h5"}>
                            {name}
                        </Typography>
                        <RatingControl
                            error={Boolean(errors["rating"])}
                            rating={ratingField.value}
                            onChange={ratingField.onChange}
                        />
                    </Stack>
                    <Controller
                        rules={{
                            validate: (val) => val.length > 0,
                        }}
                        control={control}
                        name="customer.name"
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={Boolean(errors["customer"])}
                                fullWidth
                                sx={{ mt: 2 }}
                                label={"Kundens navn"}
                                value={value ?? ""}
                                onChange={onChange}
                            />
                        )}
                    />
                    <Controller
                        rules={{
                            validate: (val) => val.length > 0,
                        }}
                        control={control}
                        name="content"
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={Boolean(errors["content"])}
                                fullWidth
                                sx={{ mt: 2 }}
                                label={"Omtale"}
                                multiline
                                minRows={3}
                                value={value ?? ""}
                                onChange={onChange}
                            />
                        )}
                    />
                    <Stack
                        direction={"row"}
                        justifyContent={editingId ? "space-between" : "end"}
                        alignItems={"center"}
                        spacing={1}
                        mt={3}
                    >
                        {editingId && review.data && (
                            <ProgressButton
                                label={"Slet"}
                                size={"large"}
                                variant={"outlined"}
                                color={"secondary"}
                                onClick={handleDelete}
                            />
                        )}
                        <Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
                            {editingId && (
                                <Button
                                    size={"large"}
                                    onClick={() => setEditingId(null)}
                                    variant={"outlined"}
                                    color={"secondary"}
                                >
                                    Annuller
                                </Button>
                            )}
                            <ProgressButton
                                onClick={handleSubmit(onSubmit, () => Promise.reject())}
                                label={editingId ? "Gem" : "Tilføj omtale"}
                                size={"large"}
                                variant={"contained"}
                            />
                        </Stack>
                    </Stack>
                    {reviews.data && reviews.data.length > 0 && (
                        <>
                            <Typography mt={3} variant={"h5"}>
                                Omtaler tilknyttet {name}
                            </Typography>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Stack spacing={3}>
                                {reviews.data?.map((review) => (
                                    <Review
                                        key={review.id}
                                        name={review.customer.name}
                                        content={review.content}
                                        label={format(
                                            new Date(review.publishedDate),
                                            "dd.MM.yyy, hh:mm"
                                        )}
                                        editing={editingId === review.id}
                                        rating={review.rating}
                                        onToggleEdit={() => handleToggleEdit(review.id)}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Box>
            </Dialog>
        );
    }
);

const steps = [
    [1, "Forfærdelig"],
    [2, "Dårlig"],
    [3, "Okay"],
    [4, "God"],
    [5, "Fremragende"],
] as [number, string][];

type RatingProps = {
    rating: number;
    error?: boolean;
    onChange: (newRating: number) => void;
} & StackProps;
const RatingControl = ({ rating, error, onChange, ...props }: RatingProps) => {
    return (
        <Stack
            direction={"row"}
            sx={{
                border: "1px solid transparent",
                ...(error && {
                    borderColor: "error.main",
                    borderRadius: 1,
                }),
            }}
            {...props}
        >
            {steps.map(([step, label]) => (
                <Star
                    key={`rating-step-${step}`}
                    label={label}
                    enabled={rating >= step}
                    onClick={() => onChange(step)}
                />
            ))}
        </Stack>
    );
};

const Star = ({
    enabled,
    label,
    onClick,
}: {
    enabled: boolean;
    label: string;
    onClick?: (enabled: boolean) => void;
}) => {
    return (
        <Tooltip title={label}>
            <IconButton
                size={"small"}
                onClick={(evt) => {
                    evt.stopPropagation();
                    onClick?.(!enabled);
                }}
            >
                {enabled ? (
                    <StarRounded fontSize={"large"} htmlColor={"rgba(255, 149, 0, 1)"} />
                ) : (
                    <StarOutlineRounded fontSize={"large"} />
                )}
            </IconButton>
        </Tooltip>
    );
};

type ReviewProps = {
    picture?: string;
    name: string;
    content: string;
    label: string;
    rating: number;
    editing: boolean;
    onToggleEdit: () => void;
} & BoxProps;
const Review = ({
    name,
    picture,
    content,
    label,
    onToggleEdit,
    editing,
    rating,
    ...props
}: ReviewProps) => {
    return (
        <Box {...props}>
            <Stack direction={"row"} alignItems={"start"} justifyContent={"space-between"} mb={1}>
                <Box display={"flex"} alignItems={"center"}>
                    <Avatar
                        sx={{ width: 40, height: 40 }}
                        src={picture}
                        children={picture ? undefined : name.substring(0, 1)}
                    />
                    <Box flexGrow={1} ml={1}>
                        <Typography variant={"h5"}>{name}</Typography>
                        <Typography variant={"body2"} color={"grey.500"}>
                            {label}
                        </Typography>
                    </Box>
                </Box>
                <Link
                    onClick={(evt) => {
                        evt.preventDefault();
                        onToggleEdit();
                    }}
                    color={"text.primary"}
                    underline={"hover"}
                >
                    {editing ? "annuller" : "rediger"}
                </Link>
            </Stack>
            <Rating size={"small"} value={rating} readOnly />
            <Typography mt={1}>{content}</Typography>
        </Box>
    );
};
