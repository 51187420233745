import { BookingCancelledIcon, BookingMovedIcon, BookingsIcon, GiftcardIcon } from "../../Icons";
import { Card, Grid, Stack, StackProps, Typography, Link as MuiLink } from "@mui/material";
import { IconLabel } from "../IconLabel/IconLabel";
import { AccessTimeRounded, ArticleRounded, PhoneOutlined } from "@mui/icons-material";
import { formatDistanceToNow } from "date-fns";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { formatMoney } from "../VariantSelect/VariantSelect";
import { renderDate, renderTime } from "@hiddengemgroup/utils-date";
import React from "react";
import { type Activity, Subject } from "../../Hooks/useActivities";
import { clamp, ellipsisStyle } from "../../Utils/theme";

const columns = [
    {
        key: "none",
        props: {
            xs: 1,
        },
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
        value: (el: Activity) =>
            el.event === "bookingMade" ? (
                <BookingsIcon />
            ) : el.event === "bookingCancelled" ? (
                <BookingCancelledIcon />
            ) : el.event === "bookingMoved" ? (
                <BookingMovedIcon />
            ) : el.event === "leadSubmitted" ? (
                <PhoneOutlined />
            ) : el.event === "offerAccepted" || el.event === "offerProposed" ? (
                <ArticleRounded />
            ) : (
                <GiftcardIcon />
            ),
    },
    {
        key: "name",
        props: {
            xs: 5,
        },
        value: (activity: Activity) => (
            <>
                <Typography fontWeight={"500"} sx={{ ...ellipsisStyle, ...clamp(2) }}>
                    {activity.event === "bookingMade" || activity.event === "bookingCancelled"
                        ? activity.metadata?.name
                        : activity.event === "bookingMoved"
                        ? `Aftale flyttet`
                        : activity.event === "voucherBought"
                        ? "Gavekort købt"
                        : activity.event === "offerAccepted"
                        ? "Tilbud købt"
                        : activity.event === "offerProposed"
                        ? "Tilbud sendt"
                        : "Lead registreret"}
                </Typography>
                <IconLabel
                    variant={"body2"}
                    fontSize={"0.75em"}
                    Icon={AccessTimeRounded}
                    label={formatDistanceToNow(new Date(activity?.created)) + " siden"}
                />
            </>
        ),
    },
    {
        key: "price",
        props: {
            xs: 3,
        },
        value: (
            activity: Activity,
            t: TranslateFunction,
            onClick: (id: string, subject: ActivitySubject) => void
        ) => {
            if (activity.event === "bookingCancelled") {
                return "Aflyst";
            }
            if (
                activity.event === "bookingMoved" &&
                activity.metadata.movedFromStartDateTime &&
                activity.metadata.movedFromEndDateTime
            ) {
                return `${renderDate(activity.metadata.movedFromStartDateTime)}\n${renderTime(
                    activity.metadata.movedFromStartDateTime,
                    activity.metadata.movedFromEndDateTime
                )}`;
            }
            if (activity.metadata?.offerId) {
                return (
                    <MuiLink
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick(activity.metadata.offerId as string, "offer");
                        }}
                    >
                        Fra tilbud
                    </MuiLink>
                );
            }
            return (
                activity.metadata?.price &&
                formatMoney(t)({
                    value: activity.metadata.price.amount,
                    nativeCurrency: activity.metadata.price.currency,
                })
            );
        },
    },
    {
        key: "time",
        props: {
            xs: 3,
        },
        value: (activity: Activity, t: TranslateFunction) =>
            activity.metadata?.startDateTime &&
            `${renderDate(activity.metadata.startDateTime)}\n${renderTime(
                activity.metadata.startDateTime,
                activity.metadata.endDateTime
            )}`,
    },
    /*{
        key: "status",
        props: {
            xs: 1.5,
        },
        value: (el: Activity) => (
            <span>
                <StatusChip status={el.event === "bookingCancelled" ? "cancelled" : "active"} />
            </span>
        ),
    },*/
];

const ActivityListItem = ({
    event,
    subject,
    metadata,
    id,
    onClick,
    created,
}: Activity & {
    onClick: (id: string, subject: ActivitySubject) => void;
}) => {
    const { t } = useTranslate("utils");

    return (
        <Grid
            container
            component={Card}
            p={1.5}
            alignItems={"center"}
            sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgb(252,252,252)" },
            }}
            onClick={() => onClick(id, getActivitySubject(event) as ActivitySubject)}
        >
            {columns.map((el) => {
                return (
                    <Grid
                        whiteSpace={"pre-wrap"}
                        key={`${id}-${el.key}`}
                        item
                        {...el.props}
                        {...el.valueProps}
                    >
                        {el.value?.(
                            { event, subject, metadata, created, id } as any,
                            t,
                            (subjectId: string, subject: ActivitySubject) =>
                                onClick(subjectId, subject)
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export type ActivitySubject = "booking" | "voucher" | "offer" | "lead";

const getActivitySubject = (type: Activity["event"]): ActivitySubject | undefined => {
    switch (type as any) {
        case "bookingMade":
            return "booking";
        case "bookingMoved":
            return "booking";
        case "bookingCancelled":
            return "booking";
        case "leadSubmitted":
            return "lead";
        case "offerAccepted":
            return "offer";
        case "voucherBought":
            return "voucher";
    }
};

export const Activities = ({
    activities,
    onClick,
    ...props
}: Omit<StackProps, "onClick"> & {
    onClick: (id: string, subject: ActivitySubject) => void;
    activities: Activity[];
}) => {
    return (
        <Stack spacing={2} {...props}>
            {activities
                ?.sort((a, b) => Number(new Date(b.created)) - Number(new Date(a.created)))
                ?.map((el) => {
                    return <ActivityListItem onClick={onClick} {...el} key={el.created} />;
                })}
        </Stack>
    );
};
