import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useMemo } from "react";
import { TUser, useUsers } from "./useUsers";
import { createId } from "../Utils/helpers";

export type Author = {
    id: string;
    name: string;
    picture: string | undefined;
};

export type Record = {
    id: string;
    authorId: string;
    subject: "customer" | "lead";
    subjectId: string;
    metadata?: {
        sessionId?: string;
        serviceId?: string;
        eventId?: string;
        bookingId?: string;
    };
    text: string;
};

export type EnrichedRecord = {
    author: Author;
} & Omit<Record, "authorId">;

export type CreateRecordPayload = Pick<
    Record,
    "authorId" | "subject" | "subjectId" | "metadata" | "text"
>;

type WithTimestamps = {
    created: string;
    updated?: string;
};

const enrichAuthor = (userId: string, users: TUser[] | undefined) => {
    const { name, pictures } = users?.find((el) => el.id === userId) ?? {};
    if (name) {
        return {
            id: userId,
            name,
            picture: pictures?.profile?.url,
        };
    }
};

export const useRecords = (subject: Record["subject"], id: string) => {
    const queryClient = useQueryClient();

    const { users } = useUsers();

    const RecordsQueryKey = ["records", subject, id];

    const records = useQuery<Record[]>(
        RecordsQueryKey,
        async () => {
            await queryClient.cancelQueries(RecordsQueryKey);
            return api.getRecordsForSubject(subject, id);
        },
        {
            enabled: Boolean(subject && id),
        }
    );

    const enrichedRecords = useMemo(() => {
        if (records.data) {
            return records.data.map(({ authorId, ...el }) => ({
                ...el,
                author: enrichAuthor(authorId, users.data),
            }));
        }
    }, [records, users]);

    const updateRecord = useMutation(
        ({
            id,
            ...payload
        }: { id: string; subject: Record["subject"]; subjectId: string } & Partial<
            Omit<Record, "id" | "subject" | "subjectId">
        >) => {
            return api.updateRecord(id, { ...payload });
        },
        {
            onMutate: async ({ id, subjectId, subject, ...payload }) => {
                const queryKey = ["records", subject, subjectId];

                await queryClient.cancelQueries(queryKey);

                const previous = queryClient.getQueryData<Record[]>(queryKey);

                queryClient.setQueryData<Record[]>(queryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<Record>(
                        ["records", variables.subject, variables.subjectId],
                        context.previous
                    );
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries([
                        "records",
                        variables.subject,
                        variables.subjectId,
                    ]),
                ]);
            },
        }
    );

    const createRecord = useMutation(
        ({ ...payload }: CreateRecordPayload) => {
            return api.createRecord({ ...payload });
        },
        {
            onMutate: async ({ ...payload }) => {
                await queryClient.cancelQueries(RecordsQueryKey);

                const previous = queryClient.getQueryData<Record[]>(RecordsQueryKey);

                queryClient.setQueryData<(Record & WithTimestamps)[]>(RecordsQueryKey, (prev) => {
                    return [
                        { ...payload, id: createId(), created: new Date().toISOString() },
                        ...(prev ?? []),
                    ];
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<Record>(RecordsQueryKey, context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([queryClient.invalidateQueries(RecordsQueryKey)]);
            },
        }
    );

    const deleteRecord = useMutation(({ id }: { id: string }) => api.deleteRecord(id), {
        onMutate: async ({ id }) => {
            await queryClient.cancelQueries(RecordsQueryKey);

            const previous = queryClient.getQueryData<Record[]>(RecordsQueryKey);

            queryClient.setQueryData<Record[]>(RecordsQueryKey, (prev) => {
                return prev?.filter((el) => el.id !== id) ?? [];
            });

            return { previous };
        },
        onError: (err, { id }, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<Record>(RecordsQueryKey, context.previous);
            }
        },
        onSettled: async (data, err, { id }, context) => {
            await queryClient.invalidateQueries(RecordsQueryKey);
        },
    });

    return {
        records,
        enrichedRecords,
        deleteRecord,
        createRecord,
        updateRecord,
    };
};
