import { useEffect, useState } from "react";
import { da } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { languagesOptions } from "../Utils/config";
import { setDefaultOptions } from "date-fns";
import { useLocalStorage } from "./useLocalStorage";

export const useLocale = () => {
    const [_locale, setLocale] = useState(da);

    const { i18n } = useTranslation();

    const [preferredLanguage] = useLocalStorage("preferredLanguage", i18n.language);

    useEffect(() => {
        import("date-fns/locale").then((locales) => {
            const { locale = "da" } =
                (languagesOptions[i18n.language.split("-")[0]] as {
                    locale: string;
                }) ?? {};
            if (locale && locales[locale]) {
                setLocale(() => locales[locale] as Locale);
                setDefaultOptions({ locale: locales[locale] as Locale });
            }
        });
    }, [i18n.language]);

    useEffect(() => {
        if (preferredLanguage) {
            i18n.changeLanguage(preferredLanguage);
        }
    }, []);

    return {
        locale: _locale,
    };
};
