import {
    Box, Button,
    Dialog, Stack, Typography,
} from "@mui/material";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {useTranslate} from "../Hooks/useTranslate";
import {CloseButton} from "../Components/CloseButton/CloseButton";

export const PendingOnboardingDialog = NiceModal.create(() => {

    const { t } = useTranslate('dialogs.pendingOnboarding')

    const modal = useModal()

    const handleClose = () => {
        modal.reject();
        modal.hide();
    }

    const handleOk = () => {
        modal.resolve();
        modal.hide();
    }

    return (
        <Dialog open={modal.visible} onClose={handleClose} PaperProps={{ sx: { maxWidth: 800}}}>
            <CloseButton onClick={handleClose} sx={{ position: 'absolute', top: 24, right: 24}}/>
            <Box p={8} textAlign={'center'}>
                <Typography display={'inline-flex'} alignItems={'center'} mb={2}>{t('headline')}</Typography>
                <Typography variant={'h4'}>
                    {t('title')}
                </Typography>
                <Stack direction={'row'} spacing={2} justifyContent={'center'} mt={4}>
                    <Button
                        onClick={handleOk}
                        size={'large'}
                        sx={{whiteSpace: 'nowrap'}}
                        variant={'contained'}
                    >{t('actions.primary') ?? t('ok')}</Button>
                </Stack>
            </Box>
        </Dialog>
    )
});
