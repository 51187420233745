import { Box, Stack, Typography, Card, Button, SelectProps, Divider, Grid } from "@mui/material";
import React, { MouseEventHandler, useState } from "react";
import { useController, useFormContext, useFormState, useWatch } from "react-hook-form";
import { ProfileUpload } from "../../Components/ProfileUpload/ProfileUpload";
import { useOutletContext } from "react-router-dom";
import { mapSelectedLanguages } from "../../Pages/Services/ServiceSyiSections/ServiceSyiSectionDescription";
import { SyiSection } from "../../Pages/SyiPage/SyiSection";
import { InputWithConfirm } from "../../Components/InputWithConfirm/InputWithConfirm";
import { CancelRounded, CheckRounded } from "@mui/icons-material";
import { useProfile } from "../../Hooks/useProfile";
import { useConfirm } from "../../Hooks/useConfirm";
import { TranslatableGroup } from "../../Components/TranslatableGroup/TranslatableGroup";
import { LanguageChips } from "../../Components/LanguageChips/LanguageChips";
import { languagesOptions } from "../../Utils/config";
import { useTranslate } from "../../Hooks/useTranslate";
import { DomainPopover } from "../../Pages/OnBoarding/OnBoardingCompanySection";
import { CountrySelect } from "../../Components/CountrySelect/CountrySelect";
import { validUrl } from "../../Utils/helpers";
import { CoverImageUpload } from "../../Components/CoverImageUpload/CoverImageUpload";
import { CurrencySelect } from "../../Components/CurrencySelect/CurrencySelect";
import { useDebounce } from "../../Hooks/useDebounce";
import { CompanyProfilePreviewer, WithPreview } from "../../Components/Previewer/Previewer";

export const SettingsCompanySection = ({
    context,
}: {
    context?: { submitCompany?: () => void };
}) => {
    const { submitCompany } = useOutletContext<any>() ?? context ?? {};

    const { t } = useTranslate("settings.company");

    const debounce = useDebounce(1000);

    const { setError, clearErrors, getValues } = useFormContext();

    const {
        userinfo: { data: userinfo },
    } = useProfile();

    const [coverImage, logoImage] = useWatch({
        name: ["company.pictures.cover", "company.pictures.logo"],
    });

    const { field: cover } = useController({ name: "company.pictures.cover" });
    const { field: logo } = useController({ name: "company.pictures.logo" });
    const { field: domains } = useController({ name: "company.domains" });
    const { field: country } = useController({
        name: "company.location.country",
        defaultValue: "DK",
    });
    const { field: languages } = useController({ name: "company.languages", defaultValue: [] });
    const { field: currency } = useController({ name: "company.defaultCurrency" });
    const { errors } = useFormState();

    const { dirty, handleSave, handleChangeLocalized, handleCancel } = useConfirm(
        "company.description",
        submitCompany
    );
    const handleUploadPrepared =
        (parentKey: "profile" | "cover" | "video" | "logo") =>
        async (
            { key, url }: { [k: string]: string },
            localUrl: string,
            fileType: "logo" | "profile",
            uploadFunc: () => Promise<void>
        ) => {
            await uploadFunc();
            if (parentKey === "logo") {
                logo.onChange({ key, url });
            } else {
                cover.onChange({ key, url });
            }
            debounce(submitCompany);
        };

    const handleChangeWebsite = (value: string) => {
        if (validUrl(value)) {
            clearErrors("website");
            return submitCompany();
        }
        setError("website", { message: t("errors.validUrl") });
    };

    const handleToggleLanguage = (keys: string[]) => {
        languages.onChange(keys);
        debounce(submitCompany);
    };

    const handleToggleCurrency: SelectProps["onChange"] = (e) => {
        currency.onChange(e);
        debounce(submitCompany);
    };

    const changeCountry: SelectProps["onChange"] = (evt) => {
        country.onChange(evt);
        debounce(submitCompany);
    };

    return (
        <WithPreview
            render={(onToggle, desktopEnabled) => (
                <CompanyProfilePreviewer desktopEnabled={desktopEnabled} onToggle={onToggle} />
            )}
        >
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Typography mb={4}>{t("description")}</Typography>

            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <Stack spacing={3} divider={<Divider />} component={"form"} maxWidth={680}>
                    <Box>
                        <CoverImageUpload
                            onUploadPrepared={handleUploadPrepared("cover")}
                            image={coverImage ?? cover.value}
                            subject={"company"}
                            subjectId={userinfo?.org}
                        />

                        <ProfileUpload
                            mt={2}
                            fileType={"logo"}
                            onUploadPrepared={handleUploadPrepared("logo")}
                            label={t("logo.helperText")}
                            media={logoImage ?? logo.value}
                            subjectId={userinfo?.org}
                            subject={"company"}
                        />
                    </Box>

                    <SyiSection title={t("detailsTitle")}>
                        <Grid container spacing={3} component={"form"} columns={2} wrap={"wrap"}>
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("companyName")}
                                type={"text"}
                                name={"company.name"}
                                onCommitChange={submitCompany}
                            />
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("website")}
                                type={"url"}
                                error={Boolean(errors["website"])}
                                helperText={errors["website"]?.message as string}
                                name={"company.website"}
                                onCommitChange={handleChangeWebsite}
                            />
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("cvrNr")}
                                type={"text"}
                                name={"company.cvrNr"}
                                onCommitChange={submitCompany}
                            />
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("email")}
                                type={"email"}
                                name={"company.companyEmail"}
                                onCommitChange={submitCompany}
                            />
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("phone")}
                                type={"phone"}
                                name={"company.companyPhone"}
                                onCommitChange={submitCompany}
                            />
                        </Grid>
                    </SyiSection>

                    <SyiSection title={t("address.title")}>
                        <Grid container spacing={3} component={"form"} columns={2}>
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("address", "utils.generic")}
                                name={"company.location.address"}
                                onCommitChange={submitCompany}
                            />
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("zipCode", "utils.generic")}
                                name={"company.location.zipCode"}
                                type={"number"}
                                onCommitChange={submitCompany}
                            />
                            <InputWithConfirm
                                containerProps={{
                                    component: Grid,
                                    xs: 1,
                                    item: true,
                                }}
                                fullWidth
                                label={t("city", "utils.generic")}
                                name={"company.location.city"}
                                onCommitChange={submitCompany}
                            />
                            <Grid item xs={1}>
                                <CountrySelect
                                    fullWidth
                                    value={country.value}
                                    onChange={changeCountry}
                                    sx={{ width: "100%", maxWidth: 320 }}
                                />
                            </Grid>
                        </Grid>
                    </SyiSection>
                    <SyiSection title={t("languages.title")} mt={4}>
                        <LanguageChips
                            onChange={handleToggleLanguage}
                            languages={languages.value}
                            multiple={true}
                        />
                    </SyiSection>

                    <SyiSection title={t("defaultCurrency.title")} mt={4}>
                        <CurrencySelect
                            value={currency.value}
                            onChange={handleToggleCurrency}
                            sx={{ minWidth: 320 }}
                        />
                    </SyiSection>

                    <SyiSection title={t("about.title")} mt={4}>
                        <TranslatableGroup
                            sx={{ maxWidth: 680 }}
                            langs={mapSelectedLanguages(languages.value, languagesOptions)}
                            propKey={"company.description"}
                            onUpdate={handleChangeLocalized}
                            input={{
                                type: "editor",
                                placeholder: t("about.inputPlaceholder"),
                            }}
                        />
                        {dirty && (
                            <Stack mt={1} direction={"row"} spacing={1} alignItems={"center"}>
                                <Button
                                    startIcon={<CheckRounded />}
                                    color={"success"}
                                    onClick={handleSave}
                                >
                                    {t("save", "buttons")}
                                </Button>
                                <Button
                                    startIcon={<CancelRounded />}
                                    color={"error"}
                                    onClick={handleCancel}
                                >
                                    {t("undo", "buttons")}
                                </Button>
                            </Stack>
                        )}
                    </SyiSection>
                </Stack>
            </Stack>

            {/*<SyiSection title={t('deleteCompany.title')} mt={4}>

                <Link fontSize={'0.88em'} color={'error'}>{t('deleteCompany.buttonLabel')}</Link>

            </SyiSection>*/}
        </WithPreview>
    );
};

const DomainWidget = ({
    onSubmit,
    domain,
}: {
    domain?: string;
    onSubmit: (val: string) => void;
}) => {
    const [domainAnchor, setDomainAnchor] = useState<HTMLElement | null>(null);

    const { t } = useTranslate("settings.company");

    const { companyProfileUrl } = useProfile();

    const handleClosePopover = () => setDomainAnchor(null);

    const handleChangeDomain: MouseEventHandler<HTMLButtonElement> = (e) => {
        setDomainAnchor(e.currentTarget);
    };

    const handleSubmitDomain = (val: string) => {
        onSubmit(val);
        handleClosePopover();
    };

    return (
        <Box component={Card} width={"100%"} maxWidth={420} position={"relative"}>
            <Typography variant={"h5"} fontWeight={700} mb={1}>
                {t("domain.title")}
            </Typography>
            <a href={companyProfileUrl()} target={"_blank"}>
                <Box
                    p={2}
                    pb={1}
                    pt={1}
                    mr={1}
                    component={Typography}
                    borderRadius={1}
                    border={"1px solid #C7C7CC"}
                    display={"inline-block"}
                >
                    {companyProfileUrl()}
                </Box>
            </a>
            <Button sx={{ mt: 1 }} size={"small"} onClick={handleChangeDomain}>
                Skift domæne
            </Button>
            {domainAnchor && (
                <DomainPopover
                    anchorEl={domainAnchor}
                    domain={domain}
                    onClose={handleClosePopover}
                    onSubmit={handleSubmitDomain}
                />
            )}
        </Box>
    );
};
