import {Box, Typography, BoxProps } from "@mui/material";
import {ErrorRounded} from "@mui/icons-material";

export const ErrorLabel = ({ label, ...props }: BoxProps & { label?: string }) => label ? (
    <Box
        p={1}
        display={'flex'}
        alignItems={'center'}
        fontSize={'12px'}
        borderRadius={1}
        sx={{
            backgroundColor: '#FEEFEF'
        }}
        {...props}
    >
        <ErrorRounded color={'error'} fontSize={'small'}/>
        <Typography ml={1} variant={'body2'} fontSize={'inherit'} display={'block'} color={'error'}>{label}</Typography>
    </Box>
) : null
