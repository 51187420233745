type GradientVariant = 'primary'

type GradientVariantOptions = {
    orientation: number
    steps: { rgb: [number, number, number]; position: number }[]
}

const gradients: Record<GradientVariant, GradientVariantOptions> = {
    primary: {
        orientation: 162,
        steps: [
            {
                rgb: [147, 63, 231],
                position: 8,
            },
            {
                rgb: [114, 47, 222],
                position: 45,
            },
            {
                rgb: [91, 44, 225],
                position: 72,
            },
            {
                rgb: [50, 47, 222],
                position: 100,
            },
        ],
    },
}

const getPct = (num: number) => {
    return `${Math.round(num)}%`
}

const makeRgba = (rgb: GradientVariantOptions['steps'][number]['rgb'], opacity: number) =>
    `rgba(${rgb.join(',')},${Math.min(opacity, 1)})`

const renderGradientSteps = (steps: GradientVariantOptions['steps'], opacity: number) => {
    return steps.map(el => `${makeRgba(el.rgb, opacity)} ${getPct(el.position)}`).join(',')
}

export const makeGradient = (gradient: GradientVariant, opacity = 1) => {
    const { orientation, steps } = gradients[gradient]
    return `linear-gradient(${orientation}deg, ${renderGradientSteps(steps, opacity)})`
}
