import { ObjectSchema } from "yup";
import {
    ComponentPropsWithoutRef,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import NiceModal from "@ebay/nice-modal-react";
import { OptionsDialog } from "../../Modals/OptionsDialog";
import { useTranslate } from "../../Hooks/useTranslate";
import { postTelemetry } from "../../Api";

interface IContext {
    errors?: {
        [k: string]: string;
    };
    getError?: (k: string, s?: string) => string | undefined;
    clearError?: (k: string | string[], s?: string) => void;
    withClear?: <P, T extends (...args: P[]) => void>(
        key: string,
        cb: T
    ) => (...params: P[]) => void;
    validate?: (data: any, useModal?: boolean) => Promise<void | "saveDraft">;
    setSchema?: (schema?: any) => void;
    setId?: (id?: any) => void;
    id?: string;
}

export const ErrorStore = createContext<IContext>({});

export const useErrors = (schema?: ObjectSchema<any>, id?: string) => {
    const { errors, getError, clearError, withClear, validate, setSchema, setId } =
        useContext(ErrorStore);
    useEffect(() => {
        setId?.((p: any) => id ?? p);
        setSchema?.((p: any) => {
            if (id && !schema) {
                return null;
            }
            return schema ?? p;
        });
    }, [schema]);
    return {
        errors,
        getError,
        withClear,
        clearError,
        validate,
    } as Required<IContext>;
};

export const ErrorProvider = ({ children }: ComponentPropsWithoutRef<any>) => {
    const { t } = useTranslate("dialogs.createValidationMissingProps");

    const [schema, setSchema] = useState<ObjectSchema<any> | null>(null);
    const [id, setId] = useState<string | null>(null);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        setErrors({});
    }, [id]);

    const validate = async (data: any, useModal = true) => {
        if (!schema) {
            return;
        }
        try {
            await schema?.validate(data, { abortEarly: false, strict: false, stripUnknown: true });
            setErrors({});
        } catch (err: any) {
            try {
                postTelemetry(
                    JSON.stringify(
                        {
                            title: "Error in SYI",
                            errors: err.errors,
                            message: err.message,
                            id: data?.id,
                            raw: JSON.stringify(err, Object.getOwnPropertyNames(err)),
                        },
                        null,
                        2
                    )
                );
            } catch {}

            console.log(err, err.errors, data);

            if (useModal) {
                const choice = await NiceModal.show(OptionsDialog, {
                    title: t("title", { errorCount: err.errors.length }),
                    headline: t("headline"),
                    buttons: [
                        {
                            key: "saveDraft",
                            label: t("actions.secondary"),
                            props: {
                                variant: "outlined",
                                color: "secondary",
                            },
                        },
                        {
                            key: "continue",
                            label: t("actions.primary"),
                            props: {
                                variant: "contained",
                            },
                        },
                    ],
                });

                if (choice === "saveDraft") {
                    return Promise.reject("saveDraft");
                }
            }

            const mapped = (err.inner as any[]).reduce((acc, { path, type, message }) => {
                return {
                    ...acc,
                    [path]: {
                        type,
                        message,
                    },
                };
            }, {} as { [key: string]: { type: string; message: string } });
            setErrors(mapped);
            return Promise.reject();
        }
    };

    const clearError = (key: string | string[], suffix?: string) => {
        setErrors((p: any) => {
            const keys = Array.isArray(key) ? [...key] : [key];
            for (const k of keys) {
                if (suffix) {
                    delete p[`${key}.${suffix}`];
                }
                delete p[k];
                delete p[`${k}.da`];
                delete p[`${k}.en`];
                delete p[`${k}.selectedOptionKey`];
                delete p[`${k}.value`];
            }
            return { ...p };
        });
    };

    const withClear = <P, T extends (...args: P[]) => void>(key: string, cb: T) => {
        return (...params: P[]) => {
            clearError(key);
            cb(...params);
        };
    };

    const getError = useCallback(
        (key: string, suffix?: string) => {
            let found =
                errors?.[key] ??
                (suffix ? errors?.[`${key}.${suffix}`] : null) ??
                errors?.[`${key}.selectedOptionKey`] ??
                errors?.[`${key}.value`] ??
                errors?.[`${key}.da`] ??
                errors?.[`${key}.en`];

            if (!found) {
                const [, sw] = Object.entries(errors).find(([k, v]) => k.startsWith(key)) ?? [];
                if (sw) {
                    found = sw;
                }
            }

            return found?.type === "optionality"
                ? t("required", "utils.errors")
                : found?.message
                ? t(found.message, "utils.errors") ?? t("required", "utils.errors")
                : undefined;
        },
        [errors]
    );

    return (
        <ErrorStore.Provider
            value={{ errors, getError, clearError, withClear, setSchema, setId, validate }}
        >
            {children}
        </ErrorStore.Provider>
    );
};
