import { Page } from "../../Components/Page/Page";
import React, { ComponentPropsWithoutRef, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    capitalize,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItemProps,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    ArrowBackRounded,
    DeleteForeverRounded,
    EditRounded,
    MoreVert,
    PauseCircleFilledOutlined,
    PlayCircleFilledOutlined,
    SvgIconComponent,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useService } from "../../Hooks/useService";
import MenuItem from "@mui/material/MenuItem";
import react from "react";
import { isVirtualId, useEvent } from "../../Hooks/useEvent";
import { useUsers } from "../../Hooks/useUsers";
import { EventCard } from "../../Components/EventCard/EventCard";
import { BookingsList } from "../../Components/BookingsList/BookingsList";
import { TBooking, useBookings } from "../../Hooks/useBookings";
import { renderGuestTypes } from "../../Utils/helpers";
import { BlackMenu } from "../../Components/BlackMenu/BlackMenu";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { useTranslate } from "../../Hooks/useTranslate";
import { ShowForRole } from "../../Components/AllowForRole/AllowForRole";
import { useTranslation } from "react-i18next";
import { useProfile } from "../../Hooks/useProfile";
import { toast } from "react-toastify";
import { OptionsDialog } from "../../Modals/OptionsDialog";
import { CreateBookingDialog } from "../../Modals/CreateBookingDialog";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { useIsMutating, useQueryClient } from "react-query";
import { TStatus } from "../../Components/StatusChip";
import { useWriteParticipants } from "../../Hooks/useWriteParticipants";
import { useLocalizer } from "./EventSyiSections/EventSyiSectionDetails";

const options: {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
}[] = [
    {
        key: "edit",
        label: "Rediger",
        icon: EditRounded,
    },
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

const prepareSheet = (bookings: TBooking[]) => {
    return bookings.reduce<any[]>((acc, el) => {
        return [
            ...acc,
            ...Object.entries(el.items).reduce<any[]>((all, [_, count], index) => {
                return [
                    ...all,
                    ...Array.from(Array(count).keys()).map((__, i) => {
                        return {
                            Time: el.startDateTime,
                            Name: el.customer.name,
                            Phone: el.customer.phone,
                            Email: el.customer.email,
                            Status: el.transaction ? "Paid" : capitalize(el.status),
                            Reference: el.id,
                            Variants: index === 0 && i === 0 ? renderGuestTypes(el) : "-",
                            Addons: index === 0 && i === 0 ? renderGuestTypes(el, "addon") : "-",
                            Note: el.internalNote,
                            Source: capitalize(el.source ?? "checkout"),
                        };
                    }),
                ];
            }, []),
        ];
    }, []);
};

export const EventDetailsPage = ({
    eventId,
    startDateTime,
    endDateTime,
    children,
}: ComponentPropsWithoutRef<any> & {
    eventId: string;
    startDateTime?: string;
    endDateTime?: string;
}) => {
    const navigate = useNavigate();

    const { t } = useTranslate("events.detailsPage.card");

    const { i18n } = useTranslation();

    const { me } = useProfile();

    const isCreatingBookings = useIsMutating("createBookingMutation");

    const queryClient = useQueryClient();

    const {
        event: { data: event, isLoading: isEventLoading },
        deleteEvent,
        updateEvent,
    } = useEvent(eventId);

    const {
        service: { data: service },
    } = useService(event?.serviceId);

    const {
        bookingsForEvent: { data: bookings },
    } = useBookings(eventId);

    const localize = useLocalizer();

    const serviceHeadline =
        service?.headline?.[i18n.language] ?? service?.headline?.[service?.languages?.[0]];

    const handleWriteParticipants = useWriteParticipants({
        eventId,
        headline: serviceHeadline,
        startDateTime: event?.startDateTime,
        participantCount: bookings?.filter((el) => el.status !== "cancelled")?.length ?? 0,
        userId: me.data?.id,
    });

    const handleBack = () => navigate(`/service/${event?.serviceId}`);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const toggleStatusTo = (newStatus: TStatus) =>
        updateEvent.mutateAsync({ ...event, status: newStatus });

    const handleSelect = (key: string) => async () => {
        if (key === "edit")
            navigate("edit/details", {
                state: {
                    returnUrl: `/event/${eventId}`,
                    ...(startDateTime &&
                        endDateTime && {
                            startDateTime,
                            endDateTime,
                        }),
                },
            });
        if (key === "activate") toggleStatusTo("active");
        if (key === "deactivate") toggleStatusTo("inactive");
        if (key === "archive") toggleStatusTo("archived");
        if (key === "delete") {
            if (event?.slots?.booked && event.slots.booked > 0) {
                NiceModal.show(ConfirmDialog, {
                    allowCancel: false,
                    headline: t("headline", "dialogs.deleteEventWithBookings"),
                    title: t("title", "dialogs.deleteEventWithBookings"),
                }).then(() => handleClose());
                return;
            }
            const type = t("thisEvent", "utils.generic");
            if (isVirtualId(eventId)) {
                NiceModal.show<string>(OptionsDialog, {
                    headline: t("headline", "dialogs.confirmDeleteRecurring", { type }),
                    title: t("title", "dialogs.confirmDeleteRecurring"),
                    buttons: [
                        {
                            key: "wholeSeries",
                            label: t("actions.secondary", "dialogs.confirmDeleteRecurring"),
                            props: {
                                variant: "outlined",
                                color: "secondary",
                            },
                        },
                        {
                            key: "onlyThis",
                            label: t("actions.primary", "dialogs.confirmDeleteRecurring"),
                            props: {
                                variant: "contained",
                            },
                        },
                    ],
                }).then((choice: string) => {
                    const id = choice === "wholeSeries" ? event?.parentId ?? eventId : eventId;
                    toast.loading(t("toast.deletingSeries"), { toastId: id });
                    deleteEvent.mutateAsync(id).then(() => {
                        toast.dismiss(id);
                        handleBack();
                    });
                });
                return;
            }
            NiceModal.show(ConfirmDialog, {
                headline: t("headline", "dialogs.confirmDelete", { type }),
                title: t("title", "dialogs.confirmDelete"),
            }).then(() => {
                deleteEvent.mutate(eventId);
                handleBack();
            });
        }
        handleClose();
    };

    const handleClick = (event: react.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickStatus = (status: TStatus) => {
        let newStatus = status;
        switch (status) {
            case "draft":
                newStatus = "active";
                break;
            case "active":
                newStatus = "inactive";
                break;
            case "inactive":
                newStatus = "active";
                break;
        }
        toggleStatusTo(newStatus);
    };

    const _options: typeof options = useMemo(() => {
        const primary =
            event?.status === "active"
                ? {
                      key: "deactivate",
                      icon: PauseCircleFilledOutlined,
                  }
                : {
                      key: "activate",
                      icon: PlayCircleFilledOutlined,
                  };
        return [primary, ...options].map((el) => ({ ...el, label: t(`options.${el.key}`) }));
    }, [event]);

    const { _startDateTime, _endDateTime } = useMemo(() => {
        if (!event?.startDateTime) return {};
        return {
            _startDateTime: new Date(event.startDateTime),
            _endDateTime: event.endDateTime ? new Date(event.endDateTime) : null,
        } as any;
    }, [event]);

    const handlePrintList = () => {
        import("xlsx").then(({ utils, writeFileXLSX }) => {
            const ws = utils.json_to_sheet(prepareSheet(bookings ?? []));
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, `ParticipantList_${eventId}.xlsx`);
        });
    };

    const [navigateTo, setNavigateTo] = useState<null | [string, string]>(null);

    const handleCreateBooking = async () => {
        const [callback, originalEventId] = await NiceModal.show<[() => Promise<string>, string]>(
            CreateBookingDialog,
            { eventId }
        );
        const newEventId = await callback();
        setNavigateTo([newEventId, originalEventId]);
    };

    useEffect(() => {
        if (navigateTo) {
            const [newEventId, originalEventId] = navigateTo;
            if (originalEventId === eventId) {
                navigate(`/event/${newEventId}`);
            }
            if (isCreatingBookings === 0) {
                Promise.all([
                    queryClient.invalidateQueries([
                        newEventId === originalEventId ? newEventId : originalEventId,
                        "bookings",
                    ]),
                    queryClient.invalidateQueries([
                        "events",
                        { type: "service", serviceId: service?.id },
                    ]),
                ]);
            }
            setNavigateTo(null);
        }
    }, [navigateTo]);

    return (
        <Page maxWidth={1200} pb={10}>
            <Box display={"flex"} alignItems={"center"} mb={4} minHeight={44}>
                <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                    <ArrowBackRounded />
                </IconButton>
                {startDateTime ?? _startDateTime ? (
                    <>
                        <Link
                            onClick={() => navigate("/service/" + event?.serviceId)}
                            color={"#000"}
                            variant={"h3"}
                            fontSize={{ xs: "1.2rem", lg: "1.5rem" }}
                            sx={{
                                fontWeight: 400,
                                display: "block",
                                cursor: "pointer",
                                maxWidth: 400,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}
                            fontWeight={"400"}
                        >
                            {serviceHeadline}
                        </Link>
                        <Typography variant={"h3"} ml={2} fontSize={{ xs: "1.2rem", lg: "1.5rem" }}>
                            /{" "}
                            {renderDateTime(
                                startDateTime ?? _startDateTime,
                                endDateTime ?? _endDateTime,
                                { standard: "ddmyyyyhh", sameDay: "dmhh" }
                            )}
                        </Typography>
                    </>
                ) : (
                    <Skeleton width={320} />
                )}
            </Box>
            <EventCard
                status={event?.status ?? "draft"}
                title={localize(event?.headline) ?? serviceHeadline}
                languages={event?.languages ?? []}
                fullWidth
                startDateTime={startDateTime ?? _startDateTime}
                endDateTime={endDateTime ?? _endDateTime}
                bookingsCount={event?.slots?.booked}
                assignees={event?.assignees ?? []}
                availableSlots={event?.slots?.total}
                onClickStatus={handleClickStatus}
            >
                <>
                    <div>
                        <ShowForRole roles={["admin"]}>
                            <IconButton onClick={handleClick}>
                                <MoreVert />
                            </IconButton>
                            <BlackMenu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                {_options.map((el, i) => {
                                    const Icon = el.icon as any;
                                    return (
                                        <MenuItem onClick={handleSelect(el.key)} autoFocus={false}>
                                            <ListItemIcon>
                                                <Icon fontSize={"small"} {...el.props} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={el.props}>
                                                {el.label}
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })}
                            </BlackMenu>
                        </ShowForRole>
                    </div>

                    {
                        <Stack spacing={2} direction={"row"} p={1}>
                            <Tooltip
                                title={
                                    service?.status !== "active"
                                        ? t("tooltip.activeToCreateBooking")
                                        : event?.slots?.booked === event?.slots?.total
                                        ? t("tooltip.notAvailable")
                                        : ""
                                }
                            >
                                <Box>
                                    <Button
                                        variant={"outlined"}
                                        disabled={
                                            event?.slots?.booked === event?.slots?.total ||
                                            service?.status !== "active"
                                        }
                                        onClick={handleCreateBooking}
                                        color={"secondary"}
                                        size={"large"}
                                    >
                                        {t("actions.tertiary")}
                                    </Button>
                                </Box>
                            </Tooltip>
                            {event?.status !== "archived" && bookings && bookings.length > 0 && (
                                <>
                                    <Button
                                        variant={"outlined"}
                                        onClick={handlePrintList}
                                        color={"secondary"}
                                        size={"large"}
                                    >
                                        {t("actions.secondary")}
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        onClick={() => handleWriteParticipants()}
                                        size={"large"}
                                    >
                                        {t("actions.primary")}
                                    </Button>
                                </>
                            )}
                        </Stack>
                    }
                </>
            </EventCard>

            <BookingsList mt={5} bookings={bookings} openModalInline={true} />
        </Page>
    );
};
