import {
    Box,
    Stack,
    Typography,
    Grid,
    Button,
    Divider,
    IconButton,
    BoxProps,
    Chip,
} from "@mui/material";
import { useController, useWatch } from "react-hook-form";
import { ProfileUpload } from "../../Components/ProfileUpload/ProfileUpload";
import * as api from "../../Api";
import { useOutletContext } from "react-router-dom";
import { CancelRounded, CheckRounded, EditOutlined } from "@mui/icons-material";
import { mapSelectedLanguages } from "../../Pages/Services/ServiceSyiSections/ServiceSyiSectionDescription";
import { SyiSection } from "../../Pages/SyiPage/SyiSection";
import { InputWithConfirm } from "../../Components/InputWithConfirm/InputWithConfirm";
import { TranslatableGroup } from "../../Components/TranslatableGroup/TranslatableGroup";
import { useConfirm } from "../../Hooks/useConfirm";
import { LanguageChips } from "../../Components/LanguageChips/LanguageChips";
import { languagesOptions } from "../../Utils/config";
import { useTranslate } from "../../Hooks/useTranslate";
import { CoverImageUpload } from "../../Components/CoverImageUpload/CoverImageUpload";
import { FlatCard } from "../../Components/FlatCard/FlatCard";
import { TagChips } from "../../Components/Tags/Tags";
import NiceModal from "@ebay/nice-modal-react";
import { TagsDialog } from "../../Modals/TagsDialog";
import { useDebounce } from "../../Hooks/useDebounce";
import { VideoUpload } from "../../Components/VideoUpload/VideoUpload";
import { ProfilePreviewer, WithPreview } from "../../Components/Previewer/Previewer";
import { useProfile } from "../../Hooks/useProfile";

export const SettingsProfileSection = ({ context }: { context?: { submitMe?: () => void } }) => {
    const { t } = useTranslate("settings.profile");

    const { submitMe } = useOutletContext<any>() ?? context ?? {};

    const debounce = useDebounce(1000);

    const { field: cover } = useController({ name: "me.pictures.cover" });
    const { field: profile } = useController({ name: "me.pictures.profile" });
    const { field: video } = useController({ name: "me.profileVideo" });

    const [coverPicture, profilePicture, profileVideo] = useWatch({
        name: ["me.pictures.cover", "me.pictures.profile", "me.profileVideo"],
    });

    const {
        me: { data: me },
    } = useProfile();

    const { field: languages } = useController({ name: "me.languages" });
    const { field: tags } = useController({ name: "me.tags", defaultValue: [] });

    const { dirty, handleSave, handleChangeLocalized, handleCancel } = useConfirm(
        "me.description",
        submitMe
    );

    const handleUploadPrepared =
        (parentKey: "profile" | "cover" | "video") =>
        async (
            { key, url, poster }: { [k: string]: string },
            localUrl: string,
            fileType: "logo" | "profile",
            uploadFunc: () => Promise<void>
        ) => {
            await uploadFunc();
            if (parentKey === "profile") {
                profile.onChange({ key, url });
            } else if (parentKey === "video") {
                video.onChange({ key, url, poster });
            } else {
                cover.onChange({ key, url });
            }
            debounce(submitMe);
        };

    const handleToggleLanguage = (keys: string[]) => {
        languages.onChange(keys);
        debounce(submitMe);
    };

    const handleDeleteVideo = async (key: string) => {
        video.onChange(null);
        await api.deleteUpload(key);
        debounce(submitMe);
    };

    const handleEditTags = () => {
        NiceModal.show(TagsDialog, { tags: tags.value }).then((keys) => {
            tags.onChange(keys);
            debounce(submitMe);
        });
    };

    return (
        <WithPreview
            render={(onToggle, desktopEnabled) => (
                <ProfilePreviewer desktopEnabled={desktopEnabled} onToggle={onToggle} />
            )}
        >
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Typography mb={4}>{t("description")}</Typography>
            <Stack
                divider={<Divider orientation={"horizontal"} />}
                direction={"column"}
                spacing={4}
            >
                <Box>
                    <CoverImageUpload
                        onUploadPrepared={handleUploadPrepared("cover")}
                        mb={2}
                        image={coverPicture ?? cover.value}
                        subject={"me"}
                        subjectId={me?.id}
                    />

                    <Stack
                        component={FlatCard}
                        direction={"row"}
                        divider={
                            <Box
                                component={"hr"}
                                sx={{
                                    borderRightWidth: "thin",
                                }}
                                border={"0px solid #E7EBF0"}
                            />
                        }
                        spacing={2}
                    >
                        <Box p={1}>
                            <Typography variant={"h5"}>{t("profilePicture.title")}</Typography>
                            <ProfileUpload
                                mt={2}
                                fileType={"profile"}
                                onUploadPrepared={handleUploadPrepared("profile")}
                                label={t("profilePicture.helperText")}
                                media={profilePicture ?? profile.value}
                                subjectId={me?.id}
                                subject={"me"}
                            />
                        </Box>
                        <ComingSoon p={1}>
                            <Typography variant={"h5"}>{t("profileVideo.title")}</Typography>
                            <VideoUpload
                                mt={2}
                                onDelete={handleDeleteVideo}
                                onUploadPrepared={handleUploadPrepared("video")}
                                label={t("profileVideo.helperText")}
                                video={profileVideo ?? video.value}
                                subjectId={me?.id}
                                subject={"me"}
                            />
                        </ComingSoon>
                    </Stack>
                </Box>
                <SyiSection title={t("detailsTitle")}>
                    <Grid container spacing={3} component={"form"} columns={2}>
                        <Grid item xs={1}>
                            <InputWithConfirm
                                fullWidth
                                label={t("fullName")}
                                type={"text"}
                                name={"me.name"}
                                onCommitChange={submitMe}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <InputWithConfirm
                                fullWidth
                                label={t("email")}
                                type={"email"}
                                disabled={true}
                                name={"me.email"}
                                onCommitChange={submitMe}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <InputWithConfirm
                                fullWidth
                                label={t("phone")}
                                type={"phone"}
                                name={"me.phone"}
                                onCommitChange={submitMe}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <InputWithConfirm
                                fullWidth
                                label={t("shortDescription.title")}
                                helperText={t("shortDescription.helperText")}
                                type={"text"}
                                name={"me.shortDescription"}
                                onCommitChange={submitMe}
                            />
                        </Grid>
                    </Grid>
                </SyiSection>

                <SyiSection title={t("languages")} mt={4}>
                    <LanguageChips
                        onChange={handleToggleLanguage}
                        shouldTranslate={false}
                        languages={languages.value}
                        multiple={true}
                    />
                </SyiSection>

                <SyiSection title={t("tags")} mt={4}>
                    <Box display={"flex"} alignItems={"center"}>
                        <TagChips
                            tags={tags.value}
                            selected={[]}
                            onToggle={tags.onChange}
                            width={"auto"}
                            clickable={false}
                            chipProps={{
                                sx: {
                                    borderColor: "#322FDE",
                                    backgroundColor: "white",
                                },
                            }}
                        />
                        <IconButton
                            onClick={handleEditTags}
                            sx={{ ml: tags.value?.length > 0 ? 1 : 0 }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Box>
                </SyiSection>

                <SyiSection title={t("about.title")} mt={4}>
                    <TranslatableGroup
                        sx={{ maxWidth: 680 }}
                        langs={mapSelectedLanguages(languages?.value ?? [], languagesOptions)}
                        propKey={"me.description"}
                        onUpdate={handleChangeLocalized}
                        input={{
                            type: "editor",
                            placeholder: t("about.inputPlaceholder"),
                        }}
                    />
                    {dirty && (
                        <Stack mt={1} direction={"row"} spacing={1} alignItems={"center"}>
                            <Button
                                startIcon={<CheckRounded />}
                                color={"success"}
                                onClick={handleSave}
                            >
                                {t("save", "buttons")}
                            </Button>
                            <Button
                                startIcon={<CancelRounded />}
                                color={"error"}
                                onClick={handleCancel}
                            >
                                {t("undo", "buttons")}
                            </Button>
                        </Stack>
                    )}
                </SyiSection>
            </Stack>
        </WithPreview>
    );
};

const ComingSoon = ({ children, title, ...props }: BoxProps) => {
    return (
        <Box {...props} position={"relative"}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255,255,255,0.65)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                }}
            >
                <Chip
                    sx={{ backgroundColor: "grey.100", boxShadow: "0 0 4px 0 rgba(0,0,0,0.1)" }}
                    label={title ?? "Feature kommer snart."}
                />
            </Box>
            <Box component={"span"} sx={{ filter: "blur(1.5px)" }}>
                {children}
            </Box>
        </Box>
    );
};
