import ButtonBase from '@mui/material/ButtonBase';
import {styled, Typography} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import {ComponentPropsWithoutRef} from "react";

const Button = styled(ButtonBase)(() => ({
  padding: '8px 24px',
  fontSize: 16,
  borderRadius: 8,
  fontFamily: 'inherit',
  color: '#1C1C1E',
  flex: '1 1 0px',
  fontWeight: 500,
  ['& > p']: {
    marginLeft: 8
  },
  ['&:hover']: {
    backgroundColor: 'rgba(200,200,200,0.05)'
  }

}));

export interface ITabProps {
  label: string,
  icon?: SvgIconComponent,
  selected: boolean
}

const Tab = ({ label, icon: Icon, selected, ...rest  }: ITabProps & ComponentPropsWithoutRef<'button'> ) => {

  return (
    <Button
        sx={{
          ...(selected && {
            color: '#1C1C1E',
            opacity: 1,
            backgroundColor: 'white !important',
            boxShadow: '0 0 3px 0 rgba(100,100,100,0.1)'
          })
        }}
        { ...rest}
    >
      {
        Icon && <Icon fontSize={'small'}/>
      }
      <Typography>{ label }</Typography>

    </Button>
  )

}

export default Tab;
