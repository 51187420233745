import { styled } from "@mui/material";
import Menu from "@mui/material/Menu";

export const BlackMenu = styled(Menu)({
    ["& .MuiMenuItem-root"]: {
        paddingRight: 40,
    },
    ["& .MuiListItemIcon-root"]: {},
    ["& .MuiListItemText-root .MuiTypography-root"]: {
        fontWeight: 500,
        fontSize: "0.875em",
    },
});
