import { Box, BoxProps, IconProps, SvgIconProps, Typography, TypographyProps } from "@mui/material";
import { AccessTimeRounded } from "@mui/icons-material";
import { ReactNode } from "react";

export type IconLabelProps = TypographyProps & {
    Icon?: typeof AccessTimeRounded;
    icon?: ReactNode;
    label: string | undefined;
    containerProps?: BoxProps;
    iconProps?: SvgIconProps;
};
export const IconLabel = ({
    Icon,
    icon,
    label,
    containerProps,
    iconProps,
    ...props
}: IconLabelProps) => {
    return (
        <Box display={"flex"} alignItems={"center"} {...containerProps}>
            {icon ? icon : Icon ? <Icon fontSize={"inherit"} {...iconProps} /> : null}
            <Typography
                pl={0.5}
                fontSize={"0.88rem"}
                {...props}
                sx={{ verticalAlign: "middle", ...props.sx }}
            >
                {label}
            </Typography>
        </Box>
    );
};
