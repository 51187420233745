import {
    Box,
    BoxProps,
    Button,
    capitalize,
    Collapse,
    Dialog,
    Divider,
    FormControlLabel,
    FormControlProps,
    Grid,
    IconButton,
    Link,
    SelectProps,
    Stack,
    Switch,
    TextField,
    TextFieldProps,
    Typography,
    useMediaQuery,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
    useWatch,
    useController,
    Validate,
    useFormState,
    UseFormReturn,
    FormState,
} from "react-hook-form";
import { Header } from "./Header";
import { Tip } from "../Components/Tip/Tip";
import SolidTextField from "../Components/SolidTextField/SolidTextField";
import { CustomSelect } from "../Components/CustomSelect/CustomSelect";
import { useCustomers } from "../Hooks/useCustomers";
import { useTranslate } from "../Hooks/useTranslate";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EditOutlined, SearchRounded } from "@mui/icons-material";
import { IService, useService } from "../Hooks/useService";
import { useLocalizer } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { useSession } from "../Hooks/useSession";
import { formatMoney } from "../Components/VariantSelect/VariantSelect";
import { useProfile } from "../Hooks/useProfile";
import { Offer, useOffers } from "../Hooks/useOffers";
import { ProgressButton } from "../Components/ProgressButton/ProgressButton";
import { createId } from "../Utils/helpers";
import { defaultTaxOption } from "../Utils/taxOptions";
import { addDays, intervalToDuration } from "date-fns";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { SimpleSkeleton } from "../Components/SimpleSkeleton/SimpleSkeleton";
import { useNavigate } from "react-router-dom";
import { TLead } from "../Hooks/useLeads";
import { GradientButton } from "../Components/GradientButton";
import { CONSUMER_URL } from "../Utils/constants";
import isEmpty from "lodash.isempty";
import { useConfirmDialog } from "../Hooks/useConfirmDialog";
import { toast } from "react-toastify";
import { CustomerDialog } from "./CustomerDialog/CustomerDialog";
import { getLocalized } from "../Hooks/useBookings";

const quantityOptions = Array.from(Array(30).keys())
    .filter((f) => f > 0)
    .map((n) => ({
        key: n.toString(10),
        label: n.toString(10),
    }));

const expiryOptions = Array.from(Array(15).keys())
    .filter((f) => f > 0)
    .map((n) => ({
        key: n.toString(10),
        label: `${n} dag${n > 1 ? "e" : ""}`,
    }));

export const OfferDialog = NiceModal.create(
    ({ offerId, lead }: { offerId?: string; lead?: Pick<TLead, "id" | "customer"> }) => {
        const modal = useModal();

        const isMobile = useMediaQuery("(max-width: 1200px)");

        const { t } = useTranslate("dialogs.offer");

        const [creating, setCreating] = useState(true);

        const {
            setValue,
            register,
            getValues,
            handleSubmit,
            control,
            reset,
            resetField,
            ...methods
        } = useForm<any>();

        const { me } = useProfile();

        const { errors, dirtyFields } = useFormState({ control });

        const { confirm } = useConfirmDialog(
            t("unsavedChanges.headline"),
            t("unsavedChanges.label")
        );

        const handleClose = async (force: boolean | undefined = false) => {
            if (isEmpty(dirtyFields) || force === true || Boolean(offerId)) {
                modal.remove();
            } else {
                confirm().then((confirmed) => {
                    if (confirmed) {
                        modal.remove();
                    }
                });
            }
        };

        const {
            offer: { data: offer, isLoading: isOfferLoading },
            updateOffer,
            resend,
        } = useOffers(offerId);

        const { getSessionByServiceId } = useSession();

        const calculatePrice = (price?: number | undefined, quantity?: number | undefined) => {
            const [_price, _quantity] = getValues(["unitPrice", "quantity"]);
            return (price ?? _price ?? 0) * (quantity ?? _quantity ?? 1);
        };

        useEffect(() => {
            if (lead && !offerId) {
                setValue("customer", lead.customer);
                setValue("leadId", lead.id);
            }
        }, [lead]);

        const handleChangeService = (
            id: string,
            name: string,
            variantId: string,
            duration: number,
            price: number
        ) => {
            setValue("durationMinutes", duration, { shouldValidate: true });
            setValue("serviceName", name, { shouldValidate: true });
            setValue("variantId", variantId, { shouldValidate: true });
            setValue("unitPrice", price, { shouldValidate: true });
            setValue("subjectId", id, { shouldValidate: true });
            setValue("price", calculatePrice(price), { shouldValidate: true });
        };

        const isServiceError = Boolean(
            errors?.["serviceName"] ??
                errors?.["subjectId"] ??
                errors?.["durationMinutes"] ??
                errors?.["unitPrice"]
        );

        const { defaultCurrency } = useProfile();

        const { field: expiryDate } = useController({
            name: "expiryDate",
            defaultValue: "7",
            control,
            rules: { required: true },
        });

        useEffect(() => {
            if (offer?.created && offer?.expiryDate) {
                const { days } = intervalToDuration({
                    start: Number(new Date(offer.created)) - 30 * 1000,
                    end: new Date(offer.expiryDate),
                });
                if (days) {
                    expiryDate.onChange(days.toString());
                }
                setCreating(false);
                setValue("durationMinutes", offer.firstItem.durationMinutes);
                setValue("serviceName", offer.firstItem.name);
                setValue("variantId", offer.firstItem.variantId);
                setValue("unitPrice", offer.firstItem.amount);
                setValue("quantity", offer.firstItem.quantity);
                setValue("price", offer.price.totalAmount);
                setValue("subjectId", offer?.firstItem.serviceId);
                setValue("description", offer.description);
                setValue("discountRate", offer?.discount?.rate ?? 0);
                setValue("discountLabel", offer?.discount?.label);
                setValue("customer", offer?.customer);
            }
        }, [offer]);

        const handleUpdateQuantity: SelectProps["onChange"] = (e) => {
            if (e.target.value) {
                setValue("price", calculatePrice(undefined, parseInt(e.target.value as string)), {
                    shouldValidate: true,
                });
                setValue("quantity", parseInt(e.target.value as string), { shouldValidate: true });
            }
        };

        register("price", { required: true });
        register("quantity", { required: true });
        register("durationMinutes", { required: true });
        register("serviceName", { required: true });
        register("unitPrice", { required: true });
        register("subjectId", { required: true });

        const navigate = useNavigate();

        const handleResendOffer = () => {
            return resend(offerId);
        };

        const onSubmit = async () => {
            if (!creating) {
                return handleResendOffer();
            }
            const {
                description,
                customer,
                unitPrice,
                serviceName,
                variantId,
                durationMinutes,
                quantity,
                price,
                subjectId,
                discountRate,
                discountLabel,
                expiryDate,
                leadId,
                metadata,
            } = getValues();

            const { id: sessionId } = getSessionByServiceId(subjectId) ?? {};

            const payload: Offer & { shouldCreate: boolean } = {
                customer,
                metadata,
                items: [
                    {
                        subject: "session",
                        subjectId: sessionId,
                        serviceId: subjectId,
                        name: serviceName,
                        variantId,
                        durationMinutes,
                        quantity,
                        amount: unitPrice,
                    },
                ],
                ...(leadId && { leadId }),
                discount: {
                    percentage: false,
                    rate: Math.min(parseInt(discountRate), price),
                    ...(discountLabel && { label: discountLabel }),
                },
                assignee: me.data?.id as string,
                expiryDate: addDays(new Date(), parseInt(expiryDate)).toISOString(),
                description,
                price: {
                    currency: defaultCurrency,
                    taxId: defaultTaxOption as string,
                    totalAmount: price ?? 0,
                },
                shouldCreate: true,
                id: createId(),
            };

            await updateOffer.mutateAsync(payload);
            reset();
            navigate("/offers");
            toast.success(
                shouldNotify
                    ? t("toast.successSent", { name: customer.email })
                    : t("toast.successCreated")
            );
            return handleClose(true);
        };

        const handleDuplicate = () => {
            setCreating(true);
        };

        const renderTip = useCallback(() => {
            if (offer) {
                return t("tip.sent", {
                    name: offer.customer.name,
                    time: renderDateTime(offer.created as string),
                });
            }
            return t("tip.create");
        }, [offer]);

        const handleClickPreview = (id: string) => () => {
            const a = document.createElement("a");
            a.href = `${CONSUMER_URL}/offer/${id}`;
            a.target = "_blank";
            a.click();
            a.remove();
        };

        const isLoading = offerId && isOfferLoading;

        const shouldNotify = useWatch({ name: "metadata.shouldNotify", control });

        return (
            <Dialog
                fullWidth
                sx={{ "& .MuiPaper-root": { maxWidth: 730 } }}
                maxWidth={"md"}
                open={modal.visible}
                onClose={() => handleClose()}
            >
                <FormProvider
                    {...{
                        setValue,
                        register,
                        getValues,
                        handleSubmit,
                        control,
                        reset,
                        resetField,
                        ...methods,
                    }}
                >
                    <Header
                        pl={{ xs: 2, lg: 4 }}
                        title={
                            offer?.customer.name
                                ? t("title.existing", { name: offer.customer.name })
                                : t("title.new")
                        }
                        id={offerId}
                        onClose={handleClose}
                    >
                        {offer?.id && (
                            <GradientButton
                                variant={"contained"}
                                color={"primary"}
                                onClick={handleClickPreview(offer.id)}
                                startIcon={<SearchRounded />}
                            >
                                {t("actions.showPreview")}
                            </GradientButton>
                        )}
                    </Header>
                    <Box sx={{ overflowY: "auto" }}>
                        <Box p={{ xs: 2, lg: 4 }}>
                            {isLoading ? (
                                <SimpleSkeleton />
                            ) : (
                                <>
                                    {!isMobile && (
                                        <Tip
                                            filled
                                            label={renderTip()}
                                            sx={{ display: { xs: "hidden", lg: "flex" } }}
                                        />
                                    )}
                                    <SolidTextField
                                        sx={{ mt: { xs: 0, lg: 2 } }}
                                        multiline
                                        error={Boolean(errors?.["description"])}
                                        fullWidth
                                        minRows={isMobile ? 2 : 4}
                                        disabled={!creating}
                                        defaultValue={offer?.description}
                                        placeholder={t("inputs.descriptionPlaceholder")}
                                        {...register("description", { required: true })}
                                    />
                                    <Grid component={"form"} container columns={2} spacing={3}>
                                        <CustomerForm
                                            component={Grid}
                                            item
                                            xs={1}
                                            mt={2}
                                            disables={{
                                                edit: !creating || Boolean(lead?.customer?.id),
                                                customerSelect: Boolean(lead),
                                                emailInput: Boolean(lead) || Boolean(offerId),
                                                phoneInput: Boolean(offerId),
                                                nameInput: Boolean(offerId),
                                            }}
                                            customerId={offer?.customer?.id ?? lead?.customer?.id}
                                        />
                                        <Grid
                                            item
                                            xs={1}
                                            component={Stack}
                                            direction={"column"}
                                            spacing={2}
                                        >
                                            <ServiceSelect
                                                sx={{ mt: 2 }}
                                                fullWidth
                                                error={isServiceError}
                                                disabled={!creating}
                                                defaultService={offer?.firstItem?.serviceId}
                                                defaultVariant={offer?.firstItem?.variantId}
                                                onChange={handleChangeService}
                                            />
                                            <CustomSelect
                                                sx={{ mt: 2 }}
                                                options={quantityOptions}
                                                fullWidth
                                                error={Boolean(
                                                    errors?.["quantity"] ?? errors?.["price"]
                                                )}
                                                disabled={!creating}
                                                defaultValue={offer?.firstItem?.quantity}
                                                label={t("inputs.quantityLabel")}
                                                onChange={handleUpdateQuantity}
                                            />
                                            <CustomSelect
                                                sx={{ mt: 2 }}
                                                options={expiryOptions}
                                                fullWidth
                                                disabled={!creating}
                                                label={t("inputs.expiryLabel")}
                                                defaultValue={expiryDate.value}
                                                onChange={expiryDate.onChange}
                                            />
                                            <TextField
                                                sx={{ mt: 2 }}
                                                fullWidth
                                                disabled={!creating}
                                                label={t("inputs.discountLabel")}
                                                type={"number"}
                                                inputProps={{
                                                    min: 0,
                                                }}
                                                defaultValue={offer?.discount?.rate}
                                                {...register("discountRate", {
                                                    valueAsNumber: true,
                                                })}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Box>
                        <Summary disableEdit={!creating} />
                    </Box>
                    <Stack
                        sx={{
                            backgroundColor: "white",
                            borderTop: "1px solid #E7EBF0",
                        }}
                        direction={"row"}
                        p={{ xs: 1.5, lg: 3 }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        {creating ? (
                            <FormControlLabel
                                control={<Switch defaultChecked={true} />}
                                label={t("inputs.shouldNotifyLabel")}
                                {...register("metadata.shouldNotify", { value: true })}
                            />
                        ) : (
                            <Button size={"large"} onClick={handleDuplicate} variant={"outlined"}>
                                {t("actions.duplicate")}
                            </Button>
                        )}

                        <ProgressButton
                            label={
                                creating
                                    ? shouldNotify
                                        ? t("actions.send")
                                        : t("actions.create")
                                    : shouldNotify
                                    ? t("actions.resend")
                                    : t("actions.send")
                            }
                            size={"large"}
                            variant={"contained"}
                            onClick={() =>
                                new Promise((resolve, reject) => {
                                    handleSubmit(
                                        () =>
                                            onSubmit().then(() => {
                                                resolve(true);
                                            }),
                                        reject
                                    )();
                                })
                            }
                        />
                    </Stack>
                </FormProvider>
            </Dialog>
        );
    }
);

const Summary = ({ disableEdit = false }: { disableEdit?: boolean }) => {
    const discount = useWatch({ name: "discountRate" });
    const quantity = useWatch({ name: "quantity" });
    const duration = useWatch({ name: "durationMinutes" });
    const serviceName = useWatch({ name: "serviceName" });
    const price = useWatch({ name: "price" });
    const discountLabel = useWatch({ name: "discountLabel", defaultValue: "Rabat" });

    const { defaultCurrency } = useProfile();

    const totalPrice = useMemo(() => {
        return (price ?? 0) - (isNaN(discount) ? 0 : discount);
    }, [discount, price]);

    const { t } = useTranslate("dialogs.offer.summary");

    return (
        <Collapse in={Boolean(quantity)}>
            <Box mt={2}>
                <Grid container pl={4} pr={4} pb={1.5}>
                    <Grid item xs={4} component={Typography} variant={"h5"}>
                        {t("description")}
                    </Grid>
                    <Grid item xs={3} component={Typography}>
                        {t("duration")}
                    </Grid>
                    <Grid item xs={1.5} component={Typography}>
                        {t("quantity")}
                    </Grid>
                    <Grid item xs={true} textAlign={"right"} component={Typography}>
                        {t("price")}
                    </Grid>
                </Grid>
                <Divider />
                <Grid mt={2} container pl={4} pr={4} pb={1.5}>
                    <Grid
                        item
                        xs={4}
                        pr={1}
                        component={EditableLabel}
                        TextFieldProps={{ multiline: true }}
                        disableEdit={disableEdit}
                        value={serviceName}
                        label={t("serviceName")}
                        name={"serviceName"}
                    />
                    <Grid
                        item
                        xs={3}
                        pr={1}
                        component={EditableLabel}
                        disableEdit={disableEdit}
                        TextFieldProps={{ type: "number", defaultValue: duration }}
                        value={`${duration} ${t("minutes", "utils.generic")}`}
                        label={`${t("duration")} (${t("minutes", "utils.generic")})`}
                        name={"durationMinutes"}
                    />
                    <Grid
                        item
                        xs={2}
                        pr={1}
                        component={EditableLabel}
                        disableEdit={true}
                        value={quantity}
                        TextFieldProps={{ type: "number" }}
                        label={t("quantity")}
                        name={"quantity"}
                    />
                    <Grid
                        item
                        xs={true}
                        name={"price"}
                        display={"flex"}
                        justifyContent={"end"}
                        component={EditableLabel}
                        disableEdit={true}
                        textAlign={"right"}
                        TextFieldProps={{
                            type: "number",
                            defaultValue: price,
                            sx: { transform: "translateX(-32px)" },
                        }}
                        value={formatMoney(t)({
                            value: parseInt(price ?? "0"),
                            nativeCurrency: defaultCurrency,
                        })}
                        label={t("price")}
                    />
                </Grid>
                {discount > 0 && (
                    <Stack
                        mt={1}
                        pl={4}
                        pr={2}
                        pb={2}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <EditableLabel
                            disableEdit={disableEdit}
                            name={"discountLabel"}
                            value={discountLabel}
                            label={t("discount")}
                        />
                        <Typography mr={1}></Typography>
                        <Typography sx={{ color: "#34C759" }} mr={2.5}>
                            {formatMoney(t)({
                                value: Math.max(
                                    -Math.abs(parseInt(discount)),
                                    -Math.abs(parseInt(price))
                                ),
                                nativeCurrency: defaultCurrency,
                            })}
                        </Typography>
                    </Stack>
                )}

                <Divider />
                <Box
                    mr={4}
                    mt={3}
                    mb={3}
                    ml={"auto"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={200}
                    pb={2}
                >
                    <Typography variant={"h5"}>{t("total")}</Typography>
                    <Typography variant={"h5"}>
                        {formatMoney(t)({
                            value: Math.max(totalPrice, 0),
                            nativeCurrency: defaultCurrency,
                        })}
                    </Typography>
                </Box>
            </Box>
        </Collapse>
    );
};

type DisableableKeys = "edit" | "customerSelect" | "emailInput" | "all";

type CustomerFormProps = BoxProps<any> & {
    disables?: Record<DisableableKeys | string, boolean>;
    requires?: InputKey[];
    customerId?: string;
};
export const CustomerForm = ({
    customerId,
    disables,
    requires,
    children,
    ...props
}: CustomerFormProps) => {
    const { t } = useTranslate("dialogs.offer");

    const { control, setValue, formState, register } = useFormContext();

    const navigate = useNavigate();

    const {
        errors: { customer: errors },
    } = formState;

    const {
        customers: { data: customers, isLoading },
    } = useCustomers();

    const handleChangeCustomer: TextFieldProps["onChange"] = (e) => {
        const { name, phone, email } = customers?.find((el) => el.id === e.target.value) ?? {};
        if (name) {
            setValue("customer.name", name);
        }
        if (phone) {
            setValue("customer.phone", phone);
        }
        if (email) {
            setValue("customer.email", email);
        }
        customerIdInput.onChange(e);
    };

    const isDisabled = Object.values(disables ?? {}).some((d) => d);

    const { field: customerIdInput } = useController({
        control,
        name: "customer.id",
        defaultValue: customerId,
    });

    const handleOpenCustomer = () => {
        if (customerIdInput.value) {
            NiceModal.show(CustomerDialog, {
                customerId: customerIdInput.value,
                disableRedirectOnClose: true,
            });
        }
    };

    return (
        <Box {...props}>
            <SolidTextField
                label={isLoading ? "Henter klienter..." : t("selectCustomer", "utils.generic")}
                select
                fullWidth
                {...{
                    ...(customerId && {
                        InputLabelProps: {
                            shrink: true,
                        },
                    }),
                }}
                {...customerIdInput}
                disabled={isDisabled || !customers || customers?.length === 0}
                onChange={handleChangeCustomer}
                helperText={
                    customerIdInput.value && (
                        <Typography variant={"body2"} fontSize={"0.75rem"}>
                            Gå til <Link onClick={handleOpenCustomer}>kunde</Link>
                        </Typography>
                    )
                }
            >
                {(customers?.map((el) => ({ key: el.id, label: el.name })) ?? []).map((el) => {
                    return (
                        <MenuItem value={el.key} key={el.key}>
                            {el.label}
                        </MenuItem>
                    );
                })}
            </SolidTextField>
            {children}
            <NewCustomerForm
                control={control}
                disables={disables}
                errors={errors}
                requires={requires}
            />
        </Box>
    );
};

type NewCustomerFormProps = {
    control: UseFormReturn["control"];
    disables?: CustomerFormProps["disables"];
    errors?: FormState<any>["errors"];
    requires?: InputKey[];
};

type InputKey = "name" | "email" | "phone";

const defaultInputs: [InputKey, string, (type: string) => Validate<any>][] = [
    ["name", "text", () => (val) => val ? typeof val === "string" : true],
    ["email", "email", () => (val) => val ? emailValid(val) : true],
    [
        "phone",
        "tel",
        (type) => (val) => {
            if (val) {
                if (type === "tel") {
                    return !isNaN(Number(val));
                }
                return Boolean(val);
            }
            return true;
        },
    ],
];
const NewCustomerForm = ({
    control,
    disables,
    errors,
    requires = ["name", "email"],
}: NewCustomerFormProps) => {
    const { t } = useTranslate("utils.generic");

    return (
        <>
            {defaultInputs.map(([key, type, validate]) => {
                return (
                    <Controller
                        control={control}
                        key={key}
                        rules={{
                            required: requires?.includes(key) ?? false,
                            validate: validate(type),
                        }}
                        name={`customer.${key}`}
                        render={({ field: { onChange, name, value } }) => (
                            <SolidTextField
                                disabled={Boolean(disables?.[`${key}Input`])}
                                sx={{ mt: 2 }}
                                type={type}
                                error={Boolean(errors?.[key])}
                                fullWidth
                                name={name}
                                label={t(key, "utils.generic")}
                                value={value ?? ""}
                                onChange={onChange}
                            />
                        )}
                    />
                );
            })}
        </>
    );
};

function emailValid(email: string) {
    const m = email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return Boolean(m);
}

export const CustomerSelect = ({ onChange, ...props }: Omit<TextFieldProps, "ref">) => {
    const {
        customers: { data: customers },
    } = useCustomers();
    return (
        <SolidTextField label={"Vælg klient"} select {...props} onChange={onChange}>
            {(customers?.map((el) => ({ key: el.id, label: el.name })) ?? []).map((el) => {
                return (
                    <MenuItem value={el.key} key={el.key}>
                        {el.label}
                    </MenuItem>
                );
            })}
        </SolidTextField>
    );
};

type SelectedService = {
    title: string;
    id: string;
    duration: number;
    variants: {
        key: string;
        label: string;
        name: string;
        price: number;
        duration?: number;
    }[];
};

const ServiceSelect = ({
    onChange,
    defaultVariant,
    defaultService,
    ...props
}: Omit<FormControlProps, "onChange"> & {
    onChange: (
        id: string,
        title: string,
        variantId: string,
        duration: number,
        price: number
    ) => void;
    defaultService?: string;
    defaultVariant?: string;
}) => {
    const { activeServices: services } = useService();

    const { defaultCurrency } = useProfile();

    const [selectedService, setSelectedService] = useState<SelectedService | null>(null);

    const { getSessionDuration } = useSession();

    const { t } = useTranslate("utils.generic");

    const localize = useLocalizer();

    const handleChangeService: SelectProps["onChange"] = (e) => {
        const service = services?.find((el) => el.id === e.target.value);
        if (service) {
            const duration = getSessionDuration(service.id);
            if (service.variants && service.variants.length === 1 && typeof duration === "number") {
                onChange(
                    service.id as string,
                    `${localize(service.headline)} - ${localize(service.variants[0].name)}`,
                    service.variants[0].id,
                    duration,
                    service.variants[0].price ?? 0
                );
                setSelectedService(null);
            }
            setSelectedService({
                id: service.id as string,
                variants:
                    service.variants?.map((v) => ({
                        key: v.id,
                        label: `${localize(v.name)}, ${formatMoney(t)({
                            value: v.price,
                            nativeCurrency: defaultCurrency,
                        })}`,
                        name: localize(v.name) ?? "",
                        price: v.price,
                        duration: v.slotDuration,
                    })) ?? [],
                title: localize(service.headline),
                duration: duration as number,
            });
        }
    };

    const handleChangeVariant: SelectProps["onChange"] = (e) => {
        const { duration, price, key, name } =
            selectedService?.variants.find((el) => el.key === e.target.value) ?? {};
        if (
            selectedService?.id &&
            name &&
            typeof price === "number" &&
            key &&
            (typeof duration === "number" || typeof selectedService?.duration === "number")
        ) {
            onChange(
                selectedService.id,
                `${selectedService?.title} - ${name}`,
                key,
                duration ?? selectedService?.duration ?? 0,
                price
            );
        }
    };

    const variantOptions = useMemo(() => {
        return (
            services
                ?.find((s) => s.id === selectedService?.id || s.id === defaultService)
                ?.variants?.map((v) => ({
                    key: v.id,
                    label: `${localize(v.name)}, ${formatMoney(t)({
                        value: v.price,
                        nativeCurrency: defaultCurrency,
                    })}`,
                    price: v.price,
                    duration: v.slotDuration,
                })) ?? []
        );
    }, [defaultVariant, defaultService, selectedService, services]);

    const serviceOptions = useMemo(() => {
        return (
            services
                ?.filter((f) => f.type === "session" && f.variants && f.variants.length > 0)
                ?.map((el) => ({
                    label: `${localize(el.headline)}`,
                    key: el.id as string,
                })) ?? []
        );
    }, [services, getSessionDuration]);

    return (
        <>
            <CustomSelect
                onChange={handleChangeService}
                options={serviceOptions}
                label={capitalize(t("service"))}
                defaultValue={defaultService}
                {...props}
            />
            {variantOptions && (
                <CustomSelect
                    onChange={handleChangeVariant}
                    options={variantOptions}
                    label={capitalize(t("variant"))}
                    defaultValue={defaultVariant}
                    {...props}
                />
            )}
        </>
    );
};

const EditableLabel = ({
    value,
    label,
    name,
    TextFieldProps,
    disableEdit = false,
    ...props
}: BoxProps & {
    TextFieldProps?: TextFieldProps;
    value: string;
    label: string;
    name: string;
    disableEdit?: boolean;
}) => {
    const [editing, setEditing] = useState(false);

    const { setValue } = useFormContext();

    const update = (value: string | number) => {
        setValue(name, value);
        setValue("isCustom", true);
        setValue("subject", "custom");
    };

    return (
        <Box {...props}>
            {editing ? (
                <TextField
                    size={"small"}
                    label={label}
                    defaultValue={value}
                    onChange={(e) =>
                        update(
                            TextFieldProps?.type === "number"
                                ? parseInt(e.target.value)
                                : e.target.value
                        )
                    }
                    {...TextFieldProps}
                />
            ) : (
                <Box ml={"auto"} display={"flex"} alignItems={"center"}>
                    <Typography mr={disableEdit ? 0 : 1}>{value as string}</Typography>
                    {!disableEdit && (
                        <IconButton size={"small"} onClick={() => setEditing(true)}>
                            <EditOutlined fontSize={"small"} />
                        </IconButton>
                    )}
                </Box>
            )}
        </Box>
    );
};
