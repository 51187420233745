import { useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useMemo } from "react";
import { Product } from "../Api/Upcoming";

export const useUpcoming = (serviceId?: string) => {
    const queryClient = useQueryClient();

    const QueryKey = ["upcoming", { serviceId }];

    const upcoming = useQuery(
        QueryKey,
        () => {
            return api.getUpcoming(serviceId as string);
        },
        {
            enabled: Boolean(serviceId),
        }
    );

    const products = useMemo(() => {
        return upcoming.data?.products;
    }, [upcoming.data]);

    const variants = useMemo(() => {
        return products
            ?.filter((el) => el.id.startsWith("variant"))
            .reduce<Product[]>((acc, p) => {
                return [
                    ...acc,
                    {
                        ...p,
                        addons: products.filter((f) => f.parent === p.id),
                    },
                ];
            }, []);
    }, [products]);

    const availability = useMemo(() => {
        return upcoming.data?.availability ?? [];
    }, [upcoming.data]);

    return {
        upcoming,
        products,
        variants,
        availability,
    };
};
