import { ViewHeader } from "./ViewHeader";
import React, { useEffect, useState } from "react";
import { Box, BoxProps, Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { TBooking, useBookings } from "../../Hooks/useBookings";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { CustomDateTimePicker } from "../../Components/CustomDateTimePicker";
import { ViewFooter } from "./ViewFooter";
import { addMinutes, differenceInMinutes } from "date-fns";
import { toISODateTime } from "@hiddengemgroup/utils-date";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";
import { toast } from "react-toastify";
import { EventSelect } from "../../Components/EventSelect";
import { DoubleBookingWarning } from "../../Components/DoubleBookingWarning";
import { useDebounce } from "../../Hooks/useDebounce";

type MoveBookingViewProps = {
    bookingId: string;
    onBack: () => void;
} & BoxProps;
export const MoveBookingView = ({ bookingId, onBack, ...props }: MoveBookingViewProps) => {
    const {
        booking: { data: booking },
        moveBooking,
        willOverlap,
    } = useBookings(undefined, bookingId);

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const [shouldNotify, setShouldNotify] = useState<boolean>(true);
    const [message, setMessage] = useState<string>();
    const [willOverlapBooking, setWillOverlapBooking] = useState<{
        type: "violatePadding" | "overlapping";
        bookingId: string;
    } | null>();

    const debounce = useDebounce(500);

    const getDuration = (booking: TBooking) => {
        return Math.abs(
            differenceInMinutes(new Date(booking.startDateTime), new Date(booking.endDateTime))
        );
    };

    useEffect(() => {
        if (selectedDate && booking) {
            debounce(() => {
                const match = willOverlap(
                    selectedDate,
                    addMinutes(selectedDate, getDuration(booking)),
                    bookingId
                );
                if (match) {
                    setWillOverlapBooking(match);
                } else {
                    setWillOverlapBooking(null);
                }
            });
        }
    }, [selectedDate, booking]);
    const handleSubmit = async () => {
        if (booking && (selectedDate || selectedEventId)) {
            try {
                if (selectedDate) {
                    await moveBooking.mutateAsync({
                        id: bookingId,
                        sessionId: booking.sessionId,
                        shouldNotify,
                        ...(message && { message }),
                        startDateTime: toISODateTime(selectedDate),
                        endDateTime: toISODateTime(addMinutes(selectedDate, getDuration(booking))),
                    });
                } else {
                    await moveBooking.mutateAsync({
                        id: bookingId,
                        eventId: selectedEventId,
                        ...(message && { message }),
                        shouldNotify,
                    });
                }
                toast.success("Din aftale er blevet flyttet.");
                onBack();
            } catch (err) {
                console.log("Error moving booking");
                toast.error("Hov, der skete en fejl.");
            }
        }
    };

    return (
        <Box {...props}>
            <ViewHeader title={"Ombook"} onBack={onBack} />
            <Stack spacing={1}>
                {booking ? (
                    <>
                        {booking.eventId ? (
                            <EventSelect
                                eventId={booking.eventId}
                                serviceId={booking.serviceId}
                                inputLabel={"Vælg begivenhed"}
                                onChange={(e) => setSelectedEventId(e.target.value as string)}
                            />
                        ) : (
                            <Box>
                                <CustomDateTimePicker
                                    sx={{ minWidth: 360 }}
                                    value={selectedDate}
                                    defaultValue={new Date(booking.startDateTime)}
                                    onChange={(d) => setSelectedDate(d)}
                                    label={"Vælg nyt tidspunkt"}
                                />
                                <DoubleBookingWarning mt={2} {...willOverlapBooking} />
                            </Box>
                        )}
                        <FormControlLabel
                            onChange={(_evt, checked) => setShouldNotify(checked)}
                            value={shouldNotify}
                            control={<Checkbox defaultChecked={shouldNotify} />}
                            label={"Send mail med bekræftelse til kunde"}
                        />
                        {Boolean(shouldNotify) && (
                            <TextField
                                fullWidth
                                minRows={3}
                                multiline
                                label={"Besked til klient (vises i mailen) - valgfrit"}
                                onChange={(evt) => setMessage(evt.target.value)}
                            />
                        )}
                    </>
                ) : (
                    <SimpleSkeleton />
                )}
            </Stack>
            <ViewFooter mt={3}>
                <Button variant={"outlined"} color={"secondary"} onClick={onBack}>
                    Annuller
                </Button>
                <ProgressButton
                    variant={"contained"}
                    color={"primary"}
                    disabled={!selectedDate && !selectedEventId}
                    onClick={handleSubmit}
                    label={"Ombook"}
                />
            </ViewFooter>
        </Box>
    );
};
