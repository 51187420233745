import { Divider, Stack, TextField, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { Tip } from "../../../Components/Tip/Tip";
import {
    OptionGroup,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useErrors } from "../../SyiPage/useErrors";
import { minimumParticipantsOptions } from "./ServiceSyiSectionPracticalities";
import { useTranslate } from "../../../Hooks/useTranslate";

export const seatTypeOptions = [
    {
        key: "single",
        unitLabelPlural: "personer",
        unitLabelSingular: "person",
        label: "Som enkeltpersoner",
        allowParticipantType: true,
    },
    {
        key: "couple",
        unitLabelPlural: "par",
        unitLabelSingular: "par",
        label: "Som par",
        allowParticipantType: true,
    },
    {
        key: "group",
        unitLabelPlural: "grupper",
        unitLabelSingular: "gruppe",
        label: "Som grupper",
        allowParticipantType: true,
        mustHaveMinMax: true,
    } /*,{
    key: 'unique',
    unitLabelPlural: 'unikke enheder',
    label: 'Unik enhed',
    allowParticipantType: false,
    isNameable: true,
    canHaveMinMax: true
}*/,
];

export const ServiceSyiSectionSeats = () => {
    const { t } = useTranslate("service.create.seats");

    const { register } = useFormContext();

    const { getError, withClear, clearError } = useErrors();

    const { field: seatCount } = useController({ name: "seatCount" });

    return (
        <>
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Tip label={t("titleHelpText")} />

            <Stack mt={3} divider={<Divider />} spacing={4}>
                <SyiSection title={t("seatCount.title")} error={getError("seatCount")}>
                    <TextField
                        sx={{ minWidth: 320, maxWidth: 320 }}
                        label={t("seatCount.inputPlaceholder")}
                        type={"number"}
                        {...seatCount}
                        inputProps={{
                            min: 0,
                        }}
                        onChange={withClear("seatCount", (val) => {
                            seatCount.onChange(parseInt(val.target.value));
                            clearError("seatCount");
                        })}
                        helperText={t("seatCount.inputHelpText")}
                    />
                </SyiSection>

                <SyiSection title={t("type.minSeats")}>
                    <OptionGroup
                        name={"minimumParticipants.selectedOptionKey"}
                        options={minimumParticipantsOptions}
                    >
                        <OptionGroupSelectedSection optionKey={"yes"} mt={2}>
                            <TextField
                                label={t("type.minSeatsDefine.inputPlaceholder")}
                                type={"number"}
                                sx={{ minWidth: 320 }}
                                {...register(`practicalities.minimumParticipants.yes`, {
                                    valueAsNumber: true,
                                })}
                            />
                        </OptionGroupSelectedSection>
                    </OptionGroup>
                </SyiSection>
            </Stack>
        </>
    );
};
