import React, { Component, ErrorInfo, ReactNode } from "react";
import { Typography } from "@mui/material";
import { postTelemetry } from "../Api";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        await postTelemetry(
            JSON.stringify(
                {
                    error,
                    errorInfo,
                },
                null,
                2
            )
        );
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Typography variant={"h3"} p={3}>
                    Ups.. Der skete en fejl. Prøv at genindlæse siden eller kontakt{" "}
                    <a href={"mailto:support@spiritworld.dk"}>kundeservice</a>
                </Typography>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
