import {
    Box,
    BoxProps,
    Button,
    Collapse,
    Dialog,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    StackProps,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
    FormProvider,
    useController,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import { ProgressButton } from "../Components/ProgressButton/ProgressButton";
import { Header } from "./Header";
import {
    AddCircleOutlined,
    DeleteOutlined,
    ExpandLessRounded,
    ExpandMoreRounded,
} from "@mui/icons-material";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { OptionChips } from "../Components/OptionChips/OptionChips";
import { addDays, addHours, formatDistance } from "date-fns";
import { useBookings } from "../Hooks/useBookings";
import { useNotifications, useReminders, type Reminder } from "../Hooks/useNotifications";
import { toISODateTime } from "@hiddengemgroup/utils-date";
import { CustomDateTimePicker } from "../Components/CustomDateTimePicker";

export type ReminderDialogProps = {
    subject: "booking";
    subjectId: string;
};

type FormData = {
    reminders: (Omit<Reminder, "scheduledAt"> & { scheduledAt: Date; ownId?: string })[];
};

export const ReminderDialog = NiceModal.create<ReminderDialogProps>(({ subject, subjectId }) => {
    const modal = useModal();

    const [expanded, setExpanded] = useState(-1);

    const methods = useForm<FormData>();

    const {
        reminders: { data: _reminders },
        updateReminders,
    } = useReminders(subject, subjectId);

    const {
        consumerTemplates: { data: consumerTemplates },
    } = useNotifications();

    const {
        booking: { isLoading, data: booking },
    } = useBookings(undefined, subjectId);

    const {
        fields: reminders,
        append,
        remove,
        replace,
    } = useFieldArray({
        control: methods.control,
        name: "reminders",
    });

    const templates = useMemo(
        () =>
            consumerTemplates
                ?.filter((el) => el.templateId.startsWith(`c-reminder-${subject}`))
                .map((el) => el.templateId) ?? [],
        [consumerTemplates]
    );

    useEffect(() => {
        if (_reminders) {
            replace(
                _reminders.map((el) => ({
                    ...el,
                    ownId: el.id,
                    scheduledAt: new Date(el.scheduledAt),
                }))
            );
        }
    }, [_reminders]);

    const handleClose = () => modal.remove();

    const handleDelete = (i: number) => () => {
        remove(i);
        setExpanded((p) => {
            if (p === i) {
                return -1;
            }
            return p;
        });
    };

    const handleSubmit = async () => {
        try {
            const { reminders } = methods.getValues();
            await updateReminders.mutateAsync({
                reminders: reminders.map((el) => ({
                    ...el,
                    subject,
                    subjectId,
                    scheduledAt: toISODateTime(el.scheduledAt),
                    ...(el.ownId && { id: el.ownId }),
                })),
                subject,
                subjectId,
            });
            handleClose();
        } catch (err) {
            console.log(err);
        }
    };

    const handleCreate = () => {
        if (booking?.startDateTime) {
            setExpanded(reminders.length);
            append({
                scheduledAt: addDays(new Date(booking.startDateTime), -1),
                templateId: "c-reminder-booking-upcoming",
                channels: ["email"],
            });
        }
    };

    const handleToggle = (index: number) => () => {
        setExpanded((p) => {
            if (index === p) {
                return -1;
            }
            return index;
        });
    };

    return (
        <FormProvider {...methods}>
            <Dialog open={modal.visible} fullWidth maxWidth={"sm"}>
                <Header pl={3} title={"Påmindelser"} onClose={handleClose} />
                <Box p={3}>
                    {!isLoading && booking && reminders.length > 0 && (
                        <Stack spacing={2} alignItems={"center"} width={"100%"} mb={2}>
                            {reminders.map((el, i) => (
                                <Reminder
                                    key={el.id}
                                    index={i}
                                    width={"100%"}
                                    scheduledAt={el.scheduledAt}
                                    expanded={expanded === i}
                                    channels={el.channels}
                                    templateId={el.templateId}
                                    onDelete={handleDelete(i)}
                                    onToggle={handleToggle(i)}
                                    maxDateTime={new Date(booking.startDateTime)}
                                    templates={templates}
                                />
                            ))}
                        </Stack>
                    )}
                    <Button size={"small"} onClick={handleCreate} startIcon={<AddCircleOutlined />}>
                        Tilføj påmindelse
                    </Button>
                </Box>

                <Divider />
                <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    p={2}
                    spacing={1}
                >
                    <ProgressButton
                        onClick={handleSubmit}
                        label={"Gem"}
                        size={"large"}
                        variant={"contained"}
                    />
                </Stack>
            </Dialog>
        </FormProvider>
    );
});

const Row = ({ label, value, ...props }: { label: string; value: ReactNode } & StackProps) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} {...props}>
            <Typography variant={"h6"}>{label}</Typography>
            {value}
        </Stack>
    );
};

type ReminderProps = {
    title?: string;
    index: number;
    toggled?: boolean;
    maxDateTime: Date;
    onDelete?: () => void;
    onToggle?: () => void;
    expanded?: boolean;
    templates?: string[];
    scheduledAt: Date;
} & Pick<Reminder, "templateId" | "channels"> &
    BoxProps;
const Reminder = ({
    title,
    toggled = false,
    onDelete,
    scheduledAt,
    templateId,
    channels,
    maxDateTime,
    children,
    index,
    onToggle,
    expanded = false,
    templates,
    ...props
}: ReminderProps) => {
    const { control } = useFormContext<FormData>();

    const { field: templateIdField } = useController({
        name: `reminders.${index}.templateId`,
        control,
        defaultValue: templateId,
    });
    const { field: scheduledAtField } = useController({
        name: `reminders.${index}.scheduledAt`,
        control,
        defaultValue: new Date(scheduledAt),
    });
    const { field: channelsField } = useController({
        name: `reminders.${index}.channels`,
        control,
        defaultValue: channels,
    });

    /*const dateSoonerThanMax = !(
        maxDateTime &&
        selectedDateTime !== null &&
        new Date(maxDateTime) < selectedDateTime
    );*/

    const handleStep = (unit: "day" | "hour", value: number) => () => {
        const newDate =
            unit === "day"
                ? addDays(scheduledAtField.value, value)
                : addHours(scheduledAtField.value, value);
        if (newDate < maxDateTime && newDate > new Date()) {
            scheduledAtField.onChange(newDate);
        }
    };

    return (
        <Box {...props}>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    ...props.sx,
                    ...{ p: "12px 16px", backgroundColor: "#F6F6F6", borderRadius: "4px" },
                }}
            >
                <Typography>
                    {templateIdField.value}
                    <Typography component={"span"} variant={"body2"} display={"block"}>
                        {formatDistance(scheduledAtField.value, maxDateTime)} før
                    </Typography>
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                    {onDelete && (
                        <IconButton onClick={onDelete} size={"small"} sx={{ ml: 1 }}>
                            <DeleteOutlined color={"error"} fontSize={"small"} />
                        </IconButton>
                    )}
                    <IconButton size={"small"} sx={{ ml: 1 }} onClick={onToggle}>
                        {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={expanded}>
                <Divider />
                <Box
                    sx={{
                        ...props.sx,
                        ...{ p: "12px 16px", backgroundColor: "#F6F6F6", borderRadius: "4px" },
                    }}
                >
                    <Stack my={2}>
                        <Stack spacing={2} display={"inline-flex"}>
                            <Row
                                label={"Kanaler"}
                                value={
                                    <OptionChips
                                        width={"auto"}
                                        allOptions={[
                                            { key: "email", label: "Email" },
                                            { key: "sms", label: "SMS" },
                                        ]}
                                        selectedOptions={channelsField.value}
                                        multiple
                                        onChange={channelsField.onChange}
                                    />
                                }
                            />
                            <Row
                                label={"Skabelon"}
                                value={
                                    <FormControl sx={{ minWidth: 340 }}>
                                        <InputLabel id="template-select-label">
                                            Vælg skabelon
                                        </InputLabel>
                                        <Select
                                            labelId="template-select-label"
                                            label="Vælg skabelon"
                                            onChange={templateIdField.onChange}
                                            value={templateIdField.value}
                                        >
                                            {templates?.map((el) => (
                                                <MenuItem key={el} value={el}>
                                                    {el}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            />
                            <Row
                                label={"Udsendelse"}
                                value={
                                    <CustomDateTimePicker
                                        label={"Tidspunkt for udsendelse"}
                                        value={scheduledAtField.value}
                                        maxDateTime={
                                            maxDateTime ? new Date(maxDateTime) : undefined
                                        }
                                        onChange={scheduledAtField.onChange}
                                        onUpdate={scheduledAtField.onChange}
                                    />
                                }
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Collapse>
        </Box>
    );
};
