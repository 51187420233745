import { Page } from "../../Components/Page/Page";
import React, { ComponentPropsWithoutRef, useMemo, useState } from "react";
import {
    Box,
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItemProps,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import {
    ArrowBackRounded,
    AutoFixHighOutlined,
    DeleteForeverRounded,
    EditRounded,
    MoreVert,
    PauseCircleFilledOutlined,
    PlayCircleFilledOutlined,
    SvgIconComponent,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import react from "react";
import { BookingsList } from "../../Components/BookingsList/BookingsList";
import { BlackMenu } from "../../Components/BlackMenu/BlackMenu";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { useTranslate } from "../../Hooks/useTranslate";
import { ShowForRole } from "../../Components/AllowForRole/AllowForRole";
import { getFunnelUrl, useFunnels } from "../../Hooks/useFunnels";
import { formatMoney } from "../../Components/VariantSelect/VariantSelect";
import { FunnelCard } from "./FunnelsPage";
import { useLocalizer } from "../Events/EventSyiSections/EventSyiSectionDetails";
import { CustomTabs } from "../../Components/CustomTabs/CustomTabs";
import { LeadsList } from "../../Components/LeadsList/LeadsList";
import { useAuth } from "../../Hooks/useAuth";

const options: {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
}[] = [
    {
        key: "edit",
        label: "Rediger",
        icon: EditRounded,
    },
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

export const FunnelDetailsPage = ({
    funnelId,
}: ComponentPropsWithoutRef<any> & {
    funnelId: string;
}) => {
    const navigate = useNavigate();

    const { t } = useTranslate("funnel.detailsPage.card");

    const {
        funnel: { isLoading },
        enrichedFunnel: funnel,
        updateFunnel,
        deleteFunnel,
        bookings,
        leads,
    } = useFunnels(funnelId);

    const { getAccessToken } = useAuth();

    const localize = useLocalizer();

    const handleBack = () => navigate(`/funnels`);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [view, setView] = useState<"bookings" | "leads" | string>("bookings");

    const open = Boolean(anchorEl);

    const togglePublic = (newStatus: boolean) => {
        if (typeof funnel?.public === "boolean") {
            updateFunnel.mutate({ ...funnel, public: newStatus });
        }
    };

    const handleSelect = (key: string) => async () => {
        if (key === "edit")
            navigate("edit/details", {
                state: {
                    returnUrl: `/funnel/${funnelId}`,
                },
            });
        if (key === "activate") togglePublic(true);
        if (key === "deactivate") togglePublic(false);
        if (key === "delete") {
            const type = t("thisFunnel", "utils.generic");
            NiceModal.show(ConfirmDialog, {
                headline: t("headline", "dialogs.confirmDelete", { type }),
                title: t("title", "dialogs.confirmDelete"),
            }).then(() => {
                deleteFunnel.mutate(funnelId);
                handleBack();
            });
        }
        handleClose();
    };

    const handleClick = (event: react.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const _options: typeof options = useMemo(() => {
        const primary = funnel?.public
            ? {
                  key: "deactivate",
                  icon: PauseCircleFilledOutlined,
              }
            : {
                  key: "activate",
                  icon: PlayCircleFilledOutlined,
              };
        return [primary, ...options].map((el) => ({ ...el, label: t(`options.${el.key}`) }));
    }, [funnel]);

    const handleGoToFunnel = (willEdit = false) => {
        if (funnel?.alias) {
            const a = document.createElement("a");
            a.href = `${getFunnelUrl(funnel.alias)}${willEdit ? `#${getAccessToken()}` : ""}`;
            a.target = "_blank";
            a.click();
        }
    };

    return (
        <Page maxWidth={1200} pb={10}>
            <Box display={"flex"} alignItems={"center"} mb={4} minHeight={44}>
                <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                    <ArrowBackRounded />
                </IconButton>
                {funnel ? (
                    <Typography variant={"h3"} ml={2} fontSize={{ xs: "1.2rem", lg: "1.5rem" }}>
                        {funnel.name}
                    </Typography>
                ) : (
                    <Skeleton width={320} />
                )}
            </Box>
            <FunnelCard
                funnelId={funnelId}
                key={funnel?.id}
                coverImage={funnel?.service?.image}
                title={localize(funnel?.service?.headline)}
                name={funnel?.name}
                enableConversion={true}
                fullWidth
                variantName={localize(funnel?.service?.variantName)}
                status={funnel?.public ?? false ? "active" : "inactive"}
                createdDate={funnel?.created ?? ""}
                discountAmount={formatMoney(t)({
                    value: funnel?.config.discountAmount ?? 0,
                    nativeCurrency: "dkk",
                })}
            >
                <>
                    <div>
                        <ShowForRole roles={["admin"]}>
                            <IconButton onClick={handleClick}>
                                <MoreVert />
                            </IconButton>
                            <BlackMenu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                {_options.map((el, i) => {
                                    const Icon = el.icon as any;
                                    return (
                                        <MenuItem onClick={handleSelect(el.key)} autoFocus={false}>
                                            <ListItemIcon>
                                                <Icon fontSize={"small"} {...el.props} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={el.props}>
                                                {el.label}
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })}
                            </BlackMenu>
                        </ShowForRole>
                    </div>

                    {
                        <Stack spacing={2} direction={"row"} p={1}>
                            <Button
                                variant={"outlined"}
                                startIcon={<AutoFixHighOutlined />}
                                color={"secondary"}
                                onClick={() => handleGoToFunnel(true)}
                            >
                                {t("actions.primary")}
                            </Button>
                            <Button
                                onClick={() => handleGoToFunnel(false)}
                                variant={"outlined"}
                                color={"secondary"}
                                size={"large"}
                            >
                                {t("actions.secondary")}
                            </Button>
                        </Stack>
                    }
                </>
            </FunnelCard>
            <Box mt={5} pb={8}>
                <CustomTabs
                    items={[
                        { key: "bookings", label: "Bookings", badge: bookings?.length },
                        { key: "leads", label: "Henvendelser", badge: leads?.length },
                    ]}
                    onChange={setView}
                    selected={view}
                    sx={{
                        borderBottom: "1px solid #EFEFEF",
                    }}
                />
                {view === "bookings" ? (
                    <BookingsList
                        mt={4}
                        bookings={isLoading ? undefined : bookings}
                        type={"session"}
                        showTitle={false}
                        openModalInline={true}
                    />
                ) : (
                    <LeadsList openModalInline={true} showService={false} mt={4} leads={leads} />
                )}
            </Box>
        </Page>
    );
};
