import { UseControllerReturn } from "react-hook-form";
import { useTranslate } from "../../Hooks/useTranslate";
import React, { useRef, useState } from "react";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { CalendarIcon } from "../../Icons";
import { getHours, setHours } from "date-fns";
import { Box, BoxProps, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { ArrowDropDown, DeleteForeverRounded } from "@mui/icons-material";
import { toISODateTime } from "../../Pages/Events/EventSyiSections/EventSyiSectionDetails";

const BoxGrid = ({ children, ...props }: BoxProps) => {
    return (
        <Box
            display={"grid"}
            gridTemplateColumns={"50% 25% 25% 1fr"}
            alignItems={"center"}
            sx={{
                "& .MuiFormControl-root": {
                    minWidth: "auto",
                },
                "& > *:not(:first-child)": {
                    marginLeft: "16px",
                },
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

const validDate = (val: Date | null) => (cb: () => void) => {
    try {
        new Date(val as Date);
        cb();
    } catch (err) {}
};

const getHourToUse = (existing: string | undefined, defaultHour: number) => {
    if (!existing) {
        return defaultHour;
    }
    try {
        new Date(existing);
        return getHours(new Date(existing));
    } catch (err) {
        return defaultHour;
    }
};

export const DateController = ({
    onChange,
    onDelete,
    hideFrom = false,
    hideTo = false,
    startDateTime,
    disabled = false,
    endDateTime,
    label = "Startdato",
    ...props
}: Partial<
    Omit<UseControllerReturn["field"], "onChange"> & {
        label: string;
        disabled?: boolean;
        onChange?: () => void;
        hideFrom: boolean;
        hideTo: boolean;
        endDateTime?: UseControllerReturn<any, any>["field"];
        startDateTime?: UseControllerReturn<any, any>["field"];
        onDelete: () => void;
    }
>) => {
    const { t } = useTranslate("utils.generic");

    const [hasNoEndTime, setHasNoEndTime] = useState(false);

    const [open, setOpen] = useState(false);

    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const handleChange = (cb?: (val: Date) => void) => (value: any) => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            if (cb && value && value instanceof Date) {
                validDate(value)(() => cb(value));
            }
        }, 800);
    };

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <BoxGrid width={"100%"}>
                <DesktopDatePicker
                    disablePast={true}
                    label={label}
                    open={open}
                    disabled={disabled}
                    format={"PPPP"}
                    onClose={() => setOpen(false)}
                    slots={{
                        openPickerIcon: CalendarIcon,
                    }}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            inputProps: {
                                placeholder: t("selectDate"),
                                style: {
                                    cursor: disabled ? "initial" : "pointer",
                                },
                                disabled,
                            },
                            onClick: () => {
                                if (!disabled) {
                                    setOpen(true);
                                }
                            },
                        },
                    }}
                    value={startDateTime?.value ? new Date(startDateTime?.value) : null}
                    onChange={(val: Date | null) => {
                        validDate(val)(() => {
                            startDateTime?.onChange?.(
                                toISODateTime(
                                    setHours(val!, getHourToUse(startDateTime?.value, 9)) as Date
                                )
                            );
                            endDateTime?.onChange?.(
                                toISODateTime(
                                    setHours(val!, getHourToUse(endDateTime?.value, 10)) as Date
                                )
                            );
                            onChange?.();
                        });
                    }}
                />
                {!hideFrom && (
                    <DesktopTimePicker
                        label={t("fromTime")}
                        ampm={false}
                        disabled={hideFrom || !startDateTime?.value || disabled}
                        slots={{
                            openPickerIcon: ArrowDropDown,
                        }}
                        slotProps={{
                            textField: {
                                sx: { minWidth: 320, maxWidth: 320 },
                            },
                        }}
                        value={startDateTime?.value ? new Date(startDateTime?.value) : null}
                        onChange={handleChange((val) =>
                            startDateTime?.onChange(toISODateTime(val))
                        )}
                    />
                )}

                {!hideTo && (
                    <DesktopTimePicker
                        label={t("toTime")}
                        ampm={false}
                        disabled={
                            hideTo ||
                            !startDateTime?.value ||
                            disabled ||
                            endDateTime?.value === null
                        }
                        slots={{
                            openPickerIcon: ArrowDropDown,
                        }}
                        slotProps={{
                            textField: {
                                sx: { minWidth: 320, maxWidth: 320 },
                            },
                        }}
                        value={endDateTime?.value ? new Date(endDateTime?.value) : null}
                        onChange={handleChange((val) =>
                            endDateTime?.onChange?.(toISODateTime(val))
                        )}
                    />
                )}

                <Box>
                    <IconButton
                        color={"error"}
                        sx={{ visibility: onDelete ? "auto" : "hidden" }}
                        onClick={onDelete}
                    >
                        <DeleteForeverRounded />
                    </IconButton>
                </Box>
            </BoxGrid>
            {/*{!hideTo && !hideFrom && (
                <FormControlLabel
                    sx={{ alignSelf: "flex-end", mr: 0 }}
                    labelPlacement={"start"}
                    disabled={hideTo || !startDateTime?.value || disabled}
                    control={<Checkbox size={"small"} />}
                    label={"No end time"}
                    onChange={(e, newValue) => endDateTime?.onChange(newValue ? null : undefined)}
                    checked={endDateTime?.value === null}
                    componentsProps={{ typography: { fontWeight: 600, fontSize: "0.75em" } }}
                />
            )}*/}
        </Box>
    );
};
