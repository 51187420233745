import { useController, useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslate } from "../../Hooks/useTranslate";
import { useUsers } from "../../Hooks/useUsers";
import { useProfile } from "../../Hooks/useProfile";
import { IService, useService } from "../../Hooks/useService";
import { useAssignees } from "../../Hooks/useAssignees";
import NiceModal from "@ebay/nice-modal-react";
import {
    Box,
    Chip,
    ChipProps,
    Divider,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Select,
    SelectProps,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { OptionChips } from "../../Components/OptionChips/OptionChips";
import { mapOptions } from "../../Utils/helpers";
import { serviceChannelOptions } from "../../Pages/Services/ServiceSyiSections/ServiceSyiSectionDetails";
import { IconRow } from "../CalendarPreview";
import { LocationIcon } from "../../Icons";
import {
    DeleteOutlined,
    NotesOutlined,
    PeopleOutlined,
    RecordVoiceOverOutlined,
} from "@mui/icons-material";
import { useAddress } from "../../Hooks/useAddress";
import { CreateCustomerDialog } from "../CreateCustomerDialog";
import { type ManualBooking } from "./CreateNewBookingDialog";
import { Customer } from "../../Utils/types";
import isEmpty from "lodash.isempty";

type DetailsProps = {
    serviceId?: string;
};
export const Details = ({ serviceId }: DetailsProps) => {
    const { control, register } = useFormContext<ManualBooking>();

    const [showNoteInput, setShowNoteInput] = useState(false);

    const { t } = useTranslate("utils.generic");

    const { field: customer } = useController({ name: "customer", control });
    const { field: channels } = useController({ name: "channels", control });
    const { field: assignees } = useController({ name: "assignees", control });

    const { field: upsert } = useController({
        name: "upsertCustomer",
        defaultValue: true,
    });

    const internalNote = useWatch({ control, name: "internalNote" });

    const {
        users: { data: allUsers },
    } = useUsers();

    const { me } = useProfile();

    const {
        service: { data: service },
    } = useService(serviceId);

    const { renderPopover, canAddAssignee } = useAssignees(assignees.value);

    const users = useMemo(() => {
        return allUsers?.filter((el) => assignees?.value?.includes(el.id));
    }, [allUsers, assignees.value]);

    useEffect(() => {
        if (!assignees.value) {
            assignees.onChange(service?.assignees);
        }
    }, [service]);

    useEffect(() => {
        if (!channels.value) {
            const [first] = service?.channels ?? [];
            if (first) {
                channels.onChange([first]);
            }
        }
    }, [service]);

    const handleAddCustomer = () => {
        NiceModal.show(CreateCustomerDialog, {
            onSubmit: (payload: Customer, shouldUpsert: boolean) => {
                upsert.onChange(shouldUpsert);
                customer.onChange(payload);
            },
            defaultValues: {
                ...customer.value,
            },
            upsert: upsert.value,
        });
    };

    const handleRemoveAssignee = (id: string) => {
        assignees.onChange(assignees.value?.filter((el) => el !== id));
    };

    const handleAddAssignee: ChipProps["onClick"] = async (evt) => {
        const userId = await renderPopover(evt.currentTarget, assignees.value);
        assignees.onChange([...assignees.value, userId]);
    };

    const handleRemoveCustomer = () => {
        customer.onChange(undefined);
    };

    return (
        <>
            {service?.channels && service.channels.length > 1 && (
                <>
                    <Box py={1.5} px={2}>
                        <OptionChips
                            multiple={false}
                            selectedOptions={channels.value}
                            onChange={channels.onChange}
                            allOptions={mapOptions(
                                serviceChannelOptions.filter((el) =>
                                    service.channels.includes(el.key)
                                )
                            )}
                        />
                    </Box>
                    <Divider />
                </>
            )}
            <Stack p={3} pb={0} spacing={2}>
                <IconRow Icon={LocationIcon}>
                    {channels.value?.includes("online") && service?.meetingLink ? (
                        <Link href={service.meetingLink} target={"_blank"}>
                            Gå til mødelink
                        </Link>
                    ) : (
                        <AddressControl service={service} control={control} />
                    )}
                </IconRow>
                <IconRow Icon={PeopleOutlined} whiteSpace={"pre-wrap"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Link onClick={handleAddCustomer} whiteSpace={"pre-wrap"}>
                            {customer.value && !isEmpty(customer.value)
                                ? Object.entries(customer.value)
                                      .filter(([k]) => k !== "id")
                                      .map(([, v]) => v)
                                      .join("\n")
                                : "Tilføj klient"}
                        </Link>
                        {customer.value && !isEmpty(customer.value) && (
                            <IconButton
                                onClick={handleRemoveCustomer}
                                sx={{ ml: 1 }}
                                size={"small"}
                            >
                                <DeleteOutlined fontSize={"small"} />
                            </IconButton>
                        )}
                    </Box>
                </IconRow>
                <IconRow Icon={NotesOutlined}>
                    {showNoteInput || internalNote ? (
                        <TextField
                            fullWidth
                            minRows={3}
                            multiline
                            label={"Intern note"}
                            {...register("internalNote")}
                        />
                    ) : (
                        <Link onClick={() => setShowNoteInput(true)}>Tilføj note</Link>
                    )}
                </IconRow>
                <IconRow Icon={RecordVoiceOverOutlined} minHeight={32}>
                    {users ? (
                        <Grid container spacing={1} alignItems={"center"} minHeight={32}>
                            {users.map((el) => {
                                const name =
                                    me.data?.email === el.email
                                        ? t("me", "utils.generic")
                                        : el.name;
                                return (
                                    <Grid item>
                                        <Chip
                                            onDelete={() => handleRemoveAssignee(el.id)}
                                            label={name}
                                        />
                                    </Grid>
                                );
                            })}
                            {canAddAssignee && (
                                <Grid item>
                                    <Chip
                                        variant={"outlined"}
                                        onClick={handleAddAssignee}
                                        label={"Tilføj"}
                                        clickable
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Link>Tilføj facilitator</Link>
                    )}
                </IconRow>
            </Stack>
        </>
    );
};

const AddressControl = ({
    service,
    control,
}: {
    service: IService | undefined;
    control: UseFormReturn<ManualBooking>["control"];
}) => {
    const { address } = useAddress(service?.locations);
    const { field: location } = useController({
        name: "location",
        control,
    });

    useEffect(() => {
        location.onChange(Array.isArray(address) ? address[0] : address);
    }, [address]);

    if (Array.isArray(address)) {
        if (address.length > 1) {
            return (
                <Select displayEmpty size={"small"} {...location} defaultValue={address[0]}>
                    {address.map((el) => (
                        <MenuItem value={el} key={el}>
                            {el}
                        </MenuItem>
                    ))}
                </Select>
            );
        }
        return <Typography>{address[0]}</Typography>;
    }
    return address ? <Typography>{address}</Typography> : null;
};
