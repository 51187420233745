import { EmptyScreen } from "../../Components/EmptyScreen/EmptyScreen";
import { CrudPage } from "../CrudPage";
import React from "react";
import { useTranslate } from "../../Hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { CampaignsList } from "./CampaignsList";
import { useCampaigns } from "../../Hooks/useCampaigns";

export const CampaignsPage = () => {
    const { t } = useTranslate("campaign");

    const { enrichedCampaigns, deleteCampaign } = useCampaigns();

    const navigate = useNavigate();

    const handleCreate = () => navigate("/campaign/create", { state: { returnUrl: "/campaigns" } });

    const handleDelete = (id: string) => {
        deleteCampaign.mutate(id);
    };
    return (
        <CrudPage
            title={t("title")}
            titleProps={{
                variant: "h3",
            }}
            createButtonLabel={t("buttonLabel")}
            handleCreate={handleCreate}
        >
            {enrichedCampaigns && enrichedCampaigns.length > 0 ? (
                <CampaignsList onDelete={handleDelete} campaigns={enrichedCampaigns ?? []} />
            ) : (
                <EmptyScreen
                    imageSrc={"/empty-screens/event.png"}
                    imageProps={{ width: 300, height: 280 }}
                    maxWidth={720}
                    title={t("emptyState.title")}
                    direction={"row"}
                    description={t("emptyState.description")}
                    onClick={handleCreate}
                    buttonLabel={t("emptyState.action")}
                    pt={6}
                    pb={5}
                />
            )}
        </CrudPage>
    );
};
