import { Box, Button, Stack, Typography } from "@mui/material";
import { Page } from "../Components/Page/Page";
import { CalendarIcon, DashboardIcon } from "../Icons";
import { Outlet } from "react-router-dom";
import { LaunchRounded } from "@mui/icons-material";
import { useProfile } from "../Hooks/useProfile";
import { useTranslate } from "../Hooks/useTranslate";

const tabs = [
    {
        key: "welcome",
        label: "Overblik",
        Icon: DashboardIcon,
    },
    {
        key: "overview",
        label: "Overblik",
        Icon: DashboardIcon,
    },
    {
        key: "calendar",
        label: "Kalender",
        Icon: CalendarIcon,
    },
];

export const DashboardPage = () => {
    const { t } = useTranslate("dashboard");

    const { companyProfileUrl, onboardingCompleted } = useProfile();

    return (
        <Page maxWidth={1050}>
            <Box mt={4} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    <Typography variant={"h2"}>{t("title")}</Typography>

                    {onboardingCompleted && companyProfileUrl() && (
                        <Stack direction={"row"} spacing={1}>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                href={"https://dashboard.stripe.com/dashboard"}
                                target={"_blank"}
                            >
                                {t("buttons.goToStripe")}
                            </Button>
                            <Button
                                variant={"contained"}
                                startIcon={<LaunchRounded />}
                                sx={{
                                    background:
                                        "linear-gradient(105.74deg, #3D207A 1.03%, #6E519F 97.7%)",
                                }}
                                href={companyProfileUrl()}
                                target={"_blank"}
                            >
                                {t("buttons.goToSpiritworld")}
                            </Button>
                        </Stack>
                    )}
                </Stack>

                <Outlet />
            </Box>
        </Page>
    );
};
