import { Box, Collapse, Link, LinkProps, Stack, StackProps, Typography } from "@mui/material";
import { CalendarWarningIcon } from "../Icons";
import React from "react";

export const DoubleBookingWarning = ({
    onClick,
    bookingId,
    type,
    ...props
}: Omit<StackProps, "onClick"> & {
    onClick?: LinkProps["onClick"];
    type?: "overlapping" | "violatePadding";
    bookingId?: string;
}) => {
    return (
        <Collapse in={Boolean(bookingId)}>
            <Stack
                flexGrow={1}
                direction={"row"}
                spacing={1}
                borderRadius={1}
                p={2}
                display={"flex"}
                {...props}
                sx={{ border: "1px solid #FF3B30", background: "#FEEFEF", ...props.sx }}
            >
                <Box pt={0.5}>
                    <CalendarWarningIcon color={"error"} />
                </Box>

                <Box>
                    <Typography color={"error"}>
                        <strong>Advarsel</strong> <br />
                        {type === "overlapping"
                            ? "Booking eksisterer allerede på dato."
                            : "Du vil overskride din pausetid."}
                    </Typography>
                </Box>
            </Stack>
        </Collapse>
    );
};
