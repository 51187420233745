import { CrudPage } from "./CrudPage";
import { useBookings } from "../Hooks/useBookings";
import React, { useEffect } from "react";
import { Box, BoxProps } from "@mui/material";
import { CustomTabs } from "../Components/CustomTabs/CustomTabs";
import { useLeads } from "../Hooks/useLeads";
import { useCustomers } from "../Hooks/useCustomers";
import NiceModal from "@ebay/nice-modal-react";
import { OfferDialog } from "../Modals/OfferDialog";
import { useOffers } from "../Hooks/useOffers";
import { useMatch, useNavigate } from "react-router-dom";
import { CustomerDialog } from "../Modals/CustomerDialog/CustomerDialog";
import { LeadDialog } from "../Modals/LeadDialog";

export const CustomersPage = ({ children }: BoxProps) => {
    const navigate = useNavigate();

    const {
        params: { collection = "bookings" },
    } = useMatch("/:collection/*") ?? { params: {} };

    const {
        params: { id },
    } = useMatch("/:collection/:id") ?? { params: {} };

    const { bookingCountWithoutBlocker } = useBookings();

    const { activeLeadsCount, closedLeads } = useLeads();

    const {
        customers: { data: customers },
    } = useCustomers();

    const {
        offers: { data: offers },
    } = useOffers();

    const handleCreate = () => {
        NiceModal.show(OfferDialog);
    };

    const handleChangeTo = (key: string) => {
        navigate(`/${key}`);
    };

    useEffect(() => {
        if (id && id !== "closed" && collection) {
            const key = ["bookings", "offers", "leads", "customers"].includes(collection)
                ? `${collection.substring(0, collection.length - 1)}Id`
                : undefined;

            if (collection === "leads" && key) {
                NiceModal.show(LeadDialog, { [key]: id });
                return;
            }
            if (key) {
                NiceModal.show(CustomerDialog, { [key]: id });
            }
        }
    }, [id]);

    return (
        <CrudPage title={"Klienter"} handleCreate={handleCreate} createButtonLabel={"Opret tilbud"}>
            <Box>
                <CustomTabs
                    items={[
                        {
                            key: "leads",
                            label: "Henvendelser",
                            badge: activeLeadsCount,
                        },
                        { key: "bookings", label: "Aftaler", badge: bookingCountWithoutBlocker },
                        { key: "offers", label: "Tilbud", badge: offers?.length },
                        { key: "customers", label: "Alle klienter", badge: customers?.length },
                        { key: "leads/closed", label: "Afvist", badge: closedLeads?.length },
                    ]}
                    onChange={handleChangeTo}
                    selected={
                        id === "closed" && collection === "leads" ? "leads/closed" : collection
                    }
                    placeholder={collection}
                    sx={{
                        borderBottom: "1px solid #EFEFEF",
                        mb: 4,
                    }}
                />

                {children}
            </Box>
        </CrudPage>
    );
};
