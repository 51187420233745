import { DesktopDateTimePicker, DesktopDateTimePickerProps } from "@mui/x-date-pickers";
import { addDays, addHours } from "date-fns";
import { CalendarIcon } from "../Icons";
import { Link, Stack } from "@mui/material";
import React from "react";

type DateTimePickerProps = {
    onUpdate?: (value: Date) => void;
} & DesktopDateTimePickerProps<any>;
export const CustomDateTimePicker = ({
    onUpdate,
    value,
    maxDateTime,
    ...props
}: DateTimePickerProps) => {
    const handleStep = (unit: "day" | "hour", newValue: number) => () => {
        const newDate = unit === "day" ? addDays(value, newValue) : addHours(value, newValue);
        if (newDate < maxDateTime && newDate > new Date()) {
            onUpdate?.(newDate);
        }
    };
    return (
        <DesktopDateTimePicker
            sx={{ minWidth: 340 }}
            disablePast={true}
            format={"PPPPp"}
            slots={{
                openPickerIcon: CalendarIcon,
            }}
            value={value}
            maxDateTime={maxDateTime}
            {...props}
            {...{
                ...(onUpdate && {
                    slotProps: {
                        textField: {
                            helperText: (
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    spacing={2}
                                >
                                    <Stack direction={"row"} spacing={1}>
                                        <Link onClick={handleStep("day", -1)}>- 1 dag</Link>
                                        <Link onClick={handleStep("hour", -1)}>- 1 time</Link>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <Link onClick={handleStep("hour", 1)}>+ 1 time</Link>
                                        <Link onClick={handleStep("day", 1)}>+ 1 dag</Link>
                                    </Stack>
                                </Stack>
                            ),
                        },
                    },
                }),
            }}
        />
    );
};
