import { useTranslate } from "../../Hooks/useTranslate";
import { useController, useFieldArray, useWatch } from "react-hook-form";
import { weekdays } from "../../Utils/config";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    ChipProps,
    FormControlLabel,
    Grid,
    MenuItem,
    Popover,
    PopoverProps,
    Select,
    SelectProps,
    Stack,
    StackProps,
    Typography,
} from "@mui/material";
import { ellipsisStyle } from "../../Utils/theme";
import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

const pad = (num: number) => (num < 10 ? `0${num}` : num.toString(10));

const asTime = (mins: number) => {
    return `${pad(Math.floor(mins / 60))}:${pad(Math.round(mins % 60))}`;
};

const times = Array.from(Array(24).keys()).reduce<{ key: number; label: string }[]>(
    (acc, el, i) => {
        if (i === 0) {
            return acc;
        }
        return [
            ...acc,
            ...Array.from(Array(4).keys()).map((m) => {
                const mins = el * 60 + m * 15;
                return {
                    key: mins,
                    label: asTime(mins),
                };
            }),
        ];
    },
    []
);

const getLaterTimes = (from?: number) => {
    if (typeof from === "number") {
        const index = times.findIndex((el) => el.key === from);
        if (index >= 0) {
            const _new = [...times];
            _new.splice(0, index);
            return _new;
        }
    }
    return times;
};

export const OpeningHoursControl = ({
    propKey,
    global = false,
    channels,
}: {
    propKey: string;
    global?: boolean;
    channels?: { id: string; label: string }[];
}) => {
    const { t } = useTranslate("utils.weekdays");

    const _weekdays = weekdays.map((key) => ({ key, label: t(key) }));

    return (
        <Stack spacing={2} justifyContent={"center"}>
            {(global ? [{ key: "global", label: null }] : _weekdays).map((el, i) => {
                const k = el.key !== "global" ? `.${el.key}` : "";
                return (
                    <OpeningHourRow
                        channels={global ? undefined : channels}
                        allowToggle={!global}
                        label={el.label}
                        propKey={`${propKey}${k}`}
                        key={el.key}
                    />
                );
            })}
        </Stack>
    );
};

const LABEL_WIDTH = 88;
const OpeningHourRow = ({
    label,
    propKey,
    allowToggle = true,
    channels,
    ...props
}: StackProps & {
    label: string | null;
    propKey: string;
    allowToggle?: boolean;
    channels?: { id: string; label: string }[];
}) => {
    const { field: fromTime } = useController({
        name: `${propKey}.fromTime`,
        defaultValue: 60 * 9,
    });

    const { field: toTime } = useController({
        name: `${propKey}.toTime`,
        defaultValue: 60 * 16,
    });

    const { field: includeChannels } = useController({
        name: `${propKey}.includeChannels`,
    });

    const { field: enabled } = useController({
        name: `${propKey}.enabled`,
        defaultValue: true,
    });

    const handleRemoveChannel = (id: string) => {
        const filtered = includeChannels.value?.filter((el: string) => el !== id);
        includeChannels.onChange(filtered);
    };

    const handleAddChannel: ChipProps["onClick"] = async (evt) => {
        const channel = await NiceModal.show(ChannelPopover, {
            anchorEl: evt.currentTarget,
            channels: channels?.filter((el) => !includeChannels.value?.includes(el.id)),
        });
        includeChannels.onChange([...includeChannels.value, channel]);
    };

    const getLabel = (id: string) => {
        return channels?.find((el) => el.id === id)?.label;
    };

    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"} {...props}>
                {label && (
                    <Typography
                        width={LABEL_WIDTH}
                        variant={"h5"}
                        sx={{ opacity: enabled ? 1 : 0.5 }}
                    >
                        {label}
                    </Typography>
                )}

                <Select
                    sx={{ minWidth: 150, mr: 2 }}
                    labelId="fromTime-select-label"
                    id="fromTime-select"
                    defaultValue={fromTime}
                    disabled={enabled.value === false}
                    {...fromTime}
                >
                    <MenuItem value={0}>Åbner kl.</MenuItem>
                    {times.map((el) => (
                        <MenuItem key={el.key} value={el.key}>
                            {el.label}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    sx={{ minWidth: 150 }}
                    labelId="toTime-select-label"
                    id="toTime-select"
                    defaultValue={toTime}
                    disabled={enabled.value === false}
                    {...toTime}
                >
                    {(!fromTime || fromTime.value === 0) && (
                        <MenuItem value={0}>Lukker kl.</MenuItem>
                    )}
                    {getLaterTimes(fromTime.value).map((el) => (
                        <MenuItem key={el.key} value={el.key}>
                            {el.label}
                        </MenuItem>
                    ))}
                </Select>
                {allowToggle && (
                    <FormControlLabel
                        sx={{ ml: 1 }}
                        control={<Checkbox size={"small"} />}
                        label={"Lukket"}
                        checked={!enabled.value}
                        onChange={(_, checked) => enabled.onChange(!checked)}
                    />
                )}
                {channels && (
                    <FormControlLabel
                        sx={{ ml: 1 }}
                        disabled={!enabled.value}
                        control={<Checkbox size={"small"} />}
                        label={"Bestem placering"}
                        checked={Boolean(includeChannels.value)}
                        onChange={(_, checked) =>
                            includeChannels.onChange(checked ? [] : undefined)
                        }
                    />
                )}
            </Stack>
            {Array.isArray(includeChannels.value) && enabled.value && (
                <Grid
                    ml={`${LABEL_WIDTH - 8}px`}
                    mt={1}
                    container
                    spacing={1}
                    alignItems={"center"}
                    minHeight={32}
                >
                    {includeChannels.value.map((id) => (
                        <Grid item key={id}>
                            <Chip
                                sx={{
                                    ...ellipsisStyle,
                                    maxWidth: 180,
                                    border: "1px solid transparent",
                                }}
                                size={"small"}
                                label={getLabel(id)}
                                onDelete={() => handleRemoveChannel(id)}
                            />
                        </Grid>
                    ))}
                    {includeChannels.value?.length !== channels?.length && (
                        <Grid item>
                            <Chip
                                variant={"outlined"}
                                onClick={handleAddChannel}
                                label={"Tilføj"}
                                clickable
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Box>
    );
};

type ChannelPopoverProps = {
    channels?: { id: string; label: string }[];
    anchorEl: PopoverProps["anchorEl"];
    onChange: (id: string) => void;
};

const ChannelPopover = NiceModal.create<ChannelPopoverProps>(({ channels, anchorEl }) => {
    const modal = useModal();

    const handleClose = () => {
        modal.remove();
    };
    const handleChange: SelectProps["onChange"] = (evt) => {
        modal.resolve(evt.target.value);
        handleClose();
    };
    return channels && channels?.length > 0 ? (
        <Popover open={true} anchorEl={anchorEl} onClose={handleClose}>
            <Select onChange={handleChange} displayEmpty defaultValue={""}>
                <MenuItem value={""}>Vælg placering</MenuItem>
                {channels?.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                        {el.label}
                    </MenuItem>
                ))}
            </Select>
        </Popover>
    ) : null;
});
