import { useDiscount } from "../../Hooks/useDiscount";
import { useTranslate } from "../../Hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { CrudPage } from "../../Pages/CrudPage";
import { DiscountsList } from "../../Components/DiscountsList/DiscountsList";
import { EmptyScreen } from "../../Components/EmptyScreen/EmptyScreen";
import React, { useMemo } from "react";

export const MarketingDiscountsSection = () => {
    const { discounts, deleteDiscount } = useDiscount();

    const { t } = useTranslate("discounts");

    const navigate = useNavigate();

    const handleDelete = (id: string) => {
        deleteDiscount.mutate(id);
    };

    const hasNoDiscounts = useMemo(() => {
        return !discounts.isLoading && discounts.data && discounts.data?.length === 0;
    }, [discounts]);

    const handleCreate = () => navigate("/discount/create", { state: { returnUrl: "/discounts" } });

    return (
        <CrudPage
            title={t("title")}
            titleProps={{
                variant: "h3",
            }}
            createButtonLabel={t("buttonLabel")}
            handleCreate={handleCreate}
        >
            {hasNoDiscounts ? (
                <EmptyScreen
                    imageSrc={"/empty-screens/event.png"}
                    imageProps={{ width: 300, height: 280 }}
                    maxWidth={720}
                    title={t("emptyState.title")}
                    direction={"row"}
                    description={t("emptyState.description")}
                    onClick={handleCreate}
                    buttonLabel={t("emptyState.action")}
                    pt={6}
                    pb={5}
                />
            ) : (
                <DiscountsList onDelete={handleDelete} discounts={discounts.data} />
            )}
        </CrudPage>
    );
};
