import { useQuery } from "react-query";
import * as api from "../Api";
import { useEffect } from "react";

export const useMetaLogin = (accountId?: string) => {
    const ValidQueryKey = ["meta", "connection", "valid"];
    const AccountsQueryKey = ["meta", "connection", "accounts"];
    const SubscribedAccountsQueryKey = ["meta", "connection", "accounts", "subscribed"];
    const FormsQueryKey = ["meta", "connection", "forms"];
    const valid = useQuery(ValidQueryKey, () => api.validConnection(), { enabled: true });
    const accounts = useQuery(AccountsQueryKey, () => api.getAccounts(), { enabled: true });
    const subscribedAccounts = useQuery(
        SubscribedAccountsQueryKey,
        () => api.getSubscribedAccounts(),
        {
            enabled: true,
        }
    );
    const forms = useQuery(FormsQueryKey, () => api.getForms(accountId), {
        enabled: Boolean(accountId),
    });
    useEffect(() => {
        if (typeof accountId === "string") {
            forms.refetch();
        }
    }, [accountId]);
    return { valid, accounts, forms, subscribedAccounts };
};
