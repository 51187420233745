import { Divider, Stack, Typography } from "@mui/material";
import { useController, UseControllerProps, useWatch } from "react-hook-form";
import { useErrors } from "../../SyiPage/useErrors";
import { TranslatableGroup } from "../../../Components/TranslatableGroup/TranslatableGroup";
import { languagesOptions } from "../../../Utils/config";
import { LanguageChips } from "../../../Components/LanguageChips/LanguageChips";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { useLanguages } from "../../../Hooks/useLanguages";

export const SOURCE_LANG = "da";

const useDescriptionGroupsDefinition: () => {
    key: string;
    title: string;
    tip?: string;
    rules?: UseControllerProps["rules"];
    input: {
        type: "input" | "editor";
        placeholder: string;
        renderToolbar?: ((lang: string, onUpdate: (value: string) => void) => ReactNode)[];
    };
    renderControls?: {
        position: "above" | "below";
        render: (lang: string, onUpdate: (value: string) => void) => ReactNode;
    }[];
}[] = () => {
    return [
        {
            key: "headline",
            title: "Overskrift",
            rules: { required: true },
            tip: "Sørg for, at det er beskrivende og unikt, så gæster forstår, hvad du tilbyder.",
            input: {
                type: "input",
                placeholder: "Skriv en overskrift",
            },
        },
        {
            key: "description",
            title: "Det skal vi lave",
            rules: { required: true },
            tip: "Beskriv, hvad der gør din oplevelse til noget særligt.",
            input: {
                type: "editor",
                placeholder: "Fortæl gæsterne, hvad der sker under din oplevelse.",
            },
        },
        {
            key: "whatsIncluded",
            rules: { required: true },
            title: "Hvad er inkluderet? (valgfrit)",
            input: {
                type: "editor",
                placeholder: "Beskriv i punktform hvad der er inkluderet i din service.",
            },
        },
    ];
};

export const mapSelectedLanguages = (selected: string[], langs: { [key: string]: any }) => {
    return selected.map((key) => {
        // @ts-ignore
        const { emoji: label } = langs?.[key] ?? {};
        return { key, label: label! };
    });
};

export const ServiceSyiSectionDescription = () => {
    const { t } = useTranslation();
    const descriptionGroups = useDescriptionGroupsDefinition();
    const { clearError, getError } = useErrors();

    const languages = useLanguages();

    return (
        <>
            <Typography variant={"h4"}>{t("service.create.description.title")}</Typography>

            <Stack mt={3} spacing={4} divider={<Divider />}>
                {descriptionGroups.map((el, i) => {
                    return (
                        <TranslatableGroup
                            mt={0}
                            langs={mapSelectedLanguages(languages ?? [], languagesOptions)}
                            tip={t(`service.create.description.${el.key}HelpText`)}
                            propKey={el.key}
                            title={t(`service.create.description.${el.key}`)}
                            input={{
                                ...el.input,
                                placeholder: t(`service.create.description.${el.key}Placeholder`),
                            }}
                            rules={el.rules}
                            clearError={clearError}
                            error={getError(el.key)}
                        />
                    );
                })}
            </Stack>
        </>
    );
};
