import {
    Box,
    BoxProps,
    Button,
    Card,
    Grid,
    IconButton,
    IconButtonProps,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { addMinutes, formatDistanceToNow } from "date-fns";
import { StatusChip, TStatus } from "../StatusChip";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { BookingListEmptyScreen, EmptyScreen } from "../EmptyScreen/EmptyScreen";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { ListHeader } from "../ListHeader/ListHeader";
import {
    EnrichedLead,
    renderLeadService,
    renderLeadType,
    TLead,
    useLeads,
} from "../../Hooks/useLeads";
import {
    AccessTimeRounded,
    DeleteForeverRounded,
    EmailOutlined,
    MoreVert,
    PhoneOutlined,
} from "@mui/icons-material";
import { BookingsIcon } from "../../Icons";
import NiceModal from "@ebay/nice-modal-react";
import { LeadDialog } from "../../Modals/LeadDialog";
import { IconLabel } from "../IconLabel/IconLabel";
import { OfferDialog } from "../../Modals/OfferDialog";
import { useListItemOptions } from "../../Hooks/useListItemOptions";
import { ShowForRole } from "../AllowForRole/AllowForRole";
import { useConfirmDialog } from "../../Hooks/useConfirmDialog";
import { clamp, ellipsisStyle } from "../../Utils/theme";
import { useOffers } from "../../Hooks/useOffers";
import { getStatus, isOfferExpired } from "../../Utils/helpers";

const columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
        value: () => <BookingsIcon />,
    },
    {
        key: "name",
        label: "Navn",
        props: {
            xs: 2.8,
            lg: 2.6,
            pr: 0.5,
        },
        value: (lead: TLead) => (
            <>
                <Typography
                    fontSize={{ xs: "0.88em", lg: "1em" }}
                    lineHeight={"1.1"}
                    sx={{ ...ellipsisStyle, ...clamp(1) }}
                    fontWeight={"500"}
                >
                    {lead.customer?.name}
                </Typography>
                <IconLabel
                    variant={"body2"}
                    fontSize={"0.75em"}
                    Icon={AccessTimeRounded}
                    label={formatDistanceToNow(new Date(lead.created)) + " siden"}
                />
            </>
        ),
    },
    {
        key: "contact",
        label: "Kontakt",
        props: {
            xs: 2.4,
        },
        value: (lead: TLead) => (
            <>
                <IconLabel sx={ellipsisStyle} Icon={EmailOutlined} label={lead.customer?.email} />
                <IconLabel Icon={PhoneOutlined} label={lead.customer?.phone} />
            </>
        ),
    },
    {
        key: "service",
        label: "Ydelse",
        mobile: false,
        props: {
            xs: 1.5,
            pr: 0.5,
        },
        value: (lead: EnrichedLead) => (
            <Typography sx={{ ...ellipsisStyle, ...clamp(2) }} fontSize={"0.88rem"}>
                {renderLeadService(lead)}
            </Typography>
        ),
    },
    {
        key: "type",
        label: "Type",
        props: {
            xs: 2,
            lg: 1.6,
        },
        value: (lead: EnrichedLead) => <IconLabel {...renderLeadType(lead)} />,
    },
];

export type LeadsListProps = BoxProps & {
    showService?: boolean;
    openModalInline?: boolean;
};

export const LeadsListWithClosed = (props: LeadsListProps) => {
    const {
        leads: { data: data, isLoading },
    } = useLeads();

    const leads = data?.filter((lead) => lead.status === "closed") ?? [];

    return <LeadsList {...props} leads={isLoading ? undefined : leads} />;
};

export const LeadsListWithoutClosedAndBookings = (props: LeadsListProps) => {
    const {
        leads: { data: data, isLoading },
    } = useLeads();

    const leads =
        data?.filter(
            (lead) =>
                lead.status !== "closed" &&
                lead.offer?.receiptId === undefined &&
                lead.booking?.id === undefined
        ) ?? [];

    return <LeadsList {...props} leads={isLoading ? undefined : leads} />;
};

type Filter = "all" | "new" | "expired" | "pending";

const options = [
    {
        key: "reject",
        label: "Afvis",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

const getListItemActionLabel = (lead: TLead, t: TranslateFunction) => {
    if (lead.status === "closed") {
        return t("deletePermanently", "buttons");
    }
    if (lead.offer?.receiptId) {
        return t("offerAccepted", "buttons");
    }
    if (isOfferExpired(lead)) {
        return t("resendOffer", "buttons");
    }
    if (lead.offer?.id) {
        return t("offerProposed", "buttons");
    }
    return t("createOffer", "buttons");
};

const ListItemAction = ({
    onClick,
    label,
    hoverLabel,
    helperText,
    allowHover = false,
}: {
    label: string;
    helperText?: string;
    hoverLabel: string;
    allowHover?: boolean;
    onClick: IconButtonProps["onClick"];
}) => {
    const [hovering, setHovering] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);
    return (
        <Box
            position={"relative"}
            display={"flex"}
            alignItems={"flex-end"}
            flexDirection={"column"}
            minWidth={ref.current?.getBoundingClientRect().width}
        >
            <Button
                ref={ref}
                size={"small"}
                color={"secondary"}
                onClick={onClick}
                sx={{ borderRadius: 100, fontSize: "0.88rem", py: 0.5, lineHeight: 1 }}
                variant={"outlined"}
                onMouseOver={allowHover ? () => setHovering(true) : undefined}
                onMouseLeave={allowHover ? () => setHovering(false) : undefined}
            >
                {hovering ? hoverLabel : label}
            </Button>
            {helperText && (
                <IconLabel
                    variant={"body2"}
                    fontSize={"0.75rem"}
                    containerProps={{
                        position: "absolute",
                        whiteSpace: "nowrap",
                        textAlign: "right",
                        right: 0,
                        bottom: -18,
                    }}
                    iconProps={{
                        htmlColor: "rgba(150, 150, 155, 1)",
                    }}
                    Icon={AccessTimeRounded}
                    label={helperText}
                />
            )}
        </Box>
    );
};

const getTimeSince = (date: string | Date, t: TranslateFunction) => {
    const d = typeof date === "string" ? new Date(date) : date;
    return addMinutes(d, 2) > new Date()
        ? t("rightNow", "utils.generic")
        : `${formatDistanceToNow(d)} ${t("since", "utils.generic")}`;
};

export const LeadsList = ({
    leads: _leads,
    showService = true,
    openModalInline = false,
    ...props
}: LeadsListProps & { leads?: TLead[] }) => {
    const [searchValue, setSearchValue] = useState<null | string>(null);

    const [filter, setFilter] = useState<Filter | string>("all");

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const { closeLead, deleteLead } = useLeads();

    const { resend } = useOffers();

    const { confirm } = useConfirmDialog(
        "Du er ved slette dette lead permanent",
        "Er du sikker på at slette?"
    );

    const leads = useMemo(() => {
        const items = _leads?.filter((el) => {
            switch (filter) {
                case "all":
                    return true;
                case "new":
                    return !Boolean(el.offer);
                case "expired":
                    return isOfferExpired(el);
                case "pending":
                    return el.offer?.id && !el.offer.receiptId;
            }
            return true;
        });
        if (searchValue) {
            return items?.filter(({ customer }) => {
                const { name, email, phone } = customer ?? {};
                return [name, email, phone].some((el) =>
                    el?.toLowerCase().includes(searchValue.toLowerCase())
                );
            });
        }
        return items;
    }, [_leads, searchValue, filter]);

    const handleSelectOption = async (key: (typeof options)[number]["key"], index?: number) => {
        if (index === undefined || !leads?.[index]["id"]) {
            return;
        }
        if (key === "reject") {
            closeLead.mutate({ id: leads?.[index].id });
        }
    };

    const { handleClickOptions, render: renderOptionsDialog } = useListItemOptions(
        options,
        handleSelectOption
    );

    const { t } = useTranslate("utils.tables.header");

    const handleClick = (leadId: string, customerId?: string) => () => {
        NiceModal.show(LeadDialog, { leadId });
    };

    const handleClickCreateOffer = (leadId: string) => {
        const { customer } = leads?.find((el) => el.id === leadId) ?? {};
        if (customer) {
            NiceModal.show(OfferDialog, { lead: { customer, id: leadId } });
        }
    };

    const handleClickListItemAction =
        (id: string): IconButtonProps["onClick"] =>
        (evt) => {
            evt.stopPropagation();
            const lead = leads?.find((el) => el.id === id);
            if (lead?.status === "closed") {
                confirm().then(() => deleteLead.mutate(lead.id));
            } else if (lead?.offer?.id && isOfferExpired(lead)) {
                confirm(
                    "Når du gensender tilbuddet forlænges betalingsfristen",
                    "Er du sikker på at gensende?"
                ).then(async () => resend(lead?.offer?.id as string));
            } else if (lead?.offer?.id) {
                NiceModal.show(OfferDialog, { offerId: lead.offer.id });
            } else if (lead) {
                handleClickCreateOffer(lead.id);
            }
        };

    return (
        <Box {...props}>
            <ListHeader
                title={null}
                filterOptions={[
                    { key: "all", label: "Alle henvendelser" },
                    { key: "new", label: "Nye" },
                    { key: "expired", label: "Udløbet" },
                    { key: "pending", label: "Afventer klient" },
                ]}
                searchPlaceholder={"Søg i leads"}
                onSearch={setSearchValue}
                onChangeFilter={setFilter}
                defaultSelectedFilter={"all"}
                mb={2}
            />
            {renderOptionsDialog()}
            <Grid container p={"12px 20px"} mb={1}>
                {columns.map((el) => {
                    if (
                        (el.key === "service" && !showService) ||
                        (el.mobile === false && isMobile)
                    ) {
                        return null;
                    }
                    return (
                        <Grid
                            fontSize={"0.75em"}
                            fontWeight={600}
                            textTransform={"uppercase"}
                            key={el.key}
                            item
                            {...el.props}
                            component={Typography}
                        >
                            {el.key !== "none" && t(el.key)}
                        </Grid>
                    );
                })}
            </Grid>

            <Stack spacing={0}>
                {leads?.length === 0 && (
                    <BookingListEmptyScreen
                        mt={6}
                        title={t("emptyState.title", "leads")}
                        description={t("emptyState.description", "leads")}
                    />
                )}

                {leads?.map((lead, i) => {
                    const canHover =
                        Boolean(lead.offer?.id) &&
                        lead.status !== "closed" &&
                        !isOfferExpired(lead);
                    return (
                        <LeadListItem
                            key={lead.id}
                            showService={showService}
                            lead={lead}
                            onClick={handleClick}
                            onClickOptions={handleClickOptions(i)}
                            mobile={isMobile}
                        >
                            {!lead.offer?.receiptId && (
                                <ListItemAction
                                    onClick={handleClickListItemAction(lead.id)}
                                    label={getListItemActionLabel(lead, t)}
                                    hoverLabel={t("showOffer", "buttons")}
                                    allowHover={canHover}
                                    helperText={
                                        canHover && lead.offer?.created
                                            ? getTimeSince(lead.offer.created, t)
                                            : undefined
                                    }
                                />
                            )}
                        </LeadListItem>
                    );
                }) ?? <ListSkeleton />}
            </Stack>
        </Box>
    );
};

const ListItem = ({ disabled = false, ...props }: BoxProps & { disabled?: boolean }) => {
    return (
        <Box
            {...props}
            sx={{
                ...props?.sx,
                ...(disabled && {
                    color: "rgba(142, 142, 147, 1)",
                }),
            }}
        />
    );
};

const LeadListItem = ({
    onClick,
    lead,
    showService = true,
    onClickOptions,
    mobile = false,
    children,
}: Omit<BoxProps, "onClick"> & {
    lead: TLead;
    showService?: boolean;
    onClickOptions: IconButtonProps["onClick"];
    onClick: (id: string, customerId?: string) => () => void;
    mobile?: boolean;
}) => {
    return (
        <ListItem p={"4px 8px"} disabled={!getStatus(lead).startsWith("new")}>
            <Grid
                container
                component={Card}
                p={1.5}
                alignItems={"center"}
                sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "rgb(252,252,252)" },
                    color: "inherit",
                }}
                onClick={onClick(lead.id, lead.customer?.id)}
            >
                {columns.map((el) => {
                    if ((el.key === "service" && !showService) || (el.mobile === false && mobile)) {
                        return null;
                    }
                    return (
                        <Grid
                            whiteSpace={"pre-wrap"}
                            key={`${lead.id}-${el.key}`}
                            item
                            {...el.props}
                            {...el.valueProps}
                        >
                            {el.value?.(lead as EnrichedLead)}
                        </Grid>
                    );
                })}
                <Grid
                    item
                    flexGrow={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Box pr={lead.offer?.receiptId ? 0 : 2}>
                        <StatusChip status={getStatus(lead)} />
                    </Box>
                    {children}
                    <Box textAlign={"right"}>
                        <ShowForRole roles={["admin"]}>
                            <IconButton
                                sx={{
                                    ml: 1,
                                    ...(lead.offer?.receiptId && { visibility: "hidden" }),
                                }}
                                size={"small"}
                                onClick={onClickOptions}
                            >
                                <MoreVert />
                            </IconButton>
                        </ShowForRole>
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    );
};
