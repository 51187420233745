import { Box, BoxProps, Button, capitalize, Stack, StackProps, Typography } from "@mui/material";
import { Dropzone } from "../Dropzone/Dropzone";
import type { TImage } from "../../Utils/types";
import { useTranslate } from "../../Hooks/useTranslate";
import { useRef } from "react";
import * as api from "../../Api";
import { TFileTypes } from "../../types";
import { PhotoIcon } from "../../Icons";

export const ProfileUpload = ({
    media,
    subjectId,
    fileType,
    containerProps,
    children,
    subject,
    onUploadPrepared,
    label,
    ...props
}: StackProps & {
    containerProps?: BoxProps;
    media?: TImage;
    fileType: TFileTypes;
    subject: "company" | "me" | "funnel";
    subjectId?: string;
    onUploadPrepared: (...params: any[]) => void;
    label: string;
}) => {
    const { t } = useTranslate("buttons");

    const ref = useRef<HTMLInputElement | null>(null);

    const handleClickUpload = () => {
        ref.current?.click();
    };

    const handleUpload = (...params: any[]) => {
        onUploadPrepared(...params);
        if (media?.key) {
            api.deleteUpload(media.key).then(() =>
                console.log("Deleted previous media by key: " + media.key)
            );
        }
    };

    return (
        <UploadContainer
            label={label}
            buttonLabel={t(`upload${capitalize(fileType)}`)}
            onClickUpload={handleClickUpload}
            {...props}
        >
            <Dropzone
                ref={ref}
                fileType={fileType}
                modelType={subject}
                urls={media?.url ? [media.url] : undefined}
                id={subjectId}
                onPrepared={handleUpload}
                maxFiles={1}
                containerProps={{
                    ...{
                        minWidth: 80,
                        minHeight: 80,
                        width: 80,
                        height: 80,
                        padding: 0,
                        borderRadius: 100,
                    },
                    ...containerProps,
                }}
            >
                {children ?? <PhotoIcon fontSize={"large"} htmlColor={"#8E8E93"} />}
            </Dropzone>
        </UploadContainer>
    );
};

export const UploadContainer = ({
    children,
    label,
    buttonLabel,
    onClickUpload,
    ...props
}: StackProps & { label: string; buttonLabel: string; onClickUpload: () => void }) => {
    return (
        <Stack spacing={2} direction={"row"} alignItems={"center"} {...props}>
            {children}
            <Box>
                <Button variant={"contained"} sx={{ mb: 1 }} onClick={onClickUpload}>
                    {buttonLabel}
                </Button>
                <Typography variant={"body2"} display={"block"}>
                    {label}
                </Typography>
            </Box>
        </Stack>
    );
};
