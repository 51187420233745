import Fetch from './index'
import { Activity } from '../Hooks/useActivities'

export const getAllActivities = async () => {
    if (typeof process.env.REACT_APP_API_ACTIVITIES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACTIVITIES_URL is not defined')
    }

    return Fetch.get<Activity[]>(`${process.env.REACT_APP_API_ACTIVITIES_URL}`)
}

export const getActivitiesForSubject = async (subject: string, subjectId: string) => {
    if (typeof process.env.REACT_APP_API_ACTIVITIES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACTIVITIES_URL is not defined')
    }

    if (!(subject && subjectId)) {
        throw new Error('Missing subject or id')
    }

    return Fetch.get<Activity[]>(
        `${process.env.REACT_APP_API_ACTIVITIES_URL}${subject}/${subjectId}`,
    )
}
