import {Box, BoxProps, InputAdornment, TextField} from "@mui/material";
import {SearchRounded} from "@mui/icons-material";
import React from "react";

type SearchFieldProps = BoxProps & {
    placeholder: string;
    onSearch?: (value: string) => void;
};

export const SearchField = ({ placeholder, onSearch, ...props }: SearchFieldProps) => {
    return (
        <Box
            component={TextField}
            variant={"outlined"}
            size={"small"}
            InputProps={{
                startAdornment: (
                    <InputAdornment position={"start"}>
                        <SearchRounded />
                    </InputAdornment>
                ),
            }}
            placeholder={placeholder}
            onChange={(e: { target: { value: string } }) => onSearch?.(e.target.value)}
            {...props}
        />
    );
};
