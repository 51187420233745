import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useService } from "./useService";
import { useCallback, useMemo } from "react";
import { TImage } from "../Utils/types";
import { useLeads } from "./useLeads";
import { useBookings } from "./useBookings";
import { FUNNEL_URL } from "../Utils/constants";

export type EditableFunnel = Pick<
    Funnel,
    "name" | "description" | "alias" | "config" | "public" | "id" | "created" | "profile" | "meta"
>;

export const getFunnelUrl = (alias: string) => {
    return `${FUNNEL_URL}`.replace(`$ALIAS`, alias);
};

export type Funnel = {
    recipe: {
        [id: string]: {
            id: string;
            data: unknown;
            placement: number;
        };
    };
    theme: {
        colors: {
            [name: string]: string;
        };
    };
    domain?: string;
    public: boolean;
    name: string;
    description: string;
    alias: string;
    versions: { id: string; publishedDate: string }[];
    config: {
        serviceId: string;
        variantId: string;
        discountAmount: number;
    };
    meta?: {
        formId?: string;
        accountId?: string;
        formEnabled?: string;
    };
    profile: {
        companyName: string;
        name: string;
        phone: string;
        email: string;
        address: string;
        greeting: string;
        profileImage: TImage;
        logo: TImage;
    };
    created: string;
    version: string;
    companyId: string;
    id: string;
};
export const useFunnels = (id?: string) => {
    const AllQueryKey = ["funnels"];
    const SingleQueryKey = ["funnel", id];

    const queryClient = useQueryClient();

    const { getService } = useService();
    const {
        leads: { data: allLeads },
    } = useLeads();

    const {
        bookings: { data: allBookings },
    } = useBookings();

    const funnels = useQuery(
        AllQueryKey,
        async () => {
            await queryClient.cancelQueries(AllQueryKey);
            const funnels = await api.getFunnels();
            for (const funnel of funnels) {
                queryClient.setQueryData(["funnel", funnel.id], funnel);
            }
            return funnels;
        },
        {
            enabled: true,
        }
    );

    const funnel = useQuery(
        SingleQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleQueryKey);
            return api.getFunnel(id as string);
        },
        {
            enabled: Boolean(id),
        }
    );

    const updateFunnel = useMutation(
        ({ id, ...payload }: EditableFunnel) => api.updateFunnel(id, payload),
        {
            onMutate: async ({ id, ...funnel }) => {
                await queryClient.cancelQueries(AllQueryKey);

                const previous = queryClient.getQueryData(["funnel", id]);

                const previousAll = queryClient.getQueryData(["funnels"]);

                queryClient.setQueryData<EditableFunnel>(["funnel", id], (prev) => {
                    return {
                        ...prev,
                        ...funnel,
                        id,
                    };
                });

                queryClient.setQueryData<EditableFunnel[]>(AllQueryKey, (prev) => {
                    if (prev?.some((p) => p.id === id)) {
                        return prev.map((el) => {
                            return el.id === id
                                ? {
                                      ...el,
                                      ...funnel,
                                      id,
                                  }
                                : el;
                        });
                    }
                    return [{ ...funnel, id }].concat(prev ?? []);
                });

                return { previous, previousAll };
            },
            onError: (err, variables, context) => {
                if (context?.previous) {
                    queryClient.setQueryData(["funnel", variables.id], context.previous);
                }
                if (context?.previousAll) {
                    queryClient.setQueryData(AllQueryKey, context.previousAll);
                }
            },
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries(["funnel", variables.id]);
                await queryClient.invalidateQueries(["funnels"]);
            },
        }
    );

    const deleteFunnel = useMutation((id: string) => api.deleteFunnel(id), {
        onMutate: async (id) => {
            const previous = queryClient.getQueryData<Funnel>(["funnel", id]);
            const previousAll = queryClient.getQueryData<Funnel[]>(["funnels"]);

            queryClient.removeQueries({ queryKey: ["funnel", id], exact: true });

            queryClient.setQueryData<Funnel[]>(["funnels"], (prev) => {
                return prev?.filter((el) => !(el.id === id)) ?? [];
            });

            return { previous, previousAll };
        },
        onError: (err, id, context) => {
            if (context?.previous) {
                queryClient.setQueryData(["funnel", id], context.previous);
            }
            if (context?.previousAll) {
                queryClient.setQueryData(AllQueryKey, context.previousAll);
            }
        },
        onSettled: async (data, error, id, context) => {
            await queryClient.invalidateQueries(["funnel", id]);
            await queryClient.invalidateQueries(["funnels"]);
        },
    });

    const enrichedFunnels = useMemo(() => {
        return funnels.data?.map((el) => {
            const { headline, pictures, variants } = getService(el.config.serviceId) ?? {};
            const { name } = variants?.find((v) => el.config.variantId === v.id) ?? {};
            return {
                ...el,
                service: {
                    headline,
                    image: pictures?.[0]?.url,
                    variantName: name,
                },
            };
        });
    }, [getService, funnels.data]);

    const enrichedFunnel = useMemo(() => {
        if (funnel.data) {
            const { headline, pictures, variants } = getService(funnel.data.config.serviceId) ?? {};
            const { name } = variants?.find((v) => funnel.data.config.variantId === v.id) ?? {};
            return {
                ...funnel.data,
                service: {
                    headline,
                    image: pictures?.[0]?.url,
                    variantName: name,
                },
            };
        }
    }, [getService, funnel.data]);

    const leads = useMemo(() => {
        return allLeads?.filter((lead) => lead.funnelId === id);
    }, [allLeads, id]);

    const bookings = useMemo(() => {
        return allBookings?.filter((lead) => lead.funnelId === id);
    }, [allBookings, id]);

    const getFunnel = useCallback(
        (funnelId: string): EditableFunnel | undefined => {
            return funnels.data?.find((el) => el.id === funnelId);
        },
        [funnels.data]
    );

    return {
        funnels,
        enrichedFunnels,
        funnel,
        leads,
        bookings,
        deleteFunnel,
        enrichedFunnel,
        updateFunnel,
        getFunnel,
    };
};
