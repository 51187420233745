import { MenuItem, Select, SelectProps, Stack, TextField, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useTranslate } from "../../../Hooks/useTranslate";
import React, { useCallback, useMemo } from "react";
import { useErrors } from "../../SyiPage/useErrors";
import { EditableFunnel } from "../../../Hooks/useFunnels";
import { ServiceSelect } from "../../../Components/ServiceSelect";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { useService } from "../../../Hooks/useService";
import { useLocalizer } from "../../Events/EventSyiSections/EventSyiSectionDetails";
import { ProfileUpload } from "../../../Components/ProfileUpload/ProfileUpload";
import { useOutletContext } from "react-router-dom";

export const FunnelSyiSectionProfile = () => {
    const { t } = useTranslate("funnel.create.profile");

    const { id } = useOutletContext<{ id: string }>();

    const { register, setValue, control } = useFormContext<EditableFunnel>();

    const { clearError, getError } = useErrors();

    const { field: profileImage } = useController({
        control,
        name: "profile.profileImage",
        rules: { required: true },
    });

    const { field: logo } = useController({
        control,
        name: "profile.logo",
        rules: { required: true },
    });

    const handleUploadPrepared = async (
        { key, url }: { [k: string]: string },
        _localUrl: string,
        fileType: "profile" | "logo",
        uploadFunc: () => Promise<void>
    ) => {
        await uploadFunc();
        if (fileType === "profile") {
            profileImage.onChange({ key, url });
        } else {
            logo.onChange({ key, url });
        }
    };

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            <Stack mt={3} spacing={3}>
                <TextField
                    label={t("companyName")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("profile.companyName", {
                        required: true,
                    })}
                />
                <TextField
                    label={t("agentName")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("profile.name", {
                        required: true,
                    })}
                />
                <TextField
                    label={t("address")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("profile.address", {
                        required: true,
                    })}
                />
                <TextField
                    type={"email"}
                    label={t("email")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("profile.email", {
                        required: true,
                    })}
                />
                <TextField
                    label={t("phone")}
                    type={"tel"}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("profile.phone", {
                        required: true,
                    })}
                />
                <TextField
                    label={t("greeting")}
                    multiline
                    rows={8}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("profile.greeting", {
                        required: true,
                    })}
                    helperText={"Brug kundes navn med {{name}}"}
                />
            </Stack>
            <SyiSection mt={3} title={"Billeder"}>
                <Stack spacing={3}>
                    <ProfileUpload
                        fileType={"logo"}
                        onUploadPrepared={handleUploadPrepared}
                        label={t("logo")}
                        media={logo.value}
                        subjectId={id}
                        subject={"funnel"}
                    />
                    <ProfileUpload
                        fileType={"profile"}
                        onUploadPrepared={handleUploadPrepared}
                        label={t("profileImage")}
                        media={profileImage.value}
                        subjectId={id}
                        subject={"funnel"}
                    />
                </Stack>
            </SyiSection>
        </>
    );
};
