import {CloseRounded} from "@mui/icons-material";
import {IconButton, IconButtonProps} from "@mui/material";

export const CloseButton = ({ sx, ...props}: IconButtonProps) => (
    <IconButton size={'medium'} sx={{
        border: '1px solid rgba(0,0,0,0.6)'
    , ...sx}} {...props} >
        <CloseRounded htmlColor={'black'}/>
    </IconButton>
)
