import { Box, Checkbox, Dialog, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Header } from "./Header";
import { TranslateFunction, useTranslate } from "../Hooks/useTranslate";
import { TEvent, useEvent } from "../Hooks/useEvent";
import { EventCard } from "../Components/EventCard/EventCard";
import { useService } from "../Hooks/useService";
import { FormProvider, useController, useForm, useWatch } from "react-hook-form";
import { useProfile } from "../Hooks/useProfile";
import React, { useEffect, useMemo, useState } from "react";
import {
    formatMoney,
    getUnitCount,
    Row,
    VariantSelect,
} from "../Components/VariantSelect/VariantSelect";
import { useUpcoming } from "../Hooks/useUpcoming";
import { TCreateBookingPayload, useBookings } from "../Hooks/useBookings";
import { ProgressButton } from "../Components/ProgressButton/ProgressButton";
import { updatePayment } from "../Api";
import { toast } from "react-toastify";
import { Product } from "../Api/Upcoming";
import { calculateExpiresIn, createId, mapOptions, uuid } from "../Utils/helpers";
import { serviceChannelOptions } from "../Pages/Services/ServiceSyiSections/ServiceSyiSectionDetails";
import { OptionChips } from "../Components/OptionChips/OptionChips";
import { CustomerForm } from "./OfferDialog";
import { useCustomers } from "../Hooks/useCustomers";
import { Customer } from "../Utils/types";
import SolidTextField from "../Components/SolidTextField/SolidTextField";
import isEmpty from "lodash.isempty";

export const prepareGuests = (guests: { [k: string]: number }, products: Product[] | undefined) => {
    if (!products) return {};
    return Object.entries(guests).reduce((acc, [id, quantity]) => {
        const { name, price, offer } = products.find((p) => p.id === id) ?? {};
        return {
            ...acc,
            [id]: {
                quantity,
                name,
                price,
                offer,
            },
        };
    }, {});
};

export const CreateBookingDialog = NiceModal.create<{
    eventId: string;
    defaultValues?: Partial<
        Pick<TCreateBookingPayload, "customer" | "internalNote" | "shouldNotify">
    >;
}>(({ eventId, defaultValues }) => {
    const [id, setId] = useState(uuid());
    const [paymentId, setPaymentId] = useState(uuid);

    const [defaultHaveBeenSet, setDefaultHasBeenSet] = useState(false);

    const { t, i18n } = useTranslate("dialogs.createBooking");

    const {
        event: { data: event },
    } = useEvent(eventId);

    const { defaultCurrency } = useProfile();

    const { createBooking } = useBookings();

    const { updateCustomer } = useCustomers();

    const {
        service: { data: service },
    } = useService(event?.serviceId);

    const { products, upcoming } = useUpcoming(event?.serviceId);

    const { register, setValue, control, reset, getValues, ...methods } = useForm();

    const { field: channels } = useController({
        name: "channels",
        control,
        defaultValue: event?.channels ?? [],
    });
    const { field: guests } = useController({ name: "guests", control, defaultValue: {} });
    const { field: shouldCreatePaymentLink } = useController({
        name: "shouldCreatePaymentLink",
        control,
        defaultValue: false,
    });
    const { field: shouldSendNotification } = useController({
        name: "shouldSendNotification",
        control,
        defaultValue: false,
    });

    const { field: upsertCustomer } = useController({
        name: "upsertCustomer",
        control,
        defaultValue: false,
    });

    const customerId = useWatch({ control, name: "customer.id" });

    const location = useEventLocation(event, channels.value);

    useEffect(() => {
        if (defaultValues?.customer && !defaultHaveBeenSet) {
            setValue("name", defaultValues?.customer?.name);
            setValue("email", defaultValues?.customer?.email);
            setValue("phone", defaultValues?.customer?.phone);
            setValue("internalNote", defaultValues?.internalNote);
            setValue("shouldNotify", defaultValues?.shouldNotify);
            setDefaultHasBeenSet(true);
        }
    }, [defaultValues]);

    const resetModal = () => {
        setId(uuid());
        setPaymentId(uuid());
        reset();
    };

    const modal = useModal();

    const handleClose = async (shouldRemove = true) => {
        modal.hide();
        resetModal();
        if (shouldRemove) {
            modal.remove();
        }
    };

    const ensureCustomer = async (customer?: Customer, canCreate?: boolean) => {
        if (customer?.name && canCreate) {
            const id = customer?.id ?? createId();
            const res = await updateCustomer.mutateAsync({ ...customer, id });
            return { id: res.id ?? id };
        }
        return Promise.resolve();
    };

    const handleCreate = async () => {
        if (event) {
            const {
                customer,
                guests,
                internalNote,
                shouldCreatePaymentLink,
                shouldSendNotification,
                channels,
            } = getValues();

            if (isEmpty(guests)) {
                toast.warning("Vælg antal gæster");
                return Promise.reject();
            }

            const [aChannel] = channels;

            const payload: TCreateBookingPayload = {
                customer: {
                    ...customer,
                    ...(await ensureCustomer(customer, upsertCustomer.value)),
                },
                ...(internalNote && { internalNote }),
                items: guests,
                eventId,
                ...(aChannel && {
                    contactChannel: aChannel,
                }),
                serviceId: event.serviceId,
                startDateTime: event.startDateTime,
                endDateTime: event.endDateTime,
                location,
                source: "manual",
                status: "unpaid",
                ...(shouldCreatePaymentLink && {
                    paymentId,
                }),
                metadata: {
                    disableValidation: true,
                    shouldNotify: shouldSendNotification,
                },
            };

            const proms = Promise.all([
                createBooking.mutateAsync({ id, ...payload }),
                shouldCreatePaymentLink &&
                    updatePayment(paymentId, {
                        subject: "booking",
                        subjectId: id,
                        expiresIn: calculateExpiresIn(
                            new Date(),
                            event.startDateTime,
                            60 * 24 * 14
                        ),
                        items: {
                            ...prepareGuests(guests, products),
                        },
                    }),
            ]);

            const callback = async () => {
                try {
                    const [newEventId] = await toast.promise(proms, {
                        pending: t("toast.loading"),
                        success: t("toast.success"),
                        error: {
                            render({ data }) {
                                return (
                                    (data as any)?.message ??
                                    `${t("toast.error")} ${payload.customer.name} - ${
                                        payload.customer.email
                                    }`
                                );
                            },
                        },
                    });
                    return newEventId;
                } catch (err) {
                    await NiceModal.show(CreateBookingDialog, {
                        eventId,
                        defaultValues: { ...payload },
                    });
                } finally {
                    await upcoming.refetch({
                        queryKey: ["upcoming", { serviceId: payload.serviceId }],
                    });
                }
            };
            modal.resolve([callback, payload.eventId]);
            await handleClose(!modal.visible);
        }
    };

    const handleUpdateGuests = (value: { [k: string]: number }) => {
        guests.onChange(value);
    };

    const totalGuests = useMemo(() => {
        return Object.entries(guests.value as { [k: string]: number }).reduce(
            (acc, [id, count]) => {
                return acc + count;
            },
            0
        );
    }, [guests.value]);

    const totalAmount = useMemo(() => {
        return Object.entries(guests.value as { [k: string]: number }).reduce(
            (acc, [id, count]) => {
                const { price } = products?.find((el) => el.id === id) ?? {};
                return acc + count * (price?.amount ?? 0);
            },
            0
        );
    }, [guests.value]);

    return (
        <Dialog fullWidth maxWidth={"sm"} open={modal.visible}>
            <FormProvider {...{ register, setValue, control, reset, getValues, ...methods }}>
                <Box pl={4}>
                    <Header title={t("title")} onClose={handleClose} />

                    <Stack pt={4} pb={4} pr={4}>
                        <EventCard
                            status={event?.status}
                            title={
                                service?.headline[i18n.language] ??
                                service?.headline[event?.languages[0] ?? "da"]
                            }
                            languages={event?.languages ?? []}
                            startDateTime={event?.startDateTime ?? ""}
                            endDateTime={event?.endDateTime}
                            bookingsCount={event?.slots?.booked ?? 0}
                            assignees={event?.assignees ?? []}
                            availableSlots={event?.slots?.total ?? 0}
                        />

                        <VariantSelect
                            serviceId={event?.serviceId}
                            onSubmit={handleUpdateGuests}
                            eventId={eventId}
                        />

                        {totalGuests > 0 && (
                            <Stack spacing={2} divider={<Divider />} mt={4} mb={0}>
                                {Object.entries(guests.value).map(([id, count]) => {
                                    const { name = "", price } =
                                        products?.find((p) => p.id === id) ?? {};
                                    const unitCount = getUnitCount(count as number, null);
                                    return count === 0 ? null : (
                                        <Row
                                            key={id}
                                            left={
                                                renderRowLeftLabel(t)({
                                                    count,
                                                    unitCount,
                                                    name,
                                                }) as any
                                            }
                                            right={renderRightLabel(t)({
                                                count: count as number,
                                                price: price?.amount ?? 0,
                                                currency: defaultCurrency,
                                            })}
                                        />
                                    );
                                })}
                                <Row
                                    fontSize={"1.2em"}
                                    left={t("totalMoney", "utils.generic", {
                                        currency: defaultCurrency,
                                    })}
                                    leftProps={{ variant: "h6" }}
                                    rightProps={{ variant: "h6" }}
                                    right={formatMoney(t)({
                                        value: totalAmount,
                                        nativeCurrency: defaultCurrency,
                                    })}
                                />
                            </Stack>
                        )}

                        {event?.channels && event.channels?.length > 1 && (
                            <>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <OptionChips
                                    selectedOptions={channels.value}
                                    multiple={false}
                                    onChange={channels.onChange}
                                    allOptions={mapOptions(
                                        serviceChannelOptions.filter((f) =>
                                            event?.channels?.includes(f.key)
                                        )
                                    )}
                                />
                            </>
                        )}

                        <Divider sx={{ mt: 3, mb: 1 }} />

                        <Stack component={"form"} spacing={3} mt={2}>
                            <CustomerForm component={Stack} requires={["name"]}>
                                <>
                                    <Divider orientation={"horizontal"} sx={{ mt: 3 }} />
                                    <Typography variant={"h5"} mt={3} mb={1}>
                                        Eller opret som ny klient
                                    </Typography>
                                </>
                            </CustomerForm>
                            <SolidTextField
                                label={t("internalNote", "utils.generic")}
                                fullWidth
                                multiline
                                minRows={3}
                                {...register("internalNote")}
                            />
                            <Box>
                                <FormControlLabel
                                    onChange={shouldCreatePaymentLink.onChange}
                                    value={shouldCreatePaymentLink.value}
                                    inputRef={shouldCreatePaymentLink.ref}
                                    control={<Checkbox />}
                                    label={t("actions.generatePaymentLink")}
                                />
                                <FormControlLabel
                                    onChange={shouldSendNotification.onChange}
                                    value={shouldSendNotification.value}
                                    inputRef={shouldSendNotification.ref}
                                    control={<Checkbox />}
                                    label={t("actions.sendNotification")}
                                />
                                {!customerId && (
                                    <FormControlLabel
                                        onChange={upsertCustomer.onChange}
                                        value={upsertCustomer.value}
                                        inputRef={upsertCustomer.ref}
                                        control={<Checkbox />}
                                        label={t("actions.createCustomer")}
                                    />
                                )}
                            </Box>

                            <ProgressButton
                                variant={"contained"}
                                size={"large"}
                                fullWidth
                                onClick={handleCreate}
                                label={t("actions.primary")}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </FormProvider>
        </Dialog>
    );
});

// eslint-disable-next-line react/display-name
export const renderRowLeftLabel =
    (t: TranslateFunction) =>
    ({ count, unitCount, name, customName, type }: any) => {
        const hasName = Boolean(name ?? customName);
        if (type === "group") {
            return (
                <Typography>
                    {unitCount} {t(`group`)}
                    {count > 1 && "r"}
                    <Typography variant={"body2"} color={"grey.700"}>
                        {count} {t(`persons`, "utils.generic")}
                    </Typography>
                </Typography>
            );
        }
        if (type === "unique") {
            return (
                <Typography>
                    {name ?? customName}
                    <Typography variant={"body2"} color={"grey.700"}>
                        {unitCount} {t(`${unitCount > 1 ? "groups" : "group"}`, "utils.generic")} |{" "}
                        {count} {t(`persons`, "utils.generic")}
                    </Typography>
                </Typography>
            );
        }
        if (type === "single") {
            return (
                <Typography>
                    {count}
                    {hasName ? " x " : " "}
                    {name ?? customName ?? t(`${type}`, "utils.generic")}
                    {hasName ? "" : t("plural", "utils.generic")}
                </Typography>
            );
        }

        return (
            <Typography>
                {count / (hasName ? 1 : 2)}
                {hasName ? " x " : " "}
                {name ?? customName ?? t(`${type}`, "utils.generic")}
            </Typography>
        );
    };

export const renderRightLabel =
    (t: TranslateFunction) =>
    ({ currency, price, count }: { currency: string; count: number; price: number }) => {
        return (
            <Typography textAlign={"right"}>
                {formatMoney(t)({ nativeCurrency: currency, value: count * price })}
                {count > 1 && (
                    <Typography display={"block"} variant={"body2"} color={"grey.700"}>
                        {count} x {price}
                    </Typography>
                )}
            </Typography>
        ) as any;
    };

const useEventLocation = (event: TEvent<any> | undefined, channels: string[]) => {
    const { companyAddress } = useProfile();
    const {
        service: { data: service },
    } = useService(event?.serviceId);
    if (event == undefined) {
        return "";
    }
    const k = event.locations.selectedOptionKey ?? "";
    if (channels.every((e) => e === "online")) {
        return "Online videokald";
    }
    if (k === "serviceCustom") {
        return service?.locations.custom;
    }
    if (k.startsWith("serviceMultiple-")) {
        const [, i] = k.split("-") as [unknown, string];
        return service?.locations.multiple?.[Number(i)]?.value;
    }
    if (k === "custom") {
        return event.locations?.value ?? event.locations?.custom;
    }
    return companyAddress;
};
