import { useNavigate, useLocation } from "react-router-dom";
import { thrownHasStatus, useAuth } from "../../Hooks/useAuth";
import { useTranslation } from "react-i18next";
import { AuthLayout } from "./AuthLayout";
import { AuthForm } from "./AuthForm";
import type { TInput } from "./AuthForm";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface ILoginFormValues {
    email: string;
    password: string;
}

const _inputs: Array<TInput<ILoginFormValues>> = [
    {
        key: "email",
        type: "text",
        isRequired: true,
        autoFocus: true,
        variant: "outlined",
    },
    {
        key: "password",
        type: "password",
        isRequired: true,
        variant: "outlined",
    },
];

export const LoginPage = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const { signIn, getEmailFromToken } = useAuth();
    const { setValue } = useFormContext();
    const [inputs, setInputs] = useState(_inputs);

    const onSubmit = async (data: ILoginFormValues) => {
        const { email, password } = data;
        try {
            const hasOrg = await signIn(email.toLowerCase(), password);
            const from = location.state?.from?.pathname || "/";
            navigate(hasOrg ? from : "/welcome/profile", { replace: true });
        } catch (err) {
            console.log("Error logging in.", err);
        }
    };

    useEffect(() => {
        if (location.hash) {
            const email = getEmailFromToken(location.hash.substring(1));
            if (email) {
                setInputs((p) =>
                    p.map((input) => {
                        return input.key === "email"
                            ? { ...input, disabled: true, isRequired: false }
                            : input;
                    })
                );
                setValue("email", email);
            }
        }
    }, [location.hash]);

    return (
        <AuthLayout state={"login"}>
            <AuthForm inputs={inputs} onSubmit={onSubmit} buttonLabel={"Log ind"} />
        </AuthLayout>
    );
};
