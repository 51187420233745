import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Header } from "./Header";
import React, { useState } from "react";
import { createId } from "../Pages/SyiPage/SyiPage";
import { InputDialog } from "./InputDialog";
import { ROLE_OPTIONS as options } from "../Utils/constants";
import { UserItem } from "../Components/UserItem/UserItem";
import { AddRounded } from "@mui/icons-material";
import { SendIcon } from "../Icons";
import { useTranslate } from "../Hooks/useTranslate";
import { validEmail } from "../Utils/helpers";

export const InviteUsersDialog = NiceModal.create(() => {
    const { t } = useTranslate("dialogs.inviteUser");

    const modal = useModal();

    const handleClose = () => {
        modal.remove();
    };

    const [users, setUsers] = useState<any[]>([]);

    const handleDelete = (index: number) => () => setUsers((p) => p.filter((_, i) => i !== index));

    const handleUpdate = (index: number) => (key: "email" | "role", value: any) =>
        setUsers((p) =>
            p.map((el, i) => {
                return i === index ? { ...el, [key]: value.toLowerCase() } : el;
            })
        );

    const handleSubmit = () => {
        modal.resolve(users);
        handleClose();
    };

    const handleCreate = async () => {
        try {
            const { email, role, name } = await NiceModal.show<{
                email: string;
                role: string;
                name: string;
            }>(InputDialog, {
                title: t("createUserDialogTitle"),
                inputs: [
                    {
                        key: "name",
                        name: t("name", "utils.generic"),
                        props: {
                            fullWidth: true,
                        },
                        rules: {
                            required: true,
                        },
                    },
                    {
                        key: "email",
                        name: t("email", "utils.generic"),
                        props: {
                            fullWidth: true,
                            helperText: t("emailHelpText"),
                        },
                        rules: {
                            required: true,
                            validate: validEmail,
                        },
                    },
                    {
                        key: "role",
                        name: t("role", "utils.generic"),
                        props: {
                            fullWidth: true,
                            options,
                            type: "select",
                            helperText: t("roleHelpText"),
                        },
                        rules: {
                            required: true,
                        },
                    },
                ],
            });
            setUsers((p) => [...p, { email: email.toLowerCase(), role, name, id: createId() }]);
        } catch (err) {
            return;
        }
    };

    return (
        <Dialog fullWidth maxWidth={"sm"} open={modal.visible} onClose={handleClose}>
            <Box pl={4} pb={4}>
                <Header title={t("title")} onClose={handleClose} />
                <Typography mb={2} mt={3} fontWeight={"600"}>
                    {t("description")}
                </Typography>
                <Stack pl={0} pr={11} pb={4} spacing={2}>
                    {users.map((el, i) => {
                        return (
                            <UserItem
                                name={el.name}
                                email={el.email}
                                role={el.role}
                                id={`user-item-${i}`}
                                onUpdate={handleUpdate(i)}
                                onDelete={handleDelete(i)}
                            />
                        );
                    })}
                    <Stack spacing={4} alignItems={"start"} mt={2}>
                        <Button
                            variant={"outlined"}
                            color={"secondary"}
                            startIcon={<AddRounded />}
                            onClick={handleCreate}
                        >
                            {users.length > 0 ? t("buttons.addOneMoreUser") : t("buttons.addUser")}
                        </Button>
                    </Stack>
                </Stack>

                <Box display={"flex"} justifyContent={"flex-end"} pr={4}>
                    <Button
                        disabled={users.length === 0}
                        variant={"contained"}
                        onClick={handleSubmit}
                        startIcon={<SendIcon />}
                    >
                        {t("buttons.sendInvitations")}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
});
