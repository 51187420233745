import { useCurrentEditor } from "@tiptap/react";
import {
    FormatBoldRounded,
    FormatItalicRounded,
    FormatListBulletedRounded,
    FormatListNumberedRounded,
    FormatStrikethroughRounded,
    FormatUnderlinedRounded,
    RedoRounded,
    UndoRounded,
} from "@mui/icons-material";
import { MenuBarButton } from "./MenuBarButton";
import { LinkButton } from "./LinkButton/LinkButton";

export const MenuBar = () => {
    const { editor } = useCurrentEditor();

    if (!editor) {
        return null;
    }

    return (
        <div
            className={
                "flex items-center py-1 px-2 space-x-6 rounded-t-lg border-input border-t border-r border-l bg-[#F2F2F7]"
            }
        >
            <div className={"flex items-center space-x-2"}>
                <MenuBarButton
                    disabled={!editor.can().undo()}
                    onClick={() => editor.chain().focus().undo().run()}
                >
                    <UndoRounded fontSize={"small"} />
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().redo()}
                    onClick={() => editor.chain().focus().redo().run()}
                >
                    <RedoRounded fontSize={"small"} />
                </MenuBarButton>
            </div>
            <div className={"flex items-center space-x-2"}>
                <MenuBarButton
                    disabled={!editor.can().toggleBold()}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    active={editor.isActive("bold")}
                >
                    <FormatBoldRounded fontSize={"small"} />
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().toggleItalic()}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    active={editor.isActive("italic")}
                >
                    <FormatItalicRounded fontSize={"small"} />
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().toggleUnderline()}
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    active={editor.isActive("underline")}
                >
                    <FormatUnderlinedRounded fontSize={"small"} />
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().toggleStrike()}
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    active={editor.isActive("strike")}
                >
                    <FormatStrikethroughRounded fontSize={"small"} />
                </MenuBarButton>
            </div>

            <div className={"flex items-center space-x-2 ml-6"}>
                <MenuBarButton
                    disabled={!editor.can().toggleHeading({ level: 1 })}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    active={editor.isActive("heading", { level: 1 })}
                >
                    H1
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().toggleHeading({ level: 2 })}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    active={editor.isActive("heading", { level: 2 })}
                >
                    H2
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().toggleHeading({ level: 3 })}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    active={editor.isActive("heading", { level: 3 })}
                >
                    H3
                </MenuBarButton>
            </div>

            <div className={"flex items-center space-x-2 ml-6"}>
                <MenuBarButton
                    disabled={!editor.can().toggleBulletList()}
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    active={editor.isActive("bulletList")}
                >
                    <FormatListBulletedRounded fontSize={"small"} />
                </MenuBarButton>
                <MenuBarButton
                    disabled={!editor.can().toggleOrderedList()}
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    active={editor.isActive("orderedList")}
                >
                    <FormatListNumberedRounded fontSize={"small"} />
                </MenuBarButton>
                <LinkButton />
            </div>
        </div>
    );
};
