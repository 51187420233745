import Fetch from './index'

export const validOutlookConnection = async () => {
    if (typeof process.env.REACT_APP_API_OUTLOOK_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OUTLOOK_FACADE_URL is not defined')
    }

    return Fetch.get<{ validConnection: boolean; validSubscriptions: boolean }>(
        `${process.env.REACT_APP_API_OUTLOOK_FACADE_URL}valid`,
    )
}

export const initOutlookLogin = async () => {
    if (typeof process.env.REACT_APP_API_OUTLOOK_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OUTLOOK_FACADE_URL is not defined')
    }

    return Fetch.get<{ url: string }>(`${process.env.REACT_APP_API_OUTLOOK_FACADE_URL}oauth/init`)
}

export const getAvailableCalendars = async () => {
    if (typeof process.env.REACT_APP_API_OUTLOOK_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OUTLOOK_FACADE_URL is not defined')
    }

    return Fetch.get<{ id: string; name: string }[]>(
        `${process.env.REACT_APP_API_OUTLOOK_FACADE_URL}calendars`,
    )
}

export const subscribeCalendars = async (
    payload:
        | { ids: { id: string; syncStrategy: string }[] }
        | { name: string; syncStrategy: string },
    offset = 0,
) => {
    if (typeof process.env.REACT_APP_API_OUTLOOK_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OUTLOOK_FACADE_URL is not defined')
    }

    return Fetch.post<{
        failed: { id: string; name: string }[]
        subscribed: { id: string; name: string }[]
    }>(`${process.env.REACT_APP_API_OUTLOOK_FACADE_URL}calendars?offset=${offset}`, { ...payload })
}
