import {
    Box,
    BoxProps,
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    MenuItemProps,
    Select,
    SelectProps,
    Typography,
} from "@mui/material";
import { useService } from "../Hooks/useService";
import { StatusChip } from "./StatusChip";
import React, { useMemo, useState } from "react";
import { useLocalizer } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";

type ServiceSelectProps<T extends string | undefined = undefined> = {
    serviceId: T extends string ? "all" | string : string;
    inputLabel: string;
    onChange?: SelectProps["onChange"];
    selectProps?: Omit<SelectProps, "onChange">;
    thumbnailProps?: { width?: number; height?: number };
    allLabel?: T;
    showStatus?: boolean;
    type?: "event" | "session";
} & Omit<FormControlProps, "onChange">;

export const useServiceSelect = (defaultValue?: string) => {
    const [serviceId, setServiceId] = useState(defaultValue ?? "all");

    const onChange: SelectProps["onChange"] = (evt) => {
        setServiceId(evt.target.value as string);
    };

    const render = (props: Omit<ServiceSelectProps, "serviceId" | "onChange">) => {
        return (
            <ServiceSelect
                serviceId={serviceId}
                onChange={onChange}
                showStatus={false}
                {...props}
            />
        );
    };

    return {
        render,
        serviceId,
    };
};

export const ServiceSelect = ({
    serviceId,
    onChange,
    inputLabel,
    allLabel,
    selectProps,
    thumbnailProps,
    type,
    showStatus = true,
    ...props
}: ServiceSelectProps) => {
    const localize = useLocalizer();

    const {
        services: { data: _services },
    } = useService();

    const services = useMemo(() => {
        if (type) {
            return _services?.filter((s) => s.type === type);
        }
        return _services;
    }, [type, _services]);

    return (
        <FormControl sx={{ minWidth: 320, maxWidth: 320 }} {...props}>
            <InputLabel sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }} id="select-service">
                {inputLabel}
            </InputLabel>
            <Select labelId="select-service" value={serviceId} onChange={onChange} {...selectProps}>
                {allLabel && <MenuItem value={"all"}>{allLabel}</MenuItem>}
                {services?.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                        <ServiceSelectItemContent
                            image={el?.pictures?.[0]?.url}
                            key={el.id}
                            name={localize(el.headline)}
                            thumbnailProps={thumbnailProps}
                            showStatus={showStatus}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

type ServiceSelectItemContentProps = {
    name: string;
    image?: string;
} & Pick<ServiceSelectProps, "showStatus" | "thumbnailProps"> &
    BoxProps;
export const ServiceSelectItemContent = ({
    showStatus,
    thumbnailProps,
    name,
    image,
    ...props
}: ServiceSelectItemContentProps) => {
    return (
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} {...props}>
            <Box
                display={"flex"}
                alignItems={"center"}
                mr={2}
                flexGrow={1}
                maxWidth={showStatus ? "75%" : "100%"}
                overflow={"hidden"}
            >
                <Box
                    component={"img"}
                    src={image}
                    width={48}
                    height={48}
                    borderRadius={1}
                    mr={1}
                    {...thumbnailProps}
                    sx={{ objectFit: "cover" }}
                />
                <Typography textOverflow={"ellipsis"} maxWidth={"100%"} overflow={"hidden"}>
                    {name}
                </Typography>
            </Box>
            {showStatus && (
                <StatusChip sx={{ whiteSpace: "nowrap" }} status={"active"} size={"small"} />
            )}
        </Box>
    );
};
