import {
    Box,
    BoxProps,
    Button,
    Card,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    StackProps,
    TextField,
    Typography,
} from "@mui/material";
import { StatsCard } from "../../Components/StatsCard/StatsCard";
import { useProfile } from "../../Hooks/useProfile";
import React, { useEffect, useMemo, useState } from "react";
import { useMetrics } from "../../Hooks/useMetrics";
import { OnBoardingWidget } from "../../Components/OnBoardingWidget/OnBoardingWidget";
import { useTranslate } from "../../Hooks/useTranslate";
import { CalendarIcon } from "../../Icons";
import { addDays } from "date-fns";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useService } from "../../Hooks/useService";
import { CloseRounded } from "@mui/icons-material";
import { Graph } from "../../Components/Graph/Graph";
import { Activities, ActivitySubject } from "../../Components/Activities/Activities";
import { useActivities } from "../../Hooks/useActivities";
import { useNavigate } from "react-router-dom";

type Item = {
    key: string;
    value: number | undefined;
    title: string | undefined;
};

const sumMetric = (key: string, values: { key: string; value: number }[]) => {
    return values.reduce((total, { value, key: k }) => (key === k ? total + value : total), 0);
};

export const DashboardOverviewSection = () => {
    const { t } = useTranslate("dashboard");

    const { onboardingCompleted, defaultCurrency } = useProfile();

    const {
        metrics: { data: metrics, isLoading },
        getMetricsForPeriod,
        setServiceFilter,
    } = useMetrics({ from: addDays(new Date(), -7), to: new Date() });

    const [items, setItems] = useState<Item[]>([{ key: "", value: undefined, title: undefined }]);

    //const { enrichedAllActivities: activities } = useActivities();

    const [selectedMetric, setSelectedMetric] = useState(0);

    const navigate = useNavigate();

    const handleSelectMetric = (index: number) => () => {
        setSelectedMetric(index);
    };

    const handleChangePeriod: PeriodSelectorProps["onChange"] = (from, to) => {
        getMetricsForPeriod.mutate({ from, to });
    };

    const handleChangeServiceFilter = (serviceId: string | null) => {
        setServiceFilter.mutate(serviceId);
    };

    const fillDates = (startDateTime: string, endDateTime: string) => {
        let dates: Date[] = [];
        const d = new Date(startDateTime);
        while (Number(d) <= Number(new Date(endDateTime))) {
            dates.push(d);
            d.setHours(d.getHours() + 24);
        }
        return dates;
    };

    const filteredMetrics = useMemo(() => {
        return Object.entries(metrics ?? {})
            .sort((a, b) => Number(new Date(a[0])) - Number(new Date(b[0])))
            .reduce((acc, [date, values]) => {
                return {
                    ...acc,
                    [date]: values[selectedMetric]?.value ?? 0,
                };
            }, {});
    }, [metrics, selectedMetric]);

    const selectedMetricName = useMemo(() => {
        return Object.values(metrics ?? {})?.[0]?.[selectedMetric]?.key ?? null;
    }, [filteredMetrics]);

    const handleClickActivity = (id: string, subject: ActivitySubject) => {
        console.log(id, subject);
        //navigate(`/${subject}s/${id}`);
    };

    useEffect(() => {
        if (metrics) {
            const labels = Object.values(metrics ?? {})?.[0]?.map((m) => m.key);
            setItems(
                Array.from(labels).reduce<Item[]>((total, key) => {
                    return [
                        ...total,
                        {
                            key,
                            title: t(`statistics.cards.${key}`),
                            value: sumMetric(key, Object.values(metrics ?? {}).flat()),
                        },
                    ];
                }, [])
            );
        }
    }, [metrics]);

    return (
        <>
            {onboardingCompleted === false && (
                <>
                    <Typography variant={"h3"} mt={2} mb={3}>
                        {t("title", "onboardingWidget")}
                    </Typography>

                    <OnBoardingWidget />
                </>
            )}

            {onboardingCompleted === true && <></>}

            <>
                <Typography mt={5} variant={"h3"}>
                    {t("statistics.title")}
                </Typography>

                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    <PeriodSelector
                        mb={3}
                        mt={3}
                        loading={isLoading}
                        onChange={handleChangePeriod}
                    />
                    <ServiceFilter onChange={handleChangeServiceFilter} />
                </Stack>

                <Grid container spacing={3} columns={3} maxWidth={800}>
                    {items.map(({ value, ...el }, i) => (
                        <Grid item xs={1} key={el.key}>
                            <StatsCard
                                value={
                                    el.key === "revenue"
                                        ? `${value} ${t(defaultCurrency, "utils.currency")}`
                                        : value
                                }
                                isSelected={selectedMetric === i && !isLoading}
                                onClick={() => setSelectedMetric(i)}
                                sx={{ width: "100%", height: "100%" }}
                                {...el}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Box component={Card} width={"100%"} mt={3}>
                    <Graph
                        labels={Object.keys(filteredMetrics ?? {})}
                        series={[
                            {
                                name: t(`statistics.cards.${selectedMetricName}`),
                                data: [...(Object.values(filteredMetrics) as number[])],
                            },
                        ]}
                    />
                </Box>
            </>

            {/*<Box maxWidth={720} width={"100%"} mt={4} mb={8}>
                <Typography mb={2} variant={"h4"}>
                    Nylig aktivitet
                </Typography>
                <Activities onClick={handleClickActivity} activities={activities} />
            </Box>*/}
        </>
    );
};

type PeriodSelectorProps = Omit<StackProps, "onChange"> & {
    onChange: (from: Date, to: Date) => void;
    loading?: boolean;
};

const PeriodSelector = ({ onChange, loading = false, ...props }: PeriodSelectorProps) => {
    const { t } = useTranslate("utils.generic");

    const [from, setFrom] = useState(addDays(new Date(), -7));
    const [to, setTo] = useState(new Date());

    const [open, setOpen] = useState(-1);

    const handleChange = (key: "from" | "to") => (value: string | null) => {
        if (value) {
            switch (key) {
                case "from":
                    setFrom(new Date(value));
                    onChange(new Date(value), to);
                    break;
                case "to":
                    setTo(new Date(value));
                    onChange(from, new Date(value));
                    break;
            }
        }
    };

    return (
        <Stack direction={"row"} spacing={3} {...props} alignItems={"center"}>
            <DesktopDatePicker
                label={t("from")}
                open={open === 0}
                format={"PPP"}
                onClose={() => setOpen(-1)}
                slots={{
                    openPickerIcon: CalendarIcon,
                }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        inputProps: {
                            placeholder: t("selectDate"),
                            disabled: true,
                        },
                        onClick: () => {
                            setOpen(0);
                        },
                    },
                }}
                onChange={handleChange("from")}
                // @ts-ignore
                value={from}
                disabled={loading}
            />
            <DesktopDatePicker
                label={t("to")}
                // @ts-ignore
                minDate={from}
                open={open === 1}
                format={"PPP"}
                onClose={() => setOpen(-1)}
                slots={{
                    openPickerIcon: CalendarIcon,
                }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        inputProps: {
                            placeholder: t("selectDate"),
                            disabled: true,
                        },
                        onClick: () => {
                            setOpen(1);
                        },
                    },
                }}
                onChange={handleChange("to")}
                // @ts-ignore
                value={to}
                disabled={loading}
            />
            {loading && (
                <Box fontSize={"0.88em"}>
                    <CircularProgress size={"2em"} />
                </Box>
            )}
        </Stack>
    );
};

type ServiceFilterProps = Omit<BoxProps, "onChange"> & {
    onChange: (serviceId: string | null) => void;
};

const ServiceFilter = ({ onChange, ...props }: ServiceFilterProps) => {
    const { i18n } = useTranslation();
    const {
        services: { data: services },
    } = useService();
    const { t } = useTranslate("events.create.details");
    const [service, setService] = useState<null | string>(null);
    useEffect(() => {
        onChange(service);
    }, [service]);
    return (
        <Box {...props}>
            {service !== null ? (
                <Box display={"flex"} alignItems={"center"}>
                    <IconButton
                        color={"error"}
                        size={"small"}
                        sx={{ mr: 1 }}
                        onClick={() => setService(null)}
                    >
                        <CloseRounded />
                    </IconButton>
                    <FormControl sx={{ minWidth: 260, maxWidth: 260 }}>
                        <InputLabel
                            sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }}
                            id="select-service"
                        >
                            {t("buttons.selectService")}
                        </InputLabel>
                        <Select
                            labelId="select-service"
                            value={service}
                            onChange={(evt) => setService(evt.target.value)}
                        >
                            {services?.map((el) =>
                                el?.headline?.[i18n.language] ?? el?.headline?.[el.languages[0]] ? (
                                    <Box
                                        component={MenuItem}
                                        key={el.id}
                                        value={el.id}
                                        justifyContent={"space-between"}
                                    >
                                        <Box display={"flex"} alignItems={"center"} mr={2}>
                                            <Box
                                                component={"img"}
                                                src={el?.pictures?.[0]?.url}
                                                width={24}
                                                height={24}
                                                borderRadius={1}
                                                mr={1}
                                            />
                                            {el?.headline?.[i18n.language] ??
                                                el?.headline?.[el.languages[0]] ??
                                                ""}
                                        </Box>
                                    </Box>
                                ) : null
                            )}
                        </Select>
                    </FormControl>
                </Box>
            ) : (
                <Button onClick={() => setService("")}>Filtrer på ydelse</Button>
            )}
        </Box>
    );
};
