import Fetch, { IShallowSuccessResponse } from './index'
import { CreateRecordPayload, Record } from '../Hooks/useRecords'

export const getRecordsForSubject = async (subject: Record['subject'], id: string) => {
    if (typeof process.env.REACT_APP_API_RECORDS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_RECORDS_URL is not defined')
    }

    if (!(subject && id)) {
        throw new Error('Missing param')
    }

    return Fetch.get<Record[]>(`${process.env.REACT_APP_API_RECORDS_URL}${subject}/${id}`)
}

export const updateRecord = async (id: string, payload: Partial<Record>) => {
    if (typeof process.env.REACT_APP_API_RECORDS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_RECORDS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_RECORDS_URL}${id}`, payload)
}

export const createRecord = async (payload: CreateRecordPayload) => {
    if (typeof process.env.REACT_APP_API_RECORDS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_RECORDS_URL is not defined')
    }

    return Fetch.post(`${process.env.REACT_APP_API_RECORDS_URL}`, payload)
}

export const deleteRecord = async (id: string) => {
    if (typeof process.env.REACT_APP_API_RECORDS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_RECORDS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_RECORDS_URL}${id}`)
}
