import { useQuery } from "react-query";
import * as api from "../Api";

export const useOutlook = (accountId?: string) => {
    const ValidQueryKey = ["outlook", "connection", "valid"];
    const CalendarsQueryKey = ["outlook", "connection", "calendars"];
    const SubscribedCalendarsQueryKey = ["outlook", "connection", "calendars", "subscribed"];
    const valid = useQuery(ValidQueryKey, () => api.validOutlookConnection(), { enabled: true });
    const calendars = useQuery(CalendarsQueryKey, () => api.getAvailableCalendars(), {
        enabled: Boolean(valid.data?.validConnection),
    });

    return { valid, calendars };
};
