import { Box, BoxProps, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const MicroButton = styled(Button)({
    borderRadius: 100,
    padding: "5px 16px",
    whiteSpace: "nowrap",
    "&.MuiButton-outlinedSizeSmall": {
        fontSize: "0.88rem",
        lineHeight: 1,
    },
});

export const LabelWithButton = ({
    label,
    href,
    buttonLabel,
    onClick,
    ...props
}: BoxProps & { label: string; buttonLabel: string; href?: string; onClick?: () => void }) => {
    const renderButton = (onClick?: () => void) => (
        <MicroButton size={"small"} variant={"outlined"} color={"secondary"} onClick={onClick}>
            {buttonLabel}
        </MicroButton>
    );

    const isExternalUrl = href?.startsWith("http:") || href?.startsWith("https:");

    return (
        <Box {...props} display={"flex"} alignItems={"center"}>
            <Typography mr={1.5}>{label}</Typography>
            {href ? (
                isExternalUrl ? (
                    <a href={href} target={"_blank"}>
                        {renderButton(onClick)}
                    </a>
                ) : (
                    <Link to={href}>{renderButton(onClick)}</Link>
                )
            ) : onClick ? (
                renderButton(onClick)
            ) : null}
        </Box>
    );
};
