import { SyiSection } from "../../SyiPage/SyiSection";
import { OptionChips } from "../../../Components/OptionChips/OptionChips";
import { Divider, Stack, Typography } from "@mui/material";
import { LanguageChips } from "../../../Components/LanguageChips/LanguageChips";
import { useController, useWatch } from "react-hook-form";
import { SOURCE_LANG } from "./ServiceSyiSectionDescription";
import { mapOptions } from "../../../Utils/helpers";
import { ServiceTypes, TOptionItem } from "../../../Utils/types";
import { useTranslate } from "../../../Hooks/useTranslate";
import { useServiceType } from "../../../Hooks/useServiceType";
import { useErrors } from "../../SyiPage/useErrors";
import { useEffect } from "react";

export const serviceTypeOptions: TOptionItem<ServiceTypes>[] = [
    {
        key: "session",
        label: "Private sessioner",
        width: 34,
        height: 16,
        icon: "/icons/service-types/session.svg",
    },
    {
        key: "event",
        label: "Gruppearrangement",
        width: 36,
        height: 17,
        icon: "/icons/service-types/retreat.svg",
    },
    /*{
        key: "course",
        label: "Kursus",
        width: 22,
        height: 20,
        icon: "/icons/service-types/course.svg",
    },
    {
        key: "workshop",
        label: "Workshop",
        width: 31,
        height: 21,
        icon: "/icons/service-types/session.svg",
    },*/
];

export const serviceChannelOptions = [
    {
        key: "inPerson",
        label: "Fysisk fremmøde",
        width: 26,
        height: 17,
        icon: "/icons/channels/in-person.svg",
    },
    {
        key: "online",
        label: "Online",
        width: 23,
        height: 18,
        icon: "/icons/channels/online.svg",
    },
];

export type Channel = "inPerson" | "online";

export const ServiceSyiSectionDetails = () => {
    const { t } = useTranslate("service.create.details");

    const { withClear, getError } = useErrors();

    const { field: languages } = useController({ name: "languages", defaultValue: [SOURCE_LANG] });

    const { field: type } = useController({ name: "type" });

    const { field: channels } = useController({ name: "channels" });

    const { type: serviceType } = useServiceType();

    return (
        <>
            <Typography variant={"h4"}>{t("title")}</Typography>
            <Stack divider={<Divider />} spacing={4} mt={3}>
                <SyiSection title={t("serviceType")} error={getError("type")}>
                    <OptionChips
                        multiple={false}
                        selectedOptions={type.value}
                        onChange={withClear("type", ([value]) => type.onChange(value))}
                        allOptions={mapOptions(serviceTypeOptions)}
                    />
                </SyiSection>
                <SyiSection title={t("languages", { serviceType })} error={getError("languages")}>
                    <LanguageChips
                        onChange={withClear("languages", languages.onChange)}
                        languages={languages.value}
                        multiple={true}
                    />
                </SyiSection>
                <SyiSection title={t("channels", { serviceType })} error={getError("channels")}>
                    <OptionChips
                        multiple={true}
                        selectedOptions={channels.value}
                        onChange={withClear("channels", channels.onChange)}
                        allOptions={mapOptions(serviceChannelOptions)}
                    />
                </SyiSection>
            </Stack>
        </>
    );
};
