import { Box, Dialog, Button } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Header } from "./Header";
import { LeadView } from "./CustomerDialog/LeadView";
import { OfferDialog } from "./OfferDialog";
import { useLeads } from "../Hooks/useLeads";
import { useTranslate } from "../Hooks/useTranslate";

export const LeadDialog = NiceModal.create(({ leadId }: { leadId: string }) => {
    const modal = useModal();

    const { t } = useTranslate("leads");

    const handleClose = () => {
        modal.hide().then(() => modal.remove());
    };

    const { lead } = useLeads(undefined, leadId);

    const handleCreateOffer = () => {
        const { id, customer } = lead.data ?? {};
        if (id && customer) {
            NiceModal.show(OfferDialog, { lead: { id, customer } });
        }
    };

    return (
        <Dialog fullWidth maxWidth={"sm"} open={modal.visible} onClose={handleClose}>
            <Box pl={4}>
                <Header
                    mb={0}
                    title={t("dialog.title")}
                    id={leadId}
                    borderBottom={"none"}
                    onClose={handleClose}
                />
                <LeadView
                    skeletonProps={{ sx: { mb: 3 } }}
                    leadId={leadId}
                    showCustomerLink={true}
                    onBack={handleClose}
                />
            </Box>
            <Box
                display={"flex"}
                justifyContent={"flex-end"}
                borderTop={"1px solid #E5E5EA"}
                py={2}
                px={4}
            >
                <Button variant={"contained"} onClick={handleCreateOffer} size={"large"}>
                    {t("createOffer", "buttons")}
                </Button>
            </Box>
        </Dialog>
    );
});
