import { Box, styled, Tab, Tabs, TabsProps } from "@mui/material";

export const StyledTabs = styled(Tabs)`
    min-height: 36px;
    & .MuiTab-root {
        min-height: 56px;
        line-height: 1;
        font-size: 1.1em;
        min-width: 56px;
        padding-bottom: 24px;
        border-radius: 8px;
        font-weight: 600;
        &.Mui-selected {
            color: #7736ba;
            z-index: 2;
        }
        & .MuiTab-iconWrapper {
            margin-left: 12px;
        }
    }
    & .MuiTabs-indicator {
        border-bottom: 3px solid #7736ba;
        background-color: transparent;
        z-index: 0;
        border-radius: 0;
    }
`;

type ICustomTabs = {
    items: {
        key: string;
        label: string;
        badge?: string | number;
    }[];
    onChange: (newValue: string) => void;
    selected: string;
    placeholder?: string;
};

export const CustomTabs = ({
    items,
    onChange,
    selected,
    ...props
}: Omit<TabsProps, "onChange"> & ICustomTabs) => {
    return (
        <StyledTabs value={selected} onChange={(_, val) => onChange(val)} {...props}>
            {items.map(({ key, label, badge }, index) => (
                <Tab
                    key={key}
                    label={label}
                    value={key}
                    icon={
                        Boolean(badge) ? (
                            <Box
                                p={"3px 10px"}
                                borderRadius={2}
                                fontSize={"0.75em"}
                                color={"#8E8E93"}
                                border={"1px solid #C7C7CC"}
                            >
                                {badge}
                            </Box>
                        ) : undefined
                    }
                    iconPosition={"end"}
                />
            ))}
        </StyledTabs>
    );
};
