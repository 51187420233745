import {
    Box,
    BoxProps,
    Button,
    capitalize,
    Divider,
    Select,
    SelectProps,
    Stack,
    StackProps,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { EnrichedLead, renderLeadService, TLead, useLeads } from "../../Hooks/useLeads";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { SyiSection } from "../../Pages/SyiPage/SyiSection";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { EmailOutlined, PersonRounded, PhoneOutlined } from "@mui/icons-material";
import { FlatCard } from "../../Components/FlatCard/FlatCard";
import { IconLabel } from "../../Components/IconLabel/IconLabel";
import { formatDistanceToNow } from "date-fns";
import MenuItem from "@mui/material/MenuItem";
import { StatusChip } from "../../Components/StatusChip";
import React, { useEffect } from "react";
import isEmpty from "lodash.isempty";
import { Link, useNavigate } from "react-router-dom";
import { Records } from "../../Components/Records/Records";
import SolidTextField from "../../Components/SolidTextField/SolidTextField";
import { useTranslate } from "../../Hooks/useTranslate";
import NiceModal from "@ebay/nice-modal-react";
import { CustomerDialog } from "./CustomerDialog";
import { getStatus } from "../../Utils/helpers";

export const LeadView = ({
    leadId,
    onBack,
    children,
    onLoadCustomer,
    disableRecords = false,
    showCustomerLink = false,
    skeletonProps,
    ...props
}: BoxProps & {
    disableRecords?: boolean;
    showCustomerLink?: boolean;
    leadId: string;
    skeletonProps?: StackProps;
    onBack: () => void;
    onLoadCustomer?: (customerId: string) => void;
}) => {
    const { enrichedLead: lead, updateLead } = useLeads(undefined, leadId);

    const { t } = useTranslate("leads");

    const {
        getValues,
        setValue,
        control,
        formState: { touchedFields, ...formState },
        ...methods
    } = useForm<TLead>();

    const navigate = useNavigate();

    useEffect(() => {
        if (lead && isEmpty(touchedFields)) {
            for (const [k, v] of Object.entries(lead)) {
                setValue(k as keyof TLead, v as any, { shouldTouch: true });
            }
            if (lead.customer.id) {
                onLoadCustomer?.(lead.customer.id);
            }
        }
    }, [lead, touchedFields]);

    const submit = (payload: Partial<TLead>) => {
        if (lead?.serviceId) {
            return updateLead.mutateAsync({ ...payload, serviceId: lead?.serviceId, id: leadId });
        }
    };

    const handleClickGoToCustomer = () => {
        if (lead?.customer?.id) {
            NiceModal.show(CustomerDialog, { customerId: lead.customer.id });
        }
    };

    return (
        <Box {...props}>
            {children}
            <FormProvider
                {...{
                    getValues,
                    setValue,
                    control,
                    ...{ formState: { ...formState, touchedFields } },
                    ...methods,
                }}
            >
                {!isEmpty(touchedFields) && lead ? (
                    <Stack pb={4} pr={4} spacing={3} divider={<Divider />}>
                        <>
                            <FlatCard p={0}>
                                <Stack p={3} direction={"row"} spacing={2} alignItems={"center"}>
                                    <Box
                                        sx={{
                                            width: 80,
                                            height: 80,
                                            borderRadius: 100,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background:
                                                "linear-gradient(113.98deg, #933FE7 1.87%, #722FDE 47.17%, #5B2CE1 81.08%, #322FDE 116.14%)",
                                        }}
                                    >
                                        <PersonRounded htmlColor={"white"} fontSize={"large"} />
                                    </Box>
                                    <Stack spacing={1}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Typography variant={"h4"} fontSize={"1.2em"}>
                                                {lead.customer.name}
                                            </Typography>
                                            {lead.customer?.id && showCustomerLink && (
                                                <Button
                                                    size={"small"}
                                                    variant={"outlined"}
                                                    color={"secondary"}
                                                    onClick={handleClickGoToCustomer}
                                                    sx={{
                                                        borderRadius: 100,
                                                        ml: 2,
                                                        fontSize: "0.88em",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {t("buttons.showCustomerInfo")}
                                                </Button>
                                            )}
                                        </Box>
                                        <Stack direction={"row"} spacing={3}>
                                            <IconLabel
                                                Icon={EmailOutlined}
                                                label={lead.customer.email}
                                            />
                                            <IconLabel
                                                Icon={PhoneOutlined}
                                                label={lead.customer.phone}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Stack
                                    divider={<Divider orientation={"vertical"} flexItem />}
                                    direction={"row"}
                                    justifyContent={"space-evenly"}
                                    p={"0 16px"}
                                    spacing={3}
                                >
                                    <RowItem label={"Modtaget"}>
                                        <>
                                            <Typography>
                                                {formatDistanceToNow(new Date(lead.created))}{" "}
                                                {t("since", "utils.generic")}
                                            </Typography>
                                            <Typography variant={"body2"}>
                                                {renderDateTime(lead.created)}
                                            </Typography>
                                        </>
                                    </RowItem>
                                    <RowItem label={"Type"}>
                                        <Typography whiteSpace={"pre-wrap"}>
                                            {t("labels.new")}
                                            {lead.customer?.id &&
                                                `\n${t("labels.existingCustomer")}`}
                                        </Typography>
                                    </RowItem>
                                    <RowItem label={"Status"}>
                                        <StatusChip status={getStatus(lead)} />
                                    </RowItem>
                                </Stack>
                            </FlatCard>
                            {lead?.booking && !lead.booking.id ? (
                                <IntroSession handleClose={onBack} lead={lead as EnrichedLead} />
                            ) : lead.service ? (
                                <SyiSection title={capitalize(t("service", "utils.generic"))}>
                                    <ServiceLink
                                        picture={lead.service.picture}
                                        id={lead.serviceId}
                                        name={lead.service.name}
                                        onClose={onBack}
                                    />
                                </SyiSection>
                            ) : null}
                        </>

                        {!disableRecords && (
                            <SyiSection title={t("addNote", "utils.generic")}>
                                <Records
                                    hideSkeleton={true}
                                    subject={"lead"}
                                    subjectId={leadId}
                                    renderAddRecord={({ handleChange, value }) => (
                                        <SolidTextField
                                            value={value}
                                            label={t("addNote", "utils.generic")}
                                            onChange={(e) => handleChange(e.target.value)}
                                            fullWidth
                                            multiline
                                        />
                                    )}
                                />
                            </SyiSection>
                        )}
                    </Stack>
                ) : (
                    <SimpleSkeleton {...skeletonProps} />
                )}
            </FormProvider>
        </Box>
    );
};

const sources = ["facebook", "google", "website", "phone"];

const SourceSelect = ({
    defaultValue,
    ...props
}: SelectProps & { defaultValue: string | undefined }) => {
    const { t } = useTranslation("translation");
    return (
        <Select
            defaultValue={defaultValue}
            displayEmpty
            sx={{
                ":before": { borderBottom: "none !important" },
                "& .MuiSelect-select": { pr: "20px !important", fontSize: "1rem" },
            }}
            {...props}
        >
            <MenuItem value={undefined}>Vælg</MenuItem>
            {sources.map((el) => {
                return (
                    <MenuItem value={el} key={el}>
                        {t(`utils.leadSources.${el}`)}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

const RowItem = ({ label, children, ...props }: BoxProps & { label: string }) => {
    return (
        <Box
            textAlign={"center"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            p={"16px 0"}
            {...props}
        >
            <Typography
                display={"block"}
                mb={0.5}
                variant={"body2"}
                fontWeight={500}
                color={"#8E8E93"}
            >
                {label}
            </Typography>
            {children}
        </Box>
    );
};

const IntroSession = ({
    lead,
    handleClose,
    ...props
}: BoxProps & { lead: EnrichedLead; handleClose: () => void }) => {
    const { t } = useTranslate("utils.generic");
    return (
        <SyiSection title={t("introSessionBooked")} {...props}>
            <ServiceLink
                picture={lead.service.picture}
                id={lead.serviceId}
                name={lead.service.name}
                onClose={handleClose}
            >
                <Typography variant={"body2"} mt={0.5} mb={0.5} display={"block"}>
                    {renderLeadService(lead).replace("\n", " ")}
                </Typography>
                <Typography variant={"body2"} display={"block"}>
                    {renderDateTime(lead.booking.startDateTime, lead.booking.endDateTime, {
                        sameDay: "ddmhh",
                    })}
                </Typography>
            </ServiceLink>
        </SyiSection>
    );
};

type ServiceLinkProps = {
    picture: string;
    id: string;
    name: string;
    onClose: () => void;
} & BoxProps;
const ServiceLink = ({ picture, id, name, children, onClose, ...props }: ServiceLinkProps) => {
    return (
        <Box display={"flex"} {...props}>
            <Box
                component={"img"}
                src={picture}
                width={64}
                height={64}
                sx={{ objectFit: "cover" }}
                borderRadius={1}
                alt={name}
                mr={1}
            />
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                <Link to={`/service/${id}`} onClick={onClose}>
                    <Typography sx={{ textDecoration: "underline" }} variant={"h5"}>
                        {name}
                    </Typography>
                </Link>

                {children}
            </Box>
        </Box>
    );
};
