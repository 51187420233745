import {
    Box,
    BoxProps,
    Card,
    Chip,
    Grid,
    IconButton,
    IconButtonProps,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { BookingListEmptyScreen, EmptyScreen } from "../EmptyScreen/EmptyScreen";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { ListHeader } from "../ListHeader/ListHeader";
import {
    AccessTimeRounded,
    DeleteForeverRounded,
    EmailOutlined,
    MoreVert,
    PhoneOutlined,
} from "@mui/icons-material";
import { BookingsIcon } from "../../Icons";
import { IconLabel } from "../IconLabel/IconLabel";
import NiceModal from "@ebay/nice-modal-react";
import { EnrichedOffer, Offer, useOffers } from "../../Hooks/useOffers";
import { OfferDialog } from "../../Modals/OfferDialog";
import { renderDate } from "@hiddengemgroup/utils-date";
import { ShowForRole } from "../AllowForRole/AllowForRole";
import { useListItemOptions } from "../../Hooks/useListItemOptions";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { ReducedPrice } from "../ReducedPrice";
import { useNavigate } from "react-router-dom";
import { ellipsisStyle } from "../../Utils/theme";

const columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
        value: () => <BookingsIcon />,
    },
    {
        key: "name",
        label: "Navn",
        props: {
            xs: 3.2,
            lg: 2.6,
        },
        value: (offer: Offer) => (
            <>
                <Typography
                    fontSize={{ xs: "0.88em", lg: "1em" }}
                    lineHeight={"1.1"}
                    fontWeight={"500"}
                >
                    {offer?.customer.name}
                </Typography>
                <IconLabel
                    variant={"body2"}
                    fontSize={"0.75em"}
                    Icon={AccessTimeRounded}
                    label={formatDistanceToNow(new Date((offer as any).created)) + " siden"}
                />
            </>
        ),
    },
    {
        key: "contact",
        label: "Kontakt",
        props: {
            xs: 3.5,
            lg: 2.5,
            pr: { xs: 0.5, lg: 2 },
        },
        value: (offer: Offer) => (
            <>
                <IconLabel sx={ellipsisStyle} Icon={EmailOutlined} label={offer?.customer.email} />
                <IconLabel Icon={PhoneOutlined} label={offer?.customer.phone} />
            </>
        ),
    },
    {
        key: "created",
        label: "Købsdato",
        mobile: false,
        props: {
            xs: 1.5,
        },
        value: (el: Offer & { created?: string }) => (
            <Typography fontSize={"0.88rem"}>{renderDate(el.created ?? "")}</Typography>
        ),
    },
    {
        key: "nextTime",
        label: "Næste gang",
        mobile: false,
        props: {
            xs: 1.5,
        },
        value: (el: EnrichedOffer) => (
            <Typography fontSize={"0.88rem"}>
                {renderDate(el.nextBooking?.startDateTime ?? "")}
            </Typography>
        ),
    },
    {
        key: "status",
        label: "Status",
        props: {
            xs: 3,
            lg: 2,
        },
        value: (el: EnrichedOffer) => (
            <span>
                <Chip
                    color={
                        el.availableQuantity === 0
                            ? "default"
                            : el.transaction
                            ? "success"
                            : "warning"
                    }
                    label={
                        el.transaction
                            ? `${el.availableQuantity ?? el.totalQuantity}/${
                                  el.totalQuantity
                              } sessioner tilbage`
                            : "Afventer betaling"
                    }
                />
            </span>
        ),
    },
    {
        key: "amount",
        label: "Beløb",
        props: {
            xs: true,
        },
        value: (offer: Offer, t: TranslateFunction) => (
            <ReducedPrice
                originalPrice={offer?.price?.totalAmount ?? 0}
                reducedPrice={(offer?.price?.totalAmount ?? 0) - (offer.discount?.rate ?? 0)}
                currency={offer?.price?.currency ?? "dkk"}
                fontSize={"0.88rem"}
            />
        ),
    },
];

export type OffersListProps = BoxProps & {};

const options = [
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

export const OffersList = ({ ...props }: OffersListProps) => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const {
        offers: { data: _offers, isLoading },
    } = useOffers();

    const [searchValue, setSearchValue] = useState<null | string>(null);

    const [filter, setFilter] = useState<"all" | "pending" | "paid" | string>("all");

    const { deleteOffer, resend } = useOffers();

    const handleSelectOption = async (key: string, index?: number) => {
        if (index === undefined || !offers?.[index]?.id) {
            return;
        }
        if (key === "delete") {
            const { refund, id, transaction, totalQuantity, availableQuantity } = offers[index];
            if (totalQuantity !== availableQuantity) {
                window.alert(
                    "Du kan ikke slette et tilbud med aktive bookings. Aflys de pågælende"
                );
                return;
            }
            if (transaction && !refund) {
                window.alert("Du skal refundere kundens betaling før du kan slette");
            }
            if (
                id &&
                ((transaction && refund) || (!transaction && !refund)) &&
                totalQuantity === availableQuantity
            ) {
                await NiceModal.show(ConfirmDialog, {
                    headline: `Du er ved at slette dette tilbud`,
                    title: "Er du sikker på at slette?",
                }).then(() => {
                    return deleteOffer.mutateAsync(id);
                });
            }
        }
    };

    const { handleClickOptions, render: renderOptionsDialog } = useListItemOptions(
        options,
        handleSelectOption
    );

    const offers = useMemo(() => {
        const o = _offers?.filter((el) => {
            switch (filter) {
                case "all":
                    return true;
                case "pending":
                    return !Boolean(el.transaction);
                case "paid":
                    return Boolean(el.transaction);
            }
            return true;
        });
        if (searchValue) {
            return o?.filter(({ customer }) => {
                return [customer.name, customer.email, customer.phone].some((el) =>
                    el?.toLowerCase().includes(searchValue.toLowerCase())
                );
            });
        }
        return o;
    }, [_offers, searchValue, filter]);

    const { t } = useTranslate("utils.tables.header");

    const handleClick = (offerId?: string, customerId?: string) => () => {
        if (customerId) {
            navigate(`/offers/${offerId}`);
        } else {
            NiceModal.show(OfferDialog, { offerId });
        }
    };

    return (
        <Box {...props}>
            <ListHeader
                title={null}
                filterOptions={[
                    { key: "all", label: "Alle" },
                    { key: "pending", label: "Afventer betaling" },
                    { key: "paid", label: "Betalt" },
                ]}
                searchPlaceholder={"Søg i tilbud"}
                onSearch={setSearchValue}
                onChangeFilter={setFilter}
                defaultSelectedFilter={"all"}
                mb={2}
            />
            {renderOptionsDialog()}
            <Grid container p={"12px 20px"} mb={1}>
                {columns.map((el) => {
                    if (el.mobile === false && isMobile) {
                        return null;
                    }
                    return (
                        <Grid
                            fontSize={"0.75em"}
                            fontWeight={600}
                            textTransform={"uppercase"}
                            key={el.key}
                            item
                            {...el.props}
                            component={Typography}
                        >
                            {el.key !== "none" && t(el.key)}
                        </Grid>
                    );
                })}
            </Grid>

            <Stack spacing={0}>
                {offers?.length === 0 && (
                    <BookingListEmptyScreen
                        mt={6}
                        title={t("emptyState.title", "offers")}
                        description={t("emptyState.description", "offers")}
                        buttonLabel={t("emptyState.action", "offers")}
                        onClick={handleClick()}
                    />
                )}

                {offers?.map((offer, i) => (
                    <OfferListItem
                        key={offer.id}
                        offer={offer}
                        onClick={handleClick}
                        onClickOptions={handleClickOptions(i)}
                        mobile={isMobile}
                    />
                )) ?? <ListSkeleton />}
            </Stack>
        </Box>
    );
};

const OfferListItem = ({
    onClick,
    onClickOptions,
    offer,
    mobile = false,
}: {
    offer: Offer;
    onClick: (id: string, customerId?: string) => () => void;
    onClickOptions: IconButtonProps["onClick"];
    mobile?: boolean;
}) => {
    const { t } = useTranslate("utils");
    return (
        <Box p={"4px 8px"}>
            <Grid
                container
                component={Card}
                p={1.5}
                alignItems={"center"}
                sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "rgb(252,252,252)" },
                }}
                onClick={onClick(offer.id as string, offer?.transaction && offer.customer?.id)}
            >
                {columns.map((el) => {
                    if (el.mobile === false && mobile) {
                        return null;
                    }
                    return (
                        <Grid
                            whiteSpace={"pre-wrap"}
                            key={`${offer.id}-${el.key}`}
                            item
                            {...el.props}
                            {...el.valueProps}
                        >
                            {el.value?.(offer as EnrichedOffer, t)}
                        </Grid>
                    );
                })}
                <Grid item flexGrow={1} textAlign={"right"}>
                    <ShowForRole roles={["admin"]}>
                        <IconButton size={"small"} onClick={onClickOptions}>
                            <MoreVert />
                        </IconButton>
                    </ShowForRole>
                </Grid>
            </Grid>
        </Box>
    );
};
