import { Box, BoxProps, StackProps, Typography, TypographyProps } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";
import { ErrorLabel } from "./ErrorLabel";

export const SyiSection = ({
    title,
    error,
    children,
    Component = Box,
    typographyProps,
    ...props
}: (BoxProps | StackProps) & {
    title: string | ReactNode;
    Component?: FunctionComponent;
    typographyProps?: TypographyProps;
    error?: string;
}) => {
    return (
        <Component {...props}>
            <Typography mb={2} variant={"h5"} {...typographyProps}>
                {title}
                {Boolean(error) && <ErrorLabel mt={1} label={error} />}
            </Typography>
            {children}
        </Component>
    );
};
