import {
    Box,
    BoxProps,
    CircularProgress,
    IconButton,
    Link,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import { Profile, CompanyProfile } from "@spiritworld-dk/ui-core";
import { useWatch } from "react-hook-form";
import { useLocalizer } from "../../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { DesktopIcon, MobileIcon } from "../../Icons";
import { IFrame } from "../IFrame";
import { allTagsObject } from "../Tags/Tags";
import { useService, type Variant as ServiceVariant } from "../../Hooks/useService";
import { ReactNode, Suspense, useCallback, useState } from "react";
import { CONSUMER_URL } from "../../Utils/constants";
import { useProfile } from "../../Hooks/useProfile";
import { useWeakStore as useStore } from "../../Store/useStore";
import { IconLabel } from "../IconLabel/IconLabel";

export const WithPreview = ({
    children,
    render,
}: {
    children: ReactNode;
    render: (onToggle: () => void, desktopEnabled: boolean) => ReactNode;
}) => {
    const [desktopPreview, setDesktopPreview] = useState(false);
    const renderPreview = useCallback(render, [desktopPreview]);
    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            maxWidth={"100%"}
            spacing={{ lg: 2, xl: 0 }}
        >
            <Box
                maxWidth={desktopPreview ? 0 : 680}
                maxHeight={desktopPreview ? 800 : "100%"}
                sx={{
                    transition: "max-width 0.2s",
                }}
                overflow={"hidden"}
                position={"relative"}
            >
                <Box maxWidth={{ xs: 680 }}>{children}</Box>
            </Box>
            <Box display={{ xs: "none", lg: "block" }}>
                {renderPreview(() => setDesktopPreview((p) => !p), desktopPreview)}
            </Box>
        </Stack>
    );
};

const Frame = ({
    children,
    format = "mobile",
    ...props
}: BoxProps & { format?: "mobile" | "desktop" }) => {
    return (
        <Box
            height={{ lg: 640, xl: 806 }}
            width={{
                lg: format === "desktop" ? 1200 : 320,
                xl: format === "desktop" ? 1200 : 400,
            }}
            position={"relative"}
            overflow={"hidden"}
            boxShadow={"0 0 8px 0 rgba(100,100,100,0.1)"}
            borderRadius={{
                lg: format === "desktop" ? 2 : 6,
                xl: format === "desktop" ? 2 : 7.75,
            }}
            {...props}
            sx={{
                transition: "0.4s all",
                backgroundColor: "white",
                ...props?.sx,
            }}
        >
            <Box
                sx={{
                    zIndex: 3,
                    backgroundRepeat: "no-repeat",
                    backgroundImage: "url('/graphics/device-frame.svg')",
                    backgroundSize: { lg: "328px 648px", xl: "400px 806px" },
                    ...(format === "desktop" && {
                        display: "none",
                    }),
                    pointerEvents: "none",
                }}
                position={"absolute"}
                top={{ lg: "-4px", xl: 0 }}
                left={{ lg: "-4px", xl: 0 }}
                height={"calc(100% + 8px)"}
                width={"calc(100% + 8px)"}
            />
            <IFrame
                height={"100%"}
                position={"absolute"}
                top={0}
                p={format === "desktop" ? 2 : "0px 8px"}
                mt={{ lg: "-4px", xl: "-2px" }}
                ml={{ lg: "-4px", xl: 0 }}
                left={0}
                sx={{ zIndex: 2, width: { lg: "calc(100% + 8px)", xl: "calc(100% + 14px)" } }}
            >
                {children}
            </IFrame>
            <Box
                position={"absolute"}
                top={0}
                left={0}
                height={"100%"}
                width={"100%"}
                sx={{
                    zIndex: 1,
                    backgroundImage: "url('/graphics/device-background.svg')",
                    backgroundSize: { lg: "320px 640px", xl: "400px 806px" },
                    ...(format === "desktop" && {
                        display: "none",
                    }),
                }}
            />
        </Box>
    );
};

const FormatSwitcher = ({
    desktopEnabled,
    onToggle,
    ...props
}: BoxProps & {
    desktopEnabled?: boolean;
    onToggle: (isDesktop: boolean) => void;
}) => {
    return (
        <Box display={"flex"} {...props}>
            <IconButton onClick={() => onToggle(true)}>
                <DesktopIcon htmlColor={desktopEnabled ? "#007AFF" : "inherit"} />
            </IconButton>
            <IconButton onClick={() => onToggle(false)}>
                <MobileIcon htmlColor={desktopEnabled ? "inherit" : "#007AFF"} />
            </IconButton>
        </Box>
    );
};
const getLowestPrice = (variants: ServiceVariant[] | undefined) => {
    return Math.min(...(variants?.map((el) => el.price) ?? [0]));
};

const getFirstName = (name: string) => {
    const [first] = name?.split(" ") ?? [];
    return first ?? "";
};

export const ProfilePreviewer = ({
    onToggle,
    desktopEnabled = false,
    ...props
}: BoxProps & {
    desktopEnabled?: boolean;
    onToggle: (isDesktop: boolean) => void;
}) => {
    const {
        me: { data: me },
    } = useProfile();

    const { updatingProfile } = useStore();

    const [name, cover, profile, shortDescription, tags, description, video] = useWatch({
        name: [
            "me.name",
            "me.pictures.cover",
            "me.pictures.profile",
            "me.shortDescription",
            "me.tags",
            "me.description",
            "me.profileVideo",
        ],
    });

    const { activeServices: services } = useService();

    const localize = useLocalizer();

    const publicUrl = `${CONSUMER_URL}/profile/${me?.id}`;

    return (
        <Previewer
            updating={updatingProfile}
            publicUrl={publicUrl}
            desktopEnabled={desktopEnabled}
            onToggle={onToggle}
            {...props}
        >
            <Profile
                p={1}
                hero={{
                    coverImage: cover?.url,
                    profileImage: profile?.url,
                    name: name,
                    subtitle: localize(shortDescription),
                    tagsLabel: `${getFirstName(name)} arbejder med`,
                    tags:
                        tags?.map((key: string) => ({
                            key,
                            label: allTagsObject[key],
                        })) ?? [],
                }}
                description={{
                    body: localize(description),
                    title: "Beskrivelse",
                }}
                video={
                    video && {
                        src: video?.url,
                        poster: video?.poster?.url ?? profile?.url,
                    }
                }
                itemsSectionTitle={`Alt hvad ${getFirstName(name)} tilbyder`}
                items={
                    services?.map((el) => ({
                        id: el.id as string,
                        image: el.pictures?.[0]?.url,
                        title: localize(el.headline),
                        price: getLowestPrice(el.variants),
                    })) ?? []
                }
            />
        </Previewer>
    );
};

const Previewer = ({
    updating,
    publicUrl,
    desktopEnabled,
    onToggle,
    children,
    ...props
}: BoxProps & {
    updating: boolean;
    publicUrl: string;
    desktopEnabled: boolean;
    onToggle: (isDesktop: boolean) => void;
}) => {
    return (
        <Box {...props}>
            <Box mb={5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h4"} fontSize={{ lg: "1.2em", xl: "1.3em" }}>
                        Din Spiritworld Profilside
                    </Typography>
                    {updating ? (
                        <IconLabel
                            color={"#777777"}
                            fontSize={"0.75em"}
                            label={"Opdaterer din profil på Spiritworld..."}
                            icon={<CircularProgress sx={{ mr: 0.5 }} size={"0.88em"} />}
                        />
                    ) : (
                        <Link target={"_blank"} href={publicUrl} variant={"h6"}>
                            Se på spiritworld.dk
                        </Link>
                    )}
                </Box>

                <FormatSwitcher onToggle={onToggle} desktopEnabled={desktopEnabled} />
            </Box>

            <Frame format={desktopEnabled ? "desktop" : "mobile"}>
                <Suspense fallback={<p>Loading...</p>}>{children}</Suspense>
            </Frame>
        </Box>
    );
};
export const CompanyProfilePreviewer = ({
    onToggle,
    desktopEnabled = false,
    ...props
}: BoxProps & {
    desktopEnabled?: boolean;
    onToggle: (isDesktop: boolean) => void;
}) => {
    const {
        company: { data: company },
    } = useProfile();

    const { updatingProfile } = useStore();

    const [name, cover, logo, description] = useWatch({
        name: [
            "company.name",
            "company.pictures.cover",
            "company.pictures.logo",
            "company.description",
        ],
    });

    const { activeServices: services } = useService();

    const localize = useLocalizer();

    const publicUrl = `${CONSUMER_URL}/company/${company?.id}`;

    return (
        <Previewer
            updating={updatingProfile}
            publicUrl={publicUrl}
            desktopEnabled={desktopEnabled}
            onToggle={onToggle}
            {...props}
        >
            <CompanyProfile
                mt={{ xs: 8, md: 0 }}
                px={{ xs: 1, md: 0 }}
                pb={{ xs: 3, md: 0 }}
                hero={{
                    coverImage: cover?.url,
                    logo: logo?.url,
                    name,
                    subtitle: "Verificeret og godkendt virksomhed",
                }}
                description={{
                    body: localize(description),
                    title: `Om ${name}`,
                }}
                itemsSectionTitle={`Alt hvad ${name} tilbyder`}
                items={
                    services?.map((el) => ({
                        id: el.id as string,
                        image: el.pictures?.[0]?.url,
                        title: localize(el.headline),
                        price: getLowestPrice(el.variants),
                    })) ?? []
                }
                vouchersCard={{
                    href: "#",
                    title: "Giv som gave",
                    subtitle:
                        "Gavekortet leveres digitalt til din mail og gælder til alle Cecilies ydelser",
                    bullets: [
                        "2 års gyldighed & gratis forlængelse",
                        "2 års fri bytte- & returret",
                        "Mulighed for at tilføje en personlig hilsen",
                    ],
                    buttonLabel: "Køb et gavekort",
                    illustration: "/graphics/voucher-graphic.png",
                }}
            />
        </Previewer>
    );
};
