import { FlatCard } from "../FlatCard/FlatCard";
import { Box, Button, Typography, type BoxProps } from "@mui/material";
import { Dropzone } from "../Dropzone/Dropzone";
import { TImage } from "../../Utils/types";
import { useRef } from "react";
import * as api from "../../Api";

type CoverImageUploadProps = {
    image?: TImage;
    subject: "company" | "me";
    subjectId: string | undefined;
    onUploadPrepared: (...params: any[]) => void;
} & BoxProps;

export const CoverImageUpload = ({
    image,
    subject,
    subjectId,
    onUploadPrepared,
    ...props
}: CoverImageUploadProps) => {
    const ref = useRef<HTMLInputElement | null>(null);

    const handleClickUpload = () => {
        ref.current?.click();
    };

    const handleUpload = (...params: any[]) => {
        onUploadPrepared(...params);
        if (image?.key) {
            api.deleteUpload(image.key);
        }
    };

    return (
        <FlatCard height={180} p={0} position={"relative"} {...props}>
            <Box
                p={3}
                height={"100%"}
                position={"absolute"}
                zIndex={5}
                pr={8}
                sx={{
                    background:
                        "linear-gradient(90deg, rgba(255,255,255,0.80) 0%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0) 100%)",
                }}
            >
                <Typography variant={"h5"}>Coverbillede</Typography>
                <Button variant={"contained"} sx={{ mb: 2, mt: 2 }} onClick={handleClickUpload}>
                    Upload coverbillede
                </Button>
                <Typography variant={"body2"} display={"block"}>
                    Dit coverbillede skal være <br /> minimum 1200 (b) x 250 (h) pixels.
                </Typography>
            </Box>
            <Dropzone
                ref={ref}
                fileType={"cover"}
                modelType={subject}
                urls={image?.url ? [image.url] : undefined}
                id={subjectId}
                onPrepared={handleUpload}
                maxFiles={1}
                containerProps={{
                    sx: {
                        width: "100%",
                        height: "100%",
                        alignItems: "flex-start",
                        textAlign: "left",
                        border: "none",
                        p: 0,
                    },
                }}
            />
        </FlatCard>
    );
};
