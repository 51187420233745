import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import { KeyboardBackspaceRounded } from "@mui/icons-material";

export const ViewHeader = ({
    title,
    onBack,
    ...props
}: BoxProps & {
    title: string | undefined;
    onBack?: () => void;
}) => {
    return (
        <Box display={"flex"} alignItems={"center"} mb={3} {...props}>
            {onBack && (
                <IconButton onClick={onBack}>
                    <KeyboardBackspaceRounded fontSize={"large"} />
                </IconButton>
            )}
            <Typography variant={"h5"} fontSize={"1.3em"} fontWeight={700} ml={0.5}>
                {title}
            </Typography>
        </Box>
    );
};
