import NiceModal from "@ebay/nice-modal-react";
import {
    WriteParticipantsDialog,
    WriteParticipantsDialogReturnValue,
} from "../Modals/WriteParticipantsDialog";
import { deleteUpload, messageParticipants } from "../Api";
import { toast } from "react-toastify";
import { useTranslate } from "./useTranslate";

type Props = {
    eventId: string;
    headline?: string;
    startDateTime?: string;
    participantCount: number;
    userId?: string;
};

type Attachment = {
    key: string;
    url: string;
    name: string;
};
export const useWriteParticipants = ({
    eventId,
    headline,
    startDateTime,
    participantCount,
    userId,
}: Props) => {
    const { t } = useTranslate("dialogs.writeParticipants.toast");
    const handleWriteParticipants = (
        config = {
            eventId,
            headline,
            startDateTime,
            participantCount,
            defaultSendCopyToMe: undefined as undefined | boolean,
            defaultMessage: undefined as undefined | string,
            defaultAttachments: undefined as undefined | Attachment[],
        },
        excludes?: string[]
    ) => {
        if (userId) {
            NiceModal.show<WriteParticipantsDialogReturnValue>(WriteParticipantsDialog, config)
                .then(({ message, attachments, sendCopyToMe, ...conf }) => {
                    const toastId = toast.loading("Sender besked...");
                    messageParticipants(
                        eventId,
                        userId,
                        message,
                        sendCopyToMe,
                        attachments,
                        excludes
                    )
                        .then(() =>
                            toast.update(toastId, {
                                render: t("success"),
                                type: "success",
                                autoClose: 2500,
                                isLoading: false,
                            })
                        )
                        .catch((err) => {
                            toast.update(toastId, {
                                render: t("error", { message: err.msg }),
                                autoClose: false,
                                type: "error",
                                isLoading: false,
                            });
                            handleWriteParticipants(
                                {
                                    ...conf,
                                    defaultSendCopyToMe: sendCopyToMe,
                                    participantCount: err.failed.length,
                                    defaultMessage: message,
                                    defaultAttachments: attachments,
                                },
                                err.succeeded as string[]
                            );
                        });
                })
                .catch(() =>
                    Promise.allSettled(
                        config.defaultAttachments?.map((el) => deleteUpload(el.key)) ?? []
                    )
                );
        }
    };
    return handleWriteParticipants;
};
