import Fetch, { IShallowSuccessResponse } from './index'
import type { TDiscount, TRedemption } from '../Hooks/useDiscount'

export const getDiscounts = async () => {
    if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DISCOUNTS_URL is not defined')
    }

    return Fetch.get<TDiscount[]>(`${process.env.REACT_APP_API_DISCOUNTS_URL}`)
}

export const getRedemptions = async (discountId: string) => {
    if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DISCOUNTS_URL is not defined')
    }

    if (!discountId) {
        throw Error('Missing discount id')
    }

    return Fetch.get<TRedemption[]>(
        `${process.env.REACT_APP_API_DISCOUNTS_URL}${discountId}/redemptions`,
    )
}

export const getDiscount = async (id: string) => {
    if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DISCOUNTS_URL is not defined')
    }

    if (!id) {
        throw Error('Missing discount id')
    }

    return Fetch.get<TDiscount>(`${process.env.REACT_APP_API_DISCOUNTS_URL}${id}`)
}

export const updateDiscount = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DISCOUNTS_URL is not defined')
    }

    return Fetch.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_DISCOUNTS_URL}${id}`,
        payload,
    )
}

export const deleteDiscount = async (id: string) => {
    if (typeof process.env.REACT_APP_API_DISCOUNTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DISCOUNTS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_DISCOUNTS_URL}${id}`)
}
