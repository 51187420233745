import { Box, Typography, Button } from "@mui/material";
import { mapRoleToUsers, UsersList } from "../../Components/UsersList/UsersList";
import { AddRounded } from "@mui/icons-material";
import NiceModal from "@ebay/nice-modal-react";
import { InviteUsersDialog } from "../../Modals/InviteUsersDialog";
import { useOutletContext } from "react-router-dom";
import { useController } from "react-hook-form";
import { useTranslate } from "../../Hooks/useTranslate";

const mapUsersToRoles = (users: any[]) => {
    return users.reduce((acc, { role, ...props }) => {
        const type = `${role}s`;
        return {
            ...acc,
            [type]: [...(acc?.[type] ?? []), { ...props }],
        };
    }, {});
};

export const SettingsUsersSection = () => {
    const { t } = useTranslate("settings.team");

    const { submitCompany } = useOutletContext<any>();

    const { field: viewers } = useController({ name: "company.viewers" });
    const { field: admins } = useController({ name: "company.admins" });

    const handleClickInvite = async () => {
        const usersToBeAdded = await NiceModal.show<any[]>(InviteUsersDialog);
        const existingUsers = mapRoleToUsers({
            admins: admins.value ?? [],
            viewers: viewers.value ?? [],
        });
        handleUpdateUsers([...existingUsers, ...usersToBeAdded]);
    };

    const handleUpdateUsers = (users: any[]) => {
        const previousAdmins = admins.value;
        const previousGuides = viewers.value;
        const { admins: _admins, viewers: _viewers } = mapUsersToRoles(users);
        try {
            admins.onChange(Array.from(new Set([...(_admins ?? [])])));
            viewers.onChange(Array.from(new Set([...(_viewers ?? [])])));
            submitCompany();
        } catch (err) {
            console.log(err);
            admins.onChange(previousAdmins);
            viewers.onChange(previousGuides);
        }
    };

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                <Typography variant={"h3"}>{t("title")}</Typography>
                <Button
                    variant={"contained"}
                    startIcon={<AddRounded />}
                    onClick={handleClickInvite}
                >
                    {t("buttonLabel")}
                </Button>
            </Box>

            <UsersList onUpdate={handleUpdateUsers} />
        </>
    );
};
