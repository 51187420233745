import { createTheme } from '@mui/material'
import { UnfoldMoreRounded } from '@mui/icons-material'
import { makeGradient } from './gradients'

const fontFamily = 'Figtree,-apple-system,Roboto,Helvetica Neue,Arial,sans-serif'

export const ellipsisStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '95%',
}

export const clamp = (rows: number) => {
    return {
        lineClamp: `${rows}`,
        display: '-webkit-box',
        '-webkit-line-clamp': `${rows}`,
        '-webkit-box-orient': 'vertical',
    }
}

export const theme = createTheme({
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
        unit: 'px',
    },
    direction: 'ltr',
    components: {
        MuiAvatar: {
            defaultProps: {},
        },
        MuiCssBaseline: {
            defaultProps: {
                enableColorScheme: true,
            },
        },
        MuiButtonBase: {
            defaultProps: {},
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    border: '1px solid transparent',
                },
                sizeLarge: {
                    padding: '0.875rem 24px',
                    lineHeight: 1.3125,
                    letterSpacing: 0,
                    fontFamily,
                    fontWeight: 600,
                },
                sizeSmall: {
                    padding: '3px 12px',
                },
                contained: {
                    paddingLeft: 24,
                    paddingRight: 24,
                },
                outlinedSecondary: {
                    backgroundColor: 'white',
                    borderColor: '#C7C7CC',
                },
            },
            variants: [
                {
                    props: {},
                    style: {
                        color: '#2D3843',
                        border: '1px solid',
                        borderColor: '#CDD2D7',
                        backgroundColor: '#F3F6F9',
                        fontFamily,
                        fontWeight: 600,
                        lineHeight: 1.5,
                        letterSpacing: 0,
                        WebkitFontSmoothing: 'subpixel-antialiased',
                        '&:hover, &.Mui-focusVisible': {
                            borderColor: 'red',
                            backgroundColor: 'rgba(28,97, 175, 0.2)',
                            '& .MuiButton-endIcon': {
                                color: '#007FFF',
                            },
                        },
                        '& .MuiButton-startIcon': {
                            color: '#B2BAC2',
                        },
                        '& .MuiButton-endIcon': {
                            display: 'inline-block',
                            position: 'absolute',
                            right: 0,
                            marginRight: 10,
                            color: '#3E5060',
                        },
                    },
                },
                {
                    props: {},
                    style: {
                        fontWeight: 600,
                        '& svg': {
                            ml: -0.5,
                        },
                    },
                },
            ],
        },
        MuiIconButton: {
            variants: [
                {
                    props: {
                        color: 'primary',
                    },
                    style: {
                        height: 34,
                        width: 34,
                        border: '1px solid #E0E3E7',
                        borderRadius: 10,
                        color: '#007FFF',
                        '&:hover': {
                            borderColor: '#CDD2D7',
                            background: '#F3F6F9',
                        },
                    },
                },
            ],
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    ['@media screen and (max-width: 699px)']: {
                        margin: 0,
                        maxHeight: '100%',
                        borderRadius: 0,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    mt: 0.5,
                    minWidth: 160,
                    elevation: 0,
                    color: '#3E5060',
                    backgroundImage: 'none',
                    border: '1px solid #E0E3E7',
                    '&:hover': {
                        backgroundColor: 'rhba(255,255,255,0.1)',
                    },
                    '& .MuiMenuItem-root': {
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        '&:hover': {
                            backgroundColor: '#F3F6F9',
                        },
                        '&:focus': {
                            backgroundColor: '#F3F6F9',
                        },
                        '&.Mui-selected': {
                            fontWeight: 500,
                            color: '#0072E5',
                            backgroundColor: 'rgba(194, 224, 255, 0.6)',
                        },
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 4px 20px rgba(170, 180, 190, 0.3)',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width:900px)': {
                        paddingLeft: '48px',
                        paddingRight: '48px',
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#E7EBF0',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                color: '#007AFF',
            },
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontFamily,
                    '&.MuiTypography-body1 > svg': {
                        marginTop: 2,
                    },
                    '& svg:last-child': {
                        marginLeft: 2,
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'p',
                    body2: 'label',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    fontWeight: 500,
                    borderRadius: 28,
                    padding: '4px 0',
                    height: 'auto',
                    border: 'none',
                    ...(ownerState.variant === 'filled' &&
                        ownerState.color === 'primary' && {
                            color: '#fff',
                            background: makeGradient('primary'),
                        }),
                }),
                label: ({ ownerState }) => ({
                    ...(!ownerState.avatar && {}),
                }),
                outlined: {
                    color: '#1A2027',
                    backgroundColor: 'transparent',
                    border: '1px solid #CDD2D7',
                },
                filled: {
                    '&:hover': {},
                },
                deleteIcon: {
                    color: '#ccc',
                    '&:hover': {
                        color: '#ddd',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {},
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 40,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 600,
                },
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: false,
            },
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
            },
        },

        MuiListItemButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 12,
                    color: '#C7C7CC',
                    border: '1px solid transparent',
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.1)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#C7C7CC',
                    },
                    '&.Mui-selected': {
                        color: 'white',
                        borderRadius: 12,
                        backgroundColor: '#8E439A',
                        '&:hover': {
                            backgroundColor: 'rgba(255,255,255,0.1)',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: UnfoldMoreRounded,
            },
            styleOverrides: {
                select: {
                    paddingRight: '44px !important',
                },
                iconFilled: {
                    top: 'calc(50% - .25em)',
                },
                standard: {
                    '&::before': {
                        border: 'none !important',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    overflow: 'hidden',
                    '& .MuiTab-root:not(:first-child)': {
                        marginLeft: 4,
                    },
                },
                indicator: {
                    height: 'calc(100%)',
                    borderRadius: '10px',
                    backgroundColor: 'rgba(10,143,214,0.1)',
                    zIndex: 0,
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableTouchRipple: true,
            },
            styleOverrides: {
                root: {
                    fontSize: '0.88em',
                    fontWeight: 500,
                    minHeight: 56,
                    borderRadius: 10,
                    padding: '12px 18px',
                    '&:hover': { backgroundColor: 'rgba(220,220,220,0.08)' },
                    '&.Mui-selected': { color: 'primary.main' },
                    '& .MuiSvgIcon-root': { opacity: 0.8 },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: '#FFFFFF',
                    boxShadow:
                        '0 0 10px 0 rgba(181,181,190,0.10), 0 0 2px 0 rgba(151,151,151,0.20), 0 0 1px 0 rgba(151,151,151,0.35)',
                    borderRadius: 12,
                    padding: 20,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    backgroundColor: '#fff',
                    '&[href]': {
                        textDecorationLine: 'none',
                    },
                },
                outlined: {
                    display: 'block',
                    borderColor: '#E0E3E7',
                    'a&, button&': {
                        '&:hover': {
                            boxShadow: '0px 4px 20px rgba(170, 180, 190, 0.3)',
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {},
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '6px 16px',
                    borderColor: 'transparent',
                    backgroundColor: 'white',
                    borderTop: '1px solid #E5E5EA',
                    borderBottom: '1px solid #E5E5EA',
                },
                head: {
                    color: '#1A2027',
                    fontWeight: 700,
                },
                body: {
                    color: '#3E5060',
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 500,
                    color: '#3E5060',
                    borderColor: '#E0E3E7',
                    '&.Mui-selected': {
                        color: '#007FFF',
                        borderColor: '#007FFF !important',
                        backgroundColor: '#F0F7FF',
                        '&:hover': {
                            backgroundColor: '#C2E0FF',
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: '5px 9px',
                },
            },
        },
        /*"MuiSwitch": {
            "styleOverrides": {
                "root": {
                    "width": 32,
                    "height": 20,
                    "padding": 0,
                    "& .MuiSwitch-switchBase": {
                        "&.Mui-checked": {
                            "transform": "translateX(11px)",
                            "color": "#fff"
                        }
                    }
                },
                "switchBase": {
                    "height": 20,
                    "width": 20,
                    "padding": 0,
                    "color": "#fff",
                    "&.Mui-checked + .MuiSwitch-track": {
                        "opacity": 1,
                        "backgroundColor": "#1CAF5E"
                    }
                },
                "track": {
                    "opacity": 1,
                    "borderRadius": 32,
                    "backgroundColor": "#B2BAC2"
                },
                "thumb": {
                    "flexShrink": 0,
                    "width": "14px",
                    "height": "14px"
                }
            }
        },*/
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 700,
                    color: '#3E5060',
                    borderColor: '#E0E3E7',
                    '&.Mui-selected': {
                        color: '#007FFF',
                        borderColor: '#007FFF !important',
                        backgroundColor: '#F0F7FF',
                        '&:hover': {
                            backgroundColor: '#C2E0FF',
                        },
                    },
                },
            },
        },
    },
    palette: {
        mode: 'light',
        primary: {
            '50': '#F0F7FF',
            '100': '#C2E0FF',
            '200': '#99CCF3',
            '300': '#66B2FF',
            '400': '#3399FF',
            '500': '#007FFF',
            '600': '#0072E5',
            '700': '#0059B2',
            '800': '#004C99',
            '900': '#003A75',
            main: '#391874',
            light: '#4C2F81',
            dark: '#321566',
            contrastText: '#fff',
        },
        divider: '#E7EBF0',
        common: {
            black: '#1D1D1D',
            white: '#fff',
        },
        text: {
            primary: '#1A2027',
            secondary: '#3E5060',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        grey: {
            '50': '#F3F6F9',
            '100': '#E7EBF0',
            '200': '#E0E3E7',
            '300': '#CDD2D7',
            '400': '#B2BAC2',
            '500': '#A0AAB4',
            '600': '#6F7E8C',
            '700': '#3E5060',
            '800': '#2D3843',
            '900': '#1A2027',
            A100: '#f5f5f5',
            A200: '#eeeeee',
            A400: '#bdbdbd',
            A700: '#616161',
        },
        error: {
            '50': '#FFF0F1',
            '100': '#FFDBDE',
            '200': '#FFBDC2',
            '300': '#FF99A2',
            '400': '#FF7A86',
            '500': '#FF505F',
            '600': '#EB0014',
            '700': '#C70011',
            '800': '#94000D',
            '900': '#570007',
            main: '#BF324B',
            light: '#FF99A2',
            dark: 'rgba(241,75,68,1)',
            contrastText: '#ffffff',
        },
        success: {
            '50': '#E9FBF0',
            '100': '#C6F6D9',
            '200': '#9AEFBC',
            '300': '#6AE79C',
            '400': '#3EE07F',
            '500': '#21CC66',
            '600': '#1DB45A',
            '700': '#1AA251',
            '800': '#178D46',
            '900': '#0F5C2E',
            main: '#1CAF5E',
            light: '#6AE79C',
            dark: 'rgba(28,175,94,1)',
            contrastText: '#ffffff',
        },
        warning: {
            '50': '#FFF9EB',
            '100': '#FFF3C1',
            '200': '#FFECA1',
            '300': '#FFDC48',
            '400': '#F4C000',
            '500': '#DEA500',
            '600': '#D18E00',
            '700': '#AB6800',
            '800': '#8C5800',
            '900': '#5A3600',
            main: 'rgba(255, 204, 0, 1)',
            light: 'rgba(215, 208, 0, 1)',
            dark: 'rgba(245, 200, 0, 1)',
            contrastText: '#fff',
        },
        secondary: {
            main: '#000000',
            light: '#000000',
            dark: 'rgba(50,50,50,1)',
            contrastText: '#fff',
        },
        info: {
            contrastText: '#fff',
            dark: '#01579b',
            light: '#03a9f4',
            main: '#0288d1',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        background: {
            paper: '#fff',
            default: '#fff',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
    },
    shape: {
        borderRadius: 8,
    },
    unstable_strictMode: true,
    typography: {
        fontFamily,
        h1: {
            fontSize: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)',
            fontWeight: 800,
            lineHeight: 1.1142857142857143,
            color: '#0A1929',
        },
        h2: {
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            fontWeight: 700,
            lineHeight: 1.2222222222222223,
            color: '#132F4C',
        },
        h3: {
            fontSize: '1.5rem',
            lineHeight: 1.2222222222222223,
            letterSpacing: 0.2,
            fontWeight: 700,
        },
        h4: {
            fontSize: '1.4rem',
            lineHeight: 1.5,
            letterSpacing: 0.2,
            fontWeight: 700,
        },
        h5: {
            fontSize: '1em',
            letterSpacing: 0.1,
            fontWeight: 600,
        },
        h6: {
            fontSize: '0.88em',
            lineHeight: 1.5,
            fontWeight: 600,
        },
        button: {
            textTransform: 'initial',
            fontWeight: 500,
            letterSpacing: 0,
            fontSize: '1em',
            lineHeight: 1.75,
        },
        subtitle1: {
            fontSize: '1.125rem',
            lineHeight: 1.3333333333333333,
            letterSpacing: 0,
            fontWeight: 500,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: 0,
            fontWeight: 400,
        },
        body2: {
            fontSize: '0.75em',
            lineHeight: 1.4,
            letterSpacing: 0,
            fontWeight: 400,
        },
        caption: {
            display: 'inline-block',
            fontSize: '0.75rem',
            lineHeight: 1.5,
            letterSpacing: 0,
            fontWeight: 500,
        },
        htmlFontSize: 16,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        subtitle2: {
            fontFamily,
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.57,
        },
        overline: {
            fontFamily,
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 2.66,
            textTransform: 'uppercase',
        },
    },
    mixins: {
        toolbar: {
            minHeight: 56,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 48,
            },
            '@media (min-width:600px)': {
                minHeight: 64,
            },
        },
    },
    shadows: [
        'none',
        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
        '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
        '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
        '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
        '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
        '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
        '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
        '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
        '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
        '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
        '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
        '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
        '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
        '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    ],
    transitions: {
        easing: {
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
        },
    },
    zIndex: {
        mobileStepper: 1000,
        fab: 1050,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
})
