import Fetch from './index'

export type PaymentItems = {
    [id: string]: {
        quantity: number
        name: string
        variantId?: string
        sessionId?: string
        serviceId?: string
        price?: {
            amount: number
            taxId?: string
            currency: string
        }
        offer?: {
            token: string
        }
    }
}

export type Payment = {
    subject: string
    subjectId: string
    expiresIn?: number
    items: PaymentItems
}

export const updatePayment = async (paymentId: string, payload: Payment) => {
    if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PAYMENT_URL is not defined')
    }

    await Fetch.put(`${process.env.REACT_APP_API_PAYMENT_URL}payment/${paymentId}`, payload)
}

export const getPayment = async (id: string) => {
    if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PAYMENT_URL is not defined')
    }

    await Fetch.get(`${process.env.REACT_APP_API_PAYMENT_URL}${id}`)
}
