import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useBookings } from "./useBookings";
import { IService, useService } from "./useService";
import { useProfile } from "./useProfile";
import { TUser, useUsers } from "./useUsers";
import { mapAssignableGuidesToUsers } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { TShallowUser } from "../Components/EventsList/EventsList";
import { type TStatus } from "../Components/StatusChip";
import { eventSchema } from "../Pages/SyiPage/config";
import { useOnBoarding } from "./useOnBoarding";
import { useCallback } from "react";

export type Localized = {
    da: string;
    en: string;
};

type OptionValue = {
    selectedOptionKey?: string;
    value: string;
};

export interface IEvent extends Record<string, any> {
    id?: string;
    status: string;
    headline?: Localized | any;
    practicalities?: Localized;
    whatsIncluded?: Localized;
    bookings?: TShallowBooking[] | null;
    slots?: unknown;
}

export type TEvent<T extends string | Record<string, string> = string> = {
    recurring: {
        selectedOptionKey: "yes" | "no";
        rrulestr: string;
        exDateTimes: string[];
    };
    org: string;
    id: string;
    externalEventId?: string;
    serviceId: string;
    parentId?: string;
    status: TStatus;
    slots: {
        booked?: number;
        total: number;
    };
    isSameDay?: boolean;
    headline?: Localized;
    meetingLink?: string;
    seatCount: OptionValue;
    bookings: TShallowBooking[];
    waitingList: number;
    assignees: T[];
    languages: Array<keyof Localized>;
    locations: {
        selectedOptionKey?: string;
        value?: string;
        custom?: string;
    };
    channels: string[];
    isOneDay: boolean;
    startDateTime: string;
    endDateTime?: string;
    intervals?: TFromValue;
};

type TFromValue = {
    selectedOptionKey?: string;
    value: string | number | Record<string, any> | any[];
};

type TLocation = {
    city: string;
    zipCode: string;
    address: string;
};

type TShallowBooking = {
    bookingId: string;
    items: { [variant: string]: number };
    slots: number;
};

const virtualPrefix = process.env.REACT_APP_VIRTUAL_EVENT_PREFIX ?? "VVID+";

export function isVirtualId(id: unknown) {
    return typeof id === "string" && id.startsWith(virtualPrefix);
}

export const getLocation = (
    addressesObj: {
        selectedOptionKey: "custom" | "companyAddress" | "multiple";
        custom?: string;
        multiple?: string[];
    },
    companyAddress?: TLocation
) => {
    const { address, city, zipCode } = companyAddress ?? {};
    const { selectedOptionKey } = addressesObj ?? {};
    if (selectedOptionKey === "companyAddress") {
        return companyAddress ? `${address}, ${zipCode} ${city}` : "";
    }
    if (selectedOptionKey === "multiple") {
        return addressesObj?.[selectedOptionKey]?.join(", ") ?? "";
    }
    return addressesObj?.[selectedOptionKey] ?? "";
};

export const getGuideById = (guideIds: string[], guides: TUser[]) => {
    return guides?.find((el) => el.id === guideIds?.[0]) ?? {};
};

export const getDescriptionFromHtml = (htmlString?: string) => {
    if (!htmlString) return "";
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const text = div.textContent;
    div.remove();
    return text ?? "";
};

export type TEventColor = {
    color: string;
    borderLeftColor: string;
    backgroundColor: string;
    border?: object;
};

const calendarEventColors: TEventColor[] = [
    {
        color: "#0369A1",
        borderLeftColor: "#0EA5E9",
        backgroundColor: "#E7F6FD",
    },
    {
        color: "#BE123C",
        borderLeftColor: "#F43F5E",
        backgroundColor: "#FFE4E6",
    },
    {
        color: "#6D28D9",
        borderLeftColor: "#8B5CF6",
        backgroundColor: "#F4EFFF",
    },
    {
        color: "#B45309",
        borderLeftColor: "#F59E0B",
        backgroundColor: "#FEF6E7",
    },
    {
        color: "#047857",
        borderLeftColor: "#10B981",
        backgroundColor: "#E8F9F3",
    },
];
export type TEventWithTitle = TEvent & {
    title: string;
    location: string;
    description: Localized;
    type: "event" | "session";
    serviceId: string;
    guide: {
        name: string;
        email: string;
    };
    colors: TEventColor;
};

export const getColor = (index: number): TEventColor => {
    return calendarEventColors?.[index] ?? getColor(index - calendarEventColors.length);
};

type TSeatTypes = "single" | "couple" | "group" | "unique";

const getSeatCount = (seatCount: number, type: TSeatTypes, maxParticipants: number) => {
    switch (type) {
        case "couple":
            return seatCount * 2;
        case "group":
        case "unique":
            return seatCount * (maxParticipants ?? 1);
        default:
            return seatCount;
    }
};

const transformEvents = (events: TEvent[], users?: TUser[]) => {
    return events.filter(Boolean).map((ev) => {
        return {
            ...ev,
            ...(users &&
                ev?.assignees && {
                    assignees: mapAssignableGuidesToUsers(ev.assignees, users),
                }),
            slots: {
                total: Number(ev.seatCount?.value ?? 0),
                booked: ev.bookings?.reduce((total, { slots }) => total + slots, 0) ?? 0,
            },
        };
    });
};

const getEventLocation = (
    event: TEvent<any> | undefined,
    service: IService,
    companyAddress: string
) => {
    if (event == undefined) {
        return "";
    }
    const k = event.locations.selectedOptionKey ?? "";
    if (k === "serviceCustom") {
        return service?.locations.custom;
    }
    if (k.startsWith("serviceMultiple-")) {
        const [, i] = k.split("-") as [unknown, string];
        return service?.locations.multiple?.[Number(i)]?.value;
    }
    if (k === "custom") {
        return event.locations?.value ?? event.locations?.custom;
    }
    return companyAddress;
};
export const stripGuides = (items: Array<string | { id: string }> | undefined) =>
    items?.map((el) => (typeof el === "string" ? el : el.id)) ?? [];

export const useEvent = (id?: string, serviceId?: string) => {
    const queryClient = useQueryClient();

    const EventQueryKey = ["events", { id }];
    const EventsQueryKey = ["events"];
    const EventsForServiceQueryKey = ["events", { type: "service", serviceId }];
    const EventsWithBookingsQueryKey = ["events", { type: "bookings" }];

    const { services, getService } = useService();
    const { company, companyAddress, UserInfoQueryKey, myRole, userinfo } = useProfile();
    const { users } = useUsers();
    const { bookings } = useBookings(id);
    const { updateStep } = useOnBoarding();

    const events = useQuery<TEvent<TShallowUser>[]>(
        EventsQueryKey,
        async () => {
            await queryClient.cancelQueries(EventsQueryKey);
            const events = await api.getEvents();
            const transformed = transformEvents(events, users.data);
            transformed.forEach((el) => queryClient.setQueryData(["events", { id: el.id }], el));
            return transformed;
        },
        {
            enabled: Boolean(users.data),
        }
    );

    const eventsForService = useQuery<TEvent<TShallowUser>[]>(
        EventsForServiceQueryKey,
        async () => {
            await queryClient.cancelQueries(EventsForServiceQueryKey);
            const events = await api.getEventsInService(serviceId as string);
            return transformEvents(events, users.data).map((ev) => ({
                ...ev,
                formattedLocation: getEventLocation(
                    ev,
                    getService(ev.serviceId) as IService,
                    companyAddress
                ),
            }));
        },
        {
            enabled: Boolean(services.data && serviceId && bookings?.data && users?.data),
        }
    );

    const updateEventWithBooking = useMutation(
        ({ ...data }: { start: string; end: string }) => Promise.resolve(data),
        {
            onMutate: async (_data: { id: string; start: string; end: string }) => {
                const { start, end, id } = _data ?? {};

                await queryClient.cancelQueries(EventsWithBookingsQueryKey);

                const previous = queryClient.getQueryData<TEventWithTitle[]>(
                    EventsWithBookingsQueryKey
                );

                queryClient.setQueryData<TEventWithTitle[]>(EventsWithBookingsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id
                                ? {
                                      ...el,
                                      startDateTime: start,
                                      endDateTime: end,
                                  }
                                : el;
                        }) ?? []
                    );
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TEventWithTitle[]>(
                        EventsWithBookingsQueryKey,
                        context.previous
                    );
                }
            },
            onSettled: async (data: any) => {
                //queryClient.invalidateQueries(['events', data!.experienceId])
            },
        }
    );

    const event = useQuery<TEvent<{ id: string; name: string }>>(
        EventQueryKey,
        async () => {
            await queryClient.cancelQueries(EventQueryKey);
            const fetched = await api.getEvent(id as string);
            const [transformed] = transformEvents([fetched], users.data);
            return transformed;
        },
        {
            enabled: Boolean(id && users.data),
        }
    );

    const updateEvent = useMutation(
        ({ org, assignees, slots, ...data }: IEvent) => {
            const userinfo = queryClient.getQueryData<{ org?: string }>(UserInfoQueryKey);
            return api.updateEvent(
                {
                    ...data,
                    companyId: org ?? company.data.id ?? userinfo?.org,
                    assignees: stripGuides(assignees),
                },
                data.id ?? id
            );
        },
        {
            onMutate: async (data) => {
                const queryKey = ["events", { id: data.id ?? id }];
                const forServiceQueryKey = [
                    "events",
                    { type: "service", serviceId: data.serviceId ?? serviceId },
                ];

                await queryClient.cancelQueries(queryKey);
                await queryClient.cancelQueries(forServiceQueryKey);

                const previousEvent = queryClient.getQueryData<IEvent>(queryKey);

                const previousEventsForService =
                    queryClient.getQueryData<IEvent[]>(forServiceQueryKey);

                queryClient.setQueryData<IEvent>(queryKey, (prev) => {
                    return { ...data };
                });

                queryClient.setQueryData<IEvent[]>(forServiceQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === data.id ?? id
                                ? {
                                      ...data,
                                      assignees: mapAssignableGuidesToUsers(
                                          stripGuides(data.assignees),
                                          users.data
                                      ),
                                  }
                                : el;
                        }) ?? []
                    );
                });

                return { previousEvent, previousEventsForService, serviceId: data.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previousEvent) {
                    queryClient.setQueryData<IEvent>(EventQueryKey, context.previousEvent);
                }
                if (context?.previousEventsForService) {
                    queryClient.setQueryData<IEvent[]>(
                        EventsForServiceQueryKey,
                        context.previousEventsForService
                    );
                }
            },
            onSuccess: async (data, variables) => {
                updateStep({ ...variables, id }, eventSchema, "event", "create");
            },
            onSettled: async (data, err, variables, context) => {
                await queryClient.invalidateQueries(EventsQueryKey);
                await Promise.all([
                    queryClient.invalidateQueries(["events", { id: variables.id }]),
                    queryClient.invalidateQueries([
                        "events",
                        { type: "service", serviceId: context?.serviceId ?? serviceId },
                    ]),
                    queryClient.invalidateQueries([
                        "upcoming",
                        { serviceId: context?.serviceId ?? serviceId },
                    ]),
                ]);
            },
        }
    );

    const deleteEvent = useMutation(
        async (id: string) => {
            await api.deleteEvent(id);
        },
        {
            onMutate: async (id) => {
                await queryClient.cancelQueries(EventsQueryKey);

                const events = queryClient.getQueryData<TEvent[]>(EventsQueryKey);

                const serviceId = events?.find((el) => el.id === id)?.serviceId;

                queryClient.setQueryData<IEvent[]>(
                    ["events", { type: "service", serviceId }],
                    (prev) => {
                        return prev?.filter((el) => el.id !== id) ?? [];
                    }
                );

                return { serviceId };
            },
            onSettled: async (data, err, id, context: any) => {
                await queryClient.invalidateQueries(EventsQueryKey);
                await queryClient.invalidateQueries(["events", { id }]);
                await queryClient.invalidateQueries([
                    "events",
                    { type: "service", serviceId: context?.serviceId },
                ]);
                await queryClient.invalidateQueries([
                    "upcoming",
                    { serviceId: context?.serviceId },
                ]);
            },
        }
    );

    const getUpcomingEventsCount = useCallback(
        (expId?: string) => {
            return events.data?.filter((el) => {
                return (
                    new Date(el.startDateTime) > new Date() && el.serviceId === (expId ?? serviceId)
                );
            })?.length;
        },
        [events.data, id]
    );

    return {
        event,
        eventsForService,
        updateEvent,
        updateEventWithBooking,
        deleteEvent,
        EventsQueryKey,
        EventsWithBookingsQueryKey,
        EventsForServiceQueryKey,
        getUpcomingEventsCount,
        events,
    };
};
