import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CloseButton } from "../Components/CloseButton/CloseButton";
import { useTranslate } from "../Hooks/useTranslate";

interface IConfirmDialogProps {
    onClose?: () => void;
    title: string;
    headline: string;
    description: string;
    confirmLabel?: string;
    allowCancel?: boolean;
}

export const ConfirmDialog = NiceModal.create<IConfirmDialogProps>(
    ({ onClose, headline, title, allowCancel = true, description, confirmLabel }) => {
        const { t } = useTranslate("utils.generic");

        const modal = useModal();

        const handleClose = () => {
            modal.reject();
            modal.remove();
            onClose?.();
        };

        const handleOk = () => {
            modal.resolve(true);
            modal.remove();
        };

        return (
            <Dialog open={modal.visible} onClose={handleClose}>
                <CloseButton
                    onClick={handleClose}
                    sx={{ position: "absolute", top: 24, right: 24 }}
                />
                <Box p={8} textAlign={"center"}>
                    <Typography display={"inline-flex"} alignItems={"center"} mb={2}>
                        {headline}
                    </Typography>
                    <Typography variant={"h4"}>{title}</Typography>
                    <Stack direction={"row"} spacing={2} justifyContent={"center"} mt={4}>
                        {allowCancel && (
                            <Button
                                onClick={handleClose}
                                size={"large"}
                                sx={{ whiteSpace: "nowrap" }}
                                variant={"outlined"}
                                color={"secondary"}
                            >
                                {t("cancel")}
                            </Button>
                        )}
                        <Button
                            onClick={handleOk}
                            size={"large"}
                            sx={{ whiteSpace: "nowrap" }}
                            variant={"contained"}
                        >
                            {confirmLabel ?? t("ok")}
                        </Button>
                    </Stack>
                </Box>
            </Dialog>
        );
    }
);
