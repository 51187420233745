import {
    Box,
    Button,
    Checkbox,
    Chip,
    ChipProps,
    Collapse,
    Dialog,
    Divider,
    FormControlLabel,
    IconButton,
    Link,
    MenuItem,
    PopoverProps,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { ArrowDropDownRounded, DeleteOutlined, LinkOutlined } from "@mui/icons-material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { ServiceSelectItemContent } from "../../Components/ServiceSelect";
import { isSameDay, toISODate, toISOTime } from "@hiddengemgroup/utils-date";
import { useService } from "../../Hooks/useService";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalizer } from "../../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { DatePicker, TimeField, TimeFieldProps } from "@mui/x-date-pickers";
import { FormProvider, useController, useForm, useWatch } from "react-hook-form";
import { Details } from "./Details";
import { Customer } from "../../Utils/types";
import { calculateExpiresIn, createId, uuid } from "../../Utils/helpers";
import { toast } from "react-toastify";
import { useBookings } from "../../Hooks/useBookings";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";
import { useCustomers } from "../../Hooks/useCustomers";
import { useTranslate } from "../../Hooks/useTranslate";
import { useSession } from "../../Hooks/useSession";
import { updatePayment } from "../../Api";
import { useProfile } from "../../Hooks/useProfile";
import { defaultTaxOption } from "../../Utils/taxOptions";
import { CONSUMER_URL } from "../../Utils/constants";
import { IconRow } from "../CalendarPreview";
import { CloseButton } from "../../Components/CloseButton/CloseButton";
import { useOffers } from "../../Hooks/useOffers";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../Hooks/useDebounce";
import { DoubleBookingWarning } from "../../Components/DoubleBookingWarning";
import { addMinutes } from "date-fns";

type CalendarCreateBookingProps = {
    anchorEl: PopoverProps["anchorEl"];
    start: Date;
    end: Date;
    bookingId?: string;
    serviceId?: string;
};

export type ManualBooking = {
    customer?: Customer;
    title?: string;
    upsertCustomer: boolean;
    serviceId: string;
    location: string;
    channels: string[];
    assignees: string[];
    channel: "admin";
    source: "manual";
    startDateTime: string;
    endDateTime: string;
    internalNote?: string;
    variantId?: string;
    shouldCreatePaymentLink: boolean;
    shouldSendNotification: boolean;
    enablePaymentReminders: boolean;
    messageInNotification?: string;
    offerId?: string;
};

export const CreateNewBookingDialog = NiceModal.create<CalendarCreateBookingProps>(
    ({ start, end, bookingId: defaultBookingId, serviceId }) => {
        const modal = useModal();
        const { defaultCurrency } = useProfile();

        const { t } = useTranslate("dialogs.createBooking");

        const [selected, setSelected] = useState<string | undefined>(serviceId);
        const [selectedVariantId, setSelectedVariantId] = useState<string | undefined>();

        const [loading, setLoading] = useState(false);

        const [willOverlapBooking, setWillOverlapBooking] = useState<{
            type: "violatePadding" | "overlapping";
            bookingId: string;
        } | null>();

        const [startDate, setStartDate] = useState(start);
        const [endDate, setEndDate] = useState(end);

        const { willOverlap, createSessionBooking, deleteSessionBooking, booking } = useBookings(
            undefined,
            defaultBookingId
        );

        const debounce = useDebounce(500);

        const [bookingId] = useState(defaultBookingId ?? createId());

        const [startDateOpen, setStartOpen] = useState(false);
        const [endDateOpen, setEndOpen] = useState(false);

        const [paymentId, setPaymentId] = useState(uuid);

        const methods = useForm<ManualBooking>();

        const customer = useWatch({ name: "customer", control: methods.control });

        const { field: shouldCreatePaymentLink } = useController({
            name: "shouldCreatePaymentLink",
            control: methods.control,
            defaultValue: false,
        });
        const { field: shouldSendNotification } = useController({
            name: "shouldSendNotification",
            control: methods.control,
            defaultValue: false,
        });
        const { field: enablePaymentReminders } = useController({
            name: "enablePaymentReminders",
            control: methods.control,
            defaultValue: true,
        });
        const { field: offerId } = useController({
            name: "offerId",
            control: methods.control,
            defaultValue: undefined,
        });

        const { offersByCustomer } = useOffers(
            undefined,
            booking.data?.paymentId ? undefined : customer?.id
        );

        const eligibleOffer = useMemo(() => {
            if (offersByCustomer.data && selectedVariantId) {
                return offersByCustomer.data.find(
                    (el) =>
                        el.firstItem.variantId === selectedVariantId &&
                        el.availableQuantity > 0 &&
                        el.transaction
                );
            }
            return undefined;
        }, [offersByCustomer, selectedVariantId]);

        const handleClose = () => {
            modal.remove();
        };

        const localize = useLocalizer();

        const {
            services: { data: services },
        } = useService();

        const {
            sessionForService: { data: session },
        } = useSession(undefined, selected);

        const { updateCustomer } = useCustomers();

        const sessions = useMemo(() => {
            return (
                services?.filter(
                    (el) => el.type === "session" && el.variants && el.variants.length > 0
                ) ?? []
            );
        }, [services]);

        const selectedService = useMemo(() => {
            if (selected) {
                const service = sessions?.find((el) => el.id === selected);
                if (selectedVariantId === undefined) {
                    setSelectedVariantId(service?.variants?.[0]?.id);
                }
                return service;
            }
        }, [sessions, selected]);

        const getHeadline = useCallback(
            (id?: string) => {
                return localize(sessions?.find((el) => id && el.id === id)?.headline);
            },
            [sessions]
        );

        const getVariantName = useCallback(
            (id?: string) => {
                return localize(selectedService?.variants?.find((el) => el.id === id)?.name);
            },
            [selectedService]
        );

        const getVariantDuration = useCallback(
            (id?: string) => {
                return (
                    selectedService?.variants?.find((el) => el.id === id)?.slotDuration ||
                    session?.duration
                );
            },
            [selectedService, session]
        );

        useEffect(() => {
            setSelectedVariantId(selectedService?.variants?.[0]?.id);
        }, [selectedService]);

        useEffect(() => {
            if (booking.data) {
                if (booking.data.contactChannel) {
                    methods.setValue("channels", [booking.data.contactChannel]);
                }
                if (booking.data.metadata?.shouldNotify) {
                    methods.setValue("shouldSendNotification", true);
                }
                if (booking.data.metadata?.messageInNotification) {
                    methods.setValue(
                        "messageInNotification",
                        booking.data.metadata.messageInNotification
                    );
                }
                if (booking.data.variantId?.startsWith("SSID")) {
                    const [anItem] = Object.keys(booking.data.items ?? {});
                    if (anItem?.startsWith("variant/")) {
                        const vid = anItem.substring(8);
                        setSelectedVariantId(vid);
                    }
                }
                if (booking.data.variantId?.startsWith("variant/")) {
                    const vid = booking.data.variantId.substring(8);
                    setSelectedVariantId(vid);
                }
                if (booking.data.offerId) {
                    methods.setValue("offerId", booking.data.offerId);
                }
                if (booking.data.title) {
                    methods.setValue("title", booking.data.title);
                }
                if (booking.data.customer) {
                    methods.setValue("customer", booking.data.customer);
                }
                if (booking.data.assignees) {
                    methods.setValue("assignees", booking.data.assignees);
                }
                if (booking.data.location) {
                    methods.setValue("location", booking.data.location);
                }
                if (booking.data.internalNote) {
                    methods.setValue("internalNote", booking.data.internalNote);
                }
                if (booking.data.paymentId) {
                    methods.setValue("shouldCreatePaymentLink", true);
                    setPaymentId(booking.data.paymentId);
                }
            }
        }, [booking.data]);

        useEffect(() => {
            if (selectedVariantId && isSameDay(startDate, endDate)) {
                const duration = getVariantDuration(selectedVariantId);
                if (typeof duration === "string" || typeof duration === "number") {
                    setEndDate(() => {
                        const d = new Date(startDate);
                        d.setMinutes(d.getMinutes() + Number(duration));
                        return d;
                    });
                }
            }
        }, [selectedVariantId]);

        useEffect(() => {
            if (!isSameDay(start, end) && isSameTime(start, end) && !defaultBookingId) {
                setEndDate((p) => {
                    p.setMinutes(p.getMinutes() - 1);
                    return p;
                });
            }
        }, []);

        useEffect(() => {
            if (startDate && endDate) {
                debounce(() => {
                    const match = willOverlap(
                        startDate,
                        endDate,
                        bookingId,
                        session?.slotPaddingMins
                    );
                    if (match) {
                        setWillOverlapBooking(match);
                    } else {
                        setWillOverlapBooking(null);
                    }
                });
            }
        }, [startDate, endDate, session]);

        const handleChangeTime =
            (type: "start" | "end"): TimeFieldProps<Date>["onChange"] =>
            (date) => {
                if (date instanceof Date) {
                    switch (type) {
                        case "start":
                            setStartDate(date);
                            if (date > endDate) {
                                setEndDate(
                                    addMinutes(date, getVariantDuration(selectedVariantId) ?? 60)
                                );
                            }
                            break;
                        case "end":
                            setEndDate(date);
                            break;
                    }
                }
            };

        const handleChangeDate = (date: Date | null, isEndDate = false) => {
            if (isEndDate) {
                setEndOpen(false);
            } else {
                setStartOpen(false);
            }
            if (date instanceof Date) {
                if (isEndDate) {
                    setEndDate(date);
                } else {
                    setStartDate(date);
                    if (date > endDate) {
                        setEndDate(addMinutes(date, getVariantDuration(selectedVariantId) ?? 60));
                    }
                }
            }
        };

        const getTimes = () => {
            const startTime = [toISODate(startDate), toISOTime(startDate)].join("T");
            const endTime = [toISODate(endDate), toISOTime(endDate)].join("T");
            return [startTime, endTime];
        };

        const ensureCustomer = async (
            customer?: ManualBooking["customer"],
            canCreate?: boolean
        ) => {
            if (customer?.name && canCreate) {
                const id = customer?.id ?? createId();
                const res = await updateCustomer.mutateAsync({
                    ...customer,
                    showAsyncToast: false,
                    id,
                });
                return { id: res.id ?? id };
            }
            return Promise.resolve();
        };

        const handleSubmit = async () => {
            setLoading(true);

            const {
                channels,
                assignees,
                location,
                shouldCreatePaymentLink,
                shouldSendNotification,
                offerId,
                messageInNotification,
                enablePaymentReminders,
                customer,
                internalNote,
                upsertCustomer,
                title,
            } = methods.getValues();
            const [contactChannel] = channels ?? [];
            const [startDateTime, endDateTime] = getTimes();
            const variant = selectedService?.variants?.find((el) =>
                el.id.endsWith(selectedVariantId ?? "noop")
            );

            try {
                await toast.promise(
                    Promise.all([
                        shouldCreatePaymentLink &&
                            variant &&
                            session &&
                            updatePayment(paymentId, {
                                subject: "booking",
                                subjectId: bookingId,
                                expiresIn: calculateExpiresIn(
                                    new Date(),
                                    startDateTime,
                                    60 * 24 * 14
                                ),
                                items: {
                                    [`variant/${variant.id}`]: {
                                        quantity: 1,
                                        name: localize(variant.name),
                                        price: {
                                            amount: variant.price,
                                            taxId: variant.vatId ?? defaultTaxOption,
                                            currency: defaultCurrency,
                                        },
                                        sessionId: session.id,
                                        serviceId: selected,
                                    },
                                },
                            }),
                        new Promise<{ id: string; startDateTime: string; endDateTime: string }>(
                            async (res) => {
                                const payload = {
                                    id: bookingId,
                                    ...(selectedService?.id
                                        ? {
                                              contactChannel,
                                              ...(offerId && eligibleOffer && { offerId }),
                                              ...(variant && {
                                                  variantId: `variant/${variant.id}`,
                                              }),
                                              ...(session?.id && { sessionId: session.id }),
                                              ...(shouldCreatePaymentLink && {
                                                  paymentId,
                                                  metadata: {
                                                      shouldNotify: true,
                                                      enablePaymentReminders,
                                                      ...(shouldSendNotification &&
                                                          messageInNotification && {
                                                              messageInNotification,
                                                          }),
                                                  },
                                              }),
                                              ...(internalNote && { internalNote }),
                                              serviceId: selectedService?.id,
                                              assignees,
                                              location,
                                              status: "unpaid",
                                              source: "manual",
                                              ...(customer && {
                                                  customer: {
                                                      ...customer,
                                                      ...((customer?.id === undefined ||
                                                          customer?.id !==
                                                              booking.data?.customer?.id) &&
                                                          (await ensureCustomer(
                                                              customer,
                                                              upsertCustomer
                                                          ))),
                                                  },
                                              }),
                                          }
                                        : { ...(title && { title }) }),
                                    startDateTime,
                                    endDateTime,
                                };
                                res(payload);
                            }
                        ).then(createSessionBooking.mutateAsync),
                    ]),
                    {
                        success: `Tillykke, din aftale er ${
                            defaultBookingId ? "opdateret" : "oprettet"
                        }`,
                        error: "Hov, der skete en fejl",
                        pending: `${defaultBookingId ? "Opdaterer" : "Opretter"} aftale...`,
                    }
                );
                handleClose();
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        const handleDelete = () => {
            deleteSessionBooking.mutate(bookingId);
            handleClose();
        };

        const navigate = useNavigate();
        const handleGoToOffer: ChipProps["onClick"] = (evt) => {
            evt.stopPropagation();
            if (eligibleOffer?.id) {
                navigate(`/offers/${eligibleOffer.id}`);
            }
        };

        const handleGoToBooking = (id: unknown) => {
            if (typeof id === "string") {
                navigate(`/bookings/${id}`);
            }
        };

        return (
            <FormProvider {...methods}>
                <Dialog open={modal.visible}>
                    <Stack
                        direction={"row"}
                        p={2}
                        width={"100%"}
                        position={"absolute"}
                        justifyContent={"space-between"}
                        spacing={2}
                    >
                        <div />
                        <CloseButton size={"small"} onClick={handleClose} />
                    </Stack>
                    <Box p={2} pr={8} display={"flex"}>
                        <Box
                            component={"img"}
                            width={80}
                            height={80}
                            sx={{ objectFit: "cover" }}
                            src={selectedService?.pictures?.[0]?.url ?? "/placeholder.svg"}
                            borderRadius={2}
                        />
                        <Stack ml={2}>
                            <Select
                                displayEmpty
                                IconComponent={ArrowDropDownRounded}
                                value={selected}
                                onChange={(event) => {
                                    setSelected(event.target.value);
                                }}
                                sx={{
                                    display: "inline-flex",
                                    mr: "auto",
                                    "& .MuiSelect-select": {
                                        padding: "0px",
                                        paddingRight: "32px !important",
                                    },
                                    "& .MuiSelect-select:hover": {
                                        opacity: 0.85,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        display: "none",
                                    },
                                    "&.MuiSelect-root:before": {
                                        display: "none",
                                    },
                                }}
                                renderValue={(id: string | undefined | "private") => (
                                    <Typography
                                        variant={"h4"}
                                        whiteSpace={"normal"}
                                        color={"#007AFF"}
                                    >
                                        {getHeadline(id) || "Vælg ydelse"}
                                    </Typography>
                                )}
                            >
                                <MenuItem value={undefined}>Vælg ydelse</MenuItem>
                                {sessions.map((el) => (
                                    <MenuItem value={el.id} key={el.id}>
                                        <ServiceSelectItemContent
                                            image={el?.pictures?.[0]?.url}
                                            name={localize(el.headline)}
                                            showStatus={true}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                            {selectedService && (
                                <Select
                                    displayEmpty
                                    IconComponent={ArrowDropDownRounded}
                                    value={selectedVariantId}
                                    defaultValue={selectedService?.variants?.[0]?.id}
                                    onChange={(event) => {
                                        setSelectedVariantId(event.target.value);
                                    }}
                                    sx={{
                                        display: "inline-flex",
                                        mr: "auto",
                                        "& .MuiSelect-select": {
                                            padding: "0px",
                                            paddingRight: "32px !important",
                                        },
                                        "& .MuiSelect-select:hover": {
                                            opacity: 0.85,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            display: "none",
                                        },
                                        "&.MuiSelect-root:before": {
                                            display: "none",
                                        },
                                    }}
                                    renderValue={(id: string | undefined) => (
                                        <Typography variant={"h5"}>
                                            {getVariantName(
                                                selectedVariantId ??
                                                    selectedService?.variants?.[0]?.id
                                            ) || "Vælg billettype"}
                                        </Typography>
                                    )}
                                >
                                    <MenuItem value={undefined}>Vælg billettype</MenuItem>
                                    {selectedService?.variants?.map((el) => (
                                        <MenuItem value={el.id} key={el.id}>
                                            {localize(el.name)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}

                            <Stack
                                direction={"column"}
                                spacing={2}
                                mt={2}
                                alignItems={"center"}
                                minWidth={320}
                            >
                                <DateTimeControl
                                    date={startDate}
                                    open={startDateOpen}
                                    label={"Startdato"}
                                    onChangeDate={(date) => handleChangeDate(date, false)}
                                    onChangeTime={handleChangeTime("start")}
                                    toggleOpen={() => setStartOpen((p) => !p)}
                                />
                                <DateTimeControl
                                    date={endDate}
                                    minDate={startDate}
                                    open={endDateOpen}
                                    label={"Slutdato"}
                                    onChangeDate={(date) => handleChangeDate(date, true)}
                                    onChangeTime={handleChangeTime("end")}
                                    toggleOpen={() => setEndOpen((p) => !p)}
                                />
                            </Stack>
                            <DoubleBookingWarning
                                mt={2}
                                {...willOverlapBooking}
                                onClick={() => handleGoToBooking(willOverlapBooking)}
                            />
                            {selectedService === undefined && (
                                <TextField
                                    sx={{ mt: 3 }}
                                    fullWidth
                                    size={"small"}
                                    label={"Overskrift (valgfrit)"}
                                    {...methods.register("title")}
                                />
                            )}
                        </Stack>
                    </Box>
                    {Boolean(selectedService) && <Divider />}
                    <Collapse sx={{ overflowY: "auto", pb: 3 }} in={Boolean(selectedService)}>
                        <Details serviceId={selected} />
                        {customer?.email && (
                            <Stack px={3} pt={3}>
                                {eligibleOffer && (
                                    <FormControlLabel
                                        disabled={Boolean(booking.data?.offerId)}
                                        onChange={(evt, checked) =>
                                            offerId.onChange(checked ? eligibleOffer.id : undefined)
                                        }
                                        checked={Boolean(offerId.value)}
                                        inputRef={offerId.ref}
                                        control={<Checkbox />}
                                        label={
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Typography mr={1.5}>
                                                    Brug 1 klip fra tilbud
                                                </Typography>
                                                {!booking.data?.offerId && (
                                                    <Chip
                                                        onClick={handleGoToOffer}
                                                        clickable
                                                        color={
                                                            eligibleOffer.availableQuantity === 0
                                                                ? "default"
                                                                : eligibleOffer.transaction
                                                                ? "success"
                                                                : "warning"
                                                        }
                                                        label={
                                                            eligibleOffer.transaction
                                                                ? `${
                                                                      eligibleOffer.availableQuantity ??
                                                                      eligibleOffer.totalQuantity
                                                                  }/${
                                                                      eligibleOffer.totalQuantity
                                                                  } sessioner tilbage`
                                                                : "Afventer betaling"
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        }
                                    />
                                )}
                                {booking.data?.paymentId ? (
                                    <IconRow mb={2} Icon={LinkOutlined}>
                                        <Link
                                            target={"_blank"}
                                            href={
                                                CONSUMER_URL + `/payment/${booking.data?.paymentId}`
                                            }
                                        >
                                            Gå til betaling
                                        </Link>
                                    </IconRow>
                                ) : (
                                    <FormControlLabel
                                        disabled={Boolean(offerId.value && eligibleOffer)}
                                        onChange={shouldCreatePaymentLink.onChange}
                                        checked={shouldCreatePaymentLink.value}
                                        inputRef={shouldCreatePaymentLink.ref}
                                        control={<Checkbox />}
                                        label={t("actions.generatePaymentLink")}
                                    />
                                )}
                                {shouldCreatePaymentLink.value && (
                                    <FormControlLabel
                                        disabled={Boolean(
                                            booking.data?.metadata?.enablePaymentReminders
                                        )}
                                        onChange={enablePaymentReminders.onChange}
                                        checked={enablePaymentReminders.value}
                                        inputRef={enablePaymentReminders.ref}
                                        control={<Checkbox />}
                                        label={"Aktiver automatiske betalingspåmindelser"}
                                    />
                                )}
                                <FormControlLabel
                                    disabled={Boolean(booking.data?.metadata?.shouldNotify)}
                                    onChange={shouldSendNotification.onChange}
                                    checked={shouldSendNotification.value}
                                    inputRef={shouldSendNotification.ref}
                                    control={<Checkbox />}
                                    label={t("actions.sendNotification")}
                                />
                                {shouldSendNotification.value && (
                                    <TextField
                                        disabled={Boolean(booking.data?.metadata?.shouldNotify)}
                                        sx={{ mt: 1.5 }}
                                        fullWidth
                                        minRows={3}
                                        multiline
                                        label={"Personlig besked til klient (vises i mailen)"}
                                        {...methods.register("messageInNotification")}
                                    />
                                )}
                            </Stack>
                        )}
                    </Collapse>
                    <Divider />
                    <Stack
                        direction={"row"}
                        justifyContent={defaultBookingId ? "space-between" : "flex-end"}
                        alignItems={"center"}
                        p={2}
                        spacing={1}
                    >
                        {defaultBookingId && (
                            <IconButton onClick={handleDelete}>
                                <DeleteOutlined />
                            </IconButton>
                        )}
                        <Box display={"flex"} alignItems={"center"}>
                            {!selectedService && !defaultBookingId && (
                                <Button size={"large"} sx={{ pr: 2, pl: 2 }} onClick={handleSubmit}>
                                    Bloker denne tid
                                </Button>
                            )}
                            <ProgressButton
                                label={"Gem"}
                                onClick={handleSubmit}
                                size={"large"}
                                variant={"contained"}
                            />
                        </Box>
                    </Stack>
                </Dialog>
            </FormProvider>
        );
    }
);

type DateTimeControlProps = {
    date: Date | null;
    open: boolean;
    label: string;
    minDate?: Date;
    onChangeDate: (date: Date | null) => void;
    onChangeTime: TimeFieldProps<Date>["onChange"];
    toggleOpen: () => void;
};
const DateTimeControl = ({
    date,
    label,
    open,
    onChangeDate,
    onChangeTime,
    toggleOpen,
    minDate,
}: DateTimeControlProps) => {
    return (
        <Stack alignItems={"end"} direction={"row"} spacing={0.5} width={"100%"}>
            <DatePicker
                format={"eeee, do LLLL"}
                value={date ?? null}
                label={label}
                open={open}
                minDate={minDate}
                sx={{ textTransform: "capitalize" }}
                onChange={onChangeDate}
                slotProps={{
                    openPickerButton: { sx: { display: "none" } },
                    textField: {
                        fullWidth: true,
                        variant: "standard",
                        onClick: toggleOpen,
                    },
                }}
            />
            <TimeField
                ampm={false}
                value={date ?? null}
                onChange={onChangeTime}
                format={"HH:mm"}
                slotProps={{
                    textField: {
                        variant: "standard",
                        sx: { maxWidth: 56 },
                    },
                }}
            />
        </Stack>
    );
};

const isSameTime = (first: Date, second: Date) => {
    const f = `${first.getHours()}:${first.getMinutes()}`;
    const s = `${second.getHours()}:${second.getMinutes()}`;
    return f === s;
};
