import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../Hooks/useTranslate";
import { useLocalizer } from "../Events/EventSyiSections/EventSyiSectionDetails";
import { CrudPage } from "../CrudPage";
import { Box, ButtonBaseProps, Grid, Stack } from "@mui/material";
import { ServiceCard } from "../../Components/ServiceCard/ServiceCard";
import { EmptyScreen } from "../../Components/EmptyScreen/EmptyScreen";
import { useFunnels } from "../../Hooks/useFunnels";
import { IPictureProps, Picture } from "../../Components/Picture/Picture";
import { CustomCard, ICustomCardProps, Row } from "../../Components/CustomCard/CustomCard";
import { renderDate } from "@hiddengemgroup/utils-date";
import { formatMoney } from "../../Components/VariantSelect/VariantSelect";
import React from "react";
import { FunnelMetrics } from "../../Components/FunnelMetrics";

export const FunnelsPage = () => {
    const navigate = useNavigate();

    const { t } = useTranslate("funnel");

    const localize = useLocalizer();

    const {
        funnels: { isLoading },
        enrichedFunnels,
    } = useFunnels();

    const handleClick = (id: string) => navigate(`/funnel/${id}`);

    const handleCreate = () => {
        navigate(`/funnel/create/details`, { replace: true });
    };

    return (
        <CrudPage
            title={t("title")}
            createButtonLabel={t("buttonLabel")}
            handleCreate={handleCreate}
        >
            <Grid container spacing={4} columns={{ xs: 1, lg: 2 }}>
                {isLoading ? (
                    <ServiceCard />
                ) : (
                    enrichedFunnels?.map((el) => {
                        return (
                            <FunnelCard
                                funnelId={el.id}
                                key={el.id}
                                coverImage={el.service?.image}
                                title={localize(el.service?.headline)}
                                name={el.name}
                                variantName={localize(el.service?.variantName)}
                                status={el?.public ?? false ? "active" : "inactive"}
                                createdDate={el.created}
                                discountAmount={formatMoney(t)({
                                    value: el.config.discountAmount,
                                    nativeCurrency: "dkk",
                                })}
                                onClick={() => handleClick(el.id)}
                            />
                        );
                    })
                )}
            </Grid>
            {enrichedFunnels?.length === 0 && (
                <EmptyScreen
                    direction={"row"}
                    maxWidth={720}
                    imageSrc={"/empty-screens/service.png"}
                    imageProps={{ width: 320, height: 320 }}
                    title={t("emptyState.title")}
                    description={t("emptyState.description")}
                    onClick={handleCreate}
                    buttonLabel={t("emptyState.action")}
                    pt={6}
                    pb={5}
                />
            )}
        </CrudPage>
    );
};

export const FunnelCard = ({
    coverImage,
    imageProps,
    title,
    status,
    fullWidth,
    discountAmount,
    createdDate,
    funnelId,
    name,
    variantName,
    children,
    enableConversion = false,
    ...props
}: FunnelCardProps) => {
    const { t } = useTranslate("utils.generic");

    return (
        <CustomCard
            image={
                <Picture
                    borderRadius={2}
                    height={156}
                    minWidth={156}
                    source={coverImage ?? ""}
                    {...imageProps}
                    aspectRatio={1}
                />
            }
            status={status}
            title={name}
            fullWidth={fullWidth}
            content={
                (
                    <Stack mt={1} spacing={1} justifyContent={"flex-end"}>
                        {enableConversion ? (
                            <Row
                                label={t("conversion")}
                                skeletonProps={{ width: 72 }}
                                value={<FunnelMetrics id={funnelId as string} type={"funnel"} />}
                            />
                        ) : (
                            <Row
                                label={t("created")}
                                skeletonProps={{ width: 48 }}
                                value={
                                    createdDate ? renderDate(createdDate, "MM.dd.yyyy") : undefined
                                }
                            />
                        )}
                        <Row label={"Ydelse"} skeletonProps={{ width: 48 }} value={title} />
                        <Row
                            label={"Billettype"}
                            skeletonProps={{ width: 48 }}
                            value={variantName}
                        />
                        <Row label={"Rabat"} skeletonProps={{ width: 48 }} value={discountAmount} />
                    </Stack>
                ) as any
            }
            {...props}
        >
            {children && (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                >
                    {children}
                </Box>
            )}
        </CustomCard>
    );
};

type FunnelCardProps = {
    createdDate: string;
    coverImage?: string;
    variantName?: string;
    discountAmount?: string;
    funnelId: string;
    enableConversion?: boolean;
    imageProps?: Partial<IPictureProps>;
} & Omit<ICustomCardProps, "image" | "content"> &
    Omit<ButtonBaseProps, "type">;
