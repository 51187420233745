import {
    FormControl,
    GridProps,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";
import { useController } from "react-hook-form";
import { DeleteForeverRounded } from "@mui/icons-material";
import React from "react";
import { pad } from "../../Utils/helpers";

export const IntervalController = ({
    formKey,
    onDelete,
    ...props
}: GridProps & { onDelete: () => void; formKey: string }) => {
    const { t } = useTranslate("utils.generic");

    const {
        field: { value, onChange },
    } = useController({ name: `${formKey}.fromTime` });
    const {
        field: { value: valueTo, onChange: onChangeTo },
    } = useController({ name: `${formKey}.toTime` });

    return (
        <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <FormControl sx={{ width: 320 }}>
                <InputLabel
                    sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }}
                    id="frequency-select-label"
                >
                    {t("fromTime")}
                </InputLabel>
                <Select
                    labelId="frequency-select-label"
                    id="frequency-select"
                    value={value}
                    MenuProps={{ sx: { maxHeight: 400 } }}
                    onChange={(evt) => onChange(evt.target.value as string)}
                >
                    {getTimeOptions().map((el) => (
                        <MenuItem value={el.key}>{el.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width: 320 }}>
                <InputLabel
                    sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }}
                    id="interval-select-label"
                >
                    {t("toTime")}
                </InputLabel>
                <Select
                    labelId="interval-select-label"
                    id="interval-select"
                    value={valueTo}
                    disabled={!value}
                    MenuProps={{ sx: { maxHeight: 400 } }}
                    onChange={(evt) => onChangeTo(evt.target.value as string)}
                >
                    {getTimeOptions(parseInt(value)).map((el) => (
                        <MenuItem value={el.key}>
                            {el.label} ({calcTimeDiff(parseInt(value), parseInt(el.key))})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconButton onClick={onDelete}>
                <DeleteForeverRounded />
            </IconButton>
        </Stack>
    );
};

export const calcTimeDiff = (fromMins: number, toMins: number) => {
    const diff = toMins - fromMins;
    const h = Math.floor(diff / 60);
    const m = diff % 60;
    return `${h > 0 ? `${h} t. ${m > 0 ? `${m} min` : ""}` : `${diff} min`}`;
};

export const getTimeOptions = (fromMinutes: number = 0, intervals: number[] = [0, 15, 30, 45]) => {
    let output: { key: string; label: string }[] = [];
    for (let i = 0; i < 24; i++) {
        for (let x = 0; x < 60; x++) {
            if (i * 60 + x < fromMinutes) continue;
            if (intervals.includes(x)) {
                output.push({
                    key: `${i * 60 + x}`,
                    label: `${pad(i)}:${pad(x)}`,
                });
            }
        }
    }
    return output;
};

export const getMins = (date: Date) => {
    const h = date.getHours();
    const m = date.getMinutes();
    return `${h * 60 + m}`;
};
