import { Typography, TypographyProps } from "@mui/material";
import { formatMoney } from "./VariantSelect/VariantSelect";
import React from "react";
import { useTranslate } from "../Hooks/useTranslate";

export const ReducedPrice = ({
    originalPrice,
    reducedPrice,
    currency,
    inline = false,
    ...props
}: TypographyProps & {
    originalPrice: number;
    reducedPrice: number;
    currency: string;
    inline?: boolean;
}) => {
    const { t } = useTranslate("utils");
    return (
        <Typography {...props}>
            <Typography component={"span"} fontSize={"inherit"} color={"inherit"}>
                {formatMoney(t)({
                    value: reducedPrice,
                    nativeCurrency: currency,
                })}
            </Typography>
            {originalPrice !== reducedPrice && (
                <Typography
                    component={"span"}
                    fontSize={"inherit"}
                    display={inline ? "inline-block" : "block"}
                    pl={inline ? 1 : 0}
                    color={"grey.500"}
                    sx={{ textDecoration: "line-through" }}
                >
                    {formatMoney(t)({
                        value: originalPrice,
                        nativeCurrency: currency,
                    })}
                </Typography>
            )}
        </Typography>
    );
};
