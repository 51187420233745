import { useMutation, useQuery, useQueryClient } from "react-query";
import isEmpty from "lodash.isempty";
import { TokenPayload, ClaimableResponse, getClaimableCompanies } from "../Api/Profiles";
import { object, string } from "yup";
import { localizedSchema } from "../Pages/SyiPage/config";
import { useOnBoarding } from "./useOnBoarding";
import { useCallback, useMemo } from "react";
import { CONSUMER_URL, EXPERIENCE_URL } from "../Utils/constants";
import Auth, {
    getMyProfile,
    getUserinfo,
    getCompanyProfile,
    updateCompanyProfile,
    updateMyProfile,
    firstLogin as apiFirstLogin,
    refreshToken,
} from "../Api";

export const meSchema = object().shape({
    name: string().required(),
    email: string().required(),
    phone: string().required(),
    description: localizedSchema.required(),
    pictures: object()
        .shape({
            profile: object().required(),
        })
        .required(),
});

export const companySchema = object().shape({
    name: string().required(),
    companyPhone: string().required(),
    companyEmail: string().required(),
    description: localizedSchema.required(),
    cvrNr: string().required(),
    location: object()
        .shape({
            address: string().required(),
            city: string().required(),
            zipCode: string().required(),
        })
        .required(),
    pictures: object()
        .shape({
            logo: object().required(),
            cover: object().required(),
        })
        .required(),
});

export interface ICompanyInfo {
    name?: string;
    website?: string;
    cvrNr?: number;
    admins?: any[];
    viewers?: any[];
    users?: string[];
    org?: string;
    location?: {
        address: string;
        city: string;
        country: string;
        zipCode: string;
    };
    onboardingCompleted?: boolean;
}

export interface IMyInfo extends Record<string, any> {
    name?: string;
    email?: string;
    phone?: string;
}

export const useProfile = () => {
    const { updateStep } = useOnBoarding();
    const queryClient = useQueryClient();
    //const { isAuthenticated, getRefreshToken, setAuth } = useAuth();

    const CompanyQueryKey = ["company"];
    const MeQueryKey = ["me"];
    const UserInfoQueryKey = ["userinfo"];
    const ClaimableQueryKey = ["claimable"];

    const userinfo = useQuery<TokenPayload>(UserInfoQueryKey, () => getUserinfo(), {
        enabled: Auth.isAuthorized,
    });

    const claimableCompanies = useQuery<ClaimableResponse | undefined>(
        ClaimableQueryKey,
        () => getClaimableCompanies(),
        {
            enabled: Auth.isAuthorized,
        }
    );

    const company = useQuery(CompanyQueryKey, () => getCompanyProfile(), {
        enabled: Boolean(Auth.isAuthorized && userinfo.data?.org),
        retry: 3,
    });

    const me = useQuery<{
        pictures: { profile: { url: string } };
        name: string;
        email: string;
        id: string;
    }>(MeQueryKey, () => getMyProfile(), {
        enabled: Auth.isAuthorized,
        retry: 3,
    });

    const companyProfileUrl = useCallback(() => {
        return `${CONSUMER_URL}/company/${company.data?.id}`;
    }, [company]);

    const myRole = useMemo(() => {
        if (company.data?.admins && me.data?.email) {
            return company.data.admins.some((a: { email: string }) => a.email === me.data.email)
                ? "admin"
                : "viewer";
        }
    }, [company.data, me.data]);

    const onboardingCompleted = useMemo(() => {
        if (company.data) {
            return company.data.onboardingCompleted === true;
        }
    }, [company]);

    const updateCompany = useMutation(
        (data: ICompanyInfo) => {
            return updateCompanyProfile(data, !Boolean(userinfo.data?.org));
        },
        {
            onMutate: async (data) => {
                await queryClient.cancelQueries(CompanyQueryKey);

                const previousCompany = queryClient.getQueryData<ICompanyInfo>(CompanyQueryKey);

                queryClient.setQueryData<ICompanyInfo>(CompanyQueryKey, (prev) => {
                    return { ...prev!, ...data };
                });

                return { previousCompany };
            },
            onError: async (err, variables, context: any) => {
                if (context?.previousCompany) {
                    queryClient.setQueryData<ICompanyInfo>(
                        CompanyQueryKey,
                        context.previousDetails
                    );
                } else {
                    await queryClient.resetQueries(CompanyQueryKey);
                }
            },
            onSuccess: async (data, variables, context) => {
                if (isEmpty(context?.previousCompany) && Auth.getRefreshToken()) {
                    const tokens = await refreshToken(Auth.getRefreshToken());
                    Auth.setAuth({ ...tokens });
                }
                setTimeout(() => {
                    updateStep(
                        { ...variables, id: undefined },
                        companySchema,
                        "profile",
                        "company"
                    );
                }, 500);
            },
            onSettled: async (data, err, vars, context) => {
                if (isEmpty(context?.previousCompany) && Auth.getRefreshToken()) {
                    const tokens = await refreshToken(Auth.getRefreshToken());
                    Auth.setAuth({ ...tokens });
                }
                setTimeout(() => {
                    queryClient.invalidateQueries(CompanyQueryKey);
                    queryClient.invalidateQueries(UserInfoQueryKey);
                    queryClient.invalidateQueries(["users"]);
                }, 500);
            },
        }
    );

    const updateMe = useMutation((data: IMyInfo) => updateMyProfile(data), {
        onMutate: async (data) => {
            await queryClient.cancelQueries(MeQueryKey);

            const previousDetails = queryClient.getQueryData<IMyInfo>(MeQueryKey);

            queryClient.setQueryData<IMyInfo>(MeQueryKey, (prev) => {
                return { ...prev!, ...data };
            });

            return { previousDetails };
        },
        onError: (err, variables, context: any) => {
            if (context?.previousDetails) {
                queryClient.setQueryData<IMyInfo>(MeQueryKey, context.previousDetails);
            }
        },
        onSuccess: async (data, variables) => {
            await updateStep({ ...variables, id: undefined }, meSchema, "profile", "me");
        },
        onSettled: () => {
            queryClient.invalidateQueries(MeQueryKey);
        },
    });

    const finishOnboarding = useMutation(
        () => {
            const existing = queryClient.getQueryData<ICompanyInfo>(CompanyQueryKey);
            return updateCompanyProfile({
                ...existing,
                onboardingCompleted: true,
            });
        },
        {
            onMutate: async () => {
                await queryClient.cancelQueries(CompanyQueryKey);

                const previous = queryClient.getQueryData<ICompanyInfo>(CompanyQueryKey);

                queryClient.setQueryData<ICompanyInfo>(CompanyQueryKey, (prev) => {
                    return {
                        ...prev!,
                        onboardingCompleted: true,
                    };
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                queryClient.setQueryData<ICompanyInfo>(CompanyQueryKey, context.previous);
            },
            onSettled: () => {
                queryClient.invalidateQueries(CompanyQueryKey);
            },
        }
    );

    const firstLogin = useMutation((_: unknown) => apiFirstLogin(), {
        onMutate: async (id: string) => {
            await queryClient.cancelQueries(CompanyQueryKey);

            const previousUsers = queryClient.getQueryData<ICompanyInfo>(CompanyQueryKey);

            queryClient.setQueryData<ICompanyInfo>(CompanyQueryKey, (prev) => {
                return {
                    ...prev!,
                    users: [...(prev?.users ?? []), id],
                };
            });

            return { previousUsers };
        },
        onError: (err, variables, context: any) => {
            if (context?.previousUsers) {
                queryClient.setQueryData<ICompanyInfo>(CompanyQueryKey, context.previousUsers);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(CompanyQueryKey);
        },
    });

    const defaultCurrency = useMemo(() => {
        return company.data?.defaultCurrency?.toUpperCase() ?? "DKK";
    }, [company.data]);

    const companyAddress = useMemo(() => {
        return `${company.data?.location?.address}, ${company.data?.location?.zipCode} ${company.data?.location?.city}`;
    }, [company.data]);

    const resetQueries = () => {
        return Promise.all([
            queryClient.invalidateQueries(MeQueryKey),
            queryClient.invalidateQueries(UserInfoQueryKey),
            queryClient.invalidateQueries(CompanyQueryKey),
        ]);
    };

    return {
        company,
        me,
        updateCompany,
        updateMe,
        firstLogin,
        userinfo,
        UserInfoQueryKey,
        companyProfileUrl,
        myRole,
        finishOnboarding,
        onboardingCompleted,
        defaultCurrency,
        companyAddress,
        resetQueries,
        claimableCompanies,
    };
};
