import {
    Box,
    BoxProps,
    Fade,
    Grid,
    GridProps,
    IconButton,
    Paper,
    Popover,
    Popper,
    TextField,
    TextFieldProps,
} from "@mui/material";
import { Check, CloseRounded, DeleteOutlineOutlined } from "@mui/icons-material";
import { useConfirm } from "../../Hooks/useConfirm";
import { UseControllerProps } from "react-hook-form";
import { useRef } from "react";

export const SaveButtons = ({
    handleCancel,
    handleSave,
}: {
    handleSave?: () => void;
    handleCancel?: () => void;
}) => (
    <Box ml={1}>
        <IconButton onClick={handleCancel} color={"error"}>
            <CloseRounded fontSize={"small"} />
        </IconButton>
        <IconButton onClick={handleSave} color={"success"}>
            <Check />
        </IconButton>
    </Box>
);

export const InputWithConfirm = ({
    onCommitChange,
    onDelete,
    rules,
    containerProps,
    ...props
}: TextFieldProps & {
    containerProps?: GridProps;
    onDelete?: () => void;
    onCommitChange?: (value: string) => void;
    rules?: UseControllerProps["rules"];
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const onCommit = (value: string) => {
        onCommitChange?.(value);
        setTimeout(() => inputRef.current?.blur(), 100);
    };

    const { field, dirty, handleSave, handleCancel, handleChange } = useConfirm(
        props.name!,
        onCommit,
        rules
    );

    return (
        <Grid
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            {...containerProps}
        >
            <TextField
                sx={{ maxWidth: 320 }}
                {...props}
                {...field}
                onChange={(evt) => handleChange(evt.target.value)}
                inputRef={inputRef}
                ref={ref}
            />
            <Popper
                open={dirty || Boolean(onDelete)}
                anchorEl={ref.current}
                placement={"top-end"}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            {dirty && (
                                <SaveButtons handleSave={handleSave} handleCancel={handleCancel} />
                            )}

                            {!dirty && onDelete && (
                                <IconButton sx={{ ml: 1 }} onClick={onDelete}>
                                    <DeleteOutlineOutlined />
                                </IconButton>
                            )}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Grid>
    );
};
