import { Box, IconButton, Stack, Typography } from "@mui/material";
import { EditRounded } from "@mui/icons-material";
import { Customer } from "../../Hooks/useCustomers";
import React from "react";
import { useTranslate } from "../../Hooks/useTranslate";
import { Activities, ActivitySubject } from "../../Components/Activities/Activities";
import { LoadingRow } from "../../Components/LoadingRow";
import { useActivities } from "../../Hooks/useActivities";

const getCustomerValue = (customer: Customer | undefined, key: keyof Customer) => {
    if (key === "location") {
        if (customer?.location?.address) {
            return `${customer.location.address}, ${customer.location.zipCode} ${customer.location.city}`;
        }
        return "Ikke angivet";
    }
    return customer?.[key] ?? "Ikke angivet";
};

export const OverviewView = ({
    customer,
    isLoading,
    onClickActivity,
    onClickEditDetails,
}: {
    customer: Customer;
    isLoading: boolean;
    onClickActivity: (id: string, subject: ActivitySubject) => void;
    onClickEditDetails: () => void;
}) => {
    const { t } = useTranslate("dialogs.customer");
    const { enrichedActivities: activities } = useActivities("customer", customer.id);
    return (
        <Stack p={4} spacing={5}>
            <Box>
                <Box display={"flex"} alignItems={"center"} mb={2}>
                    <Typography variant={"h5"} fontSize={"1.3em"} fontWeight={700} mr={2}>
                        {t("customerInfo", "utils.generic")}
                    </Typography>
                    <IconButton onClick={onClickEditDetails}>
                        <EditRounded />
                    </IconButton>
                </Box>
                <Stack spacing={0.5}>
                    {["name", "email", "phone", "location", "age"].map((el) => {
                        return (
                            <LoadingRow
                                label={t("labels." + el)}
                                value={getCustomerValue(customer, el as keyof Customer) as string}
                                isLoading={isLoading}
                            />
                        );
                    })}
                </Stack>
            </Box>
            <Box>
                <Typography variant={"h5"} fontSize={"1.3em"} fontWeight={700} mr={2}>
                    {t("activity", "utils.generic")}
                </Typography>
                <Activities mt={2} onClick={onClickActivity} activities={activities} />
            </Box>
        </Stack>
    );
};
