import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    StackProps,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useProfile } from "../../../Hooks/useProfile";
import { DeleteForeverRounded, DeleteOutlineOutlined } from "@mui/icons-material";
import {
    OptionGroup,
    OptionGroupOption,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import { ArrayContainer } from "../../../Components/ArrayContainer/ArrayContainer";
import NiceModal from "@ebay/nice-modal-react";
import { InputDialog } from "../../../Modals/InputDialog";
import { mapSelectedLanguages } from "./ServiceSyiSectionDescription";
import { renderLocation, translateOptions } from "../../../Utils/helpers";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useErrors } from "../../SyiPage/useErrors";
import { TranslatableGroup } from "../../../Components/TranslatableGroup/TranslatableGroup";
import { languagesOptions } from "../../../Utils/config";
import { useTranslate } from "../../../Hooks/useTranslate";
import { useLanguages } from "../../../Hooks/useLanguages";
import { Tip } from "../../../Components/Tip/Tip";
import { useEffect } from "react";
import { type Channel } from "./ServiceSyiSectionDetails";

const addressesOptions = [
    {
        key: "custom",
        label: "Det er på en anden adresse",
    },
    {
        key: "multiple",
        label: "Den foregår på flere forskellige adresser",
    },
];

export const deactivateBeforeDaysOptions = [
    {
        key: "no",
        label: "Nej, gæster skal kunne booke helt op til start",
        prefillLabel: "Skal gæster kunne booke helt op til start? ",
    },
    {
        key: "yes",
        label: "Ja, lad mig definere antal dage",
        prefillLabel: "Skal der lukkes for tilmeldinger {{value}} dag(e) før?",
    },
];

const availableAddonsOptions = [
    {
        key: "no",
        label: "Nej, ingen krav",
    },
    {
        key: "yes",
        label: "Ja, lad mig beskrive",
    },
];

const guestRequirementsOptions = [
    {
        key: "no",
        label: "Nej, ingen krav",
    },
    {
        key: "yes",
        label: "Ja, lad mig beskrive",
    },
];

const guestInfoOptions = [
    {
        key: "no",
        label: "Nej, de skal bare møde op",
    },
    {
        key: "yes",
        label: "Ja, lad mig beskrive",
    },
];

const customDescriptionsOptions = [
    {
        key: "no",
        label: "Nej",
    },
    {
        key: "yes",
        label: "Ja, lad mig definere mit eget unikke felt",
    },
];

export const minimumParticipantsOptions = [
    {
        key: "no",
        label: "Nej, oplevelsen afholdes selv med 0 deltagere",
        prefillLabel: "Skal eventet afholdes selv med 0 deltagere?",
    },
    {
        key: "yes",
        label: "Ja, lad mig definere antal pladser",
        prefillLabel: "Er minimum deltager antal ${value} for eventet?",
    },
];

const locationInputs: { key: string; name: string; props: Partial<TextFieldProps> }[] = [
    {
        key: "address",
        name: "Adresse",
        props: {
            fullWidth: true,
        },
    },
    {
        key: "zipCode",
        name: "Postnummer",
        props: {
            sx: {
                width: "35%",
                pr: 2,
                type: "number",
                inputProps: {
                    maxLength: 4,
                },
            },
        },
    },
    {
        key: "city",
        name: "By",
        props: {
            sx: {
                width: "65%",
            },
        },
    },
];

export const ServiceSyiSectionPracticalities = () => {
    const { t } = useTranslate("service.create.practicalities");

    const { company, updateCompany } = useProfile();

    const { register, setValue, getValues } = useFormContext();

    const channels = useWatch({ name: "channels" }) as Channel[];
    const selectedLocation = useWatch({ name: "locations.selectedOptionKey" });

    const { getError, clearError } = useErrors();

    useEffect(() => {
        if (
            company.data?.location &&
            selectedLocation !== "custom" &&
            selectedLocation !== "multiple"
        ) {
            setValue("locations.selectedOptionKey", "companyAddress", { shouldDirty: true });
        }
    }, [company.data]);

    const handleClickRegisterAddress = async () => {
        if (company.data) {
            const { address, city, zipCode } = await NiceModal.show<any>(InputDialog, {
                title: "Opret firmaadresse",
                inputs: locationInputs.map((el) => ({
                    ...el,
                    name: t(`${el.key}`, "utils.generic"),
                })),
            });
            updateCompany.mutateAsync({ ...company.data, location: { address, city, zipCode } });
            setValue("locations.selectedOptionKey", "companyAddress", { shouldDirty: true });
        }
    };

    const languages = useLanguages();

    const { field: deactivateBeforeDays } = useController({ name: "deactivateBeforeDays.value" });

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            <Stack mt={2} divider={<Divider />} spacing={4}>
                <SyiSection
                    title={t("deactivateBeforeDays.title")}
                    error={getError("deactivateBeforeDays")}
                >
                    <OptionGroup
                        name={"deactivateBeforeDays.selectedOptionKey"}
                        options={translateOptions(
                            t,
                            "deactivateBeforeDays",
                            deactivateBeforeDaysOptions
                        )}
                        onChangeValue={() => clearError("deactivateBeforeDays")}
                    >
                        <OptionGroupSelectedSection optionKey={"yes"} mt={2}>
                            <TextField
                                label={t(`deactivateBeforeDays.options.yes.inputPlaceholder`)}
                                type={"number"}
                                sx={{ minWidth: 320 }}
                                inputProps={{
                                    min: 0,
                                }}
                                {...deactivateBeforeDays}
                                onChange={(v) =>
                                    deactivateBeforeDays.onChange(parseInt(v.target.value))
                                }
                            />
                        </OptionGroupSelectedSection>
                    </OptionGroup>
                </SyiSection>

                {channels?.includes("inPerson") && (
                    <SyiSection title={t("addresses.title")} error={getError("locations")}>
                        <OptionGroup
                            name={"locations.selectedOptionKey"}
                            options={translateOptions(t, "addresses", addressesOptions)}
                            onChangeValue={() => clearError("locations")}
                        >
                            <OptionGroupOption
                                label={
                                    company.data?.location
                                        ? renderLocation(company.data.location)
                                        : t(`addresses.options.companyAddress.label`)
                                }
                                optionKey={"companyAddress"}
                                type={"option"}
                                formLabel={
                                    company.data?.location ? null : (
                                        <Button
                                            sx={{ ml: -1 }}
                                            onClick={handleClickRegisterAddress}
                                        >
                                            {t("addresses.options.companyAddress.buttonLabel")}
                                        </Button>
                                    )
                                }
                            />

                            <OptionGroupSelectedSection optionKey={"custom"} mt={2} mb={2}>
                                <TextField
                                    label={t("addresses.options.custom.inputPlaceholder")}
                                    helperText={t("addresses.options.custom.inputHelpText")}
                                    {...register(`locations.custom`)}
                                />
                            </OptionGroupSelectedSection>

                            <OptionGroupSelectedSection optionKey={"multiple"}>
                                <ArrayContainer
                                    addButtonProps={{
                                        label: t("addresses.options.multiple.buttonLabel"),
                                    }}
                                    controllerKey={"locations.multiple"}
                                    defaultValue={{ value: "" }}
                                    renderItem={(el, i, { onDeleteItem }) => (
                                        <TextField
                                            key={el.key}
                                            label={t("addresses.options.multiple.inputPlaceholder")}
                                            {...register(`locations.multiple.${i}.value`)}
                                            sx={{ minWidth: 320, maxWidth: 320, mt: 2 }}
                                            helperText={t(
                                                "addresses.options.multiple.inputHelpText"
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={onDeleteItem}
                                                            edge="end"
                                                        >
                                                            <DeleteForeverRounded />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </OptionGroupSelectedSection>
                        </OptionGroup>
                    </SyiSection>
                )}

                {channels?.includes("online") && (
                    <SyiSection title={t("meetingLink.title")} error={getError("meetingLink")}>
                        <TextField
                            label={t("meetingLink.inputPlaceholder")}
                            sx={{ minWidth: 560 }}
                            helperText={
                                <div style={{ whiteSpace: "pre-wrap" }}>
                                    {t("meetingLink.inputHelpText")}
                                </div>
                            }
                            {...register(`meetingLink`)}
                        />
                    </SyiSection>
                )}

                <SyiSection title={t("guestInfo.title")} error={getError("guestInfo")}>
                    <OptionGroup
                        name={"guestInfo.selectedOptionKey"}
                        options={translateOptions(t, "guestInfo", guestInfoOptions)}
                        onChangeValue={() => clearError("guestInfo")}
                    >
                        <OptionGroupSelectedSection optionKey={"yes"}>
                            <TranslatableGroup
                                langs={mapSelectedLanguages(languages ?? [], languagesOptions)}
                                propKey={"guestInfo.value"}
                                input={{
                                    type: "editor",
                                    placeholder: t(`guestInfo.options.yes.inputPlaceholder`),
                                }}
                            />
                        </OptionGroupSelectedSection>
                    </OptionGroup>
                </SyiSection>

                <SyiSection
                    title={t("guestRequirements.title")}
                    error={getError("guestRequirements")}
                >
                    <OptionGroup
                        name={"guestRequirements.selectedOptionKey"}
                        options={translateOptions(t, "guestRequirements", guestRequirementsOptions)}
                        onChangeValue={() => clearError("guestRequirements")}
                    >
                        <OptionGroupSelectedSection optionKey={"yes"}>
                            <TranslatableGroup
                                langs={mapSelectedLanguages(languages ?? [], languagesOptions)}
                                propKey={"guestRequirements.value"}
                                input={{
                                    type: "editor",
                                    placeholder: t(
                                        `guestRequirements.options.yes.inputPlaceholder`
                                    ),
                                }}
                            />
                        </OptionGroupSelectedSection>
                    </OptionGroup>
                </SyiSection>

                <SyiSection
                    title={t("customDescriptions.title")}
                    error={getError("customDescriptions")}
                >
                    <OptionGroup
                        name={"customDescriptions.selectedOptionKey"}
                        options={translateOptions(
                            t,
                            "customDescriptions",
                            customDescriptionsOptions
                        )}
                        onChangeValue={() => clearError("customDescriptions")}
                    >
                        <OptionGroupSelectedSection optionKey={"yes"}>
                            <ArrayContainer
                                addButtonProps={{
                                    label: t("customDescriptions.addField"),
                                }}
                                controllerKey={"customDescriptions.value"}
                                defaultValue={{}}
                                renderItem={(el, i, { onDeleteItem }) => (
                                    <CustomDescriptionGroup
                                        mt={i > 1 ? 0 : 1}
                                        index={i}
                                        languages={languages ?? []}
                                        propKey={`customDescriptions.value.${i}`}
                                        onDelete={onDeleteItem}
                                    />
                                )}
                            />
                        </OptionGroupSelectedSection>
                    </OptionGroup>
                </SyiSection>
            </Stack>
        </>
    );
};

const CustomDescriptionGroup = ({
    languages,
    propKey,
    onDelete,
    index,
    ...props
}: StackProps & {
    index: number;
    languages: string[];
    propKey: string;
    onDelete: () => void;
}) => {
    const { t } = useTranslate("service.create.practicalities.customDescriptions.inputs");
    return (
        <>
            <Stack spacing={1} {...props}>
                <TranslatableGroup
                    langs={mapSelectedLanguages(languages, languagesOptions)}
                    propKey={`${propKey}.title`}
                    title={t("titleLabel", { name: index + 1 })}
                    input={{
                        type: "input",
                        placeholder: "Fx Huskelisten, Krav til deltagerne, ell. lign",
                    }}
                />
                <TranslatableGroup
                    langs={mapSelectedLanguages(languages, languagesOptions)}
                    propKey={`${propKey}.description`}
                    title={"Feltets indhold"}
                    input={{
                        type: "editor",
                        placeholder: "Beskriv kort og præcist hvad du vil formidle",
                    }}
                />
            </Stack>
            <Stack direction={"row"} justifyContent={"end"}>
                <Button
                    sx={{ borderRadius: 100 }}
                    variant={"outlined"}
                    color={"secondary"}
                    startIcon={<DeleteOutlineOutlined />}
                    onClick={onDelete}
                >
                    Slet unik felt
                </Button>
            </Stack>
        </>
    );
};
