import Fetch from './index'
import { type Campaign } from '../Hooks/useCampaigns'

export const getCampaigns = async () => {
    if (typeof process.env.REACT_APP_API_CAMPAIGNS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CAMPAIGNS_URL is not defined')
    }

    return Fetch.get<Campaign[]>(`${process.env.REACT_APP_API_CAMPAIGNS_URL}`)
}

export const getCampaign = async (id: string) => {
    if (typeof process.env.REACT_APP_API_CAMPAIGNS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CAMPAIGNS_URL is not defined')
    }

    return Fetch.get<Campaign>(`${process.env.REACT_APP_API_CAMPAIGNS_URL}${id}`)
}

export const updateCampaign = async (id: string, payload: Omit<Campaign, 'id'>) => {
    if (typeof process.env.REACT_APP_API_CAMPAIGNS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CAMPAIGNS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_CAMPAIGNS_URL}${id}`, { ...payload })
}

export const deleteCampaign = async (id: string) => {
    if (typeof process.env.REACT_APP_API_CAMPAIGNS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CAMPAIGNS_URL is not defined')
    }

    return Fetch.delete(`${process.env.REACT_APP_API_CAMPAIGNS_URL}${id}`)
}
