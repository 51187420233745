import Fetch, { IShallowSuccessResponse } from './index'
import type { IService } from '../Hooks/useService'

export const getServices = async () => {
    if (typeof process.env.REACT_APP_API_SERVICES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SERVICES_URL is not defined')
    }

    return Fetch.get<IService[]>(`${process.env.REACT_APP_API_SERVICES_URL}`)
}

export const updateService = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_SERVICES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SERVICES_URL is not defined')
    }

    return Fetch.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_SERVICES_URL}${id}`,
        payload,
    )
}

export const deleteService = async (id: string) => {
    if (typeof process.env.REACT_APP_API_SERVICES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SERVICES_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_SERVICES_URL}${id}`)
}
