import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./i18n/config";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLocale } from "./Hooks/useLocale";
import router from "./router";
const App = () => {
    const { locale } = useLocale();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <ToastContainer />
            <RouterProvider router={router} />
        </LocalizationProvider>
    );
};

export default App;
