import React, { ComponentPropsWithoutRef, useMemo } from "react";
import { SyiPage } from "../SyiPage/SyiPage";
import { campaignSyiSteps, campaignSchema } from "../SyiPage/config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import randomBytes from "randombytes";
import { useTranslate } from "../../Hooks/useTranslate";
import { TFunction } from "i18next";
import { useForm, FormProvider } from "react-hook-form";
import { Campaign, useCampaigns } from "../../Hooks/useCampaigns";

const getTitle = (t: TFunction, id: string | undefined) =>
    id === "create"
        ? `${t("new", "utils.generic")} ${t("campaign", "utils.generic")}`
        : `${t("edit", "utils.generic")} ${t("campaign", "utils.generic")}`;

export const CampaignPage = ({ children }: ComponentPropsWithoutRef<"main">) => {
    const { t } = useTranslate("utils.generic");

    const { id } = useParams<{ id: string }>();

    const location = useLocation();
    const navigate = useNavigate();

    const _id = useMemo(() => (id !== "create" && id ? id : randomBytes(16).toString("hex")), [id]);

    const { control, setValue, ...methods } = useForm<Campaign>({
        defaultValues: {
            created: new Date().toISOString(),
            provider: "meta",
            assetType: "instantForm",
        },
    });

    const { campaign, updateCampaign, deleteCampaign } = useCampaigns(_id);

    const _steps = useMemo(() => {
        return campaignSyiSteps.map((el) => {
            return {
                ...el,
                label: t(el.key, "funnel.create.progress"),
            };
        });
    }, []);

    const onSubmit = async (
        data: Campaign,
        _dirtyFields: any,
        _shouldNavigate: any,
        validated?: boolean
    ) => {
        if (!validated) {
            window.alert("Der kan ikke gemmes kladde for kampagner.");
            return;
        }
        updateCampaign.mutate({ ...data, id: _id });
        return navigate(location?.state?.returnUrl ?? "/campaigns", { replace: true });
    };

    return (
        <FormProvider {...{ ...methods, control, setValue }}>
            <SyiPage
                modelType={"campaign"}
                steps={_steps}
                title={getTitle(t, id)}
                prepareData={(data) => data}
                deleteItem={deleteCampaign}
                updateItem={updateCampaign}
                item={campaign}
                id={_id}
                schema={campaignSchema}
                returnUrl={location?.state?.returnUrl ?? "/campaigns"}
                onSubmit={onSubmit}
                isCreateFlow={id === "create"}
            >
                {children}
            </SyiPage>
        </FormProvider>
    );
};
