import { Button, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import {useTranslate} from "../../Hooks/useTranslate";

export const OnBoardingStartSection = () => {
    const { t } = useTranslate('onboarding.start');

    const { handleNext } = useOutletContext<{ handleNext: () => void }>();

    return (
        <>
            <Typography mt={2} mb={2} variant={"h2"}>
                {t("boxTitle")}
            </Typography>
            <Typography>{t("boxDescription")}</Typography>
            <Button sx={{ mt: 5, mb: 5 }} variant={"contained"} size={"large"} onClick={handleNext}>
                {t("boxButtonLabel")}
            </Button>
        </>
    );
};
