import {styled, Tab, Tabs, TabsProps} from "@mui/material";

const StyledTabs = styled(Tabs)`
  min-height: 24px;
  border: 1px solid #E5E5EA;
  border-radius: 100px;
  padding: 3px;
  font-size: 24px;
  & .MuiTab-root {
    min-height: 12px;
    line-height: 1;
    font-size: 0.75em;
    min-width: 56px;
    border-radius: 100px;
    padding: 4px 18px;
    &.Mui-selected {
      color: ${({theme}) => theme.palette.primary.main};
      z-index: 2;
    }
  }
  & .MuiTabs-indicator {
    border: 0px solid ${({theme}) => theme.palette.primary.main};
    background-color: rgba(228, 228, 228, 0.5);
    z-index: 0;
    border-radius: 100px;
  }
`;

type ICustomTabs = {
    items: {
        key: string,
        label: string
    }[],
    selected: number
}

export const LanguageTabs = ({ items, selected, ...props }: TabsProps & ICustomTabs) => {
    return (
        <StyledTabs value={selected} { ...props}>
            {
                items?.map(({ key, label }, index) => <Tab key={key} label={label} value={index} />)
            }
        </StyledTabs>
    )
}
