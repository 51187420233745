import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from "@mui/material";
import { BlackMenu } from "../Components/BlackMenu/BlackMenu";
import React, { MouseEvent, useState } from "react";
import react from "react";
import { DeleteForeverRounded, EditRounded, SvgIconComponent } from "@mui/icons-material";

type Option = {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
};

export const useListItemOptions = (
    options: Option[],
    onClick: (key: string, index?: number) => Promise<void | unknown>
) => {
    const [anchorEl, setAnchorEl] = useState<null | [HTMLElement, number]>(null);
    const open = Boolean(anchorEl);

    const handleClickOptions = (index: number) => (event: react.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl([event.currentTarget, index]);
    };

    const handleCloseOptions = (evt: MouseEvent) => {
        evt.stopPropagation();
        setAnchorEl(null);
    };

    const handleClick = (key: string, index?: number) => async (e: MouseEvent) => {
        handleCloseOptions(e);
        await onClick(key, index);
    };

    const render = () => (
        <BlackMenu
            anchorEl={anchorEl?.[0]}
            open={open}
            onClose={handleCloseOptions}
            transformOrigin={{ horizontal: "right", vertical: "bottom" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            {options.map((el, i) => {
                const Icon = el.icon as any;
                return (
                    <MenuItem
                        autoFocus={false}
                        key={el.key}
                        onClick={handleClick(el.key, anchorEl?.[1])}
                    >
                        <ListItemIcon>
                            <Icon fontSize={"small"} {...el.props} />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={el.props}>{el.label}</ListItemText>
                    </MenuItem>
                );
            })}
        </BlackMenu>
    );

    return {
        render,
        handleClickOptions,
    };
};
