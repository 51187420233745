import {
    Avatar,
    Box,
    Dialog,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    StackProps,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Header } from "../Header";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState } from "react";
import { Customer, useCustomers } from "../../Hooks/useCustomers";
import { useTranslate } from "../../Hooks/useTranslate";
import { BookingView } from "./BookingView";
import { VoucherView } from "./VoucherView";
import { OverviewView } from "./OverviewView";
import { ActivitySubject } from "../../Components/Activities/Activities";
import { EditDetailsView } from "./EditDetailsView";
import { PersonRounded } from "@mui/icons-material";
import { JournalView } from "./JournalView";
import { LeadView } from "./LeadView";
import { ViewHeader } from "./ViewHeader";
import { OfferView } from "./OfferView";
import { useMatch, useNavigate } from "react-router-dom";
import { MoveBookingView } from "./MoveBookingView";

const Sidebar = ({
    customer,
    onChangeTab,
    selectedTab,
    ...props
}: StackProps & {
    customer: Customer | undefined;
    selectedTab: string;
    onChangeTab: (key: string) => void;
}) => {
    return (
        <Stack {...props} sx={{ backgroundColor: "#50116F" }} p={3}>
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} color={"white"}>
                <Avatar
                    sx={{ width: 92, height: 92, backgroundColor: "#DF99F0" }}
                    src={customer?.picture?.url}
                >
                    <PersonRounded fontSize={"large"} htmlColor={"white"} />
                </Avatar>
                <Typography mt={2} variant={"h5"} textAlign={"center"}>
                    {customer?.name}
                </Typography>
            </Box>
            <Divider sx={{ mt: 3, b: 0, borderColor: "#8E439A" }} />
            <Box component={List} width={"100%"}>
                <ListItem sx={{ color: "white" }}>
                    <ListItemButton
                        selected={selectedTab === "overview"}
                        onClick={() => onChangeTab("overview")}
                    >
                        <ListItemText color={"inherit"} primary={"Overblik"} />
                    </ListItemButton>
                </ListItem>
                <ListItem sx={{ color: "white" }}>
                    <ListItemButton
                        selected={selectedTab === "journal"}
                        onClick={() => onChangeTab("journal")}
                    >
                        <ListItemText color={"inherit"} primary={"Journal"} />
                    </ListItemButton>
                </ListItem>
            </Box>
        </Stack>
    );
};

export const CustomerDialog = NiceModal.create(
    ({
        customerId: _customerId,
        bookingId: _bookingId,
        voucherId: _voucherId,
        leadId: _leadId,
        offerId: _offerId,
        disableRedirectOnClose = false,
    }: {
        customerId?: string;
        bookingId?: string;
        voucherId?: string;
        leadId?: string;
        offerId?: string;
        disableRedirectOnClose?: boolean;
    }) => {
        const [customerId, setCustomerId] = useState<typeof _customerId>(_customerId);
        const [bookingId, setBookingId] = useState<null | typeof _bookingId>(_bookingId);
        const [leadId, setLeadId] = useState<null | typeof _leadId>(_leadId);
        const [offerId, setOfferId] = useState<null | typeof _offerId>(_offerId);
        const [voucherId, setVoucherId] = useState<null | typeof _voucherId>(_voucherId);

        const {
            params: { collection },
        } = useMatch("/:collection/*") ?? { params: {} };

        const navigate = useNavigate();

        const [editingDetails, setEditingDetails] = useState(false);
        const [movingBooking, setMovingBooking] = useState(false);

        const { t } = useTranslate("dialogs.customer");

        const modal = useModal();

        const [view, setView] = useState<string | "overview" | "journal">("overview");

        const handleChangeView = (key: string) => {
            setView(key);
            setBookingId(null);
            setVoucherId(null);
            setOfferId(null);
            setLeadId(null);
        };

        const {
            enrichedCustomer: customer,
            customer: { isLoading },
            updateCustomer,
        } = useCustomers(undefined, customerId);

        const {
            getValues,
            setValue,
            control,
            formState: { touchedFields, ...formState },
            ...methods
        } = useForm<Customer>();

        const handleClose = () => {
            modal.remove();
            if (disableRedirectOnClose) {
                return;
            }
            navigate(`/${collection ?? ""}`);
        };

        const handleClickActivity = (id: string, subject: ActivitySubject) => {
            if (subject === "booking") {
                setBookingId(id);
            }
            if (subject === "voucher") {
                setVoucherId(id);
            }
            if (subject === "lead") {
                setLeadId(id);
            }
            if (subject === "offer") {
                setOfferId(id);
            }
        };

        const renderHeaderTitle = () => {
            if (view === "journal") {
                return t("views." + view);
            }
            if (bookingId) {
                return "Booking";
            }
            if (voucherId) {
                return "Gavekort";
            }
            if (leadId) {
                return "Lead";
            }
            if (offerId) {
                return "Tilbud";
            }
            return t("views." + view);
        };

        return (
            <Dialog fullWidth maxWidth={"md"} open={modal.visible} onClose={handleClose}>
                <FormProvider
                    {...{
                        getValues,
                        setValue,
                        control,
                        ...{ formState: { ...formState, touchedFields } },
                        ...methods,
                    }}
                >
                    <Stack
                        direction={"row"}
                        height={"90vh"}
                        maxHeight={700}
                        sx={{ transition: "all 0.2s" }}
                    >
                        <Sidebar
                            width={210}
                            minWidth={210}
                            selectedTab={view}
                            customer={customer}
                            onChangeTab={handleChangeView}
                        />
                        <Box flexGrow={1} sx={{ overflowY: "auto", transition: "height 0.2s" }}>
                            <Header
                                mb={0}
                                pl={4}
                                title={renderHeaderTitle()}
                                id={customerId}
                                onClose={handleClose}
                            />

                            {view === "overview" && (
                                <>
                                    {voucherId ? (
                                        <VoucherView
                                            onLoadCustomer={setCustomerId}
                                            onBack={() => setVoucherId(null)}
                                            p={4}
                                            voucherId={voucherId}
                                        />
                                    ) : offerId ? (
                                        <OfferView
                                            onLoadCustomer={setCustomerId}
                                            onBack={() => setOfferId(null)}
                                            p={4}
                                            offerId={offerId}
                                        />
                                    ) : leadId ? (
                                        <LeadView
                                            onLoadCustomer={setCustomerId}
                                            onBack={() => setLeadId(null)}
                                            p={4}
                                            leadId={leadId}
                                            disableRecords
                                        >
                                            <ViewHeader
                                                title={"Lead"}
                                                onBack={() => setLeadId(null)}
                                            />
                                        </LeadView>
                                    ) : bookingId ? (
                                        movingBooking ? (
                                            <MoveBookingView
                                                bookingId={bookingId}
                                                p={4}
                                                onBack={() => setMovingBooking(false)}
                                            />
                                        ) : (
                                            <BookingView
                                                onClickMoveBooking={() => setMovingBooking(true)}
                                                onLoadCustomer={setCustomerId}
                                                onBack={() => setBookingId(null)}
                                                p={4}
                                                bookingId={bookingId}
                                            />
                                        )
                                    ) : editingDetails && customer ? (
                                        <EditDetailsView
                                            customer={customer}
                                            onBack={() => setEditingDetails(false)}
                                        />
                                    ) : customer ? (
                                        <OverviewView
                                            customer={customer}
                                            isLoading={isLoading}
                                            onClickEditDetails={() => setEditingDetails(true)}
                                            onClickActivity={handleClickActivity}
                                        />
                                    ) : null}
                                </>
                            )}
                            {view === "journal" && customerId && (
                                <>
                                    <JournalView subject={"customer"} subjectId={customerId} />
                                </>
                            )}
                        </Box>
                    </Stack>
                </FormProvider>
            </Dialog>
        );
    }
);
