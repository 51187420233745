import { useNavigate } from "react-router-dom";
import { CrudPage } from "../CrudPage";
import { IService, useService } from "../../Hooks/useService";
import { Button, Grid, Stack } from "@mui/material";
import { serviceSyiSteps } from "../SyiPage/config";
import { ServiceCard } from "../../Components/ServiceCard/ServiceCard";
import { useEvent } from "../../Hooks/useEvent";
import { useTranslate } from "../../Hooks/useTranslate";
import { useLocalizer } from "../Events/EventSyiSections/EventSyiSectionDetails";
import { useBookings } from "../../Hooks/useBookings";
import { EmptyScreen } from "../../Components/EmptyScreen/EmptyScreen";
import React, { useMemo, useState } from "react";
import { SearchField } from "../../Components/SearchField/SearchField";
import { AddRounded } from "@mui/icons-material";
import { Rating, useReviews } from "../../Hooks/useReviews";

const applyRatings = (services: IService[] | undefined, ratings: Rating[] | undefined) => {
    return services?.map((service) => {
        const { rating = 0, count = 0 } =
            ratings?.find((r) => r.subject === "service" && r.subjectId === service.id) ?? {};
        return {
            ...service,
            rating: {
                count,
                rating,
            },
        };
    });
};
export const ServicesPage = () => {
    const navigate = useNavigate();

    const { t } = useTranslate("service");

    const [searchValue, setSearchValue] = useState<null | string>(null);

    const { ratings } = useReviews("service");

    const localize = useLocalizer();

    const {
        services: { data: _services, isLoading },
    } = useService();

    const { getUpcomingEventsCount } = useEvent();
    const { getBookingsForServiceCount, bookings } = useBookings(undefined, undefined);

    const services = useMemo(() => {
        if (searchValue) {
            return applyRatings(
                _services?.filter(({ headline }) => {
                    return [localize(headline)].some((el) =>
                        el?.toLowerCase().includes(searchValue.toLowerCase())
                    );
                }),
                ratings.data
            );
        }
        return applyRatings(_services, ratings.data);
    }, [_services, searchValue, ratings]);

    const handleClick = (id: string) => navigate(`/service/${id}`);

    const handleCreate = () => {
        navigate(`/service/create/${serviceSyiSteps[0].key}`, { replace: true });
    };

    return (
        <CrudPage title={t("title")}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={4}>
                <SearchField
                    minWidth={420}
                    onSearch={setSearchValue}
                    placeholder={"Søg i ydelser"}
                />
                <Button
                    onClick={handleCreate}
                    variant={"contained"}
                    size={"large"}
                    color={"primary"}
                    startIcon={<AddRounded />}
                >
                    {t("buttonLabel")}
                </Button>
            </Stack>
            <Grid container spacing={4} columns={{ xs: 1, lg: 2 }}>
                {isLoading ? (
                    <ServiceCard />
                ) : (
                    services?.map((el: any) => {
                        return (
                            <ServiceCard
                                key={el.id}
                                serviceId={el.id}
                                coverImage={el?.pictures?.[0]?.url}
                                title={localize(el?.headline)}
                                languages={el?.languages}
                                eventCount={
                                    getUpcomingEventsCount(el.id) ??
                                    (bookings.isLoading ? undefined : 0)
                                }
                                type={el.type}
                                bookingCount={
                                    getBookingsForServiceCount(el.id) ??
                                    (bookings.isLoading ? undefined : 0)
                                }
                                status={el?.status ?? "draft"}
                                rating={{
                                    average: el.rating.rating,
                                    count: el.rating.count,
                                    editable: false,
                                }}
                                createdDate={el.created}
                                onClick={() => handleClick(el.id)}
                            />
                        );
                    })
                )}
            </Grid>
            {services?.length === 0 && (
                <EmptyScreen
                    direction={"row"}
                    maxWidth={720}
                    imageSrc={"/empty-screens/service.png"}
                    imageProps={{ width: 320, height: 320 }}
                    title={t("emptyState.title")}
                    description={t("emptyState.description")}
                    onClick={handleCreate}
                    buttonLabel={t("emptyState.action")}
                    pt={6}
                    pb={5}
                />
            )}
        </CrudPage>
    );
};
