import { Button, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useController, useFormContext, useWatch } from "react-hook-form";
import {
    OptionGroup,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useErrors } from "../../SyiPage/useErrors";

import { DateController } from "../../../Components/DateController/DateController";
import { AddRounded } from "@mui/icons-material";
import { toISODateTime } from "../../Events/EventSyiSections/EventSyiSectionDetails";
import { addDays } from "date-fns";
import {
    RecurringControl,
    useRecurringOptions,
} from "../../../Components/RecurringControl/RecurringControl";
import { useTranslate } from "../../../Hooks/useTranslate";
import { durationOptions, paddingOptions, weekdays } from "../../../Utils/config";
import { OpeningHoursControl } from "../../../Components/OpeningHoursControl/OpeningHoursControl";
import { ErrorLabel } from "../../SyiPage/ErrorLabel";
import { useAddress } from "../../../Hooks/useAddress";

const getTranslationKey = (serviceType: "session" | string) => {
    return serviceType === "session" ? "sessionType" : "eventType";
};

const alignDay = (str: string, str2: string) => {
    const d = new Date(str).getDate();
    const m = new Date(str).getMonth();
    const y = new Date(str).getFullYear();
    const _new = new Date(str2);
    _new.setFullYear(y, m, d);
    return _new.toString();
};

export const ServiceSyiSectionTime = () => {
    const { t } = useTranslate("service.create.time");

    const serviceType = useWatch({ name: "type" });

    return (
        <>
            <Typography variant={"h4"}>{t(`${getTranslationKey(serviceType)}.title`)}</Typography>
            <Typography variant={"h5"} mt={2} mb={4}>
                {t(`${getTranslationKey(serviceType)}.subtitle`)}
            </Typography>

            {serviceType === "session" ? <SessionTypeTimeControls /> : null}
        </>
    );
};

const EventTypeTimeControls = () => {
    const { field: startDateTime } = useController({ name: "event.startDateTime" });
    const { field: endDateTime } = useController({ name: "event.endDateTime" });
    const { field: isSameDay } = useController({ name: "event.isSameDay", defaultValue: true });

    const { t } = useTranslate("service.create.time.eventType");

    const { getError, withClear, clearError } = useErrors();

    const { recurringOptions, handleChangeRecurring } = useRecurringOptions("event");

    const handleAddEndDate = () => {
        if (endDateTime?.value || startDateTime?.value) {
            endDateTime.onChange(
                toISODateTime(addDays(new Date(endDateTime?.value ?? startDateTime?.value), 1))
            );
        }
        isSameDay.onChange(false);
    };

    const handleDeleteEndDate = () => {
        endDateTime.onChange(alignDay(startDateTime.value, endDateTime.value));
        isSameDay.onChange(true);
    };

    return (
        <Stack spacing={4} divider={<Divider />}>
            <Stack alignItems={"start"} spacing={3}>
                {getError("event.startDateTime") && (
                    <ErrorLabel label={getError("event.startDateTime")} />
                )}
                <DateController
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    hideTo={!isSameDay.value}
                    onChange={() => clearError(["event.startDateTime", "event.endDateTime"])}
                    name={"startDateTime"}
                    label={
                        isSameDay.value
                            ? t("date", "utils.generic")
                            : t("startDate", "utils.generic")
                    }
                />
                {!isSameDay.value ? (
                    <DateController
                        label={t("endDate", "utils.generic")}
                        hideFrom={true}
                        onDelete={handleDeleteEndDate}
                        startDateTime={endDateTime}
                        endDateTime={endDateTime}
                        name={"endDateTime"}
                    />
                ) : (
                    <Button
                        disabled={endDateTime.value === null}
                        sx={{ borderRadius: 100, mt: "0 !important" }}
                        color={"secondary"}
                        variant={"outlined"}
                        onClick={handleAddEndDate}
                        startIcon={<AddRounded />}
                    >
                        {t("buttons.addEndDate")}
                    </Button>
                )}
            </Stack>
            <SyiSection title={t("recurring.title")} error={getError("event.recurring")}>
                <OptionGroup
                    name={"event.recurring.selectedOptionKey"}
                    options={recurringOptions}
                    onChangeValue={withClear("event.recurring", handleChangeRecurring)}
                >
                    <OptionGroupSelectedSection optionKey={"yes"} mt={2}>
                        <RecurringControl parentFormKey={"event"} />
                    </OptionGroupSelectedSection>
                </OptionGroup>
            </SyiSection>
        </Stack>
    );
};

const useLocationChannels = () => {
    const [channels, serviceLocations] = useWatch({ name: ["channels", "locations"] });

    const { address } = useAddress(serviceLocations);

    return [address ?? []]
        .flat()
        .map((a) => ({ id: asId(a), label: a }))
        .concat(channels.includes("online") ? [{ id: "online", label: "Online" }] : []);
};
export const asId = (value: string, prefix = "inPerson"): string =>
    window.btoa(`${prefix}#${encodeURIComponent(value)}`);

const SessionTypeTimeControls = () => {
    const { field: duration } = useController({ name: "session.duration", defaultValue: 60 });
    const { field: padding } = useController({
        name: "session.slotPaddingMins",
        defaultValue: 0,
    });

    const { t } = useTranslate("service.create.time.sessionType");

    const { getError } = useErrors();

    const channels = useLocationChannels();

    return (
        <Stack spacing={4} divider={<Divider />}>
            <OpeningHoursControl channels={channels} propKey={"session.openingHours"} />
            <SyiSection
                error={getError("duration")}
                typographyProps={{ mb: 3 }}
                title={t("selectDurationTitle")}
            >
                <Select
                    labelId="duration-select-label"
                    id="duration-select"
                    value={duration.value}
                    onChange={duration.onChange}
                >
                    {durationOptions.map((el) => (
                        <MenuItem value={el}>{el} minutter</MenuItem>
                    ))}
                </Select>
            </SyiSection>

            <SyiSection
                error={getError("padding")}
                typographyProps={{ mb: 3 }}
                title={t("selectPaddingTitle")}
            >
                <Select
                    labelId="padding-select-label"
                    id="padding-select"
                    value={padding.value}
                    onChange={padding.onChange}
                >
                    <MenuItem value={0}>Ingen pause</MenuItem>
                    {paddingOptions.map((el) => (
                        <MenuItem value={el}>{el} minutter</MenuItem>
                    ))}
                </Select>
            </SyiSection>
        </Stack>
    );
};
