import Fetch, { IShallowSuccessResponse } from './index'
import { Offer } from '../Hooks/useOffers'

export const getOffers = async () => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    return Fetch.get<Offer[]>(`${process.env.REACT_APP_API_OFFERS_URL}`)
}

export const getOffersByCustomerId = async (customerId: string) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    return Fetch.get<Offer[]>(`${process.env.REACT_APP_API_OFFERS_URL}customer/${customerId}`)
}

export const getOffer = async (offerId: string) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    if (!offerId) {
        throw new Error('Missing offer id')
    }

    return Fetch.get<Offer>(`${process.env.REACT_APP_API_OFFERS_URL}${offerId}`)
}

export const updateOffer = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_OFFERS_URL}${id}`, payload)
}
export const createOffer = async (payload: Omit<Offer, 'created'>) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    return Fetch.post<{ id: string }>(`${process.env.REACT_APP_API_OFFERS_URL}`, payload)
}

export const resendOffer = async (offerId: string | undefined) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    await Fetch.post<{ id: string }>(`${process.env.REACT_APP_API_OFFERS_URL}${offerId}/resend`)
}

export const deleteOffer = async (id: string) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_OFFERS_URL}${id}`)
}

export const refundOffer = async (id: string) => {
    if (typeof process.env.REACT_APP_API_OFFERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_OFFERS_URL is not defined')
    }

    await Fetch.post<unknown>(`${process.env.REACT_APP_API_OFFERS_URL}${id}/refund`)
}
