import { Grid, GridProps, SvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomChip } from "../CustomChip/CustomChip";
import { DoneRounded } from "@mui/icons-material";
import React, { ReactElement, useEffect, useState } from "react";

type OptionChipsProps = Omit<GridProps, "onChange"> & {
    translateKey?: string;
    multiple?: boolean;
    disabled?: boolean;
    allowEmpty?: boolean;
    allOptions?: OptionItem[];
    color?: string;
    selectedOptions?: string[];
    onChange: (keys: string[]) => void;
};

export type OptionItem = {
    key: string;
    label: string;
    icon?: string | typeof SvgIcon | ReactElement;
};

export const OptionChips = ({
    multiple = true,
    allowEmpty = false,
    translateKey,
    selectedOptions,
    allOptions,
    onChange,
    color,
    disabled = false,
    ...props
}: OptionChipsProps) => {
    const { t } = useTranslation();

    const [selected, setSelected] = useState(selectedOptions ?? []);
    const handleToggle = (key: string) => () => {
        if (multiple) {
            const _new = selected.includes(key)
                ? selected.filter((el: string) => el !== key)
                : [...selected, key];
            if (_new.length === 0 && allowEmpty) {
                setSelected([]);
                return onChange([]);
            }
            if (selected.length > 0 && _new.length > 0) {
                setSelected(_new);
                return onChange(_new);
            }
        }
        setSelected([key]);
        onChange([key]);
    };

    useEffect(() => {
        if (Array.isArray(selectedOptions)) {
            setSelected(selectedOptions);
        }
    }, [selectedOptions]);

    return (
        <Grid container gap={1} {...props}>
            {allOptions?.map(({ key, label, icon }) => {
                return (
                    <Grid
                        item
                        key={key}
                        disabled={disabled}
                        component={CustomChip}
                        onClick={handleToggle(key)}
                        selected={selected.includes(key)}
                        label={translateKey ? t(`options.${translateKey}.${key}`) : label}
                        icon={renderIcon(icon)}
                        deleteIcon={<DoneRounded />}
                        sx={{
                            height: 38,
                            fontSize: "0.88em",
                            fontWeight: "600",
                            borderRadius: 100,
                            color,
                        }}
                    />
                );
            })}
        </Grid>
    );
};

export const renderIcon = (Icon: OptionItem["icon"]) => {
    if (typeof Icon === "string") {
        return <img src={Icon} width={20} height={20} />;
    }
    if (React.isValidElement(Icon)) {
        return Icon;
    }
    if (Icon) {
        return <Icon />;
    }
    return undefined;
};
