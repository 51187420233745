import Fetch from './index'
import { TBooking } from '../Hooks/useBookings'

export const getBookings = async () => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    return Fetch.get<TBooking[]>(`${process.env.REACT_APP_API_BOOKINGS_URL}`)
}

export const getBooking = async (id: string) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    return Fetch.get<TBooking>(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}`)
}

export const createBooking = async <T, D>(id: string, payload: D) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    return Fetch.post<T, D>(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}`, {
        ...payload,
    })
}

export const createManualBookingForSession = async <T, D>(id: string, payload: D) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    return Fetch.post<T, D>(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}/manual`, {
        ...payload,
    })
}

export const updateBooking = async <T, D>(id: string, payload: D) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    await Fetch.put<T, D>(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}`, { ...payload })
}

export const cancelBooking = async (id: string, shouldRefund: boolean = false) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    await Fetch.post<unknown>(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}/cancel`, {
        shouldRefund,
    })
}

export const deleteBooking = async (id: string) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    await Fetch.delete(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}`)
}

export const moveBooking = async (
    id: string,
    shouldNotify: boolean,
    toEventId?: string,
    startDateTime?: string,
    endDateTime?: string,
    message?: string,
) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    return Fetch.post<{ newBookingId: string; newEventId?: string }>(
        `${process.env.REACT_APP_API_BOOKINGS_URL}${id}/move`,
        {
            shouldNotify,
            ...(toEventId && { newEventId: toEventId }),
            ...(startDateTime &&
                endDateTime && {
                    startDateTime,
                    endDateTime,
                }),
            ...(message && { message }),
        },
    )
}

export const refundBooking = async (id: string) => {
    if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_BOOKINGS_URL is not defined')
    }

    await Fetch.post(`${process.env.REACT_APP_API_BOOKINGS_URL}${id}/refund`)
}
