import { Select, SelectProps, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { currencyOptions } from "../../Utils/config";
import { startTransition } from "react";

export const CurrencySelect = ({ sx = {}, value, onChange, ...props }: Partial<SelectProps>) => {
    return (
        <Select
            onChange={(...params) => {
                startTransition(() => {
                    onChange?.(...params);
                });
            }}
            inputProps={{
                sx: {
                    display: "flex",
                    alignItems: "center",
                },
            }}
            MenuProps={{
                sx: {
                    maxHeight: 400,
                    ...sx,
                },
            }}
            {...props}
            value={value ?? ""}
            sx={{ ...sx, maxHeight: 56 }}
        >
            {Object.entries(currencyOptions).map(([code, { emoji, label }]) => (
                <MenuItem value={code} key={code}>
                    <Typography mr={1} fontSize={"2em"}>
                        {emoji}
                    </Typography>
                    <Typography>{label}</Typography>
                </MenuItem>
            ))}
        </Select>
    );
};
