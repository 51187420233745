import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { type Customer, useCustomers } from "../../Hooks/useCustomers";
import React, { useRef } from "react";
import { useTranslate } from "../../Hooks/useTranslate";
import { ViewHeader } from "./ViewHeader";
import { FlatCard } from "../../Components/FlatCard/FlatCard";
import { ProfileUpload } from "../../Components/ProfileUpload/ProfileUpload";
import { TImage } from "../../Utils/types";
import { useForm, useWatch } from "react-hook-form";
import * as api from "../../Api";
import { PersonRounded } from "@mui/icons-material";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";

type EditableCustomer = Pick<Customer, "name" | "email" | "phone" | "location" | "picture">;

export const EditDetailsView = ({
    customer,
    onBack,
}: {
    customer: Customer;
    onBack: () => void;
}) => {
    const { t } = useTranslate("dialogs.customer");

    const {
        register,
        getValues,
        setValue,
        control,
        reset,
        formState: { isDirty },
    } = useForm<EditableCustomer>();

    const picture = useWatch({ name: "picture", control, defaultValue: customer.picture });

    const picturePromise = useRef<() => Promise<void>>();

    const { updateCustomer } = useCustomers(undefined, customer.id);

    const handleUploadPrepared = (
        { key, url }: { [k: string]: string },
        localUrl: string,
        fileType: "logo" | "profile",
        uploadFunc: () => Promise<void>
    ) => {
        setValue("picture", { url, key }, { shouldDirty: true });
        picturePromise.current = uploadFunc;
    };

    const handleSave = async () => {
        await picturePromise.current?.();
        await updateCustomer.mutateAsync({ ...getValues(), id: customer.id });
        reset(getValues());
    };

    const handleDeletePicture = async ({ key }: TImage) => {
        setValue("picture", null, { shouldDirty: false });
        api.deleteUpload(key).then(handleSave);
    };
    return (
        <Stack p={4}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                <ViewHeader title={t("editDetails.title")} mb={0} onBack={onBack} />
                {isDirty && (
                    <ProgressButton
                        label={t("save", "buttons")}
                        disabled={!isDirty}
                        variant={"contained"}
                        onClick={handleSave}
                    />
                )}
            </Box>
            <FlatCard p={3}>
                <Typography variant={"h5"}>{t("profilePicture.title")}</Typography>
                <ProfileUpload
                    mt={2}
                    fileType={"profile"}
                    containerProps={{
                        sx: {
                            backgroundColor: "#DF99F0",
                            border: "none",
                        },
                    }}
                    onUploadPrepared={handleUploadPrepared}
                    label={t("profilePicture.helperText")}
                    media={picture ?? undefined}
                    subjectId={customer.id}
                    subject={"me"}
                >
                    <PersonRounded fontSize={"large"} htmlColor={"white"} />
                </ProfileUpload>
            </FlatCard>
            <Grid container columns={2} spacing={2} mt={3}>
                {(["name", "email", "phone", "age", "location"] as (keyof EditableCustomer)[]).map(
                    (el) => {
                        if (el === "location") {
                            return (
                                [
                                    "address",
                                    "city",
                                    "zipCode",
                                ] as (keyof EditableCustomer["location"])[]
                            ).map((loc) => (
                                <Grid item xs={1}>
                                    <TextField
                                        label={t("labels." + loc)}
                                        fullWidth
                                        defaultValue={customer.location?.[loc]}
                                        {...register(`${el}.${loc}`)}
                                    />
                                </Grid>
                            ));
                        }
                        return (
                            <Grid item xs={1}>
                                <TextField
                                    label={t("labels." + el)}
                                    fullWidth
                                    defaultValue={customer[el]}
                                    {...register(el)}
                                />
                            </Grid>
                        );
                    }
                )}
            </Grid>
        </Stack>
    );
};
