import React, { ChangeEvent, ReactNode } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { Box, BoxProps, Button, ButtonProps, Stack } from "@mui/material";
import { AddRounded } from "@mui/icons-material";

export const ArrayContainer = ({
    renderItem,
    max = 10,
    onAdd,
    controllerKey,
    emptyState,
    addButtonProps,
    defaultValue,
    children,
    ...props
}: {
    onAdd?: (val: any) => void;
    defaultValue: { [k: string]: string | number } | string | ((index: number) => any);
    max?: number;
    emptyState?: UseControllerProps["defaultValue"];
    addButtonProps: ButtonProps & { label: string };
    controllerKey: string;
    renderItem: (
        el: any,
        i: number,
        props: { onChange: (evt: ChangeEvent<HTMLInputElement>) => void; onDeleteItem: () => void }
    ) => ReactNode;
} & Omit<BoxProps, "defaultValue">) => {
    const { label, ...buttonProps } = addButtonProps;

    const {
        field: { value, onChange },
    } = useController({ name: controllerKey, defaultValue: emptyState });

    const handleAddVariant = () => {
        const newValue =
            defaultValue === "latest"
                ? value?.[value.length - 1] ?? {}
                : typeof defaultValue === "function"
                ? defaultValue((value?.length ?? 0) + 1)
                : defaultValue;
        onChange([...(value ?? []), newValue]);
        onAdd?.(newValue);
    };

    const handleDeleteVariant = (index: number) => () => {
        const _filtered = [...(value ?? []).filter((_: never, i: number) => i !== index)];
        onChange(_filtered.length > 0 ? _filtered : []);
    };

    return (
        <Box mt={2} {...props}>
            <Stack spacing={3}>
                {value?.map((el: any, i: number) =>
                    renderItem(el, i, {
                        onDeleteItem: handleDeleteVariant(i),
                        onChange: (evt) => onChange(evt.target.value),
                    })
                )}
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
                {(value?.length ?? 0) < max && (
                    <Button
                        sx={{ borderRadius: 100, mt: 2 }}
                        variant={"outlined"}
                        color={"secondary"}
                        startIcon={<AddRounded />}
                        onClick={handleAddVariant}
                        {...buttonProps}
                    >
                        {label}
                    </Button>
                )}
                {children}
            </Stack>
        </Box>
    );
};
