import { BoxProps, Divider, Stack, TextField, Typography } from "@mui/material";
import { useController, UseControllerReturn, useFormContext, useWatch } from "react-hook-form";
import {
    OptionGroup,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import { useService } from "../../../Hooks/useService";
import { ReactNode, useMemo } from "react";
import {
    deactivateBeforeDaysOptions,
    minimumParticipantsOptions,
} from "../../Services/ServiceSyiSections/ServiceSyiSectionPracticalities";
import { useProfile } from "../../../Hooks/useProfile";
import { renderLocation } from "../../../Utils/helpers";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useTranslate } from "../../../Hooks/useTranslate";
import { Channel } from "../../Services/ServiceSyiSections/ServiceSyiSectionDetails";
import { useErrors } from "../../SyiPage/useErrors";
import { getDefinedValueOrDefault } from "../EventPage";

const seatsAvailableOptions = [
    {
        key: "serviceDefault",
        label: "Ja, det er korrekt",
    },
    {
        key: "custom",
        label: "Nej, lad mig definere",
    },
];

const addressOptions = [
    {
        key: "custom",
        label: "Det er på en anden adresse",
    },
];

type TOptionGroupObj = Record<string, string> & {
    selectedOptionKey: string;
};

const replaceValue = (str: string, value: string) => str.replaceAll("{{value}}", value as string);

const getPrefillLabel =
    (options: any[]) =>
    (optionKey: string = "no", value: string | number) => {
        const label = options?.find((el) => el.key === optionKey)?.prefillLabel ?? "";
        return replaceValue(label, value as string);
    };

const FormGroupWithPrefill = ({
    formKey,
    defaultValue,
    label,
    render,
    prefillOptions,
    options,
    ...props
}: BoxProps & {
    render: (field: UseControllerReturn<any, any>["field"]) => ReactNode;
    label?: string;
    options: any[];
    prefillOptions?: typeof minimumParticipantsOptions;
    formKey: string;
    defaultValue?: any;
}) => {
    const { setValue } = useFormContext();

    const { field: selected } = useController({
        name: `${formKey}.selectedOptionKey`,
        defaultValue: "serviceDefault",
    });
    const { field: value } = useController({
        name: `${formKey}.value`,
        defaultValue:
            typeof defaultValue === "string" || typeof defaultValue === "number"
                ? defaultValue
                : defaultValue?.value,
    });

    const handleOptionKeyChangeToServiceDefault =
        (formKey: string, value: string | number) => (optionKey: string) => {
            if (optionKey === "serviceDefault" && value !== undefined) {
                setValue(formKey, value);
            }
        };

    return (
        <SyiSection
            title={
                label
                    ? replaceValue(label, value.value)
                    : getPrefillLabel(prefillOptions!)(
                          defaultValue !== undefined ? "yes" : "no",
                          getDefinedValueOrDefault(
                              defaultValue !== undefined ? defaultValue : value.value,
                              0
                          )
                      )
            }
            {...props}
        >
            <OptionGroup
                name={formKey}
                options={options}
                field={selected}
                onChangeValue={handleOptionKeyChangeToServiceDefault(
                    `${formKey}.value`,
                    defaultValue
                )}
            >
                {render(value)}
            </OptionGroup>
        </SyiSection>
    );
};

const getAddressKey = (serviceKey: string) => {
    switch (serviceKey) {
        case "custom":
            return "serviceCustom";
        case "multiple":
            return "serviceMultiple-0";
        default:
            return "companyAddress";
    }
};

export const EventSyiSectionPracticalities = () => {
    const { t } = useTranslate("events.create.practicalities");

    const { company } = useProfile();

    const { register } = useFormContext();

    const { getError, withClear } = useErrors();

    const { field: serviceId } = useController({ name: "serviceId" });
    const { field: locations } = useController({ name: "locations.value" });
    const { field: meetingLink } = useController({ name: "meetingLink" });

    const channels = useWatch({ name: "channels" }) as Channel[];

    const {
        service: { data: service },
    } = useService(serviceId.value);

    const addressesOptions = useMemo(() => {
        return [
            {
                label: renderLocation(company?.data?.location),
                key: "companyAddress",
            },
            {
                label: service?.locations?.custom ?? "",
                key: "serviceCustom",
            },
            ...(service?.locations?.multiple ?? []).map((el: any, i: number) => {
                return { ...el, label: el.value, key: `serviceMultiple-${i}` };
            }),
            ...addressOptions.map((el) => ({
                ...el,
                label: t(`addresses.options.${el.key}.label`),
            })),
        ].filter((el) => el?.label);
    }, [company.data, service]);

    const handleChangeAddress = (newKey: string) => {
        locations.onChange((addressesOptions?.find((el) => el.key == newKey) ?? {})?.label ?? "");
    };

    const _deactivateBeforeDaysOptions = deactivateBeforeDaysOptions.map((el) => ({
        ...el,
        prefillLabel: t(`deactivateBeforeDays.prefillLabels.${el.key}`),
    }));

    const _seatsAvailableOptions = seatsAvailableOptions.map((el) => ({
        ...el,
        label: t(`seatCount.options.${el.key}.label`),
    }));

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            {(service as any)?.seatCount && (
                <Stack mt={2} divider={<Divider />} spacing={4}>
                    <FormGroupWithPrefill
                        render={(field) => (
                            <OptionGroupSelectedSection optionKey={"custom"} mt={2}>
                                <TextField
                                    label={t("seatCount.options.custom.inputLabel")}
                                    type={"number"}
                                    inputProps={{
                                        min: 0,
                                    }}
                                    sx={{ minWidth: 320 }}
                                    {...field}
                                    onChange={(v) => {
                                        field.onChange(parseInt(v.target.value));
                                    }}
                                />
                            </OptionGroupSelectedSection>
                        )}
                        formKey={"seatCount"}
                        label={t("seatCount.title")}
                        options={_seatsAvailableOptions}
                        defaultValue={(service as any)?.seatCount}
                    />
                    <FormGroupWithPrefill
                        render={(field) => (
                            <OptionGroupSelectedSection optionKey={"custom"} mt={2}>
                                <TextField
                                    label={t("deactivateBeforeDays.options.custom.inputLabel")}
                                    type={"number"}
                                    inputProps={{
                                        min: 0,
                                    }}
                                    sx={{ minWidth: 320 }}
                                    {...field}
                                    onChange={(v) => {
                                        field.onChange(parseInt(v.target.value));
                                    }}
                                />
                            </OptionGroupSelectedSection>
                        )}
                        formKey={"deactivateBeforeDays"}
                        prefillOptions={_deactivateBeforeDaysOptions}
                        options={_seatsAvailableOptions}
                        defaultValue={service?.deactivateBeforeDays as any}
                    />

                    {channels?.includes("inPerson") && (
                        <SyiSection title={t("addresses.title")}>
                            <OptionGroup
                                name={"locations.selectedOptionKey"}
                                options={addressesOptions}
                                onChangeValue={handleChangeAddress}
                                defaultValue={getAddressKey(
                                    (service?.locations as any)?.selectedOptionKey
                                )}
                            >
                                <OptionGroupSelectedSection optionKey={"custom"} mt={2} mb={2}>
                                    <TextField
                                        label={t("addresses.options.custom.inputLabel")}
                                        sx={{ minWidth: 320 }}
                                        helperText={t("addresses.options.custom.inputHelperText")}
                                        {...register("locations.value")}
                                    />
                                </OptionGroupSelectedSection>
                            </OptionGroup>
                        </SyiSection>
                    )}

                    {channels?.includes("online") && (
                        <SyiSection title={t("meetingLink.title")} error={getError("meetingLink")}>
                            <TextField
                                label={t("meetingLink.inputPlaceholder")}
                                sx={{ minWidth: 560 }}
                                defaultValue={service?.meetingLink}
                                helperText={
                                    <div style={{ whiteSpace: "pre-wrap" }}>
                                        {t("meetingLink.inputHelpText")}
                                    </div>
                                }
                                {...meetingLink}
                                onChange={withClear("meetingLink", meetingLink.onChange)}
                            />
                        </SyiSection>
                    )}
                </Stack>
            )}
        </>
    );
};
