import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTranslate } from "../../Hooks/useTranslate";
import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Options, RRule, WeekdayStr } from "rrule";
import { FrequencyUntilSelect } from "../FrequencyUntilSelect/FrequencyUntilSelect";
import { weekdays } from "../../Utils/config";
import { toISODateTime } from "@hiddengemgroup/utils-date";

const _recurringOptions = [
    {
        key: "no",
        label: "Nej, det afholdes kun denne ene gang",
    },
    {
        key: "yes",
        label: "Ja, lad mig definere frekvens",
    },
];

const DEFAULT_FREQUENCY_COUNT = 100;

const frequencyOptions = [
    {
        key: "daily",
        label: "Dagligt",
        rruleOptions: {
            freq: RRule.DAILY,
            interval: 1,
            count: DEFAULT_FREQUENCY_COUNT,
            byweekday: weekdays.map((el: WeekdayStr) => RRule[el]),
        },
    },
    {
        key: "weekly",
        label: "Ugentligt",
        rruleOptions: {
            freq: RRule.WEEKLY,
            interval: 1,
            count: DEFAULT_FREQUENCY_COUNT,
        },
    },
    {
        key: "allBusinessDays",
        label: "Alle hverdage",
        rruleOptions: {
            freq: RRule.DAILY,
            interval: 1,
            count: DEFAULT_FREQUENCY_COUNT,
            byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
        },
    },
    {
        key: "allWeekends",
        label: "Alle weekender",
        rruleOptions: {
            freq: RRule.DAILY,
            interval: 1,
            count: DEFAULT_FREQUENCY_COUNT,
            byweekday: [RRule.SA, RRule.SU],
        },
    },
];

export const useRecurringOptions = (prefix?: string) => {
    const { t } = useTranslate("events.create.details");
    const { setValue } = useFormContext();
    const [recurringOptions, setRecurringOptions] = useState(
        _recurringOptions.map((el) => ({ ...el, label: t(`recurring.options.${el.key}`) }))
    );
    const handleChangeRecurring = (val: string) => {
        if (val === "no") {
            setValue(`${prefix ? `${prefix}.` : ""}recurring.rrulestr`, null, {
                shouldDirty: true,
            });
        }
    };
    return {
        handleChangeRecurring,
        recurringOptions,
        setRecurringOptions,
    };
};

const getFormKey = (...keys: (string | undefined)[]) => {
    return keys.filter(Boolean).join(".");
};

const asDate = (date?: string | Date) => {
    if (date instanceof Date) {
        return date;
    }
    if (typeof date === "string") {
        try {
            return new Date(date);
        } catch {
            return undefined;
        }
    }
    return undefined;
};

export const RecurringControl = ({
    disabled = false,
    parentFormKey,
}: {
    disabled?: boolean;
    parentFormKey?: string;
}) => {
    const { field: frequency } = useController({
        name: getFormKey(parentFormKey, "recurring.frequency.value"),
    });

    const { t } = useTranslate("events.create.details");

    const { control, getValues, setValue } = useFormContext();

    const startDateTime = useWatch({
        control,
        name: "startDateTime",
    });

    useEffect(() => {
        if (frequency.value) {
            handleChangeUntil(false);
        }
    }, [startDateTime]);

    const _frequencyOptions = frequencyOptions.map((el) => ({
        ...el,
        label: t(`recurring.frequency.options.${el.key}`),
    }));

    const handleChangeUntil = (shouldDirty = true) => {
        const [type, val] = getValues([
            getFormKey(parentFormKey, "recurring.untilType"),
            getFormKey(parentFormKey, "recurring.until"),
        ]);
        const [date] = toISODateTime(asDate(val) as Date).split("T");
        handleChangeFrequency(
            {
                count: type === "occurrence" && val ? val : DEFAULT_FREQUENCY_COUNT,
                until: date ? date : null,
            },
            shouldDirty
        )(frequency.value);
    };

    const handleChangeFrequency =
        (opts?: object, shouldDirty = true) =>
        async (value: string) => {
            let options: Partial<Options> = {
                ...frequencyOptions.find((el) => el.key === value)?.rruleOptions,
                ...opts,
            };
            if (value === "weekly") {
                options.byweekday = [
                    (startDateTime ? new Date(startDateTime) : new Date()).getDay() - 1,
                ];
            }
            setValue(getFormKey(parentFormKey, "recurring.frequency.value"), value, {
                shouldDirty,
            });
            setValue(getFormKey(parentFormKey, "recurring.rrulestr"), generateRuleString(options), {
                shouldDirty,
            });
        };

    const generateRuleString = ({ ...options }: Partial<Options>) => {
        console.log(options);
        try {
            return new RRule({
                byhour: 0,
                byminute: 0,
                bysecond: 0,
                ...options,
            }).toString();
        } catch (err) {
            console.log("Error creating rule", err);
        }
    };

    return (
        <FormControl sx={{ minWidth: 320 }}>
            <InputLabel
                sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }}
                id="frequency-select-label"
            >
                {t("recurring.frequency.selectLabel")}
            </InputLabel>
            <Select
                labelId="frequency-select-label"
                id="frequency-select"
                value={frequency.value}
                onChange={(evt) => handleChangeFrequency()(evt.target.value)}
            >
                {_frequencyOptions.map((el) => (
                    <MenuItem value={el.key} disabled={disabled}>
                        {el.label}
                    </MenuItem>
                ))}
            </Select>
            {frequency.value !== "custom" && frequency.value && (
                <FrequencyUntilSelect
                    control={control}
                    typeFormKey={getFormKey(parentFormKey, "recurring.untilType")}
                    valueFormKey={getFormKey(parentFormKey, "recurring.until")}
                    onChange={() => handleChangeUntil()}
                    mt={2}
                />
            )}
        </FormControl>
    );
};
