import {
    Avatar,
    Box,
    BoxProps,
    Button,
    capitalize,
    Card,
    Divider,
    Grid,
    IconButton,
    Popover,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { DeleteOutlineOutlined, MoreVert, PhoneRounded } from "@mui/icons-material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useUsers, TUser } from "../../Hooks/useUsers";
import { useProfile } from "../../Hooks/useProfile";
import { ROLE_OPTIONS as options } from "../../Utils/constants";
import { Input } from "../Input/Input";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { StatusChip } from "../StatusChip";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { useTranslate } from "../../Hooks/useTranslate";
import { ellipsisStyle } from "../../Utils/theme";

const columns = [
    {
        key: "none",
        props: {
            xs: 0.75,
        },
        value: (el: TUser) => <Avatar src={el.pictures?.profile?.url} />,
    },
    {
        key: "name",
        label: "Navn",
        props: {
            xs: 3,
        },
        value: (el: TUser) => el.name,
    },
    {
        key: "role",
        label: "Rolle",
        props: {
            xs: 1,
        },
        value: (el: TUser) => capitalize(el.role),
    },
    {
        key: "phone",
        label: "Telefon",
        mobile: false,
        props: {
            xs: 1.8,
        },
        value: (el: TUser) => (
            <Box display={"flex"} alignItems={"center"}>
                <PhoneRounded fontSize={"small"} sx={{ mr: 1 }} />
                {el.phone}
            </Box>
        ),
    },
    {
        key: "email",
        label: "Email",
        value: (el: TUser) => (
            <Button component={"span"} sx={{ ...ellipsisStyle }} disabled variant={"contained"}>
                {el.email}
            </Button>
        ),
        valueProps: {
            fontWeight: 600,
        },
        props: {
            xs: 5.5,
            lg: 4,
        },
    },
    {
        key: "status",
        label: "Status",
        value: (el: TUser & { status: "active" | "invited" }) => <StatusChip status={el.status} />,
        valueProps: {
            fontWeight: 600,
        },
        props: {
            xs: true,
        },
    },
];

export const mapRoleToUsers = ({
    admins = [],
    viewers = [],
}: {
    admins?: any[];
    viewers?: any[];
}) => {
    return [
        ...admins.map((el) => {
            return { ...el, role: "admin" };
        }),
        ...viewers.map((el) => {
            return { ...el, role: "viewer" };
        }),
    ];
};

const UserPopover = ({
    id,
    name,
    email,
    pictures,
    phone,
    role,
    status,
    onUpdateRole,
    onDelete,
}: TUser & {
    status?: string;
    onDelete: (id: string) => void;
    onUpdateRole: (userId: string) => (evt: ChangeEvent<HTMLInputElement>) => void;
}) => {
    const { t } = useTranslate("settings.team.user");
    return (
        <Box p={3} minWidth={280}>
            <Box display={"flex"}>
                <Avatar src={pictures?.profile?.url} sx={{ width: 64, height: 64 }} />
                <Stack spacing={0.5} ml={1} justifyContent={"center"}>
                    <Typography variant={"h5"}>{name}</Typography>
                    <Typography variant={"body2"}>{email}</Typography>
                    <Typography variant={"body2"}>{phone}</Typography>
                </Stack>
            </Box>
            <Divider sx={{ mt: 2, mb: 3 }} />
            <Input
                fullWidth
                type={"select"}
                size={"small"}
                InputLabelProps={{ shrink: Boolean(role) }}
                onChange={onUpdateRole(id)}
                helperText={t("changeRoleHelperText")}
                label={t("role")}
                defaultValue={role}
                options={options}
            />
            <Divider sx={{ mt: 2, mb: 3 }} />
            <Stack spacing={1}>
                {status === "active" && (
                    <Button variant={"contained"} fullWidth href={`mailto:${email}`}>
                        {t("actions.writeTo", { userName: name?.split(" ")?.[0] ?? "" })}
                    </Button>
                )}

                <Button
                    variant={"contained"}
                    fullWidth
                    color={"error"}
                    onClick={() => onDelete(id)}
                    startIcon={<DeleteOutlineOutlined />}
                >
                    {t("actions.delete")}
                </Button>
            </Stack>
        </Box>
    );
};

const getInvitedUsers = (
    users: { name: string; email: string; role: string; invitationId: string }[]
) => {
    return users.map((el) => {
        const { email, name, invitationId: id, role } = el;
        return {
            email,
            name,
            id,
            role,
        };
    });
};

type MappedUser = TUser & { status: "active" | "invited"; invitationId: string };

const getRole = (email: string, admins?: { email: string }[]) => {
    return admins?.find((el: { email: string }) => el.email === email) ? "admin" : "viewer";
};

export const UsersList = ({
    onUpdate,
    ...props
}: BoxProps & { onUpdate: (users: any[]) => void }) => {
    const { t } = useTranslate("settings.team");

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const { users } = useUsers();
    const { company } = useProfile();

    const [openUser, setOpenUser] = useState(-1);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [_users, setUsers] = useState<MappedUser[]>([]);

    useEffect(() => {
        if (users.data && company.data) {
            const invitedUsers = mapRoleToUsers(company.data);
            const uniqueInvited = new Set(invitedUsers.map((u) => u.email));
            const userIds = company.data?.users ?? [];
            setUsers(
                Array.from(uniqueInvited)
                    .reduce<MappedUser[]>((all, inv) => {
                        const invited = invitedUsers.find(
                            (el) => el.email === inv && !userIds.includes(el.id)
                        );
                        if (!invited) {
                            return all;
                        }
                        const active = users.data?.find((el) => el.email === inv);
                        return [
                            ...all,
                            {
                                ...(active ?? invited),
                                invitationId: invited.id,
                                status: active ? "active" : "invited",
                                role: getRole(invited.email, company.data.admins),
                            },
                        ];
                    }, [])
                    .sort((a, b) => a.status.localeCompare(b.status))
            );
        }
    }, [company.data, users.data]);

    const handleClick = (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenUser(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenUser(-1);
    };

    const handleUpdateRole = (userId: string) => (evt: ChangeEvent<HTMLInputElement>) => {
        setUsers((p) => {
            const _new = p.map((el) => {
                return el.id === userId ? { ...el, role: evt.target.value as any } : el;
            });
            if (!_new.some((el) => el.role === "admin")) {
                return p;
            }
            onUpdate(getInvitedUsers(_new));
            return _new;
        });
    };

    const handleDeleteUser = (id: string) => {
        NiceModal.show(ConfirmDialog, {
            headline: t("headline", "dialogs.confirmDelete", {
                type: t("thisUser", "utils.generic"),
            }),
            title: t("title", "dialogs.confirmDelete"),
            confirmLabel: t("actions.primary", "dialogs.confirmDelete"),
        }).then(() => {
            setUsers((p) => {
                const _new = p.filter((el) => el.id !== id);
                onUpdate(getInvitedUsers(_new));
                return _new;
            });
            handleClose();
        });
    };

    return (
        <Box {...props}>
            <Grid container p={"12px"} mb={1}>
                {columns.map((el) => {
                    if (el.mobile === false && isMobile) {
                        return null;
                    }
                    return (
                        <Grid
                            fontSize={"0.75em"}
                            fontWeight={600}
                            textTransform={"uppercase"}
                            key={el.key}
                            item
                            {...el.props}
                            component={Typography}
                        >
                            {t(el.key, "utils.tables.header")}
                        </Grid>
                    );
                })}
            </Grid>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <UserPopover
                    {..._users[openUser]}
                    onDelete={handleDeleteUser}
                    onUpdateRole={handleUpdateRole}
                />
            </Popover>

            <Stack spacing={1}>
                {(users.isLoading || company.isLoading) && <ListSkeleton />}

                {_users?.map((user, index) => (
                    <Grid
                        container
                        component={Card}
                        p={1.5}
                        alignItems={"center"}
                        sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "rgb(252,252,252)" },
                        }}
                        onClick={handleClick(index)}
                    >
                        {columns.map((el) => {
                            if (el.mobile === false && isMobile) {
                                return null;
                            }
                            return (
                                <Grid
                                    fontSize={"0.88em"}
                                    key={el.key}
                                    item
                                    {...el.props}
                                    {...el.valueProps}
                                    component={Typography}
                                >
                                    {el.value?.(user)}
                                </Grid>
                            );
                        })}
                        <Grid item flexGrow={1} textAlign={"right"}>
                            <IconButton size={"small"}>
                                <MoreVert />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </Stack>
        </Box>
    );
};
