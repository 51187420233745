import { ComponentProps } from "react";
import clsx from "clsx";

export type MenuBarButtonProps = { active?: boolean } & ComponentProps<"button">;
export const MenuBarButton = ({ children, active = false, ...props }: MenuBarButtonProps) => {
    return (
        <button
            {...props}
            type={"button"}
            className={clsx(
                "rounded p-1 hover:bg-gray-200 border border-transparent self-stretch flex items-center",
                "disabled:pointer-events-none disabled:text-gray-400",
                active && "!border-gray-600",
                props.className,
            )}
        >
            {children}
        </button>
    );
};
