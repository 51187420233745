import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";

export type TVoucher = {
    id: string;
    price?: number;
    isCustomPrice?: boolean;
};

export type TBoughtVoucher = {
    code: string;
    customer: {
        id: string;
        name: string;
        email: string;
        phone?: string;
    };
    refund?: {
        timestamp: string;
    };
    expiresAt: string;
    id: string;
    amountLeft: number;
    originalAmount: number;
    receiptId: string;
    currency: string;
    createdDate: string;
};

export const useVoucher = (voucherId?: string) => {
    const queryClient = useQueryClient();

    const VouchersQueryKey = ["vouchers"];
    const VoucherQueryKey = ["voucher", voucherId];
    const BoughtVoucherQueryKey = ["voucher", "bought", voucherId];
    const BoughtVouchersQueryKey = ["vouchers", "bought"];

    const vouchers = useQuery<TVoucher[]>(
        VouchersQueryKey,
        async () => {
            await queryClient.cancelQueries(VouchersQueryKey);
            return await api.getVouchers();
        },
        {
            enabled: true,
        }
    );

    const boughtVouchers = useQuery<TBoughtVoucher[]>(
        BoughtVouchersQueryKey,
        async () => {
            await queryClient.cancelQueries(BoughtVouchersQueryKey);
            return (await api.getSoldVouchers()).sort((a, b) => {
                return Number(new Date(b.createdDate)) - Number(new Date(a.createdDate));
            });
        },
        {
            enabled: true,
        }
    );

    const boughtVoucher = useQuery<TBoughtVoucher>(
        BoughtVoucherQueryKey,
        () => {
            queryClient.cancelQueries(BoughtVoucherQueryKey);
            const vouchers = queryClient.getQueryData<TBoughtVoucher[]>(BoughtVouchersQueryKey);
            return vouchers?.find((el) => el.id === voucherId) ?? ({} as TBoughtVoucher);
        },
        {
            enabled: !!voucherId && Boolean(boughtVouchers.data),
        }
    );

    const voucher = useQuery<Partial<TVoucher>>(
        VoucherQueryKey,
        () => {
            queryClient.cancelQueries(VoucherQueryKey);
            const vouchers = queryClient.getQueryData<TVoucher[]>(VouchersQueryKey);
            return vouchers?.find((el) => el.id === voucherId) ?? {};
        },
        {
            enabled: !!voucherId && Boolean(vouchers.data),
        }
    );

    const updateVoucher = useMutation(
        ({ id, ...data }: TVoucher) => api.updateVoucher(id ?? voucherId, data),
        {
            onMutate: async (data) => {
                await queryClient.cancelQueries(VoucherQueryKey);

                const previous = queryClient.getQueryData<TVoucher>(VoucherQueryKey);

                queryClient.setQueryData<TVoucher[]>(VouchersQueryKey, (prev) => {
                    return prev!.map((el) => {
                        return el.id === data.id ? { ...data } : el;
                    });
                });

                queryClient.setQueryData<TVoucher>(VoucherQueryKey, (prev) => {
                    return { ...prev!, ...data };
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TVoucher>(VoucherQueryKey, context.previous);
                }
            },
            onSettled: async () => {
                queryClient.invalidateQueries(VouchersQueryKey);
            },
        }
    );

    const deleteVoucher = useMutation((id: string) => api.deleteVoucher(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(VouchersQueryKey);

            const previous = queryClient.getQueryData<TVoucher[]>(VoucherQueryKey);

            queryClient.setQueryData<TVoucher[]>(VouchersQueryKey, (prev) => {
                return prev!.filter((el) => el.id !== id);
            });

            return { previous };
        },
        onError: (err, variables, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<TVoucher>(VoucherQueryKey, context.previous);
            }
        },
        onSettled: async () => {
            queryClient.invalidateQueries(VouchersQueryKey);
            queryClient.invalidateQueries(VoucherQueryKey);
        },
    });

    const refundVoucher = useMutation(() => api.refundVoucher(voucherId!), {
        onMutate: async () => {
            const payload = { refund: { timestamp: new Date().toISOString() } };

            await queryClient.cancelQueries(BoughtVoucherQueryKey);

            const previous = queryClient.getQueryData<TBoughtVoucher>(BoughtVoucherQueryKey);

            queryClient.setQueryData<TBoughtVoucher[]>(BoughtVouchersQueryKey, (prev) => {
                return prev!.map((el) => {
                    return el.id === voucherId ? { ...el, ...payload } : el;
                });
            });

            queryClient.setQueryData<TBoughtVoucher>(BoughtVoucherQueryKey, (prev) => {
                return { ...prev!, ...payload };
            });

            return { previous };
        },
        onError: (err, variables, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<TBoughtVoucher>(BoughtVoucherQueryKey, context.previous);
            }
        },
        onSettled: async (data, err, variables, context: any) => {
            queryClient.invalidateQueries(BoughtVoucherQueryKey);
            queryClient.invalidateQueries(BoughtVouchersQueryKey);
        },
    });

    return {
        vouchers,
        boughtVouchers,
        voucher,
        boughtVoucher,
        updateVoucher,
        deleteVoucher,
        refundVoucher,
    };
};
