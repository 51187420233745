import Fetch from './index'

export type Domain = {
    type: 'custom' | 'subdomain'
    domain: string
}

export const domainAvailable = async (domain: string) => {
    if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DOMAINS_URL is not defined')
    }

    return Fetch.get<{ available: boolean }>(
        `${process.env.REACT_APP_API_DOMAINS_URL}${domain}/available`,
    )
}

export const createDomain = async (payload: Domain) => {
    if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DOMAINS_URL is not defined')
    }

    await Fetch.post(`${process.env.REACT_APP_API_DOMAINS_URL}`, { ...payload })
}

export const deleteDomain = async (domain: string) => {
    if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DOMAINS_URL is not defined')
    }

    await Fetch.delete(`${process.env.REACT_APP_API_DOMAINS_URL}${domain}`)
}

export const checkDomain = async (domain?: string) => {
    if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_DOMAINS_URL is not defined')
    }

    return await Fetch.get<{
        verified: boolean
        configured: boolean
        challenges: DomainChallenge[]
    }>(`${process.env.REACT_APP_API_DOMAINS_URL}${domain}/check`)
}

export type DomainChallenge = {
    domain: string
    reason?: string
    type: 'CNAME' | 'TXT'
    value: string
}
