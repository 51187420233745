import {useMutation, useQuery, useQueryClient} from "react-query";
import * as api from '../Api';
import {TTerms} from "../Api/Terms";
import {useOnBoarding} from "./useOnBoarding";

export const useTerms = () => {

    const queryClient = useQueryClient();

    const { updateOnBoardingStep } = useOnBoarding()

    const TermsQueryKey = ['terms'];

    const terms = useQuery(TermsQueryKey, async () => {
        await queryClient.cancelQueries(TermsQueryKey);
        return api.getTerms();
    }, {
        enabled: true,
        retry: false
    });

    const updateTerms = useMutation(
        ({type, payload}: {type: string, payload: {[lang: string]: { content: string }}}) => api.updateTerms(type, payload),
        {
            onMutate: async ({type, payload}) => {

                await queryClient.cancelQueries(TermsQueryKey);

                const previous =
                    queryClient.getQueryData(TermsQueryKey);


                queryClient.setQueryData<TTerms>(TermsQueryKey, (prev) => {
                    return {
                        ...prev,
                        [type]: {
                            ...(prev?.[type] ?? {}),
                            versions: Object.entries(payload).reduce((updated, [lang, latest]) => {
                                return {
                                    ...updated,
                                    [lang]: latest,
                                }
                            }, prev?.[type]?.versions ?? {}),

                        }
                    }
                })

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData(
                        TermsQueryKey,
                        context.previous
                    );
                }
            },
            onSuccess: (data, {type, payload}) => {
                updateOnBoardingStep.mutate({type: 'terms', payload: {
                        key: type,
                        progress: 100,
                    }
                })
            },
            onSettled: async () => {
                queryClient.invalidateQueries(TermsQueryKey)
            },
        }
    );

    return {
        terms,
        updateTerms
    }
}
