import Fetch, { IShallowSuccessResponse } from './index'
import { TBoughtVoucher, TVoucher } from '../Hooks/useVoucher'

export const getVouchers = async () => {
    if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_VOUCHERS_URL is not defined')
    }

    return Fetch.get<TVoucher[]>(`${process.env.REACT_APP_API_VOUCHERS_URL}company`)
}

export const getSoldVouchers = async () => {
    if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_VOUCHERS_URL is not defined')
    }

    return Fetch.get<TBoughtVoucher[]>(`${process.env.REACT_APP_API_VOUCHERS_URL}company/sold`)
}

export const updateVoucher = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_VOUCHERS_URL is not defined')
    }

    return Fetch.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_VOUCHERS_URL}company/${id}`,
        payload,
    )
}

export const deleteVoucher = async (id: string) => {
    if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_VOUCHERS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_VOUCHERS_URL}company/${id}`,
    )
}

export const refundVoucher = async (id: string) => {
    if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_VOUCHERS_URL is not defined')
    }

    return Fetch.post<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_VOUCHERS_URL}refund/${id}`,
    )
}
