import {
    Box,
    BoxProps,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { format } from "date-fns";
import { Header } from "./Header";
import { ConfirmDialog } from "./ConfirmDialog";
import { useTranslate } from "../Hooks/useTranslate";
import { SendIcon } from "../Icons";
import React, { ChangeEventHandler, useRef, useState } from "react";
import { AttachFileOutlined } from "@mui/icons-material";
import { IDropzoneProps, useUploader } from "../Hooks/useUploader";
import { useConfirmDialog } from "../Hooks/useConfirmDialog";

export type WriteParticipantsDialogProps = {
    startDateTime: string;
    headline: string;
    eventId: string;
    participantCount: number;
    defaultMessage?: string;
    defaultAttachments?: Attachment[];
    defaultSendCopyToMe?: boolean;
    excludes?: string[];
};

type Attachment = {
    key: string;
    url: string;
    name: string;
    execUpload?: () => Promise<void>;
    cleanUp?: () => void;
};

export type WriteParticipantsDialogReturnValue = Omit<
    WriteParticipantsDialogProps,
    "defaultMessage" | "defaultAttachments" | "defaultSendCopyToMe"
> & {
    message: string;
    sendCopyToMe: boolean;
    attachments?: Pick<Attachment, "key" | "url" | "name">[];
};
export const WriteParticipantsDialog = NiceModal.create<WriteParticipantsDialogProps>(
    ({
        eventId,
        headline,
        participantCount,
        startDateTime,
        defaultMessage,
        defaultAttachments,
        defaultSendCopyToMe,
        excludes,
    }) => {
        const { t } = useTranslate("dialogs.writeParticipants");

        const [message, setMessage] = useState(defaultMessage ?? "");
        const [attachments, setAttachments] = useState<Attachment[]>(defaultAttachments ?? []);
        const [sendCopyToMe, setSendCopyToMe] = useState(defaultSendCopyToMe ?? true);

        const modal = useModal();

        const { confirm } = useConfirmDialog(
            t("headline", "dialogs.unsavedChanges"),
            t("title", "dialogs.unsavedChanges")
        );
        const handleClose = async (disableConfirm = false) => {
            let confirmed = true;
            if ((message || attachments.length > 0) && !disableConfirm) {
                confirmed = Boolean(await confirm());
            }
            if (confirmed) {
                modal.reject();
                modal.remove();
            }
        };

        const handleSubmit = async () => {
            try {
                await NiceModal.show(ConfirmDialog, {
                    title: t("title", "dialogs.confirmWriteParticipants"),
                    headline: t("headline", "dialogs.confirmWriteParticipants", {
                        participantCount,
                    }),
                    confirmLabel: t("confirmLabel", "dialogs.confirmWriteParticipants"),
                });

                await Promise.allSettled(attachments.map((el) => el.execUpload?.())).finally(() =>
                    attachments[0]?.cleanUp?.()
                );

                modal.resolve({
                    message,
                    headline,
                    participantCount,
                    startDateTime,
                    eventId,
                    sendCopyToMe,
                    ...(attachments.length > 0 && {
                        attachments: attachments.map(({ key, url, name }) => ({ url, key, name })),
                    }),
                });
                await handleClose(true);
            } catch {}
        };

        const handleAddAttachment = (attachment: Attachment) => {
            setAttachments((p) => [...p, attachment]);
        };

        const handleDeleteAttachment = (key: string) => {
            setAttachments((p) => p.filter((el) => el.key !== key));
        };

        return (
            <Dialog fullWidth maxWidth={"sm"} open={modal.visible} onClose={() => handleClose()}>
                <Box pl={4}>
                    <Header title={t("title")} onClose={() => handleClose()} />
                    <Box pr={4}>
                        <Typography mt={2} variant={"h5"}>
                            {t("subtitle", { participantCount })}
                            {excludes && excludes.length > 0 && (
                                <Typography
                                    pl={1}
                                    display={"inline-block"}
                                    color={"green"}
                                    variant={"h6"}
                                >
                                    (ud af {participantCount + excludes.length} hvoraf{" "}
                                    {excludes.length} blev sendt)
                                </Typography>
                            )}
                        </Typography>

                        <Typography variant={"body2"} display={"block"} mt={0.5} mb={0.2}>
                            {headline}
                        </Typography>
                        <Typography variant={"body2"} display={"block"}>
                            {format(new Date(startDateTime), "PPPp")}
                        </Typography>

                        <TextField
                            fullWidth
                            sx={{
                                mt: 2,
                                "& fieldset": {
                                    borderColor: "transparent",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#3A3A3C",
                                    fontWeight: 600,
                                },
                            }}
                            InputProps={{
                                sx: {
                                    backgroundColor: "#F2F2F7",
                                },
                            }}
                            multiline
                            minRows={14}
                            defaultValue={message}
                            onChange={(evt) => setMessage(evt.target.value)}
                            label={t("placeholder")}
                        />

                        <FormControlLabel
                            sx={{ mt: 1 }}
                            onChange={(_evt, checked) => setSendCopyToMe(checked)}
                            checked={sendCopyToMe}
                            control={<Checkbox />}
                            label={"Send kopi til mig selv"}
                        />

                        <Attachments
                            mt={2}
                            subjectId={eventId}
                            onAddAttachment={handleAddAttachment}
                            onDeleteAttachment={handleDeleteAttachment}
                        />

                        <Stack
                            direction={"row"}
                            spacing={1.5}
                            mb={4}
                            mt={4}
                            justifyContent={"space-between"}
                        >
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                onClick={() => handleClose()}
                            >
                                {t("actions.secondary")}
                            </Button>
                            <Button
                                variant={"contained"}
                                onClick={handleSubmit}
                                startIcon={<SendIcon />}
                            >
                                {t("actions.primary")}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Dialog>
        );
    }
);

type AttachmentsProps = {
    subjectId: string;
    onAddAttachment: (attachment: Attachment) => void;
    onDeleteAttachment: (key: string) => void;
} & BoxProps;
const Attachments = ({
    subjectId,
    onAddAttachment,
    onDeleteAttachment,
    ...props
}: AttachmentsProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [files, setFiles] = useState<{ name: string; key: string }[]>([]);

    const onPrepared: IDropzoneProps["onPrepared"] = (
        upload,
        _localUrl,
        _fileType,
        execUpload,
        cleanUp,
        fileName
    ) => {
        setFiles((p) => [...(p ?? []), { name: fileName, key: upload.key }]);
        onAddAttachment({ ...upload, execUpload, cleanUp, name: fileName });
    };

    const { onDrop, loading } = useUploader({
        fileType: "attachment",
        modelType: "event",
        id: subjectId,
        onPrepared,
        multiple: true,
    });

    const handleClick = () => {
        inputRef.current?.click();
    };

    const handleDeleteFile = (key: string) => () => {
        setFiles((p) => p?.filter((el) => el.key !== key));
        onDeleteAttachment(key);
    };

    const handleAddFiles: ChangeEventHandler<HTMLInputElement> = async (evt) => {
        if (evt.target.files && evt.target.files?.length > 0) {
            await onDrop(Array.from(evt.target.files));
        }
    };

    return (
        <Box {...props}>
            {files.length > 0 && (
                <Stack spacing={1} mb={2} alignItems={"flex-start"}>
                    {files.map((el) => (
                        <Chip key={el.key} label={el.name} onDelete={handleDeleteFile(el.key)} />
                    ))}
                </Stack>
            )}
            <Button
                disabled={Boolean(loading)}
                startIcon={loading ? <CircularProgress size={"1em"} /> : <AttachFileOutlined />}
                onClick={handleClick}
            >
                Vedhæft fil{" "}
                <input ref={inputRef} onChange={handleAddFiles} type={"file"} multiple hidden />
            </Button>
        </Box>
    );
};
