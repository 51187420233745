import {
    Avatar,
    Box,
    BoxProps,
    Button,
    Card,
    Divider,
    IconButton,
    Stack,
    StackProps,
    Typography,
} from "@mui/material";
import { useProfile } from "../../Hooks/useProfile";
import React, { ReactNode, useState } from "react";
import { useTranslate } from "../../Hooks/useTranslate";
import {
    CancelRounded,
    CheckRounded,
    DeleteOutlineOutlined,
    EditOutlined,
} from "@mui/icons-material";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { Author, EnrichedRecord, useRecords } from "../../Hooks/useRecords";
import { SimpleSkeleton } from "../SimpleSkeleton/SimpleSkeleton";
import { RichText } from "@spiritworld-dk/ui-core";
import "../../RichText.css";
import { Editor } from "../Editor/Editor";

const useRecordsHandlers = (
    subject: EnrichedRecord["subject"],
    subjectId: string,
    authorId?: string
) => {
    const [text, setText] = useState<string | null>(null);
    const [metadata, setMetadata] = useState<EnrichedRecord["metadata"] | null>(null);

    const {
        records: { isLoading },
        enrichedRecords: records,
        updateRecord,
        deleteRecord,
        createRecord,
    } = useRecords(subject, subjectId);

    const handleSave = () => {
        if (text && authorId) {
            createRecord.mutateAsync({
                text: text.trim(),
                subjectId,
                subject,
                authorId,
                ...(metadata && {
                    metadata,
                }),
            });
            setText(null);
        }
    };

    const handleCancel = () => {
        setText(null);
    };

    const handleUpdate = (id: string) => (text: string) => {
        return updateRecord.mutateAsync({ id, subject, subjectId, text });
    };

    const handleDelete = (id: string) => () => {
        if (window.confirm("Er du sikker på at slette?")) {
            return deleteRecord.mutateAsync({ id });
        }
    };

    const handleChange = (value: string) => {
        setText(value);
    };

    return {
        handleChange,
        handleDelete,
        handleCancel,
        handleUpdate,
        handleSave,
        handleUpdateMetadata: setMetadata,
        isLoading,
        records,
        text,
        metadata,
    };
};

export const Records = ({
    subject,
    subjectId,
    hideSkeleton = false,
    hideAuthor = false,
    renderAddRecord,
    renderMetadata,
    shouldToggleAddRecord = false,
    renderMetadataControl,
    ...props
}: Omit<BoxProps, "onSubmit"> &
    Pick<EnrichedRecord, "subject" | "subjectId"> & {
        hideSkeleton?: boolean;
        hideAuthor?: boolean;
        shouldToggleAddRecord?: boolean;
        renderAddRecord?: (props: {
            value: string;
            handleChange: (value: string) => void;
        }) => ReactNode;
        renderMetadata?: (metadata: EnrichedRecord["metadata"]) => ReactNode;
        renderMetadataControl?: (props: {
            handleUpdateMetadata: (metadata: EnrichedRecord["metadata"]) => void;
            metadata: EnrichedRecord["metadata"];
        }) => ReactNode;
    }) => {
    const { me } = useProfile();

    const [willAdd, setWillAdd] = useState(false);

    const {
        handleChange,
        handleDelete,
        handleCancel,
        handleUpdate,
        handleSave,
        handleUpdateMetadata,
        isLoading,
        records,
        text,
    } = useRecordsHandlers(subject, subjectId, me.data?.id);

    const onCancel = () => {
        handleCancel();
        if (shouldToggleAddRecord) {
            setWillAdd(false);
        }
    };

    const showAddControl =
        (shouldToggleAddRecord && willAdd) ||
        (records && records?.length < 2) ||
        !shouldToggleAddRecord;

    return (
        <Box {...props}>
            {showAddControl && (
                <Stack mb={4} direction={"row"} spacing={2}>
                    {!hideAuthor && <Avatar src={me.data?.pictures?.profile?.url} />}
                    <Box flexGrow={1}>
                        {renderMetadataControl?.({ handleUpdateMetadata, metadata: {} })}
                        {renderAddRecord?.({ handleChange, value: text ?? "" })}
                        <Box>
                            {Boolean(text) && (
                                <ConfirmButtons
                                    onSave={handleSave}
                                    onCancel={onCancel}
                                    mt={1}
                                    saveLabel={"Opret note"}
                                />
                            )}
                        </Box>
                    </Box>
                </Stack>
            )}
            {!showAddControl && (
                <Button
                    size={"large"}
                    sx={{ mb: 4 }}
                    variant={"outlined"}
                    onClick={() => setWillAdd(true)}
                    color={"secondary"}
                >
                    Tilføj note
                </Button>
            )}

            <Stack spacing={2}>
                {isLoading && !hideSkeleton ? (
                    <SimpleSkeleton />
                ) : (
                    records?.map((el) => (
                        <Entry
                            onUpdate={handleUpdate(el.id)}
                            onDelete={handleDelete(el.id)}
                            key={el.id}
                            allowEdit={me.data?.id === el.author?.id}
                            created={(el as any).created}
                            author={el.author}
                            text={el.text}
                        >
                            {renderMetadata?.(el.metadata)}
                        </Entry>
                    ))
                )}
            </Stack>
        </Box>
    );
};

export const ConfirmButtons = ({
    onSave,
    onCancel,
    saveLabel,
    cancelLabel,
    hideIcons = false,
    ...props
}: StackProps & {
    onSave: () => void;
    onCancel: () => void;
    saveLabel?: string;
    cancelLabel?: string;
    hideIcons?: boolean;
}) => {
    const { t } = useTranslate("utils");
    return (
        <Stack direction={"row"} spacing={1} alignItems={"center"} {...props}>
            <Button startIcon={!hideIcons && <CheckRounded />} color={"success"} onClick={onSave}>
                {saveLabel ?? t("save", "buttons")}
            </Button>
            <Button startIcon={!hideIcons && <CancelRounded />} color={"error"} onClick={onCancel}>
                {cancelLabel ?? t("undo", "buttons")}
            </Button>
        </Stack>
    );
};

export const Entry = ({
    author,
    allowEdit,
    onDelete,
    onUpdate,
    text,
    created,
    children,
}: BoxProps & {
    allowEdit: boolean;
    created: string;
    onDelete: () => void;
    onUpdate: (text: string) => void;
    author: Author | undefined;
    text: string;
}) => {
    const [editing, setEditing] = useState(false);

    const [editedNote, setEditedNote] = useState<string | null>(null);

    const handleEdit = () => setEditing(true);

    const handleSave = () => {
        if (editedNote) {
            onUpdate(editedNote);
            setEditedNote(null);
            setEditing(false);
        }
    };

    const handleCancel = () => {
        setEditedNote(null);
        setEditing(false);
    };

    return (
        <Stack
            component={Card}
            direction={"row"}
            divider={<Divider orientation={"vertical"} flexItem />}
            spacing={2}
            sx={{
                "&:hover .record-buttons": {
                    opacity: 1,
                    pointerEvents: "initial",
                },
            }}
        >
            {!editing && (
                <Box minWidth={130}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Avatar src={author?.picture} />
                        {allowEdit && !editing && (
                            <Stack
                                direction={"row"}
                                spacing={0.5}
                                sx={{
                                    opacity: 0,
                                    pointerEvents: "none",
                                    transition: "opacity 0.15s",
                                }}
                                className={"record-buttons"}
                            >
                                <IconButton size={"small"} onClick={onDelete}>
                                    <DeleteOutlineOutlined fontSize={"small"} />
                                </IconButton>
                                <IconButton size={"small"} onClick={handleEdit}>
                                    <EditOutlined fontSize={"small"} />
                                </IconButton>
                            </Stack>
                        )}
                    </Box>
                    <Typography variant={"h5"} mt={1} mb={1}>
                        {author?.name}
                    </Typography>
                    <Typography variant={"h5"} fontSize={"0.75em"}>
                        {renderDateTime(created)}
                    </Typography>
                    {children}
                </Box>
            )}
            {editing ? (
                <Box flexGrow={1} display={"flex"} flexDirection={"column"}>
                    <Editor onChange={(value) => setEditedNote(value)} defaultValue={text} />
                    <ConfirmButtons
                        mt={1}
                        hideIcons
                        onSave={handleSave}
                        saveLabel={"Gem"}
                        onCancel={handleCancel}
                    />
                </Box>
            ) : (
                <RichText value={text} />
            )}
        </Stack>
    );
};
