import {
    Box,
    BoxProps,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { useController, useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import { DeleteForeverRounded } from "@mui/icons-material";
import { Tip } from "../../../Components/Tip/Tip";
import {
    OptionGroup,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import { ArrayContainer } from "../../../Components/ArrayContainer/ArrayContainer";
import { SyiSection } from "../../SyiPage/SyiSection";
import React, { useMemo } from "react";
import { mapSelectedLanguages, SOURCE_LANG } from "./ServiceSyiSectionDescription";
import randomBytes from "randombytes";
import { useErrors } from "../../SyiPage/useErrors";
import { TranslatableGroup } from "../../../Components/TranslatableGroup/TranslatableGroup";
import { Input, TInputProps } from "../../../Components/Input/Input";
import { defaultTaxOption, taxOptions } from "../../../Utils/taxOptions";
import { durationOptions, languagesOptions, paddingOptions } from "../../../Utils/config";
import { useTranslation } from "react-i18next";
import { useProfile } from "../../../Hooks/useProfile";
import { translateOptions } from "../../../Utils/helpers";
import { useTranslate } from "../../../Hooks/useTranslate";
import { OptionChips } from "../../../Components/OptionChips/OptionChips";
import { TOptionItem } from "../../../Utils/types";
import { OpeningHoursControl } from "../../../Components/OpeningHoursControl/OpeningHoursControl";
import { useServiceType } from "../../../Hooks/useServiceType";
import { useLanguages } from "../../../Hooks/useLanguages";
import { ErrorLabel } from "../../SyiPage/ErrorLabel";

const anId = () => randomBytes(16).toString("hex");

export const cancellationFeeOptions = [
    {
        key: "no",
        label: "Nej, mine kunder kan få det fulde beløb refunderet i forbindelse med en aflysning",
    },
    {
        key: "yes",
        label: "Ja, lad mig definere",
    },
];

export const introSessionOptions = [
    {
        key: "no",
        label: "Nej, ingen forsamtale af nye kunder, nye kunder booker direkte i min kalender.",
    },
    {
        key: "yes",
        label: "Ja, jeg vil gerne tilbyde en gratis forsamtale, lad mig definere hvordan",
    },
];

type TElement = { type: "input" | "editor"; props?: TInputProps };

export const ServiceSyiSectionPrice = () => {
    const { t } = useTranslate("service.create.price");

    const { register, setValue } = useFormContext();

    const { type: serviceType } = useServiceType();

    const { getError, withClear, clearError } = useErrors();

    const { defaultCurrency } = useProfile();

    const { field: cancellationFee } = useController({
        name: "cancellationFee.yes",
        defaultValue: null,
    });

    const languages = useWatch({ name: "languages" }) ?? [SOURCE_LANG];

    const duration = useWatch({ name: "session.duration" });

    const variantToggleOptions = useMemo(() => {
        const opts: {
            key: string;
            element: TElement;
            label: (i: number) => string;
        }[] = [
            {
                key: "explanation",
                label: () => t("tickets.options.true.explanation.title"),
                element: {
                    type: "editor",
                    props: {
                        placeholder: t("tickets.options.true.explanation.inputPlaceholder"),
                        fullWidth: true,
                        minRows: 2,
                        multiline: true,
                    },
                },
            },
            {
                key: "vatId",
                label: () => t("tickets.options.true.vat.title"),
                element: {
                    type: "input",
                    props: {
                        label: t("tickets.options.true.vat.inputPlaceholder"),
                        helperText: t("tickets.options.true.vat.inputHelpText"),
                        defaultValue: defaultTaxOption,
                        type: "select",
                        sx: { maxWidth: 240 },
                        options: taxOptions as any[],
                    },
                },
            },
        ];
        if (serviceType === "session") {
            opts.splice(1, 0, {
                key: "slotDuration",
                label: () => t("tickets.options.true.slotDuration.title"),
                element: {
                    type: "input",
                    props: {
                        label: t("tickets.options.true.slotDuration.inputPlaceholder"),
                        helperText: "",
                        defaultValue: duration ?? 60,
                        type: "select",
                        sx: { maxWidth: 240 },
                        options: durationOptions.map((d) => ({
                            key: d,
                            label: `${d.toString(10)} minutter`,
                        })),
                    },
                },
            });
        }
        if (serviceType !== "session") {
            opts.splice(1, 0, {
                key: "max",
                label: () => t("tickets.options.true.max.title"),
                element: {
                    type: "input",
                    props: {
                        placeholder: t("tickets.options.true.max.inputPlaceholder"),
                        type: "number",
                    },
                },
            });
        }
        return opts;
    }, []) as { key: string; element: TElement; label: (i: number) => string }[];

    const addonToggleOptions: { key: string; element: TElement; label: (i: number) => string }[] = [
        {
            key: "explanation",
            label: (i) => `${t("tickets.addon.explanation.title")} ${i}`,
            element: {
                type: "editor",
                props: {
                    placeholder: t("tickets.addon.explanation.inputPlaceholder"),
                    fullWidth: true,
                    minRows: 2,
                    multiline: true,
                },
            },
        },
        {
            key: "vatId",
            label: (i) =>
                t("tickets.addon.adjustVat.title", {
                    index: i,
                    defaultVat: `25%`,
                }),
            element: {
                type: "input",
                props: {
                    label: t("tickets.addon.adjustVat.inputPlaceholder"),
                    helperText: t("tickets.addon.adjustVat.inputHelpText"),
                    defaultValue: defaultTaxOption,
                    type: "select",
                    sx: { maxWidth: 240 },
                    options: taxOptions,
                },
            },
        },
    ];

    const getLocalized = (text: string) => {
        return { [languages?.[0] ?? "da"]: text };
    };

    return (
        <>
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Tip filled={true} label={t("titleHelpText")} />

            <Stack mt={2} divider={<Divider />} spacing={4}>
                <ErrorLabel label={getError("variants")} />
                <ArrayContainer
                    max={10}
                    addButtonProps={{
                        label: t("tickets.options.true.buttonLabel"),
                    }}
                    controllerKey={"variants"}
                    defaultValue={(newIndex) => ({
                        name: getLocalized(`${t("tickets.utils.ticketType")} ${newIndex}`),
                        price: 300,
                    })}
                    onAdd={() => clearError("variants")}
                    renderItem={(el, i, { onDeleteItem }) => (
                        <VariantItem
                            index={i}
                            key={`variants.${i}`}
                            register={register}
                            nameInputProps={{
                                helperText: t("tickets.options.true.inputHelpText"),
                                label: `${t("tickets.options.true.inputPlaceholder")}`,
                            }}
                            priceInputProps={{
                                label: `${t("tickets.options.true.inputPrice", {
                                    currency: t(`${defaultCurrency}`, "utils.currency"),
                                })}`,
                            }}
                            setValue={setValue}
                            toggleOptions={variantToggleOptions}
                            parentKey={"variants"}
                            mt={3}
                            onDelete={onDeleteItem}
                        >
                            {serviceType === "event" && (
                                <ArrayContainer
                                    addButtonProps={{
                                        label: t("tickets.addon.buttonLabel"),
                                    }}
                                    controllerKey={`variants.${i}.addons`}
                                    defaultValue={(newIndex) => ({
                                        name: getLocalized(
                                            `${t("tickets.addon.name")} ${newIndex}`
                                        ),
                                    })}
                                    renderItem={(el, index, { onDeleteItem: _onDeleteItem }) => (
                                        <VariantItem
                                            index={index}
                                            withContainer={false}
                                            mt={3}
                                            key={`variants.${i}.addons.${index}`}
                                            toggleOptions={addonToggleOptions}
                                            nameInputProps={{
                                                helperText: t("tickets.addon.inputHelpText"),
                                                label: `${t("tickets.addon.name")} ${index + 1}`,
                                                sx: {
                                                    fieldset: {
                                                        borderStyle: "dashed",
                                                    },
                                                },
                                            }}
                                            priceInputProps={{
                                                label: `${t("tickets.addon.price", {
                                                    currency: t(
                                                        `${defaultCurrency}`,
                                                        "utils.currency"
                                                    ),
                                                })}`,
                                                sx: {
                                                    fieldset: {
                                                        borderStyle: "dashed",
                                                    },
                                                },
                                            }}
                                            register={register}
                                            setValue={setValue}
                                            parentKey={`variants.${i}.addons`}
                                            onDelete={_onDeleteItem}
                                        />
                                    )}
                                />
                            )}
                        </VariantItem>
                    )}
                />
                {/*<SyiSection title={t("cancellationFee.title")} error={getError("cancellationFee")}>
                    <OptionGroup
                        name={"cancellationFee.selectedOptionKey"}
                        options={translateOptions(t, "cancellationFee", cancellationFeeOptions)}
                        onChangeValue={() => clearError("cancellationFee")}
                    >
                        <OptionGroupSelectedSection optionKey={"yes"} mt={2}>
                            <TextField
                                sx={{ minWidth: 320, maxWidth: 320 }}
                                label={t("cancellationFee.options.yes.inputPlaceholder")}
                                type={"number"}
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                }}
                                defaultValue={cancellationFee.value?.rate}
                                onChange={withClear("cancellationFee.rate", (val) => {
                                    cancellationFee.onChange({
                                        percentage: true,
                                        rate: parseInt(val.target.value),
                                    });
                                })}
                                helperText={t("cancellationFee.options.yes.inputHelpText")}
                            />
                        </OptionGroupSelectedSection>
                    </OptionGroup>
                </SyiSection>*/}

                {serviceType === "session" && (
                    <SyiSection
                        title={t("introSession.title")}
                        error={getError("session.introSession")}
                    >
                        <OptionGroup
                            name={"session.introSession.selectedOptionKey"}
                            options={translateOptions(t, "introSession", introSessionOptions)}
                            onChangeValue={() => clearError("session.introSession")}
                        >
                            <OptionGroupSelectedSection optionKey={"yes"} mt={4}>
                                <IntroSessionControl parentKey={"session"} />
                            </OptionGroupSelectedSection>
                        </OptionGroup>
                    </SyiSection>
                )}
            </Stack>
        </>
    );
};

const _channelOptions: TOptionItem[] = [
    {
        label: "Telefonisk",
        key: "byPhone",
        icon: "/icons/channels/by-phone.svg",
        width: 18,
        height: 18,
    },
    { label: "Online", key: "online", icon: "/icons/channels/online.svg", width: 18, height: 18 },
    {
        label: "Fysisk",
        key: "inPerson",
        icon: "/icons/channels/in-person.svg",
        width: 34,
        height: 16,
    },
];

const IntroSessionControl = ({ parentKey }: { parentKey?: string }) => {
    const { t } = useTranslate("service.create.price.introSession");

    const pKey = (parentKey && `${parentKey}.`) || "";

    const { field: channels } = useController({
        name: `${pKey}introSession.channels`,
        defaultValue: ["byPhone"],
    });

    const { field: duration } = useController({
        name: `${pKey}introSession.duration`,
        defaultValue: 15,
    });

    const channelOptions = _channelOptions.map((el) => ({
        ...el,
        label: t(el.key, "utils.channels"),
    }));

    return (
        <Stack spacing={4}>
            <SyiSection typographyProps={{ mb: 3 }} title={t("channelsTitle")}>
                <OptionChips
                    onChange={channels.onChange}
                    selectedOptions={channels.value}
                    allOptions={channelOptions}
                />
            </SyiSection>
            <SyiSection typographyProps={{ mb: 3 }} title={t("durationTitle")}>
                <Select
                    labelId="duration-select-label"
                    id="duration-select"
                    value={duration.value}
                    onChange={duration.onChange}
                >
                    {paddingOptions.map((el) => (
                        <MenuItem value={el}>Ca. {el} minutter</MenuItem>
                    ))}
                </Select>
            </SyiSection>
            <SyiSection typographyProps={{ mb: 3 }} title={t("openingHoursTitle")}>
                <OpeningHoursControl global={true} propKey={`${pKey}introSession.openingHours`} />
            </SyiSection>
        </Stack>
    );
};

const VariantItem = ({
    register,
    withContainer = true,
    toggleOptions,
    children,
    onDelete,
    setValue,
    priceInputProps,
    nameInputProps,
    index,
    parentKey,
    ...props
}: BoxProps & {
    toggleOptions?: {
        key: string;
        element: TElement;
        label: (i: number) => string;
    }[];
    withContainer?: boolean;
    onDelete: () => void;
    priceInputProps?: TextFieldProps;
    nameInputProps?: TextFieldProps;
    index: number;
    parentKey: string;
    register: UseFormReturn["register"];
    setValue: UseFormReturn["setValue"];
}) => {
    const { t } = useTranslation();

    const id = anId();

    useController({ name: `${parentKey}.${index}.id`, defaultValue: id });

    const { getError, clearError } = useErrors();

    const languages = useLanguages();

    const containerProps: BoxProps = withContainer
        ? {
              border: "1px solid #C7C7CC",
              borderRadius: 3,
              p: 3,
              pr: 0,
              position: "relative",
          }
        : {};

    return (
        <Box {...containerProps} {...props}>
            {withContainer && (
                <Typography
                    variant={"h5"}
                    sx={{
                        position: "absolute",
                        left: 20,
                        top: -12,
                        backgroundColor: "white",
                        pl: 0.5,
                        pr: 0.5,
                    }}
                >{`${t("utils.generic.ticketType")} ${index + 1}`}</Typography>
            )}
            <Box display={"flex"} position={"relative"} justifyContent={"space-between"} mb={1.5}>
                <Typography variant={"h5"} mt={1.5}>
                    {nameInputProps?.label}
                    <ErrorLabel mt={1} label={getError(`${parentKey}[${index}].name`)} />
                </Typography>
            </Box>
            <Grid container columns={11} spacing={2} alignItems={"flex-start"}>
                <Grid item flexGrow={1} ml={withContainer ? 0 : 3}>
                    <TranslatableGroup
                        mt={languages.length > 1 ? -5.2 : 0}
                        clearError={clearError}
                        propKey={`${parentKey}[${index}].name`}
                        input={{
                            type: "input",
                            placeholder: nameInputProps?.helperText as string,
                            props: nameInputProps,
                        }}
                        langs={mapSelectedLanguages(languages, languagesOptions)}
                    />
                </Grid>
                <Grid item xs={4} display={"inline-flex"} alignItems={"center"}>
                    <TextField
                        type={"number"}
                        onInput={() => clearError(`${parentKey}[${index}].price`)}
                        error={Boolean(getError(`${parentKey}[${index}].price`))}
                        helperText={getError(`${parentKey}[${index}].price`) ?? " "}
                        {...register(`${parentKey}.${index}.price`, { valueAsNumber: true })}
                        {...priceInputProps}
                    />
                    <IconButton onClick={onDelete} sx={{ ml: 1, mr: 2, mb: "18px" }}>
                        <DeleteForeverRounded />
                    </IconButton>
                </Grid>
            </Grid>

            {toggleOptions && (
                <Stack mt={2} ml={withContainer ? 0 : 3}>
                    {toggleOptions.map((el, i) => (
                        <CheckboxOption
                            pr={3}
                            key={el.key}
                            name={el.key}
                            element={el.element}
                            parentKey={`${parentKey}.${index}`}
                            label={el.label(index + 1)}
                        />
                    ))}
                </Stack>
            )}

            {children}
        </Box>
    );
};

const CheckboxOption = ({
    name,
    label,
    children,
    parentKey,
    element,
    ...props
}: BoxProps & { name: string; parentKey: string; element: TElement; label: string }) => {
    const { field: toggle } = useController({
        name: `${parentKey}.${name}`,
        defaultValue: element.props?.defaultValue,
    });

    return (
        <Box {...props}>
            <FormControlLabel
                componentsProps={{ typography: { fontSize: "0.88rem" } }}
                control={<Checkbox />}
                label={label}
                checked={Boolean(toggle.value)}
                {...toggle}
                onChange={(_evt, checked) =>
                    toggle.onChange(checked ? element.props?.defaultValue ?? true : null)
                }
            />

            {Boolean(toggle.value) && (
                <CheckboxOptionInput {...element} name={`${parentKey}.${name}`} />
            )}
        </Box>
    );
};

const CheckboxOptionInput = ({ name, type, props }: TElement & { name: string }) => {
    const { control } = useFormContext();

    const { field } = useController({ name, control, defaultValue: props?.defaultValue });

    const languages = useLanguages();

    return type === "input" ? (
        <Box mt={1.5} mb={1.5}>
            <Input {...props} {...field} />
        </Box>
    ) : (
        <TranslatableGroup
            mt={languages?.length > 1 ? -4 : 0}
            mb={1.5}
            propKey={name}
            input={{
                type: "input",
                props,
            }}
            langs={mapSelectedLanguages(languages, languagesOptions)}
        />
    );
};
