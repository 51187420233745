import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { IService, useService } from "./useService";
import { useMemo } from "react";
import {
    LocalizeFunc,
    useLocalizer,
} from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { format, formatDistance } from "date-fns";
import { TUser, useUsers } from "./useUsers";
import { createId } from "../Pages/SyiPage/SyiPage";
import { CalendarIcon } from "../Icons";
import { FacebookRounded, PhoneRounded } from "@mui/icons-material";
import { sortByLatestCreated } from "../Utils/helpers";

export type TNote<T extends string | object = string> = {
    id: string;
    created: string;
    updated: string;
    author: T;
    text: string;
};

export type ContactType = "email" | "phone" | "sms";

export type TLead = {
    created: string;
    updated: string;
    serviceId: string;
    id: string;
    type: "introSession" | "callMe" | "firstTimeVoucher" | "metaInstantForm";
    status: "new" | "closed" | "rejected" | "pending" | "active";
    customer: {
        id?: string;
        name: string;
        phone: string;
        email: string;
    };
    booking?: {
        startDateTime: string;
        endDateTime: string;
    };
    contacted?: {
        type: ContactType;
        timestamp: string;
    };
    offer?: {
        id: string;
        created: string;
        expiryDate: string;
        receiptId?: string;
    };
    channel: string;
    variantId?: string;
    funnelId?: string;
    campaignId?: string;
    internalNotes?: TNote[];
    source?: string; // facebook | website | google
};

export type EnrichedLead = {
    service: {
        name: string;
        picture: string;
    };
    booking: {
        id?: string;
        startDateTime: string;
        endDateTime: string;
    };
    internalNotes?: TNote<{ name: string; picture: string; id: string }>[];
} & TLead;

export const renderLeadService = (lead: EnrichedLead) => {
    switch (lead.type) {
        case "introSession":
            const duration =
                lead.booking &&
                formatDistance(
                    new Date(lead.booking.startDateTime),
                    new Date(lead.booking.endDateTime)
                );
            return `Gratis forsamtale\n${duration}`;
    }
    return lead.service?.name ?? "";
};

export const renderLeadType = (lead: TLead) => {
    switch (lead.type) {
        case "introSession":
            return {
                label: `I KALENDER\n${
                    lead.booking?.startDateTime
                        ? format(new Date(lead.booking?.startDateTime), "dd.MM.yyyy")
                        : ""
                }`,
                Icon: CalendarIcon,
            };
        case "callMe":
            return {
                label: `RING MIG OP`,
                Icon: PhoneRounded,
            };
        case "firstTimeVoucher":
            return {
                label: `FACEBOOK TILBUD`,
                Icon: FacebookRounded,
            };
        case "metaInstantForm":
            return {
                label: `FACEBOOK LEAD`,
                Icon: FacebookRounded,
            };
    }
    return {
        label: ``,
        Icon: undefined,
    };
};

const enrichAuthor = (userId: string, users: TUser[] | undefined) => {
    const { name, pictures } = users?.find((el) => el.id === userId) ?? {};
    if (name) {
        return {
            id: userId,
            name,
            picture: pictures?.profile?.url,
        };
    }
};

const enrichLead = (
    lead: TLead,
    services: IService[] | undefined,
    users: TUser[] | undefined,
    localize: LocalizeFunc
) => {
    const _lead = {
        ...lead,
        ...(lead.internalNotes && {
            internalNotes: lead.internalNotes.map((el) => {
                return {
                    ...el,
                    author: enrichAuthor(el.author, users),
                };
            }),
        }),
    };
    const foundService = services?.find((ser) => ser.id === lead.serviceId) ?? ({} as IService);
    if (foundService) {
        return {
            ..._lead,
            service: {
                name: localize(foundService?.headline),
                picture: foundService.pictures?.[0]?.url,
            },
        } as EnrichedLead;
    }
    return _lead as EnrichedLead;
};

export const useLeads = (serviceId?: string | undefined, leadId?: string) => {
    const queryClient = useQueryClient();

    const { services } = useService();

    const localize = useLocalizer();

    const { users } = useUsers();

    const LeadsQueryKey = ["leads"];
    const LeadsForServiceQueryKey = ["leads", "service", serviceId];
    const SingleBookingQueryKey = ["lead", leadId];

    const leads = useQuery<EnrichedLead[]>(
        LeadsQueryKey,
        async () => {
            await queryClient.cancelQueries(LeadsQueryKey);
            const data = await api.getLeads();
            return sortByLatestCreated(
                data.map((el) => {
                    const enriched = enrichLead(el, services.data, users.data, localize);
                    queryClient.setQueryData(["lead", el.id], { ...enriched });
                    return enriched;
                })
            );
        },
        {
            enabled: Boolean(services.data),
        }
    );

    const lead = useQuery<TLead>(
        SingleBookingQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleBookingQueryKey);
            return api.getLead(leadId as string);
        },
        {
            enabled: Boolean(leadId),
        }
    );

    const enrichedLead = useMemo(() => {
        if (lead.data) {
            return enrichLead(lead.data, services.data, users.data, localize);
        }
    }, [lead, users]);

    const leadsForService = useQuery<TLead[]>(
        LeadsForServiceQueryKey,
        async () => {
            const _leads = queryClient.getQueryData<TLead[]>(LeadsQueryKey);
            return sortByLatestCreated(_leads?.filter((el) => el.serviceId === serviceId) ?? []);
        },
        {
            enabled: Boolean(leads.data) && Boolean(serviceId),
        }
    );

    const updateLead = useMutation(
        ({ id, ...payload }: { id: string; serviceId: string }) => {
            return api.updateLead(id, { ...payload });
        },
        {
            onMutate: async ({ id, serviceId: sid, ...payload }) => {
                const singleQueryKey = ["lead", id];

                await queryClient.cancelQueries(LeadsQueryKey);

                const previous = queryClient.getQueryData<TLead>(singleQueryKey);

                queryClient.setQueryData<TLead[]>(LeadsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TLead[]>(["leads", "service", sid], (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TLead>(singleQueryKey, (prev) => {
                    return { ...prev!, ...payload };
                });

                return { previous, serviceId: sid };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TLead>(["lead", variables.id], context.previous);
                }
            },
            onSettled: async (data, err, variables, context) => {
                await Promise.all([
                    queryClient.invalidateQueries(LeadsQueryKey),
                    queryClient.invalidateQueries(["lead", variables?.id]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["leads", "service", variables?.serviceId]),
                ]);
            },
        }
    );

    const closedLeads = useMemo(() => {
        return sortByLatestCreated(leads.data?.filter((el) => el.status === "closed") ?? []);
    }, [leads.data]);

    const activeLeadsCount = useMemo(() => {
        return leads.data?.filter(
            (lead) =>
                lead.status !== "closed" &&
                lead.offer?.receiptId === undefined &&
                lead.booking?.id === undefined
        ).length;
    }, [leads.data]);

    const closeLead = useMutation(
        ({ id }: { id: string }) => {
            return api.closeLead(id);
        },
        {
            onMutate: async ({ id }) => {
                const singleQueryKey = ["lead", id];

                await queryClient.cancelQueries(LeadsQueryKey);

                const previous = queryClient.getQueryData<TLead>(singleQueryKey);

                queryClient.setQueryData<TLead[]>(LeadsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, status: "closed" } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TLead[]>(
                    ["leads", "service", previous?.serviceId],
                    (prev) => {
                        return (
                            prev?.map((el) => {
                                return el.id === id ? { ...el, status: "closed" } : el;
                            }) ?? []
                        );
                    }
                );

                queryClient.setQueryData<TLead>(singleQueryKey, (prev) => {
                    return { ...prev!, status: "closed" };
                });

                return { previous, serviceId: previous?.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TLead>(["lead", variables.id], context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(LeadsQueryKey),
                    queryClient.invalidateQueries(["lead", variables?.id]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["leads", "service", context?.serviceId]),
                ]);
            },
        }
    );

    const updateNote = useMutation(
        ({
            id,
            serviceId: _serviceId,
            noteId,
            text,
            author,
        }: {
            noteId?: string;
            id: string;
            serviceId: string;
            text: string;
            author: string;
        }) => {
            return api.updateNote(id, noteId ?? createId(), text, author);
        },
        {
            onMutate: async ({ id, noteId, serviceId: sid, ...payload }) => {
                const singleQueryKey = ["lead", id];

                await queryClient.cancelQueries(LeadsQueryKey);

                const previous = queryClient.getQueryData<TLead>(singleQueryKey);

                const internalNotes = noteId
                    ? (previous?.internalNotes ?? []).map((el) => {
                          return el.id === noteId ? { ...el, ...payload } : el;
                      })
                    : ([
                          {
                              ...payload,
                              created: new Date().toISOString(),
                              id: noteId ?? createId(),
                          },
                          ...(previous?.internalNotes ?? []),
                      ] as TNote[]);

                queryClient.setQueryData<TLead[]>(LeadsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, internalNotes } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TLead[]>(["leads", "service", sid], (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, internalNotes } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TLead>(singleQueryKey, (prev) => {
                    return { ...prev!, internalNotes };
                });

                return { previous, serviceId: previous?.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TLead>(["lead", variables.id], context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(LeadsQueryKey),
                    queryClient.invalidateQueries(["lead", variables?.id]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["leads", "service", context?.serviceId]),
                ]);
            },
        }
    );

    const deleteNote = useMutation(
        ({
            id,
            serviceId: _serviceId,
            noteId,
        }: {
            noteId: string;
            id: string;
            serviceId: string;
        }) => {
            return api.deleteNote(id, noteId);
        },
        {
            onMutate: async ({ id, noteId, serviceId }) => {
                const singleQueryKey = ["lead", id];

                await queryClient.cancelQueries(LeadsQueryKey);

                const previous = queryClient.getQueryData<TLead>(singleQueryKey);

                const internalNotes = (previous?.internalNotes ?? []).filter(
                    (el) => el.id !== noteId
                ) as TNote[];

                queryClient.setQueryData<TLead[]>(LeadsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, internalNotes } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TLead[]>(
                    ["lead", { type: "service", serviceId }],
                    (prev) => {
                        return (
                            prev?.map((el) => {
                                return el.id === id ? { ...el, internalNotes } : el;
                            }) ?? []
                        );
                    }
                );

                queryClient.setQueryData<TLead>(singleQueryKey, (prev) => {
                    return { ...prev!, internalNotes };
                });

                return { previous, serviceId: previous?.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TLead>(["lead", variables.id], context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(LeadsQueryKey),
                    queryClient.invalidateQueries(["lead", variables?.id]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["leads", "service", context?.serviceId]),
                ]);
            },
        }
    );

    const deleteLead = useMutation((id: string) => api.deleteLead(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(LeadsQueryKey);

            const leads = queryClient.getQueryData<TLead[]>(LeadsQueryKey);

            const serviceId = leads?.find((el) => el.id === id)?.serviceId;

            queryClient.setQueryData<TLead[]>(["leads", "service", serviceId], (prev) => {
                return prev?.filter((el) => el.id !== id) ?? [];
            });

            queryClient.setQueryData<TLead[]>(["leads"], (prev) => {
                return prev?.filter((el) => el.id !== id) ?? [];
            });

            queryClient.setQueryData<TLead | undefined>(["lead", id], undefined);

            return { serviceId };
        },
        onSettled: async (data, err, id, context: any) => {
            await queryClient.invalidateQueries(LeadsQueryKey);
            await queryClient.invalidateQueries(["leads", "service", context?.serviceId]);
        },
    });

    return {
        lead,
        enrichedLead,
        leads,
        leadsForService,
        updateNote,
        deleteNote,
        deleteLead,
        updateLead,
        closeLead,
        closedLeads,
        activeLeadsCount,
        LeadsQueryKey,
    };
};
