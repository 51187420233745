import {
    Box,
    Button,
    Divider,
    Grid,
    GridProps,
    Stack,
    StackProps,
    TextField,
    Typography,
} from "@mui/material";
import { OptionItem } from "../OptionChips/OptionChips";
import { Tip } from "../Tip/Tip";
import { CustomChip, CustomChipProps } from "../CustomChip/CustomChip";
import { toast } from "react-toastify";
import { useTranslate } from "../../Hooks/useTranslate";

const MAX_SELECTABLE = 50;

export const TagsSection = ({
    tags,
    onChange,
    disableSubmitSection = false,
    showHeader = true,
}: {
    tags: string[];
    showHeader?: boolean;
    onChange: (keys: string[]) => void;
    disableSubmitSection?: boolean;
}) => {
    const { t } = useTranslate("tags");
    const handleToggle = (key: string) => {
        const _new = tags.includes(key) ? tags.filter((el: string) => el !== key) : [...tags, key];

        if (_new.length > MAX_SELECTABLE) {
            toast.warning(t("toast.maxSelectable"), {
                position: "bottom-center",
                hideProgressBar: true,
                autoClose: 2500,
            });
            return;
        }
        onChange(_new);
    };

    return (
        <>
            {showHeader && (
                <Box mb={3}>
                    <Typography mb={1} variant={"h4"}>
                        {t("title")}
                    </Typography>
                    <Tip label={t("tip.label")} />
                </Box>
            )}
            <Tags selectedTags={tags} onToggle={handleToggle} />
            {!disableSubmitSection && (
                <>
                    <Divider sx={{ mb: 3, mt: 3 }} />
                    <Box>
                        <Typography variant={"h5"}>{t("submitSection.title")}</Typography>
                        <Typography variant={"body2"} whiteSpace={"pre-wrap"}>
                            {t("submitSection.description")}
                        </Typography>
                        <Stack direction={"row"} spacing={1} mt={3}>
                            <TextField
                                sx={{ minWidth: 320 }}
                                label={t("submitSection.inputLabel")}
                            />
                            <Button variant={"outlined"}>{t("submitSection.buttonLabel")}</Button>
                        </Stack>
                    </Box>
                </>
            )}
        </>
    );
};

const Tags = ({
    onToggle,
    selectedTags,
}: {
    onToggle: (key: string) => void;
    selectedTags: string[];
}) => {
    return (
        <Stack spacing={2}>
            {getAlphabet().map((letter) => {
                return (
                    <TagRow label={letter}>
                        <TagChips
                            tags={getTagsOfLetter(letter)}
                            selected={selectedTags}
                            onToggle={onToggle}
                        />
                    </TagRow>
                );
            })}
        </Stack>
    );
};

export const TagChips = ({
    tags,
    selected,
    onToggle,
    clickable = true,
    chipProps,
    ...props
}: GridProps & {
    tags: OptionItem[] | string[];
    selected: string[];
    clickable?: boolean;
    onToggle: (key: string) => void;
    chipProps?: CustomChipProps;
}) => {
    return (
        <Grid container gap={1} {...props}>
            {tags?.map((el) => {
                const tag = typeof el === "string" ? { key: el, label: allTagsObject[el] } : el;
                const s = selected.includes(tag.key);
                return (
                    <Grid
                        item
                        key={tag.key}
                        component={CustomChip}
                        onClick={clickable ? () => onToggle(tag.key) : undefined}
                        selected={s}
                        label={tag.label}
                        clickable={clickable}
                        {...chipProps}
                        sx={{
                            height: 38,
                            fontSize: "0.88em",
                            fontWeight: "600",
                            borderRadius: 100,
                            color: "#322FDE",
                            ...chipProps?.sx,
                            ...(s && {
                                borderColor: "transparent",
                                color: "white",
                                background:
                                    "linear-gradient(113.98deg, #933FE7 1.87%, #722FDE 47.17%, #5B2CE1 81.08%, #322FDE 116.14%)",
                            }),
                        }}
                    />
                );
            })}
        </Grid>
    );
};

const TagRow = ({
    label,
    children,
}: StackProps & {
    label: string;
}) => {
    return (
        <Stack spacing={1} direction={"row"} alignItems={"flex-start"}>
            <Typography width={24} lineHeight={"38px"} variant={"h5"}>
                {label}
            </Typography>
            {children}
        </Stack>
    );
};

const getTagsOfLetter = (letter: string) =>
    allTags.filter(({ label }) => label.toLowerCase().startsWith(letter));

const getAlphabet = () => "abcdefghijklmnopqrstuvwxyzæøå".split("");

const allTags = [
    { label: "Akupressur", key: "acupressure" },
    { label: "Akupunktur", key: "acupuncture" },
    { label: "Aromaterapi", key: "aromaTherapy" },
    { label: "Ayurveda", key: "ayurveda" },
    { label: "Angst", key: "anxiety" },
    { label: "ADHD", key: "adhd" },
    { label: "Afhængighed", key: "addiction" },
    { label: "Breathwork", key: "breathwork" },
    { label: "Biorytmer", key: "bioRythms" },
    { label: "Bodymind", key: "bodymind" },
    { label: "Chakra healing", key: "chakraHealing" },
    { label: "Clairvoyance", key: "clairvoyance" },
    { label: "Coaching", key: "coaching" },
    { label: "Danse terapi", key: "danceTherapy" },
    { label: "Depression", key: "depression" },
    { label: "Drømmetydning", key: "dreamInterpretation" },
    { label: "EFT (Emotions-Fokuseret-Terapi)", key: "eft" },
    { label: "Energi healing", key: "energyHealing" },
    { label: "Enneagram", key: "enneagram" },
    { label: "Faste", key: "fasting" },
    { label: "Hypnose", key: "hypnosis" },
    { label: "Healing", key: "healing" },
    { label: "Homøopati", key: "homeopathy" },
    { label: "Hydroterapi", key: "hydroTherapy" },
    { label: "Kampsport", key: "martialArts" },
    { label: "Kinesiologi", key: "kinesiology" },
    { label: "Kost og ernæring", key: "dietAndNutrition" },
    { label: "Kiropraktik", key: "chiropractic" },
    { label: "Kropsterapi", key: "bodyTherapy" },
    { label: "Krystaller og sten", key: "crystalsAndStones" },
    { label: "Kranio sakral terapi", key: "cranioSacralTherapy" },
    { label: "Kunstterapi", key: "artTherapy" },
    { label: "Lyd healing", key: "soundHealing" },
    { label: "Meditation", key: "meditation" },
    { label: "Mindfullness", key: "mindfullness" },
    { label: "Massage terapi", key: "massageTherapy" },
    { label: "Naturmedicin", key: "naturalMedicine" },
    { label: "Naturterapi", key: "naturalTherapy" },
    { label: "NLP", key: "nlp" },
    { label: "Osteopati", key: "osteopathy" },
    { label: "Psykoterapi", key: "psychotherapy" },
    { label: "Plantemedicin", key: "plantMedicine" },
    { label: "Peak performance", key: "peakPerformance" },
    { label: "PTSD", key: "ptsd" },
    { label: "Qigong", key: "qigong" },
    { label: "Refleksologi", key: "reflexology" },
    { label: "Reiki", key: "reiki" },
    { label: "Shamanisme", key: "shamanism" },
    { label: "Shiatsu", key: "shiatsu" },
    { label: "Tai Chi", key: "taiChi" },
    { label: "Tantra", key: "tantra" },
    { label: "Tarot", key: "tarot" },
    { label: "Traditionel kinesisk medicin", key: "traditionalChineseMedicine" },
    { label: "Traume arbejde", key: "traumaWork" },
    { label: "Trommerejse", key: "drumJourney" },
    { label: "Urteterapi", key: "herbalTherapy" },
    { label: "Vagus nerven", key: "vagusNerve" },
    { label: "Voodoo", key: "voodoo" },
    { label: "Yoga", key: "yoga" },
    { label: "Zoneterapi", key: "zoneTherapy" },
    { label: "Psykologi", key: "psychology" },
    { label: "Vægttab", key: "weightLoss" },
    { label: "Rygestop", key: "smokingCessation" },
    { label: "Ernæringsterapi", key: "nutritionTherapy" },
    { label: "Phytoterapi", key: "phytotherapy" },
    { label: "Biopati", key: "biopathy" },
    { label: "Naturopati", key: "naturopathy" },
    { label: "Bioresonanstherapi", key: "bioresonanceTherapy" },
    { label: "Stress", key: "stress" },
    { label: "Præstationsangst", key: "performanceAnxiety" },
    { label: "Trivsel", key: "well-being" },
    { label: "Fobier", key: "phobias" },
    { label: "Grounding", key: "grounding" },
    { label: "Inner Child", key: "innerChild" },
    { label: "Kronisk smerte", key: "chronicPain" },
    { label: "Hovedpine", key: "headache" },
    { label: "Tinnitus", key: "tinnitus" },
    { label: "Konflikter", key: "conflicts" },
    { label: "Mobning", key: "bullying" },
    { label: "Vrede", key: "anger" },
    { label: "Alvorlig sygdom", key: "seriousIllness" },
    { label: "Vold", key: "violence" },
    { label: "Svigt", key: "neglect" },
    { label: "Jalousi", key: "jealousy" },
    { label: "Incest", key: "incest" },
    { label: "Overgreb", key: "abuse" },
    { label: "Parforholdsproblematikker", key: "relationshipIssues" },
    { label: "Seksualitet", key: "sexuality" },
    { label: "Selvmordsproblematik", key: "suicidalIssues" },
    { label: "Selvskade", key: "self-harm" },
    { label: "Selvtillid", key: "self-confidence" },
    { label: "Selvværd", key: "self-esteem" },
    { label: "Spiseforstyrrelser", key: "eatingDisorders" },
    { label: "Skilsmisse", key: "divorce" },
    { label: "Sorg og krise", key: "griefAndCrisis" },
    { label: "Regressionsterapi", key: "regressionTherapy" },
    { label: "Englehealing", key: "angleHealing" },
    { label: "Spirituel vejledning", key: "spiritualGuidance" },
    { label: "Spirituel", key: "spiritual" },
];

export const allTagsObject = allTags.reduce<{ [k: string]: string }>((obj, { label, key }) => {
    return {
        ...obj,
        [key]: label,
    };
}, {});
