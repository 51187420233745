import React, { ComponentPropsWithoutRef, useMemo, useState } from "react";
import { SyiPage, walkObjectRecursively } from "./SyiPage/SyiPage";
import { discountSyiSteps, discountSchema } from "./SyiPage/config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import randomBytes from "randombytes";
import { useTranslate } from "../Hooks/useTranslate";
import { TDiscount, useDiscount } from "../Hooks/useDiscount";
import { useProfile } from "../Hooks/useProfile";
import { TFunction } from "i18next";
import { isEmptyObject } from "../Utils/helpers";
import { useForm, FormProvider } from "react-hook-form";

const getTitle = (t: TFunction, id: string | undefined) =>
    id === "create"
        ? `${t("new", "utils.generic")} ${t("discount", "utils.generic")}`
        : `${t("edit", "utils.generic")} ${t("discount", "utils.generic")}`;

const preparePayload = (payload: object) => {
    const { overrides, ...sanitized } = walkObjectRecursively(
        walkObjectRecursively(
            payload,
            (key, value) => value !== null && value !== undefined && !key.startsWith("_")
        ),
        (_, value) => !isEmptyObject(value)
    ) as { overrides?: { id?: string }[] };
    return {
        ...sanitized,
        ...(overrides && {
            overrides: overrides?.filter((el) => Boolean(el?.id)) ?? [],
        }),
    };
};

export const DiscountPage = ({ children }: ComponentPropsWithoutRef<"main">) => {
    const { t } = useTranslate("utils.generic");

    const { id } = useParams<{ id: string }>();

    const location = useLocation();
    const navigate = useNavigate();

    const { defaultCurrency } = useProfile();

    const _id = useMemo(() => (id !== "create" && id ? id : randomBytes(16).toString("hex")), [id]);

    const defaultValues: { [key: string]: any } = {
        currency: defaultCurrency,
        _applyForAll: true,
        _limitTimesRedeemed: false,
        _shouldExpire: false,
        scope: "total",
        percentage: true,
        source: "admin",
    };

    const { control, setValue, ...methods } = useForm<Record<string, any>>({
        defaultValues: {
            status: "draft",
            ...defaultValues,
        },
    });

    const { discount, updateDiscount, deleteDiscount } = useDiscount(_id);

    const _steps = useMemo(() => {
        return discountSyiSteps.map((el) => {
            return {
                ...el,
                label: t(el.key, "discounts.create.progress"),
            };
        });
    }, []);

    const onSubmit = async (data: object) => {
        updateDiscount.mutate(preparePayload(data) as TDiscount);
        return navigate(location?.state?.returnUrl ?? "/discounts", { replace: true });
    };

    return (
        <FormProvider {...{ ...methods, control, setValue }}>
            <SyiPage
                modelType={"discount"}
                steps={_steps}
                title={getTitle(t, id)}
                prepareData={(data) => data}
                deleteItem={deleteDiscount}
                updateItem={updateDiscount}
                item={discount}
                id={_id}
                schema={discountSchema}
                returnUrl={location?.state?.returnUrl ?? "/discounts"}
                onSubmit={onSubmit}
                isCreateFlow={id === "create"}
            >
                {children}
            </SyiPage>
        </FormProvider>
    );
};
