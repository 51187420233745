import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
    Box,
    BoxProps,
    Checkbox,
    Dialog,
    Divider,
    FormControlLabel,
    Stack,
    StackProps,
    Typography,
} from "@mui/material";
import { CloseButton } from "../Components/CloseButton/CloseButton";
import { SyiSection } from "../Pages/SyiPage/SyiSection";
import { CustomerForm } from "./OfferDialog";
import { GradientButton } from "../Components/GradientButton";
import React, { useState } from "react";
import { Customer } from "../Utils/types";

type CreateCustomerDialogProps = {
    onSubmit?: (customer: Customer, upsert: boolean) => void;
    defaultValues?: Customer;
    upsert?: boolean;
};

export const CreateCustomerDialog = NiceModal.create<CreateCustomerDialogProps>(
    ({ onSubmit, upsert: defaultUpsert, defaultValues }) => {
        const modal = useModal();

        const { handleSubmit, control, ...methods } = useForm<{ customer: Customer }>({
            defaultValues: { customer: defaultValues },
        });

        const [upsert, setUpsert] = useState(defaultUpsert ?? true);

        const existingCustomer = Boolean(
            useWatch({ control, name: "customer.id", defaultValue: undefined })
        );

        const handleClose = () => {
            modal.remove();
        };

        const handleSubmitForm: BoxProps<"form">["onSubmit"] = (evt) => {
            evt.preventDefault();
            if (onSubmit) {
                handleSubmit(({ customer }) => {
                    onSubmit(customer, upsert);
                    handleClose();
                })();
            }
        };

        return (
            <Dialog open={modal.visible} onClose={handleClose}>
                <FormProvider {...{ ...methods, control, handleSubmit }}>
                    <Stack p={3} spacing={3} divider={<Divider />}>
                        <Box component={"header"} display={"flex"} alignItems={"flex-start"}>
                            <Box flexGrow={1}>
                                <Typography variant={"h4"} fontSize={"1.1em"}>
                                    Klientoplysninger
                                </Typography>
                                <Typography mt={2}>
                                    Vælg klient eller indtast klientoplysninger manuelt
                                </Typography>
                            </Box>
                            <CloseButton onClick={handleClose} />
                        </Box>
                        <SyiSection
                            Component={(props: StackProps<"form">) => (
                                <Stack component={"form"} onSubmit={handleSubmitForm} {...props} />
                            )}
                            title={"Vælg blandt eksisterende klienter"}
                        >
                            <CustomerForm
                                customerId={defaultValues?.id}
                                component={Stack}
                                requires={["name", "email"]}
                            >
                                <>
                                    <Divider orientation={"horizontal"} sx={{ mt: 3 }} />
                                    <Typography variant={"h5"} mt={3} mb={1}>
                                        Eller opret som ny klient
                                    </Typography>
                                </>
                            </CustomerForm>
                            {!existingCustomer && (
                                <Box
                                    mt={2}
                                    pl={2}
                                    component={FormControlLabel}
                                    control={<Checkbox />}
                                    checked={upsert}
                                    onChange={() => setUpsert((p) => !p)}
                                    label={"Opret i kundeliste"}
                                />
                            )}
                            <GradientButton sx={{ mt: 4 }} type={"submit"} size={"large"} fullWidth>
                                {defaultValues ? "Gem" : "Tiløj"} klient
                            </GradientButton>
                        </SyiSection>
                    </Stack>
                </FormProvider>
            </Dialog>
        );
    }
);
