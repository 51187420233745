import {useTranslation} from "react-i18next";

export type TranslateFunction = (key: string, prefixOrOptions?: string | object, options?: object) => string

export const useTranslate = (prefix: string) => {
    const { t, i18n } = useTranslation();
    return {
        i18n,
        t: (key, prefixOrOptions, options) => {
            const hasPrefix = typeof prefixOrOptions === 'string';
            return t(`${(hasPrefix ? prefixOrOptions : null) ?? prefix}.${key}`, options ?? (hasPrefix ? {} : prefixOrOptions ?? {}))
        }
    } as {
        t: TranslateFunction,
        i18n: typeof i18n
    }
}
