import {ComponentPropsWithoutRef} from "react";
import {useProfile} from "../../Hooks/useProfile";
import {Box} from "@mui/material";

export const EnableForRole = ({ children, roles }: ComponentPropsWithoutRef<any> & {roles: string[]}) => {
    const { myRole } = useProfile()
    if (myRole && roles.includes(myRole)) {
        return <>{children}</>
    }
    return <Box sx={{ pointerEvents: 'none' }}>{children}</Box>
}

export const ShowForRole = ({ children, roles }: ComponentPropsWithoutRef<any> & {roles: string[]}) => {
    const { myRole } = useProfile()
    if (myRole && roles.includes(myRole)) {
        return <>{children}</>
    }
    return <></>
}
