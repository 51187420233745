import { Box, BoxProps, Stack, Typography, useMediaQuery } from "@mui/material";
import { CloseButton } from "../Components/CloseButton/CloseButton";

export const Header = ({
    children,
    title,
    id,
    onClose,
    ...props
}: BoxProps & { title?: string; id?: string; onClose: () => void }) => {
    const isMobile = useMediaQuery("(max-width: 1200px)");
    return (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={"1px solid #E3E3E3"}
            p={2}
            pr={{ xs: 2, lg: 3 }}
            pl={0}
            {...props}
        >
            <Box>
                <Typography variant={"h3"} fontSize={{ xs: "1.2em", lg: "1.5em" }}>
                    {title}
                </Typography>
                {id && (
                    <Typography variant={"body2"}>
                        <strong>ID</strong>: {id}
                    </Typography>
                )}
            </Box>
            <Stack direction={"row"} spacing={2}>
                {children}
                <CloseButton size={isMobile ? "small" : "medium"} onClick={onClose} />
            </Stack>
        </Box>
    );
};
