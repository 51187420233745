import {
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from "@mui/material";
import { useFunnels } from "../Hooks/useFunnels";

type FunnelSelectProps<T extends string | undefined = undefined> = {
    funnelId: T extends string ? "all" | string : string;
    inputLabel: string;
    onChange?: SelectProps["onChange"];
    selectProps?: Omit<SelectProps, "onChange">;
    allLabel?: T;
} & Omit<FormControlProps, "onChange">;
export const FunnelSelect = ({
    funnelId,
    onChange,
    inputLabel,
    allLabel,
    selectProps,
    ...props
}: FunnelSelectProps) => {
    const {
        funnels: { data: funnels },
    } = useFunnels();

    return (
        <FormControl sx={{ minWidth: 320, maxWidth: 320 }} {...props}>
            <InputLabel sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }} id="select-service">
                {inputLabel}
            </InputLabel>
            <Select labelId="select-service" value={funnelId} onChange={onChange} {...selectProps}>
                {allLabel && <MenuItem value={"all"}>{allLabel}</MenuItem>}
                {funnels?.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                        {el.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
