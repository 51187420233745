import { ComponentPropsWithoutRef, useCallback, useEffect, useMemo, useState } from "react";
import { SyiPage } from "../SyiPage/SyiPage";
import { serviceSyiSteps, serviceSchema, serviceTypeEventInjectableSteps } from "../SyiPage/config";
import { IService, useService } from "../../Hooks/useService";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import { ServiceDetailsPage } from "./ServiceDetailsPage";
import { AppShell } from "../../Components/AppShell/AppShell";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, UseFormReturn } from "react-hook-form";
import { useServiceType } from "../../Hooks/useServiceType";
import { anId } from "../../Utils/helpers";
import { useSession } from "../../Hooks/useSession";
import { useWeakStore as useStore } from "../../Store/useStore";

const defaultValues = {
    cancellationFee: {
        selectedOptionKey: "no",
    },
    session: {
        introSession: {
            selectedOptionKey: "no",
        },
    },
    minimumParticipants: {
        selectedOptionKey: "no",
    },
    deactivateBeforeDays: {
        selectedOptionKey: "no",
    },
    guestInfo: {
        selectedOptionKey: "no",
    },
    guestRequirements: {
        selectedOptionKey: "no",
    },
    customDescriptions: {
        selectedOptionKey: "no",
    },
    customData: {
        selectedOptionKey: "no",
    },
    notifications: {
        selectedOptionKey: "emailAndText",
    },
};

const isObject = (obj: unknown) => typeof obj === "object" && !Array.isArray(obj) && obj !== null;

const walk = (obj: Record<string, any>, returnNull: boolean = false): Record<string, any> => {
    return Object.entries(obj).reduce((acc, [k, v]) => {
        if (v === undefined) {
            return returnNull
                ? {
                      ...acc,
                      [k]: null,
                  }
                : acc;
        }
        if (Array.isArray(v)) {
            return {
                ...acc,
                [k]: v.map((el) => (isObject(el) ? walk(el) : el)),
            };
        }
        if (isObject(v)) {
            return {
                ...acc,
                [k]: walk(v as any),
            };
        }
        return {
            ...acc,
            [k]: v,
        };
    }, {});
};

const preparePayload = (data: Record<string, any>, returnNull: boolean = false) => {
    return Object.entries(walk(data)).reduce((acc, [k, v]) => {
        if (v?.selectedOptionKey === "no") {
            return returnNull
                ? {
                      ...acc,
                      [k]: null,
                  }
                : acc;
        }
        if (v?.selectedOptionKey === "yes" && v?.value) {
            return {
                ...acc,
                [k]: v.value,
            };
        }
        if (v?.selectedOptionKey === "yes" && (v?.value ?? v?.["yes"])) {
            return {
                ...acc,
                [k]: v?.value ?? v?.["yes"],
            };
        }
        return {
            ...acc,
            [k]: v,
        };
    }, {});
};

const toObject = (arr?: { key: string }[]) => {
    if (Array.isArray(arr)) {
        return arr?.reduce((acc, { key, ...props }) => {
            return {
                ...acc,
                [key]: props,
            };
        }, {});
    }
    return {};
};

const toArray = (obj?: Record<string, { [k: string]: unknown }>) => {
    return Object.entries(obj ?? {}).map(([key, props]) => ({ key, ...props }));
};

export const ServicePage = ({ children }: ComponentPropsWithoutRef<"main">) => {
    const { t } = useTranslation();

    const { id } = useParams<{ id: string }>();

    const {
        params: { type },
    } = useMatch(":key/:id/:type/*") ?? { params: {} };

    const { setServiceUpdating } = useStore();

    const { state } = useLocation();
    const navigate = useNavigate();

    const _id = useMemo(() => (id !== "create" && id ? id : anId()), [id]);

    const location = useLocation();

    const { updateSession, sessionForService } = useSession(undefined, _id);

    const { service, updateService, deleteService } = useService(_id!);

    const { control, setValue, ...methods } = useForm<Record<string, any>>({
        defaultValues: {
            status: "draft",
            ...defaultValues,
        },
    });

    const { type: serviceType } = useServiceType(control);

    const prepareData = useCallback(
        (data: IService | null) => {
            return Object.entries(data ?? {}).reduce((acc, [key, value]) => {
                if (key === "customDescriptions") {
                    return {
                        ...acc,
                        [key]: {
                            selectedOptionKey: "yes",
                            value: data?.customDescriptions ?? [],
                        },
                    };
                }
                if (key === "deactivateBeforeDays") {
                    return {
                        ...acc,
                        [key]: {
                            selectedOptionKey: "yes",
                            value: data?.deactivateBeforeDays,
                        },
                    };
                }
                if (key === "guestInfo") {
                    return {
                        ...acc,
                        [key]: {
                            selectedOptionKey: "yes",
                            value: data?.guestInfo,
                        },
                    };
                }
                if (key === "customData") {
                    return {
                        ...acc,
                        [key]: {
                            selectedOptionKey: "yes",
                            value: data?.customData,
                        },
                    };
                }
                if (key === "guestRequirements") {
                    return {
                        ...acc,
                        [key]: {
                            selectedOptionKey: "yes",
                            value: data?.guestRequirements,
                        },
                    };
                }
                if (key === "customData") {
                    return {
                        ...acc,
                        [key]: {
                            selectedOptionKey: "yes",
                            value: data?.customData,
                        },
                    };
                }
                return {
                    ...acc,
                    [key]: value,
                };
            }, {});
        },
        [service]
    );

    const prefiller = useCallback(
        (setValue: UseFormReturn["setValue"]) => {
            if (sessionForService.data && service.data && service.data.type === "session") {
                setValue(
                    "session",
                    {
                        ...sessionForService.data,
                        introSession: {
                            selectedOptionKey:
                                sessionForService.data.introSession === null ? "no" : "yes",
                            ...sessionForService.data.introSession,
                        },
                        openingHours: toObject(sessionForService.data?.openingHours),
                    },
                    { shouldTouch: false }
                );
                return true;
            }
            if (!sessionForService.isLoading && sessionForService.data === null) {
                return true;
            }
            return service.data && service.data.type !== "session";
        },
        [sessionForService, service]
    );

    const _steps = useMemo(() => {
        let _syiSteps = [...serviceSyiSteps];
        if (serviceType && serviceType !== "session") {
            serviceTypeEventInjectableSteps.forEach(({ position, ...el }) => {
                _syiSteps.splice(position, 0, el);
            });
        } else {
            _syiSteps = _syiSteps.filter(
                (f) => !serviceTypeEventInjectableSteps.map((s) => s.key).includes(f.key)
            );
        }
        if (serviceType !== "session") {
            _syiSteps = _syiSteps.filter((s) => !["time"].includes(s.key));
        }
        return _syiSteps.map((el) => {
            return {
                ...el,
                label: t(`service.create.progress.${el.key}`),
            };
        });
    }, [serviceType]);

    if (id !== "create" && type !== "edit") {
        return (
            <AppShell>
                <ServiceDetailsPage serviceId={_id}>{children}</ServiceDetailsPage>
            </AppShell>
        );
    }

    const onSubmit = async (
        data: any,
        dirtyFields: unknown,
        shouldNavigate: unknown,
        validated = true
    ) => {
        setServiceUpdating(_id);
        const { session, event, ...payload } = preparePayload(data) as ServicePayload;
        let proms: Promise<any>[] = [updateService.mutateAsync({ ...payload, validated })];
        if (session && payload.type === "session") {
            const sessionId = session.id ?? anId();
            proms.push(
                updateSession.mutateAsync({
                    ...preparePayload(session, true),
                    openingHours: toArray(session.openingHours),
                    serviceId: _id,
                    id: sessionId,
                } as any)
            );
        }

        await Promise.all(proms).then(() => {
            setTimeout(() => setServiceUpdating(undefined), 30000);
        });
        return navigate(location?.state?.returnUrl ?? `/service/${_id}`, { replace: true });
    };

    return (
        <FormProvider {...{ ...methods, control, setValue }}>
            <SyiPage
                modelType={"service"}
                steps={_steps}
                returnUrl={location?.state?.returnUrl ?? "/services"}
                title={
                    id === "create"
                        ? `${t("utils.generic.new")} ${t("utils.generic.service")}`
                        : `${t("utils.generic.edit")} ${t("utils.generic.service")}`
                }
                deleteItem={deleteService}
                updateItem={updateService}
                prepareData={prepareData}
                item={service}
                schema={serviceSchema}
                state={state}
                onSubmit={onSubmit}
                prefiller={prefiller}
                id={_id}
                isCreateFlow={id === "create"}
            >
                {children}
            </SyiPage>
        </FormProvider>
    );
};

type EventTypeServicePayload = {
    event?: Record<string, any>;
    seatCount: number;
};

type SessionTypeServicePayload = {
    session?: Record<string, any>;
};

type ServicePayload = IService & (EventTypeServicePayload & SessionTypeServicePayload);
