import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCompanyUsers } from "../Api";
import { useCallback } from "react";

export type TUser = {
    id: string;
    name: string;
    pictures?: {
        profile: {
            url: string;
            key: string;
        };
    };
    phone: string;
    email: string;
    role: "admin" | "viewer";
};

export const useUsers = () => {
    const queryClient = useQueryClient();

    const QueryKey = ["users"];

    const users = useQuery<TUser[]>(QueryKey, () => getCompanyUsers(), {
        enabled: true,
    });

    const getUser = useCallback(
        (userId?: string) => {
            return users.data?.find((el) => el.id === userId);
        },
        [users.data]
    );

    return {
        users,
        getUser,
    };
};
