import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormControlProps,
    FormLabel,
    Link,
    Radio,
    RadioGroup,
    RadioGroupProps,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTranslate } from "../../../Hooks/useTranslate";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useMetaLogin } from "../../../Hooks/useMetaLogin";
import React, { useState } from "react";
import { Campaign } from "../../../Hooks/useCampaigns";
import { ServiceSelect } from "../../../Components/ServiceSelect";
import { FunnelSelect } from "../../../Components/FunnelSelect";
import { useErrors } from "../../SyiPage/useErrors";
import { LanguageChips } from "../../../Components/LanguageChips/LanguageChips";
import { languagesOptions } from "../../../Utils/config";
export const CampaignSyiSectionSetup = () => {
    const { t } = useTranslate("campaign.create.setup");

    const { control, register } = useFormContext<Campaign>();

    const { getError } = useErrors();

    const { field: subject } = useController({ control, name: "subject" });

    const { field: subjectId } = useController({ control, name: "subjectId" });

    const { field: language } = useController({ control, name: "language" });

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            <Stack spacing={4} mt={3} divider={<Divider />}>
                <Stack spacing={3}>
                    <TextField
                        label={t("name")}
                        sx={{ minWidth: 320, maxWidth: 320 }}
                        error={Boolean(getError("name"))}
                        {...register("name", { required: true })}
                    />
                    <TextField
                        multiline={true}
                        minRows={3}
                        label={t("description")}
                        sx={{ minWidth: 320, maxWidth: 320 }}
                        {...register("description")}
                    />
                    <LanguageChips
                        onChange={([key]) => language.onChange(key)}
                        multiple={false}
                        allowEmpty={false}
                        languages={[language.value]}
                        languageOptions={languagesOptions}
                    />
                </Stack>
                <SyiSection
                    title={t("subject")}
                    error={getError("subject") ?? getError("subjectId")}
                >
                    <RadioGroup {...subject}>
                        <FormControlLabel value={"service"} control={<Radio />} label={"Ydelse"} />
                        <FormControlLabel value={"funnel"} control={<Radio />} label={"Tragt"} />
                    </RadioGroup>
                    {subject.value && (
                        <Box mt={3}>
                            {subject.value === "service" && (
                                <Stack spacing={2}>
                                    <ServiceSelect
                                        type={"session"}
                                        serviceId={subjectId.value}
                                        inputLabel={t("service")}
                                        onChange={subjectId.onChange}
                                    />
                                    <TextField
                                        label={`Overskriv link til checkout`}
                                        helperText={"Genereres som udgangspunkt automatisk"}
                                        {...register("linkToCheckout")}
                                    />
                                </Stack>
                            )}
                            {subject.value === "funnel" && (
                                <FunnelSelect
                                    funnelId={subjectId.value}
                                    inputLabel={t("funnel")}
                                    onChange={subjectId.onChange}
                                />
                            )}
                        </Box>
                    )}
                </SyiSection>

                <SyiSection
                    title={"Vælg side på Meta"}
                    error={getError("assetId") ?? getError("accountId")}
                >
                    <AccountSelect disabled={!Boolean(subjectId.value)} />
                </SyiSection>
            </Stack>
        </>
    );
};

const AccountSelect = ({ ...props }: FormControlProps) => {
    const { subscribedAccounts: accounts } = useMetaLogin();
    const { setValue, control } = useFormContext<Campaign>();
    const selectedAccount = useWatch({ control, name: "accountId" });
    const handleChange: RadioGroupProps["onChange"] = (evt, id) => {
        setValue("accountId", id);
    };
    return (
        <FormControl {...props}>
            {accounts.isLoading ? (
                <Skeleton width={200} height={20} />
            ) : (
                <>
                    <RadioGroup onChange={handleChange} defaultValue={selectedAccount}>
                        {accounts.data?.map((el) => (
                            <FormControlLabel
                                key={el.id}
                                value={el.id}
                                control={<Radio />}
                                label={el.name}
                            />
                        ))}
                    </RadioGroup>

                    {selectedAccount && <FormSelect accountId={selectedAccount} sx={{ mt: 2 }} />}
                </>
            )}
        </FormControl>
    );
};

const FormSelect = ({ accountId, ...props }: FormControlProps & { accountId: string }) => {
    const { forms } = useMetaLogin(accountId);
    const { setValue, control, register } = useFormContext<Campaign>();
    const [manual, setManual] = useState(false);
    const selectedForm = useWatch({ control, name: "assetId" });
    const handleChange: RadioGroupProps["onChange"] = (evt, id) => {
        if (id === "manual") {
            setManual(true);
        } else {
            setValue("assetId", id);
        }
    };
    return (
        <FormControl {...props}>
            <FormLabel sx={{ fontWeight: 600, color: "#1A2027" }}>Vælg Instant Form</FormLabel>
            {forms.isLoading ? (
                <Skeleton width={200} height={20} sx={{ mt: 2 }} />
            ) : (
                <>
                    <RadioGroup sx={{ mt: 2 }} onChange={handleChange} defaultValue={selectedForm}>
                        {forms.data?.map((el) => (
                            <FormControlLabel
                                key={el.id}
                                value={el.id}
                                control={<Radio />}
                                label={el.name}
                            />
                        ))}
                        <FormControlLabel
                            control={<Radio />}
                            label={"Indtast manuel"}
                            value={"manual"}
                        />
                    </RadioGroup>

                    {manual && (
                        <TextField
                            label={"Meta Instant Form ID"}
                            sx={{ minWidth: 320, mt: 2, mb: 2 }}
                            helperText={
                                <Link
                                    href="https://business.facebook.com/latest/instant_forms/forms"
                                    target="_blank"
                                >
                                    Findes i Meta her.
                                </Link>
                            }
                            {...register("assetId")}
                        />
                    )}
                </>
            )}
        </FormControl>
    );
};
