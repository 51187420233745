import { Box, Typography, Card, Button, CircularProgress, ButtonProps, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { initStripeSetup } from "../../Api/Api";
import NiceModal from "@ebay/nice-modal-react";
import { StripeDialog } from "../../Modals/StripeDialog";
import { useOnBoarding } from "../../Hooks/useOnBoarding";
import { useTranslate } from "../../Hooks/useTranslate";
import { useMetaLogin } from "../../Hooks/useMetaLogin";
import { initLogin, initOutlookLogin } from "../../Api";
import { META_URL, OUTLOOK_URL } from "../../Utils/constants";
import { MetaConnectAccountsDialog } from "../../Modals/MetaConnectAccountsDialog";
import { OutlookConnectCalendarsDialog } from "../../Modals/OutlookConnectCalendarsDialog";
import { useOutlook } from "../../Hooks/useOutlook";

export const SettingsIntegrationsSection = () => {
    const { t } = useTranslate("settings.integrations");

    const { onBoarding } = useOnBoarding();
    const { valid } = useMetaLogin();
    const { valid: validOutlook } = useOutlook();
    const popupRef = useRef<Window | null>();

    const paymentStatus = useMemo(() => {
        const { steps } = onBoarding.data?.find((el) => el.key === "payment") ?? {};
        return steps?.find((el) => el.key === "setup")?.metadata?.status ?? "connect";
    }, [onBoarding.data]);

    const handleConnectStripe = (status: string) => async () => {
        if (status === "connect") {
            NiceModal.show(StripeDialog, {
                onClick: async () => {
                    const { url } = await initStripeSetup();
                    window.open(url, "_blank");
                },
            });
        } else {
            const { url } = await initStripeSetup();
            window.open(url, "_blank");
        }
    };

    useEffect(() => {
        window.addEventListener("message", handleSuccessfulLogin);
        return () => window.removeEventListener("message", handleSuccessfulLogin);
    }, []);

    const handleSuccessfulLogin = (event: MessageEvent<any>) => {
        console.log("received event", event);
        const metaUrl = new URL(META_URL ?? "");
        const outlookUrl = new URL(OUTLOOK_URL ?? "");
        if (event.data === "outlookLoginSuccess" && event.origin === outlookUrl.origin) {
            popupRef.current?.close();
            return Promise.all([
                NiceModal.show(OutlookConnectCalendarsDialog),
                validOutlook.refetch(),
            ]);
        } else if (event.data === "success" && event.origin === metaUrl.origin) {
            popupRef.current?.close();
            return Promise.all([NiceModal.show(MetaConnectAccountsDialog), valid.refetch()]);
        }
    };

    const handleInitMetaLogin = (status: string) => async () => {
        if (status === "connect") {
            const { url } = await initLogin();
            popupRef.current = window.open(
                url,
                "metaOauthPopup",
                "toolbar=no, menubar=no, width=600, height=700, top=100, left=100"
            );
        }
    };
    const handleInitOutlookLogin = (status: string) => async () => {
        if (status === "connect") {
            const { url } = await initOutlookLogin();
            popupRef.current = window.open(
                url,
                "outlookOauthPopup",
                "toolbar=no, menubar=no, width=600, height=700, top=100, left=100"
            );
        }
    };

    return (
        <>
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Stack spacing={3}>
                <IntegrationItem
                    title={t("payments.stripe.title")}
                    subtitle={t("payments.stripe.subtitle")}
                    description={t("payments.stripe.description")}
                    buttonLabel={(status) => t(`payments.status.${status}`)}
                    status={paymentStatus}
                    onClick={handleConnectStripe}
                    icon={"/spiritworld-logo-square.png"}
                    buttonProps={(status) => ({
                        color: status === "connected" ? "success" : "primary",
                        sx: {
                            ...(status === "connected" && {
                                pointerEvents: "none",
                            }),
                        },
                    })}
                />
                <IntegrationItem
                    title={t("meta.title")}
                    subtitle={t("meta.subtitle")}
                    description={t("meta.description")}
                    buttonLabel={(status) => t(`meta.status.${status}`)}
                    status={valid.data ? "connected" : "connect"}
                    onClick={handleInitMetaLogin}
                    icon={"/tracking-providers/facebook-logo.png"}
                    buttonProps={(status) => ({
                        color: valid.data ? "success" : "primary",
                        sx: {
                            ...(valid.data && {
                                pointerEvents: "none",
                            }),
                        },
                    })}
                />
                <IntegrationItem
                    title={t("outlook.title")}
                    subtitle={t("outlook.subtitle")}
                    description={t("outlook.description")}
                    buttonLabel={(status) => t(`outlook.status.${status}`)}
                    status={validOutlook.data?.validSubscriptions ? "connected" : "connect"}
                    onClick={handleInitOutlookLogin}
                    icon={"/integrations/outlook/icon.svg"}
                    buttonProps={(status) => ({
                        color: validOutlook.data?.validSubscriptions ? "success" : "primary",
                        sx: {
                            ...(validOutlook.data?.validSubscriptions && {
                                pointerEvents: "none",
                            }),
                        },
                    })}
                />
            </Stack>
        </>
    );
};

type IntegrationItemProps = {
    title: string;
    subtitle: string;
    description: string;
    icon: string;
    status: string;
    buttonLabel: string | ((status: string) => string);
    onClick: (status: string) => () => Promise<void>;
    buttonProps?: Partial<ButtonProps> | ((status: string) => Partial<ButtonProps>);
};

const IntegrationItem = ({
    onClick,
    icon,
    title,
    status,
    subtitle,
    description,
    buttonLabel,
    buttonProps = {},
}: IntegrationItemProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);
        await onClick(status)();
        setIsLoading(false);
    };

    const { sx = {}, ..._buttonProps } =
        typeof buttonProps === "function" ? buttonProps(status) : buttonProps;

    return (
        <Box component={Card} display={"inline-flex"} alignItems={"center"} maxWidth={500}>
            <Box
                sx={{
                    minWidth: 80,
                    minHeight: 80,
                    backgroundSize: "cover",
                    borderRadius: 200,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage: `url("${icon}")`,
                }}
            />
            <Box ml={2}>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    flexGrow={1}
                    mb={1}
                >
                    <Box>
                        <Typography variant={"h5"}>{title}</Typography>
                        <Typography variant={"body2"}>{subtitle}</Typography>
                    </Box>
                    <Button
                        variant={"contained"}
                        sx={{
                            fontSize: "0.88em",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            ...sx,
                        }}
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress color={"inherit"} size={"1em"} />}
                        onClick={handleClick}
                        {..._buttonProps}
                    >
                        {typeof buttonLabel === "function" ? buttonLabel(status) : buttonLabel}
                    </Button>
                </Box>
                <Typography>{description}</Typography>
            </Box>
        </Box>
    );
};
