import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import { EditorProvider, EditorProviderProps, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import "./Editor.css";
import { useDebounce } from "../../Hooks/useDebounce";
import { MenuBar } from "./MenuBar";
import { useEffect, useMemo, useState } from "react";

const defaultExtensions = [
    Color.configure({ types: [ListItem.name] }),
    StarterKit,
    Link.configure({
        protocols: [
            {
                scheme: "tel",
                optionalSlashes: true,
            },
            {
                scheme: "mailto",
                optionalSlashes: true,
            },
        ],
    }),
    Underline,
];

type EmulatedEvent = {
    target: {
        value: string;
    };
};

const makeEvent = (value: string) => {
    return { target: { value } };
};

export type EditorProps = {
    onChange: (evt: string) => void;
    defaultValue?: string;
    placeholder?: string;
};
export const Editor = ({ onChange, defaultValue, placeholder }: EditorProps) => {
    const debounce = useDebounce(250);

    const handleUpdate: EditorProviderProps["onUpdate"] = ({ editor }) => {
        debounce(() => {
            const updated = editor.getHTML();
            if (updated !== defaultValue) {
                onChange(updated);
            }
        });
    };

    const extensions = useMemo(
        () => [
            ...defaultExtensions,
            Placeholder.configure({
                placeholder,
            }),
        ],
        [placeholder]
    );

    return (
        <EditorProvider
            content={defaultValue}
            onUpdate={handleUpdate}
            editorProps={{
                attributes: {
                    class: "rich-text rounded-b-lg text-label border-[1px] border-transparent shadow-editor m-[1px] focus:border-primary focus:shadow-editor-focus hover:shadow-editor-focus py-[14px] px-[16px] focus:outline-none",
                },
            }}
            slotBefore={<MenuBar />}
            extensions={extensions}
        >
            <Updater value={defaultValue} placeholder={placeholder} />
        </EditorProvider>
    );
};

const Updater = ({ value, placeholder }: { value?: string; placeholder?: string }) => {
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);
    const { editor } = useCurrentEditor();
    useEffect(() => {
        if (editor) {
            const updated = editor?.getHTML();
            if (updated !== value && typeof value === "string") {
                editor.commands.setContent(value);
            }
        }
    }, [value]);
    useEffect(() => {
        if (editor) {
            if (currentPlaceholder !== placeholder && typeof placeholder === "string") {
                editor.commands.setContent(placeholder);
            }
        }
    }, [placeholder]);
    return null;
};
