import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import { useTranslation } from "react-i18next";
import { AuthLayout } from "./AuthLayout";
import { AuthForm } from "./AuthForm";
import type { TInput } from "./AuthForm";
import { useEffect, useState } from "react";
import { FieldErrors, useFormContext } from "react-hook-form";

interface ILoginFormValues {
    email: string;
    password: string;
    repeatPassword: string;
}

const _inputs: Array<TInput<ILoginFormValues>> = [
    {
        key: "email",
        type: "email",
        isRequired: true,
        autoFocus: true,
        variant: "outlined",
        InputLabelProps: { shrink: true },
    },
    {
        key: "password",
        type: "password",
        isRequired: false,
        variant: "outlined",
    },
    {
        key: "repeatPassword",
        type: "password",
        isRequired: true,
        autoFocus: false,
        variant: "outlined",
    },
];

export const CreateAccountPage = () => {
    const { t } = useTranslation();

    const { hash } = useLocation();

    const { setValue, reset } = useFormContext();

    const navigate = useNavigate();
    const [inputs, setInputs] = useState(_inputs);
    const { createAccount, getEmailFromToken } = useAuth();
    const [errors, setErrors] = useState<FieldErrors>({});

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        if (hash) {
            const email = getEmailFromToken(hash.substring(1));
            if (email) {
                setInputs((p) =>
                    p.map((input) => {
                        return input.key === "email"
                            ? { ...input, disabled: true, isRequired: false }
                            : input;
                    })
                );
                setValue("email", email);
            }
        }
    }, [hash]);

    const onSubmit = async (data: ILoginFormValues) => {
        const { email, password, repeatPassword } = data;
        if (password !== repeatPassword) {
            return setErrors({
                repeatPassword: { message: t("auth.errors.passwordShouldMatch") as any },
            });
        }
        try {
            await createAccount(email, password);
            navigate("/welcome", { replace: true });
        } catch (err) {
            setErrors({ email: { message: t("auth.errors.cannotLogin") as any } });
        }
    };

    const handleGoToLogin = () => {
        navigate("/login");
    };

    return (
        <AuthLayout state={"create"}>
            {/*<SocialLogins mt={1}/>

            <OrDivider my={3}/>*/}

            <AuthForm
                inputs={inputs}
                showForgotPassword={false}
                shouldAcceptTerms={true}
                onSubmit={onSubmit}
                errors={errors}
                buttonLabel={"Opret din Spiritworld konto"}
            />
        </AuthLayout>
    );
};
