import {
    Box,
    ButtonBase,
    Checkbox,
    CircularProgress,
    Typography,
    styled,
    Button,
    TextField,
    TextFieldProps,
    Link as MuiLink,
    Stack,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, useForm, useFormContext } from "react-hook-form";
import { ArrowForwardRounded } from "@mui/icons-material";
import { TERMS_URL } from "../../Utils/constants";
import { Link } from "react-router-dom";

const Styled = styled("form")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
});

export type TInput<T> = TextFieldProps & {
    key: Extract<keyof T, string>;
    isRequired: boolean;
};

interface IAuthFormProps {
    inputs: Array<
        {
            key: string;
            isRequired: boolean;
        } & TextFieldProps
    >;
    buttonLabel?: string;
    errors?: FieldErrors;
    onSubmit: (data: any) => void;
    shouldAcceptTerms?: boolean;
    showForgotPassword?: boolean;
}

export const AuthForm = ({
    inputs,
    buttonLabel,
    showForgotPassword = true,
    shouldAcceptTerms = false,
    onSubmit,
    errors = {},
}: IAuthFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors: formErrors },
    } = useFormContext();

    const _onSubmit = async (data: any) => {
        if (shouldAcceptTerms && !isAccepted) {
            return;
        }
        setIsSubmitting(true);
        try {
            await onSubmit(data);
        } catch (err: any) {
            console.log(err);
            if (err.message === "incorrectPassword") {
                setError("password", { message: t(`utils.errors.${err.message}`) });
            }

            if (err.message === "incorrectEmail") {
                setError("email", { message: t(`utils.errors.${err.message}`) });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    // Set defaultValue property on TextField, as using setValue won't move the label away.
    // According to the GitHub issue posted here, https://github.com/react-hook-form/react-hook-form/issues/220#issuecomment-649174176,
    // setting the defaultValue to anything but undefined, should resolve the issue.
    return (
        <Styled onSubmit={handleSubmit(_onSubmit)}>
            <Stack alignItems={"flex-end"} mb={3}>
                {inputs.map(({ isRequired, ...el }) => {
                    return (
                        <TextField
                            margin="dense"
                            id={el.key}
                            label={t(`auth.inputs.${el.key}`)}
                            defaultValue=""
                            {...register(el.key, {
                                ...(isRequired && { required: t("utils.errors.required") }),
                            })}
                            sx={{
                                mt: 2,
                                "& fieldset": {
                                    borderColor: "transparent",
                                },
                            }}
                            error={Boolean(errors?.[el.key] ?? formErrors?.[el.key])}
                            helperText={
                                (errors?.[el.key]?.message ??
                                    formErrors?.[el.key]?.message) as string
                            }
                            fullWidth
                            variant="outlined"
                            {...el}
                        />
                    );
                })}
                {showForgotPassword && (
                    <MuiLink
                        component={Link}
                        to={"/forgot"}
                        sx={{ fontSize: "0.8em", color: "#5A2DE2" }}
                    >
                        Glemt kodeord?
                    </MuiLink>
                )}
            </Stack>

            {shouldAcceptTerms && (
                <Box display={"flex"} alignItems={"center"} mb={2}>
                    <Checkbox onChange={(evt, checked) => setIsAccepted(checked)} />
                    <Typography ml={0} lineHeight={"1.5"}>
                        {t("utils.generic.IAccept")}{" "}
                        <MuiLink href={TERMS_URL} target={"_blank"}>
                            Spiritworlds {t("utils.generic.terms")}
                        </MuiLink>
                    </Typography>
                </Box>
            )}

            {buttonLabel ? (
                <Button
                    type={"submit"}
                    disabled={
                        !(!shouldAcceptTerms || (shouldAcceptTerms && isAccepted)) || isSubmitting
                    }
                    startIcon={
                        isSubmitting ? <CircularProgress size={"1em"} color={"inherit"} /> : <></>
                    }
                    size={"large"}
                    variant={"contained"}
                >
                    <span>{buttonLabel}</span>
                </Button>
            ) : (
                <Box
                    component={ButtonBase}
                    p={"12px"}
                    fontSize={28}
                    borderRadius={"20px"}
                    display={"flex"}
                    m={"0px auto 0 auto"}
                    height={72}
                    width={72}
                    color={"white"}
                    type={"submit"}
                    sx={{
                        backgroundColor:
                            !shouldAcceptTerms || (shouldAcceptTerms && isAccepted)
                                ? "primary.main"
                                : "grey.500",
                        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
                        pointerEvents: shouldAcceptTerms && !isAccepted ? "none" : "initial",
                    }}
                >
                    {isSubmitting ? (
                        <CircularProgress size={18} color={"inherit"} />
                    ) : (
                        <ArrowForwardRounded fontSize={"inherit"} htmlColor={"white"} />
                    )}
                </Box>
            )}
        </Styled>
    );
};
