import Fetch from './index'

export const postMessage = async (message: string, notifySlack = true) => {
    if (typeof process.env.REACT_APP_API_TELEMETRY_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TELEMETRY_URL is not defined')
    }

    return Fetch.post(`${process.env.REACT_APP_API_TELEMETRY_URL}collect`, {
        message,
        notifySlack,
        domain: 'admin',
    })
}
