import { object, boolean, array, string, number, mixed } from 'yup'

export type SyiStep = {
    key: string
    label: string
    formKeys: string[]
}

export const serviceSyiSteps = [
    {
        key: 'details',
        label: 'Detaljer',
        formKeys: ['channels', 'type', 'languages'],
    },
    {
        key: 'description',
        label: 'Beskrivelse',
        formKeys: [
            'headline',
            'headline.da',
            'headline.en',
            'whatsIncluded.da',
            'description.da',
            'description',
        ],
    },
    {
        key: 'tags',
        label: 'Ydelsesområde',
        formKeys: ['tags'],
    },
    {
        key: 'pictures',
        label: 'Billeder',
        formKeys: ['pictures', 'pictures.cover'],
    },
    {
        key: 'assignees',
        label: 'Facilitator',
        formKeys: ['assignees'],
    },
    {
        key: 'practicalities',
        label: 'Det praktiske',
        formKeys: [
            'deactivateBeforeDays',
            'locations',
            'guestInfo',
            'customDescriptions',
            'guestRequirements',
            'meetingLink',
        ],
    },
    {
        key: 'time',
        label: 'Tidspunkt',
        formKeys: ['event.startDateTime', 'event.recurring'],
    },
    {
        key: 'price',
        label: 'Pris',
        formKeys: ['session.introSession', 'variants'],
    },
    {
        key: 'customer-info',
        label: 'Kundeinformationer',
        formKeys: ['customData', 'notifications'],
    },
]

export type InjectableStep = {
    step: SyiStep
    when: {
        key: string
        is: string | string[]
    }
    position: number
}

export const serviceTypeEventInjectableSteps: (SyiStep & { position: number })[] = [
    {
        key: 'seats',
        label: 'Pladser',
        formKeys: ['seatCount', 'minimumParticipants'],
        position: 7,
    },
]

export const eventSyiSteps = [
    {
        key: 'details',
        label: 'Detaljer',
        formKeys: ['startDateTime', 'channels', 'endDateTime', 'recurring'],
    },
    {
        key: 'guides',
        label: 'Valg af guides',
        formKeys: ['assignees'],
    },
    {
        key: 'practicalities',
        label: 'Praktisk',
        formKeys: ['meetingLink'],
    },
]

export const discountSyiSteps = [
    {
        key: 'details',
        label: 'Detaljer',
    },
]

export const campaignSyiSteps = [
    {
        key: 'setup',
        label: 'Opsætning',
    },
]

export const funnelSyiSteps = [
    {
        key: 'details',
        label: 'Detaljer',
    },
    {
        key: 'profile',
        label: 'Stamdata',
    },
    {
        key: 'domain',
        label: 'Domæne',
    },
]

export const localizedSchema = object({
    da: string(),
    en: string(),
})

const localizedRequiredSchema = object().when('languages', ([langs], schema) => {
    const [aLang] = langs ?? []
    if (typeof aLang !== 'string') {
        return schema.nullable()
    }
    return schema
        .shape({
            [aLang]: string().required(),
        })
        .required()
})

export const withRequiredOptionSchema = object({
    selectedOptionKey: string().required(),
})

export const withOptionSchema = object({
    selectedOptionKey: string(),
})

export const eventSchema = object().shape({
    startDateTime: string().required(),
    channels: array()
        .of(string().oneOf(['inPerson', 'online']))
        .required(),
    endDateTime: string().required(),
    meetingLink: string().when('channels', (channels, schema) => {
        return channels?.includes('online') ? schema.required() : schema.nullable()
    }),
    assignees: array(),
})

export const funnelSchema = object().shape({
    name: string().required(),
    description: string(),
    alias: string().required(),
    config: object({
        serviceId: string().required(),
        variantId: string().required(),
        discountAmount: number().required(),
    }).required(),
})

export const campaignSchema = object().shape({
    name: string().required(),
    description: string(),
    subject: string().oneOf(['service', 'funnel']).required(),
    subjectId: string().required(),
    assetId: string().required(),
    accountId: string().required(),
    language: string().oneOf(['da', 'en']).required(),
    linkToCheckout: string(),
    assetType: string().oneOf(['instantForm']).required(),
    provider: string().oneOf(['meta']).required(),
})

export const discountSchema = object().shape({
    currency: string(),
    scope: string().required(),
    percentage: boolean().required(),
    rate: mixed().when('_applyForAll', {
        is: false,
        then: s => s.required(),
        otherwise: s => s.nullable(),
    }),
    code: string().min(1, 'required').required(),
    _applyForAll: boolean().required(),
    _shouldExpire: boolean().required(),
    _limitTimesRedeemed: boolean().required(),
    overrides: array().when('_applyForAll', {
        is: false,
        then: schema =>
            schema
                .required()
                .of(
                    object()
                        .shape({
                            enabled: boolean(),
                            rate: mixed().nullable(),
                        })
                        .nullable(),
                )
                .min(1, 'required'),
        otherwise: schema => schema.min(0).nullable(),
    }),
})

const variantItemSchema = object().shape({
    id: string().required(),
    name: mixed()
        .required()
        .test(
            'is-defined',
            'required',
            (value, context) =>
                (typeof value === 'string' && value.length > 0) ||
                (typeof value === 'object' && !Array.isArray(value)),
        ),
    price: mixed()
        .required()
        .test(
            'has-price',
            'required',
            (value, context) => typeof value === 'number' && !isNaN(value),
        ),
    explanation: mixed().nullable(),
    vatId: mixed().nullable(),
    max: mixed().nullable(),
    slotDuration: mixed().nullable(),
})

const customDescriptionsSchema = object({
    selectedOptionKey: string(),
    value: array().when('selectedOptionKey', {
        is: 'yes',
        then: s =>
            s
                .of(
                    object().shape({
                        title: localizedSchema.required(),
                        description: localizedSchema.required(),
                    }),
                )
                .required(),
        otherwise: s => s.nullable(),
    }),
})

const introSessionSchema = object({
    selectedOptionKey: string().required(),
}).when('selectedOptionKey', {
    is: 'yes',
    then: s =>
        s.shape({
            channels: array()
                .of(string().oneOf(['inPerson', 'online', 'byPhone']))
                .min(1)
                .required(),
            duration: number().required(),
            openingHours: array()
                .of(
                    object({
                        fromTime: string().required(),
                        toTime: string().required(),
                        enabled: boolean().required(),
                    }).default({}),
                )
                .required(),
        }),
})

export const serviceSchema = object().shape({
    type: string().oneOf(['session', 'event']).required(),
    languages: array().of(string()).required(),
    channels: array()
        .of(string().oneOf(['inPerson', 'online']))
        .required()
        .min(1, 'required'),
    headline: localizedRequiredSchema.required(),
    description: localizedRequiredSchema.required(),
    whatsIncluded: localizedSchema,
    tags: array().of(string()),
    assignees: array()
        .of(string())
        .when('type', {
            is: 'session',
            then: s => s.required().min(1, 'mustHaveAtLeastOne'),
        }),
    deactivateBeforeDays: withOptionSchema,
    locations: mixed().when('channels', (channels, schema) => {
        return Array.isArray(channels) && channels.includes('inPerson')
            ? withRequiredOptionSchema.required()
            : withOptionSchema.nullable()
    }),
    guestInfo: withOptionSchema,
    guestRequirements: withOptionSchema,
    customDescriptions: customDescriptionsSchema,
    session: object().when('type', {
        is: 'session',
        then: s =>
            s.shape({
                introSession: introSessionSchema.required(),
            }),
    }),
    variants: array().when('session.introSession.selectedOptionKey', {
        is: 'no',
        then: s =>
            s
                .of(
                    object()
                        .shape(variantItemSchema.fields)
                        .shape({
                            addons: array().of(variantItemSchema),
                        }),
                )
                .required(),
        otherwise: s => s.nullable(),
    }),
    meetingLink: string().when('channels', ([channels], schema) => {
        return channels?.includes('online') ? schema.required() : schema.nullable()
    }),
    seatCount: number().when('type', {
        is: 'event',
        then: schema => schema.required(),
        otherwise: schema => schema.nullable(),
    }),
    minimumParticipants: mixed().when('type', {
        is: 'event',
        then: () => withOptionSchema.required(),
        otherwise: () => withOptionSchema,
    }),
    pictures: array().required(),
    customData: mixed(),
    notifications: withOptionSchema,
})
