import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { UseControllerProps, useForm } from "react-hook-form";
import { Input, TInputProps } from "../Components/Input/Input";
import { useTranslate } from "../Hooks/useTranslate";

export const InputDialog = NiceModal.create(
    ({
        inputs = [],
        title,
    }: {
        title?: string;
        inputs?: {
            name: string;
            key: string;
            helperText?: string;
            defaultValue?: string | number;
            rules?: UseControllerProps["rules"];
            props?: TInputProps;
        }[];
    }) => {
        const { t } = useTranslate("utils.generic");

        const modal = useModal();

        const {
            register,
            getValues,
            unregister,
            handleSubmit,
            formState: { errors },
        } = useForm({
            defaultValues: inputs.reduce((acc, el) => {
                return {
                    ...acc,
                    [el.key]: el.defaultValue,
                };
            }, {} as { [key: string]: string | number | undefined }),
        });

        const handleClose = () => {
            modal.remove();
        };

        const handleOk = () => {
            handleSubmit(() => {
                modal.resolve(getValues());
                inputs.forEach((el) => {
                    unregister(el.key);
                });
                handleClose();
            })();
        };

        return (
            <Dialog open={modal.visible} fullWidth={true} maxWidth={"xs"} onClose={handleClose}>
                <Box p={4} pb={2}>
                    {title && (
                        <Typography variant={"h5"} mb={3}>
                            {title}
                        </Typography>
                    )}

                    {inputs.map((el) => {
                        return (
                            <Input
                                sx={{ mb: 2 }}
                                autoFocus={true}
                                error={Boolean(errors[el.key])}
                                label={el.name}
                                helperText={el.helperText}
                                {...(el?.props ?? {})}
                                {...register(el.key, { ...el.rules })}
                            />
                        );
                    })}
                </Box>
                <DialogActions>
                    <Button color={"error"} onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleOk}>{t("ok")}</Button>
                </DialogActions>
            </Dialog>
        );
    }
);
