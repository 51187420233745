import Fetch, { IShallowSuccessResponse } from './index'
import { TLead } from '../Hooks/useLeads'

export const getLeads = async () => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    return Fetch.get<TLead[]>(`${process.env.REACT_APP_API_LEADS_URL}`)
}

export const getLead = async (leadId: string) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    if (!leadId) {
        throw new Error('Missing lead id')
    }

    return Fetch.get<TLead>(`${process.env.REACT_APP_API_LEADS_URL}${leadId}`)
}

export const getRelated = async (id: string) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    return Fetch.get(`${process.env.REACT_APP_API_LEADS_URL}${id}/related`)
}

export const updateLead = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_LEADS_URL}${id}`, payload)
}

export const updateNote = async (leadId: string, noteId: string, text: string, author: string) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    if (!leadId) {
        throw new Error('Missing lead id')
    }

    if (!noteId) {
        throw new Error('Missing note id')
    }

    return Fetch.put(`${process.env.REACT_APP_API_LEADS_URL}${leadId}/note/${noteId}`, {
        text,
        author,
    })
}

export const deleteNote = async (leadId: string, noteId: string) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    if (!leadId) {
        throw new Error('Missing lead id')
    }

    if (!noteId) {
        throw new Error('Missing note id')
    }

    return Fetch.delete(`${process.env.REACT_APP_API_LEADS_URL}${leadId}/note/${noteId}`)
}

export const deleteLead = async (id: string) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_LEADS_URL}${id}`)
}

export const closeLead = async (id: string) => {
    if (typeof process.env.REACT_APP_API_LEADS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_LEADS_URL is not defined')
    }

    return Fetch.post<IShallowSuccessResponse>(`${process.env.REACT_APP_API_LEADS_URL}${id}/close`)
}
