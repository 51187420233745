import { Box, Button, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import * as api from "../../../Api";
import { useOutletContext } from "react-router-dom";
import { PhotoIcon } from "../../../Icons";
import { GalleryDropzone } from "../../../Components/GalleryDropzone/Dropzone";
import { TFileTypes } from "../../../types";
import { Pictures } from "../../../Components/DraggableGallery/Pictures";
import { ErrorLabel } from "../../SyiPage/ErrorLabel";
import { useErrors } from "../../SyiPage/useErrors";
import { useTranslation } from "react-i18next";

export const ServiceSyiSectionPictures = () => {
    const { t } = useTranslation();

    const {
        id: serviceId,
        submitForm,
        isCreateFlow,
    } = useOutletContext<{
        id: string;
        isCreateFlow: boolean;
        submitForm: (forceUpdate: boolean) => void;
    }>();

    const { getValues } = useFormContext();

    const { getError, clearError } = useErrors();

    const { field: pictures } = useController({
        name: "pictures",
        rules: { required: true, validate: (value) => (value.length > 2 ? true : "Need 3 images") },
    });
    const { field: picturesProms } = useController({ name: "picturesPromises" });

    const handlePreparedUpload = (
        image: any,
        fileType: TFileTypes,
        uploadFunc: () => Promise<void>
    ) => {
        const prev = getValues()?.pictures ?? [];
        const prevProms = getValues()?.picturesPromises ?? [];
        pictures.onChange([...prev, image]);
        picturesProms.onChange([...prevProms, uploadFunc]);
        clearError("pictures");
    };

    const handleDeleteUpload = (key: string) => {
        const prev = getValues()?.pictures ?? [];
        const prevProms = getValues()?.picturesPromises ?? [];
        pictures.onChange(prev.filter((el: { key: string }) => el.key !== key));
        picturesProms.onChange([...prevProms, () => api.deleteUpload(key)]);
    };

    const hasImages = useMemo(() => pictures?.value?.length > 0, [pictures.value]);

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>
                    {t("service.create.pictures.title")}
                    <ErrorLabel fontSize={"0.88rem"} label={getError("pictures", "cover")} />
                </Typography>
            </Stack>
            <Typography variant={"h5"} mt={2}>
                {t("service.create.pictures.subtitle")}
            </Typography>

            <Typography mt={1} mb={3} color={"grey.600"}>
                {t("service.create.pictures.helpText")}
            </Typography>

            <Pictures
                onDelete={handleDeleteUpload}
                onUpdate={pictures.onChange}
                pictures={pictures.value ?? []}
            />

            <GalleryDropzone
                modelType={"service"}
                fileType={"cover"}
                showProgress={true}
                id={serviceId}
                onPrepared={handlePreparedUpload}
                containerProps={{
                    sx: {
                        borderRadius: "8px",
                        background: "white",
                        border: hasImages ? "none" : "1px dashed rgba(100,100,100,0.15)",
                        "&:hover": {
                            backgroundColor: hasImages ? "transparent" : "rgba(252,252,252,0.8)",
                        },
                    },
                }}
            >
                <Box mt={hasImages ? 2 : 8} mb={8}>
                    {!hasImages && (
                        <>
                            <PhotoIcon fontSize={"large"} />
                            <Typography mt={2} mb={2} variant={"h5"}>
                                {t("service.create.pictures.uploadTextEmpty")}
                            </Typography>
                        </>
                    )}
                    {hasImages && (
                        <Typography mt={1} mb={2} variant={"h6"} color={"grey.600"}>
                            {t("service.create.pictures.uploadTextFilled", {
                                imageCount: pictures.value.length,
                            })}
                        </Typography>
                    )}
                    <Button
                        size={"large"}
                        sx={{ minWidth: 240 }}
                        color={"secondary"}
                        variant={"outlined"}
                        fullWidth
                    >
                        {t("service.create.pictures.uploadButtonLabel")}
                    </Button>
                </Box>
            </GalleryDropzone>

            <Box mt={3}>
                <Typography variant={"h6"}>
                    {t("service.create.pictures.requirementsTitle")}
                </Typography>
                <Box component={"ul"} pl={2} mt={2} lineHeight={"2px"}>
                    {Array.from(Array(3).keys()).map((el, i) => {
                        return (
                            <Typography mt={2} mb={2} variant={"h6"} component={"li"}>
                                {t(`service.create.pictures.requirement${i + 1}`)}
                            </Typography>
                        );
                    })}
                </Box>
            </Box>
        </>
    );
};
