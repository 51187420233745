import {
    Box,
    BoxProps,
    capitalize,
    Card,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuItemProps,
    Stack,
    Typography,
} from "@mui/material";
import { DeleteForeverRounded, EditRounded, MoreVert, SvgIconComponent } from "@mui/icons-material";
import { FacebookIcon } from "../../Icons";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { renderDate } from "@hiddengemgroup/utils-date";
import { useLocation, useNavigate } from "react-router-dom";
import react, { MouseEvent, useState } from "react";
import { BlackMenu } from "../../Components/BlackMenu/BlackMenu";
import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { ListSkeleton } from "../../Components/ListSkeleton/ListSkeleton";
import { EnrichedCampaign } from "../../Hooks/useCampaigns";

const getCampaignGoal = (campaign: EnrichedCampaign) => {
    if (campaign.subject.type === "funnel") {
        return "firstTimeVoucher";
    }
    if (campaign.subject.type === "service") {
        if (campaign.subject.variantName) {
            return "variantCheckout";
        }
        return "introSession";
    }
    return "none";
};

const columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        value: () => <FacebookIcon />,
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
    },
    {
        key: "subject",
        label: "Type",
        props: {
            xs: 1.5,
        },
        value: (el: EnrichedCampaign, t: TranslateFunction) =>
            capitalize(t(el.subject.type, "utils.generic")),
    },
    {
        key: "created",
        label: "Oprettet",
        props: {
            xs: 2,
        },
        value: (el: EnrichedCampaign) => renderDate(el.created, "ddmmyyyy"),
    },
    {
        key: "goal",
        label: "Mål",
        props: {
            xs: 1.5,
        },
        value: (el: EnrichedCampaign, t: TranslateFunction) =>
            t(getCampaignGoal(el), "utils.generic"),
    },
    {
        key: "service",
        label: "Ydelse",
        value: (el: EnrichedCampaign) => el.subject.name,
        props: {
            xs: 2,
        },
    },
    {
        key: "variant",
        label: "Billettype",
        value: (el: EnrichedCampaign) => el.subject.variantName ?? "",
        props: {
            xs: 2,
        },
    },
    {
        key: "leads",
        label: "Henvendelser",
        value: (el: EnrichedCampaign) => el.leadCount,
        props: {
            xs: 1,
        },
    },
    {
        key: "bookings",
        label: "Bookings",
        value: (el: EnrichedCampaign) => el.bookingCount,
        props: {
            xs: 1,
        },
    },
];

const options: {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
}[] = [
    {
        key: "edit",
        label: "Rediger",
        icon: EditRounded,
    },
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

export const CampaignsList = ({
    campaigns,
    onDelete,
    ...props
}: BoxProps & { onDelete: (id: string) => void; campaigns?: EnrichedCampaign[] }) => {
    const { t } = useTranslate("utils.tables.header");

    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | [HTMLElement, number]>(null);
    const open = Boolean(anchorEl);

    const handleClick = (campaignId: string) => () => {
        navigate(`/campaign/${campaignId}/edit`, { state: { returnUrl: location.pathname } });
    };

    const handleClickOptions = (index: number) => (event: react.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl([event.currentTarget, index]);
    };

    const handleCloseOptions = (evt: MouseEvent) => {
        evt.stopPropagation();
        setAnchorEl(null);
    };

    const handleSelectOption = (key: string, index?: number) => (evt: MouseEvent) => {
        if (index === undefined) {
            return;
        }
        if (key === "edit") {
            const { id } = campaigns?.[index] ?? {};
            if (id) {
                handleClick(id)();
            }
        }
        if (key === "delete") {
            NiceModal.show(ConfirmDialog, {
                headline: t("headline", "dialogs.confirmDelete", {
                    type: t("thisDiscount", "utils.generic"),
                }),
                title: t("title", "dialogs.confirmDelete"),
                confirmLabel: t("actions.primary", "dialogs.confirmDelete"),
            }).then(() => {
                const { id } = campaigns?.[index] ?? {};
                if (id) {
                    onDelete(id);
                }
            });
        }
        handleCloseOptions(evt);
    };

    return (
        <Box {...props}>
            <BlackMenu
                anchorEl={anchorEl?.[0]}
                open={open}
                onClose={handleCloseOptions}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {options.map((el, i) => {
                    const Icon = el.icon as any;
                    return (
                        <MenuItem
                            autoFocus={false}
                            key={el.key}
                            onClick={handleSelectOption(el.key, anchorEl?.[1])}
                        >
                            <ListItemIcon>
                                <Icon fontSize={"small"} {...el.props} />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={el.props}>
                                {el.label}
                            </ListItemText>
                        </MenuItem>
                    );
                })}
            </BlackMenu>

            <Grid container p={"12px"} mb={1}>
                {columns.map((el) => (
                    <Grid
                        fontSize={"0.75em"}
                        fontWeight={600}
                        textTransform={"uppercase"}
                        key={el.key}
                        item
                        {...el.props}
                        component={Typography}
                    >
                        {el.key !== "none" && t(el.key)}
                    </Grid>
                ))}
            </Grid>

            <Stack spacing={1}>
                {campaigns?.map((campaign, i) => (
                    <Grid
                        container
                        key={campaign.id}
                        component={Card}
                        p={1.5}
                        alignItems={"center"}
                        sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "rgb(252,252,252)" },
                        }}
                        onClick={handleClick(campaign.id)}
                    >
                        {columns.map((el) => (
                            <Grid
                                fontSize={"0.88em"}
                                key={el.key}
                                item
                                {...el.props}
                                {...el.valueProps}
                                component={Typography}
                            >
                                {el.value?.(campaign, t)}
                            </Grid>
                        ))}
                        <Grid item flexGrow={1} textAlign={"right"}>
                            <IconButton size={"small"} onClick={handleClickOptions(i)}>
                                <MoreVert />
                            </IconButton>
                        </Grid>
                    </Grid>
                )) ?? <ListSkeleton />}
            </Stack>
        </Box>
    );
};
