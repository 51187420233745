export const DEFAULT_PAGE = 'services'
export const DEFAULT_AUTH_IMAGE = '/login/animation/bg.png'
export const LOGO_SQUARE = '/spiritworld-logo-square.png'
export const LOGO = '/spiritworld-logo.svg'
export const LOGO_WHITE = '/spiritworld-logo-white.svg'
export const DEFAULT_FORM_SEPARATOR = '.'
export const DEFAULT_FORM_PREFIX = '#'
export const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_BASE_URL

export const META_URL = process.env.REACT_APP_API_META_FACADE_URL
export const OUTLOOK_URL = process.env.REACT_APP_API_OUTLOOK_FACADE_URL
export const FUNNEL_URL =
    process.env.REACT_APP_FUNNEL_CONSUMER_BASE_URL ?? 'http://$ALIAS.tilbud.localhost:3005'
export const EXPERIENCE_URL = 'http://{{domain}}.localhost:3005'
export const CONSUMER_URL = process.env.REACT_APP_CONSUMER_URL ?? 'http://localhost:3005'
export const ROLE_OPTIONS = [
    { key: 'admin', label: 'Admin' },
    { key: 'viewer', label: 'Viewer' },
]
export const TERMS_URL = process.env.REACT_APP_TERMS_URL
