import Fetch from './index'

export const getMetrics = async (from: string, to: string, serviceId: string | null) => {
    if (typeof process.env.REACT_APP_API_TRACKING_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TRACKING_URL is not defined')
    }

    return Fetch.get<{ [date: string]: { key: string; value: number }[] }>(
        `${process.env.REACT_APP_API_TRACKING_URL}metrics`,
        {
            params: {
                from,
                to,
                serviceId,
            },
        },
    )
}

export const getFunnelMetrics = async (type: 'service' | 'funnel', id: string) => {
    if (typeof process.env.REACT_APP_API_TRACKING_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TRACKING_URL is not defined')
    }

    return Fetch.get<{ key: string; value: number }[]>(
        `${process.env.REACT_APP_API_TRACKING_URL}metrics/funnel`,
        {
            params: {
                mt: type,
                mid: id,
            },
        },
    )
}

export const getMetricsForExperience = async (experienceId: string, from: string, to: string) => {
    if (typeof process.env.REACT_APP_API_TRACKING_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TRACKING_URL is not defined')
    }

    return Fetch.get<{ [date: string]: { key: string; value: number }[] }>(
        `${process.env.REACT_APP_API_TRACKING_URL}metrics/experience/${experienceId}`,
        {
            params: {
                from,
                to,
            },
        },
    )
}
