import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Popover,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Tip } from "../../Components/Tip/Tip";
import {
    ArrowBackRounded,
    ArrowForwardRounded,
    CheckRounded,
    EditRounded,
} from "@mui/icons-material";
import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { ProfileUpload } from "../../Components/ProfileUpload/ProfileUpload";
import type { TInput } from "./OnBoardingPage";
import { useOutletContext } from "react-router-dom";
import { useController, UseControllerProps, useFormState, useWatch } from "react-hook-form";
import { useTranslate } from "../../Hooks/useTranslate";
import { InputStatus } from "../../Components/InputStatus/InputStatus";
import * as api from "../../Api";
import { CountrySelect } from "../../Components/CountrySelect/CountrySelect";
import { validUrl } from "../../Utils/helpers";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";
import { Footer } from "./ContentBlock";

export const OnBoardingCompanySection = () => {
    const { t } = useTranslate("onboarding.company");

    const {
        handleUploadPrepared,
        registerCompany,
        handleBack,
        handleNext,
        handleSubmit,
        getCompanyValues,
        onSubmitCompany,
        company,
        companyControl,
    } = useOutletContext<any>();

    const { errors } = useFormState({ control: companyControl });

    const pictures = useWatch({ name: "pictures", control: companyControl });
    const { field: country } = useController({
        name: "location.country",
        defaultValue: "DK",
        control: companyControl,
    });

    const picture = useMemo(() => {
        const { pictures } = getCompanyValues();
        const { localUrl, ...props } = pictures?.logo ?? {};
        return { ...props, url: localUrl };
    }, [pictures]);

    const companyInputs: TInput[] = useMemo(() => {
        return [
            {
                key: "name",
                name: "Virksomhedsnavn",
                helperText: "Som det fremgår i CVR registret",
                props: {
                    fullWidth: true,
                },
            },
            {
                key: "website",
                name: "Hjemmeside",
                helperText: "Fx. min-hjemmeside.dk",
                props: {
                    type: "url",
                },
            },
            {
                key: "cvrNr",
                name: "CVR nummer",
            },
            {
                key: "ownerName",
                name: "Ejer af virksomhed",
                helperText: "Indtast navn",
            },
            {
                key: "companyEmail",
                name: "Email på virksomhed",
                props: {
                    type: "email",
                },
            },
            {
                key: "companyPhone",
                name: "Telefonnummer på virksomhed",
                props: {
                    type: "phone",
                },
            },
            {
                key: "location.address",
                name: "Virksomhedsadresse",
            },
            {
                key: "location.city",
                name: "By",
            },
            {
                key: "location.zipCode",
                name: "Postnummer",
            },
        ];
    }, []);

    return (
        <>
            <Typography mb={2} variant={"h3"}>
                {t("boxTitle")}
            </Typography>
            <Tip filled label={t("logoHelpText")} />
            <Typography mt={3} mb={2} variant={"h5"}>
                {t("logoTitle")}
            </Typography>
            <ProfileUpload
                onUploadPrepared={handleUploadPrepared("company")}
                fileType={"logo"}
                label={t("logoButtonHelpText")}
                media={picture}
                subjectId={company?.id}
                subject={"company"}
                containerProps={{
                    sx: {
                        borderColor: "transparent",
                    },
                }}
            />

            <Divider sx={{ mt: 3, mb: 3 }} />

            <Grid sx={{ pb: 12 }} container spacing={2} columns={2} mt={0} mb={4}>
                {companyInputs.map((el, i) => {
                    const helperText = errors[el.key]?.message ?? t(`${el.key}HelpText`);
                    return (
                        <Grid item xs={el.props?.fullWidth ? 1 : 1}>
                            <TextField
                                autoFocus={i === 0}
                                label={t(`${el.key}Placeholder`)}
                                error={errors[el.key]}
                                fullWidth={true}
                                helperText={helperText}
                                sx={{
                                    ...(typeof helperText !== "string" && {
                                        "& .MuiFormHelperText-root": {
                                            ml: 0,
                                            mr: 0,
                                        },
                                    }),
                                }}
                                {...(el?.props ?? {})}
                                {...registerCompany(el.key, {
                                    required: true,
                                    ...(el.rules ?? {}),
                                })}
                            />
                        </Grid>
                    );
                })}
                <Grid item flexGrow={1}>
                    <CountrySelect
                        value={country.value}
                        onChange={country.onChange}
                        sx={{ maxWidth: 320, width: "100%" }}
                    />
                </Grid>
            </Grid>

            <Footer>
                <Button
                    onClick={handleBack}
                    startIcon={<ArrowBackRounded />}
                    variant={"outlined"}
                    size={"large"}
                    color={"secondary"}
                >
                    {t("back", "buttons")}
                </Button>
                <ProgressButton
                    size={"large"}
                    variant={"contained"}
                    onClick={onSubmitCompany(handleSubmit)}
                    startIcon={<ArrowForwardRounded />}
                    label={t("next", "buttons")}
                />
            </Footer>
        </>
    );
};

const getDomain = (url: URL) => {
    if (!url.host.includes(".")) {
        throw new Error("Invalid url");
    }
    const [domain] = url.host.split(".");
    return domain ?? "";
};

const DomainCheck = ({ control }: { control?: UseControllerProps["control"] }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { t } = useTranslate("utils.errors");

    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const [loading, setLoading] = useState(false);
    const { field: domain } = useController({ name: "domain", control, rules: { required: true } });
    const website = useWatch({ name: "website", control });

    const [state, setState] = useState<Error | string>("empty");

    useEffect(() => {
        if (validUrl(website) && !domain.value) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(async () => {
                try {
                    setLoading(true);
                    const host = getDomain(new URL(website));
                    const { available } = await api.domainAvailable(host);
                    if (available) {
                        domain.onChange(host);
                        setState("valid");
                    } else {
                        domain.onChange(undefined);
                        setState(new Error(t("domainUnavailable")));
                    }
                } catch (err) {
                    setState(new Error(t("website")));
                } finally {
                    setLoading(false);
                }
            }, 500);
        }
    }, [website]);

    const isValid = !(state instanceof Error);

    const handleClosePopover = () => setAnchorEl(null);

    const handleClickEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleChangeDomain = (val: string) => {
        domain.onChange(val);
        setState("valid");
        handleClosePopover();
    };

    return (
        <>
            {state !== "empty" && (
                <InputStatus status={isValid ? "success" : "error"}>
                    <Typography fontSize={"1em"} pr={4} flexGrow={1}>
                        {isValid ? (
                            <>
                                {t("yourDomain", "utils.generic")}{" "}
                                <strong>{domain.value}.spiritworld.dk</strong>
                            </>
                        ) : (
                            state.message
                        )}
                    </Typography>
                    {loading ? (
                        <CircularProgress size={"1.5em"} />
                    ) : (
                        <IconButton size={"small"} onClick={handleClickEdit}>
                            <EditRounded fontSize={"small"} />
                        </IconButton>
                    )}
                    {anchorEl && (
                        <DomainPopover
                            domain={domain.value}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            onSubmit={handleChangeDomain}
                        />
                    )}
                </InputStatus>
            )}
        </>
    );
};

export const DomainPopover = ({
    anchorEl,
    domain,
    onClose,
    onSubmit,
}: {
    anchorEl: HTMLElement | null;
    domain?: string;
    onClose: () => void;
    onSubmit: (domain: string) => void;
}) => {
    const { t } = useTranslate("utils.generic");

    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const [hasChanged, setHasChanged] = useState(false);

    const [input, setInput] = useState<string | undefined>(domain);
    const [loading, setLoading] = useState(false);

    const [valid, setValid] = useState(false);

    useEffect(() => {
        if (input) {
            clearTimeout(timeout.current);
            setLoading(true);
            timeout.current = setTimeout(async () => {
                try {
                    setLoading(true);
                    const { available } = await api.domainAvailable(input);
                    setValid(available);
                } catch (err) {
                    setValid(false);
                } finally {
                    setLoading(false);
                    if (input !== domain) {
                        setHasChanged(true);
                    }
                }
            }, 500);
        }
        setValid(false);
    }, [input]);

    const handleSave = () => {
        if (input) {
            onSubmit(input);
        }
    };

    return (
        <Popover
            open={true}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <Box p={3} maxWidth={320}>
                <TextField
                    fullWidth
                    onChange={(e) => setInput(e.target.value)}
                    label={t("inputDomain")}
                    defaultValue={domain}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment sx={{ opacity: 0.5 }} position="end">
                                .spiritworld.dk
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        ...(valid && {
                            "& fieldset": {
                                borderWidth: 2,
                                borderColor: "success.main",
                            },
                        }),
                    }}
                />
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={0.5}
                >
                    <Typography variant={"body2"} color={valid ? "success" : "error"}>
                        {input &&
                            hasChanged &&
                            !loading &&
                            (valid
                                ? t("domainAvailable", "utils.generic")
                                : t("domainUnavailable", "utils.errors"))}
                    </Typography>
                    {input && (hasChanged || loading) && (
                        <Button
                            onClick={handleSave}
                            disabled={!valid}
                            color={"success"}
                            size={"small"}
                            startIcon={
                                loading ? (
                                    <CircularProgress size={"1em"} />
                                ) : valid ? (
                                    <CheckRounded />
                                ) : null
                            }
                        >
                            {loading
                                ? t("checkingDomainAvailability")
                                : t("saveAndClose", "buttons")}
                        </Button>
                    )}
                </Stack>
            </Box>
        </Popover>
    );
};
