import { Box, Button, Card, Divider, SelectProps, Stack, Typography } from "@mui/material";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useService } from "../../../Hooks/useService";
import {
    mapSelectedLanguages,
    SOURCE_LANG,
} from "../../Services/ServiceSyiSections/ServiceSyiSectionDescription";
import { useOutletContext } from "react-router-dom";
import { AddRounded } from "@mui/icons-material";
import React, { useEffect, useMemo } from "react";
import { SyiSection } from "../../SyiPage/SyiSection";
import { StatusChip } from "../../../Components/StatusChip";
import { formatISO } from "date-fns";
import { TUser } from "../../../Hooks/useUsers";
import { useErrors } from "../../SyiPage/useErrors";
import { languagesOptions } from "../../../Utils/config";
import { LanguageChips } from "../../../Components/LanguageChips/LanguageChips";
import { useTranslate } from "../../../Hooks/useTranslate";
import { DateController } from "../../../Components/DateController/DateController";
import { Picture } from "../../../Components/Picture/Picture";
import { useTranslation } from "react-i18next";
import { OptionChips } from "../../../Components/OptionChips/OptionChips";
import { mapOptions } from "../../../Utils/helpers";
import { serviceChannelOptions } from "../../Services/ServiceSyiSections/ServiceSyiSectionDetails";
import { ServiceSelect } from "../../../Components/ServiceSelect";
import { TranslatableGroup } from "../../../Components/TranslatableGroup/TranslatableGroup";
import {
    OptionGroup,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import {
    RecurringControl,
    useRecurringOptions,
} from "../../../Components/RecurringControl/RecurringControl";

export const mapAssignableGuidesToUsers = (ids: string[], users?: TUser[]) => {
    return (
        ids?.reduce((acc, id) => {
            const found = users?.find((el) => el.id === id);
            return found ? [...acc, found] : acc;
        }, [] as any[]) ?? []
    );
};

const addDaysToDate = (date: Date, days = 1) => {
    date.setDate(date.getDate() + 1);
    return date;
};

const alignDay = (str: string, str2: string) => {
    const d = new Date(str).getDate();
    const m = new Date(str).getMonth();
    const y = new Date(str).getFullYear();
    const _new = new Date(str2);
    _new.setFullYear(y, m, d);
    return _new.toString();
};

export const toISODateTime = (date: Date) => {
    const _t = formatISO(date).split("+")[0];
    return _t.substring(0, _t.length - 3);
};

type Localized = {
    [language: string]: string;
};

export type LocalizeFunc = (value?: Localized | string) => string;

export const useLocalizer = () => {
    const { i18n } = useTranslation();
    return ((value?: Localized | string) => {
        if (typeof value === "string") {
            return value.trim() ? value : undefined;
        }
        const val = value?.[i18n.language] ?? Object.values(value ?? {})[0] ?? "";
        return val.trim() ? val : undefined;
    }) as LocalizeFunc;
};

export const EventSyiSectionDetails = () => {
    const { t } = useTranslate("events.create.details");

    const { setValue } = useFormContext();

    const localize = useLocalizer();

    const { field: serviceId } = useController({ name: "serviceId" });

    const { getError, withClear, clearError } = useErrors();

    const { state } = useOutletContext<any>();

    const {
        service: { data: service, isLoading },
    } = useService(serviceId.value);

    const { field: languages } = useController({ name: "languages", defaultValue: [SOURCE_LANG] });

    const { field: bookings } = useController({ name: "bookings" });
    const { field: isSameDay } = useController({ name: "isSameDay", defaultValue: true });
    const { field: startDateTime } = useController({ name: "startDateTime" });
    const { field: endDateTime } = useController({ name: "endDateTime" });

    const startDateInput = useWatch({ name: "startDateTime" });
    const endDateInput = useWatch({ name: "endDateTime" });

    const [aChannel] = service?.channels ?? [];

    const { recurringOptions, handleChangeRecurring } = useRecurringOptions();

    const { field: channels } = useController({
        name: "channels",
        defaultValue: aChannel ? [aChannel] : [],
    });

    const hasBookings = useMemo(() => {
        return Boolean(bookings.value?.length > 0);
    }, [bookings]);

    useEffect(() => {
        if (startDateInput) {
            clearError("startDateTime");
        }
        if (endDateInput) {
            clearError("endDateTime");
        }
    }, [startDateInput, endDateInput]);

    useEffect(() => {
        const { endDateTime, startDateTime } = state ?? {};
        if (endDateTime && startDateTime) {
            setValue("startDateTime", startDateTime, { shouldDirty: false });
            setValue("endDateTime", endDateTime, { shouldDirty: false });
        }
    }, []);

    const handleAddEndDate = () => {
        endDateTime.onChange(
            toISODateTime(addDaysToDate(new Date(endDateTime?.value ?? startDateTime?.value)))
        );
        isSameDay.onChange(false);
    };

    const handleDeleteEndDate = () => {
        endDateTime.onChange(alignDay(startDateTime.value, endDateTime.value));
        isSameDay.onChange(true);
    };

    const handleChangeService: SelectProps["onChange"] = (evt) => {
        serviceId.onChange(evt.target.value);
    };

    const languageOptions = useMemo(() => {
        return service?.languages?.reduce((obj: { [k: string]: any }, key: string) => {
            return {
                ...obj,
                [key]: {
                    ...languagesOptions[key],
                },
            };
        }, {});
    }, [service]);

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            <Stack mt={2} divider={<Divider />} spacing={4}>
                <SyiSection Component={Stack} alignItems={"flex-start"} title={t("serviceTitle")}>
                    {serviceId.value ? (
                        <Box
                            component={Card}
                            padding={2}
                            display={"flex"}
                            justifyContent={"space-between"}
                            width={"100%"}
                            alignItems={"center"}
                        >
                            <Box display={"flex"} alignItems={"center"}>
                                <Picture
                                    borderRadius={1}
                                    height={48}
                                    minWidth={48}
                                    source={service?.pictures?.[0]?.url ?? ""}
                                    aspectRatio={1}
                                />
                                <Typography ml={2} variant={"h5"}>
                                    {localize(service?.headline)}
                                </Typography>
                            </Box>
                            <StatusChip status={service?.status as any} />
                        </Box>
                    ) : (
                        <ServiceSelect
                            serviceId={serviceId.value}
                            inputLabel={t("buttons.selectService")}
                            onChange={handleChangeService}
                            type={"event"}
                        />
                    )}
                </SyiSection>

                {serviceId.value && (
                    <>
                        <TranslatableGroup
                            langs={mapSelectedLanguages(languages.value ?? [], languagesOptions)}
                            propKey={"headline"}
                            title={t("headline.title")}
                            input={{
                                type: "input",
                                props: {
                                    helperText: t("headline.inputHelperText"),
                                },
                                placeholder: t("headline.inputLabel"),
                            }}
                            clearError={clearError}
                            error={getError("headline")}
                        />
                        <SyiSection title={t("languageTitle")}>
                            <LanguageChips
                                onChange={languages.onChange}
                                multiple={true}
                                allowEmpty={true}
                                disabled={hasBookings}
                                languages={languages.value}
                                languageOptions={languageOptions}
                                mt={3}
                            />
                        </SyiSection>
                        {service?.channels && service.channels.length > 1 && (
                            <SyiSection
                                title={t("channels", { serviceType: service?.type })}
                                error={getError("channels")}
                            >
                                <OptionChips
                                    disabled={hasBookings}
                                    selectedOptions={channels.value}
                                    multiple={false}
                                    onChange={withClear("channels", channels.onChange)}
                                    allOptions={mapOptions(
                                        serviceChannelOptions.filter((f) =>
                                            service?.channels?.includes(f.key)
                                        )
                                    )}
                                />
                            </SyiSection>
                        )}

                        <SyiSection
                            Component={Stack}
                            alignItems={"start"}
                            spacing={3}
                            error={getError("startDateTime") ?? getError("endDateTime")}
                            typographyProps={{ mb: 1 }}
                            title={t("selectTimeTitle")}
                        >
                            <DateController
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                hideTo={!isSameDay.value}
                                disabled={hasBookings}
                                name={"startDateTime"}
                                label={
                                    isSameDay.value
                                        ? t("date", "utils.generic")
                                        : t("startDate", "utils.generic")
                                }
                            />
                            {!isSameDay.value ? (
                                <DateController
                                    label={t("endDate", "utils.generic")}
                                    hideFrom={true}
                                    disabled={hasBookings}
                                    onDelete={hasBookings ? undefined : handleDeleteEndDate}
                                    startDateTime={endDateTime}
                                    endDateTime={endDateTime}
                                    name={"endDateTime"}
                                />
                            ) : (
                                <Button
                                    disabled={hasBookings || endDateTime.value === null}
                                    sx={{ borderRadius: 100 }}
                                    color={"secondary"}
                                    variant={"outlined"}
                                    onClick={handleAddEndDate}
                                    startIcon={<AddRounded />}
                                >
                                    {t("buttons.addEndDate")}
                                </Button>
                            )}
                        </SyiSection>
                        {!hasBookings && startDateTime.value && (
                            <SyiSection title={t("recurring.title")}>
                                <OptionGroup
                                    name={"recurring.selectedOptionKey"}
                                    options={recurringOptions}
                                    onChangeValue={handleChangeRecurring}
                                >
                                    <OptionGroupSelectedSection optionKey={"yes"} mt={2}>
                                        <RecurringControl />
                                    </OptionGroupSelectedSection>
                                </OptionGroup>
                            </SyiSection>
                        )}
                    </>
                )}
            </Stack>
        </>
    );
};
