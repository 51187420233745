import { Box, BoxProps, Button, Stack } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { ReactNode, useLayoutEffect } from "react";
import { StatusChip } from "../../Components/StatusChip";
import { useTranslate, TranslateFunction } from "../../Hooks/useTranslate";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { useForm, FormProvider } from "react-hook-form";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { formatMoney } from "../../Components/VariantSelect/VariantSelect";
import { ViewHeader } from "./ViewHeader";
import { ViewFooter } from "./ViewFooter";
import { EnrichedOffer, useOffers } from "../../Hooks/useOffers";
import { ReducedPrice } from "../../Components/ReducedPrice";
import { LabelWithButton } from "../../Components/LabelWithButton";
import { formatDistanceToNow } from "date-fns";
import { LoadingRow } from "../../Components/LoadingRow";
import { CONSUMER_URL } from "../../Utils/constants";
import { ConfirmDialog } from "../ConfirmDialog";
import { useNavigate } from "react-router-dom";

type TRow = {
    key: string;
    label: string;
    value: (
        el: EnrichedOffer,
        t: TranslateFunction,
        handleClose: () => void
    ) => string | number | ReactNode;
    props?: BoxProps;
};

const rows: TRow[] = [
    {
        key: "offerId",
        label: "Tilbud ID",
        value: (el) =>
            el.id && (
                <LabelWithButton
                    label={el.id}
                    buttonLabel={"Vis tilbud"}
                    href={`${CONSUMER_URL}/offer/${el.id}`}
                />
            ),
    },
    {
        key: "created",
        label: "Oprettelsesdato",
        value: (el) => el.created && renderDateTime(el.created),
    },
    {
        key: "paymentDate",
        label: "Betalingstidpunkt",
        value: (el) =>
            el.transaction?.timestamp ? (
                <LabelWithButton
                    label={renderDateTime(el.transaction.timestamp)}
                    buttonLabel={"Vis kvittering"}
                    href={`${CONSUMER_URL}/receipt/${el.transaction.receiptId}`}
                />
            ) : (
                <em>Ikke betalt endnu</em>
            ),
    },
    {
        key: "price",
        label: "Beløb",
        value: (el) => (
            <ReducedPrice
                originalPrice={el?.price?.totalAmount ?? 0}
                reducedPrice={(el?.price?.totalAmount ?? 0) - (el.discount?.rate ?? 0)}
                currency={el?.price?.currency ?? "dkk"}
                inline
            />
        ),
    },
    {
        key: "discount",
        label: "Rabat",
        value: (el, t) =>
            el.discount &&
            formatMoney(t)({
                value: el.discount.rate,
                nativeCurrency: el.price.currency,
            }),
    },
    {
        key: "status",
        label: "Status",
        value: (el) => (
            <StatusChip
                size={"small"}
                status={el.refund ? "refunded" : el.transaction ? "paid" : "unpaid"}
            />
        ),
        props: {
            fontSize: "1.1em",
            mb: 3,
        },
    },
    {
        key: "sessionsTotal",
        label: "Antal sessioner i alt",
        value: (offer) => offer.totalQuantity,
    },
    {
        key: "sessionsAvailable",
        label: "Kanal",
        value: (offer) => offer.availableQuantity,
    },
    {
        key: "serviceName",
        label: "Ydelse",
        value: (el, _, handleClose) => (
            <LabelWithButton
                label={el.firstItem.name}
                buttonLabel={"Gå til ydelse"}
                onClick={handleClose}
                href={`/service/${el.firstItem.serviceId}`}
            />
        ),
    },
];

export const OfferView = ({
    offerId,
    onBack,
    onLoadCustomer,
    ...props
}: BoxProps & {
    offerId: string;
    onBack: () => void;
    onLoadCustomer: (customerId: string) => void;
}) => {
    const { t } = useTranslate("dialogs.offer");

    const modal = useModal();
    const navigate = useNavigate();

    const {
        offer: { isLoading },
        enrichedOffer: offer,
        refundOffer,
        deleteOffer,
    } = useOffers(offerId);

    const { getValues, setValue, ...methods } = useForm();

    const handleClose = () => {
        modal.remove();
        navigate("/offers");
    };

    useLayoutEffect(() => {
        if (offer?.customer?.id) {
            onLoadCustomer(offer.customer.id);
        }
    }, [offer]);

    const handleRefund = () => {
        if (offer?.transaction !== undefined && !offer?.refund) {
            NiceModal.show(ConfirmDialog, {
                title: t("title", "dialogs.refund"),
                headline: t("headline", "dialogs.refund", {
                    customerName: offer?.customer.name,
                    refundAmount: `${offer?.transaction?.totalAmount} ${offer?.price.currency}`,
                }),
                confirmLabel: t("actions.primary", "dialogs.refund"),
            }).then(() => refundOffer.mutate());
        }
    };

    const handleDelete = () => {
        if (offer?.refund && offer?.transaction !== undefined && !hasBookings) {
            NiceModal.show(ConfirmDialog, {
                headline: t("headline", "dialogs.confirmDelete", {
                    type: t("thisOffer", "utils.generic"),
                }),
                title: t("title", "dialogs.confirmDelete"),
            }).then(() => {
                deleteOffer.mutate(offerId);
                handleClose();
            });
        }
    };

    const hasBookings = offer?.availableQuantity !== offer?.totalQuantity;

    return (
        <Box {...props}>
            <FormProvider {...{ getValues, setValue, ...methods }}>
                <ViewHeader title={"Tilbud"} onBack={onBack} />
                <Stack spacing={0.5}>
                    {offer ? (
                        rows.map((row) => (
                            <LoadingRow
                                key={row.key}
                                label={t(row.key, "utils.tables.header")}
                                value={row.value(offer as EnrichedOffer, t, handleClose)}
                                isLoading={isLoading}
                                {...row.props}
                            />
                        ))
                    ) : (
                        <SimpleSkeleton />
                    )}
                    {!offer?.transaction && offer?.expiryDate && (
                        <LoadingRow
                            label={t("expiryDate", "utils.tables.header")}
                            value={
                                offer.expiryDate && formatDistanceToNow(new Date(offer.expiryDate))
                            }
                            isLoading={isLoading}
                        />
                    )}
                </Stack>

                <ViewFooter>
                    {!offer?.refund?.timestamp && offer?.transaction !== undefined && (
                        <Button variant={"outlined"} color={"secondary"} onClick={handleRefund}>
                            {t("actions.refund")}
                        </Button>
                    )}
                    {offer?.refund && offer?.transaction !== undefined && !hasBookings && (
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDelete}>
                            {t("delete", "utils.generic")}
                        </Button>
                    )}
                </ViewFooter>
            </FormProvider>
        </Box>
    );
};
