import {
    Box,
    BoxProps,
    Button,
    Card,
    Chip,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import React from "react";
import { GiftcardIcon } from "../../Icons";
import type { TBoughtVoucher } from "../../Hooks/useVoucher";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { renderDate } from "@hiddengemgroup/utils-date";
import { useNavigate } from "react-router-dom";

const columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        value: () => <GiftcardIcon />,
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
    },
    {
        key: "code",
        label: "Kode",
        props: {
            xs: 1.5,
        },
        value: (el: TBoughtVoucher) => el?.code,
    },
    {
        key: "totalAmount",
        label: "Værdi",
        props: {
            xs: 2,
        },
        value: (el: TBoughtVoucher) => `${el?.originalAmount},00 ${el?.currency?.toUpperCase()}`,
    },
    {
        key: "amountLeft",
        label: "Beløb tilbage",
        props: {
            xs: 1.5,
        },
        value: (el: TBoughtVoucher) => `${el?.amountLeft} ${el?.currency?.toUpperCase()}`,
    },
    {
        key: "expiry",
        label: "Udløb",
        value: (el: TBoughtVoucher) => renderDate(el.expiresAt),
        valueProps: {
            fontWeight: 600,
        },
        props: {
            xs: 2,
        },
    },
    {
        key: "status",
        label: "Status",
        props: {},
        value: (el: TBoughtVoucher, t: TranslateFunction) => (
            <Chip
                component={"span"}
                label={
                    el?.originalAmount === el.amountLeft
                        ? t("unused", "utils.statusOptions")
                        : el.amountLeft === 0
                        ? t("used", "utils.statusOptions")
                        : t("partlyUsed", "utils.statusOptions")
                }
            />
        ),
    },
];

export const VouchersList = ({
    vouchers,
    ...props
}: BoxProps & { vouchers?: TBoughtVoucher[] }) => {
    const navigate = useNavigate();

    const { t } = useTranslate("utils.tables.header");

    const handleClick = (voucherId: string) => () => {
        navigate(voucherId);
    };

    return (
        <Box {...props}>
            <Grid container p={"12px"} mb={1}>
                {columns.map((el) => (
                    <Grid
                        fontSize={"0.75em"}
                        fontWeight={600}
                        textTransform={"uppercase"}
                        key={el.key}
                        item
                        {...el.props}
                        component={Typography}
                    >
                        {el.key !== "none" && t(el.key)}
                    </Grid>
                ))}
            </Grid>

            <Stack spacing={1}>
                {vouchers?.map((voucher) => (
                    <Grid
                        container
                        key={voucher.id}
                        component={Card}
                        p={1.5}
                        alignItems={"center"}
                        sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "rgb(252,252,252)" },
                        }}
                        onClick={handleClick(voucher.id)}
                    >
                        {columns.map((el) => (
                            <Grid
                                fontSize={"0.88em"}
                                key={el.key}
                                item
                                {...el.props}
                                {...el.valueProps}
                                component={Typography}
                            >
                                {el.value?.(voucher, t)}
                            </Grid>
                        ))}
                        <Grid item flexGrow={1} textAlign={"right"}>
                            <Button
                                sx={{ mr: 1, padding: "1px 16px", borderRadius: 100 }}
                                variant={"outlined"}
                                color={"secondary"}
                                size={"small"}
                            >
                                {t("seeDetails", "utils.generic")}
                            </Button>
                            <IconButton size={"small"}>
                                <MoreVert />
                            </IconButton>
                        </Grid>
                    </Grid>
                )) ?? <ListSkeleton />}
            </Stack>
        </Box>
    );
};
