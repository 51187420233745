import { TUser } from "../Hooks/useUsers";
import { Avatar, Grid, Stack } from "@mui/material";
import { CustomChip } from "./CustomChip/CustomChip";
import React from "react";

const renderNames = (users: TUser[], fullName: boolean) => {
    return users
        .map((el) => {
            const [first] = el.name.split(" ");
            return users.length > 1 ? first : fullName ? el.name : first;
        })
        .join(", ");
};

const isLast = (items: unknown[], index: number) => items.length === index + 1;

export const Assignees = ({
    assignees,
    showName = true,
    fullName = true,
}: {
    assignees: TUser[];
    showName?: boolean;
    fullName?: boolean;
}) => {
    return (
        <Stack direction={"row"} alignItems={"center"}>
            {assignees.map((el, i) => {
                return isLast(assignees, i) ? (
                    <Grid
                        item
                        component={CustomChip}
                        avatar={<Avatar alt={el.name} src={el.pictures?.profile?.url} />}
                        label={showName ? renderNames(assignees, fullName) : ""}
                        size={"medium"}
                        sx={{
                            border: "none",
                            pointerEvents: "none",
                            color: "grey.600",
                            ml: "-8px",
                            borderRadius: 100,
                        }}
                    />
                ) : (
                    <Avatar
                        alt={el.name}
                        sx={{ height: 24, width: 24, ml: 0.5 }}
                        src={el.pictures?.profile?.url}
                    />
                );
            })}
        </Stack>
    );
};
