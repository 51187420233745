import React, { ComponentPropsWithoutRef, useCallback, useMemo, useState } from "react";
import { SyiPage } from "../SyiPage/SyiPage";
import { eventSyiSteps, eventSchema } from "../SyiPage/config";
import { isVirtualId, useEvent } from "../../Hooks/useEvent";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import randomBytes from "randombytes";
import { AppShell } from "../../Components/AppShell/AppShell";
import { EventDetailsPage } from "./EventDetailsPage";
import { useService } from "../../Hooks/useService";
import { UseFormReturn } from "react-hook-form";
import NiceModal from "@ebay/nice-modal-react";
import { OptionsDialog } from "../../Modals/OptionsDialog";
import { RepeatRounded } from "@mui/icons-material";
import { useTranslate } from "../../Hooks/useTranslate";
import { useForm, FormProvider } from "react-hook-form";

type TOptionGroupObj = Record<string, string> & {
    selectedOptionKey: string;
};

export const getDefinedValueOrDefault = (
    obj: TOptionGroupObj | string | number,
    defaultValue: number | string
) => {
    if (typeof obj === "string" || typeof obj === "number") {
        return obj;
    }
    if (obj?.hasOwnProperty(obj?.selectedOptionKey)) {
        return obj[obj?.selectedOptionKey];
    }
    if (obj?.selectedOptionKey === "yes" && obj?.value) {
        return obj.value;
    }
    return defaultValue as string;
};

export const EventPage = ({ children }: ComponentPropsWithoutRef<"main">) => {
    const { t } = useTranslate("dialogs.recurringChanges");

    const { id } = useParams<{ id: string }>();

    const location = useLocation();
    const navigate = useNavigate();

    const { returnUrl, serviceId } = useMemo(() => {
        const { returnUrl, serviceId } = (location?.state as any) ?? {};
        return {
            serviceId,
            returnUrl,
        };
    }, [location.state]);

    const {
        params: { section },
    } = useMatch(":key/:id/:section/*") ?? { params: {} };

    const {
        service: { data: service },
    } = useService(serviceId);

    const _id = useMemo(() => (id !== "create" && id ? id : randomBytes(16).toString("hex")), [id]);

    const defaultValues: { [key: string]: any } & { parentId?: string } = {
        serviceId: serviceId ?? null,
        status: "draft",
        intervals: {
            selectedOptionKey: "no",
        },
        recurring: {
            selectedOptionKey: "no",
        },
    };

    const { control, ...methods } = useForm<Record<string, any>>({
        defaultValues: {
            status: "draft",
            ...defaultValues,
        },
    });

    const { event, updateEvent, deleteEvent } = useEvent(_id);

    const _steps = useMemo(() => {
        return eventSyiSteps.map((el) => {
            return {
                ...el,
                label: t(el.key, "events.create.progress"),
            };
        });
    }, []);

    const prefiller = useCallback(
        (setValue: UseFormReturn["setValue"]) => {
            if (service) {
                setValue("seatCount", {
                    selectedOptionKey: "serviceDefault",
                    value: (service as any)?.seatCount,
                });
                setValue("deactivateBeforeDays", {
                    selectedOptionKey: "serviceDefault",
                    value: getDefinedValueOrDefault((service as any)?.deactivateBeforeDays, 0),
                });
            }
        },
        [service]
    );

    if (id !== "create" && section !== "edit") {
        return (
            <AppShell>
                <EventDetailsPage eventId={_id}>{children}</EventDetailsPage>
            </AppShell>
        );
    }
    const onSubmit = isVirtualId(_id)
        ? async (payload: any, dirtyFields?: { [k: string]: boolean }) => {
              if (
                  dirtyFields?.hasOwnProperty("recurring") ||
                  dirtyFields?.hasOwnProperty("intervals")
              ) {
                  await updateEvent.mutateAsync({ ...payload, id: payload.parentId ?? payload.id });
                  return navigate(`/service/${serviceId ?? event.data?.serviceId}`, {
                      replace: true,
                  });
              }

              const choice = await NiceModal.show(OptionsDialog, {
                  title: t("title"),
                  headline: (
                      <>
                          {t("headline1")}{" "}
                          <RepeatRounded sx={{ ml: 0.5, mr: 0.5 }} fontSize={"small"} />
                          {t("headline2")}
                      </>
                  ),
                  buttons: [
                      {
                          key: "editRule",
                          label: t("actions.editRule"),
                          props: {
                              variant: "outlined",
                              color: "secondary",
                          },
                      },
                      {
                          key: "editThis",
                          label: t("actions.editThis"),
                          props: {
                              variant: "contained",
                          },
                      },
                  ],
              });

              if (choice === "editRule") {
                  await updateEvent.mutateAsync({ ...payload, id: payload.parentId });
              } else {
                  await updateEvent.mutateAsync(payload);
              }

              return navigate(`/service/${serviceId ?? payload?.serviceId}`, { replace: true });
          }
        : async (
              data: any,
              dirtyFields: unknown,
              shouldNavigate: boolean | undefined,
              validated = true
          ) => {
              if (validated) {
                  await updateEvent.mutateAsync({ ...data, id: _id });
              }
              if (shouldNavigate) {
                  return navigate(`/service/${serviceId ?? event.data?.serviceId}`, {
                      replace: true,
                  });
              }
          };

    return (
        <FormProvider {...{ ...methods, control }}>
            <SyiPage
                modelType={"event"}
                steps={_steps}
                title={
                    id === "create"
                        ? `${t("new", "utils.generic")} ${t("event", "utils.generic")}`
                        : `${t("edit", "utils.generic")} ${t("event", "utils.generic")}`
                }
                deleteItem={deleteEvent}
                updateItem={updateEvent}
                item={event}
                id={_id}
                disableConfirmOnBack={id === "create"}
                schema={eventSchema}
                returnUrl={returnUrl ?? "/services"}
                prefiller={prefiller}
                onSubmit={onSubmit}
                isCreateFlow={id === "create"}
            >
                {children}
            </SyiPage>
        </FormProvider>
    );
};
