import React from "react";
import fallbackImage from "../Assets/placeholder.svg";

interface UseImageProps {
    source?: string;
    loadNow?: boolean;
}

type useImageState = "loading" | "success" | "error";

export const useLazyImage = ({ source, loadNow = true }: UseImageProps) => {
    const [state, setState] = React.useState<useImageState>("loading");

    React.useEffect(() => {
        if (!loadNow || !source) {
            return setState("error");
        }

        const main = new Image();

        main.onload = () => {
            setState("success");
        };

        main.onerror = () => {
            setState("error");
        };

        main.src = source;
    }, [source, loadNow]);

    return { source: state === "success" ? source : fallbackImage, state };
};
