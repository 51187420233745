import NumberFormat from "react-number-format";
import { MenuItem, TextField, StandardTextFieldProps } from "@mui/material";
import { forwardRef } from "react";

const NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(values) => {
                onChange?.({
                    target: {
                        name: props.name ?? "",
                        value: "" + values.floatValue || "",
                    },
                });
            }}
            isNumericString
            prefix="Kr.  "
        />
    );
};

export type TInputProps = StandardTextFieldProps & {
    type?: string;
    options?: {
        key: number | string;
        label: string;
    }[];
};

export const Input = forwardRef<HTMLInputElement, TInputProps>(
    ({ type, inputProps, options, ...props }, ref) => {
        return (
            <TextField
                {...props}
                ref={ref}
                select={type === "select"}
                type={type}
                autoComplete={"false"}
                variant="outlined"
                inputProps={type === "money" ? { inputComponent: NumberFormatCustom } : inputProps}
                color={"primary"}
            >
                {options &&
                    type === "select" &&
                    options.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.label}
                        </MenuItem>
                    ))}
            </TextField>
        );
    }
);
