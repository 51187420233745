import { Box, Button, Dialog, FormControlLabel, Stack, Switch } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CloseButton } from "../Components/CloseButton/CloseButton";
import { useTranslate } from "../Hooks/useTranslate";
import { TagsSection } from "../Components/Tags/Tags";
import React, { useState } from "react";
import { ProgressButton } from "../Components/ProgressButton/ProgressButton";

interface TagsDialogProps {
    onClose?: () => void;
    tags: string[];
}

export const TagsDialog = NiceModal.create<TagsDialogProps>(({ tags: _tags, onClose }) => {
    const { t } = useTranslate("utils.generic");

    const [tags, setTags] = useState(_tags);

    const modal = useModal();

    const handleClose = () => {
        onClose?.();
        modal.hide().then(() => modal.remove());
    };

    const handleOk = () => {
        modal.resolve(tags);
        modal.hide().then(() => modal.remove());
    };

    return (
        <Dialog open={modal.visible} maxWidth={"md"} onClose={handleClose}>
            <CloseButton onClick={handleClose} sx={{ position: "absolute", top: 24, right: 24 }} />
            <Box p={4} sx={{ overflowY: "auto" }}>
                <TagsSection tags={tags} onChange={(keys) => setTags(keys)} />
            </Box>
            <Stack
                sx={{
                    backgroundColor: "white",
                    borderTop: "1px solid #E7EBF0",
                }}
                direction={"row"}
                p={3}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Button size={"large"} onClick={handleClose} variant={"outlined"}>
                    {t("cancel")}
                </Button>

                <ProgressButton
                    label={t("save")}
                    size={"large"}
                    variant={"contained"}
                    onClick={handleOk}
                />
            </Stack>
        </Dialog>
    );
});
