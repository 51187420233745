import { Box, BoxProps, CircularProgress, Grid, styled, SvgIcon } from "@mui/material";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { useDropzone } from "react-dropzone";
import { TFileTypes, TModelTypes } from "../../types";
import { useUploader } from "../../Hooks/useUploader";

const Styled = styled(Box)({
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(100,100,100,.05)",
    ["&:hover"]: {
        backgroundColor: "rgba(100,100,100,.15)",
    },
});

const StyledOverlay = styled(Box)({
    width: "100%",
    height: "100%",
    position: "absolute",
    background: "rgba(250,250,250,0.9)",
    zIndex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "common.main",
});

const StyledProgress = styled("div")(({ progress }: { progress: number }) => ({
    width: `${progress}%`,
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 127, 255, 0.1)",
    transition: "width 160ms",
}));

const LoadingOverlay = ({
    progress,
    children,
}: { progress: number } & ComponentPropsWithoutRef<typeof StyledOverlay>) => {
    return (
        <StyledOverlay>
            <StyledProgress progress={progress} />
            <Box sx={{ zIndex: 5 }}>{children}</Box>
        </StyledOverlay>
    );
};

interface IDropzoneProps {
    urls?: string[];
    modelType: TModelTypes;
    id?: string;
    fileType: TFileTypes;
    Icon?: typeof SvgIcon;
    onSuccess?: (args: { key: string; url: string }, key: TFileTypes, file: File) => Promise<void>;
    onPrepared?: (
        args: { key: string; url: string },
        localUrl: string,
        key: TFileTypes,
        uploadFunc: () => Promise<void>
    ) => void;
    containerProps?: BoxProps;
    maxFiles?: number;
}

export const Dropzone = forwardRef<HTMLInputElement, BoxProps & IDropzoneProps>(
    (
        { children, maxFiles = 40, modelType, urls, id, onPrepared, fileType, containerProps = {} },
        ref
    ) => {
        const { loading, uploadProgress, onDrop, tempUrls } = useUploader({
            fileType,
            modelType,
            id,
            onPrepared,
        });

        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles });

        const items = tempUrls ?? urls ?? null;

        return (
            <Styled
                {...getRootProps()}
                {...containerProps}
                {...(urls ? undefined : { border: "3px solid #322FDE" })}
            >
                {Boolean(loading) && (
                    <LoadingOverlay progress={uploadProgress}>
                        <CircularProgress size={40} />
                    </LoadingOverlay>
                )}

                <input {...getInputProps()} ref={ref} />

                {items ? (
                    <Grid
                        height={"100%"}
                        container
                        columns={maxFiles > 1 ? 4 : 1}
                        spacing={maxFiles > 1 ? 1 : 0}
                    >
                        {items.map((el, i) => (
                            <img
                                alt={"Preview"}
                                src={el}
                                width={"100%"}
                                height={"100%"}
                                style={{ objectFit: "cover" }}
                            />
                        ))}
                    </Grid>
                ) : (
                    children
                )}
            </Styled>
        );
    }
);
