import { Stack, StackProps, Typography } from "@mui/material";
import { IdeaIcon } from "../../Icons";

export const Tip = ({
    label,
    filled = false,
    ...props
}: StackProps & { label: string; filled?: boolean }) => {
    return (
        <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            {...props}
            p={filled ? 1.5 : 0}
            sx={{
                ...(filled && { background: "#EFECFF" }),
                ...props.sx,
            }}
            borderRadius={1}
        >
            <IdeaIcon />
            <Typography fontSize={"0.88em"} fontWeight={600} color={"grey.600"}>
                {label}
            </Typography>
        </Stack>
    );
};
