import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { ObjectSchema } from "yup";
import { useMemo } from "react";
import Auth from "../Api";
type Step = {
    key: string;
    getUrl: (subjectId?: string) => string | (() => void);
    progress: number;
    metadata?: {
        status?: string;
    };
    depends?: string[];
    buttonLabel: string[];
    subjectId?: string;
};

type Item = {
    key: string;
    stepLabel: string;
    cardTitle: string;
    cardDescription: string;
    imageSrc: string;
    imageWidth: number;
    steps: Step[];
    completed?: boolean;
};

export const items: Item[] = [
    {
        key: "profile",
        stepLabel: "Udfyld dine oplysninger",
        cardTitle: "1. Udfyld dine bruger- og virksomhedsoplysninger",
        cardDescription: "Tryk på knappen herunder så gør vi det sammen, det er ret nemt.",
        imageSrc: "/graphics/onboarding-widget-illustration-1.png",
        imageWidth: 260,
        steps: [
            {
                key: "me",
                getUrl: () => `/settings/profile`,
                progress: 0,
                buttonLabel: ["Kom godt i gang"],
            },
            {
                key: "company",
                getUrl: () => `/settings/company`,
                progress: 0,
                buttonLabel: ["Færdiggør min virksomhedsprofil"],
            },
        ],
    },
    {
        key: "service",
        stepLabel: "Opret din første oplevelse",
        cardTitle: "2. Fortæl verden hvilken oplevelse du gerne vil dele med dem",
        cardDescription: "Tryk på knappen herunder så gør vi det sammen, det er ret nemt.",
        steps: [
            {
                key: "create",
                getUrl: (subjectId?: string) =>
                    `/service/${subjectId ?? "create"}${subjectId ? "/edit" : ""}/details`,
                progress: 0,
                buttonLabel: ["Opret din første ydelse", "Færdiggør din ydelse"],
            },
        ],
        imageSrc: "/graphics/onboarding-widget-illustration-2.png",
        imageWidth: 220,
    },
    /* {
        key: 'event',
        stepLabel: 'Opret din første begivenhed',
        cardTitle: '3. Fortæl dine kommende gæster hvornår oplevelsen finder sted',
        cardDescription: 'Tryk på knappen herunder så gør vi det sammen, det er ret nemt.',
        steps: [{
            key: 'create',
            getUrl: (subjectId?: string) => `/event/${subjectId ?? 'create'}${subjectId ? '/edit' : ''}/details`,
            progress: 0,
            depends: ['experience'],
            buttonLabel: ['Opret din første begivenhed','Færdiggør din begivenhed'],
        }],
        imageSrc: '/graphics/onboarding-widget-illustration-3.svg',
        imageWidth: 180
    },*/
    {
        key: "payment",
        stepLabel: "Tag i mod betalinger",
        cardTitle: "4. Begynd at sælge billetter til dine oplevelser",
        cardDescription: "Tryk på knappen herunder så gør vi det sammen, det er ret nemt.",
        steps: [
            {
                key: "setup",
                getUrl: () => `/settings/integrations`,
                progress: 0,
                buttonLabel: ["Opsæt Holdbar Pay", "Færdiggør din opsætning af Holdbar Pay"],
            },
        ],
        imageSrc: "/graphics/onboarding-widget-illustration-3.png",
        imageWidth: 220,
    },
    {
        key: "terms",
        stepLabel: "Sæt dine vilkår",
        cardTitle: "5. Sæt dine vilkår",
        cardDescription:
            "Lad os sætte dine handelsvilkår, persondatapolitik og annulleringspolitik.",
        steps: [
            {
                key: "general",
                getUrl: () => `/settings/terms`,
                progress: 0,
                buttonLabel: ["Tilføj handelsvilkår"],
            },
            {
                key: "cancellation",
                getUrl: () => `/settings/terms`,
                progress: 0,
                buttonLabel: ["Tilføj en annulleringspolitik"],
            },
            {
                key: "privacy",
                getUrl: () => `/settings/terms`,
                progress: 0,
                buttonLabel: ["Tilføj en privatlivspolitik"],
            },
        ],
        imageSrc: "/graphics/onboarding-widget-illustration-4.png",
        imageWidth: 260,
    },
];

export const useOnBoarding = () => {
    const queryClient = useQueryClient();

    const QueryKey = ["onBoarding"];

    const onBoarding = useQuery<Item[]>(
        QueryKey,
        async () => {
            await queryClient.cancelQueries(QueryKey);
            const saved = await api.getOnBoarding();
            return items
                .map((el) => {
                    return saved[el.key]
                        ? {
                              ...el,
                              steps:
                                  el.steps?.map((step) => {
                                      const {
                                          progress = 0,
                                          metadata,
                                          subjectId,
                                      } = saved[el.key].find((f) => f.key === step.key) ?? {};
                                      return {
                                          ...step,
                                          subjectId,
                                          progress,
                                          ...(metadata && { metadata }),
                                      };
                                  }) ?? [],
                          }
                        : el;
                })
                .map((m) => {
                    return {
                        ...m,
                        completed: !m.steps?.some((st) => st.progress !== 100),
                    };
                });
        },
        {
            enabled: Auth.isAuthorized,
            retry: false,
            refetchOnWindowFocus: true,
        }
    );

    const missingSteps = useMemo(() => {
        if (onBoarding.data) {
            return onBoarding.data.filter((f) => !f.completed).map(({ key }) => ({ key }));
        }
        return items.map(({ key }) => ({ key }));
    }, [onBoarding]);

    const updateOnBoardingStep = useMutation(
        ({ type, payload }: { type: string; payload: { key: string; progress: number } }) => {
            const previous = queryClient.getQueryData<Item[]>(QueryKey);
            if (
                previous?.some(
                    (el) =>
                        el.key === type &&
                        el.steps.some((s) => s.key === payload.key && s.progress !== 100)
                )
            ) {
                return api.updateOnBoardingStep(type, payload);
            }
            return Promise.resolve();
        },
        {
            onMutate: async ({ type, payload }) => {
                await queryClient.cancelQueries(QueryKey);

                const previous = queryClient.getQueryData(QueryKey);

                queryClient.setQueryData<
                    { type: string; steps: { key: string; progress: number }[] }[]
                >(QueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.type === type
                                ? {
                                      ...el,
                                      steps: el.steps.map((step) => {
                                          return step.key === payload.key && step.progress !== 100
                                              ? {
                                                    ...step,
                                                    ...payload,
                                                }
                                              : step;
                                      }),
                                  }
                                : el;
                        }) ?? []
                    );
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData(QueryKey, context.previous);
                }
            },
            onSettled: async () => {
                queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    const updateStep = async (
        payload: { id?: string; validated?: boolean },
        schema: ObjectSchema<any>,
        type: string,
        stepKey: string
    ) => {
        const errorCount = await schema
            .validate(payload, { abortEarly: false, strict: true })
            .then(() => 0)
            .catch((err) => {
                console.log(err.errors);
                if (payload.validated) {
                    return 0;
                }
                return err.errors.length;
            });
        const fieldsCount = Object.keys(schema.fields).length;
        updateOnBoardingStep.mutate({
            type,
            payload: {
                key: stepKey,
                progress:
                    errorCount > 0
                        ? Math.round(((fieldsCount - errorCount) / fieldsCount) * 100)
                        : 100,
                ...(payload.id && {
                    subjectId: payload.id,
                }),
            },
        });
    };

    return {
        onBoarding,
        updateStep,
        updateOnBoardingStep,
        missingSteps,
    };
};
