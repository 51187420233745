import Fetch, { IShallowSuccessResponse } from './index'
import { TFileTypes, TModelTypes } from '../types'

export const getSigned = async (
    propType: TFileTypes,
    modelType: TModelTypes,
    mime: string,
    id: string,
) => {
    if (typeof process.env.REACT_APP_API_UPLOAD_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_UPLOAD_URL is not defined')
    }

    return Fetch.post<{ url: string; key: string }>(
        `${process.env.REACT_APP_API_UPLOAD_URL}signed`,
        {
            propType,
            modelType,
            mime,
            id,
        },
    )
}

export const upload = async (
    file: File,
    presignedUrl: string,
    renderProgress?: (progress: number) => void,
    mime: string = 'application/x-www-form-urlencoded',
) => {
    if (!presignedUrl) {
        throw new Error('No presigned url present.')
    }

    await import('axios').then(instance =>
        instance.default.put(presignedUrl, file, {
            headers: {
                'Content-Type': mime,
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / (progressEvent?.total ?? 0),
                )
                renderProgress?.(percentCompleted)
            },
        }),
    )
}

export const deleteUpload = async (key: string) => {
    if (typeof process.env.REACT_APP_API_UPLOAD_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_UPLOAD_URL is not defined')
    }

    await Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_UPLOAD_URL}`, {
        params: {
            key,
        },
    })
}
