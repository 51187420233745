import Fetch, { IShallowSuccessResponse } from './index'
import { IEvent, TEvent } from '../Hooks/useEvent'

export const getEvents = async () => {
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }

    return Fetch.get<TEvent[]>(`${process.env.REACT_APP_API_EVENTS_URL}`)
}

export const getEvent = async (id: string) => {
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }

    return Fetch.get<TEvent>(`${process.env.REACT_APP_API_EVENTS_URL}${id}`)
}

export const getEventsInService = async (serviceId: string) => {
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }

    return Fetch.get<TEvent[]>(`${process.env.REACT_APP_API_EVENTS_URL}service/${serviceId}`)
}

export const updateEvent = async (payload: Record<string, any>, id?: string) => {
    if (!id) {
        throw new Error('No id')
    }
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }

    await Fetch.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_EVENTS_URL}${id}`,
        payload,
    )
}

export const addExcludedDate = async (dateTime: string, id?: string) => {
    if (!id) {
        throw new Error('No id')
    }
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }
    await Fetch.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_EVENTS_URL}${id}/exclude`,
        { dateTime },
    )
}

export const manifestVirtualEvent = async (id: string, parentId: string, payload: any) => {
    if (!id || !parentId) {
        throw new Error('No ids')
    }
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }
    await Fetch.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_EVENTS_URL}${parentId}/manifest/${id}`,
        payload,
    )
}

export const deleteEvent = async (id: string) => {
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }

    try {
        await Fetch.delete(`${process.env.REACT_APP_API_EVENTS_URL}${id}`)
    } catch {
        return undefined
    }
}
