import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useState } from "react";

export type TDiscount = {
    id: string;
    status: string;
    name: string;
    created: string;
    companyId: string;
    code: string;
    rate: number;
    expiry: null | {
        startDateTime: string;
        endDateTime: string;
    };
    percentage: boolean;
    scope: "unit" | "total";
    currency: string;
    overrides: {
        type: "service";
        id: string;
        enabled?: boolean;
        rate?: number;
        percentage?: boolean;
    }[];
    source: "admin" | "leadPage";
    limits?: {
        timesRedeemed?: number;
    };
    timesRedeemed: number;
    enabled: boolean;
};

export type TRedemption = {
    code: string;
    companyId: string;
    created: string;
    discountId: string;
    id: string;
    percentage: boolean;
    rate: number;
    receiptId: string;
    totalDiscountedAmount: number;
    updated: string;
};

export const useDiscount = (discountId?: string) => {
    const queryClient = useQueryClient();

    const DiscountsQueryKey = ["discounts"];
    const RedemptionsQueryKey = ["discounts", "redemptions"];
    const DiscountQueryKey = ["discount", discountId];

    const discounts = useQuery<TDiscount[]>(
        DiscountsQueryKey,
        async () => {
            await queryClient.cancelQueries(DiscountsQueryKey);
            const els = await api.getDiscounts();
            return els.filter((el) => el.source === "admin");
        },
        {
            enabled: true,
        }
    );

    const redemptions = useQuery<TRedemption[]>(
        RedemptionsQueryKey,
        async () => {
            await queryClient.cancelQueries(RedemptionsQueryKey);
            return api.getRedemptions(discountId ?? "all");
        },
        {
            enabled: true,
        }
    );

    const discount = useQuery<Partial<TDiscount>>(
        DiscountQueryKey,
        async () => {
            await queryClient.cancelQueries(DiscountQueryKey);
            const discounts = queryClient.getQueryData<TDiscount[]>(DiscountsQueryKey);
            const found = discounts?.find((el) => el.id === discountId);
            if (found) {
                return found;
            }
            return api.getDiscount(discountId as string);
        },
        {
            enabled: Boolean(discountId),
        }
    );

    const updateDiscount = useMutation(
        ({ id, ...data }: TDiscount) => api.updateDiscount(id ?? discountId, data),
        {
            onMutate: async ({ id, ...data }) => {
                await queryClient.cancelQueries(DiscountsQueryKey);

                const previous = queryClient.getQueryData<TDiscount[]>(DiscountsQueryKey);

                queryClient.setQueryData<TDiscount>(["discount", id ?? discountId], (prev) => {
                    return {
                        ...prev,
                        ...data,
                        id: id ?? discountId,
                    };
                });

                queryClient.setQueryData<TDiscount[]>(DiscountsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => (el.id === id ? { ...el, ...data } : el)) ?? [
                            { id, ...data },
                        ]
                    );
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TDiscount>(DiscountQueryKey, context.previous);
                }
            },
            onSettled: async (data, error, variables, context) => {
                queryClient.removeQueries({ queryKey: ["discount"] });
                await queryClient.invalidateQueries(DiscountsQueryKey);
                await queryClient.invalidateQueries(["discount", variables?.id ?? discountId]);
            },
        }
    );

    const deleteDiscount = useMutation((id: string) => api.deleteDiscount(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(DiscountsQueryKey);

            const previous = queryClient.getQueryData<TDiscount[]>(DiscountsQueryKey);

            queryClient.setQueryData<TDiscount[]>(DiscountsQueryKey, (prev) => {
                return prev!.filter((el) => el.id !== id);
            });

            return { previous };
        },
        onError: (err, variables, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<TDiscount>(DiscountsQueryKey, context.previous);
            }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(DiscountsQueryKey);
            await queryClient.invalidateQueries(DiscountQueryKey);
        },
    });

    return {
        discount,
        discounts,
        updateDiscount,
        deleteDiscount,
        redemptions,
    };
};
