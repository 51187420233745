import { Box, Typography, Stack, Divider, Button, StackProps } from "@mui/material";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { useTerms } from "../../Hooks/useTerms";
import { TranslatableGroup } from "../../Components/TranslatableGroup/TranslatableGroup";
import { mapSelectedLanguages } from "../../Pages/Services/ServiceSyiSections/ServiceSyiSectionDescription";
import { CancelRounded, CheckRounded } from "@mui/icons-material";
import { useConfirm } from "../../Hooks/useConfirm";
import { useController, useFormContext } from "react-hook-form";
import isEmpty from "lodash.isempty";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";
import { useOutletContext } from "react-router-dom";
import { createFromTemplate } from "../../Api";
import { useProfile } from "../../Hooks/useProfile";
import { languagesOptions } from "../../Utils/config";
import { useTranslate } from "../../Hooks/useTranslate";

export const SettingsTermsSection = () => {
    const { t } = useTranslate("settings.terms");

    const { getValues } = useFormContext();

    const { hasLoadedInitially } = useOutletContext<{ hasLoadedInitially: boolean }>();

    const {
        terms: { data: terms, isLoading },
        updateTerms,
    } = useTerms();

    const {
        company: { data: companyProfile },
    } = useProfile();

    const { field: languages } = useController({ name: "company.languages", defaultValue: [] });

    const handleChange = (termsType: string) => () => {
        const { terms: _terms } = getValues();
        const changedContent = Object.entries(_terms as { [k: string]: string }).reduce(
            (acc, [type, el]) => {
                return termsType === type
                    ? {
                          ...acc,
                          ...Object.entries(el).reduce((changed, [lang, content]) => {
                              return content !== terms?.[type]?.versions?.[lang]?.content
                                  ? { ...changed, [lang]: { content } }
                                  : changed;
                          }, {}),
                      }
                    : acc;
            },
            {}
        );

        if (!isEmpty(changedContent)) {
            updateTerms.mutate({ type: termsType, payload: changedContent });
        }
    };

    const {
        dirty: dirtyGeneral,
        handleSave: handleSaveGeneral,
        handleChangeLocalized: handleChangeGeneral,
        handleCancel: handleCancelGeneral,
    } = useConfirm("terms.general", handleChange("general"));

    const {
        dirty: dirtyCancellation,
        handleSave: handleSaveCancellation,
        handleChangeLocalized: handleChangeCancellation,
        handleCancel: handleCancelCancellation,
    } = useConfirm("terms.cancellation", handleChange("cancellation"));

    const {
        dirty: dirtyPrivacy,
        handleSave: handleSavePrivacy,
        handleChangeLocalized: handleChangePrivacy,
        handleCancel: handleCancelPrivacy,
    } = useConfirm("terms.privacy", handleChange("privacy"));

    const handleCreateFromTemplate = (type: string, lang: string) => async () => {
        const {
            name: companyName,
            location,
            cvrNr: companyCvr,
            companyEmail,
            companyPhone,
        } = companyProfile ?? {};
        try {
            const { content, version } = await createFromTemplate<{
                content: string;
                version: number;
            }>(type, lang, {
                companyName,
                address: `${location?.address}, ${location?.zipCode} ${location?.city}`,
                companyCvr,
                companyPhone,
                companyEmail,
            });
            switch (type) {
                case "general":
                    handleChangeGeneral(content, lang);
                    return;
                case "cancellation":
                    handleChangeCancellation(content, lang);
                    return;
                case "privacy":
                    handleChangePrivacy(content, lang);
                    return;
            }
        } catch (err) {
            console.error("Could not get template");
        }
    };

    return (
        <>
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Typography whiteSpace={"pre-wrap"}>{t("description")}</Typography>

            <Divider sx={{ mt: 4, mb: 4 }} />

            <Stack mt={{ xs: 0, lg: 5 }} spacing={5}>
                {!(hasLoadedInitially && !isLoading) ? (
                    <SimpleSkeleton />
                ) : (
                    <>
                        <Box>
                            <Typography variant={"h4"} mb={2} fontSize={"1.2em"}>
                                {t("types.general")}
                            </Typography>
                            <TranslatableGroup
                                sx={{ maxWidth: 680 }}
                                langs={mapSelectedLanguages(languages.value, languagesOptions)}
                                propKey={`terms.general`}
                                onUpdate={handleChangeGeneral}
                                input={{
                                    type: "editor",
                                    placeholder: t("inputPlaceholder"),
                                }}
                                renderControls={[
                                    {
                                        position: "below",
                                        render: (selectedLang) =>
                                            !dirtyGeneral && (
                                                <ProgressButton
                                                    label={t("insertFromTemplate")}
                                                    size={"large"}
                                                    sx={{ mt: 3 }}
                                                    onClick={handleCreateFromTemplate(
                                                        "general",
                                                        selectedLang
                                                    )}
                                                    variant={"outlined"}
                                                    color={"secondary"}
                                                />
                                            ),
                                    },
                                ]}
                            />
                            {dirtyGeneral && (
                                <ConfirmButtons
                                    sx={{ mt: 3 }}
                                    onSave={handleSaveGeneral}
                                    onCancel={handleCancelGeneral}
                                />
                            )}
                        </Box>

                        <Box>
                            <Typography variant={"h4"} fontSize={"1.2em"} mb={2}>
                                {t("types.cancellation")}
                            </Typography>
                            <TranslatableGroup
                                sx={{ maxWidth: 680 }}
                                langs={mapSelectedLanguages(languages.value, languagesOptions)}
                                propKey={`terms.cancellation`}
                                onUpdate={handleChangeCancellation}
                                input={{
                                    type: "editor",
                                    placeholder: t("inputPlaceholder"),
                                }}
                                renderControls={[
                                    {
                                        position: "below",
                                        render: (selectedLang) =>
                                            !dirtyCancellation && (
                                                <ProgressButton
                                                    label={t("insertFromTemplate")}
                                                    size={"large"}
                                                    sx={{ mt: 3 }}
                                                    onClick={handleCreateFromTemplate(
                                                        "cancellation",
                                                        selectedLang
                                                    )}
                                                    variant={"outlined"}
                                                    color={"secondary"}
                                                />
                                            ),
                                    },
                                ]}
                            />
                            {dirtyCancellation && (
                                <ConfirmButtons
                                    sx={{ mt: 3 }}
                                    onSave={handleSaveCancellation}
                                    onCancel={handleCancelCancellation}
                                />
                            )}
                        </Box>

                        <Box>
                            <Typography variant={"h4"} fontSize={"1.2em"} mb={2}>
                                {t("types.privacy")}
                            </Typography>
                            <TranslatableGroup
                                sx={{ maxWidth: 680 }}
                                langs={mapSelectedLanguages(languages.value, languagesOptions)}
                                propKey={`terms.privacy`}
                                onUpdate={handleChangePrivacy}
                                input={{
                                    type: "editor",
                                    placeholder: t("inputPlaceholder"),
                                }}
                                renderControls={[
                                    {
                                        position: "below",
                                        render: (selectedLang) =>
                                            !dirtyPrivacy && (
                                                <ProgressButton
                                                    label={t("insertFromTemplate")}
                                                    size={"large"}
                                                    sx={{ mt: 3 }}
                                                    onClick={handleCreateFromTemplate(
                                                        "privacy",
                                                        selectedLang
                                                    )}
                                                    variant={"outlined"}
                                                    color={"secondary"}
                                                />
                                            ),
                                    },
                                ]}
                            />
                            {dirtyPrivacy && (
                                <ConfirmButtons
                                    sx={{ mt: 3 }}
                                    onSave={handleSavePrivacy}
                                    onCancel={handleCancelPrivacy}
                                />
                            )}
                        </Box>
                    </>
                )}
            </Stack>
        </>
    );
};

const ConfirmButtons = ({
    onSave,
    onCancel,
    ...props
}: StackProps & { onSave: () => void; onCancel: () => void }) => {
    const { t } = useTranslate("buttons");

    return (
        <Stack mt={1} direction={"row"} spacing={1} alignItems={"center"} {...props}>
            <Button startIcon={<CheckRounded />} color={"success"} onClick={onSave}>
                {t("save")}
            </Button>
            <Button startIcon={<CancelRounded />} color={"error"} onClick={onCancel}>
                {t("undo")}
            </Button>
        </Stack>
    );
};
