import { Skeleton, Stack, StackProps } from "@mui/material";
import React from "react";

export const SimpleSkeleton = ({ ...props }: StackProps) => (
    <Stack spacing={2} {...props}>
        <Skeleton width={60} />
        <Skeleton width={180} />
        <Skeleton width={240} />
        <Skeleton width={164} />
    </Stack>
);
