import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface PersistedStore {
    serviceEventsFiltering: string
    setServiceEventsFiltering: (value: string) => void
}

interface Store {
    updatingProfile: boolean
    setUpdatingProfile: (updating: boolean) => void
    serviceUpdating: string | undefined
    setServiceUpdating: (updating: string | undefined) => void
}

type TEventSorting = 'all' | 'future' | 'active' | 'past'

export const useStore = create(
    persist<PersistedStore>(
        (set, get) => ({
            serviceEventsFiltering: 'all',
            setServiceEventsFiltering: value => set({ serviceEventsFiltering: value }),
        }),
        {
            name: 'spiritworld-admin',
            getStorage: () => sessionStorage,
        },
    ),
)

export const useWeakStore = create<Store>((set, get) => ({
    updatingProfile: false,
    setUpdatingProfile: updating => set({ updatingProfile: updating }),
    serviceUpdating: undefined,
    setServiceUpdating: id => set({ serviceUpdating: id }),
}))
