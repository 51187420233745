import React, { useEffect, useState } from "react";
import { VouchersList } from "../Components/VouchersList/VouchersList";
import { Box, Button, Collapse, Grid, Skeleton, Stack, Switch, Typography } from "@mui/material";
import { TVoucher, useVoucher } from "../Hooks/useVoucher";
import { AddRounded } from "@mui/icons-material";
import { Page } from "../Components/Page/Page";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { createId } from "./SyiPage/SyiPage";
import { EmptyScreen } from "../Components/EmptyScreen/EmptyScreen";
import { ShowForRole } from "../Components/AllowForRole/AllowForRole";
import { useTranslate } from "../Hooks/useTranslate";
import { useMatch } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { CustomerDialog } from "../Modals/CustomerDialog/CustomerDialog";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { Check, CloseRounded, DeleteOutlineOutlined } from "@mui/icons-material";
import { useConfirm } from "../Hooks/useConfirm";
import { UseControllerProps, useFormContext } from "react-hook-form";

export const VouchersPage = () => {
    const {
        params: { id },
    } = useMatch("/vouchers/:id") ?? { params: {} };

    const { t } = useTranslate("vouchers");
    const [hasLoadedInitially, setHasLoadedInitially] = useState(false);

    const {
        vouchers: { data: vouchers, isLoading },
        boughtVouchers: { data: boughtVouchers },
        updateVoucher,
        deleteVoucher,
    } = useVoucher();

    const { ...methods } = useForm();

    const voucherItems = useWatch({ control: methods.control, defaultValue: [], name: "vouchers" });

    const [customPrice, setCustomPrice] = useState<null | Omit<TVoucher, "price">>(null);

    useEffect(() => {
        if (vouchers && !hasLoadedInitially) {
            const amountVouchers = vouchers
                .reduce<TVoucher[]>((filtered, el) => {
                    if (el.isCustomPrice) {
                        setCustomPrice(el);
                        return filtered;
                    }
                    if (el.price === null) {
                        return filtered;
                    }
                    return [...filtered, el];
                }, [])
                .sort((a, b) => Number(a.price) - Number(b.price));
            methods.setValue("vouchers", amountVouchers, { shouldTouch: true, shouldDirty: false });
            setHasLoadedInitially(true);
        }
    }, [vouchers]);

    useEffect(() => {
        if (id) {
            NiceModal.show(CustomerDialog, { voucherId: id });
        }
    }, [id]);

    const handleAddAmount = () => {
        methods.setValue("vouchers", [
            ...voucherItems,
            {
                price: null,
                currency: "da",
                id: createId(),
            },
        ]);
    };

    const handleSubmitAmount = (index: number) => () => {
        const { price, id } = voucherItems[index];
        updateVoucher.mutate({ id: id ?? createId(), price });
    };

    const handleDeleteAmount = (id: string | null) => () => {
        const newItems = (voucherItems ?? []).filter((el: { id: string }) => el.id !== id);
        methods.setValue("vouchers", newItems);
        if (id) {
            deleteVoucher.mutate(id);
        }
    };

    const handleToggleEnabled = (evt: unknown, toggled: boolean) => {
        const payload = {
            id: createId(),
            isCustomPrice: true,
        };
        setCustomPrice(toggled ? payload : null);
        if (toggled) {
            updateVoucher.mutate(payload);
        } else if (customPrice?.id) {
            deleteVoucher.mutate(customPrice.id);
        }
    };

    return (
        <Page maxWidth={1200} pb={10}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                <Typography variant={"h2"}>{t("title")}</Typography>
                <Stack direction={"row"} spacing={2} alignItems={"center"}></Stack>
            </Stack>
            <ShowForRole roles={["admin"]}>
                <>
                    <Box sx={{ paddingTop: 2, marginTop: 2 }}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography mr={1} variant={"h3"}>
                                {t("activate.title")}
                            </Typography>
                            {isLoading ? (
                                <Skeleton sx={{ ml: 1.5 }} width={56} height={38} />
                            ) : (
                                <Switch
                                    onChange={handleToggleEnabled}
                                    checked={Boolean(customPrice)}
                                />
                            )}
                        </Box>
                        <Typography maxWidth={400}>{t("activate.description")}</Typography>
                    </Box>
                    <Collapse in={Boolean(customPrice)}>
                        <Typography mt={4} variant={"h4"}>
                            {t("amounts.title")}
                        </Typography>
                        <Typography maxWidth={400}>{t("amounts.description")}</Typography>
                        <Box mt={3}>
                            <FormProvider {...{ ...methods }}>
                                <Grid container spacing={2} mb={3}>
                                    {voucherItems?.map((el: any, i: any) => (
                                        <Grid
                                            item
                                            component={Box}
                                            key={el.id}
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <InputWithConfirm
                                                size={"small"}
                                                sx={{ maxWidth: 140 }}
                                                label={t("amounts.inputPlaceholder")}
                                                type={"number"}
                                                inputProps={{ max: 999999 }}
                                                name={`vouchers.${i}.price`}
                                                onCommitChange={handleSubmitAmount(i)}
                                                onDelete={handleDeleteAmount(el.id)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormProvider>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                startIcon={<AddRounded />}
                                onClick={handleAddAmount}
                            >
                                {t("amounts.action")}
                            </Button>
                        </Box>
                    </Collapse>
                </>
            </ShowForRole>

            {boughtVouchers && boughtVouchers.length > 0 && (
                <>
                    <Typography variant={"h3"} mb={3} mt={6}>
                        {t("sold.title")}
                    </Typography>

                    <VouchersList vouchers={boughtVouchers ?? []} />
                </>
            )}

            {!Boolean(customPrice) && !isLoading && (
                <EmptyScreen
                    mt={8}
                    direction={"row"}
                    imageSrc={"/empty-screens/voucher.png"}
                    maxWidth={720}
                    imageProps={{ width: 230, height: 250 }}
                    title={t("emptyState.title")}
                    description={t("emptyState.description")}
                    buttonLabel={t("emptyState.action")}
                    onClick={() => handleToggleEnabled(undefined, true)}
                />
            )}
        </Page>
    );
};

export const SaveButtons = ({
    handleCancel,
    handleSave,
}: {
    handleSave?: () => void;
    handleCancel?: () => void;
}) => (
    <Box ml={1}>
        <IconButton onClick={handleCancel} color={"error"}>
            <CloseRounded fontSize={"small"} />
        </IconButton>
        <IconButton onClick={handleSave} color={"success"}>
            <Check />
        </IconButton>
    </Box>
);

export const InputWithConfirm = ({
    onCommitChange,
    onDelete,
    rules,
    ...props
}: TextFieldProps & {
    onDelete?: () => void;
    onCommitChange?: (value: string) => void;
    rules?: UseControllerProps["rules"];
}) => {
    const { field, dirty, handleSave, handleCancel, handleChange } = useConfirm(
        props.name!,
        onCommitChange!,
        rules
    );

    return (
        <Box display={"flex"} alignItems={"center"}>
            <TextField
                sx={{ minWidth: 320, maxWidth: 320 }}
                {...props}
                {...field}
                onChange={(evt) => handleChange(evt.target.value)}
            />
            {dirty && <SaveButtons handleSave={handleSave} handleCancel={handleCancel} />}

            {!dirty && onDelete && (
                <IconButton sx={{ ml: 1 }} onClick={onDelete}>
                    <DeleteOutlineOutlined />
                </IconButton>
            )}
        </Box>
    );
};
