import { IPictureProps, Picture } from "../Picture/Picture";
import { Box, BoxProps, ButtonBaseProps, Link, Rating, Stack, Typography } from "@mui/material";
import { CustomCard, ICustomCardProps, Row } from "../CustomCard/CustomCard";
import { languagesOptions } from "../../Utils/config";
import { useTranslation } from "react-i18next";
import NiceModal from "@ebay/nice-modal-react";
import { ReviewDialog } from "../../Modals/ReviewDialog";
import { FunnelMetrics } from "../FunnelMetrics";
import { renderDate } from "@hiddengemgroup/utils-date";

interface IServiceCard {
    languages: string[];
    createdDate: string;
    eventCount: number | null | undefined;
    bookingCount: number | null | undefined;
    type: "event" | "session" | "retreat" | "course" | string | undefined;
    rating:
        | {
              count: number;
              average: number;
              editable?: boolean;
          }
        | undefined;
    coverImage: string;
    imageProps?: Partial<IPictureProps>;
}

export const ServiceCard = ({
    title,
    onClickStatus,
    status,
    serviceId,
    type,
    languages: langs,
    createdDate,
    children,
    eventCount,
    bookingCount,
    rating,
    imageProps,
    coverImage,
    onClick,
    id,
    fullWidth = false,
    enableConversion = false,
    ...props
}: Omit<ButtonBaseProps, "type"> &
    Omit<ICustomCardProps, "image" | "content"> &
    Partial<IServiceCard> & { serviceId?: string; enableConversion?: boolean }) => {
    const { t } = useTranslation();

    const handleEditReviews = () => {
        NiceModal.show(ReviewDialog, {
            subject: "service",
            subjectId: serviceId,
            name: title,
            picture: coverImage,
        });
    };

    return (
        <CustomCard
            image={
                <Picture
                    borderRadius={2}
                    height={168}
                    minWidth={168}
                    source={coverImage ?? ""}
                    {...imageProps}
                    aspectRatio={1}
                />
            }
            title={title}
            onClickStatus={onClickStatus}
            onClick={onClick}
            status={status}
            fullWidth={fullWidth}
            content={
                (
                    <Stack mt={2} spacing={1} justifyContent={"flex-end"}>
                        <Row
                            label={t("utils.generic.language")}
                            skeletonProps={{ width: 40 }}
                            valueProps={{
                                fontSize: "20px",
                                lineHeight: "1em",
                            }}
                            value={langs?.map((el) => languagesOptions[el].emoji).join(" ")}
                        />
                        {eventCount !== null && type !== "session" && (
                            <Row
                                label={t("utils.generic.countEvents")}
                                skeletonProps={{ width: 32 }}
                                value={
                                    typeof eventCount === "number"
                                        ? `${eventCount} (${t("utils.generic.coming")})`
                                        : undefined
                                }
                            />
                        )}
                        {bookingCount !== null && type === "session" && (
                            <Row
                                label={t("utils.generic.countBookings")}
                                skeletonProps={{ width: 32 }}
                                value={
                                    typeof bookingCount === "number"
                                        ? `${bookingCount} (${t("utils.generic.coming")})`
                                        : undefined
                                }
                            />
                        )}
                        {enableConversion ? (
                            <Row
                                label={t("utils.generic.conversion")}
                                skeletonProps={{ width: 72 }}
                                value={<FunnelMetrics id={serviceId as string} type={"service"} />}
                            />
                        ) : (
                            <Row
                                label={t("utils.generic.created")}
                                skeletonProps={{ width: 48 }}
                                value={
                                    createdDate ? renderDate(createdDate, "MM.dd.yyyy") : undefined
                                }
                            />
                        )}
                        <Row
                            label="Gns. rating"
                            skeletonProps={{ width: 64 }}
                            value={
                                rating === undefined ? undefined : (
                                    <RatingControl
                                        ml={"-2px"}
                                        editable={rating?.editable ?? true}
                                        rating={rating.average}
                                        count={rating.count}
                                        onEdit={handleEditReviews}
                                    />
                                )
                            }
                        />
                    </Stack>
                ) as any
            }
            {...props}
        >
            {children && (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                >
                    {children}
                </Box>
            )}
        </CustomCard>
    );
};

type RatingControlProps = {
    rating: number;
    count: number;
    onEdit: () => void;
    editable: boolean;
} & BoxProps;
const RatingControl = ({ rating, count, onEdit, editable, ...props }: RatingControlProps) => {
    return (
        <Box display={"flex"} alignItems={"center"} {...props}>
            <Rating value={rating} readOnly size={"small"} />
            <Typography ml={0.5} mr={0.5} variant={"h5"} fontSize={"0.88em"}>
                {rating}
            </Typography>
            <Typography color={"grey.500"} mr={1} fontSize={"0.88em"}>
                ({count})
            </Typography>
            {editable && (
                <Link
                    fontSize={"0.88em"}
                    color={"text.primary"}
                    onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        onEdit();
                    }}
                    underline={"hover"}
                >
                    Rediger omtaler
                </Link>
            )}
        </Box>
    );
};
