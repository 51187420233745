import { Chip, Grid, GridProps, IconButton, TextField, Typography } from "@mui/material";
import { Input } from "../Input/Input";
import { DeleteRounded } from "@mui/icons-material";
import React from "react";
import { ROLE_OPTIONS as options } from "../../Utils/constants";
import { useTranslation } from "react-i18next";

export const UserItem = ({
    email,
    name,
    role,
    id,
    isCurrent = false,
    onDelete,
    onUpdate,
    ...props
}: GridProps & {
    email?: string;
    isCurrent?: boolean;
    name: string;
    id: string;
    role: "admin" | "viewer";
    onDelete?: () => void;
    onUpdate: (key: "email" | "role", value: any) => void;
}) => {
    const { t } = useTranslation();
    const _options = options.map((el) => {
        return {
            ...el,
            label: t(`onboarding.team.roleOptions.${el.key}`),
        };
    });
    return (
        <Grid container columns={2} rowSpacing={1} position={"relative"} {...props}>
            <Grid item component={Typography} variant={"h6"} xs={2} mb={1}>
                {name}
                {isCurrent && (
                    <Chip
                        size={"small"}
                        sx={{ ml: 1 }}
                        color={"info"}
                        label={t("utils.generic.me")}
                    />
                )}
            </Grid>
            <Grid item xs={1} pr={1}>
                <TextField
                    fullWidth
                    disabled={isCurrent}
                    InputLabelProps={{ shrink: Boolean(email) }}
                    defaultValue={email}
                    onChange={(evt) => onUpdate("email", evt.target.value)}
                    label={t("utils.generic.email")}
                    helperText={t("onboarding.team.userHelpText")}
                />
            </Grid>
            <Grid item xs={1} alignItems={"center"} pl={1} display={"flex"}>
                <Input
                    fullWidth
                    type={"select"}
                    disabled={isCurrent}
                    InputLabelProps={{ shrink: Boolean(role) }}
                    onChange={(evt) => onUpdate("role", evt.target.value)}
                    helperText={t("onboarding.team.roleHelpText")}
                    label={t("utils.generic.role")}
                    defaultValue={role}
                    options={_options}
                />
            </Grid>
            {onDelete && (
                <IconButton
                    sx={{ position: "absolute", right: -48, bottom: 32 }}
                    size={"medium"}
                    onClick={onDelete}
                >
                    <DeleteRounded fontSize={"small"} />
                </IconButton>
            )}
        </Grid>
    );
};
