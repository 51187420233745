import { Select, SelectProps, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { countries } from "@hiddengemgroup/utils-countries";
import { startTransition } from "react";
import { useTranslate } from "../../Hooks/useTranslate";

export const CountrySelect = ({ sx = {}, value, onChange, ...props }: Partial<SelectProps>) => {
    const { t } = useTranslate("countryCodes");
    return (
        <Select
            onChange={(...params) => {
                startTransition(() => {
                    onChange?.(...params);
                });
            }}
            inputProps={{
                sx: {
                    display: "flex",
                    alignItems: "center",
                },
            }}
            MenuProps={{
                sx: {
                    maxHeight: 400,
                    ...sx,
                },
            }}
            {...props}
            value={value}
            sx={{ ...sx, maxHeight: 56 }}
        >
            {Object.entries(countries).map(([code, { name, emoji }]) => (
                <MenuItem value={code}>
                    <Typography mr={1} fontSize={"2em"}>
                        {emoji}
                    </Typography>
                    <Typography>{t(code) === code ? name : t(code)}</Typography>
                </MenuItem>
            ))}
        </Select>
    );
};
