import * as api from "../Api";
import { useState } from "react";
import randomBytes from "randombytes";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import Auth, { AccountFetch as AccountAuth } from "../Api";
import { type TokenResponse } from "@hiddengemgroup/utils-frontend-fetch";
import { useProfile } from "./useProfile";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

export function thrownHasStatus(e: unknown, status: number) {
    return (e as { response?: { status?: number } } | undefined)?.response?.status === status;
}

export const hasOrgInToken = () => {
    try {
        return typeof (jwtDecode(Auth.getAccessToken()) as { org?: string })?.org === "string";
    } catch {
        return false;
    }
};

export const useAuth = () => {
    const { i18n } = useTranslation();

    const { hash } = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(Auth.isAuthorized);
    const [isLoading, setIsLoading] = useState(false);
    const { updateMe, resetQueries } = useProfile();
    const signIn = async (username: string, password: string) => {
        try {
            setIsLoading(true);
            const data = await api.signIn(username.toLowerCase(), password);
            AccountAuth.setAuth(data);
            const [firstClaimable] = (await api.getClaimableCompanies()) ?? [];
            if (firstClaimable) {
                Auth.setAuth(await api.reclaim(firstClaimable.org));
            } else {
                AccountAuth.clearAuth();
                Auth.setAuth(data);
            }
            setIsAuthenticated(true);
            return Promise.resolve(hasOrgInToken());
        } catch (err: any) {
            Auth.clearAuth();
            AccountAuth.clearAuth();
            setIsAuthenticated(false);
            return Promise.reject(err);
        } finally {
            setIsLoading(false);
        }
    };

    const reclaim = async (org: string) => {
        try {
            setIsLoading(true);
            const data = await api.reclaim(org);
            Auth.setAuth(data);
            setIsAuthenticated(true);
            return Promise.resolve();
        } catch (err: any) {
            Auth.clearAuth();
            AccountAuth.clearAuth();
            setIsAuthenticated(false);
            return Promise.reject(err?.response?.body);
        } finally {
            setIsLoading(false);
        }
    };

    const forgotPassword = async (username: string) => {
        try {
            setIsLoading(true);
            await api.resetPassword(username.toLowerCase(), i18n.language);
            return Promise.resolve();
        } catch (err) {
            Auth.clearAuth();
            AccountAuth.clearAuth();
            setIsAuthenticated(false);
            return Promise.reject();
        } finally {
            setIsLoading(false);
        }
    };

    const signOut = async () => {
        try {
            setIsLoading(true);
            await api.signOut();
            return Promise.resolve();
        } catch (err) {
            console.log(err);
        } finally {
            Auth.clearAuth();
            AccountAuth.clearAuth();
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    };

    const resetPassword = async (password: string) => {
        try {
            setIsLoading(true);
            await api.resetPassword(password, i18n.language);
        } catch (err) {
            Auth.clearAuth();
            AccountAuth.clearAuth();
            setIsAuthenticated(false);
            return Promise.reject();
        } finally {
            setIsLoading(false);
        }
    };

    const updateResatPassword = async (password: string, token: string) => {
        try {
            const { sub, email } = jwtDecode(token) as { sub: string; email: string };
            if (!sub || !email) {
                return Promise.reject();
            }
            try {
                setIsLoading(true);
                await api.updateResatPassword(email.toLowerCase(), password, sub, token);
                const data = await api.signIn(email.toLowerCase(), password);
                Auth.setAuth(data);
                setIsAuthenticated(true);
                return Promise.resolve();
            } catch (err) {
                Auth.clearAuth();
                AccountAuth.clearAuth();
                setIsAuthenticated(false);
                return Promise.reject();
            } finally {
                setIsLoading(false);
            }
        } catch (err) {
            return Promise.reject();
        }
    };

    const authenticateGoogle = async (code: string) => {
        try {
            if (!code) {
                return Promise.reject();
            }
            try {
                setIsLoading(true);
                const tokens = await api.authenticateGoogle(code);
                Auth.setAuth(tokens);
                setIsAuthenticated(true);
                return Promise.resolve();
            } catch (err) {
                Auth.clearAuth();
                setIsAuthenticated(false);
                return Promise.reject();
            } finally {
                setIsLoading(false);
            }
        } catch (err) {
            return Promise.reject();
        }
    };

    const createAccount = async (email: string, password: string) => {
        try {
            setIsLoading(true);
            const id = randomBytes(16).toString("hex");
            const data = await api.createAccount(email.toLowerCase(), password, id);
            Auth.setAuth(data);
            await updateMe.mutateAsync({
                email: email.toLowerCase(),
                id,
            });
            setIsAuthenticated(true);
            return Promise.resolve();
        } catch (err) {
            Auth.clearAuth();
            AccountAuth.clearAuth();
            setIsAuthenticated(false);
            return Promise.reject();
        } finally {
            setIsLoading(false);
        }
    };

    const queryClient = useQueryClient();
    const getEmailFromToken = (token: string) => {
        try {
            const { email } = jwtDecode(token) as { email: string };
            if (email) {
                Auth.clearAuth({ disableCallback: true });
                AccountAuth.clearAuth({ disableCallback: true });
                setIsAuthenticated(false);
                queryClient.clear();
                return email.toLowerCase();
            }
        } catch (err) {
            console.log("Not a valid email");
        }
    };

    const hasValidHashToken = () => {
        try {
            const { email: _email } = jwtDecode(hash.substring(1)) as { email: string };
            return true;
        } catch {
            return false;
        }
    };

    const getEmailFromInvitation = async (id: string) => {
        try {
            return await api.getInvitationData(id);
        } catch (err) {
            console.log("Not valid invitation");
        }
    };

    return {
        isAuthenticated,
        getRefreshToken: () => Auth.getRefreshToken(),
        getAccessToken: () => Auth.getAccessToken(),
        setAuth: (auth: TokenResponse) => Auth.setAuth(auth),
        clearAuth: () => {
            Auth.clearAuth();
            AccountAuth.clearAuth();
        },
        isLoading,
        signIn,
        signOut,
        forgotPassword,
        updateResatPassword,
        createAccount,
        resetPassword,
        getEmailFromToken,
        getEmailFromInvitation,
        authenticateGoogle,
        reclaim,
        hasValidHashToken,
    };
};
