import { useCurrentEditor } from "@tiptap/react";
import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import { LinkPopover } from "./LinkPopover";
import { LinkRounded } from "@mui/icons-material";
import { MenuBarButton, MenuBarButtonProps } from "../MenuBarButton";

export const LinkButton = (props: Partial<MenuBarButtonProps>) => {
    const { editor } = useCurrentEditor();

    if (!editor) {
        return null;
    }

    const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
        if (editor.isActive("link")) {
            editor.chain().focus().unsetLink().run();
            return;
        }
        const previousUrl = editor.getAttributes("link").href;
        const url = (await NiceModal.show(LinkPopover, {
            link: previousUrl,
            anchorEl: evt.currentTarget,
        })) as string;
        if (url === null) {
            return;
        }
        if (url === "") {
            editor.chain().focus().extendMarkRange("link").unsetLink().run();
            return;
        }
        editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    };

    return (
        <MenuBarButton onClick={handleClick} active={editor.isActive("link")} {...props}>
            <LinkRounded fontSize={"small"} />
        </MenuBarButton>
    );
};
