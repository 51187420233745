import Fetch from './index'
import { type Funnel, EditableFunnel } from '../Hooks/useFunnels'

export const getFunnels = async () => {
    if (typeof process.env.REACT_APP_API_FUNNELS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_FUNNELS_URL is not defined')
    }

    return Fetch.get<EditableFunnel[]>(`${process.env.REACT_APP_API_FUNNELS_URL}`)
}

export const getFunnel = async (id: string) => {
    if (typeof process.env.REACT_APP_API_FUNNELS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_FUNNELS_URL is not defined')
    }

    return Fetch.get<Funnel>(`${process.env.REACT_APP_API_FUNNELS_URL}${id}`)
}

export const updateFunnel = async (id: string, payload: Omit<EditableFunnel, 'id'>) => {
    if (typeof process.env.REACT_APP_API_FUNNELS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_FUNNELS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_FUNNELS_URL}`, { ...payload, id })
}

export const deleteFunnel = async (id: string) => {
    if (typeof process.env.REACT_APP_API_FUNNELS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_FUNNELS_URL is not defined')
    }

    return Fetch.delete(`${process.env.REACT_APP_API_FUNNELS_URL}${id}`)
}
