import { useUpcoming } from "./useUpcoming";
import { useEvent } from "./useEvent";
import { useMemo } from "react";

export const useAvailability = (eventId: string) => {
    const {
        event: { data: event },
    } = useEvent(eventId);

    const { serviceId } = event ?? {};

    const { availability, upcoming } = useUpcoming(serviceId);

    const available = useMemo<{ [key: string]: number }>(() => {
        if (availability && availability?.length > 0) {
            return (
                availability
                    .map((m) => m.times)
                    .flat()
                    .find((e) => e.eventId === eventId)?.availableSeats ?? {}
            );
        }
        return {};
    }, [availability, upcoming]);

    return {
        available,
    };
};
