import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useState } from "react";
import { pad } from "../Utils/helpers";

const getDate = (date: Date) => {
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

export const useFunnelMetrics = (type: "service" | "funnel", id?: string) => {
    const MetricsQueryKey = ["metrics", type, id];

    const metrics = useQuery(
        MetricsQueryKey,
        () => {
            return api.getFunnelMetrics(type, id as string);
        },
        {
            enabled: Boolean(id),
        }
    );

    return { metrics };
};

export const useMetrics = (defaultPeriod: { from: Date; to: Date }) => {
    const queryClient = useQueryClient();
    const [period, setPeriod] = useState(defaultPeriod);
    const [service, setService] = useState<null | string>(null);

    const MetricsQueryKey = ["metrics"];

    const metrics = useQuery(
        [...MetricsQueryKey, service, getDate(period.from), getDate(period.to)],
        () => {
            return api.getMetrics(getDate(period.from), getDate(period.to), service);
        },
        {
            enabled: true,
        }
    );

    const getMetricsForPeriod = useMutation({
        mutationFn: ({ from, to }: { from: Date; to: Date }) => {
            setPeriod({ from, to });
            return Promise.resolve();
        },
    });

    const setServiceFilter = useMutation({
        mutationFn: (serviceId: string | null) => {
            setService(serviceId);
            return Promise.resolve();
        },
    });

    return {
        metrics,
        getMetricsForPeriod,
        setServiceFilter,
    };
};
