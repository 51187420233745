import Menu from "@mui/material/Menu";
import {
    Avatar,
    Box,
    ButtonBase,
    capitalize,
    CircularProgress,
    Divider,
    ListItemText,
    MenuItem as MuiMenuItem,
    MenuItemProps,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { UnfoldMoreRounded } from "@mui/icons-material";
import { useState, MouseEvent, useMemo } from "react";
import { useProfile } from "../Hooks/useProfile";
import { getFirstName } from "../Utils/helpers";
import { ellipsisStyle } from "../Utils/theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import { useQueryClient } from "react-query";

export const AccountControl = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [switchingCompany, setSwitchingCompany] = useState(false);

    const navigate = useNavigate();

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const { clearAuth, reclaim } = useAuth();

    const queryClient = useQueryClient();

    const {
        claimableCompanies: { data: claimableCompanies },
        me,
        myRole,
        company,
    } = useProfile();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditProfile = () => {
        handleClose();
        navigate("/settings", { replace: true });
    };

    const handleLogout = () => {
        queryClient.removeQueries();
        clearAuth();
    };

    const handleSelectOrg = (org: string) => async () => {
        handleClose();
        setSwitchingCompany(true);
        await reclaim(org);
        await queryClient.invalidateQueries({ refetchActive: true });
        setSwitchingCompany(false);
    };

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <Box position={"relative"} mb={2}>
                    {switchingCompany && (
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                zIndex: 10,
                                borderRadius: "100%",
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(0,0,0,0.4)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                            }}
                        >
                            <CircularProgress color={"inherit"} />
                        </Box>
                    )}
                    <Avatar
                        component={ButtonBase}
                        sx={{
                            width: { xs: "56px", lg: "120px" },
                            height: { xs: "56px", lg: "120px" },
                            fontSize: "1.2em",
                            fontWeight: "500",
                        }}
                        src={me.data?.pictures?.profile?.url}
                    >
                        {me.isLoading ? null : getInitials(me.data?.name)}
                    </Avatar>
                    {!switchingCompany && (
                        <Avatar
                            component={ButtonBase}
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                backgroundColor: "white",
                            }}
                            src={company.data?.pictures?.logo?.url}
                        >
                            {company.isLoading ? null : getInitials(company.data?.name)}
                        </Avatar>
                    )}
                </Box>
                <ButtonBase
                    sx={{
                        width: "100%",
                        p: 1,
                        borderRadius: 1,
                        "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                        },
                    }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <Box
                        mr={1.5}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"start"}
                        flexGrow={1}
                    >
                        {me.isLoading ? (
                            <Skeleton
                                sx={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                                width={120}
                            />
                        ) : (
                            <Typography color={"white"}>
                                {isMobile ? getFirstName(me.data?.name ?? "") : me.data?.name}
                            </Typography>
                        )}
                        {company.isLoading ? (
                            <Skeleton
                                sx={{ backgroundColor: "rgba(255, 255, 255, 0.11)" }}
                                height={14}
                                width={48}
                            />
                        ) : (
                            <Typography
                                sx={{ cursor: "pointer", ...ellipsisStyle }}
                                color={"#8E8E93"}
                                fontSize={"0.88em"}
                                lineHeight={1.3}
                            >
                                {capitalize(myRole ?? "")}, {company.data?.name}
                            </Typography>
                        )}
                    </Box>
                    <UnfoldMoreRounded htmlColor={"#8E8E93"} />
                </ButtonBase>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                autoFocus={false}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            background: "#5428B4",
                            overflow: "visible",
                            color: "white",
                            border: "none",
                            boxShadow: "0 4px 16px 0 rgba(0,0,0,0.35)",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {claimableCompanies && (
                    <Box>
                        <Box px={1.5} pb={2} pt={1}>
                            <Typography
                                variant={"body2"}
                                display={"block"}
                                mb={1.5}
                                ml={2}
                                color={"#C7C7CC"}
                            >
                                Virksomheder du er tilknyttet
                            </Typography>
                            <Stack spacing={0.5} sx={{ overflowY: "auto", maxHeight: 400 }}>
                                {claimableCompanies?.map((el) => (
                                    <MenuItem
                                        selected={company.data?.id === el.org}
                                        sx={{ borderRadius: 1 }}
                                        onClick={handleSelectOrg(el.org)}
                                    >
                                        <Avatar sx={{ background: "white" }} src={el.logo} />
                                        <ListItemText
                                            primary={el.name}
                                            secondary={capitalize(el.role ?? "")}
                                            secondaryTypographyProps={{
                                                color: "#C7C7CC",
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Stack>
                        </Box>
                        <Divider sx={{ borderColor: "#3D1D8A", mb: 1 }} />
                    </Box>
                )}
                <MenuItem onClick={handleEditProfile}>Rediger profil</MenuItem>
                <MenuItem onClick={handleLogout}>Log ud</MenuItem>
            </Menu>
        </>
    );
};

export const getInitials = (label?: string) => {
    if (!label) return "";
    const parts = label.split(" ").filter((e, i) => i < 2);
    return parts.map((el) => el.slice(0, 1).toUpperCase());
};

const MenuItem = (props: MenuItemProps) => {
    return (
        <MuiMenuItem
            {...props}
            sx={{
                "&:hover": { backgroundColor: "#3D1D8A !important" },
                "&.Mui-selected": {
                    backgroundColor: "#3D1D8A !important",
                    color: "white !important",
                },
                ...props.sx,
            }}
        />
    );
};
