import { MenuItem, Select, SelectProps, Stack, TextField, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { SyiSection } from "../../SyiPage/SyiSection";
import { useTranslate } from "../../../Hooks/useTranslate";
import React, { useCallback, useMemo } from "react";
import { useErrors } from "../../SyiPage/useErrors";
import { EditableFunnel } from "../../../Hooks/useFunnels";
import { ServiceSelect } from "../../../Components/ServiceSelect";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { useService } from "../../../Hooks/useService";
import { useLocalizer } from "../../Events/EventSyiSections/EventSyiSectionDetails";

export const FunnelSyiSectionDetails = () => {
    const { t } = useTranslate("funnel.create.details");

    const { register, setValue, control } = useFormContext<EditableFunnel>();

    const { clearError, getError } = useErrors();

    const { field: serviceId } = useController({
        control,
        name: "config.serviceId",
        rules: { required: true },
    });

    const { field: variantId } = useController({
        control,
        name: "config.variantId",
        rules: { required: true },
    });

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            <Stack mt={3} spacing={3}>
                <TextField
                    label={t("name")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    error={Boolean(getError("name"))}
                    {...register("name", { required: true })}
                />
                <TextField
                    multiline={true}
                    minRows={3}
                    label={t("description")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    {...register("description")}
                />

                <SyiSection title={"Opsætning"}>
                    <Stack spacing={3}>
                        <ServiceSelect
                            serviceId={serviceId.value}
                            onChange={serviceId.onChange}
                            showStatus={false}
                            error={Boolean(getError("config.serviceId"))}
                            inputLabel={t("service")}
                            type={"session"}
                        />
                        <VariantSelect
                            onChange={variantId.onChange}
                            error={Boolean(getError("config.variantId"))}
                            disabled={typeof serviceId.value !== "string"}
                            sx={{ minWidth: 320, maxWidth: 320 }}
                            serviceId={serviceId.value}
                            variantId={variantId.value}
                        />
                        <TextField
                            type={"number"}
                            label={t("discountAmount")}
                            sx={{ minWidth: 320, maxWidth: 320 }}
                            {...register("config.discountAmount", {
                                valueAsNumber: true,
                                required: true,
                            })}
                        />
                    </Stack>
                </SyiSection>
            </Stack>
        </>
    );
};

type VariantSelectProps = {
    serviceId: string;
    variantId: string;
} & Omit<SelectProps, "defaultValue">;

const VariantSelect = ({ serviceId, variantId, ...props }: VariantSelectProps) => {
    const { service } = useService(serviceId);
    const localize = useLocalizer();
    const options = useMemo(() => {
        return service.data?.variants?.map(({ id, name }) => ({ id, name }));
    }, [service.data]);
    const getVariantName = useCallback(
        (id: string) => {
            return localize(options?.find((el) => el.id === id)?.name);
        },
        [options]
    );
    return (
        <Select
            displayEmpty
            defaultValue={variantId}
            IconComponent={ArrowDropDownRounded}
            {...props}
            renderValue={(id) => (
                <Typography variant={"h5"}>
                    {getVariantName(id as string) || "Vælg billettype"}
                </Typography>
            )}
        >
            <MenuItem value={undefined}>Vælg billettype</MenuItem>
            {options?.map((el) => (
                <MenuItem value={el.id} key={el.id}>
                    {localize(el.name)}
                </MenuItem>
            ))}
        </Select>
    );
};
