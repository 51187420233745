import {
    Box,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    StackProps,
    TextField,
    Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CalendarIcon } from "../../Icons";
import React, { ChangeEvent } from "react";
import { useController, UseFormReturn } from "react-hook-form";
import { useTranslate } from "../../Hooks/useTranslate";

interface IFrequencyUntilSelectProps {
    typeFormKey: string;
    valueFormKey: string;
    control?: UseFormReturn["control"];
    onChange?: (key: "until" | "untilType", value: string | undefined) => void;
}

export const FrequencyUntilSelect = ({
    typeFormKey,
    valueFormKey,
    control,
    onChange,
    ...props
}: Omit<StackProps, "onChange"> & IFrequencyUntilSelectProps) => {
    const { t } = useTranslate("events.create.details.recurring.frequency.customOptions");

    const { field: untilType } = useController({
        control,
        name: typeFormKey,
        defaultValue: "never",
    });
    const { field: until } = useController({ control, name: valueFormKey, defaultValue: "" });

    const handleChange = (key: "type" | "value") => (value: string | undefined) => {
        if (key === "type") {
            untilType.onChange(value);
            onChange?.("untilType", value);
        }
        if (key === "value") {
            until.onChange(value);
            onChange?.("until", value);
        }
    };

    return (
        <Stack direction={"row"} spacing={2} minHeight={48} alignItems={"flex-start"} {...props}>
            <Typography mt={2} variant={"h5"} sx={{ minWidth: 96 }}>
                {t("ends.title")}
            </Typography>
            <RadioGroup
                value={untilType.value}
                onChange={(evt, value) => handleChange("type")(value)}
            >
                <FormControlLabel
                    value={"never"}
                    control={<Radio />}
                    label={t("ends.options.never")}
                />
                <Box display={"flex"} alignItems={"center"}>
                    <FormControlLabel
                        value={"date"}
                        control={<Radio />}
                        label={t("ends.options.until")}
                        sx={{ mr: 3 }}
                    />
                    <DesktopDatePicker
                        slots={{
                            openPickerIcon: CalendarIcon,
                        }}
                        slotProps={{
                            textField: {
                                size: "small",
                            },
                        }}
                        disabled={untilType.value !== "date"}
                        value={untilType.value === "date" ? new Date(until.value) : null}
                        onChange={(val: Date | null) => handleChange("value")(val?.toString())}
                    />
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <FormControlLabel
                        value={"occurrence"}
                        control={<Radio />}
                        label={t("ends.options.after")}
                        sx={{ mr: 3 }}
                    />
                    <TextField
                        value={untilType.value === "occurrence" ? until.value : null}
                        type={"number"}
                        margin={"dense"}
                        size={"small"}
                        disabled={untilType.value !== "occurrence"}
                        sx={{ width: 164, opacity: untilType.value !== "occurrence" ? 0.5 : 1 }}
                        onChange={(evt) => handleChange("value")(evt.target.value)}
                        inputProps={{
                            min: 0,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {t("ends.options.occurrences")}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </RadioGroup>
        </Stack>
    );
};
