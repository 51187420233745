import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useOnBoarding } from "../../Hooks/useOnBoarding";
import { useTranslate } from "../../Hooks/useTranslate";

export const OnBoardingSuccessSection = () => {
    const { t } = useTranslate("onboarding.success");

    const { updateOnBoardingStep } = useOnBoarding();

    const { wasInvited } = useOutletContext<{ wasInvited: boolean }>();

    const navigate = useNavigate();

    return (
        <Stack spacing={2} pb={4} alignItems={"center"}>
            <Box
                mt={5}
                component={"img"}
                src={"/graphics/onboarding-success.png"}
                width={"100%"}
                height={300}
                maxWidth={300}
            />
            <Typography mt={2} variant={"h2"}>
                {t("boxTitle")}
            </Typography>
            <Typography whiteSpace={"pre-wrap"}>{t("boxDescription")}</Typography>
            <Stack spacing={2} pt={2} alignItems={"center"}>
                <Button
                    variant={"contained"}
                    size={"large"}
                    onClick={() => navigate(`/dashboard`, { replace: true })}
                >
                    {t("primaryButton")}
                </Button>
            </Stack>
        </Stack>
    );
};
