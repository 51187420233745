import Fetch, { IShallowSuccessResponse } from './index'
import { Customer } from '../Hooks/useCustomers'

export const getCustomers = async () => {
    if (typeof process.env.REACT_APP_API_CUSTOMERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CUSTOMERS_URL is not defined')
    }

    return Fetch.get<Customer[]>(`${process.env.REACT_APP_API_CUSTOMERS_URL}`)
}

export const getCustomer = async (customerId: string) => {
    if (typeof process.env.REACT_APP_API_CUSTOMERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CUSTOMERS_URL is not defined')
    }

    if (!customerId) {
        throw new Error('Missing lead id')
    }

    return Fetch.get<Customer>(`${process.env.REACT_APP_API_CUSTOMERS_URL}${customerId}`)
}

export const updateCustomer = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_CUSTOMERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CUSTOMERS_URL is not defined')
    }

    return Fetch.put<{ id: string }>(`${process.env.REACT_APP_API_CUSTOMERS_URL}${id}`, payload)
}

export const deleteCustomer = async (id: string) => {
    if (typeof process.env.REACT_APP_API_CUSTOMERS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_CUSTOMERS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_CUSTOMERS_URL}${id}`)
}
