import Fetch, { AccountFetch } from './index'
import { ICompanyInfo, IMyInfo } from '../Hooks/useProfile'
import randomBytes from 'randombytes'

export interface TokenPayload {
    sub: string
    alt_subs?: string[]
    auth_time?: Date
    email?: string
    org?: string
    scope?: string
}

export const getCompanyProfile = async () => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES_URL is not defined')
    }

    return Fetch.get<any>(`${process.env.REACT_APP_API_PROFILES_URL}company`)
}

export type ClaimableResponse = {
    org: string
    name: string
    logo?: string
    role: string
}[]
export const getClaimableCompanies = async () => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES_URL is not defined')
    }
    try {
        if (AccountFetch.isAuthorized) {
            return await AccountFetch.get<ClaimableResponse>(
                `${process.env.REACT_APP_API_PROFILES_URL}companies/claimable`,
            )
        }
    } catch {
        return undefined
    }
}

export const getCompanyUsers = async () => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES_URL is not defined')
    }

    return Fetch.get<any>(`${process.env.REACT_APP_API_PROFILES_URL}company/users`)
}

export const getMyProfile = async () => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES_URL is not defined')
    }

    return Fetch.get<any>(`${process.env.REACT_APP_API_PROFILES_URL}me`)
}

export const updateCompanyProfile = async (payload: ICompanyInfo, createNew = false) => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES_URL is not defined')
    }

    const path = createNew
        ? `${process.env.REACT_APP_API_PROFILES_URL}companies/${randomBytes(16).toString('hex')}`
        : `${process.env.REACT_APP_API_PROFILES_URL}company`

    return Fetch.put<ICompanyInfo>(`${path}`, { ...payload })
}

export const updateMyProfile = async (payload: IMyInfo) => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES_URL is not defined')
    }

    return Fetch.put<IMyInfo>(`${process.env.REACT_APP_API_PROFILES_URL}me`, {
        ...payload,
    })
}

export const firstLogin = async () => {
    if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PROFILES is not defined')
    }

    return Fetch.post(`${process.env.REACT_APP_API_PROFILES_URL}company/first-login`)
}

export const getUserinfo = async () => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL
    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }
    return Fetch.get<TokenPayload>(`${url}userinfo`)
}
