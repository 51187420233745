import {FormProvider, useForm} from "react-hook-form";
import {ReactNode} from "react";

export const AuthPage = ({ children }: { children: ReactNode}) => {

    const form = useForm({ reValidateMode: 'onChange' })

    return (
        <FormProvider {...form}>
            { children }
        </FormProvider>
    )
}
