import { Box, BoxProps, Button, Chip, Stack, Typography } from "@mui/material";
import React, { ReactNode, useLayoutEffect } from "react";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import NiceModal from "@ebay/nice-modal-react";
import { TBoughtVoucher, useVoucher } from "../../Hooks/useVoucher";
import { ConfirmDialog } from "../ConfirmDialog";
import { format } from "date-fns";
import { ViewHeader } from "./ViewHeader";
import { ViewFooter } from "./ViewFooter";
import { LoadingRow } from "../../Components/LoadingRow";
import { useActivities } from "../../Hooks/useActivities";

type TRow = {
    key: string;
    label: string;
    value: (el: TBoughtVoucher, t: TranslateFunction) => string | number | ReactNode;
    props?: BoxProps;
};
const rows: TRow[] = [
    {
        key: "code",
        label: "Kode",
        value: (el) => el.code,
    },
    {
        key: "voucherId",
        label: "Kode",
        value: (el) => el.id,
    },
    {
        key: "expireAt",
        label: "Udløber",
        value: (el) => format(new Date(el.expiresAt), "PPPp"),
    },
    {
        key: "availableAmount",
        label: "Beløb tilbage",
        value: (el) => `${el?.amountLeft} / ${el?.originalAmount} ${el?.currency.toUpperCase()}`,
    },
    {
        key: "status",
        label: "Status",
        value: (el, t) => (
            <Box>
                <Chip
                    label={
                        el?.originalAmount === el.amountLeft
                            ? t("unused", "utils.statusOptions")
                            : el.amountLeft === 0
                            ? t("used", "utils.statusOptions")
                            : t("partlyUsed", "utils.statusOptions")
                    }
                    size={"small"}
                />
                {el.refund?.timestamp && (
                    <Typography variant={"body2"} ml={1}>
                        Refunderet ({format(new Date(el.refund.timestamp), "PPPp")})
                    </Typography>
                )}
            </Box>
        ),
        props: {},
    },
    {
        key: "createdDate",
        label: "Købsdato",
        value: (el) => format(new Date(el.createdDate), "PPPp"),
    },
];

export const VoucherView = ({
    voucherId,
    onBack,
    onLoadCustomer,
    ...props
}: BoxProps & {
    onBack: () => void;
    voucherId: string;
    onLoadCustomer: (customerId: string) => void;
}) => {
    const { t } = useTranslate("dialogs.voucher");

    const {
        boughtVoucher: { data: voucher, isLoading },
        refundVoucher,
    } = useVoucher(voucherId);

    const { enrichedActivities: activities } = useActivities("voucher", voucherId);

    useLayoutEffect(() => {
        if (voucher?.customer?.id) {
            onLoadCustomer(voucher.customer.id);
        }
    }, [voucher]);

    const handleRefund = async () => {
        try {
            await NiceModal.show(ConfirmDialog, {
                title: t("title", "dialogs.refund"),
                headline: t("headline", "dialogs.refund", {
                    refundAmount: `${voucher?.originalAmount} ${voucher?.currency.toUpperCase()}`,
                    customerName: voucher?.customer.name,
                }),
                confirmLabel: t("actions.primary"),
            });
            return refundVoucher.mutate();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Box {...props}>
            <ViewHeader title={t("voucher")} onBack={onBack} />
            <Stack spacing={0.5}>
                {rows.map((row) => (
                    <LoadingRow
                        key={row.key}
                        label={t(row.key)}
                        value={voucher ? row.value(voucher, t) : undefined}
                        isLoading={isLoading || !voucher}
                        {...row.props}
                    />
                ))}
            </Stack>
            <ViewFooter>
                {!voucher?.refund?.timestamp && (
                    <Button variant={"outlined"} color={"secondary"} onClick={handleRefund}>
                        {t("actions.secondary")}
                    </Button>
                )}
            </ViewFooter>
            {/*<Box>
                <Typography variant={"h5"} fontSize={"1.3em"} fontWeight={700} mr={2}>
                    Historik
                </Typography>
                <Activities onClick={handleClickActivity} activities={activities} />
            </Box>*/}
        </Box>
    );
};
