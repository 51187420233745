import Fetch, { IShallowSuccessResponse } from './index'

export const getSessions = async <T>() => {
    if (typeof process.env.REACT_APP_API_SESSIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SESSIONS_URL is not defined')
    }

    return Fetch.get<T>(`${process.env.REACT_APP_API_SESSIONS_URL}`)
}

export const getSession = async <T>(id: string) => {
    if (typeof process.env.REACT_APP_API_SESSIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SESSIONS_URL is not defined')
    }

    return Fetch.get<T>(`${process.env.REACT_APP_API_SESSIONS_URL}${id}`)
}

export const getSessionForService = async <T>(serviceId?: string) => {
    if (typeof process.env.REACT_APP_API_SESSIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SESSIONS_URL is not defined')
    }

    if (!serviceId) {
        throw new Error('Missing service id')
    }

    return Fetch.get<T>(`${process.env.REACT_APP_API_SESSIONS_URL}service/${serviceId}`)
}

export const getExternalBookingsByUserId = async <T>(userId?: string) => {
    if (typeof process.env.REACT_APP_API_SESSIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SESSIONS_URL is not defined')
    }

    return Fetch.get<T>(
        `${process.env.REACT_APP_API_SESSIONS_URL}bookings/external${
            userId ? `?userId=${userId}` : ''
        }`,
    )
}
export const updateSession = async (id: string, payload: Record<string, any>) => {
    if (typeof process.env.REACT_APP_API_SESSIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SESSIONS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_SESSIONS_URL}${id}`, payload)
}

export const deleteSession = async (id: string) => {
    if (typeof process.env.REACT_APP_API_SESSIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_SESSIONS_URL is not defined')
    }

    return Fetch.delete<IShallowSuccessResponse>(`${process.env.REACT_APP_API_SESSIONS_URL}${id}`)
}
