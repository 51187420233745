import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useService } from "./useService";
import { useFunnels } from "./useFunnels";
import { useCallback, useMemo } from "react";
import { useLeads } from "./useLeads";
import { useBookings } from "./useBookings";
import { useLocalizer } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";

export type Campaign = CommonCampaign & (ServiceCampaign | FunnelCampaign) & MetaProvider;

export type EnrichedCampaign = Omit<Campaign, "subject"> & {
    subject: { type: Campaign["subject"]; name: string; variantName: string };
    bookingCount: number;
    leadCount: number;
};

type CommonCampaign = {
    name: string;
    description?: string;
    subjectId: string;
    language: string;
    companyId: string;
    id: string;
    assetId: string;
    created: string;
};

type MetaProvider = {
    provider: "meta";
    accountId: string;
    assetType: "instantForm";
};

type FunnelCampaign = {
    subject: "funnel";
};

type ServiceCampaign = {
    subject: "service";
    linkToCheckout?: string;
    serviceId: string;
    variantId?: string; // if unset intro session will be used if enabled
};
export const useCampaigns = (id?: string) => {
    const AllQueryKey = ["campaigns"];
    const SingleQueryKey = ["campaign", id];

    const queryClient = useQueryClient();

    const localize = useLocalizer();

    const { getService } = useService();
    const { getFunnel } = useFunnels();
    const {
        leads: { data: allLeads },
    } = useLeads();

    const {
        bookings: { data: allBookings },
    } = useBookings();

    const campaigns = useQuery(
        AllQueryKey,
        async () => {
            await queryClient.cancelQueries(AllQueryKey);
            const campaigns = await api.getCampaigns();
            for (const campaign of campaigns) {
                queryClient.setQueryData(["campaign", campaign.id], campaign);
            }
            return campaigns;
        },
        {
            enabled: true,
        }
    );

    const campaign = useQuery(
        SingleQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleQueryKey);
            return api.getCampaign(id as string);
        },
        {
            enabled: Boolean(id),
        }
    );

    const updateCampaign = useMutation(
        ({ id, ...payload }: Campaign) => api.updateCampaign(id, payload),
        {
            onMutate: async ({ id, ...campaign }) => {
                await queryClient.cancelQueries(AllQueryKey);

                const previous = queryClient.getQueryData(["campaign", id]);

                const previousAll = queryClient.getQueryData(["campaigns"]);

                queryClient.setQueryData<Campaign>(["campaign", id], (prev) => {
                    return {
                        ...prev,
                        ...campaign,
                        id,
                    };
                });

                queryClient.setQueryData<Campaign[]>(AllQueryKey, (prev) => {
                    if (prev?.some((p) => p.id === id)) {
                        return prev.map((el) => {
                            return el.id === id
                                ? {
                                      ...el,
                                      ...campaign,
                                      id,
                                  }
                                : el;
                        });
                    }
                    return [{ ...campaign, id }].concat(prev ?? []);
                });

                return { previous, previousAll };
            },
            onError: (err, variables, context) => {
                if (context?.previous) {
                    queryClient.setQueryData(["campaign", variables.id], context.previous);
                }
                if (context?.previousAll) {
                    queryClient.setQueryData(AllQueryKey, context.previousAll);
                }
            },
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries(["campaign", variables.id]);
                await queryClient.invalidateQueries(["campaigns"]);
            },
        }
    );

    const deleteCampaign = useMutation((id: string) => api.deleteCampaign(id), {
        onMutate: async (id) => {
            const previous = queryClient.getQueryData<Campaign>(["campaign", id]);
            const previousAll = queryClient.getQueryData<Campaign[]>(["campaigns"]);

            queryClient.removeQueries({ queryKey: ["campaign", id], exact: true });

            queryClient.setQueryData<Campaign[]>(["campaigns"], (prev) => {
                return prev?.filter((el) => !(el.id === id)) ?? [];
            });

            return { previous, previousAll };
        },
        onError: (err, id, context) => {
            if (context?.previous) {
                queryClient.setQueryData(["campaign", id], context.previous);
            }
            if (context?.previousAll) {
                queryClient.setQueryData(AllQueryKey, context.previousAll);
            }
        },
        onSettled: async (data, error, id, context) => {
            await queryClient.invalidateQueries(["campaign", id]);
            await queryClient.invalidateQueries(["campaigns"]);
        },
    });

    const leads = useCallback(
        (campaignId: string) => {
            return allLeads?.filter((lead) => lead.campaignId === campaignId)?.length ?? 0;
        },
        [allLeads, id]
    );

    const bookings = useCallback(
        (campaignId: string) => {
            return allBookings?.filter((lead) => lead.campaignId === campaignId)?.length ?? 0;
        },
        [allBookings, id]
    );

    const enrichedCampaigns = useMemo(() => {
        return campaigns.data?.map((el) => {
            if (el.subject === "funnel") {
                const { config } = getFunnel(el.subjectId) ?? {};
                if (config) {
                    const { serviceId, variantId } = config;
                    const { headline, pictures, variants } = getService(serviceId) ?? {};
                    const { name } = variants?.find((v) => variantId === v.id) ?? {};
                    return {
                        ...el,
                        bookingCount: bookings(el.id),
                        leadCount: leads(el.id),
                        subject: {
                            type: "funnel",
                            name: localize(headline),
                            variantName: localize(name),
                        },
                    };
                }
                return el;
            } else {
                const { headline, pictures, variants } = getService(el.subjectId) ?? {};
                const { name } = variants?.find((v) => el.variantId === v.id) ?? {};
                return {
                    ...el,
                    bookingCount: bookings(el.id),
                    leadCount: leads(el.id),
                    subject: {
                        type: "service",
                        name: localize(headline),
                        variantName: localize(name),
                    },
                };
            }
        });
    }, [getService, getFunnel, leads, bookings, campaigns.data]) as EnrichedCampaign[] | undefined;

    return {
        campaigns,
        enrichedCampaigns,
        campaign,
        leads,
        bookings,
        deleteCampaign,
        updateCampaign,
    };
};
