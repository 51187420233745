import {
    Control,
    useController,
    UseControllerProps,
    useFormContext,
    UseFormReturn,
} from "react-hook-form";
import React, { useEffect, useState } from "react";
import { flattenObject } from "../Utils/helpers";
import isObject from "lodash.isobject";
import { Button, Stack } from "@mui/material";
import { CancelRounded, CheckRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useTranslate } from "./useTranslate";

export const useConfirm = (
    name: string,
    onCommitChange: (value: string) => void,
    rules?: UseControllerProps["rules"] | undefined,
    control?: UseFormReturn["control"] | undefined
) => {
    const { setValue } = useFormContext();

    const { t } = useTranslate("utils");

    const { field, formState } = useController({ control, name, ...(rules && { rules }) });

    const [dirty, setDirty] = useState(false);

    const [defaultValue, setDefaultValue] = useState<null | string | { [k: string]: string }>(null);

    useEffect(() => {
        if (defaultValue === null) {
            const flat = flattenObject(formState.touchedFields);
            if (flat?.[name] && field.value) {
                setDefaultValue(field.value);
            }
        }
    }, [formState, field]);

    const handleSave = () => {
        onCommitChange?.(field.value);
        setDefaultValue(field.value);
        setTimeout(() => {
            setDirty(false);
        }, 0);
    };

    const handleCancel = () => {
        setValue(name, defaultValue ?? "", { shouldDirty: false });
        setTimeout(() => {
            setDirty(false);
        }, 0);
    };

    const handleChange = (value: string | { [k: string]: string }) => {
        field.onChange(value);
        setTimeout(() => {
            setDirty(true);
        }, 0);
    };

    const handleChangeLocalized = (value: string, langKey: string) => {
        if (field.value?.[langKey]?.trim() === value.trim()) {
            return;
        }
        handleChange({
            ...(isObject(field.value) ? field.value : {}),
            [langKey]: value,
        });
    };

    return {
        handleSave,
        handleCancel,
        handleChange,
        handleChangeLocalized,
        dirty,
        field,
        formState,
        render: () =>
            dirty && (
                <Stack mt={1} direction={"row"} spacing={1} alignItems={"center"}>
                    <Button startIcon={<CheckRounded />} color={"success"} onClick={handleSave}>
                        {t("save", "buttons")}
                    </Button>
                    <Button startIcon={<CancelRounded />} color={"error"} onClick={handleCancel}>
                        {t("undo", "buttons")}
                    </Button>
                </Stack>
            ),
    };
};
