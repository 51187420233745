import { Box, BoxProps, Skeleton, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export const LoadingRow = ({
    label,
    value,
    isLoading,
    ...props
}: BoxProps & { label: string; value: string | ReactNode; isLoading: boolean }) => {
    return (
        <Box display={"flex"} {...props}>
            <Typography fontSize={"inherit"} fontWeight={600} width={"100%"} maxWidth={160}>
                {label}
            </Typography>

            {isLoading ? (
                <Skeleton width={120} />
            ) : (
                <Typography whiteSpace={"pre-wrap"} fontSize={"inherit"}>
                    {value}
                </Typography>
            )}
        </Box>
    );
};
