import React, { ComponentPropsWithoutRef, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, Outlet, useMatch } from "react-router-dom";

import { useForm, FormProvider } from "react-hook-form";

import { useProfile } from "../../Hooks/useProfile";
import { TabBar } from "../../Components/TabBar/TabBar";
import { Page } from "../../Components/Page/Page";
import isEmpty from "lodash.isempty";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { useTerms } from "../../Hooks/useTerms";
import { useTranslate } from "../../Hooks/useTranslate";
import { useWeakStore as useStore } from "../../Store/useStore";

export const SettingsPage = ({ children }: ComponentPropsWithoutRef<"main">) => {
    const { t } = useTranslate("settings");

    const {
        params: { id },
    } = useMatch("settings/:id") ?? { params: {} };

    const { setUpdatingProfile } = useStore();

    const [activeTab, setActiveTab] = useState(0);

    const { me, updateCompany, updateMe, company, myRole } = useProfile();

    const { terms } = useTerms();

    const [hasLoadedInitially, setHasLoadedInitially] = useState(false);

    const navigate = useNavigate();

    const { getValues, formState, reset, setValue, control, ...methods } =
        useForm<Record<string, any>>();

    const _tabs = ["profile", "company", "users", "integrations", "notifications", "terms"].map(
        (key) => ({
            key,
            label: t(`navigation.${key}`),
        })
    );

    useEffect(() => {
        if (id && myRole) {
            const foundIndex = tabs.findIndex((el) => el.key === id);
            setActiveTab(foundIndex);
        }
    }, [id, myRole]);

    useEffect(() => {
        if (company.data && !company.isLoading && isEmpty(formState.dirtyFields)) {
            for (const [key, value] of Object.entries(company?.data ?? {})) {
                setValue(`company.${key}`, value, { shouldTouch: true });
            }
            setHasLoadedInitially(true);
        }
    }, [company]);

    useEffect(() => {
        if (me.data && !me.isLoading && isEmpty(formState.dirtyFields)) {
            for (const [key, value] of Object.entries(me?.data ?? {})) {
                setValue(`me.${key}`, value, { shouldTouch: true });
            }
            setHasLoadedInitially(true);
        }
    }, [me]);

    useEffect(() => {
        if (terms.data && !terms.isLoading && isEmpty(formState.dirtyFields)) {
            for (const [key, { versions }] of Object.entries(terms.data ?? {})) {
                const prepared = Object.entries(versions).reduce((mapped, [lang, { content }]) => {
                    return {
                        ...mapped,
                        [lang]: content,
                    };
                }, {});
                setValue(`terms.${key}`, prepared, { shouldTouch: true });
            }
            setHasLoadedInitially(true);
        }
    }, [terms]);

    useEffect(() => {
        if (myRole && myRole !== "admin" && id) {
            navigate(tabs[0].key, { replace: true });
        }
    }, [myRole, id]);

    const tabs = useMemo(() => {
        return myRole === "admin" ? _tabs : _tabs.filter(({ key }) => key === "profile");
    }, [myRole, t]);

    const handleChangeTab = (index: number) => {
        setActiveTab(index);
        navigate(`${tabs[index].key}`);
    };

    const submitCompany = () => {
        setUpdatingProfile(true);
        const { company: _company } = getValues();
        updateCompany.mutateAsync({ ..._company }).then(() => {
            setTimeout(() => setUpdatingProfile(false), 15000);
        });
    };

    const submitMe = () => {
        setUpdatingProfile(true);
        const { me: _me } = getValues();
        updateMe.mutateAsync({ ..._me }).then(() => {
            setTimeout(() => setUpdatingProfile(false), 15000);
        });
    };

    return (
        <Page maxWidth={1200} pb={10} position={"relative"}>
            <Typography variant={"h2"} mb={4}>
                {t("title")}
            </Typography>

            <TabBar items={tabs} onChange={handleChangeTab} selected={activeTab} />

            <FormProvider {...{ ...methods, getValues, setValue, reset, formState, control }}>
                <Stack flexGrow={1} mt={6}>
                    {!(me.data && company.data) ? (
                        <SimpleSkeleton />
                    ) : (
                        <Outlet context={{ id, submitMe, submitCompany, hasLoadedInitially }} />
                    )}
                </Stack>
            </FormProvider>
        </Page>
    );
};
