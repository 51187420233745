import { Button, type ButtonProps } from "@mui/material";
import { makeGradient } from "../Utils/gradients";

export const GradientButton = (props: ButtonProps) => {
    return (
        <Button
            {...props}
            sx={{
                ...props.sx,
                color: "#fff",
                background: makeGradient("primary"),
                "&:hover": {
                    background: makeGradient("primary", 0.9),
                },
            }}
        />
    );
};
