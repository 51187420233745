export class Grouper {
    private m
    private n

    constructor(m: number, n: number) {
        this.m = Math.min(m, n)
        this.n = Math.max(m, n)
    }

    first(): [number, number] {
        return [1, this.m]
    }

    previous([, t]: [number, number]) {
        if (t === this.m) {
            return [1, this.m]
        }
        for (--t; ; --t) {
            const s = this.valid(t)
            if (s) {
                return s
            }
        }
    }
    next([, t]: [number, number]) {
        for (++t; ; ++t) {
            const s = this.valid(t)
            if (s) {
                return s
            }
        }
    }

    valid(t: number): [number, number] | undefined {
        if (t % this.n === 0) {
            return [t / this.n, t]
        }
        if (this.m === this.n) {
            return undefined
        }
        const gMin = Math.ceil(t / this.n)
        const gMax = Math.ceil(t / this.m)
        for (let g = gMax; g !== gMin - 1; --g) {
            if (this.m * g <= t && t <= this.n * g) {
                return [g, t]
            }
        }
        return undefined
    }
}
