import { createBrowserRouter, Outlet, Navigate } from "react-router-dom";
import { AuthPage } from "./Pages/Auth/AuthPage";
import { LoginPage } from "./Pages/Auth/LoginPage";
import { CreateAccountPage } from "./Pages/Auth/CreateAccountPage";
import { ForgotPasswordPage } from "./Pages/Auth/ForgotPasswordPage";
import { ResetPasswordPage } from "./Pages/Auth/ResetPasswordPage";
import { PrivateRoute } from "./Utils/PrivateRoute";
import { CustomersPage } from "./Pages/CustomersPage";
import { BookingsListWithData } from "./Components/BookingsList/BookingsList";
import {
    LeadsListWithClosed,
    LeadsListWithoutClosedAndBookings,
} from "./Components/LeadsList/LeadsList";
import { CustomersList } from "./Components/CustomersList/CustomersList";
import { OffersList } from "./Components/OffersList/OffersList";
import { CalendarPage } from "./Pages/CalendarPage";
import { ServicesPage } from "./Pages/Services/ServicesPage";
import { VouchersPage } from "./Pages/VouchersPage";
import { DiscountPage } from "./Pages/DiscountPage";
import { DiscountSyiSectionDetails } from "./Sections/DiscountSyiSections/DiscountSyiSectionDetails";
import { EventPage } from "./Pages/Events/EventPage";
import { EventSyiSectionDetails } from "./Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { EventSyiSectionGuides } from "./Pages/Events/EventSyiSections/EventSyiSectionGuides";
import { EventSyiSectionPracticalities } from "./Pages/Events/EventSyiSections/EventSyiSectionPracticalitites";
import { ServicePage } from "./Pages/Services/ServicePage";
import { ServiceSyiSectionDetails } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionDetails";
import { ServiceSyiSectionDescription } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionDescription";
import { ServiceSyiSectionTags } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionTags";
import { ServiceSyiSectionPictures } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionPictures";
import { ServiceSyiSectionAssignees } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionAssignees";
import { ServiceSyiSectionPracticalities } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionPracticalities";
import { ServiceSyiSectionTime } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionTime";
import { ServiceSyiSectionSeats } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionSeats";
import { ServiceSyiSectionPrice } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionPrice";
import { ServiceSyiSectionCustomerInfo } from "./Pages/Services/ServiceSyiSections/ServiceSyiSectionCustomerInfo";
import { MarketingDiscountsSection } from "./Sections/MarketingSections/MarketingDiscountsSection";
import { SettingsPage } from "./Pages/Settings/SettingsPage";
import { SettingsProfileSection } from "./Sections/SettingsSections/SettingsProfileSection";
import { SettingsCompanySection } from "./Sections/SettingsSections/SettingsCompanySection";
import { SettingsUsersSection } from "./Sections/SettingsSections/SettingsUsersSection";
import { SettingsIntegrationsSection } from "./Sections/SettingsSections/SettingsIntegrationsSection";
import { SettingsNotificationsSection } from "./Sections/SettingsSections/SettingsNotificationsSection";
import { SettingsTermsSection } from "./Sections/SettingsSections/SettingsTermsSection";
import { OnBoardingPage } from "./Pages/OnBoarding/OnBoardingPage";
import { OnBoardingStartSection } from "./Pages/OnBoarding/OnBoardingStartSection";
import { OnBoardingProfileSection } from "./Pages/OnBoarding/OnBoardingProfileSection";
import { OnBoardingCompanySection } from "./Pages/OnBoarding/OnBoardingCompanySection";
import { OnBoardingLocaleSection } from "./Pages/OnBoarding/OnBoardingLocaleSection";
import { OnBoardingTagsSection } from "./Pages/OnBoarding/OnBoardingTagsSection";
import { OnBoardingSuccessSection } from "./Pages/OnBoarding/OnBoardingSuccessSection";
import { DashboardPage } from "./Pages/DashboardPage";
import { DashboardOverviewSection } from "./Sections/DashboardSections/DashboardOverviewSection";
import NiceModal from "@ebay/nice-modal-react";
import { FunnelsPage } from "./Pages/Funnels/FunnelsPage";
import { FunnelPage } from "./Pages/Funnels/FunnelPage";
import { FunnelSyiSectionDetails } from "./Pages/Funnels/FunnelSyiSections/FunnelSyiSectionDetails";
import { FunnelSyiSectionDomain } from "./Pages/Funnels/FunnelSyiSections/FunnelSyiSectionDomain";
import { FunnelSyiSectionProfile } from "./Pages/Funnels/FunnelSyiSections/FunnelSyiSectionProfile";
import { CampaignsPage } from "./Pages/Campaigns/CampaignsPage";
import { CampaignPage } from "./Pages/Campaigns/CampaignPage";
import { CampaignSyiSectionSetup } from "./Pages/Campaigns/CampaignSyiSections/CampaignSyiSectionSetup";

const router = createBrowserRouter([
    {
        element: (
            <NiceModal.Provider>
                <Outlet />
            </NiceModal.Provider>
        ),
        children: [
            {
                path: "login",
                element: (
                    <AuthPage>
                        <LoginPage />
                    </AuthPage>
                ),
            },
            {
                path: "create",
                element: (
                    <AuthPage>
                        <CreateAccountPage />
                    </AuthPage>
                ),
            },
            {
                path: "forgot",
                element: (
                    <AuthPage>
                        <ForgotPasswordPage />
                    </AuthPage>
                ),
            },
            {
                path: "reset",
                element: (
                    <AuthPage>
                        <ResetPasswordPage />
                    </AuthPage>
                ),
            },
            {
                path: "bookings/*",
                element: (
                    <PrivateRoute>
                        <CustomersPage>
                            <BookingsListWithData
                                type={"session"}
                                showTitle={false}
                                showService={true}
                            />
                        </CustomersPage>
                    </PrivateRoute>
                ),
            },
            {
                path: "leads/closed",
                element: (
                    <PrivateRoute>
                        <CustomersPage>
                            <LeadsListWithClosed />
                        </CustomersPage>
                    </PrivateRoute>
                ),
            },
            {
                path: "leads/*",
                element: (
                    <PrivateRoute>
                        <CustomersPage>
                            <LeadsListWithoutClosedAndBookings />
                        </CustomersPage>
                    </PrivateRoute>
                ),
            },
            {
                path: "customers/*",
                element: (
                    <PrivateRoute>
                        <CustomersPage>
                            <CustomersList />
                        </CustomersPage>
                    </PrivateRoute>
                ),
            },
            {
                path: "offers/*",
                element: (
                    <PrivateRoute>
                        <CustomersPage>
                            <OffersList />
                        </CustomersPage>
                    </PrivateRoute>
                ),
            },
            {
                path: "calendar",
                element: (
                    <PrivateRoute>
                        <CalendarPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "services",
                element: (
                    <PrivateRoute>
                        <ServicesPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "funnels",
                element: (
                    <PrivateRoute>
                        <FunnelsPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "funnel/:id",
                element: (
                    <PrivateRoute showAppShell={false}>
                        <FunnelPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: "edit",
                        children: [
                            { index: true, element: <Navigate to={"details"} /> },
                            { path: "details", element: <FunnelSyiSectionDetails /> },
                            { path: "profile", element: <FunnelSyiSectionProfile /> },
                            { path: "domain", element: <FunnelSyiSectionDomain /> },
                        ],
                    },
                    {
                        path: "details",
                        element: <FunnelSyiSectionDetails />,
                    },
                    {
                        path: "profile",
                        element: <FunnelSyiSectionProfile />,
                    },
                    {
                        path: "domain",
                        element: <FunnelSyiSectionDomain />,
                    },
                    {
                        index: true,
                        element: <Navigate to={"details"} />,
                    },
                ],
            },
            {
                path: "campaigns",
                element: (
                    <PrivateRoute>
                        <CampaignsPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "campaign/:id",
                element: (
                    <PrivateRoute showAppShell={false}>
                        <CampaignPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: "edit",
                        children: [
                            { index: true, element: <Navigate to={"setup"} /> },
                            { path: "setup", element: <CampaignSyiSectionSetup /> },
                        ],
                    },
                    {
                        path: "setup",
                        element: <CampaignSyiSectionSetup />,
                    },
                    {
                        index: true,
                        element: <Navigate to={"setup"} />,
                    },
                ],
            },
            {
                path: "vouchers/*",
                element: (
                    <PrivateRoute>
                        <VouchersPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "discount/:id",
                element: (
                    <PrivateRoute showAppShell={false}>
                        <DiscountPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: "edit",
                        children: [
                            { index: true, element: <Navigate to={"details"} /> },
                            { path: "details", element: <DiscountSyiSectionDetails /> },
                        ],
                    },
                    {
                        path: "details",
                        element: <DiscountSyiSectionDetails />,
                    },
                    {
                        index: true,
                        element: <Navigate to={"details"} />,
                    },
                ],
            },
            {
                path: "event/:id",
                element: (
                    <PrivateRoute showAppShell={false}>
                        <EventPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: "edit",
                        children: [
                            {
                                index: true,
                                element: <Navigate to={"details"} />,
                            },
                            { path: "details", element: <EventSyiSectionDetails /> },
                            { path: "guides", element: <EventSyiSectionGuides /> },
                            { path: "practicalities", element: <EventSyiSectionPracticalities /> },
                        ],
                    },
                    {
                        index: true,
                        element: <Navigate to={"details"} />,
                    },
                    { path: "details", element: <EventSyiSectionDetails /> },
                    { path: "guides", element: <EventSyiSectionGuides /> },
                    { path: "practicalities", element: <EventSyiSectionPracticalities /> },
                ],
            },
            {
                path: "service/:id",
                element: (
                    <PrivateRoute showAppShell={false}>
                        <ServicePage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: "edit",
                        children: [
                            {
                                index: true,
                                element: <Navigate to={"details"} />,
                            },
                            { path: "details", element: <ServiceSyiSectionDetails /> },
                            { path: "description", element: <ServiceSyiSectionDescription /> },
                            { path: "tags", element: <ServiceSyiSectionTags /> },
                            { path: "pictures", element: <ServiceSyiSectionPictures /> },
                            { path: "assignees", element: <ServiceSyiSectionAssignees /> },
                            {
                                path: "practicalities",
                                element: <ServiceSyiSectionPracticalities />,
                            },
                            { path: "time", element: <ServiceSyiSectionTime /> },
                            { path: "seats", element: <ServiceSyiSectionSeats /> },
                            { path: "price", element: <ServiceSyiSectionPrice /> },
                            { path: "customer-info", element: <ServiceSyiSectionCustomerInfo /> },
                        ],
                    },
                    {
                        index: true,
                        element: <Navigate to={"details"} />,
                    },
                    { path: "details", element: <ServiceSyiSectionDetails /> },
                    { path: "description", element: <ServiceSyiSectionDescription /> },
                    { path: "tags", element: <ServiceSyiSectionTags /> },
                    { path: "pictures", element: <ServiceSyiSectionPictures /> },
                    { path: "assignees", element: <ServiceSyiSectionAssignees /> },
                    { path: "practicalities", element: <ServiceSyiSectionPracticalities /> },
                    { path: "time", element: <ServiceSyiSectionTime /> },
                    { path: "seats", element: <ServiceSyiSectionSeats /> },
                    { path: "price", element: <ServiceSyiSectionPrice /> },
                    { path: "customer-info", element: <ServiceSyiSectionCustomerInfo /> },
                ],
            },
            {
                path: "discounts",
                element: (
                    <PrivateRoute>
                        <MarketingDiscountsSection />
                    </PrivateRoute>
                ),
            },
            {
                path: "settings",
                element: (
                    <PrivateRoute>
                        <SettingsPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={"profile"} />,
                    },
                    {
                        path: "profile",
                        element: <SettingsProfileSection />,
                    },
                    {
                        path: "company",
                        element: <SettingsCompanySection />,
                    },
                    {
                        path: "users",
                        element: <SettingsUsersSection />,
                    },
                    {
                        path: "integrations",
                        element: <SettingsIntegrationsSection />,
                    },
                    {
                        path: "terms",
                        element: <SettingsTermsSection />,
                    },
                    {
                        path: "notifications",
                        element: <SettingsNotificationsSection />,
                    },
                ],
            },
            {
                path: "welcome",
                element: (
                    <PrivateRoute showAppShell={false}>
                        <OnBoardingPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={"start"} />,
                    },
                    {
                        path: "start",
                        element: <OnBoardingStartSection />,
                    },
                    {
                        path: "profile",
                        element: <OnBoardingProfileSection />,
                    },
                    {
                        path: "company",
                        element: <OnBoardingCompanySection />,
                    },
                    {
                        path: "locale",
                        element: <OnBoardingLocaleSection />,
                    },
                    {
                        path: "tags",
                        element: <OnBoardingTagsSection />,
                    },
                    {
                        path: "success",
                        element: <OnBoardingSuccessSection />,
                    },
                ],
            },
            {
                path: "dashboard",
                element: (
                    <PrivateRoute>
                        <DashboardPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={"overview"} />,
                    },
                    {
                        path: "overview",
                        element: <DashboardOverviewSection />,
                    },
                ],
            },
            {
                path: "*",
                element: <Navigate to={"/dashboard"} />,
            },
        ],
    },
]);
export default router;
