import {
    Box,
    BoxProps,
    Button,
    Card,
    Chip,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuItemProps,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { DeleteForeverRounded, EditRounded, MoreVert, SvgIconComponent } from "@mui/icons-material";
import { DiscountIcon } from "../../Icons";
import type { TDiscount } from "../../Hooks/useDiscount";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { renderDate } from "@hiddengemgroup/utils-date";
import { useLocation, useNavigate } from "react-router-dom";
import react, { MouseEvent, useState } from "react";
import { BlackMenu } from "../BlackMenu/BlackMenu";
import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";

const isInPast = (dateStr?: string | null) => {
    if (!dateStr) return false;
    return new Date(dateStr) < new Date();
};

const columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        value: () => <DiscountIcon />,
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
    },
    {
        key: "code",
        label: "Kode",
        props: {
            xs: 1.5,
        },
        value: (el: TDiscount) => el?.code,
    },
    {
        key: "created",
        label: "Oprettet",
        props: {
            xs: 2,
        },
        value: (el: TDiscount) => renderDate(el.created, "ddmmyyyy"),
    },
    {
        key: "value",
        label: "Værdi",
        props: {
            xs: 1.5,
        },
        value: (el: TDiscount) =>
            el.overrides ? "-" : `${el?.rate} ${el.percentage ? "%" : el?.currency.toUpperCase()}`,
    },
    {
        key: "expiry",
        label: "Udløb",
        value: (el: TDiscount) => (el.expiry ? renderDate(el.expiry.endDateTime) : "-"),
        props: {
            xs: 2,
        },
    },
    {
        key: "redeemed",
        label: "Indløst",
        value: (el: TDiscount) => el.timesRedeemed ?? 0,
        props: {
            xs: 1.5,
        },
    },
    {
        key: "status",
        label: "Status",
        props: {},
        value: (el: TDiscount, t: TranslateFunction) => (
            <Chip
                color={
                    el.timesRedeemed === el.limits?.timesRedeemed
                        ? "default"
                        : el.status === "active"
                        ? "success"
                        : "default"
                }
                component={"span"}
                label={
                    isInPast(el.expiry?.endDateTime)
                        ? t("expired", "utils.statusOptions")
                        : el.timesRedeemed === el.limits?.timesRedeemed
                        ? t("inactive", "utils.statusOptions")
                        : t(el.status, "utils.statusOptions")
                }
            />
        ),
    },
];

const options: {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
}[] = [
    {
        key: "edit",
        label: "Rediger",
        icon: EditRounded,
    },
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

export const DiscountsList = ({
    discounts,
    onDelete,
    ...props
}: BoxProps & { onDelete: (id: string) => void; discounts?: TDiscount[] }) => {
    const { t } = useTranslate("utils.tables.header");

    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | [HTMLElement, number]>(null);
    const open = Boolean(anchorEl);

    const handleClick = (discountId: string) => () => {
        navigate(`/discount/${discountId}/edit`, { state: { returnUrl: location.pathname } });
    };

    const handleClickOptions = (index: number) => (event: react.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl([event.currentTarget, index]);
    };

    const handleCloseOptions = (evt: MouseEvent) => {
        evt.stopPropagation();
        setAnchorEl(null);
    };

    const handleSelectOption = (key: string, index?: number) => (evt: MouseEvent) => {
        if (index === undefined) {
            return;
        }
        if (key === "edit") {
            const { id } = discounts?.[index] ?? {};
            if (id) {
                handleClick(id)();
            }
        }
        if (key === "delete") {
            NiceModal.show(ConfirmDialog, {
                headline: t("headline", "dialogs.confirmDelete", {
                    type: t("thisDiscount", "utils.generic"),
                }),
                title: t("title", "dialogs.confirmDelete"),
                confirmLabel: t("actions.primary", "dialogs.confirmDelete"),
            }).then(() => {
                const { id } = discounts?.[index] ?? {};
                if (id) {
                    onDelete(id);
                }
            });
        }
        handleCloseOptions(evt);
    };

    return (
        <Box {...props}>
            <BlackMenu
                anchorEl={anchorEl?.[0]}
                open={open}
                onClose={handleCloseOptions}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {options.map((el, i) => {
                    const Icon = el.icon as any;
                    return (
                        <MenuItem
                            autoFocus={false}
                            key={el.key}
                            onClick={handleSelectOption(el.key, anchorEl?.[1])}
                        >
                            <ListItemIcon>
                                <Icon fontSize={"small"} {...el.props} />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={el.props}>
                                {el.label}
                            </ListItemText>
                        </MenuItem>
                    );
                })}
            </BlackMenu>

            <Grid container p={"12px"} mb={1}>
                {columns.map((el) => (
                    <Grid
                        fontSize={"0.75em"}
                        fontWeight={600}
                        textTransform={"uppercase"}
                        key={el.key}
                        item
                        {...el.props}
                        component={Typography}
                    >
                        {el.key !== "none" && t(el.key)}
                    </Grid>
                ))}
            </Grid>

            <Stack spacing={1}>
                {discounts?.map((discount, i) => (
                    <Grid
                        container
                        key={discount.id}
                        component={Card}
                        p={1.5}
                        alignItems={"center"}
                        sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "rgb(252,252,252)" },
                        }}
                        onClick={handleClick(discount.id)}
                    >
                        {columns.map((el) => (
                            <Grid
                                fontSize={"0.88em"}
                                key={el.key}
                                item
                                {...el.props}
                                {...el.valueProps}
                                component={Typography}
                            >
                                {el.value?.(discount, t)}
                            </Grid>
                        ))}
                        <Grid item flexGrow={1} textAlign={"right"}>
                            <IconButton size={"small"} onClick={handleClickOptions(i)}>
                                <MoreVert />
                            </IconButton>
                        </Grid>
                    </Grid>
                )) ?? (
                    <Stack spacing={1}>
                        <Stack spacing={4} pl={7} component={Card} direction={"row"}>
                            <Skeleton width={60} />
                            <Skeleton width={180} />
                            <Skeleton width={240} />
                            <Skeleton width={164} />
                        </Stack>
                        <Stack spacing={4} pl={7} component={Card} direction={"row"}>
                            <Skeleton width={60} />
                            <Skeleton width={180} />
                            <Skeleton width={211} />
                            <Skeleton width={120} />
                        </Stack>
                        <Stack spacing={4} pl={7} component={Card} direction={"row"}>
                            <Skeleton width={60} />
                            <Skeleton width={180} />
                            <Skeleton width={240} />
                            <Skeleton width={164} />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};
