import { Box, Button, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import { useTranslation } from "react-i18next";
import { AuthLayout } from "./AuthLayout";
import { AuthForm } from "./AuthForm";
import type { TInput } from "./AuthForm";
import { useEffect, useState } from "react";
import { FieldErrors } from "react-hook-form";
import { toast } from "react-toastify";

interface IForgotPasswordFormValues {
    password: string;
    repeatPassword: string;
}

const inputs: Array<TInput<IForgotPasswordFormValues>> = [
    {
        key: "password",
        type: "password",
        isRequired: false,
        variant: "outlined",
    },
    {
        key: "repeatPassword",
        type: "password",
        isRequired: true,
        autoFocus: false,
        variant: "outlined",
    },
];

export const ResetPasswordPage = () => {
    const { t } = useTranslation();

    const { hash } = useLocation();

    useEffect(() => {
        if (!hash) {
            navigate("/login");
        }
    }, [hash]);

    const navigate = useNavigate();
    const { updateResatPassword } = useAuth();

    const [errors, setErrors] = useState<FieldErrors>({});

    const onSubmit = async (data: IForgotPasswordFormValues) => {
        const { password, repeatPassword } = data;
        if (password !== repeatPassword) {
            return setErrors({
                repeatPassword: { message: t("auth.errors.passwordShouldMatch") as any },
            });
        }
        try {
            await updateResatPassword(password, hash.substring(1));
            toast.success(t("auth.toast.resetPasswordSuccess"));
            navigate("/", { replace: true });
        } catch (err) {
            console.log("Cannot update password");
        }
    };

    const handleGoToLogin = () => navigate("/login");

    return (
        <AuthLayout
            title={t("auth.titles.resetPassword")}
            hideFooter={true}
            hideTabs={true}
            onBack={handleGoToLogin}
        >
            <AuthForm
                inputs={inputs}
                showForgotPassword={false}
                buttonLabel={t("auth.buttons.resetPassword")}
                onSubmit={onSubmit}
                errors={errors}
            />
        </AuthLayout>
    );
};
