import { hasOrgInToken, useAuth } from "../Hooks/useAuth";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { AppShell } from "../Components/AppShell/AppShell";
import { useEffect, useState } from "react";

export const PrivateRoute = ({
    children,
    showAppShell = true,
}: {
    children: JSX.Element;
    showAppShell?: boolean;
}) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const hasOrg = hasOrgInToken();
        if (!hasOrg && isAuthenticated && !location.pathname.includes("/welcome/")) {
            navigate("/welcome/profile");
        }
    }, []);

    if (!isAuthenticated) {
        return <Navigate to={getLogoutToPath(location)} state={{ from: location }} replace />;
    }

    return showAppShell ? <AppShell>{children}</AppShell> : children;
};

const getLogoutToPath = (location: ReturnType<typeof useLocation>) => {
    if (location.pathname.startsWith("/create")) {
        return location.pathname + location.hash;
    }
    if (location.pathname.startsWith("/login")) {
        return location.pathname + location.hash;
    }
    return "/login";
};
