import {
    AddRounded,
    ArrowDropDownRounded,
    HelpOutlineRounded,
    RemoveRounded,
} from "@mui/icons-material";
import {
    Box,
    BoxProps,
    capitalize,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    OutlinedInput,
    Popover,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from "@mui/material";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";
import { ReactNode, useMemo, useState } from "react";
import { Grouper } from "../../Utils/grouper";
import { useUpcoming } from "../../Hooks/useUpcoming";
import { useAvailability } from "../../Hooks/useAvailability";
import { useProfile } from "../../Hooks/useProfile";
import { SimpleSkeleton } from "../SimpleSkeleton/SimpleSkeleton";

export const formatMoney =
    (t: TranslateFunction, showZero = false) =>
    ({ value, nativeCurrency = "dkk" }: { value: number; nativeCurrency: string }) => {
        if (value === undefined) return "";
        if ((value === 0 || value === null) && !showZero) return t("free", "utils.generic");
        if (value === -1) return t("utils.comingSoon");
        return value.toLocaleString(nativeCurrency, {
            style: "currency",
            currency: nativeCurrency,
            maximumFractionDigits: 0,
        });
    };

export const getUnitCount = (count: number, seatProps?: object | null) => {
    const { max = 1, type = "single" } = seatProps ?? ({} as any);
    const _m = type === "couple" ? 2 : max === null ? 1 : max;
    return Math.floor(count / _m) + (count % _m > 0 ? 1 : 0);
};

export const VariantSelect = ({
    serviceId,
    eventId,
    onSubmit,
}: {
    serviceId?: string;
    eventId: string;
    onSubmit: (value: { [k: string]: number }) => void;
}) => {
    const { t } = useTranslate("utils.generic");

    const { variants, products } = useUpcoming(serviceId);

    const { defaultCurrency } = useProfile();

    const [guests, setGuests] = useState<{ [k: string]: number }>({});

    const { available } = useAvailability(eventId);

    const [showPopover, setShowPopover] = useState(false);
    const [anchor, setAnchor] = useState<HTMLElement>();

    const handleSubmit = () => {
        setShowPopover(false);
        onSubmit(guests);
    };

    const handleStep =
        (key: string) =>
        (direction: "up" | "down", step = 1) =>
        () => {
            setGuests((p) => {
                const maxAvailable = available[key] ?? 0;
                const nextState: { [id: string]: number } = (() => {
                    const { seatProps } = variants?.find((p) => p.id === key) ?? {};
                    if (seatProps?.min && seatProps?.max) {
                        const grouper = new Grouper(seatProps.min, seatProps.max);
                        const newValue = (p?.[key] ?? 0) + (direction === "up" ? step : -step);
                        if (grouper.valid(newValue)) {
                            return {
                                ...p,
                                [key]: newValue,
                            };
                        }
                        const nextValue = grouper[direction === "up" ? "next" : "previous"]([
                            0,
                            newValue,
                        ])[1];
                        return {
                            ...p,
                            [key]: nextValue > maxAvailable ? p?.[key] ?? 0 : nextValue,
                        };
                    }
                    return {
                        ...p,
                        [key]: (p?.[key] ?? 0) + (direction === "up" ? step : -step),
                    };
                })();
                const withoutZeroes = Object.entries(nextState).reduce<{ [id: string]: number }>(
                    (acc, [id, count]) => {
                        return count > 0
                            ? {
                                  ...acc,
                                  [id]: count,
                              }
                            : acc;
                    },
                    {}
                );
                const _keys = Object.keys(withoutZeroes);
                return Object.entries(withoutZeroes).reduce((acc, [id, count]) => {
                    if (id.startsWith("addon")) {
                        const { parent } = products?.find((el) => el.id === id) ?? {};
                        if (!_keys.includes(parent ?? "")) {
                            return acc;
                        }
                    }
                    return {
                        ...acc,
                        [id]: count,
                    };
                }, {});
            });
        };

    const handleClickGuests = () => {
        setShowPopover(true);
    };

    const totalGuests = useMemo(() => {
        return Object.entries(guests).reduce((acc, [id, count]) => {
            return acc + count;
        }, 0);
    }, [guests]);

    return (
        <Box position={"relative"} width={"100%"}>
            <Box
                component={FormControl}
                width={"100%"}
                onClick={handleClickGuests}
                mt={5}
                sx={{ cursor: "pointer", "&:hover fieldset": { borderColor: "#1A2027" } }}
            >
                <InputLabel
                    sx={{ background: "white", textTransform: "capitalize" }}
                    htmlFor="time"
                >
                    {t("guests")}
                </InputLabel>
                <OutlinedInput
                    ref={(ref) => setAnchor(ref as HTMLElement)}
                    size={"medium"}
                    fullWidth
                    sx={{
                        width: "100%",
                        pointerEvents: "none",
                        pr: 0.5,
                        fontSize: { xs: "0.9em" },
                    }}
                    endAdornment={<ArrowDropDownRounded htmlColor="#8E8E93" />}
                    value={
                        totalGuests > 0
                            ? `${totalGuests} ${t(`guest`)}${totalGuests > 1 ? t("plural") : ""}`
                            : "Vælg antal gæster..."
                    }
                    id="guests"
                />
            </Box>
            <Popover
                open={showPopover}
                onClose={handleSubmit}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Stack spacing={2} p={3} width={anchor?.clientWidth ?? "auto"}>
                    {variants?.map((el, i) => {
                        return (
                            <Box key={el.id}>
                                <ProductRow
                                    label={
                                        el.name ??
                                        el.seatProps?.customName ??
                                        capitalize(t(el.seatProps?.type ?? ""))
                                    }
                                    price={formatMoney(t)({
                                        value: el.price.amount,
                                        nativeCurrency: el.price.currency,
                                    })}
                                    maxAvailable={available?.[el.id] ?? 0}
                                    step={el.seatProps?.type === "couple" ? 2 : 1}
                                    count={guests?.[el.id] ?? 0}
                                    onStep={handleStep(el.id)}
                                />

                                {Boolean(guests?.[el.id]) &&
                                    el.addons?.map((ad) => (
                                        <ProductRow
                                            key={ad.id}
                                            label={ad.name}
                                            explanation={ad.explanation}
                                            price={formatMoney(t)({
                                                value: ad.price.amount,
                                                nativeCurrency: ad.price.currency,
                                            })}
                                            count={guests?.[ad.id] ?? 0}
                                            onStep={handleStep(ad.id)}
                                            p={1.5}
                                            mt={1}
                                            borderRadius={1}
                                            border={"1px dashed #C7C7CC"}
                                        />
                                    ))}
                            </Box>
                        );
                    }) ?? <SimpleSkeleton />}
                </Stack>
            </Popover>
        </Box>
    );
};

const ProductRow = ({
    label,
    price,
    explanation,
    step = 1,
    maxAvailable = 100,
    count,
    onStep,
    ...props
}: BoxProps & {
    label: string;
    explanation?: string;
    count: number;
    maxAvailable?: number;
    step?: number;
    onStep: (direction: "up" | "down", step?: number) => () => void;
    price: string;
}) => {
    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} {...props}>
            <Box>
                <Typography variant={"h5"} fontSize={"1.1em"} display="flex" alignItems="center">
                    {label}
                    {explanation && (
                        <Tooltip title={explanation}>
                            <HelpOutlineRounded
                                sx={{ pl: 0.5 }}
                                fontSize="medium"
                                htmlColor="#8E8E93"
                            />
                        </Tooltip>
                    )}
                </Typography>
                <Typography variant={"body2"} color={"grey.700"}>
                    {price}
                </Typography>
            </Box>
            <Stack spacing={0.5} direction={"row"} alignItems={"center"}>
                <IconButton
                    size={"small"}
                    disabled={count === 0}
                    onClick={onStep("down", step)}
                    sx={{ border: "1px solid", borderColor: "#C7C7CC" }}
                >
                    <RemoveRounded fontSize={"small"} />
                </IconButton>
                <Typography variant={"h4"} textAlign={"center"} width={"40px"}>
                    {count}
                </Typography>
                <IconButton
                    size={"small"}
                    disabled={count >= maxAvailable}
                    onClick={onStep("up", step)}
                    sx={{ border: "1px solid", borderColor: "#C7C7CC" }}
                >
                    <AddRounded fontSize={"small"} />
                </IconButton>
            </Stack>
        </Box>
    );
};

export const Row = ({
    left,
    right,
    leftProps = {},
    rightProps = {},
    ...props
}: BoxProps<
    any,
    {
        left: string | ReactNode;
        right?: string | ReactNode;
        leftProps?: TypographyProps;
        rightProps?: TypographyProps;
    }
>) => (
    <Box display={"flex"} justifyContent={"space-between"} {...props}>
        {typeof left === "string" ? <Typography {...leftProps}>{left}</Typography> : left}
        {typeof right === "string" ? <Typography {...rightProps}>{right}</Typography> : right}
    </Box>
);
