import {Card, Skeleton, Stack, StackProps} from "@mui/material";
import React from "react";

export const ListSkeleton = (props: StackProps) => (
    <Stack spacing={1} { ...props}>
        <Stack spacing={4} pl={7} component={Card} direction={'row'}>
            <Skeleton width={60}/>
            <Skeleton width={180}/>
            <Skeleton width={240}/>
            <Skeleton width={164}/>
        </Stack>
        <Stack spacing={4} pl={7} component={Card} direction={'row'}>
            <Skeleton width={60}/>
            <Skeleton width={180}/>
            <Skeleton width={211}/>
            <Skeleton width={120}/>
        </Stack>
        <Stack spacing={4} pl={7} component={Card} direction={'row'}>
            <Skeleton width={60}/>
            <Skeleton width={180}/>
            <Skeleton width={240}/>
            <Skeleton width={164}/>
        </Stack>
    </Stack>
)
