import {Box, BoxProps, styled} from '@mui/material';
import Tab, {ITabProps} from './Tab';

const Styled = styled(Box)`
  position: relative;
  padding: 4px;
  border-radius: 12px;
  background: #E5E5EA;

  & > .items {
    display: grid;
    grid-template-columns: repeat(${(props: { count: number }) => props.count}, 1fr);
  }

  & > .line {
    position: absolute;
    width: calc((100% / ${props => props.count}) );
    height: 2px;
    left: calc(${(props: { count: number, selectedIndex: number }) => (100 / props.count) * (props.selectedIndex)}%);
    background-color: var(--color-main);
    transition: left 0.2s;
  }
`;

interface ITabsProps {
    items: (Omit<ITabProps, 'selected'> & { key: string })[],
    selectedKey?: string,
    handleClick: (key: string) => void
}

const Tabs = ({ items, selectedKey, handleClick, ...props }: BoxProps & ITabsProps) => {

    const index = items?.findIndex(el => el.key === selectedKey)

  return (
    <Styled count={items.length} selectedIndex={index} { ...props }>

      <div className={'items'}>
        {
          items.map((el, i) => {

            return <Tab
              { ...el }
              onClick={() => handleClick(el.key)}
              selected={i === index}
            />

          })

        }
      </div>

      { /*<div className={'line'}/> */ }

    </Styled>
  )

};

export default Tabs;
