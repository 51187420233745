import {
    Box,
    BoxProps,
    ButtonBase,
    Card,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { format, formatDistanceToNow } from "date-fns";
import { BookingsIcon } from "../../Icons";
import { TBooking, useBookings } from "../../Hooks/useBookings";
import { StatusChip } from "../StatusChip";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { BookingListEmptyScreen } from "../EmptyScreen/EmptyScreen";
import { useTranslate } from "../../Hooks/useTranslate";
import { ListHeader } from "../ListHeader/ListHeader";
import { renderTime } from "@hiddengemgroup/utils-date";
import { IconLabel, IconLabelProps } from "../IconLabel/IconLabel";
import {
    AccessAlarmOutlined,
    AccessTimeRounded,
    EmailOutlined,
    MoreVert,
    PhoneOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { CustomerDialog } from "../../Modals/CustomerDialog/CustomerDialog";
import { serviceChannelOptions } from "../../Pages/Services/ServiceSyiSections/ServiceSyiSectionDetails";
import { renderIcon } from "../OptionChips/OptionChips";
import { clamp, ellipsisStyle } from "../../Utils/theme";
import { useListItemOptions } from "../../Hooks/useListItemOptions";
import { ReminderDialog } from "../../Modals/ReminderDialog";
import { renderGuestTypes } from "../../Utils/helpers";

const renderDate = (date: string) => {
    try {
        return format(new Date(date), "dd/MM/yyyy");
    } catch (err) {
        return date;
    }
};

export const renderChannel = (
    bookingOrChannel: Pick<TBooking, "contactChannel" | "location"> | string,
    hideLabelOnMobile = false,
    props?: Partial<IconLabelProps>,
    showPhysicalAddress = false
) => {
    const { channel, location } = getBookingValues(bookingOrChannel);
    if (bookingOrChannel) {
        const { label, icon } = serviceChannelOptions.find((el) => el.key === channel) ?? {};
        if (label && icon) {
            return (
                <IconLabel
                    label={
                        channel === "inPerson" && location && showPhysicalAddress ? location : label
                    }
                    icon={renderIcon(icon)}
                    {...props}
                    sx={{
                        ...props?.sx,
                        display: { xs: hideLabelOnMobile ? "none" : "block", lg: "block" },
                    }}
                />
            );
        }
    }
};

const getBookingValues = (
    bookingOrChannel: Pick<TBooking, "contactChannel" | "location"> | string
) => {
    return typeof bookingOrChannel === "string"
        ? { channel: bookingOrChannel, location: undefined }
        : { channel: bookingOrChannel.contactChannel, location: bookingOrChannel.location };
};

const _columns = [
    {
        key: "none",
        props: {
            xs: 0.5,
        },
        value: () => <BookingsIcon />,
        valueProps: {
            display: "flex",
            pl: 0.5,
            alignItems: "center",
        },
    },
    {
        key: "name",
        label: "Navn",
        props: {
            xs: 3.2,
            lg: 2.6,
        },
        value: (el: TBooking) => (
            <>
                <Typography
                    fontSize={{ xs: "0.88em", lg: "1em" }}
                    lineHeight={"1.1"}
                    fontWeight={"500"}
                >
                    {el.customer?.name}
                </Typography>
                <IconLabel
                    variant={"body2"}
                    fontSize={"0.75em"}
                    Icon={AccessTimeRounded}
                    label={formatDistanceToNow(new Date(el.created)) + " siden"}
                />
            </>
        ),
    },
    {
        key: "contact",
        label: "Kontakt",
        props: {
            xs: 3,
            lg: 2.5,
            pr: 0.5,
        },
        value: (el: TBooking) => (
            <>
                <IconLabel sx={ellipsisStyle} Icon={EmailOutlined} label={el.customer?.email} />
                <IconLabel Icon={PhoneOutlined} label={el.customer?.phone ?? undefined} />
            </>
        ),
    },
    {
        key: "service",
        label: "Ydelse",
        mobile: false,
        props: {
            xs: 2,
            pr: 1,
        },
        value: (el: TBooking) => (
            <Typography sx={{ ...ellipsisStyle, ...clamp(2) }} fontSize={"0.88rem"}>
                {el.serviceHeadline}
            </Typography>
        ),
    },
    {
        key: "time",
        label: "Tid",
        props: {
            xs: 1.8,
            lg: 1.2,
            pr: 1,
        },
        value: (el: TBooking) => (
            <Typography fontSize={"0.88rem"} whiteSpace={"pre-wrap"}>{`${renderDate(
                el.startDateTime
            )}\n${renderTime(el.startDateTime, el.endDateTime)}`}</Typography>
        ),
    },

    {
        key: "type",
        label: "Type",
        props: {
            xs: 0.8,
            lg: 1.7,
            pr: { xs: 0, lg: 1 },
        },
        value: (el: TBooking) =>
            renderChannel(el, true, {
                sx: {
                    ...ellipsisStyle,
                },
            }) ?? (
                <Typography fontSize={"0.88rem"}>{renderGuestTypes(el, el.variantId)}</Typography>
            ),
    },
    {
        key: "count",
        label: "Antal",
        props: {
            xs: 0.8,
        },
        value: (el: TBooking) =>
            Object.entries(el.items ?? {}).reduce(
                (acc, [id, count]) =>
                    acc + (id.startsWith("variant") ? parseInt(String(count)) : 0),
                0
            ) || 1,
        valueProps: {
            fontWeight: 600,
        },
    },
    {
        key: "status",
        label: "Status",
        props: {
            xs: true,
        },
        value: (el: TBooking) => (
            <span>
                <StatusChip
                    status={
                        ((el.status === "active" && el.transaction != null) || el.offerId) &&
                        el.status !== "cancelled" &&
                        el.status !== "moved"
                            ? "paid"
                            : el.status
                    }
                />
            </span>
        ),
    },
];

export type BookingsListProps = BoxProps & {
    showService?: boolean;
    showTitle?: boolean;
    bookings?: TBooking[];
    openModalInline?: boolean;
    type?: "session" | "event";
};

export const BookingsListWithData = (props: BookingsListProps) => {
    const {
        bookings: { data: bookings, isLoading },
    } = useBookings();

    return <BookingsList {...props} bookings={isLoading ? undefined : bookings} />;
};

const options = [
    {
        key: "reminders",
        label: "Påmindelser",
        icon: AccessAlarmOutlined,
        props: {
            color: "warning",
        },
    },
];

export const BookingsList = ({
    bookings: _bookings,
    type = "event",
    showService = false,
    showTitle = true,
    openModalInline = false,
    ...props
}: BookingsListProps) => {
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState<null | string>(null);
    const [filter, setFilter] = useState<"all" | "future" | "past" | string>("all");

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const columns = _columns
        .filter((el) => (type === "session" ? el.key !== "count" : el.key !== "time"))
        .filter((el) => (showService ? true : el.key !== "service"));

    const filteredBookings = useMemo(() => {
        const b = _bookings?.filter((el) => {
            if (el.type === "blocker") {
                return false;
            }
            switch (filter) {
                case "all":
                    return true;
                case "future":
                    return new Date(el.startDateTime!) > new Date();
                case "past":
                    return new Date(el.startDateTime!) < new Date();
            }
            return true;
        });
        if (searchValue) {
            return b?.filter(({ customer, serviceHeadline }) => {
                const { name, email, phone } = customer ?? {};
                return [name, email, phone, serviceHeadline].some((el) =>
                    el?.toLowerCase().includes(searchValue.toLowerCase())
                );
            });
        }
        return b;
    }, [_bookings, searchValue, filter]);

    const { t } = useTranslate("utils.tables.header");

    const handleClick = (bookingId: string) => () => {
        if (openModalInline) {
            NiceModal.show(CustomerDialog, { bookingId, disableRedirectOnClose: true });
        } else {
            navigate(`/bookings/${bookingId}`);
        }
    };

    const handleSelectOption = async (key: (typeof options)[number]["key"], index?: number) => {
        if (index === undefined) {
            return;
        }
        const { id, status } = filteredBookings?.[index] ?? {};
        if (key === "reminders" && id && status !== "cancelled") {
            NiceModal.show(ReminderDialog, {
                subjectId: filteredBookings?.[index].id,
                subject: "booking",
            });
        }
    };

    const { handleClickOptions, render: renderOptionsDialog } = useListItemOptions(
        options,
        handleSelectOption
    );

    return (
        <Box {...props}>
            <ListHeader
                title={showTitle && !showService ? "Bookings" : null}
                filterOptions={[
                    { key: "all", label: "Alle" },
                    { key: "future", label: "Kommende" },
                    { key: "past", label: "Afsluttet" },
                ]}
                searchPlaceholder={"Søg i bookings"}
                onSearch={setSearchValue}
                onChangeFilter={setFilter}
                defaultSelectedFilter={"all"}
                mb={2}
            />
            {renderOptionsDialog()}
            <Grid container p={"12px 20px"} mb={1}>
                {columns.map((el) => {
                    if (el.mobile === false && isMobile) {
                        return null;
                    }
                    const { xs, ...props } = el.props;
                    return (
                        <Grid
                            fontSize={"0.75em"}
                            fontWeight={600}
                            textTransform={"uppercase"}
                            key={el.key}
                            item
                            {...props}
                            xs={xs}
                            component={Typography}
                        >
                            {el.key !== "none" && t(el.key)}
                        </Grid>
                    );
                })}
            </Grid>

            <Stack spacing={0}>
                {filteredBookings?.length === 0 && (
                    <BookingListEmptyScreen
                        mt={6}
                        title={t("emptyState.title", "bookings")}
                        description={t("emptyState.description", "bookings")}
                    />
                )}

                {filteredBookings?.map((booking, i) => (
                    <Box p={"4px 8px"}>
                        <Grid
                            container
                            component={Card}
                            p={1.5}
                            key={booking.id}
                            alignItems={"center"}
                            sx={{
                                cursor: "pointer",
                                "&:hover": { backgroundColor: "rgb(252,252,252)" },
                            }}
                            onClick={handleClick(booking.id)}
                        >
                            {columns.map((el) => {
                                if (el.mobile === false && isMobile) {
                                    return null;
                                }
                                const { xs, ...props } = el.props;
                                return (
                                    <Grid
                                        pr={0}
                                        whiteSpace={"pre-wrap"}
                                        key={`${booking.id}-${el.key}`}
                                        item
                                        {...props}
                                        {...el.valueProps}
                                        xs={xs}
                                    >
                                        {el.value?.(booking)}
                                    </Grid>
                                );
                            })}
                            <Grid item flexGrow={1} textAlign={"right"} position={"relative"}>
                                {booking.hasReminders && (
                                    <Tooltip title={"Har aktive påmindelser."}>
                                        <Box
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                handleSelectOption("reminders", i);
                                            }}
                                            component={ButtonBase}
                                            disableRipple
                                            sx={{
                                                position: "absolute",
                                                right: 32,
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <AccessAlarmOutlined
                                                color={"warning"}
                                                fontSize={"small"}
                                            />
                                        </Box>
                                    </Tooltip>
                                )}
                                <IconButton onClick={handleClickOptions(i)} size={"small"}>
                                    <MoreVert />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                )) ?? <ListSkeleton />}
            </Stack>
        </Box>
    );
};
