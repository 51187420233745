import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { serviceSchema } from "../Pages/SyiPage/config";
import { useOnBoarding } from "./useOnBoarding";
import { type TStatus } from "../Components/StatusChip";
import { useCallback, useMemo } from "react";

export type Localized = {
    [language: string]: string;
};

export type Variant = {
    id: string;
    addons?: Variant[];
    price: number;
    name?: Localized;
    vatId?: string;
    explanation?: string;
    slotDuration?: number;
};

interface Base {
    created: string;
    updated: string;
}

type WithSelectedOptionAndValue<T extends any = any> = {
    selectedOptionKey: string;
    value: T;
    [k: string]: any;
};

export interface IService<T extends WithSelectedOptionAndValue | any = WithSelectedOptionAndValue>
    extends Base {
    id?: string;
    status: TStatus;
    companyId?: string;
    type: string;
    languages: string[];
    validated?: boolean;
    channels: string[];
    headline: Localized;
    deactivateBeforeDays?: T;
    description: Localized;
    whatsIncluded: Localized;
    guestRequirements: Localized;
    meetingLink?: string;
    guestInfo: Localized;
    customDescriptions: { key: string; name: string; text: string }[];
    tags: { key: string }[];
    pictures: { key: string; url: string }[];
    assignees: string[];
    locations: T;
    variants?: Variant[];
    cancellationFee: {
        rate: number;
        percentage: boolean;
    };
    customData?: { value: string }[];
}

export const useService = (id?: string) => {
    const queryClient = useQueryClient();

    const { updateStep } = useOnBoarding();

    const ServiceQueryKey = ["experience", id];
    const ServicesQueryKey = "experiences";

    const service = useQuery<IService>(
        ServiceQueryKey,
        async () => {
            await queryClient.cancelQueries(ServiceQueryKey);
            const services =
                queryClient.getQueryData<IService[]>(ServicesQueryKey) ?? (await api.getServices());
            return services?.find((el) => el.id === id) ?? ({} as IService);
        },
        {
            enabled: !!id,
        }
    );

    const services = useQuery<IService[]>(
        ServicesQueryKey,
        async () => {
            await queryClient.cancelQueries(ServiceQueryKey);
            return api.getServices();
        },
        {
            enabled: true,
            retry: false,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            onSuccess: (data) => {
                data?.forEach((el) => {
                    queryClient.setQueryData(["service", el.id], () => ({ ...el }));
                });
            },
        }
    );

    const updateService = useMutation((data: IService) => api.updateService(id!, data), {
        onMutate: async (data) => {
            await queryClient.cancelQueries(ServiceQueryKey);

            const previous = queryClient.getQueryData<IService>(ServiceQueryKey);

            queryClient.setQueryData<IService[]>(ServicesQueryKey, (prev) => {
                const mapped =
                    prev?.map((el) => {
                        return el.id === data.id ? { ...data } : el;
                    }) ?? [];
                return [
                    ...mapped,
                    ...(data.id
                        ? []
                        : [
                              {
                                  ...data,
                                  id,
                              },
                          ]),
                ];
            });

            queryClient.setQueryData<IService>(ServiceQueryKey, (prev) => {
                return { ...data };
            });

            return { previous };
        },
        onError: (err, variables, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<IService>(ServiceQueryKey, context.previous);
            }
        },
        onSuccess: async (data, variables) => {
            await updateStep({ ...variables, id }, serviceSchema, "service", "create");
        },
        onSettled: async (data, variables) => {
            await Promise.all([
                queryClient.invalidateQueries(ServicesQueryKey),
                queryClient.invalidateQueries(["events", "bookings"]),
                queryClient.invalidateQueries(["session", { type: "service", serviceId: id }]),
            ]);
        },
    });

    const deleteService = useMutation((id: string) => api.deleteService(id), {
        onMutate: async (data) => {
            await queryClient.cancelQueries(ServiceQueryKey);

            queryClient.setQueryData<IService[]>(ServicesQueryKey, (prev) => {
                return prev?.filter((el) => el.id !== id) ?? [];
            });
        },
        onSuccess: () => {
            service.remove();
            return services.refetch();
        },
        onSettled: () => {
            return Promise.all([
                queryClient.invalidateQueries(ServicesQueryKey),
                queryClient.invalidateQueries(["events", "bookings"]),
            ]);
        },
    });

    const getService = useCallback(
        (serviceId: string): IService | undefined => {
            return services.data?.find((el) => el.id === serviceId);
        },
        [services.data]
    );

    const activeServices = useMemo(() => {
        return services.data?.filter((el) => el.status === "active");
    }, [services.data]);

    const getVariant = useCallback(
        (variantId: string, serviceId: string): Variant | undefined => {
            return services.data
                ?.find((el) => el.id === serviceId)
                ?.variants?.find((v) => variantId.endsWith(v.id));
        },
        [services.data]
    );

    return {
        service,
        services,
        updateService,
        deleteService,
        getService,
        getVariant,
        activeServices,
    };
};
