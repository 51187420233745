import {
    Box,
    BoxProps,
    Button,
    Divider,
    IconButton,
    Link,
    Popover,
    PopoverProps,
    Stack,
    SvgIcon,
    Typography,
} from "@mui/material";
import { isSameDay, renderDateTime, renderTime } from "@hiddengemgroup/utils-date";
import { LocationIcon } from "../Icons";
import {
    DeleteOutlined,
    LaunchOutlined,
    LinkOutlined,
    NotesOutlined,
    PeopleOutlined,
    RecordVoiceOverOutlined,
} from "@mui/icons-material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { useCallback, useState } from "react";
import { useLocalizer } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { useTranslate } from "../Hooks/useTranslate";
import { SimpleSkeleton } from "../Components/SimpleSkeleton/SimpleSkeleton";
import { useProfile } from "../Hooks/useProfile";
import { useNavigate } from "react-router-dom";
import { CalendarEvent, useCalendarEvents } from "../Hooks/useCalendarEvents";
import { TEventColor } from "../Hooks/useEvent";
import { useBookings } from "../Hooks/useBookings";
import { useWriteParticipants } from "../Hooks/useWriteParticipants";
import { RichText } from "@spiritworld-dk/ui-core";
import { CustomerDialog } from "./CustomerDialog/CustomerDialog";

export const IconRow = ({ Icon, children, ...props }: BoxProps & { Icon: typeof SvgIcon }) => {
    return (
        <Box display={"flex"} alignItems={"center"} {...props}>
            <Icon sx={{ mr: 2 }} htmlColor={"#636366"} />
            {children}
        </Box>
    );
};

type CalendarPreviewProps = {
    anchorEl: PopoverProps["anchorEl"];
    eventId: string;
    colors: TEventColor;
};

export const CalendarPreview = NiceModal.create<CalendarPreviewProps>(
    ({ anchorEl, colors, eventId }) => {
        const modal = useModal();

        const {
            event: { data: event },
        } = useCalendarEvents(eventId);

        const handleClose = () => {
            modal.hide().then(() => modal.remove());
        };

        return (
            <Popover
                open={modal.visible}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                slotProps={{
                    paper: {
                        sx: {
                            p: 0,
                            borderRadius: "16px",
                            border: "1px solid #E0E0E0",
                            width: 540,
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        ...(colors.border
                            ? {
                                  ...colors.border,
                              }
                            : {
                                  borderLeft: `6px solid ${colors.borderLeftColor}`,
                              }),
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                    }}
                />
                {event ? (
                    <PreviewContent {...event} onClose={handleClose} />
                ) : (
                    <SimpleSkeleton p={4} />
                )}
            </Popover>
        );
    }
);

const COLLAPSED_DESCRIPTION_LENGTH = 120;
const PreviewContent = ({
    id,
    slots,
    type,
    title,
    startDateTime,
    customer,
    allowDirectDelete,
    endDateTime,
    location,
    meetingLink,
    assignees,
    image,
    channels,
    description,
    onClose,
}: CalendarEvent & { onClose: () => void }) => {
    const { t } = useTranslate("calendar.popover");

    const [showFullDescription, setShowFullDescription] = useState(false);

    const { me } = useProfile();

    const { deleteSessionBooking } = useBookings();

    const localize = useLocalizer();

    const navigate = useNavigate();

    const writeToParticipants = useWriteParticipants({
        eventId: id,
        headline: title,
        startDateTime,
        participantCount: slots?.booked ?? 0,
        userId: me.data?.id,
    });

    const handleWriteParticipants = () => {
        if (type !== "event") {
            if (customer?.email) {
                const a = document.createElement("a");
                a.href = `mailto:${customer?.email}`;
                a.click();
                a.remove();
            }
            return;
        }
        return writeToParticipants();
    };

    const handleGoToEvent = () => {
        if (type === "event") {
            navigate(`/event/${id}`);
        } else if (type === "booking") {
            NiceModal.show(CustomerDialog, { bookingId: id });
        } else {
            navigate(`/${type}s/${id}`);
        }
        onClose();
    };

    const handleReadMore = () => {
        setShowFullDescription(true);
    };

    const renderGuest = useCallback(() => {
        if (customer) {
            return Object.values(customer).join("\n");
        }
        if (slots) {
            return t("labels.guests", {
                guestCount: slots.booked,
            });
        }
        return undefined;
    }, [customer, slots]);

    const handleDelete = () => {
        deleteSessionBooking.mutate(id);
        onClose();
    };

    return (
        <>
            <Box p={4} pb={2} display={"flex"} alignItems={"center"}>
                <Box
                    component={"img"}
                    width={80}
                    height={80}
                    alt={title}
                    src={image}
                    borderRadius={2}
                    sx={{ objectFit: "cover" }}
                />
                <Box ml={2}>
                    <Typography variant={"h3"}>
                        {title} {type === "intro" ? t("labels.introSession") : ""}
                    </Typography>

                    <Stack direction={"row"} mt={1.5} spacing={4}>
                        <Typography textTransform={"capitalize"}>
                            {renderDateTime(
                                startDateTime,
                                isSameDay(startDateTime, endDateTime) ? undefined : endDateTime,
                                {
                                    standard: "ddmyyyy",
                                    sameDay: "ddmm",
                                }
                            )}
                        </Typography>
                        <Typography>{renderTime(startDateTime, endDateTime)}</Typography>
                    </Stack>
                </Box>
            </Box>
            <Divider />
            <Stack p={2} pl={4} pb={3} spacing={2}>
                {location && (
                    <IconRow Icon={LocationIcon}>
                        <Typography>{location}</Typography>
                    </IconRow>
                )}

                {slots && (
                    <IconRow Icon={PeopleOutlined}>
                        <Typography whiteSpace={"pre-wrap"}>{renderGuest()}</Typography>
                    </IconRow>
                )}

                {meetingLink && (
                    <IconRow Icon={LaunchOutlined}>
                        <Link href={meetingLink} target={"_blank"} underline={"hover"}>
                            {t("labels.goToMeetingLink")}
                        </Link>
                    </IconRow>
                )}

                {type === "event" && (
                    <IconRow Icon={NotesOutlined}>
                        <Box>
                            <RichText
                                value={
                                    showFullDescription
                                        ? localize(description)
                                        : `${localize(description)
                                              ?.substring(0, COLLAPSED_DESCRIPTION_LENGTH)
                                              .trim()}`
                                }
                            />
                            {!showFullDescription &&
                                localize(description)?.length > COLLAPSED_DESCRIPTION_LENGTH && (
                                    <Link href={"#"} onClick={handleReadMore}>
                                        {t("readmore", "utils.generic")}
                                    </Link>
                                )}
                        </Box>
                    </IconRow>
                )}

                <IconRow Icon={LinkOutlined}>
                    <Link sx={{ cursor: "pointer" }} underline={"hover"} onClick={handleGoToEvent}>
                        {t("labels." + type)}
                    </Link>
                </IconRow>

                <IconRow Icon={RecordVoiceOverOutlined}>
                    <Typography>
                        {t("labels.assignee")}:{" "}
                        {assignees
                            .map((assignee) =>
                                me.data?.email === assignee.email
                                    ? t("me", "utils.generic")
                                    : assignee.name
                            )
                            .join(", ")}
                    </Typography>
                </IconRow>
            </Stack>
            <Divider />
            <Stack
                direction={"row"}
                justifyContent={allowDirectDelete ? "space-between" : "flex-end"}
                p={2.5}
                pb={1}
                pt={1}
                spacing={1}
            >
                {allowDirectDelete && (
                    <IconButton onClick={handleDelete}>
                        <DeleteOutlined />
                    </IconButton>
                )}
                <Box display={"flex"} alignItems={"center"}>
                    <Button
                        size={"small"}
                        sx={{ pr: 2, pl: 2 }}
                        variant={"contained"}
                        disabled={type === "event" && !(slots && slots.booked > 0)}
                        onClick={() => handleWriteParticipants()}
                    >
                        {type === "event"
                            ? t("actions.writeToAllGuests")
                            : t("actions.writeToCustomer")}
                    </Button>
                </Box>
            </Stack>
        </>
    );
};
