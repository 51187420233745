import Fetch from './index'
import { Reminder } from '../Hooks/useNotifications'

export const getTemplates = async () => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.get<
        { groupKey: string; templateId: string; userEditable?: boolean; companyEditable: boolean }[]
    >(`${process.env.REACT_APP_API_NOTIFICATIONS_URL}templates/editable`)
}

export const getConsumerTemplates = async () => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.get<{ templateId: string }[]>(
        `${process.env.REACT_APP_API_NOTIFICATIONS_URL}templates/consumer`,
    )
}
export const getRemindersBySubject = async (
    subject: 'booking' | 'lead' | 'offer',
    subjectId: string,
) => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.get<Reminder[]>(
        `${process.env.REACT_APP_API_NOTIFICATIONS_URL}reminders/${subject}/${subjectId}`,
    )
}

export const getAllRemindersBySubject = async (subject: 'booking' | 'lead' | 'offer') => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.get<Reminder[]>(
        `${process.env.REACT_APP_API_NOTIFICATIONS_URL}reminders/${subject}`,
    )
}

export const updateReminder = async (
    payload: Omit<Reminder, 'scheduledAt'> & { schedule: string },
) => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_NOTIFICATIONS_URL}reminder`, payload)
}

export const removeReminders = async (subject: 'booking' | 'lead' | 'offer', subjectId: string) => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.delete(
        `${process.env.REACT_APP_API_NOTIFICATIONS_URL}reminders/${subject}/${subjectId}`,
    )
}

export const getSettings = async () => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    return Fetch.get<{ user: { [k: string]: boolean }; company?: { [k: string]: boolean } }>(
        `${process.env.REACT_APP_API_NOTIFICATIONS_URL}settings`,
    )
}

export const updateSettings = async (
    payload: { [k: string]: boolean } | undefined,
    type: 'company' | 'user',
) => {
    if (typeof process.env.REACT_APP_API_NOTIFICATIONS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_NOTIFICATIONS_URL is not defined')
    }

    if (!payload) {
        throw new Error('Missing payload')
    }

    return Fetch.put(`${process.env.REACT_APP_API_NOTIFICATIONS_URL}settings/${type}`, payload)
}
