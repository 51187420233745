import Fetch from './index'

export type Product = {
    id: string
    name: string
    seatProps?: {
        type: string
        min: number
        max: number
        customName?: string
        count: number
    }
    explanation?: string
    price: {
        amount: number
        currency: string
    }
    parent: string
    addons?: {
        id: string
        name: string
        explanation?: string
        price: {
            amount: number
            currency: string
        }
        parent: string
        offer: {
            token: string
        }
    }[]
    offer: {
        token: string
    }
}

export type TDate = {
    date: string
    times: {
        time: string
        endTime: string
        eventId: string
        languages: string[]
        startDateTime: string
        location: string
        availableSeats: {
            total: number
            [variantId: string]: number
        }
    }[]
}

export const getUpcoming = async (experienceId: string) => {
    if (typeof process.env.REACT_APP_API_VIP_BFF_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_VIP_BFF_URL is not defined')
    }

    return Fetch.get<{ availability: TDate[]; products: Product[] }>(
        `${process.env.REACT_APP_API_VIP_BFF_URL}${experienceId}/upcoming`,
    )
}
