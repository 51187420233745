import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { TBooking } from "./useBookings";

type TNotification = {
    key: string;
    items: {
        key: string;
        userEnabled: boolean;
        companyEnabled?: boolean;
    }[];
};

export type Reminder = {
    id: string;
    subject: "offer" | "lead" | "booking";
    subjectId: string;
    channels: ("sms" | "email")[];
    scheduledAt: string;
    templateId: string;
};

export const useReminders = (subject: "lead" | "booking" | "offer", subjectId?: string) => {
    const RemindersQueryKey = ["reminders", subject, subjectId];
    const AllRemindersQueryKey = ["reminders", subject];

    const queryClient = useQueryClient();

    const reminders = useQuery(
        RemindersQueryKey,
        async () => {
            await queryClient.cancelQueries(RemindersQueryKey);
            return api.getRemindersBySubject(subject, subjectId as string);
        },
        {
            enabled: Boolean(subject && subjectId),
        }
    );

    const allReminders = useQuery(
        AllRemindersQueryKey,
        async () => {
            await queryClient.cancelQueries(AllRemindersQueryKey);
            return api.getAllRemindersBySubject(subject);
        },
        {
            enabled: Boolean(subject && !subjectId),
        }
    );

    const updateReminders = useMutation(
        async ({
            reminders,
            subject,
            subjectId,
        }: {
            subject: "lead" | "offer" | "booking";
            subjectId: string;
            reminders: Reminder[];
        }) => {
            await api.removeReminders(subject, subjectId);
            return Promise.all(
                reminders.map(({ scheduledAt, ...reminder }) =>
                    api.updateReminder({
                        ...reminder,
                        schedule: scheduledAt,
                    })
                )
            );
        },
        {
            onMutate: async ({ reminders, subjectId }) => {
                await queryClient.cancelQueries(RemindersQueryKey);
                await queryClient.cancelQueries(["bookings"]);

                const previous = queryClient.getQueryData(RemindersQueryKey);

                queryClient.setQueryData<TBooking[]>(["bookings"], (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === subjectId
                                ? {
                                      ...el,
                                      hasReminders:
                                          reminders.some(
                                              (r) => r.subjectId === el.id && el.id === subjectId
                                          ) ?? false,
                                  }
                                : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<Reminder[]>(RemindersQueryKey, () => [...reminders]);

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData(RemindersQueryKey, context.previous);
                }
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(AllRemindersQueryKey);
                await queryClient.invalidateQueries(RemindersQueryKey);
            },
        }
    );

    return { reminders, updateReminders, allReminders };
};
export const useNotifications = () => {
    const queryClient = useQueryClient();

    const NotificationsQueryKey = ["notifications"];
    const ConsumerTemplatesQueryKey = ["templates", "consumer"];

    const notifications = useQuery(
        NotificationsQueryKey,
        async () => {
            await queryClient.cancelQueries(NotificationsQueryKey);
            const templates = await api.getTemplates();
            const settings = await api.getSettings();
            const grouped = templates.reduce<{
                [k: string]: {
                    templateId: string;
                    userEditable?: boolean;
                    companyEditable: boolean;
                }[];
            }>((acc, { groupKey, templateId, userEditable, companyEditable }) => {
                return {
                    ...acc,
                    [groupKey]: [
                        ...(acc[groupKey] ?? []),
                        {
                            templateId,
                            ...(userEditable !== undefined && { userEditable }),
                            companyEditable,
                        },
                    ],
                };
            }, {});
            return Object.entries(grouped)
                .map(([gKey, keys]) => {
                    return {
                        key: gKey,
                        items: keys.map(({ templateId: k, userEditable, companyEditable }) => ({
                            key: k,
                            ...(userEditable !== undefined && {
                                userEnabled: settings.user[k] ?? true,
                            }),
                            ...(companyEditable !== undefined &&
                                settings.company && {
                                    companyEnabled: settings.company?.[k] ?? false,
                                }),
                        })),
                    };
                })
                .filter(
                    (f) =>
                        !f.items.every(
                            (e) => e.userEnabled === undefined && e.companyEnabled === undefined
                        )
                );
        },
        {
            enabled: true,
        }
    );

    const consumerTemplates = useQuery(
        ConsumerTemplatesQueryKey,
        async () => {
            await queryClient.cancelQueries(ConsumerTemplatesQueryKey);
            return api.getConsumerTemplates();
        },
        {
            enabled: true,
        }
    );

    const toggleNotification = useMutation(
        ({
            key,
            checked,
            type,
        }: {
            groupKey: string;
            key: string;
            type: "company" | "user";
            checked: boolean;
        }) => {
            const settings = queryClient.getQueryData<TNotification[]>(NotificationsQueryKey);
            const updated = settings
                ?.reduce<{ key: string; enabled: boolean }[]>(
                    (templates, { items }) => [
                        ...templates,
                        ...items.map(({ key, userEnabled, companyEnabled }) => ({
                            key,
                            enabled: type === "company" ? companyEnabled ?? false : userEnabled,
                        })),
                    ],
                    []
                )
                ?.reduce<{ [k: string]: boolean }>((obj, el) => {
                    return {
                        ...obj,
                        [el.key]: el.key === key ? checked : el.enabled,
                    };
                }, {});
            return api.updateSettings(updated, type);
        },
        {
            onMutate: async ({ groupKey, key, type, checked }) => {
                await queryClient.cancelQueries(NotificationsQueryKey);

                const previous = queryClient.getQueryData(NotificationsQueryKey);

                queryClient.setQueryData<TNotification[]>(NotificationsQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.key === groupKey
                                ? {
                                      ...el,
                                      items: el.items.map((it) => {
                                          return it.key === key
                                              ? {
                                                    ...it,
                                                    ...(type === "company" && {
                                                        companyEnabled: checked,
                                                    }),
                                                    ...(type === "user" && {
                                                        userEnabled: checked,
                                                    }),
                                                }
                                              : it;
                                      }),
                                  }
                                : el;
                        }) ?? []
                    );
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData(NotificationsQueryKey, context.previous);
                }
            },
            onSettled: async () => {
                queryClient.invalidateQueries(NotificationsQueryKey);
            },
        }
    );

    return {
        notifications,
        toggleNotification,
        consumerTemplates,
    };
};
