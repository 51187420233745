import { Fetch, type TokenResponse } from '@hiddengemgroup/utils-frontend-fetch'
import { AccountFetch } from './index'

const fetcher = new Fetch()

export const authenticateGoogle = async (code: string) => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.post<TokenResponse>(`${url}socials/google/authenticate`, {
        code,
    })
}

export const reclaim = (org: string) => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    if (AccountFetch.isAuthorized) {
        return AccountFetch.post<TokenResponse>(`${url}oauth/token`, {
            org,
            grant: 'reclaim',
        })
    }
}

export const signIn = async (username: string, password: string) => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.post<TokenResponse>(`${url}oauth/token`, {
        username,
        password,
        grant: 'password',
    })
}

export const refreshToken = async (refreshToken: string) => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.post<TokenResponse>(`${url}oauth/token`, {
        refresh_token: refreshToken,
        grant: 'refresh_token',
    })
}

export const signOut = async () => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.post(`${url}`)
}

export const createAccount = async (email: string, password: string, userId: string) => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.put<TokenResponse>(`${url}users/${userId}`, {
        password,
        claims: { email },
    })
}

export const resetPassword = async (email: string, language: string = 'da') => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.post(`${url}reset`, {
        username: email,
        language,
    })
}

export const updateResatPassword = async (
    email: string,
    password: string,
    id: string,
    token: string,
) => {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL

    if (typeof url === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return fetcher.put<TokenResponse>(
        `${url}users/${id}`,
        {
            password,
            claims: { email },
        },
        {
            headers: {
                authorization: `Bearer ${token}`,
            },
        },
    )
}
