import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useService } from "./useService";
import { getColor, getLocation, Localized, TEventColor } from "./useEvent";
import { useProfile } from "./useProfile";
import { useBookings } from "./useBookings";
import { useCallback } from "react";

export type OpeningHour = {
    key: "MO" | "TU" | "WE" | "TH" | "FR" | "SA" | "SU";
    fromTime: string;
    toTime: string;
};

export type Break = {
    name: string;
    fromTime: string;
    toTime: string;
};

export type Booking = {
    startDateTime: string;
    endDateTime: string;
    id: string;
    type?: "intro" | "variant";
};

export type Session = {
    id: string;
    openingHours: OpeningHour[];
    slotPaddingMins: number;
    duration: number;
    serviceId: string;
    companyId: string;
    bookings?: Booking[];
    breaks?: Break[];
    introSession?: {
        openingHours: OpeningHour[];
        duration: number;
        channels: "byPhone" | "videoCall" | "inPerson"[];
    };
};

export type SessionWithBookings = Session & {
    title: string;
    location: string;
    description: Localized;
    guide: {
        name: string;
        email: string;
    };
    colors: TEventColor;
};

type ExternalBooking = {
    startDateTime: string;
    endDateTime: string;
    id: string;
    type: "external";
    companyId: string;
    userId: string;
    title: string;
    deepLink: string;
    metadata: {
        itemId: string;
        itemType: "event";
        resourceId: string;
        resourceType: "calendar";
        provider: "outlook";
    };
};

export const useSession = (id?: string, serviceId?: string) => {
    const queryClient = useQueryClient();

    const SessionQueryKey = ["session", { id }];
    const SessionsQueryKey = ["sessions"];
    const SessionForServiceQueryKey = ["session", { type: "service", serviceId }];
    const SessionsWithBookingsQueryKey = ["sessions", { type: "bookings" }];
    const ExternalBookingsQueryKey = ["sessions", { type: "external" }];

    const { company } = useProfile();
    const { services } = useService();
    const {
        bookings: { data: bookings },
    } = useBookings();

    const sessions = useQuery<Session[]>(
        SessionsQueryKey,
        async () => {
            await queryClient.cancelQueries(SessionsQueryKey);
            return await api.getSessions<Session[]>();
        },
        {
            enabled: true,
        }
    );

    const externalBookings = useQuery<ExternalBooking[]>(ExternalBookingsQueryKey, () =>
        api.getExternalBookingsByUserId<ExternalBooking[]>()
    );

    const sessionForService = useQuery<Session | undefined>(
        SessionForServiceQueryKey,
        async () => {
            await queryClient.cancelQueries(SessionForServiceQueryKey);
            try {
                return await api.getSessionForService<Session>(serviceId);
            } catch {
                return undefined;
            }
        },
        {
            enabled: Boolean(serviceId),
        }
    );

    const session = useQuery<Session>(
        SessionQueryKey,
        async () => {
            await queryClient.cancelQueries(SessionQueryKey);
            return await api.getSession<Session>(id as string);
        },
        {
            enabled: Boolean(id),
        }
    );

    const updateSession = useMutation(
        ({ ...data }: Session) => {
            return api.updateSession(data.id ?? id, {
                ...data,
            });
        },
        {
            onMutate: async (data) => {
                const queryKey = ["session", { id: data.id ?? id }];
                const forServiceQueryKey = [
                    "session",
                    { type: "service", serviceId: data.serviceId ?? serviceId },
                ];

                await queryClient.cancelQueries(queryKey);
                await queryClient.cancelQueries(forServiceQueryKey);

                const previousSession = queryClient.getQueryData<Session>(queryKey);

                const previousSessionForService =
                    queryClient.getQueryData<Session>(forServiceQueryKey);

                queryClient.setQueryData<Session>(queryKey, (prev) => {
                    return { ...(prev ?? {}), ...data };
                });

                queryClient.setQueryData<Session>(forServiceQueryKey, (prev) => {
                    return {
                        ...prev,
                        ...data,
                    };
                });

                return { previousSession, previousSessionForService, serviceId: data.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previousSession) {
                    queryClient.setQueryData<Session>(SessionQueryKey, context.previousSession);
                }
                if (context?.previousSessionForService) {
                    queryClient.setQueryData<Session[]>(
                        SessionForServiceQueryKey,
                        context.previousSessionForService
                    );
                }
            },
            onSettled: async (data, err, variables, context) => {
                await queryClient.invalidateQueries(SessionsQueryKey);
                await queryClient.invalidateQueries(["session", { id: variables.id }]);
                await queryClient.invalidateQueries([
                    "session",
                    { type: "service", serviceId: variables.serviceId ?? serviceId },
                ]);
            },
        }
    );

    const deleteSession = useMutation((id: string) => api.deleteSession(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(SessionsQueryKey);

            const session = queryClient.getQueryData<Session>(["session", { id }]);

            const serviceId = session?.serviceId;

            queryClient.setQueryData<Session[]>(
                ["session", { type: "service", serviceId }],
                (prev) => {
                    return prev?.filter((el) => el.id !== id) ?? [];
                }
            );

            return { serviceId };
        },
        onSettled: async (data, err, variables, context) => {
            await queryClient.invalidateQueries(SessionsQueryKey);
        },
    });

    const sessionBookings = useQuery<SessionWithBookings[]>(
        SessionsWithBookingsQueryKey,
        async () => {
            await queryClient.cancelQueries(SessionsWithBookingsQueryKey);
            const serviceIds =
                services.data
                    ?.filter((f) => f.status === "active" && f.type === "session")
                    ?.map((el) => el.id) ?? [];
            const uniqueServices = Array.from(new Set(serviceIds));
            const mapped =
                bookings
                    ?.filter((el) => serviceIds.includes(el.serviceId))
                    ?.map((el) => {
                        const service = services.data?.find((ser) => ser.id === el.serviceId);
                        return {
                            ...el,
                            type: el?.variantId?.startsWith("intro") ? "intro" : "session",
                            serviceId: service?.id ?? "",
                            slots: {
                                total: 1,
                                booked: 1,
                            },
                            title: service?.headline?.da ?? "",
                            location: getLocation(service?.locations as any, company.data.location),
                            description: service?.description,
                        };
                    }) ?? [];
            return mapped as unknown as SessionWithBookings[];
        },
        {
            enabled: Boolean(services.data && company.data && bookings),
        }
    );

    const getSessionDuration = useCallback(
        (serviceId?: string) => {
            if (services.data && sessions.data) {
                const session = sessions.data.find((el) => el.serviceId === serviceId);
                if (session) {
                    return session.duration;
                }
            }
        },
        [services, sessions]
    );

    const getSessionDetails = useCallback(
        <T extends object>(serviceId?: string, keys?: string[]) => {
            if (services.data && sessions.data) {
                const session = sessions.data.find((el) => el.serviceId === serviceId);
                if (session) {
                    return Object.fromEntries(
                        Object.entries(session).filter(([k]) => keys?.includes(k) || !keys)
                    ) as T;
                }
            }
        },
        [services, sessions]
    );

    const getSessionByServiceId = useCallback(
        (serviceId?: string) => {
            if (sessions.data) {
                return sessions.data.find((el) => el.serviceId === serviceId);
            }
        },
        [sessions]
    );

    return {
        session,
        sessions,
        sessionForService,
        updateSession,
        deleteSession,
        sessionBookings,
        getSessionDuration,
        getSessionDetails,
        getSessionByServiceId,
        externalBookings,
    };
};
