import { useController, UseControllerProps, useFormContext, useWatch } from "react-hook-form";
import { Box, BoxProps, TextField, TextFieldProps, Typography } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { Localized } from "../../Hooks/useService";
import { ErrorLabel } from "../../Pages/SyiPage/ErrorLabel";
import { LanguageTabs } from "../LanguageTabs/CustomTabs";
import { Tip } from "../Tip/Tip";
import { getTranslation } from "../../Api";
import { useTranslation } from "react-i18next";
import { Editor } from "../Editor/Editor";

interface ITranslatableGroupProps {
    title?: string | ReactNode;
    propKey: string;
    input: {
        type: "input" | "editor";
        placeholder?: string;
        helperText?: string;
        props?: TextFieldProps;
    };
    tip?: string;
    langs: {
        key: string;
        label: string;
    }[];
    onSuccess?: () => void;
    onUpdate?: (val: string, langKey: string) => void;
    rules?: UseControllerProps["rules"];
    error?: string;
    renderControls?: {
        position: "above" | "below";
        render: (lang: string, onUpdate: (value: string) => void) => ReactNode;
    }[];
    clearError?: (key: string) => void;
    disableTranslation?: boolean;
}

const getValue = (value: string | Localized, langKey: keyof Localized) => {
    return typeof value === "string" ? value : value?.[langKey] ?? "";
};

const getLocalized = (value: string | Localized | undefined, langKey: keyof Localized) => {
    return typeof value === "string" ? { [langKey]: value } : value ?? {};
};

export const TranslatableGroup = ({
    title,
    error,
    clearError,
    onUpdate,
    onSuccess,
    disableTranslation = false,
    tip,
    children,
    rules,
    propKey,
    input,
    langs,
    renderControls,
    ...props
}: Omit<BoxProps, "title"> & ITranslatableGroupProps) => {
    const { i18n } = useTranslation();

    const [selectedLang, setSelectedLang] = useState(0);
    const [translating, setTranslating] = useState(false);

    const { field } = useController({ name: propKey });
    const { setValue } = useFormContext();

    const langKey = langs?.[selectedLang]?.["key"] as string;

    const handleSelectLanguage = async (evt: any, _langKey: any) => {
        const newLangKey = langs?.[_langKey]?.["key"] as string;
        setSelectedLang(_langKey);
        const value = field.value?.[newLangKey];

        if (value?.trim()) {
            setValue(
                propKey,
                { ...(field?.value ?? {}), [newLangKey]: value },
                { shouldDirty: false }
            );
            onSuccess?.();
        } else {
            if (disableTranslation) {
                return;
            }
            try {
                setTranslating(true);
                const sourceLang = field.value?.[i18n.language] ? i18n.language : langs[0].key;
                const textToTranslate = field.value?.[i18n.language] || field.value?.[langs[0].key];
                if (!textToTranslate) throw Error("no text to translate");
                const { text } = await getTranslation({
                    text: textToTranslate,
                    targetLanguageCode: newLangKey,
                    sourceLanguageCode: sourceLang,
                });
                field.onChange({ ...(field?.value ?? {}), [newLangKey]: text });
                onUpdate?.(text, newLangKey);
                onSuccess?.();
            } catch (err) {
                console.log(err);
                setValue(
                    propKey,
                    { ...(field?.value ?? {}), [newLangKey]: "" },
                    { shouldDirty: false }
                );
            } finally {
                setTranslating(false);
            }
        }
    };

    const handleUpdate = (value: string) => {
        setValue(propKey, { ...(field?.value ?? {}), [langKey]: value }, { shouldDirty: true });
    };

    return (
        <Box mt={langs.length > 1 ? -4 : 0} {...props}>
            <Box
                display={"flex"}
                position={"relative"}
                justifyContent={"space-between"}
                mb={tip ? 2 : title ? 1.5 : langs?.length > 1 ? 1 : 0}
            >
                {title ? (
                    <Typography variant={"h5"} mt={tip ? 0 : 1.5}>
                        {title}
                        <ErrorLabel mt={1} label={error} />
                    </Typography>
                ) : (
                    <div />
                )}
                {langs?.length > 1 && (
                    <LanguageTabs
                        sx={{ position: title ? "absolute" : "relative", top: 0, right: 0 }}
                        items={langs}
                        onChange={handleSelectLanguage}
                        selected={selectedLang}
                    />
                )}
            </Box>
            {tip && <Tip mb={2} label={tip} />}
            {renderControls
                ?.filter((c) => c.position === "above")
                .map((c) => {
                    return c.render(langKey, handleUpdate);
                })}
            <Box>
                {input.type === "input" ? (
                    <TextField
                        fullWidth
                        key={`input-${langKey}`}
                        placeholder={translating ? "Oversætter..." : input.placeholder}
                        value={getValue(field.value, langKey)}
                        onChange={(evt) => {
                            field.onChange({
                                ...getLocalized(field.value, langKey),
                                [langKey]: evt.target.value,
                            });
                            onUpdate?.(evt.target.value, langKey);
                            clearError?.(`${propKey}`);
                        }}
                        {...input.props}
                        label={""}
                    />
                ) : (
                    <Editor
                        defaultValue={getValue(field.value, langKey)}
                        onChange={(val) => {
                            if (!val && !field.value?.[langKey]) {
                                return;
                            }
                            onUpdate?.(val, langKey);
                            field.onChange({
                                ...getLocalized(field.value, langKey),
                                [langKey]: val,
                            });
                            clearError?.(`${propKey}`);
                        }}
                        placeholder={translating ? "Oversætter..." : input.placeholder}
                    />
                )}
            </Box>
            {renderControls
                ?.filter((c) => c.position === "below")
                .map((c) => c.render(langKey, handleUpdate))}
        </Box>
    );
};
