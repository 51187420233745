import React, { ReactNode } from "react";
import {
    Box,
    BoxProps,
    ButtonBase,
    ButtonBaseProps,
    Card,
    Grid,
    Skeleton,
    SkeletonProps,
    Stack,
    Typography,
    TypographyProps,
} from "@mui/material";
import { StatusChip, type TStatus } from "../StatusChip";

export interface ICustomCardProps {
    title?: string;
    status?: TStatus;
    fullWidth?: boolean;
    onClickStatus?: (currentStatus: TStatus) => void;
    image: ReactNode;
    content: ReactNode;
}

export const LeftLabel = ({ children, ...props }: TypographyProps) => (
    <Typography fontSize={"0.8em"} color={"#8E8E93"} minWidth={104} {...props}>
        {children}
    </Typography>
);

export const Row = ({
    label,
    value,
    skeletonProps,
    labelProps,
    valueProps,
}: {
    skeletonProps?: SkeletonProps;
    labelProps?: TypographyProps;
    valueProps?: TypographyProps;
    label?: string | null;
    value?: string | null | ReactNode;
} & BoxProps) => {
    return (
        <Box display={"flex"} alignItems={"center"}>
            <LeftLabel {...labelProps}>{label}</LeftLabel>
            {value === undefined ? (
                <Skeleton height={20} width={32} {...skeletonProps} />
            ) : typeof value === "string" ? (
                <Typography
                    {...(valueProps ?? {
                        fontWeight: 500,
                        fontSize: "0.88em",
                        color: "#636366",
                    })}
                >
                    {value}
                </Typography>
            ) : (
                value
            )}
        </Box>
    );
};

export const CustomCard = ({
    title,
    status,
    fullWidth = false,
    image,
    content,
    children,
    onClickStatus,
    onClick,
    ...props
}: ICustomCardProps & ButtonBaseProps) => {
    return (
        <Grid
            item
            xs={1}
            md={1}
            component={ButtonBase}
            sx={{
                display: "block",
                textAlign: "left",
                width: fullWidth ? "100%" : "auto",
                cursor: Boolean(onClick) ? "pointer" : "initial",
            }}
            disableRipple
            {...props}
            onClick={onClick}
        >
            <Stack
                component={Card}
                position={"relative"}
                sx={{
                    ["&:hover"]: {
                        boxShadow: Boolean(onClick)
                            ? "0 16px 16px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgb(181 181 190 / 10%), 0 0 2px 0 rgb(151 151 151 / 20%), 0 0 1px 0 rgb(151 151 151 / 35%);"
                            : "0 0 10px 0 rgb(181 181 190 / 10%), 0 0 2px 0 rgb(151 151 151 / 20%), 0 0 1px 0 rgb(151 151 151 / 35%);",
                    },
                }}
                p={2}
                direction={"row"}
                justifyContent={"space-between"}
            >
                {status && title && (
                    <StatusChip
                        sx={{
                            position: "absolute",
                            top: 24,
                            left: 24,
                            zIndex: 5,
                        }}
                        status={status}
                        onClick={onClickStatus ? () => onClickStatus?.(status) : undefined}
                    />
                )}
                <Stack
                    sx={{ opacity: status === "archived" ? 0.38 : 1 }}
                    direction={"row"}
                    alignItems={"center"}
                    spacing={4}
                >
                    {image}

                    <Box>
                        {!title ? (
                            <Skeleton width={240} height={29} />
                        ) : (
                            <Typography variant={"h4"} fontSize={"1.2em"}>
                                {title}
                            </Typography>
                        )}

                        {content}
                    </Box>
                </Stack>

                {children}
            </Stack>
        </Grid>
    );
};
