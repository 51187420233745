import {
    Box,
    BoxProps,
    Button,
    Card,
    Grow,
    lighten,
    Stack,
    Step,
    StepButton,
    Stepper,
    styled,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnBoarding } from "../../Hooks/useOnBoarding";
import { CheckRounded } from "@mui/icons-material";
import { useProfile } from "../../Hooks/useProfile";
import { TranslateFunction, useTranslate } from "../../Hooks/useTranslate";

const animationVariants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 400 : -400,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            opacity: 0,
        };
    },
};

const getSuccessPage = (callback: () => void, t: TranslateFunction) => ({
    key: "success",
    cardTitle: t("success.title"),
    cardDescription: t("success.description"),
    imageSrc: "/graphics/onboarding-widget-illustration-success.png",
    imageWidth: 220,
    stepLabel: "",
    steps: [
        {
            key: "completed",
            getUrl: () => callback,
            progress: 0,
            buttonLabel: [t("success.buttonLabel")],
        },
    ],
});

export const OnBoardingWidget = () => {
    const { t } = useTranslate("onboardingWidget");

    const [[step, direction], setStep] = useState([0, 0]);

    const location = useLocation();
    const navigate = useNavigate();

    const {
        onBoarding: { data: onBoarding = [], isLoading },
    } = useOnBoarding();
    const { companyProfileUrl, finishOnboarding } = useProfile();

    const [allCompleted, setAllCompleted] = useState(false);
    const [items, setItems] = useState<typeof onBoarding>([]);

    useEffect(() => {
        if (onBoarding?.length > 0 && companyProfileUrl()) {
            const pendingStep = onBoarding.findIndex((el) => !el.completed);
            if (pendingStep === -1) {
                setItems([...onBoarding, getSuccessPage(finishOnboarding.mutate, t)]);
                setStep([onBoarding.length, 1]);
                setAllCompleted(true);
            } else {
                setItems([...onBoarding]);
                handleChangeStep(pendingStep !== -1 ? pendingStep : 0)();
            }
        }
    }, [onBoarding]);

    const handleChangeStep = (index: number) => () => {
        setStep((p) => {
            const [current] = p;
            return current > index ? [index, 0] : [index, 1];
        });
    };

    const handleClick = (index: number) => () => {
        const { href } = getButton(index);
        if (href) {
            if (typeof href === "function") {
                return href();
            }
            if (href.includes("http")) {
                return window.open(href, "_blank");
            }
            navigate(href, { state: { returnUrl: location.pathname } });
        }
    };

    const getButton = (index: number) => {
        const { key } = items[index] ?? {};
        const { getUrl, subjectId, progress, buttonLabel } =
            getNextStep(index) ?? getLastStep(index) ?? {};
        return {
            href: typeof subjectId === "string" ? getUrl?.(subjectId) : getUrl?.(),
            buttonLabel:
                progress >= 100
                    ? t("completedButtonLabel")
                    : subjectId || progress > 0
                    ? buttonLabel.length === 2
                        ? t(`${key}.buttonLabel2`)
                        : t(`${key}.buttonLabel1`)
                    : buttonLabel?.[0]
                    ? t(`${key}.buttonLabel1`)
                    : "",
        };
    };

    const getLastStep = (index: number) => {
        return [...(items[index]?.steps ?? [])].pop();
    };

    const getNextStep = (index: number) => {
        return items[index]?.steps?.filter((el: any) => el.progress < 100)[0];
    };

    const getProgress = (steps?: { progress: number }[]) => {
        if (steps) {
            const summed = steps.reduce((total, { progress }) => progress + total, 0);
            return Math.round(summed / steps.length);
        }
        return 0;
    };

    const isStepActive = (index: number) => {
        const { depends } = getNextStep(index) ?? getLastStep(index) ?? {};
        if (depends) {
            return depends.some((key) => items.find((el) => el.key === key)?.completed === true);
        }
        return true;
    };

    return (
        <>
            {onBoarding?.length > 0 && (
                <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    spacing={{ xs: 4, lg: 8 }}
                    maxWidth={"100%"}
                >
                    <GroupItem
                        flexGrow={1}
                        maxWidth={{ xs: 560, lg: 720 }}
                        minWidth={320}
                        itemCount={(onBoarding.length === 0 ? 1 : onBoarding.length) - (step + 1)}
                    >
                        <Box
                            color={"white"}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            maxWidth={{ xs: "55%", lg: "100%" }}
                            sx={{ zIndex: 20 }}
                        >
                            <Box>
                                <Typography variant={"h3"} fontSize={"1.625em"}>
                                    {t(`${items[step]?.key}.title`)}
                                </Typography>
                                <Typography whiteSpace={"pre-wrap"} mt={2}>
                                    {t(`${items[step]?.key}.description`)}
                                </Typography>
                            </Box>
                            <Button
                                size={"large"}
                                disabled={!isStepActive(step) || items[step]?.completed === true}
                                onClick={handleClick(step)}
                                startIcon={
                                    items[step]?.completed === true && (
                                        <CheckRounded color={"success"} />
                                    )
                                }
                                sx={{
                                    whiteSpace: "nowrap",
                                    "&:hover": { backgroundColor: "rgba(245,245,245,1)" },
                                }}
                                variant={"outlined"}
                                color={"secondary"}
                            >
                                {getButton(step).buttonLabel}
                            </Button>
                        </Box>
                        <Box
                            position={"relative"}
                            width={items[step]?.["imageWidth"]}
                            ml={{ xs: 4, lg: 8 }}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <Box
                                component={"img"}
                                height={"100%"}
                                maxWidth={items[step]?.["imageWidth"] + 20}
                                maxHeight={{ xs: 164, lg: 240 }}
                                sx={{ objectFit: "contain", transform: "scale(1.8)" }}
                                src={items[step]?.["imageSrc"]}
                            />
                        </Box>
                    </GroupItem>

                    <Stepper
                        activeStep={step}
                        orientation="vertical"
                        sx={{ pointerEvents: allCompleted ? "none" : "initial" }}
                    >
                        {items
                            ?.filter((f) => f.key !== "success")
                            .map((step, index) => (
                                <Step
                                    completed={step?.completed === true}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": { opacity: 0.8 },
                                        "& svg.Mui-completed": {
                                            color: "#34C759 !important",
                                        },
                                    }}
                                    key={step.key}
                                    onClick={handleChangeStep(index)}
                                >
                                    <StepButton disableRipple={true}>
                                        <Typography pl={1} fontSize={"0.88rem"} fontWeight={600}>
                                            {t(`${step.key}.stepLabel`)}
                                        </Typography>
                                        <Typography pl={1} fontSize={"0.88rem"} fontWeight={600}>
                                            {getProgress(step.steps)}%
                                        </Typography>
                                    </StepButton>
                                </Step>
                            ))}
                    </Stepper>
                </Stack>
            )}
        </>
    );
};

const StyledItem = styled(Box)<any>({
    borderRadius: 24,
    overflow: "initial",
    zIndex: 10,
    "&:hover": {
        backgroundColor: "rgb(252,252,252)",
    },
});

const GroupItem = ({
    children,
    shouldFocus = false,
    itemCount = 3,
    ...props
}: BoxProps & { shouldFocus?: boolean; itemCount?: number }) => {
    return (
        <Box position={"relative"} mb={2} {...props}>
            <StyledItem
                component={Card}
                p={"48px 48px 56px 32px"}
                position={"relative"}
                boxShadow={"0 4px 16px 0 rgba(0,0,0,0.07)"}
                display={"flex"}
                minHeight={364}
                maxHeight={364}
                sx={{
                    background:
                        "linear-gradient(162deg, #933FE7 8.20%, #722FDE 44.59%, #5B2CE1 71.84%, #322FDE 100%)",
                }}
            >
                {children}
            </StyledItem>
            <Box sx={{ transform: "translateY(0px)" }}>
                {Array.from(Array(itemCount > 0 ? itemCount : 0).keys()).map((el, i) => (
                    <Grow in={!shouldFocus} key={`grow-item-${i}`}>
                        <Box
                            component={Card}
                            boxShadow={"0 4px 16px 0 rgba(0,0,0,0.07)"}
                            width={`calc(100% - ${(i + 1) * 32}px)`}
                            height={4}
                            borderRadius={2}
                            zIndex={itemCount - i}
                            position={"absolute"}
                            bottom={-(6 * (i + 1))}
                            left={16 * (i + 1)}
                            sx={{
                                background: `linear-gradient(162deg, ${lighten(
                                    "#933FE7",
                                    0.1
                                )} 8.20%, ${lighten("#722FDE", 0.1)} 44.59%, ${lighten(
                                    "#5B2CE1",
                                    0.1
                                )} 71.84%, ${lighten("#322FDE", 0.1)} 100%)`,
                            }}
                        />
                    </Grow>
                ))}
            </Box>
        </Box>
    );
};
