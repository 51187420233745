import React, { ComponentPropsWithoutRef, useMemo } from "react";
import { SyiPage } from "../SyiPage/SyiPage";
import { funnelSyiSteps, funnelSchema } from "../SyiPage/config";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import randomBytes from "randombytes";
import { useTranslate } from "../../Hooks/useTranslate";
import { TFunction } from "i18next";
import { useForm, FormProvider } from "react-hook-form";
import { EditableFunnel, useFunnels } from "../../Hooks/useFunnels";
import { useAuth } from "../../Hooks/useAuth";
import { AppShell } from "../../Components/AppShell/AppShell";
import { FunnelDetailsPage } from "./FunnelDetailsPage";

const getTitle = (t: TFunction, id: string | undefined) =>
    id === "create"
        ? `${t("new", "utils.generic")} ${t("funnel", "utils.generic")}`
        : `${t("edit", "utils.generic")} ${t("funnel", "utils.generic")}`;

export const FunnelPage = ({ children }: ComponentPropsWithoutRef<"main">) => {
    const { t } = useTranslate("utils.generic");

    const { id } = useParams<{ id: string }>();

    const { getAccessToken } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    const {
        params: { section },
    } = useMatch(":key/:id/:section/*") ?? { params: {} };

    const _id = useMemo(() => (id !== "create" && id ? id : randomBytes(16).toString("hex")), [id]);

    const { control, setValue, ...methods } = useForm<EditableFunnel>({
        defaultValues: {
            public: false,
        },
    });

    const { funnel, updateFunnel, deleteFunnel } = useFunnels(_id);

    const _steps = useMemo(() => {
        return funnelSyiSteps.map((el) => {
            return {
                ...el,
                label: t(el.key, "funnel.create.progress"),
            };
        });
    }, []);

    if (id !== "create" && section !== "edit") {
        return (
            <AppShell>
                <FunnelDetailsPage funnelId={_id}>{children}</FunnelDetailsPage>
            </AppShell>
        );
    }

    const onSubmit = async ({ service, meta, ...data }: EditableFunnel & { service: unknown }) => {
        updateFunnel.mutate({ ...data, id: _id });
        return navigate(location?.state?.returnUrl ?? "/funnels", { replace: true });
    };

    return (
        <FormProvider {...{ ...methods, control, setValue }}>
            <SyiPage
                modelType={"funnel"}
                steps={_steps}
                title={getTitle(t, id)}
                prepareData={(data) => data}
                deleteItem={deleteFunnel}
                updateItem={updateFunnel}
                item={funnel}
                id={_id}
                schema={funnelSchema}
                returnUrl={location?.state?.returnUrl ?? "/funnels"}
                onSubmit={onSubmit}
                isCreateFlow={id === "create"}
            >
                {children}
            </SyiPage>
        </FormProvider>
    );
};
