import Fetch from './index'

export const initializeGoogleAuth = async () => {
    if (typeof process.env.REACT_APP_API_AUTH_GOOGLE === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_AUTH_GOOGLE is not defined')
    }

    return Fetch.post<{ url: string }>(`${process.env.REACT_APP_API_AUTH_GOOGLE}`, {})
}

export const getTranslation = async (payload: {
    text: string
    targetLanguageCode: string
    sourceLanguageCode?: string
}) => {
    if (typeof process.env.REACT_APP_API_TRANSLATE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_TRANSLATE_URL is not defined')
    }

    return Fetch.post<{ text: string }>(`${process.env.REACT_APP_API_TRANSLATE_URL}`, {
        ...payload,
    })
}

export const initStripeSetup = async () => {
    if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_PAYMENT_URL is not defined')
    }

    return Fetch.get<{ url: string }>(`${process.env.REACT_APP_API_PAYMENT_URL}setup`)
}

export const messageParticipants = async (
    eventId: string,
    userId: string,
    message: string,
    sendCopyToMe: boolean,
    attachments?: { url: string; key: string }[],
    excludes?: string[],
) => {
    if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_EVENTS_URL is not defined')
    }

    try {
        return await Fetch.post(`${process.env.REACT_APP_API_EVENTS_URL}${eventId}/message`, {
            message,
            userId,
            excludes,
            attachments,
            sendCopyToMe,
        })
    } catch (err: unknown) {
        try {
            const { failed, succeeded, total } = JSON.parse((err as any).response?.body) as {
                failed: string[]
                total: number
                succeeded: string[]
            }
            throw Object.assign(new Error((err as any).message), {
                msg: `${failed.length} ud af ${total} fejlede:\n${failed.join('\n')}`,
                failed,
                succeeded,
            })
        } catch (e) {
            if (e?.hasOwnProperty('failed')) {
                throw e
            }
            throw err
        }
    }
}
