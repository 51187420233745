import { TUser, useUsers } from "./useUsers";
import {
    Box,
    MenuItem,
    Popover,
    PopoverProps,
    Select,
    SelectProps,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { useMemo } from "react";

const removeSelected = (all?: TUser[], selected?: string[]) => {
    return all?.filter((a) => !selected?.includes(a.id)) ?? [];
};
export const useAssignees = (defaultExcludes?: string[]) => {
    const {
        users: { data: users },
    } = useUsers();

    const renderPopover = (anchorEl: PopoverProps["anchorEl"], excludes?: string[]) => {
        if (removeSelected(users, excludes).length > 0) {
            return NiceModal.show(AssigneePopover, {
                users: removeSelected(users, excludes ?? excludes),
                anchorEl,
            });
        }
        return Promise.resolve();
    };

    const canAddAssignee = useMemo(
        () => users?.length !== defaultExcludes?.length,
        [users, defaultExcludes]
    );

    return {
        renderPopover,
        canAddAssignee,
    };
};

type AssigneePopoverProps = {
    users?: TUser[];
    anchorEl: PopoverProps["anchorEl"];
    onChange: (id: string) => void;
};

const AssigneePopover = NiceModal.create<AssigneePopoverProps>(({ users, anchorEl }) => {
    const modal = useModal();

    const handleClose = () => {
        modal.remove();
    };
    const handleChange: SelectProps["onChange"] = (evt) => {
        modal.resolve(evt.target.value);
        handleClose();
    };
    return users && users?.length > 0 ? (
        <Popover open={true} anchorEl={anchorEl} onClose={handleClose}>
            <Select onChange={handleChange} displayEmpty defaultValue={""}>
                <MenuItem value={""}>Vælg facilitator</MenuItem>
                {users?.map((el) => (
                    <MenuItem value={el.id}>
                        {" "}
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            mr={2}
                            flexGrow={1}
                            overflow={"hidden"}
                        >
                            <Box
                                component={"img"}
                                src={el?.pictures?.profile?.url}
                                width={32}
                                height={32}
                                borderRadius={100}
                                mr={1}
                            />
                            <Typography
                                textOverflow={"ellipsis"}
                                maxWidth={"100%"}
                                overflow={"hidden"}
                            >
                                {el.name}
                            </Typography>
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </Popover>
    ) : null;
});
