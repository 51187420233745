import {
    Box,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    List,
    ListItem,
    styled,
} from "@mui/material";
import { memo, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { Logo } from "../Logo/Logo";
import {
    BookingsIcon,
    CalendarIcon,
    DashboardIcon,
    DiscountIcon,
    ExperienceIcon,
    GiftcardIcon,
    MarketingIcon,
    SettingsIcon,
} from "../../Icons";
import { useProfile } from "../../Hooks/useProfile";
import { useTranslation } from "react-i18next";
import { AccountControl } from "../AccountControl";

const StyledNavigation = styled(Box)({
    zIndex: 120,
    left: 0,
    top: 0,
    position: "fixed",
    padding: "0 0 40px 0",
    borderRight: "1px solid #E2E2E2",
    width: "100%",
    height: "100vh",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(326.67deg, #521592 19.91%, #232580 80.24%)",
});

const StyledContainer = styled("main")({});

const _menuItems: {
    label: string;
    icon: typeof DashboardIcon;
    path: string;
    matches?: string[];
    flag?: string;
}[] = [
    {
        label: "Overblik",
        icon: DashboardIcon,
        path: "dashboard",
    },
    {
        label: "Kalender",
        icon: CalendarIcon,
        path: "calendar",
    },
    {
        label: "Ydelser",
        icon: ExperienceIcon,
        path: "services",
        matches: ["service", "event"],
    },
    {
        label: "Customers",
        icon: BookingsIcon,
        path: "customers",
        matches: ["customers", "leads", "offers", "leads/closed", "bookings"],
    },
    /*{
    label: 'Widgets',
    icon: <WebRounded/>,
    path: 'widget',
    matches: ['widget']
}, {
        label: "Gavekort",
        icon: GiftcardIcon,
        path: "vouchers",
    },*/
    {
        label: "Rabatkoder",
        icon: DiscountIcon,
        path: "discounts",
        flag: "menuItemDiscountCodes",
    } /* {
        label: "Marketing",
        icon: MarketingIcon,
        path: "marketing",
    },*/,
    {
        label: "Indstillinger",
        icon: SettingsIcon,
        path: "settings",
        matches: ["settings"],
    },
];

const pathsForViewers = ["services", "service", "settings", "calendar", "event"];

const TawkScript = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = `
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/64e5fdfc94cf5d49dc6c0ec2/1h8h6g977';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
        })();`;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return null;
};

export const AppShell = memo(({ children }: { children?: ReactNode }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { myRole } = useProfile();

    const navRef = useRef<HTMLElement | null>(null);
    const [navWidth, setNavWidth] = useState(279);

    useEffect(() => {
        const calcNavWidth = () => {
            const n = navRef.current?.clientWidth ?? 300;
            setNavWidth(n);
        };

        calcNavWidth();

        window.addEventListener("resize", calcNavWidth);

        return () => window.removeEventListener("resize", calcNavWidth);
    }, []);

    const {
        params: { id: locationKey },
    } = useMatch(":id/*") ?? { params: {} };

    useEffect(() => {
        if (myRole && myRole !== "admin" && !pathsForViewers.includes(locationKey as string)) {
            navigate(`/${pathsForViewers[0]}`, { replace: true });
        }
    }, [myRole]);

    const menuItems = useMemo(() => {
        return myRole === "admin"
            ? _menuItems
            : _menuItems.filter(({ path }) => pathsForViewers.includes(path));
    }, [myRole, t]);

    return (
        <Box
            component={"main"}
            display={"flex"}
            width={"100%"}
            height={"100vh"}
            justifyContent={"space-between"}
            overflow={{ xs: "hidden", md: "initial" }}
        >
            <TawkScript />
            <StyledNavigation ref={navRef} maxWidth={{ xs: 200, lg: 280 }} component={"nav"}>
                <Box
                    sx={{
                        background:
                            "linear-gradient(180deg, rgba(38,36,129,0.2) 0%, rgba(52,32,134,1) 100%), url(/graphics/navigation-top-bg.jpeg)",
                        backgroundSize: "contain",
                        width: "100%",
                        paddingTop: { xs: 4, lg: 8 },
                        paddingBottom: 4,
                        paddingX: 1,
                    }}
                >
                    <AccountControl />
                </Box>

                <Box component={List} width={"100%"} sx={{ minHeight: "180px", overflowY: "auto" }}>
                    {menuItems.map((el) => {
                        const Icon = el.icon;
                        return (
                            <Link to={`/${el.path}`} key={el.path}>
                                <ListItem sx={{ color: "white" }}>
                                    <ListItemButton
                                        sx={{
                                            p: "15px",
                                            "&.Mui-selected": { backgroundColor: "#5428B4" },
                                        }}
                                        selected={
                                            locationKey === el.path ||
                                            el?.matches?.includes(locationKey as string)
                                        }
                                    >
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText
                                            color={"inherit"}
                                            primary={t(`utils.primaryNavigation.${el.path}`)}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        );
                    })}
                </Box>
                <Box paddingY={2} paddingX={4} marginTop={"auto"} width={"100%"}>
                    <Logo height={"40px"} white width={"100%"} />
                </Box>
            </StyledNavigation>
            <Box
                pl={{
                    xs: `${navWidth + 8 * 2}px`,
                    lg: `${navWidth + 9 * 5}px`,
                    xl: `${navWidth + 9 * 8}px`,
                }}
                pr={{
                    xs: 2,
                    lg: 4,
                    xl: 0,
                }}
                pt={{
                    xs: 0,
                    lg: 3,
                }}
                width={"100%"}
                position={"relative"}
                sx={{ overflow: "auto" }}
            >
                {children}
            </Box>
        </Box>
    );
});
