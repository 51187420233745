import { Box, BoxProps, MenuItem, Select, Stack, styled, Typography } from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { SearchField } from "../SearchField/SearchField";

const StyledSelect = styled(Select)({
    "& .MuiSelect-select.MuiInput-input": {
        fontWeight: 600,
        fontSize: "1em",
        paddingRight: "24px !important",
    },
});

type ListHeaderProps<Filter extends string = string> = Omit<BoxProps, "title"> & {
    title: string | null;
    filterOptions: { key: string; label: string }[];
    onChangeFilter: (key: Filter) => void;
    defaultSelectedFilter: string;
    onSearch?: (value: string) => void;
    searchPlaceholder?: string;
};

export const ListHeader = ({
    title,
    filterOptions,
    defaultSelectedFilter,
    onChangeFilter,
    onSearch,
    searchPlaceholder,
    ...props
}: ListHeaderProps) => {
    return (
        <Box {...props}>
            {onSearch && title && <Typography variant={"h4"}>{title}</Typography>}
            <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                {onSearch ? (
                    <SearchField
                        minWidth={480}
                        placeholder={searchPlaceholder ?? "Søg"}
                        onSearch={onSearch}
                    />
                ) : (
                    <Typography variant={"h4"}>{title}</Typography>
                )}
                <StyledSelect
                    variant={"outlined"}
                    size={"small"}
                    sx={{ minWidth: 240 }}
                    startAdornment={
                        <Typography mr={1} fontSize={"0.88em"} color={"#636366"}>
                            Vis
                        </Typography>
                    }
                    IconComponent={ArrowDropDownRounded}
                    defaultValue={defaultSelectedFilter}
                    onChange={(e) => onChangeFilter(e.target.value as string)}
                >
                    {filterOptions.map((el) => (
                        <MenuItem value={el.key}>{el.label}</MenuItem>
                    ))}
                </StyledSelect>
            </Stack>
        </Box>
    );
};
