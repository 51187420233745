import { Page } from "../../Components/Page/Page";
import React, { ComponentPropsWithoutRef, useMemo, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItemProps,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {
    ArrowBackRounded,
    DeleteForeverRounded,
    EditRounded,
    MoreVert,
    PauseCircleFilledOutlined,
    PlayCircleFilledOutlined,
    SvgIconComponent,
    VisibilityRounded,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useService } from "../../Hooks/useService";
import { ServiceCard } from "../../Components/ServiceCard/ServiceCard";
import MenuItem from "@mui/material/MenuItem";
import react from "react";
import { BlackMenu } from "../../Components/BlackMenu/BlackMenu";
import { eventSyiSteps } from "../SyiPage/config";
import { EventsList } from "../../Components/EventsList/EventsList";
import { type TStatus } from "../../Components/StatusChip";
import { useEvent } from "../../Hooks/useEvent";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { useProfile } from "../../Hooks/useProfile";
import { ShowForRole } from "../../Components/AllowForRole/AllowForRole";
import { useTranslate } from "../../Hooks/useTranslate";
import { useOnBoarding } from "../../Hooks/useOnBoarding";
import { PendingOnboardingDialog } from "../../Modals/PendingOnboardingDialog";
import { useTranslation } from "react-i18next";
import { BookingsList, BookingsListProps } from "../../Components/BookingsList/BookingsList";
import { useBookings } from "../../Hooks/useBookings";
import { useSession } from "../../Hooks/useSession";
import { useLocalizer } from "../Events/EventSyiSections/EventSyiSectionDetails";
import { CustomTabs } from "../../Components/CustomTabs/CustomTabs";
import { useLeads } from "../../Hooks/useLeads";
import { LeadsList } from "../../Components/LeadsList/LeadsList";
import { useWeakStore as useStore } from "../../Store/useStore";
import { CONSUMER_URL } from "../../Utils/constants";
import { CreateNewBookingDialog } from "../../Modals/CreateNewBookingDialog/CreateNewBookingDialog";
import { useReviews } from "../../Hooks/useReviews";

const options: {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
}[] = [
    {
        key: "edit",
        label: "Rediger",
        icon: EditRounded,
    },
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

export const ServiceDetailsPage = ({
    serviceId,
    children,
}: ComponentPropsWithoutRef<any> & { serviceId: string }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { i18n } = useTranslation();

    const { serviceUpdating } = useStore();

    const isMobile = useMediaQuery("(max-width: 1200px)");

    const { t } = useTranslate("service.card");

    const localize = useLocalizer();

    const {
        service: { data: service },
        deleteService,
        updateService,
    } = useService(serviceId);

    const {
        eventsForService: { data: eventsForService },
        getUpcomingEventsCount,
    } = useEvent(undefined, serviceId);

    const { getBookingsForServiceCount } = useBookings();

    const {
        rating: { isLoading: ratingLoading, data: rating },
    } = useReviews("service", serviceId);

    const { missingSteps } = useOnBoarding();

    const handleBack = () => navigate(location?.state?.returnUrl ?? "/services");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const toggleStatusTo = (newStatus: TStatus) => {
        if (service) {
            updateService.mutateAsync({ ...service, status: newStatus });
        }
    };

    const handleEdit = () => {
        navigate("edit/details", { state: { returnUrl: `/service/${serviceId}` } });
    };

    const handleSelect = (key: string) => () => {
        if (key === "edit") {
            handleEdit();
        }
        if (key === "activate") toggleStatusTo("active");
        if (key === "deactivate") toggleStatusTo("inactive");
        if (key === "archive") toggleStatusTo("archived");
        if (key === "delete") {
            if (
                eventsForService
                    ?.filter((f) => new Date(f.startDateTime) > new Date())
                    ?.some((el) => el.slots.booked !== 0)
            ) {
                return NiceModal.show(ConfirmDialog, {
                    allowCancel: false,
                    headline: t("headline", "dialogs.deleteServiceWithBookings"),
                    title: t("title", "dialogs.deleteServiceWithBookings"),
                });
            }
            NiceModal.show(ConfirmDialog, {
                headline: t("headline", "dialogs.confirmDelete", {
                    type: t("thisService", "utils.generic"),
                }),
                title: t("title", "dialogs.confirmDelete"),
            }).then(() => {
                deleteService.mutate(serviceId);
                navigate("/services", { replace: true });
            });
        }
        handleClose();
    };

    const handleClick = (event: react.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateEvent = () => {
        navigate(`/event/create/${eventSyiSteps[0].key}`, {
            state: { serviceId, returnUrl: `/service/${serviceId}` },
        });
    };

    const handleCompleteDraft = () => {
        navigate("edit", { state: { returnUrl: location.pathname, validateOnMount: true } });
    };

    const handleGoToService = () => {
        window.open(`${CONSUMER_URL}/service/${serviceId}`, "_blank");
        return;
        if (missingSteps.length > 0) {
            return NiceModal.show(PendingOnboardingDialog).then(() =>
                navigate("/dashboard/overview")
            );
        }
    };

    const handleCreateManualSessionBooking = () => {
        const start = new Date();
        start.setHours(start.getHours() + 1, 0, 0, 0);
        const end = new Date(start);
        end.setHours(end.getHours() + 1, 0, 0, 0);
        NiceModal.show(CreateNewBookingDialog, {
            serviceId,
            start,
            end,
        });
    };

    const _options: typeof options = useMemo(() => {
        const primary =
            service?.status === "active"
                ? {
                      key: "deactivate",
                      icon: PauseCircleFilledOutlined,
                  }
                : {
                      key: "activate",
                      icon: PlayCircleFilledOutlined,
                  };
        return [primary, ...options].map((el) => ({ ...el, label: t(`options.${el.key}`) }));
    }, [service]);

    return (
        <Page
            maxWidth={1200}
            height={"100%"}
            sx={{
                margin: 0,
                padding: "40px 0 40px 0",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box display={"flex"} alignItems={"center"} mb={4} minHeight={44}>
                <IconButton onClick={handleBack}>
                    <ArrowBackRounded />
                </IconButton>
                <Typography variant={"h2"} ml={2}>
                    {service?.headline?.[i18n.language] ??
                        service?.headline?.[service.languages[0]]}
                </Typography>
            </Box>
            <ServiceCard
                status={service?.status ?? "draft"}
                title={localize(service?.headline)}
                type={service?.type}
                languages={service?.languages}
                eventCount={getUpcomingEventsCount()}
                bookingCount={getBookingsForServiceCount(serviceId)}
                coverImage={service?.pictures?.[0]?.url}
                enableConversion={true}
                rating={
                    ratingLoading
                        ? undefined
                        : { average: rating?.rating ?? 0, count: rating?.count ?? 0 }
                }
                createdDate={service?.created}
                fullWidth
                serviceId={serviceId}
                imageProps={{ width: isMobile ? 200 : 240 }}
            >
                <>
                    <div>
                        <ShowForRole roles={["admin"]}>
                            <IconButton onClick={handleClick}>
                                <MoreVert />
                            </IconButton>
                            <BlackMenu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                {_options.map((el, i) => {
                                    const Icon = el.icon as any;
                                    return (
                                        <MenuItem
                                            onClick={handleSelect(el.key)}
                                            key={el.key}
                                            autoFocus={false}
                                        >
                                            <ListItemIcon>
                                                <Icon fontSize={"small"} {...el.props} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={el.props}>
                                                {el.label}
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })}
                            </BlackMenu>
                        </ShowForRole>
                    </div>

                    {(service?.status === "active" || service?.status === "inactive") && (
                        <Stack spacing={2} direction={"row"} p={1} whiteSpace={"nowrap"}>
                            {service?.status === "active" && (
                                <Button
                                    variant={"outlined"}
                                    color={"secondary"}
                                    startIcon={<VisibilityRounded />}
                                    size={"large"}
                                    onClick={handleGoToService}
                                >
                                    {t("actions.secondary")}
                                </Button>
                            )}
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                startIcon={<EditRounded />}
                                size={"large"}
                                onClick={handleEdit}
                            >
                                {t("actions.edit")}
                            </Button>
                            {service?.type && (
                                <ShowForRole roles={["admin"]}>
                                    <Button
                                        disabled={service.validated === false}
                                        variant={"contained"}
                                        size={"large"}
                                        onClick={
                                            service.type === "event"
                                                ? handleCreateEvent
                                                : handleCreateManualSessionBooking
                                        }
                                    >
                                        {service.type === "event"
                                            ? t("actions.primary")
                                            : "Opret aftale"}
                                    </Button>
                                </ShowForRole>
                            )}
                        </Stack>
                    )}

                    {service?.status === "draft" && (
                        <Button variant={"contained"} size={"large"} onClick={handleCompleteDraft}>
                            {t("actions.finishDraft")}
                        </Button>
                    )}
                </>
            </ServiceCard>

            {service &&
                service.status !== "draft" &&
                (service?.type === "session" ? (
                    <BookingsForSession mt={5} pb={8} serviceId={serviceId} />
                ) : (
                    <EventsList
                        serviceId={serviceId}
                        mt={5}
                        emptyScreenProps={{ buttonProps: { disabled: !service.validated } }}
                        handleCreate={handleCreateEvent}
                    />
                ))}
        </Page>
    );
};

const BookingsForSession = ({
    serviceId,
    ...props
}: Omit<BookingsListProps, "bookings"> & { serviceId: string }) => {
    const {
        sessionForService: { data: session, isLoading },
    } = useSession(undefined, serviceId);

    const {
        leadsForService: { data: introSessions },
    } = useLeads(serviceId);

    const { bookingsForSession: bookings } = useBookings(undefined, undefined, session?.id);

    const [selected, setSelected] = useState("bookings");

    return (
        <Box {...props}>
            <CustomTabs
                items={[
                    { key: "bookings", label: "Bookings", badge: bookings?.length },
                    { key: "introSessions", label: "Henvendelser", badge: introSessions?.length },
                ]}
                onChange={setSelected}
                selected={selected}
                sx={{
                    borderBottom: "1px solid #EFEFEF",
                }}
            />
            {selected === "bookings" ? (
                <BookingsList
                    mt={4}
                    bookings={isLoading ? undefined : bookings}
                    type={"session"}
                    showTitle={false}
                    openModalInline={true}
                />
            ) : (
                <LeadsList
                    openModalInline={true}
                    showService={false}
                    mt={4}
                    leads={introSessions}
                />
            )}
        </Box>
    );
};
