import { Box, BoxProps, styled, Tab, Tabs } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { SyntheticEvent, useEffect, useState } from "react";

const Styled = styled(Box)`
    width: 100%;
    padding: 4px;
    background-color: white;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    z-index: 20;
    border: 1px solid rgba(200, 200, 200, 0.2);
`;

const StyledTab = styled(Tab)<{ selected?: boolean }>(({ selected }) => ({
    fontWeight: 600,
    minHeight: 32,
    minWidth: 140,
    borderRadius: 12,
    "&.Mui-selected": {
        color: "#007AFF",
    },
}));

const StyledTabs = styled(Tabs)({
    minHeight: 32,
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        border: "2px solid #007AFF",
    },
});

export type TTab = {
    key: string;
    label: string;
    Icon?: SvgIconComponent;
};

interface ITabBarProps {
    selected?: number;
    onChange?: (index: number) => void;
    items?: TTab[];
}

export const TabBar = ({ items, selected, onChange, ...props }: BoxProps<"div", ITabBarProps>) => {
    const handleChange = (e: SyntheticEvent, newValue: number) => {
        onChange?.(newValue);
    };

    return (
        <Styled {...props}>
            <StyledTabs value={selected} onChange={handleChange}>
                {items?.map((el, index) => {
                    const { Icon, key, label } = el;
                    return (
                        <StyledTab
                            sx={{
                                minWidth: { xs: 100, lg: 140 },
                            }}
                            key={key}
                            value={index}
                            label={label}
                            icon={Icon ? <Icon fontSize={"small"} /> : undefined}
                            iconPosition="start"
                        />
                    );
                })}
            </StyledTabs>
        </Styled>
    );
};
