import { Box, Button, Stack } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useTranslate } from "../../Hooks/useTranslate";
import React from "react";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import { TagsSection } from "../../Components/Tags/Tags";
import { useController } from "react-hook-form";
import { Footer } from "./ContentBlock";

export const OnBoardingTagsSection = () => {
    const { t } = useTranslate("onboarding.tags");

    const { handleNext, handleBack } = useOutletContext<any>();

    const { field: tags } = useController({ name: "tags", defaultValue: [] });

    return (
        <>
            <Box pb={12}>
                <TagsSection
                    tags={tags.value}
                    onChange={tags.onChange}
                    disableSubmitSection={true}
                />
            </Box>
            <Footer>
                <Button
                    onClick={handleBack}
                    startIcon={<ArrowBackRounded />}
                    variant={"outlined"}
                    size={"large"}
                    color={"secondary"}
                >
                    {t("back", "buttons")}
                </Button>
                <Button
                    size={"large"}
                    variant={"contained"}
                    onClick={handleNext}
                    startIcon={<ArrowForwardRounded />}
                >
                    {t("next", "buttons")}
                </Button>
            </Footer>
        </>
    );
};
