import { useLocalizer } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import {
    Box,
    BoxProps,
    Chip,
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Typography,
} from "@mui/material";
import React from "react";
import { TEvent, useEvent } from "../Hooks/useEvent";
import { format } from "date-fns";
import { renderRangeDate } from "./EventsList/EventsList";

type EventSelectProps = {
    eventId?: string;
    serviceId: string;
    inputLabel: string;
    onChange?: SelectProps["onChange"];
    selectProps?: Omit<SelectProps, "onChange">;
    showStatus?: boolean;
} & Omit<FormControlProps, "onChange">;
export const EventSelect = ({
    serviceId,
    eventId,
    onChange,
    inputLabel,
    selectProps,
    showStatus = true,
    ...props
}: EventSelectProps) => {
    const localize = useLocalizer();

    const {
        eventsForService: { data: events },
    } = useEvent(undefined, serviceId);

    const renderDate = (event: TEvent<any>) => {
        return !Boolean(event?.isSameDay)
            ? renderRangeDate(event?.startDateTime, event?.endDateTime)
            : format(new Date(event?.startDateTime ?? null), "dd.MM.yyy");
    };

    return (
        <FormControl sx={{ minWidth: 320, maxWidth: 320 }} {...props}>
            <InputLabel sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }} id="select-event">
                {inputLabel}
            </InputLabel>
            <Select
                labelId="select-event"
                defaultValue={eventId}
                onChange={onChange}
                {...selectProps}
            >
                {events?.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                        <EventSelectItemContent
                            key={el.id}
                            status={el.status}
                            name={localize(el.headline)}
                            label={`${el.slots?.booked} / ${el.slots?.total}`}
                            date={renderDate(el)}
                            showStatus={showStatus}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

type EventSelectItemContentProps = {
    name?: string;
    date: string;
    label: string;
    status: string;
} & Pick<EventSelectProps, "showStatus"> &
    BoxProps;
export const EventSelectItemContent = ({
    showStatus,
    name,
    date,
    label,
    status,
    ...props
}: EventSelectItemContentProps) => {
    return (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
            {...props}
        >
            <Box mr={2} flexGrow={1} maxWidth={"100%"} overflow={"hidden"}>
                <Typography textOverflow={"ellipsis"} maxWidth={"100%"} overflow={"hidden"}>
                    {name || date}
                </Typography>
                {name && (
                    <Typography display={"block"} variant={"body2"}>
                        {date}
                    </Typography>
                )}
            </Box>
            <Chip color={status === "active" ? "success" : "default"} label={label} />
        </Box>
    );
};
