import {BoxProps, Button, ButtonProps, Tooltip} from "@mui/material";
import react, { ReactNode, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { StyledMenu } from "../AccountSelect/AccountSelect";
import { useTranslation } from "react-i18next";
import { languagesOptions } from "../../Utils/config";
import { useLocalStorage } from "../../Hooks/useLocalStorage";

type TLanguage = { label: string; icon: string };

export const LanguageSelect = ({
    items = languagesOptions,
    shouldSetTranslation = true,
    onChange,
    renderComp,
    ...props
}: ButtonProps & {
    items?: { [k: string]: TLanguage };
    shouldSetTranslation?: boolean;
    renderComp?: (onClick: BoxProps["onClick"]) => ReactNode;
    onChange?: (key: string) => void;
}) => {
    const { t, i18n } = useTranslation();

    const [_language] = i18n.language.split("-");

    const [preferredLanguage, setPreferredLanguage] = useLocalStorage("preferredLanguage", "da");

    const [selected, setSelected] = useState(preferredLanguage);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (_language in items) {
            setPreferredLanguage(_language);
        } else {
            setPreferredLanguage("da");
        }
    }, []);

    const handleClick = (event: react.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelect = (key: string) => () => {
        setSelected(key);
        if (shouldSetTranslation) {
            setPreferredLanguage(key);
            i18n.changeLanguage(key);
        }
        onChange?.(key);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {renderComp ? (
                renderComp(handleClick)
            ) : (
                <Tooltip title={t("utils.generic.selectLanguage")}>
                    <Button
                        variant={"outlined"}
                        onClick={handleClick}
                        startIcon={
                            <Avatar
                                imgProps={{ sx: { width: "auto" } }}
                                sx={{ height: 24, width: "auto", borderRadius: 0 }}
                                src={items[selected]?.icon}
                            />
                        }
                        {...props}
                        sx={{
                            borderRadius: 100,
                            color: "grey.800",
                            p: "8px 24px",
                            borderColor: "#C7C7CC",
                            backgroundColor: "white",
                            borderWidth: 1,
                            ...props.sx
                        }}
                    >
                        {t(`utils.languages.${selected}`)}
                    </Button>
                </Tooltip>
            )}
            <StyledMenu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {Object.entries(items).map(([key, lang]) => {
                    return (
                        <MenuItem key={key} selected={key === selected} onClick={handleSelect(key)}>
                            <Avatar sx={{ borderRadius: 0 }} src={lang.icon} />{" "}
                            {t(`utils.languages.${key}`) ?? lang.label}
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </>
    );
};
