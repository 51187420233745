import { useController } from "react-hook-form";
import { TagsSection } from "../../../Components/Tags/Tags";
import { useErrors } from "../../SyiPage/useErrors";
import { SyiSection } from "../../SyiPage/SyiSection";
import { Tip } from "../../../Components/Tip/Tip";
import { useTranslate } from "../../../Hooks/useTranslate";

export const ServiceSyiSectionTags = () => {
    const { field: tags } = useController({
        name: "tags",
        defaultValue: [],
    });

    const { t } = useTranslate("tags");

    const { clearError, getError } = useErrors();

    return (
        <SyiSection
            title={t("title")}
            error={getError("tags")}
            typographyProps={{ variant: "h4", mb: 1 }}
        >
            <Tip mb={3} label={t("tipLabel")} />
            <TagsSection
                disableSubmitSection={true}
                showHeader={false}
                tags={tags.value}
                onChange={(e) => {
                    tags.onChange(e);
                    clearError("tags");
                }}
            />
        </SyiSection>
    );
};
