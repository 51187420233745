import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";

export type Rating = {
    rating: number;
    count: number;
    subject?: string;
    subjectId?: string;
};

export type ReviewPayload = Pick<Review, "customer" | "content" | "rating" | "publishedDate"> & {
    subjects: { id: string; type: string }[];
    id: string;
};

export type Review = {
    customer: {
        name: string;
        picture?: string;
    };
    subject: "company" | "assignee" | "service" | string;
    subjectId: string;
    rating: number; // 1 - 5
    content: string;
    publishedDate: string;
    id: string;
};
export const useReviews = (
    subject?: "service" | "company" | "assignee",
    subjectId?: string,
    reviewId?: string
) => {
    const ReviewsQueryKey = ["reviews", subject, subjectId];
    const SingleReviewQueryKey = ["review", reviewId];

    const RatingsQueryKey = ["ratings", subject];
    const SingleRatingQueryKey = ["rating", subject, subjectId];

    const queryClient = useQueryClient();

    const ratings = useQuery(
        RatingsQueryKey,
        async () => {
            await queryClient.cancelQueries(RatingsQueryKey);
            const ratings = await api.getRatings(subject as string);
            for (const rating of ratings) {
                queryClient.setQueryData(["rating", rating.subject, rating.subjectId], rating);
            }
            return ratings;
        },
        {
            enabled: Boolean(subject),
        }
    );

    const rating = useQuery(
        SingleRatingQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleRatingQueryKey);
            return api.getRatingBySubject(subject as string, subjectId as string);
        },
        {
            enabled: Boolean(subject && subjectId),
        }
    );

    const reviews = useQuery(
        ReviewsQueryKey,
        async () => {
            await queryClient.cancelQueries(ReviewsQueryKey);
            const reviews = await api.getReviewsBySubject(subject as string, subjectId as string);
            for (const review of reviews) {
                queryClient.setQueryData(["review", review.id], review);
            }
            return reviews.sort(
                (a, b) => Number(new Date(b.publishedDate)) - Number(new Date(a.publishedDate))
            );
        },
        {
            enabled: Boolean(subject && subjectId),
        }
    );

    const review = useQuery(
        SingleReviewQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleReviewQueryKey);
            return api.getReview(reviewId as string);
        },
        {
            enabled: Boolean(reviewId),
        }
    );

    const updateReview = useMutation(
        ({ id, ...payload }: ReviewPayload) => api.updateReview(id, payload),
        {
            onMutate: async ({ subjects, id, ...review }) => {
                await queryClient.cancelQueries(ReviewsQueryKey);

                const previous = queryClient.getQueryData(["review", id]);

                let previousAll: { queryKey: string[]; data: any }[] = [];

                for (const { type: subject, id: subjectId } of subjects) {
                    const queryKey = ["reviews", subject, subjectId];

                    queryClient.setQueryData<Review[]>(queryKey, (prev) => {
                        if (prev?.some((p) => p.id === id && subjectId === subjectId)) {
                            return prev.map((el) => {
                                return el.subjectId === subjectId && el.id === id
                                    ? {
                                          ...el,
                                          ...review,
                                          id,
                                      }
                                    : el;
                            });
                        }
                        return [{ ...review, id, subject, subjectId }].concat(prev ?? []);
                    });

                    queryClient.setQueryData<Review>(["review", review], (prev) => ({
                        ...prev,
                        id,
                        ...review,
                        subject,
                        subjectId,
                    }));

                    previousAll.push({ queryKey, data: queryClient.getQueryData(queryKey) });
                }

                return { previous, previousAll };
            },
            onError: (err, variables, context) => {
                if (context?.previous) {
                    queryClient.setQueryData(["review", variables.id], context.previous);
                }
                if (context?.previousAll) {
                    context.previousAll.forEach(({ queryKey, data }) =>
                        queryClient.setQueryData(queryKey, data)
                    );
                }
            },
            onSettled: async (data, error, variables, context) => {
                await Promise.all(
                    variables.subjects.map(({ id, type }) =>
                        Promise.all([
                            queryClient.invalidateQueries(["ratings", subject]),
                            queryClient.invalidateQueries(["reviews", type, id]),
                            queryClient.invalidateQueries(["rating", type, id]),
                        ])
                    )
                );
                await queryClient.invalidateQueries(["review", variables.id]);
            },
        }
    );

    const deleteReview = useMutation((id: string) => api.deleteReview(id), {
        onMutate: async (id) => {
            const previous = queryClient.getQueryData<Review>(["review", id]);

            if (previous) {
                queryClient.setQueryData<Review[]>(
                    ["reviews", previous.subject, previous.subjectId],
                    (prev) => {
                        return (
                            prev?.filter((el) => !(el.subjectId === subjectId && el.id === id)) ??
                            []
                        );
                    }
                );
            }

            return { previous };
        },
        onError: (err, variables, context) => {
            if (context?.previous) {
                queryClient.setQueryData(["review", variables], context.previous);
            }
        },
        onSettled: async (data, error, variables, context) => {
            await queryClient.invalidateQueries(["reviews"]);
            await queryClient.invalidateQueries(["ratings"]);
            queryClient.removeQueries(["review", variables]);
        },
    });

    return { reviews, review, updateReview, deleteReview, ratings, rating };
};
