import { Stack, Typography } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";
import { ViewHeader } from "./ViewHeader";
import { EnrichedRecord } from "../../Hooks/useRecords";
import { Tip } from "../../Components/Tip/Tip";
import { Records } from "../../Components/Records/Records";
import React from "react";
import { useBookings } from "../../Hooks/useBookings";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { CustomSelect } from "../../Components/CustomSelect/CustomSelect";
import { Editor } from "../../Components/Editor/Editor";

export const JournalView = ({
    subject,
    subjectId,
}: Pick<EnrichedRecord, "subject" | "subjectId">) => {
    const { t } = useTranslate("dialogs.customer");

    const {
        bookingsForCustomer: bookings,
        getServiceIdFromBooking,
        getServiceName,
    } = useBookings(undefined, undefined, undefined, subjectId);

    return (
        <Stack p={4}>
            <ViewHeader title={t("journal.title")} />
            <Tip label={t("journal.tip")} />
            <Records
                mt={3}
                subject={subject}
                subjectId={subjectId}
                hideAuthor={true}
                shouldToggleAddRecord={true}
                renderMetadataControl={({ handleUpdateMetadata, metadata }) => (
                    <CustomSelect
                        sx={{ mb: 3 }}
                        fullWidth
                        label={t("journal.bookingSelectLabel")}
                        options={bookings.data?.map((el) => ({
                            label: `${el.serviceHeadline}, ${renderDateTime(
                                el.startDateTime,
                                el.endDateTime,
                                { sameDay: "dmhh", standard: "ddmhh" }
                            )}`,
                            key: el.id,
                        }))}
                        onChange={(e) =>
                            handleUpdateMetadata({
                                ...metadata,
                                bookingId: e.target.value as string,
                                serviceId: e.target.value as string,
                            })
                        }
                    />
                )}
                renderMetadata={(metadata) => (
                    <Typography variant={"body2"} mt={1} display={"block"}>
                        {getServiceName(getServiceIdFromBooking(metadata?.bookingId))}
                    </Typography>
                )}
                renderAddRecord={({ handleChange, value }) => (
                    <Editor
                        onChange={handleChange}
                        defaultValue={value}
                        placeholder={t("journal.inputPlaceholder")}
                    />
                )}
            />
        </Stack>
    );
};
