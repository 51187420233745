import { Box, Card, CardProps, Skeleton, Typography } from "@mui/material";
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    RadioButtonChecked,
    RadioButtonUnchecked,
} from "@mui/icons-material";

type IStatsCardProps = {
    title: string | undefined;
    value: number | string | undefined;
    diff?: number;
    label?: string;
    isSelected?: boolean;
};

export const StatsCard = ({
    title,
    value,
    isSelected,
    diff,
    label = "Sammenlignet med forrige periode",
    ...props
}: CardProps & IStatsCardProps) => {
    //const isPositiveDiff = diff > 0;

    return (
        <Card
            {...props}
            variant={"outlined"}
            sx={{
                "&:hover": {
                    backgroundColor:
                        typeof isSelected !== "boolean" || isSelected
                            ? "white"
                            : "rgba(252,252,252,1)",
                },
                cursor: "pointer",
                textAlign: "left",
                position: "relative",
                border: "3px solid transparent",
                display: "flex",
                minHeight: 140,
                flexDirection: "column",
                justifyContent: "space-between",
                ...(isSelected && {
                    borderColor: "primary.main",
                }),
                ...props?.sx,
                //border: (theme) => isSelected ? `3px solid ${theme.palette.primary.main}` : '3px solid transparent'
            }}
        >
            {title !== undefined ? (
                <Typography
                    variant={"h5"}
                    align={"left"}
                    mr={4}
                    sx={{ color: (theme) => theme.palette.grey["600"] }}
                >
                    {title}
                </Typography>
            ) : (
                <>
                    <Skeleton width={120} />
                    <Skeleton width={80} />
                </>
            )}

            <Box alignItems={"baseline"} margin={"8px 0 0 0"} display={"flex"}>
                {value !== undefined ? (
                    <Typography variant={"h2"} mr={1}>
                        {value ?? 0}
                    </Typography>
                ) : (
                    <Skeleton height={48} width={90} />
                )}
            </Box>
        </Card>
    );
};
