import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Input } from "../../Input/Input";
import { Box, Button, Popover, PopoverProps, Stack } from "@mui/material";
import { ComponentProps, useEffect, useRef, useState } from "react";

type LinkPopoverProps = {
    link: string | undefined;
    anchorEl: PopoverProps["anchorEl"];
};

export const LinkPopover = NiceModal.create<LinkPopoverProps>(({ link, anchorEl }) => {
    const modal = useModal();

    const ref = useRef<HTMLInputElement>(null);

    const [input, setInput] = useState<string | undefined>(link);

    const [urlInvalid, setUrlInvalid] = useState(false);

    useEffect(() => {
        setTimeout(() => ref.current?.focus(), 0);
    }, []);

    const handleClose = () => {
        modal.remove();
    };

    const handleCancel = () => {
        modal.resolve(null);
        handleClose();
    };

    const handleSubmit: ComponentProps<"form">["onSubmit"] = (evt) => {
        evt.preventDefault();
        if (/^https?:\/\//.test(input ?? "")) {
            setUrlInvalid(false);
            modal.resolve(input);
            handleClose();
        } else {
            setUrlInvalid(true);
        }
    };

    return (
        <Popover
            open={modal.visible}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            slotProps={{
                paper: {
                    sx: {
                        py: 3,
                        px: 2,
                    },
                },
            }}
        >
            <Box
                component={"form"}
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    minWidth: 320,
                }}
            >
                <Input
                    inputRef={ref}
                    label={"Indsæt URL"}
                    fullWidth
                    value={input ?? ""}
                    error={urlInvalid}
                    helperText={urlInvalid && "Ugyldig URL. Skal starte med https://"}
                    onChange={(evt) => setInput(evt.target.value)}
                />
                <Stack direction={"row"} spacing={1} mt={2}>
                    <Button size={"small"} sx={{ mt: 1 }} onClick={handleCancel}>
                        Annuller
                    </Button>
                    <Button
                        size={"small"}
                        variant={"contained"}
                        sx={{ mt: 1 }}
                        type={"submit"}
                        disabled={!(input && input?.length > 0)}
                    >
                        Gem
                    </Button>
                </Stack>
            </Box>
        </Popover>
    );
});
