import { Page } from "../Components/Page/Page";
import { Button, Stack, Typography, Box, TypographyProps, BoxProps } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { ReactNode } from "react";
import { ShowForRole } from "../Components/AllowForRole/AllowForRole";

interface ICrudPage {
    title: string;
    titleProps?: TypographyProps;
    createButtonLabel?: string;
    filters?: ReactNode;
    children?: ReactNode;
    handleCreate?: () => void;
}

export const CrudPage = ({
    title,
    titleProps,
    filters,
    handleCreate,
    createButtonLabel = "Opret",
    children,
    ...props
}: ICrudPage & BoxProps) => {
    return (
        <Page maxWidth={1200} pb={10} {...props}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <Typography variant={"h2"} {...titleProps}>
                    {title}
                </Typography>
                {handleCreate && (
                    <ShowForRole roles={["admin"]}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Stack direction={"row"} spacing={1}>
                                {filters}
                            </Stack>
                            <Button
                                onClick={handleCreate}
                                variant={"contained"}
                                size={"large"}
                                color={"primary"}
                                startIcon={<AddRounded />}
                            >
                                {createButtonLabel}
                            </Button>
                        </Stack>
                    </ShowForRole>
                )}
            </Stack>
            <Box sx={{ paddingTop: 2, marginTop: 2 }}>{children}</Box>
        </Page>
    );
};
