import {Avatar, styled, ButtonBase, Typography, ButtonBaseProps, AvatarProps, Skeleton} from "@mui/material";
import {ArrowDropDownRounded, UnfoldMoreRounded} from "@mui/icons-material";

const Styled = styled(ButtonBase)({
    padding: 1,
    borderRadius: 10,
    '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)'
    }
})

export const getInitials = (label?: string) => {
    if (!label) return '';
    const parts = label.split(' ').filter((e,i) => i < 2);
    return parts.map(el => el.slice(0,1).toUpperCase());
}

export const AvatarButton = ({label, subtitle, isLoading, sx, AvatarProps, showAvatar = true, ...props}: ButtonBaseProps<any, { label?: string, showAvatar?: boolean, subtitle?: string, isLoading: boolean, AvatarProps?: AvatarProps }>) => {
    return (
        <Styled sx={{ padding: 1, pointerEvents: isLoading ? 'none' : 'initial', ...sx}} {...props}>
            {
                showAvatar && (
                    <Avatar sx={{ marginRight: 1, width: '32px', height: '32px', fontSize: '1.2em', fontWeight: '500'}} {...AvatarProps}>
                        {isLoading ? 'AA' : getInitials(label)}
                    </Avatar>
                )
            }
            <Typography variant={'body1'} mr={1.5} textAlign={'left'} color={'white'}>
                {isLoading ? <Skeleton width={120}/> : label}
                { isLoading ? <Skeleton height={14} width={48}/> : subtitle && <Typography sx={{ cursor: 'pointer' }} color={'#8E8E93'} variant={'body2'} lineHeight={1.3} display={'block'}>{subtitle}</Typography> }
            </Typography>
            <UnfoldMoreRounded htmlColor={'#8E8E93'}/>
        </Styled>
    )
}
