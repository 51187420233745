import {useTranslate} from "../../Hooks/useTranslate";
import {Box, Typography} from "@mui/material";

export const MobileBlocker = () => {
    const { t } = useTranslate('utils')

    return (
        <Box
            height={'100vh'}
            width={'100vw'}
            display={{ xs: 'flex', md: 'none' }}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            position={'fixed'}
            top={0}
            left={0}
            zIndex={100}
            sx={{
                backgroundColor: 'white'
            }}
        >
            <Box
                component={'img'}
                width={240}
                alt={'Illustration'}
                src={'/graphics/mobile-blocker-illustration.svg'}
            />
            <Typography
                p={1.5}
                mt={3}
                textAlign={'center'}
                whiteSpace={'pre-wrap'}
                lineHeight={2.2}
            >
                {t('mobileBlocker')}
            </Typography>
        </Box>
    )
}
