import {
    Avatar,
    Box,
    BoxProps,
    Card,
    Grid,
    GridProps,
    IconButton,
    MenuItemProps,
    styled,
    Typography,
} from "@mui/material";
import {
    DateRange,
    DeleteForeverRounded,
    EditRounded,
    EventOutlined,
    EventRepeatRounded,
    MoreVert,
    SvgIconComponent,
} from "@mui/icons-material";
import React, { FC, useMemo, useRef, useState } from "react";
import { Localized } from "../../Hooks/useService";
import { StatusChip, TStatus } from "../StatusChip";
import { useLocation, useNavigate } from "react-router-dom";
import { useEvent } from "../../Hooks/useEvent";
import {
    List as _List,
    AutoSizer as _AutoSizer,
    WindowScroller as _WindowScroller,
    ListProps,
    AutoSizerProps,
    WindowScrollerProps,
} from "react-virtualized";
import { useStore } from "../../Store/useStore";
import { TUser } from "../../Hooks/useUsers";
import NiceModal from "@ebay/nice-modal-react";
import { languagesOptions } from "../../Utils/config";
import { ConfirmDialog } from "../../Modals/ConfirmDialog";
import { EmptyScreen, EmptyScreenProps } from "../EmptyScreen/EmptyScreen";
import { ListSkeleton } from "../ListSkeleton/ListSkeleton";
import { useTranslate } from "../../Hooks/useTranslate";
import { format } from "date-fns";
import { ShowForRole } from "../AllowForRole/AllowForRole";
import { ListHeader } from "../ListHeader/ListHeader";
import { useListItemOptions } from "../../Hooks/useListItemOptions";
import { renderChannel } from "../BookingsList/BookingsList";
import { Assignees } from "../Assignees";
import { useLocalizer } from "../../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { ellipsisStyle } from "../../Utils/theme";

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const WindowScroller = _WindowScroller as unknown as FC<WindowScrollerProps>;

type OptionValue = {
    selectedOptionKey: string;
    value: string;
};

export type TShallowUser = {
    name: string;
    profilePicture: string;
    id: string;
};

export type TEvent<T extends string | { [key: string]: string | object } = string> = {
    headline?: Localized;
    isRecurring?: boolean;
    recurring: any;
    id: string;
    channels?: string[];
    serviceId: string;
    parentId?: string;
    status: TStatus;
    slots: {
        booked: number;
        total: number;
    };
    seatCount: OptionValue;
    bookings: any[];
    waitingList: number;
    assignees: T[];
    languages: Array<keyof Localized>;
    isSameDay?: boolean;
    startDateTime: string;
    endDateTime?: string;
    formattedLocation?: string;
};

export const renderRangeDate = (start?: string, end?: string) => {
    if (!start || !end) return "";
    const sd = new Date(start);
    const ed = new Date(end);

    if (sd.getMonth() === ed.getMonth() && sd.getFullYear() === ed.getFullYear()) {
        return `${format(sd, "d")}. - ${format(ed, "PPP")}`;
    }
    return `${format(sd, "dd")} - ${format(ed, "dd.MM.yyyy")}`;
};

const renderStatus = (el: TEvent<TUser>) => {
    if (new Date(el.startDateTime) < new Date()) return "done";
    if (el.status === "inactive") return "inactive";
    if (el.slots?.booked === el.slots?.total) return "fullyBooked";
    if (el?.slots?.booked > 0) return "booked";
    //if (el.parentId && el.parentId !== el.id) return 'edited';
    return "unbooked";
};

const columns: {
    key: string;
    label?: string;
    props?: GridProps;
    valueProps?: GridProps;
    value: (el: TEvent<TUser>, localize: ReturnType<typeof useLocalizer>) => any;
}[] = [
    {
        key: "none",
        props: {
            xs: 0.5,
            lg: 0.5,
        },
        value: (el) =>
            el.isRecurring ? (
                <EventRepeatRounded />
            ) : el.isSameDay ? (
                <EventOutlined />
            ) : (
                <DateRange />
            ),
        valueProps: {
            display: "flex",
            pl: { xs: 0, lg: 0.5 },
            alignItems: "center",
        },
    },
    {
        key: "date",
        label: "Dato",
        value: (el, localize) => {
            const d = !Boolean(el?.isSameDay)
                ? renderRangeDate(el?.startDateTime, el?.endDateTime)
                : format(new Date(el?.startDateTime ?? null), "dd.MM.yyy");
            if (!el.headline) {
                return d;
            }
            return (
                <>
                    <Typography
                        component={"span"}
                        fontWeight={"inherit"}
                        fontSize={"inherit"}
                        lineHeight={1.3}
                        display={"block"}
                    >
                        {d}
                    </Typography>
                    <Typography
                        fontSize={{ xs: "0.88em" }}
                        lineHeight={1}
                        component={"span"}
                        display={"block"}
                    >
                        {localize(el.headline)}
                    </Typography>
                </>
            );
        },
        valueProps: {
            fontWeight: 600,
        },
        props: {
            xs: 2.2,
            lg: 2.4,
            //sx: { ...ellipsisStyle, ...clamp(1) },
        },
    },
    {
        key: "time",
        label: "Tid",
        props: {
            xs: 1.6,
            lg: 1.3,
            pr: 1,
        },
        value: (el) =>
            `${format(new Date(el?.startDateTime), "HH:mm")}${
                el?.endDateTime ? ` - ${format(new Date(el.endDateTime), "HH:mm")}` : ""
            }`,
    },
    {
        key: "languages",
        label: "Sprog",
        props: {
            xs: 0.8,
        },
        valueProps: {
            fontSize: "1.3em",
        },
        value: (el) => el?.languages?.map((el) => languagesOptions[el].emoji).join(" "),
    },
    {
        key: "type",
        label: "Kanal",
        props: {
            xs: 0.8,
            lg: 1.6,
            pr: { xs: 0, lg: 1 },
        },
        valueProps: {
            fontSize: "1.3em",
        },
        value: (el) =>
            el?.channels?.map((m) =>
                renderChannel(
                    {
                        contactChannel: m,
                        location: el.formattedLocation,
                    },
                    true,
                    {
                        sx: {
                            ...ellipsisStyle,
                        },
                    },
                    true
                )
            ),
    },
    {
        key: "assignedGuide",
        label: "Guide",
        props: {
            xs: 2.5,
        },
        value: (el) =>
            el?.assignees?.length > 0 && (
                <Assignees
                    assignees={el.assignees}
                    fullName={false}
                    showName={el.assignees && el.assignees?.length <= 1}
                />
            ),
    },
    {
        key: "slots",
        label: "Deltagere",
        props: {
            xs: 1.2,
            lg: 1,
        },
        value: (el) => {
            return `${el.slots?.booked} / ${el.slots?.total}`;
        },
        valueProps: {
            fontWeight: 600,
        },
    },
    {
        key: "status",
        label: "Status",
        props: {
            xs: 1.8,
            lg: 1.2,
        },
        value: (el) => <StatusChip status={renderStatus(el) as TStatus} />,
    } /*,{
    key: 'waitingList',
    label: 'Venteliste',
    props: {
        xs: 0.5
    },
    value: (el) => el.waitingList ?? 0
}*/,
];

const StyledItem = styled(Grid)<any>({
    alignItems: "center",
    zIndex: 10,
    "&:hover": {
        backgroundColor: "rgb(252,252,252)",
    },
});

export const getMins = (date: Date | string = "") => {
    if (!date) return "";
    const d = typeof date === "string" ? new Date(date) : date;
    const h = d.getHours();
    const m = d.getMinutes();
    return h * 60 + m;
};

const options: {
    key: string;
    label: string;
    icon: SvgIconComponent;
    props?: Pick<MenuItemProps, "color">;
}[] = [
    {
        key: "edit",
        label: "Rediger",
        icon: EditRounded,
    },
    {
        key: "delete",
        label: "Slet",
        icon: DeleteForeverRounded,
        props: {
            color: "error",
        },
    },
];

export const EventsList = ({
    serviceId,
    handleCreate,
    emptyScreenProps,
    ...props
}: BoxProps & {
    serviceId?: string;
    emptyScreenProps?: Partial<EmptyScreenProps>;
    handleCreate: () => void;
    events?: TEvent[];
}) => {
    const { t } = useTranslate("events");

    const scrollContainerRef = useRef<HTMLDivElement>();

    const {
        eventsForService: { data: events, isLoading, isFetched },
        deleteEvent,
    } = useEvent(undefined, serviceId);

    const localize = useLocalizer();

    const handleSelectOption = async (key: string, index?: number) => {
        if (index === undefined) {
            return;
        }
        if (key === "edit") {
            await handleClick(index, true)();
        }
        if (key === "delete") {
            if (_events[index]?.slots?.booked > 0) {
                return NiceModal.show(ConfirmDialog, {
                    allowCancel: false,
                    headline: t("headline", "dialogs.deleteEventWithBookings"),
                    title: t("title", "dialogs.deleteEventWithBookings"),
                });
            }

            await NiceModal.show(ConfirmDialog, {
                headline: `Du er ved at slette denne begivenhed`,
                title: "Er du sikker på at slette?",
            }).then(() => {
                const { id } = _events[index];
                deleteEvent.mutate(id);
            });
        }
    };

    const { handleClickOptions, render: renderOptionsDialog } = useListItemOptions(
        options,
        handleSelectOption
    );

    const handleClick =
        (index: number, shouldEdit = false) =>
        async () => {
            const { startDateTime, endDateTime, id, isRecurring } = _events?.[index] ?? ({} as any);
            return navigate(`/event/${id}${shouldEdit ? "/edit" : ""}`, {
                state: {
                    returnUrl: location.pathname,
                    ...(isRecurring && {
                        startDateTime,
                        endDateTime,
                    }),
                },
            });
        };

    const navigate = useNavigate();
    const location = useLocation();

    const { setServiceEventsFiltering, serviceEventsFiltering } = useStore();

    const [selectedFilter, setSelectedFilter] = useState<string>(serviceEventsFiltering);

    const _events = useMemo(() => {
        return (
            (events?.filter((el) => {
                switch (selectedFilter) {
                    case "all":
                        return true;
                    case "future":
                        return new Date(el.startDateTime!) > new Date();
                    case "past":
                        return new Date(el.startDateTime!) < new Date();
                    case "active":
                        return el?.bookings?.length > 0 && new Date(el.startDateTime!) > new Date();
                }
                return true;
            }) as TEvent<TShallowUser>[]) ?? []
        );
    }, [selectedFilter, events]);

    const handleChangeFilter = (key: string) => {
        setSelectedFilter(key);
        setServiceEventsFiltering(key);
    };

    return (
        <Box
            {...props}
            flexGrow={1}
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <ListHeader
                title={t("title")}
                filterOptions={[
                    { key: "all", label: "Alle" },
                    { key: "active", label: "Aktive" },
                    { key: "future", label: "Kommende" },
                    { key: "past", label: "Afsluttet" },
                ]}
                onChangeFilter={handleChangeFilter}
                defaultSelectedFilter={"all"}
                mb={2}
            />

            <Grid container pl={"20px"} pr={"20px"} pb={1} pt={2} mb={1}>
                {columns.map((el) => (
                    <Grid
                        fontSize={"0.75em"}
                        fontWeight={600}
                        textTransform={"uppercase"}
                        key={el.key}
                        component={Typography}
                        item
                        {...el.props}
                    >
                        {el.key !== "none" && t(el.key, "utils.tables.header")}
                    </Grid>
                ))}
            </Grid>

            {renderOptionsDialog()}

            <Box sx={{ flexGrow: 1, overflowY: "auto", pr: 2 }} ref={scrollContainerRef}>
                <WindowScroller scrollElement={scrollContainerRef.current}>
                    {({ isScrolling, height, onChildScroll, scrollTop }) => (
                        <AutoSizer>
                            {({ width }) => (
                                <List
                                    autoHeight
                                    scrollTop={scrollTop}
                                    width={width}
                                    height={height}
                                    onScroll={onChildScroll}
                                    isScrolling={isScrolling}
                                    rowHeight={64}
                                    rowCount={_events?.length + 1 ?? 0}
                                    rowRenderer={({ key, index, style }) => {
                                        const event = _events?.[index] as any;
                                        if (!event) return null;
                                        return (
                                            <Box style={style} p={1}>
                                                <StyledItem
                                                    py={1}
                                                    px={1.5}
                                                    component={Card}
                                                    container
                                                    key={key}
                                                    onClick={handleClick(index)}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    {columns.map((el) => (
                                                        <Grid
                                                            fontSize={"0.88em"}
                                                            key={el.key}
                                                            item
                                                            {...el.props}
                                                            {...el.valueProps}
                                                            component={Typography}
                                                        >
                                                            {el.value?.(event, localize)}
                                                        </Grid>
                                                    ))}
                                                    <Grid item flexGrow={1} textAlign={"right"}>
                                                        <ShowForRole roles={["admin"]}>
                                                            <IconButton
                                                                size={"small"}
                                                                onClick={handleClickOptions(index)}
                                                            >
                                                                <MoreVert />
                                                            </IconButton>
                                                        </ShowForRole>
                                                    </Grid>
                                                </StyledItem>
                                            </Box>
                                        );
                                    }}
                                    noRowsRenderer={() => {
                                        if (isLoading || !isFetched) {
                                            return <ListSkeleton m={1.5} />;
                                        }
                                        return (
                                            <EmptyScreen
                                                imageSrc={"/empty-screens/event.png"}
                                                imageProps={{ width: 300, height: 280 }}
                                                maxWidth={600}
                                                title={t("emptyState.title")}
                                                direction={"row"}
                                                description={t("emptyState.description")}
                                                onClick={handleCreate}
                                                buttonLabel={t("emptyState.action")}
                                                pt={6}
                                                pb={5}
                                                {...emptyScreenProps}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>
            </Box>
        </Box>
    );
};
