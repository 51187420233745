import isObject from 'lodash.isobject'
import { DEFAULT_FORM_SEPARATOR } from './constants'
import merge from 'lodash.merge'
import { languagesOptions } from './config'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import isEmpty from 'lodash.isempty'
import { formatISO, differenceInSeconds, isYesterday, addDays } from 'date-fns'
import { TFunction } from 'i18next'
import { TranslateFunction } from '../Hooks/useTranslate'
import { createElement } from 'react'
import { TOptionItem } from './types'
import randomBytes from 'randombytes'
import { TLead } from '../Hooks/useLeads'
import { TStatus } from '../Components/StatusChip'
import { getLocalized, TBooking } from '../Hooks/useBookings'
import { asDate } from '@prismicio/helpers'

export const sortByLatestCreated = <T extends { created: string }>(items: T[]) => {
    return items.sort((a, b) => Number(new Date(b.created)) - Number(new Date(a.created)))
}
export const isEmptyObject = (obj: object) =>
    typeof obj === 'object' &&
    typeof obj !== null &&
    !Array.isArray(obj) &&
    Object.keys(obj).length === 0

export const toISODate = (date: Date) => {
    return formatISO(date).split('T')[0]
}

export const createId = () => randomBytes(16).toString('hex')

const renderDuration = (t: TFunction, ...params: string[]) => {
    const last = params.pop()
    if (params.length === 0) {
        return last ?? ''
    }
    if (params.length > 1) {
        return params.join(', ') + ` ${t('and', 'utils.generic')} ` + last
    }
    return [...params, last].join(', ')
}

export const getFirstName = (name: string) => {
    const [first] = name?.split(' ') ?? []
    return first ?? ''
}

export const isOfferExpired = (lead: TLead | undefined) => {
    return lead?.offer?.expiryDate && new Date(lead?.offer.expiryDate) < new Date()
}

export const getStatus = (el: TLead): TStatus => {
    if (el.status === 'closed') {
        return 'rejected'
    }
    if (el.offer?.receiptId) {
        return 'offerAccepted'
    }
    if (!el.offer) {
        if (isOrBeforeYesterday(el.created)) {
            return 'new'
        }
        return 'new-highlight'
    }
    if (isOfferExpired(el)) {
        return 'expired'
    }
    if (el.offer?.id && !el.offer.receiptId) {
        return 'pendingPayment'
    }
    return 'active'
}
export const renderDurationString = (t: TFunction, mins: number) => {
    const day = Math.floor(mins / 60 / 24)
    const h = (mins / 60) % 24
    const hour = Math.floor(h)
    const minute = Math.round(h / 60)
    const units = Object.entries({ day, hour, minute })
        .filter(([, f]) => f)
        .map(([key, count]) => {
            const plural = count > 1
            return `${count} ${t(`${key}` + (plural ? 's' : ''), 'utils')}`
        })
    return renderDuration(t, ...units)
}

const isOrBeforeYesterday = (date: string) => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)
    return new Date(date) <= yesterday
}

export const extractByPrefix = (obj: any, prefix: string) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        return key.startsWith(prefix)
            ? {
                  ...acc,
                  [key.split(prefix)[1]]: value,
              }
            : acc
    }, {})
}

export const cleanByPrefix = (obj: any, prefix: string) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        return key.startsWith(prefix)
            ? acc
            : {
                  ...acc,
                  [key]: value,
              }
    }, {})
}

export const getHoldbarCut = (value: number, takerate: number = 5) =>
    Math.round((value / 100) * takerate)

export const renderLocation = (props: { city: string; zipCode: number; address: string }) => {
    const { address, city, zipCode } = props ?? {}
    return address && city && zipCode ? `${address}, ${zipCode} ${city}` : ''
}

const getNestedKey = (obj: FieldErrors, key: string) => {
    if (isEmpty(obj)) return null
    let temp: { [key: string]: unknown } = obj as any
    for (const p of key.split('.')) {
        temp = temp?.[p] ?? (temp as any)
    }
    return temp
}

const unpackBySeparator = (separatedKey: string, value: string, obj: any = {}, sep = '__') => {
    const keys = separatedKey.split(sep)
    let output: Record<string, any> = {}
    let ref = output
    for (let i = 0; i < keys.length - 1; i++) {
        ref[keys[i]] = {}
        ref = ref[keys[i]]
    }
    ref[keys[keys.length - 1]] = value
    return output
}

export const flattenObject = (obj: Record<string, any>) => {
    let toReturn: Record<string, any> = {}
    for (const i in obj) {
        if (!obj.hasOwnProperty(i)) continue
        if (isObject(obj[i]) && !Array.isArray(obj[i])) {
            const flatObject = flattenObject(obj[i])
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue

                toReturn[i + DEFAULT_FORM_SEPARATOR + x] = flatObject[x]
            }
        } else {
            toReturn[i] = obj[i]
        }
    }
    return toReturn
}

export const pad = (val: number) => (val < 10 ? `0${val}` : `${val}`)

const transformFormState = (state: Record<string, string>) => {
    return Object.entries(state).reduce((acc, [key, value]) => {
        if (key.includes(DEFAULT_FORM_SEPARATOR)) {
            return merge(acc, unpackBySeparator(key, value))
        }
        return { ...acc, [key]: value }
    }, {} as Record<string, any>)
}

const getQueryParams = (query: string) => {
    if (!query) return {}
    return query
        .substring(1)
        .split('&')
        .reduce((acc, pair) => {
            const [key, value] = pair.split('=')
            return {
                ...acc,
                [key.trim()]: value.trim(),
            }
        }, {})
}

export const translateOptions = (
    t: TranslateFunction,
    key: string,
    options: { key: string; label: string }[],
) => {
    return options.map(el => ({
        ...el,
        label: t(`${key}.options.${el.key}.label`),
    }))
}

export const mapOptions = (items: TOptionItem[]) => {
    return items.map(({ width, height, icon, ...el }) => {
        return {
            ...el,
            icon: createElement('img', { alt: el.label, src: icon, width, height }),
        }
    })
}

export const anId = () => randomBytes(16).toString('hex')

export const renderLanguages = (langCodes: string[]) => {
    return (
        langCodes
            ?.map(code => languagesOptions[code]?.emoji)
            .filter(f => f)
            .join(' ') ?? ''
    )
}

export const wait = (ms: number) => {
    return new Promise(res => setTimeout(res, ms))
}

export const validUrl = (url: string) => {
    const input = document.createElement('input')
    input.type = 'url'
    input.value = url
    return input.checkValidity() && url.startsWith('https://')
}

export const validEmail = (email: string) => {
    const input = document.createElement('input')
    input.type = 'email'
    input.value = email
    return input.checkValidity()
}

export const uuid = () => randomBytes(16).toString('hex')

export const calculateExpiresIn = (
    start: Date,
    end: Date | string | undefined,
    additionalMinutes = 0,
) => {
    if (end === undefined) {
        return undefined
    }
    const e = new Date(end)
    e.setMinutes(e.getMinutes() + additionalMinutes)
    return Math.abs(differenceInSeconds(start, e))
}

export const renderGuestTypes = (booking?: TBooking, type?: string, language?: string) => {
    if (!booking) {
        return ''
    }
    let isSingle = true
    const enriched = Object.entries(booking?.items ?? {}).reduce((tickets, [id, count], i) => {
        const { name: n } = booking.variants?.find(v => id.endsWith(v.id)) ?? {}
        const name = getLocalized(n, language ?? 'da') ?? 'Standard'
        if (type && !id.startsWith(type)) {
            return tickets
        }
        if (i > 0 || count > 1) {
            isSingle = false
        }
        return {
            ...tickets,
            [name]: (tickets[name] ?? 0) + Number(count),
        }
    }, {} as any)
    if (isSingle) {
        return Object.keys(enriched)?.[0] ?? ''
    }
    return Object.entries(enriched)
        .map(([name, count]) => {
            return `${count} x ${name}`
        })
        .join(', ')
}
