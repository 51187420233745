import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    Skeleton,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Header } from "./Header";
import React, { useState } from "react";
import { useTranslate } from "../Hooks/useTranslate";
import { useMetaLogin } from "../Hooks/useMetaLogin";
import { subscribeAccounts } from "../Api";
import { toast } from "react-toastify";

export const MetaConnectAccountsDialog = NiceModal.create(
    ({ onClick }: { onClick: () => Promise<void> }) => {
        const { t } = useTranslate("dialogs.metaConnectAccounts");

        const [loading, setLoading] = useState(false);

        const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

        const modal = useModal();

        const { accounts } = useMetaLogin();
        const handleClose = () => {
            modal.remove();
        };

        const handleSubmit = async () => {
            setLoading(true);
            const { failed, subscribed } = await subscribeAccounts(Array.from(selectedIds));
            if (failed.length > 0) {
                toast.warning(
                    <Typography fontSize={"0.88em"} whiteSpace={"pre-wrap"}>
                        Kunne ikke forbinde følgende sider til Spiritworld:{"\n"}
                        <strong>{failed.map((f) => f.name).join(", ")}</strong>
                    </Typography>,
                    { autoClose: 5000 }
                );
            }
            handleClose();
            setLoading(false);
        };

        const handleChange =
            (id: string): FormControlLabelProps["onChange"] =>
            (_evt, checked) => {
                setSelectedIds((p) => {
                    if (checked) {
                        p.add(id);
                    } else {
                        p.delete(id);
                    }
                    return p;
                });
            };

        return (
            <Dialog open={modal.visible} maxWidth={"xs"}>
                <Box pl={4}>
                    <Header title={t("title")} onClose={handleClose} />

                    <Box pt={3} pb={4} pr={4}>
                        <Typography mb={2} whiteSpace={"pre-wrap"}>
                            {t("description")}
                        </Typography>

                        <FormGroup>
                            {accounts.data?.map((acc) => (
                                <FormControlLabel
                                    control={<Checkbox />}
                                    onChange={handleChange(acc.id)}
                                    label={acc.name}
                                />
                            )) ?? <Skeleton width={200} height={20} />}
                        </FormGroup>

                        <Button
                            sx={{ mt: 3 }}
                            variant={"contained"}
                            size={"large"}
                            disabled={loading}
                            onClick={handleSubmit}
                        >
                            {loading ? (
                                <CircularProgress color={"inherit"} size={20} sx={{ m: 0 }} />
                            ) : (
                                t("actions.primary")
                            )}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
    }
);
