import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useMemo } from "react";
import { Customer, useCustomers } from "./useCustomers";
import { createId, sortByLatestCreated } from "../Utils/helpers";
import { useLeads } from "./useLeads";
import { toast } from "react-toastify";

export type Item = {
    subject: "service" | "session" | "custom";
    subjectId?: string;
    variantId: string;
    serviceId: string;
    name: string;
    durationMinutes: number;
    quantity: number;
    amount: number;
};

export type Offer = {
    id?: string;
    created: string;
    items: Item[];
    description: string;
    assignee: string;
    price: {
        currency: string;
        taxId: string;
        totalAmount: number;
    };
    discount?: {
        rate: number;
        percentage: boolean;
        label?: string;
        id?: string;
        code?: string;
    };
    expiryDate?: string;
    customer: {
        id?: string;
        email: string;
        name: string;
        phone: string;
    };
    bookings?: { startDateTime: string; endDateTime: string; id: string }[];
    transaction?: {
        receiptId: string;
        timestamp: string;
        totalAmount: string;
        voucher?: {
            id: string;
            code: string;
        };
    };
    refund?: {
        timestamp: string;
        payment?: {
            id: string;
            amountRefunded: number;
            created: string;
            status: string;
        };
        voucher?: {
            id: string;
            amountRefunded: number;
        };
    };
};

type Booking = { startDateTime: string; id: string };

export type EnrichedOffer = {
    availableQuantity: number;
    totalQuantity: number;
    bookings?: Booking[];
    nextBooking?: Booking;
    firstItem: Item;
} & Offer;

const enrichOffer = (offer: Offer, customers: Customer[] | null | undefined): EnrichedOffer => {
    const { bookings = [] } = offer;
    const [nextBooking] = bookings
        ?.filter((f) => new Date(f.startDateTime) > new Date())
        .sort((a, b) => Number(new Date(a.startDateTime)) - Number(new Date(b.startDateTime)));

    const totalQuantity = offer.items.reduce(
        (t, i) => parseInt(i.quantity as unknown as string) + t,
        0
    );

    return {
        ...offer,
        firstItem: offer.items[0],
        ...(nextBooking && { nextBooking }),
        customer: {
            ...offer.customer,
            ...customers?.find((f) => f.id === offer.customer?.id),
        },
        totalQuantity,
        availableQuantity: totalQuantity - bookings.length,
    };
};

export const useOffers = (offerId?: string, customerId?: string) => {
    const queryClient = useQueryClient();

    const { LeadsQueryKey } = useLeads();
    const { customers } = useCustomers();

    const OffersQueryKey = ["offers"];
    const OffersByCustomerQueryKey = ["offers", "customer", customerId];
    const SingleOfferQueryKey = ["offer", offerId];

    const offers = useQuery<EnrichedOffer[]>(
        OffersQueryKey,
        async () => {
            await queryClient.cancelQueries(OffersQueryKey);
            const data = await api.getOffers();
            return sortByLatestCreated(
                data.map((el) => {
                    const enriched = enrichOffer(el, customers.data);
                    queryClient.setQueryData(["offer", el.id], enriched);
                    return {
                        ...enriched,
                    };
                })
            );
        },
        {
            enabled: Boolean(customers.data),
        }
    );

    const offersByCustomer = useQuery<EnrichedOffer[]>(
        OffersByCustomerQueryKey,
        async () => {
            await queryClient.cancelQueries(OffersByCustomerQueryKey);
            const data = await api.getOffersByCustomerId(customerId as string);
            return sortByLatestCreated(data.map((el) => enrichOffer(el, customers.data)));
        },
        {
            enabled: Boolean(customers.data && customerId),
        }
    );

    const offer = useQuery<EnrichedOffer>(
        SingleOfferQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleOfferQueryKey);
            return enrichOffer(await api.getOffer(offerId as string), customers.data);
        },
        {
            enabled: Boolean(offerId),
        }
    );

    const enrichedOffer = useMemo(() => {
        if (offer.data) {
            return offer.data;
        }
    }, [offer]);

    const updateOffer = useMutation(
        ({
            id,
            shouldCreate = false,
            ...payload
        }: Omit<Offer, "created"> & { shouldCreate?: boolean }) => {
            if (shouldCreate || !id) {
                return api.createOffer({ ...payload, ...(id && { id }) });
            }
            return api.updateOffer(id, { ...payload });
        },
        {
            onMutate: async ({ id = createId(), ...data }) => {
                const payload = { created: new Date().toISOString(), ...data };

                const singleQueryKey = ["offer", id];

                await queryClient.cancelQueries(OffersQueryKey);

                const previous = queryClient.getQueryData<Offer>(singleQueryKey);

                queryClient.setQueryData<Offer[]>(OffersQueryKey, (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<Offer>(singleQueryKey, (prev) => {
                    return { ...prev!, ...payload };
                });

                return { previous };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<Offer>(["offer", variables.id], context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(OffersQueryKey),
                    queryClient.invalidateQueries(LeadsQueryKey),
                ]);
            },
        }
    );

    const deleteOffer = useMutation((id: string) => api.deleteOffer(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(OffersQueryKey);

            queryClient.setQueryData<Offer[]>(["offers"], (prev) => {
                return prev?.filter((el) => el.id !== id) ?? [];
            });

            queryClient.setQueryData<Offer | undefined>(["offer", id], undefined);
        },
        onSettled: async (data, err, id, context) => {
            await Promise.all([
                queryClient.invalidateQueries(OffersQueryKey),
                queryClient.invalidateQueries(LeadsQueryKey),
            ]);
        },
    });

    const refundOffer = useMutation(() => api.refundOffer(offerId!), {
        onMutate: async () => {
            const payload = { refund: { timestamp: new Date().toISOString() } };

            await queryClient.cancelQueries(OffersQueryKey);

            const previous = queryClient.getQueryData<Offer>(SingleOfferQueryKey);

            queryClient.setQueryData<Offer[]>(OffersQueryKey, (prev) => {
                return (
                    prev?.map((el) => {
                        return el.id === offerId ? { ...el, ...payload } : el;
                    }) ?? []
                );
            });

            queryClient.setQueryData<Offer>(SingleOfferQueryKey, (prev) => {
                return { ...prev!, ...payload };
            });

            return { previous };
        },
        onError: (err, variables, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<Offer>(SingleOfferQueryKey, context.previous);
            }
        },
        onSettled: async (data, err, variables, context: any) => {
            await Promise.all([
                queryClient.invalidateQueries(OffersQueryKey),
                queryClient.invalidateQueries(LeadsQueryKey),
            ]);
        },
    });

    const resend = async (id?: string) => {
        await toast.promise(api.resendOffer(offerId ?? id), {
            pending: "Gensender tilbud...",
            success: "Tilbuddet er blevet gensendt og betalingsfristen forlænget.",
            error: "Hov, der skete en fejl. Prøv igen.",
        });
        return await queryClient.invalidateQueries(LeadsQueryKey);
    };

    return {
        offer,
        offers,
        offersByCustomer,
        enrichedOffer,
        deleteOffer,
        updateOffer,
        refundOffer,
        resend,
    };
};
