import { Avatar, Grid, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import { useUsers } from "../../../Hooks/useUsers";
import { Tip } from "../../../Components/Tip/Tip";
import { CustomChip } from "../../../Components/CustomChip/CustomChip";
import { DoneRounded } from "@mui/icons-material";
import { ErrorLabel } from "../../SyiPage/ErrorLabel";
import { useErrors } from "../../SyiPage/useErrors";
import { useTranslate } from "../../../Hooks/useTranslate";
import { stripGuides } from "../../../Hooks/useEvent";

export const EventSyiSectionGuides = () => {
    const { t } = useTranslate("events.create.guides");

    const { field: assignees } = useController({ name: "assignees", defaultValue: [] });

    const assignedIds = stripGuides(assignees.value);

    const { getError, clearError } = useErrors();

    const handleToggleGuide = (id: string) => () => {
        if (assignedIds.includes(id)) {
            assignees.onChange(assignedIds.filter((f) => f !== id));
        } else {
            assignees.onChange([...assignedIds, id]);
        }
        clearError("assignedGuides");
    };

    const handleNoGuide = () => {
        assignees.onChange([]);
    };

    const {
        users: { data: users },
    } = useUsers();

    return (
        <>
            <Typography variant={"h4"}>{t("title")}</Typography>
            <Typography variant={"h5"} mt={2} mb={2}>
                {t("subtitle")}
                <ErrorLabel label={getError("assignedGuides")} />
            </Typography>
            <Tip label={t("tip")} />

            <Grid container gap={1} mt={3}>
                <Grid
                    item
                    component={CustomChip}
                    label={t("noGuide")}
                    onClick={handleNoGuide}
                    selected={assignedIds.length === 0}
                    deleteIcon={<DoneRounded />}
                    sx={{ height: 48, borderRadius: 100 }}
                />
                {users?.map((el) => {
                    return (
                        <Grid
                            item
                            component={CustomChip}
                            avatar={<Avatar alt={el.name} src={el.pictures?.profile?.url} />}
                            label={el.name}
                            onClick={handleToggleGuide(el.id)}
                            selected={assignedIds.includes(el.id)}
                            deleteIcon={<DoneRounded />}
                            sx={{ height: 48, borderRadius: 100 }}
                        />
                    );
                })}
            </Grid>
        </>
    );
};
