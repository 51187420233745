import { useTranslate } from "../Hooks/useTranslate";
import { useFunnelMetrics } from "../Hooks/useMetrics";
import { Grid, Skeleton, Tooltip, Typography } from "@mui/material";

export const FunnelMetrics = ({ id, type }: { id: string; type: "service" | "funnel" }) => {
    const { t } = useTranslate("utils.metrics");

    const {
        metrics: { data: metrics, isLoading },
    } = useFunnelMetrics(type, id);

    if (isLoading) {
        return <Skeleton width={72} />;
    }
    return (
        <Grid
            display={"inline-grid"}
            width={"auto"}
            gap={0.5}
            container
            gridTemplateColumns={`repeat(${metrics?.length ?? 4}, minmax(0, 1fr))`}
        >
            {metrics?.map((el) => (
                <Grid
                    item
                    sx={{
                        display: "flex",
                        backgroundColor: "grey.100",
                        justifyContent: "center",
                        borderRadius: 1,
                        padding: "3px 5px",
                    }}
                >
                    <Tooltip title={t(el.key)}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "0.88em",
                                color: "#636366",
                            }}
                        >
                            {el.value ?? 0}
                        </Typography>
                    </Tooltip>
                </Grid>
            ))}
        </Grid>
    );
};
