import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Card,
    CircularProgress,
    Stack,
    styled,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import {
    Calendar,
    CalendarProps,
    dateFnsLocalizer,
    Event,
    EventPropGetter,
    EventProps,
    HeaderProps,
    Navigate,
    SlotInfo,
    ToolbarProps,
    View,
} from "react-big-calendar";
//import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import { format, parse, startOfWeek, getDay, endOfWeek, Interval } from "date-fns";
import { da, enGB } from "date-fns/locale";
import React, { memo, SyntheticEvent, useLayoutEffect, useRef, useState } from "react";
import "../../customCalendarStyles/styles.css";
import { getColor } from "../../Hooks/useEvent";
import { getFirstName, renderLanguages } from "../../Utils/helpers";
import {
    BlockRounded,
    ChevronLeftRounded,
    ChevronRightRounded,
    EventBusyRounded,
    PeopleAltRounded,
    PhoneRounded,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useTranslate } from "../../Hooks/useTranslate";
import NiceModal from "@ebay/nice-modal-react";
import { CalendarPreview } from "../../Modals/CalendarPreview";
import { CalendarEvent, useCalendarEvents } from "../../Hooks/useCalendarEvents";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { OnlineIcon } from "../../Icons";
import { useServiceSelect } from "../../Components/ServiceSelect";
import { useUserSelect } from "../../Components/AssigneeSelect";
import { CreateNewBookingDialog } from "../../Modals/CreateNewBookingDialog/CreateNewBookingDialog";
import { useProfile } from "../../Hooks/useProfile";
import { Assignees } from "../../Components/Assignees";

//const DnDCalendar = withDragAndDrop(Calendar);

const locales = {
    "en-US": enGB,
    "en-GB": enGB,
    da,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const StyledNavigateGroup = styled(ButtonGroup)({
    "& .MuiButtonGroup-grouped": {
        backgroundColor: "#F4F4F5",
        borderColor: "white",
        color: "#18181B",
        margin: 0,
        "&:hover": {
            borderColor: "white",
            backgroundColor: "#F1F1F1",
        },
    },
});

const StyledTabs = styled(Tabs)`
    min-height: 40px;
    max-height: 40px;
    & .MuiTab-root {
        min-height: 12px;
        line-height: 1;
        font-size: 0.88em;
        font-weight: bold;
        min-width: 56px;
        border-radius: 12px;
        &.Mui-selected {
            color: white;
            z-index: 2;
        }
    }
    & .MuiTabs-indicator {
        border: 0px solid ${({ theme }) => theme.palette.primary.main};
        background-color: ${({ theme }) => theme.palette.primary.main};
        z-index: 0;
        color: white;
        border-radius: 16px;
    }
`;

const lang = {
    en: undefined,
    "en-GB": undefined,
    da: {
        week: "Uge",
        work_week: "Arbejdsuge",
        day: "Dag",
        month: "Måned",
        previous: "Tilbage",
        next: "Næste",
        today: `I dag`,
        agenda: "Oversigt",

        showMore: (total: number) => `+${total} flere`,
    },
};

const CustomToolbar = ({
    localizer,
    loading = false,
    onNavigate,
    views,
    view,
    onView,
    label,
}: ToolbarProps & { loading?: boolean }) => {
    const { t } = useTranslate("utils.generic");

    return (
        <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            p={1.5}
            alignItems={"center"}
        >
            <StyledNavigateGroup size={"small"} variant="outlined" sx={{ width: "35%" }}>
                <Button onClick={() => onNavigate(Navigate.PREVIOUS)}>
                    <ChevronLeftRounded />
                </Button>
                <Button onClick={() => onNavigate(Navigate.TODAY)}>
                    {localizer.messages.today}
                </Button>
                <Button onClick={() => onNavigate(Navigate.NEXT)}>
                    <ChevronRightRounded />
                </Button>
                {loading && (
                    <Box display={"flex"} ml={2} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress size={"1em"} />
                        <Typography ml={1.5}>{t("loadingEvents")}</Typography>
                    </Box>
                )}
            </StyledNavigateGroup>

            <Typography variant={"h4"} fontWeight={500} textTransform={"capitalize"}>
                {label}
            </Typography>

            <Box width={"35%"} display={"flex"} justifyContent={"flex-end"}>
                <StyledTabs value={view} onChange={(evt, value) => onView(value)}>
                    {(views as View[]).map((key, index) => (
                        <Tab key={key} label={localizer.messages?.[key]} value={key} />
                    ))}
                </StyledTabs>
            </Box>
        </Box>
    );
};

const CustomHeader = memo(({ date, label, localizer }: HeaderProps) => {
    return (
        <Box p={1} textAlign={"left"}>
            <Typography variant={"body2"} textTransform={"uppercase"} fontWeight={"bold"}>
                {localizer.format(date, "E")}
            </Typography>
            <Typography variant={"h3"} className={"rbc-header-date-label"} fontWeight={600}>
                {localizer.format(date, "d")}
            </Typography>
        </Box>
    );
});

const CustomEvent = ({ title, event, slotStart, slotEnd }: EventProps) => {
    return (
        <Box p={0}>
            <Box display={"flex"} alignItems={"center"} fontSize={"0.88em"}>
                {renderLanguages(event.resource.languages)}
                <Box component={"span"} mr={0.25} fontSize={"1.2em"}>
                    {renderChannels(event.resource.channels)}
                    {event.resource.type === "blocker" ? (
                        <Box display={"flex"} alignItems={"center"}>
                            <BlockRounded fontSize={"inherit"} sx={{ mr: 0.5 }} />
                            {event.resource.assignees
                                ?.map((el: { name: string }) => getFirstName(el?.name ?? ""))
                                ?.join(", ")}
                        </Box>
                    ) : event.resource.type === "external" ? (
                        <Box display={"flex"} alignItems={"center"}>
                            <EventBusyRounded fontSize={"inherit"} sx={{ mr: 0.5 }} />
                            {event.resource.assignees
                                ?.map((el: { name: string }) => getFirstName(el?.name ?? ""))
                                ?.join(", ")}
                        </Box>
                    ) : (
                        <PeopleAltRounded sx={{ ml: 0.5 }} opacity={0.7} fontSize={"inherit"} />
                    )}
                </Box>
                {event.resource.slots &&
                    `${event.resource.slots?.booked}/${event.resource.slots?.total}`}
            </Box>
            <Typography fontSize={"1em"}>{title}</Typography>
        </Box>
    );
};

const CustomMonthEvent = ({ title, event }: EventProps) => {
    return (
        <Box
            sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontWeight: "inherit",
                fontSize: "inherit",
                lineHeight: 1.2,
                display: "flex",
                alignItems: "center",
                transform: "translateY(-2px)",
            }}
        >
            <Box component={"span"} pr={0.5}>
                {title}
            </Box>{" "}
            <Box component={"span"} mr={0.25} fontSize={"1.2em"}>
                {renderChannels(event.resource.channels)}
                {event.resource.type === "blocker" ? (
                    <Box display={"flex"} alignItems={"center"}>
                        <BlockRounded fontSize={"inherit"} sx={{ mr: 0.5 }} />
                        {event.resource.assignees
                            ?.map((el: { name: string }) => getFirstName(el?.name ?? ""))
                            ?.join(", ")}
                    </Box>
                ) : (
                    <PeopleAltRounded sx={{ ml: 0.5 }} opacity={0.7} fontSize={"inherit"} />
                )}
            </Box>
        </Box>
    );
};

const renderChannels = (channels: string[]) => {
    return channels?.map((el) => {
        if (el === "byPhone") {
            return <PhoneRounded htmlColor={"inherit"} fontSize={"inherit"} />;
        }
        if (el === "online") {
            return <OnlineIcon htmlColor={"inherit"} fontSize={"inherit"} />;
        }
        return null;
    });
};

const purpleGradient =
    "linear-gradient(162deg, #933FE7 8.20%, #722FDE 44.59%, #5B2CE1 71.84%, #322FDE 100%)";

const borderGradient = {
    border: "10px solid",
    borderImageSlice: "1",
    borderWidth: "5px",
    borderImageSource: purpleGradient,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
};

const leadStyle = {
    color: "white",
    background: purpleGradient,
    border: "none",
};

const unBookedEventStyle = {
    color: "rgba(0,0,0,0.35)",
    backgroundColor: "#F4F4F5",
    border: `1px dashed rgba(0,0,0,0.35)`,
};

const propGetter: EventPropGetter<any> = ({ resource: { colors, hasBookings, type } }) => {
    if (type === "lead") {
        return {
            style: { ...leadStyle },
        };
    }
    if (type === "event" && !hasBookings) {
        return {
            style: unBookedEventStyle,
        };
    }

    return {
        style: { ...colors },
    };
};

const mapEvents = (events: CalendarEvent[] | undefined, myId?: string): Event[] => {
    return (
        events?.map((el, i) => {
            const uniqueServices = Array.from(new Set(events.map((el) => el.serviceId)));
            return {
                start: new Date(el.startDateTime),
                end: new Date(el.endDateTime),
                title: el.title,
                resource: {
                    hasBookings: el.slots && el.slots?.booked > 0,
                    slots: el.slots,
                    type: el.type,
                    id: el.id,
                    deepLink: el.deepLink,
                    isEditable:
                        (el.type === "blocker" && el.assignees?.some((el) => el?.id === myId)) ||
                        (el.sessionId && el.source === "manual"),
                    serviceId: el.serviceId,
                    index: i,
                    channels: el.channels,
                    assignees: el.assignees,
                    colors:
                        el.type === "lead"
                            ? { ...leadStyle, border: borderGradient }
                            : getColor(uniqueServices.findIndex((un) => un === el.serviceId) ?? 0),
                },
                isDraggable: true,
            };
        }) ?? []
    );
};

export const DashboardCalendarSection = ({
    onChangeEvent,
    ...props
}: BoxProps & {
    onChangeEvent?: (data: any, cancelFunc: () => void) => void;
}) => {
    const ref = useRef<Calendar>(null);

    const {
        me: { data: me },
    } = useProfile();

    const { i18n, t } = useTranslation();

    const setCurrentWeekInterval = (v: View) => {
        if (v === "week") {
            return {
                start: startOfWeek(new Date()),
                end: endOfWeek(new Date()),
            };
        }
    };

    const [view, setView] = useState<View>("week");
    const [currentInterval, setCurrentInterval] = useState<Interval | undefined>(
        setCurrentWeekInterval(view)
    );

    const { render: renderServiceSelect, serviceId } = useServiceSelect();
    const { render: renderUserSelect, userId } = useUserSelect();

    const { events, min, max, loading } = useCalendarEvents(
        undefined,
        serviceId,
        userId,
        currentInterval
    );

    const handleNavigate: CalendarProps["onNavigate"] = (newDate, view, action) => {
        if (view === "week") {
            const start = startOfWeek(newDate);
            const end = endOfWeek(newDate);
            setCurrentInterval({ start, end });
        }
    };

    const handleClickEvent = (event: Event, evt: SyntheticEvent<HTMLElement>) => {
        if (event.resource.isEditable) {
            NiceModal.show(CreateNewBookingDialog, {
                anchorEl: ref.current,
                start: event.start,
                end: event.end,
                bookingId: event.resource.id,
                serviceId: event.resource.serviceId,
            });
        } else if (event.resource.type === "external") {
            if (event.resource.deepLink) {
                return window.open(event.resource.deepLink, "_blank");
            }
        } else if (event.resource.type !== "blocker") {
            NiceModal.show(CalendarPreview, {
                anchorEl: evt.currentTarget,
                colors: event.resource.colors,
                eventId: event.resource.id,
            });
        }
    };

    useLayoutEffect(() => {
        if (events && ref.current) {
            const _container = document.getElementsByClassName("rbc-time-content")?.[0] as any;
            const _header = document.getElementsByClassName("rbc-time-header")?.[0] as any;
            if (_container && _header) {
                const scrollBarWidth = _container.offsetWidth - _container.clientWidth;
                _header.style.marginRight = scrollBarWidth + "px";
            }
        }
    }, [events]);

    const handleSelectRange: CalendarProps["onSelectSlot"] = ({ start, end, ...props }) => {
        NiceModal.show(CreateNewBookingDialog, {
            anchorEl: ref.current,
            start,
            end,
        });
    };

    const handleChangeView: CalendarProps["onView"] = (newView) => {
        setCurrentInterval(newView === "week" ? setCurrentWeekInterval(newView) : undefined);
        setView(newView);
    };

    const handleCreateManualBooking = () => {
        const start = new Date();
        start.setHours(start.getHours() + 1, 0, 0, 0);
        const end = new Date(start);
        end.setHours(end.getHours() + 1, 0, 0, 0);
        handleSelectRange({ start, end } as SlotInfo);
    };

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                <Stack spacing={2} direction={"row"}>
                    {renderUserSelect({
                        inputLabel: "Facilitator",
                        thumbnailProps: { width: 20, height: 20 },
                        allLabel: t("utils.generic.all", { name: t("utils.generic.facilitators") }),
                    })}
                    {renderServiceSelect({
                        inputLabel: "Ydelse",
                        thumbnailProps: { width: 20, height: 20 },
                        allLabel: t("utils.generic.all", { name: t("utils.generic.services") }),
                    })}
                    {loading && (
                        <Box display={"flex"} alignItems={"center"}>
                            <CircularProgress size={"1.2em"} />
                            <Typography ml={1} color={"grey.600"}>
                                Henter...
                            </Typography>
                        </Box>
                    )}
                </Stack>
                <Button
                    size={"large"}
                    onClick={handleCreateManualBooking}
                    variant={"contained"}
                    color={"primary"}
                >
                    Opret aftale
                </Button>
            </Stack>
            <Box
                component={Card}
                height={"100%"}
                width={"100%"}
                data-view={view}
                flexGrow={1}
                p={0}
                {...props}
            >
                {events ? (
                    <Calendar
                        ref={ref}
                        defaultView="week"
                        views={["month", "week", "day"]}
                        onSelectEvent={handleClickEvent}
                        onView={handleChangeView}
                        eventPropGetter={propGetter}
                        onNavigate={handleNavigate}
                        events={mapEvents(events, me?.id)}
                        min={min}
                        max={max}
                        localizer={localizer}
                        messages={lang[i18n.language as keyof typeof lang]}
                        components={{
                            toolbar: CustomToolbar,
                            header: CustomHeader,
                            week: {
                                event: CustomEvent,
                            },
                            month: {
                                event: CustomMonthEvent,
                            },
                        }}
                        culture={i18n.language}
                        selectable
                        onSelectSlot={handleSelectRange}
                        style={{ height: "75vh" }}
                    />
                ) : (
                    <SimpleSkeleton p={4} />
                )}
            </Box>
        </>
    );
};
