import NiceModal from "@ebay/nice-modal-react";
import { ConfirmDialog } from "../Modals/ConfirmDialog";

export const useConfirmDialog = (defaultHeadline: string, defaultTitle: string) => {
    const confirm = async (headline = defaultHeadline, title = defaultTitle) => {
        try {
            return await NiceModal.show(ConfirmDialog, {
                headline,
                title,
            });
        } catch (err) {
            return false;
        }
    };

    return {
        confirm,
    };
};
