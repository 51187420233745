import React from "react";
import { Chip, ChipProps, styled } from "@mui/material";
import { useTranslate } from "../Hooks/useTranslate";

export const states = {
    created: ["Oprettet", "default"],
    active: ["Aktiv", "success"],
    "new-highlight": ["Ny", "primary"],
    new: ["Ny", "default"],
    draft: ["Kladde", "default"],
    expired: ["Udløbet", "default"],
    unbooked: ["Ikke booket", "default"],
    done: ["Afsluttet", "default"],
    moved: ["Flyttet", "default"],
    inactive: ["Inaktiv", "warning"],
    archived: ["Arkiveret", "default"],
    pending: ["Afventer", "warning"],
    paid: ["Betalt", "success"],
    unpaid: ["Afventer betaling", "warning"],
    refunded: ["Refunderet", "error"],
    cancelled: ["Aflyst", "error"],
    closed: ["Lukket", "error"],
    rejected: ["afvist", "error"],
    fullyBooked: ["Fuldt booket", "success"],
    booked: ["Booket", "success"],
    invited: ["Inviteret", "default"],
    pendingPayment: ["Afventer betaling", "warning"],
    offerAccepted: ["Tilbud købt", "success"],
    loading: ["Indlæser...", "default"],
} as const;

export type TStatus = keyof typeof states;

const StyledChip = styled(Chip)({
    "&.MuiChip-colorDefault": {
        backgroundColor: "#E3E3E3",
    },
});

export const StatusChip = ({ status, color, label, ...props }: ChipProps & { status: TStatus }) => {
    const { t } = useTranslate("utils.statusOptions");
    return (
        <StyledChip
            color={(states?.[status]?.[1] as any) ?? "default"}
            label={t(status)}
            {...props}
        />
    );
};
