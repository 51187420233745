import { useNavigate } from "react-router-dom";
import { CrudPage } from "./CrudPage";
import React from "react";
import { DashboardCalendarSection } from "../Sections/DashboardSections/DashboardCalendarSection";
import { useTranslate } from "../Hooks/useTranslate";

export const CalendarPage = () => {
    const { t } = useTranslate("calendar");

    const navigate = useNavigate();

    return (
        <CrudPage title={t("title")} pb={4}>
            <DashboardCalendarSection />
        </CrudPage>
    );
};
