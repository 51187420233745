import {
    Alert,
    AlertProps,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTranslate } from "../../../Hooks/useTranslate";
import { useErrors } from "../../SyiPage/useErrors";
import { EditableFunnel, getFunnelUrl, useFunnels } from "../../../Hooks/useFunnels";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkDomain } from "../../../Api";
import { useQuery } from "react-query";
import {
    CheckRounded,
    EditRounded,
    ErrorRounded,
    ReportGmailerrorredRounded,
} from "@mui/icons-material";

export const FunnelSyiSectionDomain = () => {
    const { t } = useTranslate("funnel.create.domain");

    const { isCreateFlow, id } = useOutletContext<{ isCreateFlow: boolean; id: string }>();

    const [allowEdit, setAllowEdit] = useState(isCreateFlow);

    const { control } = useFormContext<EditableFunnel>();

    const {
        funnel: { data: funnel },
    } = useFunnels(id);

    const { field: alias } = useController({ name: "alias", control, rules: { required: true } });

    const { clearError, getError } = useErrors();

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{t("title")}</Typography>
            </Stack>

            <Stack mt={3}>
                <TextField
                    disabled={!allowEdit}
                    label={t("alias")}
                    sx={{ minWidth: 320, maxWidth: 320 }}
                    error={Boolean(getError("alias"))}
                    helperText={getFunnelUrl(alias.value ?? "<alias>")}
                    InputProps={{
                        endAdornment: !allowEdit && (
                            <InputAdornment position="end">
                                <IconButton
                                    size={"small"}
                                    aria-label="edit alias"
                                    onClick={() => setAllowEdit(true)}
                                    edge="end"
                                >
                                    {<EditRounded />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...alias}
                />
                {funnel?.domain && <DomainStatus sx={{ mt: 2 }} domain={funnel.domain} />}
            </Stack>
        </>
    );
};

const DomainStatus = ({ domain, ...props }: { domain: string } & AlertProps) => {
    const [valid, setValid] = useState<boolean>();

    const domainStatus = useQuery(["domains", domain], () => checkDomain(domain), {
        enabled: Boolean(domain && !valid),
        refetchInterval: 10000,
    });

    useEffect(() => {
        if (domainStatus.data) {
            const { verified, configured } = domainStatus.data;
            setValid(verified && configured);
        }
    }, [domainStatus.data]);

    return (
        <Alert
            icon={
                domainStatus.isLoading ? (
                    <CircularProgress color={"primary"} size={"1rem"} />
                ) : valid ? (
                    <CheckRounded fontSize="inherit" />
                ) : (
                    <ReportGmailerrorredRounded fontSize={"inherit"} />
                )
            }
            severity={domainStatus.isLoading ? "info" : valid ? "success" : "warning"}
            {...props}
        >
            {domainStatus.isLoading
                ? "Tjekker status på domæne..."
                : valid
                ? "Domænet er korrekt sat op. Der kan dog gå op til flere timer, før DNS'en virker, som den skal."
                : "Domænet er endnu ikke sat korrekt op - dette sker dog automatisk. Tjekker igen om lidt."}
        </Alert>
    );
};
