import { Customer } from "./useCustomers";
import { useBookings } from "./useBookings";
import { useEvent } from "./useEvent";
import { IService, useService } from "./useService";
import { useLeads } from "./useLeads";
import { useUsers } from "./useUsers";
import {
    LocalizeFunc,
    useLocalizer,
} from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { useQuery } from "react-query";
import { useMemo, useState } from "react";
import { getMins } from "../Components/IntervalController/IntervalController";
import { addMinutes, Interval, isWithinInterval } from "date-fns";
import { ICompanyInfo, useProfile } from "./useProfile";
import { useSession } from "./useSession";

export type CalendarEvent = {
    id: string;
    slots?: {
        booked: number;
        total: number;
    };
    deepLink?: string;
    channels: string[];
    title: string;
    image: string;
    type: "lead" | "booking" | "event" | string;
    startDateTime: string;
    endDateTime: string;
    location?: string;
    meetingLink?: string;
    serviceId: string;
    sessionId?: string;
    source: string;
    assignees: {
        name?: string;
        email?: string;
        id?: string;
    }[];
    customer?: Pick<Customer, "name" | "email" | "phone">;
    description: string;
    allowDirectDelete?: boolean;
};

const applyServiceDetails = (service: Service, localize: LocalizeFunc) => {
    return {
        serviceId: service.id,
        title: localize(service.headline),
        image: service.pictures[0]?.url,
        description: localize(service.description),
    };
};

type Service = Omit<IService, "id"> & { id: string };

const getServiceLocation = (service: Service, company: Required<ICompanyInfo>) => {
    if (service.locations.selectedOptionKey === "companyAddress") {
        return `${company.location.address}, ${company.location.zipCode} ${company.location.city}`;
    }
    return service.locations?.value;
};

export const useCalendarEvents = (
    eventId?: string | undefined,
    serviceId?: string,
    userId?: string,
    currentInterval?: Interval
) => {
    const { bookings } = useBookings();
    const { events } = useEvent();
    const { getService, services } = useService();
    const { leads } = useLeads();
    const { externalBookings } = useSession();
    const { getUser } = useUsers();
    const { company } = useProfile();
    const [loading, setLoading] = useState(true);

    const localize = useLocalizer();

    const SingleQueryKey = ["calendar", "event", eventId];

    const calendarEvents = useMemo<CalendarEvent[]>(() => {
        if (!(services.data && bookings.data && events.data && leads.data && company.data)) {
            return [];
        }

        setLoading(true);

        const mappedLeads =
            leads.data
                ?.filter(
                    (f) =>
                        Boolean(f.booking) &&
                        typeof f.booking.id !== "string" &&
                        f.status !== "rejected" &&
                        f.status !== "closed"
                )
                .map((lead) => {
                    const service = getService(lead.serviceId) as Service;
                    if (!service) {
                        return null;
                    }
                    const assignees = service.assignees.map(getUser);
                    return {
                        ...applyServiceDetails(service, localize),
                        id: lead.id,
                        type: "lead",
                        assignees,
                        ...(lead.channel === "inPerson" && {
                            location: getServiceLocation(service, company.data),
                        }),
                        ...(lead.channel === "online" && {
                            meetingLink: service.meetingLink,
                        }),
                        channels: [lead.channel],
                        slots: {
                            booked: 1,
                            total: 1,
                        },
                        customer: {
                            name: lead.customer?.name,
                            email: lead.customer?.email,
                            phone: lead.customer?.phone,
                        },
                        startDateTime: lead.booking.startDateTime,
                        endDateTime: lead.booking.endDateTime,
                    };
                }) ?? [];

        const mappedBlockers =
            bookings.data
                ?.filter((el) => el.type === "blocker")
                .map((booking) => ({
                    id: booking.id,
                    type: booking.type,
                    ...(booking.title && { title: booking.title }),
                    startDateTime: booking.startDateTime,
                    endDateTime: booking.endDateTime,
                    assignees: booking.assignees?.map(getUser),
                })) ?? [];

        const mappedExternalBookings =
            externalBookings.data?.map((booking) => ({
                id: booking.id,
                type: booking.type,
                ...(booking.title && { title: booking.title }),
                startDateTime: booking.startDateTime,
                endDateTime: booking.endDateTime,
                assignees: [getUser(booking.userId)],
                deepLink: booking.deepLink,
            })) ?? [];

        const mappedSessions =
            bookings.data
                ?.filter((f) => Boolean(f.sessionId) && f.status !== "cancelled")
                .map((booking) => {
                    const service = getService(booking.serviceId) as Service;
                    if (!service) {
                        return null;
                    }
                    const assignees = service.assignees.map(getUser);
                    return {
                        ...applyServiceDetails(service, localize),
                        id: booking.id,
                        type: "booking",
                        ...(booking.contactChannel === "inPerson" && {
                            location: getServiceLocation(service, company.data),
                        }),
                        ...(booking.contactChannel === "online" && {
                            meetingLink: service.meetingLink,
                        }),
                        sessionId: booking.sessionId,
                        source: booking.source,
                        channels: [booking.contactChannel as string],
                        assignees,
                        slots: {
                            booked: 1,
                            total: 1,
                        },
                        customer: {
                            name: booking.customer?.name,
                            email: booking.customer?.email as string,
                            phone: booking.customer?.phone as string,
                        },
                        startDateTime: booking.startDateTime,
                        endDateTime: booking.endDateTime,
                        allowDirectDelete: booking.sessionId && booking.source === "manual",
                    };
                }) ?? [];

        const mappedEvents =
            events.data?.map((event) => {
                const service = getService(event.serviceId) as Service;
                if (!service) {
                    return null;
                }
                const assignees = service.assignees.map(getUser);
                return {
                    ...applyServiceDetails(service, localize),
                    id: event.id,
                    type: "event",
                    channels: event.channels,
                    ...(event.channels?.includes("inPerson") &&
                        event.locations?.value && {
                            location: event.locations?.value,
                        }),
                    ...(event.channels?.includes("online") && {
                        meetingLink: event.meetingLink,
                    }),
                    assignees,
                    slots: {
                        total: parseInt(event.seatCount?.value ?? 0, 10),
                        booked: event.bookings?.reduce((total, { slots }) => slots + total, 0) ?? 0,
                    },
                    startDateTime: event.startDateTime,
                    endDateTime: event.endDateTime as string,
                };
            }) ?? [];

        setLoading(false);
        return [
            ...mappedLeads,
            ...mappedEvents,
            ...mappedSessions,
            ...mappedBlockers,
            ...mappedExternalBookings,
        ].filter((f) => f !== null) as unknown as CalendarEvent[];
    }, [services.data, bookings.data, events.data, leads.data, company.data]);

    const filtered = useMemo(() => {
        if (calendarEvents) {
            let filtered: CalendarEvent[] = [...calendarEvents];
            if (typeof serviceId === "string" && serviceId !== "all") {
                filtered = filtered.filter((f) => f.serviceId === serviceId);
            }
            if (typeof userId === "string" && userId !== "all") {
                filtered = filtered.filter((f) => f.assignees?.some((a) => a?.id === userId));
            }
            return filtered;
        }
        return undefined;
    }, [serviceId, userId, calendarEvents]);

    const event = useQuery<CalendarEvent>(
        SingleQueryKey,
        () => {
            return calendarEvents?.find((el) => el.id === eventId) as CalendarEvent;
        },
        {
            enabled: Boolean(eventId) && Boolean(calendarEvents),
        }
    );

    const { min, max } = useMemo(() => {
        const times =
            calendarEvents
                ?.filter(
                    (f) =>
                        f.type !== "blocker" &&
                        (currentInterval
                            ? isWithinInterval(new Date(f.startDateTime), currentInterval)
                            : true)
                )
                ?.flatMap((e) => [e.startDateTime, e.endDateTime])
                .map((d) => parseInt(getMins(new Date(d)))) ?? [];
        return {
            min: addMinutes(new Date(1972, 0, 1, 0, 0, 0, 0), Math.min(...times, 8 * 60)),
            max: addMinutes(new Date(1972, 0, 1, 0, 0, 0, 0), Math.max(...times, 22 * 60)),
        };
    }, [calendarEvents, currentInterval]);

    return {
        event,
        events: filtered,
        min,
        max,
        loading,
    };
};
