import { Box, Typography, Button, Stack, StackProps, BoxProps, ButtonProps } from "@mui/material";
import React from "react";

export type EmptyScreenProps = Omit<StackProps, "onClick" | "title"> & {
    imageSrc: string;
    title: string;
    description: string;
    onClick?: () => void;
    buttonLabel?: string;
    buttonProps?: ButtonProps;
    imageProps?: BoxProps<"img">;
};

export const EmptyScreen = ({
    imageSrc,
    imageProps,
    title,
    description,
    onClick,
    direction,
    buttonLabel,
    buttonProps,
    ...props
}: EmptyScreenProps) => {
    return (
        <Stack
            alignItems={"center"}
            maxWidth={560}
            ml={"auto"}
            mr={"auto"}
            spacing={4}
            {...props}
            direction={direction}
        >
            <Box
                component={"img"}
                width={180}
                height={180}
                {...imageProps}
                sx={{
                    objectFit: "contain",
                    objectPosition: "center",
                    ...imageProps?.sx,
                }}
                src={imageSrc}
            />
            <Box
                display={"flex"}
                flexDirection={direction === "row" ? "column" : "row"}
                alignItems={direction === "row" ? "flex-start" : "center"}
            >
                <Typography variant={"h2"} fontSize={"2em"} mt={3} mb={2}>
                    {title}
                </Typography>
                <Typography mb={4} whiteSpace={"pre-wrap"}>
                    {description}
                </Typography>
                {buttonLabel && (
                    <Button variant={"contained"} size={"large"} onClick={onClick} {...buttonProps}>
                        {buttonLabel}
                    </Button>
                )}
            </Box>
        </Stack>
    );
};

export const BookingListEmptyScreen = (props: Omit<EmptyScreenProps, "imageSrc">) => {
    return (
        <EmptyScreen
            mt={6}
            direction={"row"}
            maxWidth={720}
            imageProps={{ height: 314, width: 240 }}
            imageSrc={"/empty-screens/booking.png"}
            {...props}
        />
    );
};
