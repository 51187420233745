import Fetch, { IShallowSuccessResponse } from './index'

export type Response = {
    [type: string]: {
        key: string
        progress: number
        metadata?: { [k: string]: string }
        subjectId?: string
    }[]
}

export const getOnBoarding = async () => {
    if (typeof process.env.REACT_APP_API_ONBOARDING_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ONBOARDING_URL is not defined')
    }

    return Fetch.get<Response>(`${process.env.REACT_APP_API_ONBOARDING_URL}`)
}

export const updateOnBoardingStep = async (type: string, payload: { [k: string]: unknown }) => {
    if (typeof process.env.REACT_APP_API_ONBOARDING_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ONBOARDING_URL is not defined')
    }

    if (!payload) {
        throw new Error('Missing payload')
    }

    return Fetch.put(`${process.env.REACT_APP_API_ONBOARDING_URL}`, {
        type,
        steps: [payload],
    })
}
