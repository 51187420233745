import { Chip, ChipProps, styled } from "@mui/material";

const Styled = styled(Chip)<{ selected: boolean; disableDelete: boolean }>(
    ({ theme, selected, disableDelete }) => ({
        backgroundColor: selected ? "#EEF2FA" : "transparent",
        color: selected ? "#007AFF" : theme.palette.text.primary,
        borderColor: selected ? "#007AFF" : theme.palette.grey["200"],
        padding: 8,
        fontSize: "14px",
        verticalAlign: "middle",
        ["& .MuiSvgIcon-root"]: {
            color: selected ? "#007AFF" : theme.palette.text.primary,
        },
        ["& .MuiChip-deleteIcon"]: {
            color: disableDelete
                ? selected
                    ? "#007AFF"
                    : theme.palette.text.primary
                : "rgba(0,0,0,0.15)",
            pointerEvents: disableDelete ? "none" : "initial",
        },
    })
);

export type CustomChipProps = ChipProps & { selected?: boolean };

export const CustomChip = ({
    selected = false,
    deleteIcon,
    onDelete,
    ...props
}: CustomChipProps) => {
    return (
        <Styled
            deleteIcon={
                Boolean(deleteIcon && !onDelete) ? (selected ? deleteIcon : undefined) : deleteIcon
            }
            disableDelete={Boolean(deleteIcon && !onDelete)}
            onDelete={
                onDelete
                    ? onDelete
                    : deleteIcon
                    ? onDelete
                        ? onDelete
                        : selected
                        ? console.log
                        : undefined
                    : undefined
            }
            variant={"outlined"}
            clickable
            selected={selected}
            {...props}
        />
    );
};
