import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    IconButton,
    Button,
    styled,
    Stack,
    Typography,
    Stepper,
    Step,
    StepButton,
    Card,
    Skeleton,
    CircularProgress,
    BoxProps,
} from "@mui/material";
import { useMatch, useNavigate, Outlet } from "react-router-dom";
import { ArrowBackRounded, ErrorOutlineRounded, ErrorRounded } from "@mui/icons-material";
import { Spacing } from "../../Components/Spacing/Spacing";
import { UseFormReturn, useFormContext } from "react-hook-form";
import isEmpty from "lodash.isempty";
import isObject from "lodash.isobject";
import { SectionContainer } from "../../Sections/SectionContainer/SectionContainer";
import { UseMutationResult, UseQueryResult } from "react-query";
import { IShallowSuccessResponse } from "../../Api";
import randomBytes from "randombytes";
import { ObjectSchema } from "yup";
import { useErrors } from "./useErrors";
import NiceModal from "@ebay/nice-modal-react";
import { OptionsDialog } from "../../Modals/OptionsDialog";
import { useTranslate } from "../../Hooks/useTranslate";
import { InjectableStep } from "./config";

export const Page = styled(Box)(({ theme }) => ({
    margin: "40px auto",
    maxWidth: theme.breakpoints.up("xl"),
    width: "100%",
})) as typeof Box;

export const walkObjectRecursively = (
    obj: Record<string, any>,
    handler: (...el: any[]) => boolean
): Record<string, any> => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (!handler(key, value)) return acc;
        return {
            ...acc,
            [key]:
                isObject(value) && !Array.isArray(value)
                    ? walkObjectRecursively(value, handler)
                    : value,
        };
    }, {});
};

interface ISyiPage<T> {
    modelType: "service" | "event" | "voucher" | "discount" | "funnel" | "campaign";
    steps: { key: string; label: string; formKeys?: string[] }[];
    deleteItem?: UseMutationResult<IShallowSuccessResponse | void | any, any, any>;
    updateItem: UseMutationResult<IShallowSuccessResponse | void | any, any, any>;
    item: UseQueryResult<T>;
    id: string;
    title: string | ReactNode;
    isCreateFlow?: boolean;
    returnUrl?: string;
    state?: Record<string, any>;
    defaultValues?: { [k: string]: any };
    prefiller?: (setValue: UseFormReturn["setValue"]) => undefined | void | boolean;
    schema?: ObjectSchema<any>;
    injectableSteps?: InjectableStep[];
    prepareData?: (data: any) => any;
    disableConfirmOnBack?: boolean;
    onSubmit: (
        data: any,
        dirtyFields?: { [k: string]: boolean } | undefined,
        shouldNavigate?: boolean | undefined,
        validated?: boolean
    ) => Promise<string | void> | undefined;
}

export const createId = () => randomBytes(16).toString("hex");

const processPictures = async (
    pictures: any[] | undefined,
    picturesPromises?: (() => Promise<void>)[]
) => {
    if (picturesPromises) {
        await Promise.all(
            picturesPromises.map(
                (uploadFunc: () => Promise<void>) =>
                    new Promise((res, rej) =>
                        uploadFunc()
                            .then(res)
                            .catch((err) => console.log("Error uploading image", err))
                    )
            )
        );
    }
    return [...(pictures ?? [])]?.map((el: any) => {
        const { localUrl, ...props } = el ?? {};
        return { ...props };
    });
};

export const SyiPage = <T extends Record<string, any>>({
    children,
    onSubmit,
    title,
    prefiller,
    schema,
    state,
    returnUrl,
    defaultValues,
    disableConfirmOnBack = false,
    modelType,
    isCreateFlow = false,
    steps: _syiSteps,
    id,
    updateItem,
    item,
    prepareData = (data) => data,
}: Omit<BoxProps, "onSubmit"> & ISyiPage<T>) => {
    const { t } = useTranslate("utils.generic");

    const [activeStep, setActiveStep] = useState(0);

    const [_returnUrl] = useState(returnUrl);
    const [_state] = useState(state);
    const [_onSubmit] = useState<ISyiPage<any>["onSubmit"]>(() => onSubmit);

    const [hasLoadedInitially, setHasLoadedInitially] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shouldBeCloned, setShouldBeCloned] = useState(false);

    const { validate, errors } = useErrors(schema, id);

    const match: any = useMatch(":id/*");

    const navigate = useNavigate();

    const { getValues, formState, control, reset, setValue } = useFormContext();

    const [hasPrefilled, setHasPrefilled] = useState(false);

    const { dirtyFields, touchedFields } = formState;

    useEffect(() => {
        if (prefiller && !hasPrefilled) {
            if (isEmpty(touchedFields)) {
                const didPrefill = prefiller?.(setValue);
                if (didPrefill !== false) {
                    setHasPrefilled(true);
                }
            }
        }
    }, [prefiller]);

    useEffect(() => {
        if (match?.params?.["*"]) {
            const pathParts = match?.params?.["*"].split("/");
            const sectionPath = pathParts.pop();
            const foundIndex = _syiSteps.findIndex((el) => el.key === sectionPath);
            if (foundIndex > -1) {
                setActiveStep(foundIndex);
            }
        }
    }, [match.params, _syiSteps]);

    useEffect(() => {
        if (
            item.data &&
            !item.isLoading &&
            ((prefiller && hasPrefilled) || !prefiller) &&
            !hasLoadedInitially
        ) {
            for (const [key, value] of Object.entries(prepareData(item?.data ?? {}))) {
                setValue(key, value, { shouldTouch: true });
            }
            setHasLoadedInitially(true);
        }
    }, [item.data, hasPrefilled]);

    useEffect(() => {
        if (hasLoadedInitially) {
            if (defaultValues?.parentId === id) {
                setShouldBeCloned(true);
            }
        }
    }, [hasLoadedInitially]);

    useEffect(() => {
        if (_state?.validateOnMount) {
            const { pictures, picturesPromises, ...data } = getValues();
            validate({ ...data, pictures }, false);
        }
    }, [hasLoadedInitially]);

    const handleBack = async () => {
        if (!isEmpty(dirtyFields) && !updateItem.isLoading && !disableConfirmOnBack) {
            try {
                const choice = await NiceModal.show(OptionsDialog, {
                    title: t("title", "dialogs.unsavedChanges"),
                    headline: t("headline", "dialogs.unsavedChanges"),
                    buttons: [
                        {
                            key: "cancel",
                            label: t("actions.secondary", "dialogs.unsavedChanges"),
                            props: {
                                variant: "outlined",
                                color: "secondary",
                            },
                        },
                        {
                            key: "save",
                            label: t("actions.primary", "dialogs.unsavedChanges"),
                            props: {
                                variant: "contained",
                            },
                        },
                    ],
                });

                if (choice === "save") {
                    prepareSubmit(true, false);
                }
            } catch (err) {
                return;
            } finally {
                reset();
            }
        }
        if (_returnUrl) {
            navigate(_returnUrl);
        } else {
            navigate(-1);
        }
        reset();
    };

    const prepareSubmit = useCallback(
        async (forceUpdate = false, shouldNavigate = true) => {
            const { pictures, picturesPromises, ...data } = getValues();

            let validated = false;

            if (!forceUpdate) {
                try {
                    await validate({ ...data, pictures });
                    setIsSubmitting(true);
                    data.status = "active";
                    validated = true;
                } catch (err: any) {
                    data.status = "inactive";
                    if (err !== "saveDraft") {
                        return;
                    }
                }
            }

            try {
                const sanitized = walkObjectRecursively({ ...data }, (key: string) =>
                    Boolean(key && key !== "undefined")
                );

                if (pictures) {
                    try {
                        const processed = await processPictures(pictures, picturesPromises);
                        sanitized.pictures = [...processed];
                    } catch (err) {
                        console.log(err);
                        sanitized.pictures = [];
                    } finally {
                        setValue("picturesPromises", null);
                    }
                }

                await _onSubmit(sanitized, dirtyFields, shouldNavigate, validated);

                reset();
            } finally {
                setIsSubmitting(false);
            }
        },
        [dirtyFields, _onSubmit, validate, getValues]
    );

    const handleChangeStep = (index: number) => () => {
        setActiveStep(index);
        navigate(`${isCreateFlow ? "" : "edit/"}${steps[index].key}`, { replace: true });
    };

    const handleNext = async () => {
        if (isLastStep || !isCreateFlow) {
            await prepareSubmit();
            return;
        }
        setActiveStep((p) => {
            if (p + 1 < steps.length) {
                navigate(`${isCreateFlow ? "" : "edit/"}${steps[p + 1].key}`, { replace: true });
                return p + 1;
            } else return p;
        });
    };
    const handlePrevious = () =>
        setActiveStep((p) => {
            if (p > 0) {
                navigate(`${isCreateFlow ? "" : "edit/"}${steps[p - 1].key}`, { replace: true });
                return p - 1;
            } else return p;
        });

    const steps = useMemo(() => {
        const errorKeys = Object.keys(errors);
        return _syiSteps.map((el) => {
            return {
                ...el,
                errors:
                    errorKeys?.filter((f) => el.formKeys?.some((item) => f.startsWith(item))) ?? [],
            };
        });
    }, [errors, _syiSteps]);

    const isLastStep = activeStep + 1 === steps.length || steps.length === 0;

    return (
        <Page maxWidth={1100} position={"relative"} px={{ xs: 3, lg: 0 }}>
            <Spacing display={{ xs: "none", lg: "block" }} height={40} />
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
            >
                <Box display={"flex"} alignItems={"center"}>
                    <IconButton onClick={handleBack} disabled={isSubmitting}>
                        <ArrowBackRounded />
                    </IconButton>
                    {typeof title === "string" ? (
                        <Typography variant={"h2"} ml={2}>
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}
                </Box>
            </Stack>
            <Stack direction={"row"} spacing={5} mt={4}>
                <Box flexGrow={1}>
                    <SectionContainer component={Card} borderRadius={1} p={4}>
                        {(item.isLoading || !item.data || !hasLoadedInitially) &&
                        !isCreateFlow &&
                        (!prefiller || prefiller(setValue) === true) ? (
                            <Stack spacing={2}>
                                <Skeleton width={60} />
                                <Skeleton width={180} />
                                <Skeleton width={240} />
                                <Skeleton width={164} />
                            </Stack>
                        ) : (
                            <Outlet
                                context={{
                                    id,
                                    state: _state,
                                    shouldBeCloned,
                                    isCreateFlow,
                                    submitForm: (force: boolean) => prepareSubmit(force),
                                }}
                            />
                        )}
                    </SectionContainer>

                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        mt={2}
                        color={"white"}
                    >
                        <Button
                            sx={{ ...(steps.length <= 1 && { visibility: "hidden" }) }}
                            disabled={activeStep === 0}
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={handlePrevious}
                        >
                            {t("back", "buttons")}
                        </Button>

                        <Button
                            variant={"contained"}
                            disabled={updateItem.isLoading || isSubmitting}
                            onClick={handleNext}
                        >
                            {isSubmitting || updateItem.isLoading ? (
                                <CircularProgress size={"1em"} color={"inherit"} />
                            ) : isLastStep || !isCreateFlow ? (
                                isCreateFlow ? (
                                    `${t("create", "buttons")} ${t(modelType, "utils.generic")}`
                                ) : (
                                    t("saveAndClose", "buttons")
                                )
                            ) : (
                                t("next", "buttons")
                            )}
                        </Button>
                    </Stack>
                </Box>
                <Box minWidth={"20%"}>
                    {steps.length > 1 && (
                        <Box
                            sx={{
                                position: "sticky",
                                top: "16px",
                            }}
                        >
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step
                                        sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
                                        key={step.label}
                                        onClick={handleChangeStep(index)}
                                    >
                                        <StepButton
                                            icon={
                                                step.errors.length > 0 ? (
                                                    index == activeStep ? (
                                                        <ErrorRounded color={"error"} />
                                                    ) : (
                                                        <ErrorOutlineRounded color={"error"} />
                                                    )
                                                ) : null
                                            }
                                            disableRipple={true}
                                        >
                                            {step.label}
                                            {step.errors.length > 0 && (
                                                <Typography fontSize={"1em"} color={"error"}>
                                                    {step.errors.length}{" "}
                                                    {step.errors.length > 1
                                                        ? t("shortcomings")
                                                        : t("shortcoming")}
                                                </Typography>
                                            )}
                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    )}
                </Box>
            </Stack>
        </Page>
    );
};
