import {
    Box,
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Typography,
} from "@mui/material";

import React, { useState } from "react";
import { useUsers } from "../Hooks/useUsers";

type UserSelectProps<T extends string | undefined = undefined> = {
    userId: T extends string ? "all" | string : string;
    inputLabel: string;
    onChange?: SelectProps["onChange"];
    selectProps?: Omit<SelectProps, "onChange">;
    thumbnailProps?: { width?: number; height?: number };
    allLabel?: T;
} & Omit<FormControlProps, "onChange">;

export const useUserSelect = (defaultValue?: string) => {
    const [userId, setUserId] = useState(defaultValue ?? "all");

    const onChange: SelectProps["onChange"] = (evt) => {
        setUserId(evt.target.value as string);
    };

    const render = (props: Omit<UserSelectProps, "userId" | "onChange">) => {
        return <UserSelect userId={userId} onChange={onChange} {...props} />;
    };

    return {
        render,
        userId,
    };
};

export const UserSelect = ({
    userId,
    onChange,
    inputLabel,
    allLabel,
    selectProps,
    thumbnailProps,
    ...props
}: UserSelectProps) => {
    const {
        users: { data: users },
    } = useUsers();

    return (
        <FormControl sx={{ minWidth: 320, maxWidth: 320 }} {...props}>
            <InputLabel sx={{ backgroundColor: "white", pl: 0.5, pr: 0.5 }} id="select-user">
                {inputLabel}
            </InputLabel>
            <Select labelId="select-user" value={userId} onChange={onChange} {...selectProps}>
                {allLabel && <MenuItem value={"all"}>{allLabel}</MenuItem>}
                {users?.map((el) => (
                    <Box component={MenuItem} maxWidth={"100%"} key={el.id} value={el.id}>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                mr={2}
                                flexGrow={1}
                                overflow={"hidden"}
                            >
                                <Box
                                    component={"img"}
                                    src={el?.pictures?.profile?.url}
                                    width={48}
                                    height={48}
                                    borderRadius={1}
                                    mr={1}
                                    {...thumbnailProps}
                                />
                                <Typography
                                    textOverflow={"ellipsis"}
                                    maxWidth={"100%"}
                                    overflow={"hidden"}
                                >
                                    {el?.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Select>
        </FormControl>
    );
};
