import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Header } from "./Header";
import React, { useState } from "react";
import { useTranslate } from "../Hooks/useTranslate";
import { subscribeCalendars } from "../Api";
import { OptionGroup, OptionGroupSelectedSection } from "../Components/OptionGroup/OptionGroup";
import { SyiSection } from "../Pages/SyiPage/SyiSection";
import { FormProvider, useController, useForm, useWatch } from "react-hook-form";
import { useOutlook } from "../Hooks/useOutlook";

const targetOptions = [
    {
        key: "create",
        label: "Opret ny kalender i Outlook",
    },
    {
        key: "existing",
        label: "Vælg eksisterende",
    },
];

const strategyOptions = [
    {
        key: "fromPlatform",
        label: "Fra Spiritworld til Outlook",
    },
    {
        key: "fromOutlook",
        label: "Fra Outlook til Spiritworld",
    },
    {
        key: "twoWay",
        label: "Begge veje",
    },
];

type FormPayload = {
    strategy: "fromPlatform" | "fromOutlook" | "twoWay";
    target: "create" | "existing";
    name?: string;
    shouldImport?: boolean;
};

export const OutlookConnectCalendarsDialog = NiceModal.create(
    ({ onClick }: { onClick: () => Promise<void> }) => {
        const { t } = useTranslate("dialogs.outlookConnectCalendars");

        const [loading, setLoading] = useState(false);

        const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

        const { ...methods } = useForm<FormPayload>();

        const modal = useModal();

        const strategy = useWatch({ control: methods.control, name: "strategy" });

        const { field: shouldImport } = useController({
            control: methods.control,
            name: "shouldImport",
            defaultValue: false,
        });

        const handleClose = () => {
            modal.remove();
        };

        const handleSubmit = async () => {
            setLoading(true);
            await subscribeCalendars(
                preparePayload({ ...methods.getValues(), selectedIds: Array.from(selectedIds) }),
                getTimezoneOffset()
            );
            handleClose();
            setLoading(false);
        };

        const handleChange =
            (id: string): FormControlLabelProps["onChange"] =>
            (_evt, checked) => {
                setSelectedIds((p) => {
                    if (checked) {
                        p.add(id);
                    } else {
                        p.delete(id);
                    }
                    return p;
                });
            };

        return (
            <Dialog open={modal.visible} fullWidth maxWidth={"sm"}>
                <Box pl={4}>
                    <Header title={t("title")} onClose={handleClose} />
                    <FormProvider {...methods}>
                        <Box pt={3} pb={4} pr={4}>
                            <Typography mb={2} whiteSpace={"pre-wrap"}>
                                {t("description")}
                            </Typography>

                            <SyiSection
                                title={"Hvilken Outlook kalender skal forbindes til Spiritworld?"}
                            >
                                <OptionGroup name={"target"} options={targetOptions}>
                                    <OptionGroupSelectedSection optionKey={"create"} mt={2}>
                                        <TextField
                                            label={"Navn på kalender"}
                                            defaultValue={"Spiritworld"}
                                            {...methods.register("name")}
                                        />
                                    </OptionGroupSelectedSection>
                                    <OptionGroupSelectedSection optionKey={"existing"} mt={2}>
                                        <AvailableCalendars onChange={handleChange} />
                                    </OptionGroupSelectedSection>
                                </OptionGroup>
                            </SyiSection>

                            <SyiSection title={"Hvordan skal aftaler synkroniseres?"} mt={3}>
                                <OptionGroup name={"strategy"} options={strategyOptions} />
                            </SyiSection>

                            {(strategy === "fromOutlook" || strategy === "twoWay") && (
                                <SyiSection
                                    title={"Skal dine fremtidige aftaler fra Outlook importeres?"}
                                    mt={3}
                                >
                                    <FormControlLabel
                                        onChange={shouldImport.onChange}
                                        checked={shouldImport.value}
                                        inputRef={shouldImport.ref}
                                        control={<Checkbox />}
                                        label={"Importer aftaler fra Outlook"}
                                    />
                                </SyiSection>
                            )}

                            <Button
                                sx={{ mt: 3 }}
                                variant={"contained"}
                                size={"large"}
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                {loading ? (
                                    <CircularProgress color={"inherit"} size={20} sx={{ m: 0 }} />
                                ) : (
                                    t("actions.primary")
                                )}
                            </Button>
                        </Box>
                    </FormProvider>
                </Box>
            </Dialog>
        );
    }
);

const AvailableCalendars = ({
    onChange,
}: {
    onChange: (id: string) => FormControlLabelProps["onChange"];
}) => {
    const { calendars } = useOutlook();

    return (
        <FormGroup>
            {calendars.data?.map((cal) => (
                <FormControlLabel
                    control={<Checkbox />}
                    onChange={onChange(cal.id)}
                    label={cal.name}
                />
            )) ?? <Skeleton width={200} height={20} />}
        </FormGroup>
    );
};

const preparePayload = ({
    target,
    strategy: syncStrategy,
    name = "Spiritworld",
    selectedIds,
    shouldImport,
}: FormPayload & { selectedIds: string[] }) => {
    if (target === "create") {
        return { name, syncStrategy };
    } else {
        return {
            ids: selectedIds.map((id) => ({
                id,
                syncStrategy,
                ...(shouldImport && { shouldImport }),
            })),
        };
    }
};

const getTimezoneOffset = () => {
    const offset = new Date().getTimezoneOffset();
    const signed = Math.sign(offset);
    if (signed === 1) {
        return -1 * offset;
    }
    if (signed === -1) {
        return Math.abs(offset);
    }
    return 0;
};
