import { Box, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { DeleteForeverRounded } from "@mui/icons-material";
import { Tip } from "../../../Components/Tip/Tip";
import {
    OptionGroup,
    OptionGroupSelectedSection,
} from "../../../Components/OptionGroup/OptionGroup";
import { ArrayContainer } from "../../../Components/ArrayContainer/ArrayContainer";
import { ErrorLabel } from "../../SyiPage/ErrorLabel";
import { useErrors } from "../../SyiPage/useErrors";
import { useTranslation } from "react-i18next";

const notificationOptions = [
    {
        key: "emailAndText",
        label: "Send beskeder på både email & SMS",
    },
    {
        key: "textOnly",
        label: "Send beskeder kun på SMS",
    },
    {
        key: "emailOnly",
        label: "Send beskeder kun på email",
    },
    {
        key: "customerShouldChoose",
        label: "Lad gæsten selv bestemme",
    },
];

export const ServiceSyiSectionCustomerInfo = () => {
    const { t } = useTranslation();

    const { register } = useFormContext();

    const { clearError, getError } = useErrors();

    const hasCustomDataOptions = [
        {
            key: "no",
            label: t("service.create.customerInfo.customData.options.no.label"),
        },
        {
            key: "yes",
            label: t("service.create.customerInfo.customData.options.yes.label"),
        },
    ];

    return (
        <>
            <Typography variant={"h4"} mb={2}>
                {t("service.create.customerInfo.title")}
            </Typography>

            <Typography variant={"h5"} mt={2} mb={2}>
                {t("service.create.customerInfo.customData.title")}
                <ErrorLabel mt={1} label={getError("customData")} />
            </Typography>

            <Tip label={t("service.create.customerInfo.customData.tip1")} />
            <Tip label={t("service.create.customerInfo.customData.tip2")} />

            <Stack mt={2} divider={<Divider />} spacing={4}>
                <OptionGroup
                    name={"customData.selectedOptionKey"}
                    options={hasCustomDataOptions}
                    onChangeValue={() => clearError("customData")}
                >
                    <OptionGroupSelectedSection optionKey={"yes"}>
                        <ArrayContainer
                            controllerKey={"customData.value"}
                            addButtonProps={{
                                label: t(
                                    "service.create.customerInfo.customData.options.yes.buttonLabel"
                                ),
                            }}
                            emptyState={[{ name: "" }]}
                            defaultValue={{ name: "" }}
                            renderItem={(el, i, { onDeleteItem }) => (
                                <Stack mt={3} spacing={2} direction={"row"} alignItems={"center"}>
                                    <TextField
                                        sx={{ minWidth: 320 }}
                                        label={t(
                                            "service.create.customerInfo.customData.options.yes.inputPlaceholder"
                                        )}
                                        {...register(`customData.value.${i}.name`)}
                                        helperText={t(
                                            "service.create.customerInfo.customData.options.yes.inputHelpText"
                                        )}
                                    />
                                    <IconButton onClick={onDeleteItem}>
                                        <DeleteForeverRounded />
                                    </IconButton>
                                </Stack>
                            )}
                        />
                    </OptionGroupSelectedSection>
                </OptionGroup>

                {/*<SyiSection title={'Holdbar sender automatisk følgende beskeder ud til dine gæster op til oplevelsens start:'} typographyProps={{ fontSize: '1.1em'}} error={getError('notifications')}>

                    <Box component={'ol'} p={2} pr={4} border={'1px solid black'} fontSize={'0.88em'} display={'inline-block'} borderRadius={2}>
                        {
                            ['Bekræftelse på booking', 'Én reminder dagen før', 'Ved ændring af oplevelsen', 'Ved aflysning af oplevelsen'].map(el => (
                                <Box component={'li'} lineHeight={1.7} ml={2} >{el}</Box>
                            ))
                        }
                    </Box>

                    <Typography variant={'h5'} mt={2} mb={1.5}>
                        Hvordan skal dine gæster modtage disse beskeder?
                    </Typography>

                    <OptionGroup
                        name={'notifications.selectedOptionKey'}
                        options={notificationOptions}
                        onChangeValue={() => clearError('notifications')}
                    />

                </SyiSection>*/}
            </Stack>
        </>
    );
};
