import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {  useState } from 'react';
import {
    DndContext,
    closestCenter,
    TouchSensor,
    MouseSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
} from '@dnd-kit/core';
import {
    SortableContext,
    rectSwappingStrategy,
    useSortable, arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Picture } from './Picture';

type Image = {
    key: string,
    url: string,
    localUrl: string
}

interface Props {
    onUpdate?: (arr: Image[]) => void,
    onDelete?: (key: string) => void,
    pictures: Image[]
}

export const Pictures = React.memo(
    ({ pictures, onUpdate, onDelete }: Props) => {

        const sensors = useSensors(
            useSensor(MouseSensor),
            useSensor(TouchSensor, {
                activationConstraint: { delay: 200, tolerance: 10 },
            })
        );

        const [activeKey, setActiveKey] = useState<string | null>(null);

        const activeSrc = useMemo(() => pictures.find(el => el.key === activeKey)?.url,[activeKey])

        const handleDragStart = (event: DragStartEvent) => {
            const { active } = event;
            setActiveKey(active.id as string);
        };

        const handleDragEnd = (event: DragEndEvent) => {
            const { active, over } = event;

            if (!!over && active.id !== over.id) {
                onUpdate?.(arrayMove(pictures, active.data.current?.sortable.index, over.data.current?.sortable.index))
            }

            setActiveKey(null);
        };

        const showPictures = true;

        const items = pictures.map(el => el.key);

        return (
            <>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(165px, 1fr))',
                        gridGap: 8,
                    }}
                >
                    {showPictures && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={items}
                                strategy={rectSwappingStrategy}
                            >
                                {pictures.map(({key, url, localUrl}, index) => (
                                    <SortablePicture
                                        key={key}
                                        source={localUrl ?? url}
                                        id={key}
                                        onDelete={() => onDelete?.(key)}
                                    />
                                ))}
                            </SortableContext>
                            <DragOverlay>
                                {activeSrc ? <Picture isMoving={true} source={activeSrc} /> : null}
                            </DragOverlay>
                        </DndContext>
                    )}
                </Box>
            </>
        );
    }
);

const SortablePicture = (
    props: React.ComponentPropsWithoutRef<typeof Picture>
) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        isOver,
        isSorting
    } = useSortable({ id: props.id ?? props.source });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.4 : 1,
    };

    return (
        <Picture
            {...props}
            ref={setNodeRef}
            sx={style}
            isMoving={isSorting || isDragging || isOver}
            attributes={attributes}
            listeners={listeners}
        />
    );
};
