import { Avatar, Grid, Typography } from "@mui/material";
import { Tip } from "../../../Components/Tip/Tip";
import { CustomChip } from "../../../Components/CustomChip/CustomChip";
import { useController, useWatch } from "react-hook-form";
import { DoneRounded } from "@mui/icons-material";
import { useUsers } from "../../../Hooks/useUsers";
import { useErrors } from "../../SyiPage/useErrors";
import { ErrorLabel } from "../../SyiPage/ErrorLabel";
import React from "react";

export const ServiceSyiSectionAssignees = () => {
    const { field: assignees } = useController({
        name: "assignees",
        defaultValue: [],
    });

    const { withClear, getError } = useErrors();

    const type = useWatch({ name: "type" });

    const handleToggleGuide = (id: string) => () => {
        const _new = !assignees.value.includes(id)
            ? [...assignees.value, id]
            : type === "event" || assignees.value.length > 1
            ? assignees.value.filter((el: string) => el !== id)
            : assignees.value;
        assignees.onChange(_new);
    };

    const {
        users: { data: users },
    } = useUsers();

    return (
        <>
            <Typography variant={"h4"}>Valg af facilitator</Typography>
            <Typography variant={"h5"} mt={2} mb={2}>
                Hvem skal være facilitator for aktiviteten?
            </Typography>
            <Tip
                label={
                    "Vælg den/de personer i dit team, der har kompetencerne til at være en erfaren, beleven og tryghedsskabende terapeut/facilitator på aktiviteten."
                }
            />

            {Boolean(getError("assignees")) && <ErrorLabel mt={2} label={getError("assignees")} />}

            <Grid container gap={1} mt={3}>
                {users?.map((el) => {
                    return (
                        <Grid
                            item
                            component={CustomChip}
                            avatar={<Avatar alt={el.name} src={el.pictures?.profile?.url} />}
                            label={el.name}
                            onClick={withClear("assignees", handleToggleGuide(el.id))}
                            selected={assignees.value?.includes(el.id)}
                            deleteIcon={<DoneRounded />}
                            sx={{ height: 48, borderRadius: 100 }}
                        />
                    );
                })}
            </Grid>
        </>
    );
};
