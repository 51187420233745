import { useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { useMemo } from "react";
import { useBookings } from "./useBookings";

type SubjectEvent =
    | "offerAccepted"
    | "bookingMade"
    | "bookingCancelled"
    | "bookingMoved"
    | "voucherBought"
    | "leadSubmitted";

type CustomerSubject<T extends OfferEvent | BookingEvent | VoucherEvent | LeadEvent> = {
    subject: "customer";
} & T;

type OfferSubject<T extends OfferEvent | BookingEvent> = {
    subject: "offer";
} & T;

type OfferEvent = {
    event: "offerAccepted";
    metadata: {
        price: {
            amount: number;
            currency: string;
        };
        items: { [productId: string]: number };
    };
};

type VoucherEvent = {
    event: "voucherBought";
    metadata: {
        price: {
            amount: number;
            currency: string;
        };
    };
};

type BookingEvent = {
    event: "bookingMade" | "bookingCancelled" | "bookingMoved";
    metadata: {
        quantity: number;
        price: {
            amount: number;
            currency: string;
        };
        contactChannel: string;
        name: string;
        serviceId: string;
        movedFromStartDateTime?: string;
        movedFromEndDateTime?: string;
        startDateTime: string;
        endDateTime: string;
        offerId?: string;
    };
};

type LeadEvent = {
    event: "leadSubmitted";
    metadata: {
        contactChannel: string;
        serviceId?: string;
        variantId?: string;
    };
};

export type Subject<T extends OfferEvent | BookingEvent | VoucherEvent | LeadEvent> =
    | CustomerSubject<T>
    | OfferSubject<T extends OfferEvent | BookingEvent ? T : never>;

export type Activity<
    T extends OfferEvent | BookingEvent | VoucherEvent | LeadEvent = BookingEvent
> = Subject<T> & {
    event: SubjectEvent;
    subjectId: string;
    companyId: string;
    created: string;
    id: string;
};

export const useActivities = (subject?: "customer" | "offer" | "voucher", subjectId?: string) => {
    const queryClient = useQueryClient();

    const { getServiceName } = useBookings();

    const AllActivitiesQueryKey = ["activities"];
    const ActivititesForSubjectQueryKey = ["activities", { subject, subjectId }];

    const allActivities = useQuery<Activity[]>(
        AllActivitiesQueryKey,
        async () => {
            await queryClient.cancelQueries(AllActivitiesQueryKey);
            return api.getAllActivities();
        },
        {
            enabled: true,
        }
    );

    const activities = useQuery<Activity[]>(
        ActivititesForSubjectQueryKey,
        async () => {
            await queryClient.cancelQueries(ActivititesForSubjectQueryKey);
            return api.getActivitiesForSubject(subject as string, subjectId as string);
        },
        {
            enabled: Boolean(subject) && Boolean(subjectId),
        }
    );

    const enrichedActivities = useMemo(() => {
        return (
            activities.data?.map((el) => {
                return {
                    ...el,
                    metadata: {
                        ...el.metadata,
                        name: getServiceName(el.metadata?.serviceId),
                    },
                };
            }) ?? []
        );
    }, [activities, getServiceName]);

    const enrichedAllActivities = useMemo(() => {
        return (
            allActivities.data?.map((el) => {
                return {
                    ...el,
                    metadata: {
                        ...el.metadata,
                        name: getServiceName(el.metadata?.serviceId),
                    },
                };
            }) ?? []
        );
    }, [activities, getServiceName]);

    return {
        allActivities,
        activities,
        enrichedActivities,
        enrichedAllActivities,
    };
};
