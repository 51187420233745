import { ComponentPropsWithoutRef, useCallback, useState } from "react";
import { TFileTypes, TModelTypes } from "../types";
import { Box } from "@mui/material";
import * as api from "../Api";

export interface IDropzoneProps {
    modelType: TModelTypes;
    id?: string;
    fileType: TFileTypes;
    multiple?: boolean;
    onPrepared?: (
        args: { key: string; url: string },
        localUrl: string,
        key: TFileTypes,
        uploadFunc: () => Promise<void>,
        cleanUp: () => void,
        fileName: string
    ) => void;
}

const cleanUrl = (url: string) => {
    const { origin, pathname } = new URL(url);
    return `${origin}${pathname}`;
};
export const useUploader = ({
    fileType,
    modelType,
    id,
    onPrepared,
    multiple = true,
}: ComponentPropsWithoutRef<typeof Box> & IDropzoneProps) => {
    const [loading, setLoading] = useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [tempUrls, setTempUrls] = useState<string[] | null>(null);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        //setTempUrls(acceptedFiles.map(el => URL.createObjectURL(el)))
        setLoading(`Uploader...`);
        try {
            for (const f of acceptedFiles) {
                try {
                    const localUrl = URL.createObjectURL(f);

                    const { url, key } = await api.getSigned(fileType, modelType, f.type, id!);

                    const uploadFunc = async () => {
                        return api
                            .upload(
                                f,
                                url,
                                (progress) => {
                                    if (progress < 100) {
                                        setLoading(`Uploader ${progress}%`);
                                        setUploadProgress(progress);
                                    } else {
                                        setLoading(`Behandler...`);
                                    }
                                },
                                f.type
                            )
                            .finally(() => {
                                setLoading(null);
                            });
                    };

                    if (url) {
                        onPrepared?.(
                            { url: cleanUrl(url), key },
                            localUrl,
                            fileType,
                            uploadFunc,
                            () => setTempUrls(null),
                            f.name
                        );
                    }
                    setTempUrls((p) => (p && multiple ? [localUrl, ...p] : [localUrl]));
                } catch (err) {}
            }
        } catch (err) {
            console.log("error ", err);
        } finally {
            setLoading(null);
        }
    }, []);

    return {
        onDrop,
        loading,
        uploadProgress,
        tempUrls,
    };
};
