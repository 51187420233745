import { FormControl, FormControlProps, InputLabel, Select, SelectProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export const CustomSelect = ({
    defaultValue,
    options,
    label,
    SelectProps,
    onChange,
    ...props
}: Omit<FormControlProps, "onChange"> & {
    SelectProps?: Omit<SelectProps, "onChange">;
    onChange?: SelectProps["onChange"];
    label: string;
    options?: { key: string; label: string }[];
}) => {
    const { t } = useTranslation("translation");
    return (
        <FormControl {...props}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                defaultValue={defaultValue}
                variant={"outlined"}
                onChange={onChange}
                {...SelectProps}
            >
                {options?.map((el) => {
                    return (
                        <MenuItem value={el.key} key={el.key}>
                            {el.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
