import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

const SolidTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    ({ sx, ...props }, ref) => {
        return (
            <TextField
                ref={ref}
                sx={{
                    "& fieldset": {
                        borderColor: "transparent",
                    },
                    ...sx,
                }}
                InputLabelProps={{
                    sx: {
                        color: "#3A3A3C",
                        fontWeight: 600,
                    },
                }}
                InputProps={{
                    sx: {
                        backgroundColor: "#F2F2F7",
                    },
                }}
                {...props}
            />
        );
    }
);

SolidTextField.displayName = "SolidTextField";

export default SolidTextField;
