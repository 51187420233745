import { Box, Card, type BoxProps } from "@mui/material";

export const FlatCard = ({ children, sx = {}, ...props }: BoxProps) => {
    return (
        <Box
            component={Card}
            border={"1px solid #D1D1D6"}
            {...props}
            sx={{
                ...sx,
                boxShadow: "none",
            }}
        >
            {children}
        </Box>
    );
};
