import Fetch from './index'
import { Rating, Review, ReviewPayload } from '../Hooks/useReviews'

export const getReviewsBySubject = async (subject: string, subjectId: string) => {
    if (typeof process.env.REACT_APP_API_REVIEWS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_REVIEWS_URL is not defined')
    }

    return Fetch.get<Review[]>(
        `${process.env.REACT_APP_API_REVIEWS_URL}reviews/${subject}/${subjectId}`,
    )
}

export const getRatingBySubject = async (subject: string, subjectId: string) => {
    if (typeof process.env.REACT_APP_API_REVIEWS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_REVIEWS_URL is not defined')
    }

    return Fetch.get<Rating>(
        `${process.env.REACT_APP_API_REVIEWS_URL}rating/${subject}/${subjectId}`,
    )
}

export const getRatings = async (subject: string) => {
    if (typeof process.env.REACT_APP_API_REVIEWS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_REVIEWS_URL is not defined')
    }

    return Fetch.get<Rating[]>(`${process.env.REACT_APP_API_REVIEWS_URL}ratings/${subject}`)
}

export const getReview = async (id: string) => {
    if (typeof process.env.REACT_APP_API_REVIEWS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_REVIEWS_URL is not defined')
    }

    return Fetch.get<Review>(`${process.env.REACT_APP_API_REVIEWS_URL}${id}`)
}
export const updateReview = async (id: string, payload: Omit<ReviewPayload, 'id'>) => {
    if (typeof process.env.REACT_APP_API_REVIEWS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_REVIEWS_URL is not defined')
    }

    return Fetch.put(`${process.env.REACT_APP_API_REVIEWS_URL}${id}`, payload)
}

export const deleteReview = async (id: string) => {
    if (typeof process.env.REACT_APP_API_REVIEWS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_REVIEWS_URL is not defined')
    }

    return Fetch.delete(`${process.env.REACT_APP_API_REVIEWS_URL}${id}`)
}
