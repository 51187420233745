import { Box, Typography, Stack, Checkbox, StackProps, Tooltip } from "@mui/material";
import { useNotifications } from "../../Hooks/useNotifications";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { useTranslate } from "../../Hooks/useTranslate";
import { useProfile } from "../../Hooks/useProfile";

export const SettingsNotificationsSection = () => {
    const { t } = useTranslate("settings.notifications");

    const {
        notifications: { data: notifications, isLoading },
        toggleNotification,
    } = useNotifications();

    const { company } = useProfile();

    const handleToggle =
        (groupKey: string, key: string, type: "company" | "user") =>
        (_: unknown, checked: boolean) => {
            return toggleNotification.mutate({ groupKey, key, checked, type });
        };

    return (
        <>
            <Typography variant={"h4"} mb={2}>
                {t("title")}
            </Typography>

            <Typography>{t("description")}</Typography>

            <Stack mt={4} spacing={2}>
                {isLoading ? (
                    <SimpleSkeleton />
                ) : (
                    <TemplateRow title={""}>
                        <Stack direction={"row"} spacing={1} textAlign={"center"}>
                            <Tooltip title={"Du modtager besked på mail"}>
                                <Typography minWidth={42} display={"block"} variant={"body2"}>
                                    Mig
                                </Typography>
                            </Tooltip>
                            <Tooltip
                                title={`Der sendes en besked til firmaets hovedmail (${company.data?.companyEmail})`}
                            >
                                <Typography minWidth={42} display={"block"} variant={"body2"}>
                                    Firma
                                </Typography>
                            </Tooltip>
                        </Stack>
                    </TemplateRow>
                )}

                {notifications?.map((group, groupIndex) => (
                    <Box>
                        <Typography variant={"h5"}>{t(`groups.${group.key}`)}</Typography>
                        <Stack>
                            {group.items.map((item, index) => (
                                <TemplateRow title={t(`templates.${item.key}`)}>
                                    <Stack direction={"row"} spacing={1}>
                                        {item.userEnabled !== undefined && (
                                            <Checkbox
                                                defaultChecked={item.userEnabled}
                                                onChange={handleToggle(group.key, item.key, "user")}
                                            />
                                        )}
                                        {item.companyEnabled !== undefined && (
                                            <Checkbox
                                                defaultChecked={item.companyEnabled}
                                                onChange={handleToggle(
                                                    group.key,
                                                    item.key,
                                                    "company"
                                                )}
                                            />
                                        )}
                                    </Stack>
                                </TemplateRow>
                            ))}
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </>
    );
};

type TemplateRowProps = {
    title: string;
} & Omit<StackProps, "title">;
const TemplateRow = ({ title, children, ...props }: TemplateRowProps) => {
    return (
        <Stack
            direction={"row"}
            spacing={2}
            width={"100%"}
            maxWidth={540}
            justifyContent={"space-between"}
            alignItems={"center"}
            {...props}
        >
            <Typography minWidth={200}>{title}</Typography>
            {children}
        </Stack>
    );
};
