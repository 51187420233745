import Fetch from './index'

export const getInvitationData = async (invitationId: string) => {
    if (typeof process.env.REACT_APP_API_ACCOUNTS_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
    }

    return Fetch.get<{ email: string }>(
        `${process.env.REACT_APP_API_ACCOUNTS_URL}invitations/${invitationId}`,
    )
}
